import React, { Component, useEffect, useState } from "react";
import "./index.scss";
import {
  IonSlides,
  IonSlide,
  IonContent,
  withIonLifeCycle,
  IonAlert,
  IonModal,
  IonPage,
} from "@ionic/react";
import { useHistory } from "react-router";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import digitService from "../../services/DigitService";
import diabetestImg from "../../assets/images/Digit/diabetes-main-2.jpeg";
import zylaLogo from "../../assets/images/zyla-logo.png";
import dollyBagai from "../../assets/images/Digit/Dr_Dolly_Bagai.svg";
import rebecaShweta from "../../assets/images/Digit/DrRebeccaShwetaGladwin.jpg";
import priyankaPaul from "../../assets/images/Digit/Dr Priyanka Paull.jpeg";
import kaminiMishra from "../../assets/images/Digit/Kamini Mishra.JPG";
import lopaSaha from "../../assets/images/Digit/Lopamudra Saha.jpg";
import trishlaRaut from "../../assets/images/Digit/Trishla Rautela.jpg";
import ketkiTawade from "../../assets/images/Digit/Ketki Tawade.jpg";
import upArrow from "../../assets/images/upArrow_img.png";
import rajesh from "../../assets/images/Digit/Rajesh Deshpande.jpg";
import rohini from "../../assets/images/Digit/Rohini GV.jpg";
import satheesh from "../../assets/images/Digit/Satheesh Ragothaman Rao.jpg";
import check from "../../assets/images/Digit/check.svg";
import PACompletedImage from "../../assets/icons/ic_completed_Success.png";
import AuthenticationService from "../../services/AuthenticationService";
import PaymentDialog from "../../components/common/PaymentDialog";
import RequestCompleted from "../../containers/premium/components/requestCompleted";
import razorPayHelper from "../../utils/RazorPayHelper";
import dompurify from "dompurify";
import { useSelector } from "react-redux";
const Doctors = [
  {
    name: "Dr Rebecca Shweta Gladwin",
    image: rebecaShweta,
    meta: "MD (Medicine), Family Physician, Previously at Boston University and Columbia Asia. Treated 5000+ COVID patients.",
    text: "MD (Medicine), Family Physician, Previously at Boston University and Columbia Asia. Treated 5000+ COVID patients.",
    exp: "10+ yrs experience",
  },
  {
    name: "Dr Dolly Bagai",
    image: dollyBagai,
    meta: "MBBS, Specialization in Family Medicine, Women’s Health. Renowned Family Physician, specialization from the prestigious GSVM Medical College, Kanpur. ",
    text: "MBBS, Specialization in Family Medicine, Women’s Health. Renowned Family Physician, specialization from the prestigious GSVM Medical College, Kanpur.  ",
    exp: "37+ years experience",
  },
  {
    name: "Dr Priyanka Paul",
    image: priyankaPaul,
    meta: "BAMS,PG (Diploma) in Clinical Nutrition and Dietetics with Fellowship in Pulmonary Rehabilitationand Nutrition and Dietetics ",
    text: "10+ years of rich experiene at renonwed hospitals such as Jyoti Punj, Fortis  and Apollo Hospitals. PG in Clinical Nutrition and Fellowship in Pulmonary Rehabilitation from Apollo Educational and Research Foundation. Specialises in managing chronic health disorders in patients.",
    exp: "10+ yrs experience",
  },
  {
    name: "Dr Kamini Mishra",
    image: kaminiMishra,
    meta: "MPT (Orthopaedics), Invasive Pelvic Floor Practitioner, Women health Physical Therapist",
    text: "9+ years of experience in treating patients with chronic health issues. Masters in Orthopedics, with previous work experience at NHI, Cloudnine and Apollo. Specializes in Physiotherapy modalities especially in chronic diseases such as Diabetes and Hypertension, an Invasive Pelvic Floor practitioner and an expert in Women health management.",
    exp: "9+ yrs experience",
  },
  {
    name: "Lopamudra Saha",
    image: lopaSaha,
    meta: "BSc (Hons.) Food Science and Nutrition Management, MSc Nutrition and Dietetics. Certified Diabetes Educator.",
    text: "An expert in Nutrition with Masters from distinguished universities like IGNOU and University of Leeds, UK. Skilled in analysing the routine & lifestyle of patients dealing with chronic diseases and helping them get make improvements in their acute and chronic symptoms.",
  },
  {
    name: "Trishla Rautela",
    image: trishlaRaut,
    meta: "BSc. Home Science, MSc. Dietetics & Food Service Management, MSc. Nutrition from University of Leeds, UK",
    text: "An expert in Nutrition with Masters from distinguished universities like IGNOU and University of Leeds, UK. Skilled in analysing the routine & lifestyle of patients dealing with chronic diseases and helping them get make improvements in their acute and chronic symptoms.",
  },
  {
    name: "Ketki Tawade",
    image: ketkiTawade,
    meta: "MA in Applied Psychology with specialization in Clinical Psychology",
    text: "Qualified in Therapeutic Nutrition with distinction from the prestigious Lady Irwin College, Delhi University. A Diploma holder in Dietetics from VLCC and MSc. in Dietetics from IGNOU. Previous experience at Metro Hospital, Faridabad focused on chronic diseases.",
  },
];

const FAQs = [
  {
    title: "How is the Zyla program different?",
    content: `A personal team of Senior Doctors, Nutritionists, Physiotherapists and Stress Counselors a message away, 24*7 on your phone. Your health plan in the app is completely personalized for your schedule and updated regularly, so it’s super easy to follow. Easy reminders on readings to measure today with immediate guidance. What’s more? Audio-guided meditations so that you sleep like a baby.
        We will be with you throughout to ensure your health improves - Zyla’s care team spends 300+ minutes with you every month to correct your health (30 times that of a monthly doctor visit).
        `,
  },
  {
    title: "What is your refund policy?",
    content:
      "At Zyla, we provide care under the most able & experienced doctors. We are therefore confident of our care quality. If the user/ patient is not satisfied with the quality of care, he/ she can seek a 100% refund, no questions asked within the first 7 days of purchasing the program. However, if the care gets stalled after the commencement, by the patient, no refund shall be applicable. More details here - https://zyla.in/refund-policy",
  },
  {
    title: "Who is the program ideal for?",
    content: ` <b>Patients on diabetes medicines or insulin</b><br />With the best diabetes plan and continuous monitoring in the Zyla program, most patients are able to reduce their diabetes medicine doses. It’s also very important to keep diabetes in check to prevent damage of delicate organs like heart, kidney, eyes, and foot.<br /><br />
      <b>Patients with pre-diabetes.</b><br />If you have just been diagnosed with diabetes or if your sugar levels came on the higher side for the first time, there is a high chance you can control diabetes without medicines through the Zyla program.`,
  },
  {
    title: "What happens after the completion of my program?",
    content:
      "The primary goal of the Zyla Program is to get your health parameters back in range and reduce your dependence on medicines. You can stay on it for the maintenance of your health for as long as you would like.",
  },
  {
    title: "I am very busy with work, how will I manage the phone calls?",
    content:
      "We understand, and that is exactly the reason the Zyla Program is designed to completely suit your busy life. All your Progress Review Calls are pre-scheduled to suit your work timings. While your first call may take 30 minutes, all follow-up calls usually wrap up in 10-15 minutes. At special occasions, if you are unable to take the calls, you can simply inform on the app and we will make all efforts to re-schedule based on the team’s availability.",
  },
  {
    title:
      "This program is for my father/ mother how will you manage them, they are not even comfortable in using the mobile app?",
    content: `Do not worry, we have handled thousands of parents through their caregivers. In fact, the program will be very helpful as you can pass on the stress of their health to us and the team will ensure they achieve their health goals. Without a doubt, the Zyla app will be the simplest mobile app you have ever used! It is designed keeping in mind that you are busy or may not have time to learn a complex application. What’s more? We will help you at every step and make this super easy and seamless for you.
          Read success stories of our Diabetes award winners `,
  },
  {
    title: "Which other health programs do you offer?",
    content: `Here are the Zyla programs that can help you with the best of health<br />
        <ul className="list-disc">
          <li><b>Covid care program:</b> To help patients who have been diagnosed with mild/ moderate Covid or showcasing Covid symptoms and now recovering at home. A holistic care plan under leading Covid specialist doctors with 24x7 support. For any queries</li>
          <li><b>Defeating Diabetes Program:</b> For patients dealing with Type 1 or Type 2 Diabetes and related complications such as Neuropathy, Nephropathy, Retinopathy</li>
          <li><b>Healthy Heart Program:</b> To treat out-of-range Total Cholesterol, LDL, HDL, Triglycerides or for patients who have undergone PTCA/ CABG heart procedures or for patients dealing with Hypertension (High BP)</li>
          <li><b>Ova Women’s Health Program:</b> For women dealing with PCOS, PCOD, Thyroid disorders, Post Menopausal Syndrome, Anemia, Osteoporosis, Arthritis</li>
          <li><b>Ova Safe Pregnancy Program:</b> For a stress-free pregnancy journey under Senior Gynecologists & experts, with pre-natal nutrition & exercise support, pre-natal community yoga, 24x7 chat support and meditation support.</li>
          <li><b>Liver Lifeline Program:</b> For patients dealing with Grade 1/ 2 Fatty liver (higher SGOT/ SGPT/ Alkaline Phosphatase) and patients who have alcoholic liver damage</li>
          <li><b>Kidney Care Program:</b> for patients dealing with Chronic Kidney Disease (Higher Creatinine/ Uric Acid or Low EGFR)</li>
          <li><b>Holistic Health program:</b> To work on weight reduction, vitamin deficiencies, low Hemoglobin, frequent allergies/ infections, and overall health for disease prevention</li>
        </ul>
        `,
  },
];

const Patients = [
  {
    image: rajesh,
    name: "Rajesh Deshpande",
    content:
      "Simple to use, intuitive and valuable app. Helps you manage diabetes effectively. You feel as if there's someone who's caring and guiding you in you diabetes management journey.",
  },
  {
    image: rohini,
    name: "Rohini GV",
    content:
      "Timely monitor suggested by the app..able to check the fluctuations both in bp and sugar levels with vital charts...taking care of the diet and vitals by the care team..immediate reply to your messages...a good app for diabetics",
  },
  {
    image: satheesh,
    name: "Satheesh Ragothaman Rao",
    content:
      "Really good one. All diabetic patients can opt for this. My HbA1C level is maintained healthy with their able guidance.",
  },
];

const SecondList = [
  "24x7 analysis of sugar readings and guidance",
  "24x7 unlimited queries and support on chat",
  "Personalized health plan (Diet & Exercise) based on your current routine with no restrictions/ fasting",
  "Consultation calls with experts:",
  "Live Yoga sessions (3 times a week) and live webinars with MD / DM doctors",
  "Educational content – Meditation, Recipes, Educational videos, Blogs",
  "Savings on lab tests and medicines through trusted partners",
];

const settings2 = {
  dots: true,
  arrows: true,
  autoplay: false,
  autoplaySpeed: 2500,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

function Diabetes() {
  const patientProfile = useSelector(
    (store) => store.login.user?.patientProfile
  );
  const [blogData, setBlogData] = useState(null);
  const [openPayment, setOpenPayment] = useState(false);
  const [paymentResult, setPaymentResult] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const [callBackRequested, setCallBackRequested] = useState(
    localStorage.getItem("callbackRequested") !== null ? true : false
  );
  const history = useHistory();

  useEffect(() => {
    if (!openPayment && paymentResult) {
      history.replace("/tab");
    }
  }, [openPayment]);

  useEffect(() => {
    digitService
      .getBlogsforTherapy("diabetes")
      .then((result) => {
        const res = result.data;

        const shuffle = (array) => array.sort(() => Math.random() - 0.5);
        shuffle(res);
        setBlogData(res ? res.slice(0, 10) : null);
      })
      .catch((err) => {
        console.log("something went wrong while fetching data");
      });

    if (!window.rzploaded) {
      razorPayHelper.loadScript();
    }
  }, []);

  const handleCompletion = (res) => {
    setOpenPayment(true);
    setPaymentResult(res);
  };

  const paymentStarted = () => {
    if (window.rzploaded !== true) {
      return;
    }

    digitService
      .getOrderId(patientProfile?.id, {
        planId: parseInt(process.env.REACT_APP_RAZORPAY_PLAN_ID),
        currency: "INR",
      })
      .then((result) => {
        const res = result.data;
        if (!res) {
          return;
        }

        const options = {
          key: res.rzpKeyId, // Enter the Key ID generated from the Dashboard
          amount: parseFloat(res.amount),
          currency: res.currency,
          name: "Zyla Health",
          description: "Digit customer payment Transaction",
          image: { zylaLogo },
          order_id: res.id,
          handler: (response) => {
            const data = {
              orderCreationId: res.order_id,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            };
            handleCompletion(true);
          },
          prefill: {
            name: patientProfile?.firstname,
            email: patientProfile?.email,
            contact: "" + patientProfile?.countryCode + patientProfile?.phoneno,
          },
          notes: {
            address:
              "LGF, Saket Salcon, Rasvilas, Saket District Centre, Sec 6, Pushp Vihar, New Delhi- 110017",
          },
          theme: {
            color: "#6E5ABB",
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.on("payment.failed", (response) => {
          handleCompletion(false);
        });
        paymentObject.open();
      })
      .catch((err) => {});
  };
  const hideModal = () => {
    setOpenPayment(false);
  };

  const requestCallback = () => {
    digitService
      .requestCallBack()
      .then((res) => {
        localStorage.setItem("callbackRequested", true);
        setRequestSent(true);
      })
      .catch((err) => {});
  };
  const handleRequestSent = () => {
    setRequestSent(false);
    setCallBackRequested(true);
  };

  return (
    <IonPage>
      <IonContent>
        {!requestSent && (
          <div className="diabetes-container">
            <div className="diabetes-container-box-1">
              <div className="diabetes-container-box-1-top">
                <img src={diabetestImg} className="main-img" />
              </div>

              <div className="diabetes-container-box-1-middle">
                <div className="diabetes-container-box-1-top-bottom">
                  <img src={zylaLogo} />
                  <span>Your personal health expert</span>
                </div>
                <div className="diabetes-container-box-1-middle-ddp">
                  <h2>Defeating Diabetes Program</h2>
                  <p>
                    For patients dealing with Type 1 or Type 2 Diabetes, and
                    related complications such as Neuropathy, Nephropathy,
                    Retinopathy, who want to work on HbA1c control, reduction of
                    Diabetes medicine load and protection of organs from
                    Diabetes damage.
                  </p>
                </div>
                <div className="diabetes-container-box-1-middle-list">
                  <h2>
                    The most effective Diabetes management program, with a
                    triple combination approach:
                  </h2>
                  <div className="diabetes-container-box-1-middle-list-conatiner">
                    <ol type="1">
                      <li>
                        Diabetes nutrition and
                        <br /> Ayurveda (Natural home remedies)
                      </li>
                      <li>
                        Physiotherapy and Diabetes <br />
                        Yoga for pancreatic activation
                      </li>
                      <li>
                        Medicine prescriptions &amp;
                        <br /> guidance under senior doctors
                      </li>
                    </ol>
                  </div>
                </div>
                {!callBackRequested ? (
                  <div
                    className="diabetes-container-box-1-button"
                    onClick={() => requestCallback()}
                  >
                    <span>Request Callback</span>
                  </div>
                ) : (
                  <div className="diabetes-container-box-1-button-2">
                    <img src={PACompletedImage} />
                    <span>Call Scheduled</span>
                  </div>
                )}
              </div>
            </div>
            <div className="diabetes-container-box-2">
              <p className="diabetes-container-box-2-heading">
                Meet our experts
              </p>
              <Slider {...settings2} className="doctor-slider-box-2">
                {Doctors.map((ele) => {
                  return (
                    <div className="diabetes-container-box-2-doctor">
                      <div className="diabetes-container-box-2-doctor-img">
                        <img src={ele.image} />
                      </div>
                      <div className="diabetes-container-box-2-doctor-desc">
                        <span className="doctor-desc-name">{ele.name}</span>
                        <span className="doctor-desc-meta">{ele.meta}</span>
                        <span className="doctor-desc-exp">{ele.exp}</span>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
            <div className="diabetes-container-box-4">
              <p className="diabetes-container-box-4-heading">
                24*7 care from a leading medical team
              </p>
              <div className="diabetes-container-box-4-inner">
                <h3 className="diabetes-container-box-4-inner-heading-1">
                  Defeating Diabetes Plan
                </h3>
                <span className="diabetes-container-box-4-inner-heading-2">
                  ₹2900 for 3 months of care
                </span>
                <div className="diabetes-container-box-4-inner-list">
                  {SecondList.slice(0, 4).map((a) => (
                    <Check content={a} />
                  ))}
                  <div className="diabetes-container-box-4-inner-list-list">
                    <ul>
                      <li>
                        <p className="text-gray-900">
                          <b>DAY 1</b> - Detailed health assessment call (30-45
                          mins) for medical history
                        </p>
                      </li>
                      <li>
                        <p className="text-gray-900">
                          <b>DAY 2</b> - App training call
                        </p>
                      </li>
                      <li>
                        <p className="text-gray-900">
                          <b>DAY 7</b> - Progress review call with Clinical
                          Nutritionist
                        </p>
                      </li>
                      <li>
                        <p className="text-gray-900">
                          <b>DAY 21</b> - Physiotherapist call and exercise plan
                        </p>
                      </li>
                      <li>
                        <p className="text-gray-900">
                          <b>Every 15 days</b> - Clinical Nutritionist/
                          Physiotherapist call
                        </p>
                      </li>
                      <li>
                        <p className="text-gray-900">
                          <b>
                            Pre-scheduled Senior Doctor (Endocrinologist)
                            consultation call
                          </b>
                        </p>
                      </li>
                    </ul>
                  </div>
                  {SecondList.slice(4).map((a) => (
                    <Check content={a} />
                  ))}
                  <div
                    className="diabetes-container-box-4-inner-button"
                    onClick={() => paymentStarted()}
                  >
                    <span className="inner-button-span-1">Register Now</span>
                    <span className="inner-button-span-2">
                      Secured by Razorpay
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="diabetes-container-box-5">
              <p className="diabetes-container-box-5-heading">
                See how Zyla program has changed lives
              </p>
              <Slider {...settings2} className="patient-slider-box-5">
                {Patients.map((ele) => {
                  return (
                    <div className="patient-slide-container">
                      <span className="patient-slide-top">{ele.content}</span>
                      <div className="patient-slide-container-bottom">
                        <img src={ele.image} />
                        <span>{ele.name}</span>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
            <div className="diabetes-conatiner-box-6">
              <p className="diabetes-conatiner-box-6-heading">
                Read More on Defeating Diabetes
              </p>
              <div className="diabetes-conatiner-box-6-main">
                {blogData &&
                  blogData.map((r) => (
                    <BlogPreviewAlt
                      title={{ rendered: r.title }}
                      link={r.link}
                      image={r.cover}
                      body={r.content}
                    />
                  ))}
              </div>
            </div>
            <div className="diabetes-container-box-3">
              <h3 className="">Frequently Asked Questions</h3>
              {FAQs.map((f) => (
                <FAQ title={f.title}>{f.content}</FAQ>
              ))}
            </div>
            {openPayment && (
              <IonModal cssClass="diabetes-payment-modal" isOpen={true}>
                <PaymentDialog result={paymentResult} hideModal={hideModal} />
              </IonModal>
            )}
          </div>
        )}
        {requestSent && (
          <RequestCompleted
            handleRequestSent={handleRequestSent}
            isDigit={true}
          />
        )}
      </IonContent>
    </IonPage>
  );
}

export default Diabetes;

function FAQ({ title, children }) {
  const [open, setOpen] = useState(false);

  return (
    <div className="diabetes-faq-container">
      <div className="diabetes-faq-title" onClick={() => setOpen(!open)}>
        <h2>{title}</h2>
        <img src={upArrow} alt="Open Faq" className="down-chevron" />
      </div>
      {open && (
        <p
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "rgba(55, 65, 81, 1)",
            lineHeight: "22px",
          }}
          className="diabetes-faq-content"
          dangerouslySetInnerHTML={{ __html: dompurify.sanitize(children) }}
        ></p>
      )}
    </div>
  );
}

const Check = ({ content }) => (
  <div className="check-container-css">
    <img className="mt-1" src={check} alt="check" />
    <p
      dangerouslySetInnerHTML={{ __html: dompurify.sanitize(content) }}
      className="check-para"
    ></p>
  </div>
);

const BlogPreviewAlt = ({ title, link, image, body }) => (
  <a
    href={link}
    target="_blank"
    className="flex text-black no-underline flex-shrink-0 flex-col items-start w-64 text-left blog-container"
    rel="noopener"
  >
    <img
      width="256"
      height="224"
      src={image}
      alt={title}
      className="w-64 h-56 object-cover rounded-lg"
    />
    <h2 className="font-bold text-lg mt-3 mb-4">{title.rendered}</h2>
    <p className="text-gray-600 text-sm">{body}</p>
  </a>
);
