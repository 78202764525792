import { IonContent, IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import orangeMan from "../../../assets/images/orangeMan.png";
import cloverDental from "../../../assets/images/cloveDental.png";
import dentalBenefit1 from "../../../assets/images/dentalBenefit1.png";
import dentalBenefit2 from "../../../assets/images/dentalBenefit2.png";
import dentalBenefit3 from "../../../assets/images/dentalBenefit3.png";
import stepsAvail1 from "../../../assets/images/stepsAvail1.png";
import stepsAvail2 from "../../../assets/images/stepsAvail2.png";
import stepsAvail3 from "../../../assets/images/stepsAvail3.png";
import BackButton from "../../../components/common/BackButton";
import { useHistory, useLocation } from "react-router";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const DentalCare = (props) => {
  const isPremium = useSelector((store) => store.tab.uiConfig.isPremium);
  const isZylaZen = useSelector((store) => store.tab.uiConfig.isZylaZen);
  const [loading, setLoading] = useState(true);
  const [membership, setMemberShip] = useState(true);
  const [premium, setIsPremium] = useState(true);
  const [zylaZen, setIsZylaZen] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const { state } = location;

    if (state && state.isMember && state.memberData) {
      if (state.memberData.status == 4) {
        setMemberShip(true);
        setIsPremium(true);
        setIsZylaZen(false);
        setShowButton(true);
        setLoading(false);
      } else {
        setMemberShip(false);
        setIsPremium(false);
        setIsZylaZen(false);
        setShowButton(false);
        setLoading(false);
      }
    } else {
      let membership = isPremium ? true : isZylaZen ? true : false;
      setMemberShip(membership);
      setIsPremium(isPremium);
      setIsZylaZen(isZylaZen);
      setLoading(false);
    }
  }, []);

  return (
    <IonPage>
      <IonContent>
        {loading && (
          <CircularProgress className="ion-router-circular-progress"></CircularProgress>
        )}
        {!loading && (
          <div className="dental-page-container">
            <div className="dental-page-header">
              <div className="dental-page-header-left">
                <BackButton history={history}></BackButton>
                <span>Dental Care</span>
              </div>
              {membership && (
                <div className="dental-page-header-right">
                  <span>{zylaZen ? "zyla ZEN" : premium ? "PREMIUM" : ""}</span>
                </div>
              )}
            </div>
            <div className="dental-page-content">
              <div className="dental-page-content-banner-div">
                <div className="dental-page-content-banner-div-left">
                  <img src={orangeMan} alt="bg-img" />
                </div>
                <div className="dental-page-content-banner-div-right">
                  <div className="dental-page-content-banner-div-right-top">
                    <span className="dental-page-content-banner-div-right-top-span-1">
                      Looking for a dentist?
                    </span>
                    <span className="dental-page-content-banner-div-right-top-span-2">
                      Book your
                    </span>
                    <span className="dental-page-content-banner-div-right-top-span-3">
                      appointment with
                    </span>
                  </div>
                  <div className="dental-page-content-banner-div-right-bottom">
                    <img src={cloverDental} alt="bg-img" />
                    <div className="dental-page-content-banner-div-right-bottom-right">
                      <span>India’s largest</span>
                      <span>dental chain.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dental-page-benefits">
                <span className="dental-page-benefits-heading">
                  Benefits for zyla users:
                </span>
                <div className="dental-page-benefit-list">
                  <img src={dentalBenefit1} alt="icon" />
                  <span>
                    Unlimited consultations at Clove Dental Clinics without any
                    extra cost
                  </span>
                </div>
                <div className="dental-page-benefit-list">
                  <img src={dentalBenefit2} alt="icon" />
                  <span>
                    No extra charges for Digital X-Rays required during
                    treatment
                  </span>
                </div>
                <div className="dental-page-benefit-list">
                  <img src={dentalBenefit3} alt="icon" />
                  <span>
                    Flat 10% discount on following procedures: Fillings,
                    Dentures, RCT, Crowns, Bridges, Extractions, Implants &
                    Braces
                  </span>
                </div>
              </div>
              {membership && (
                <div className="dental-page-benefits">
                  <span className="dental-page-benefits-heading">
                    Steps to avail:
                  </span>
                  <div className="dental-page-benefit-list">
                    <img src={stepsAvail1} alt="icon" />
                    <span>
                      Visit
                      <a
                        className="clover-dental-link"
                        href="https://clovedental.in/dentist-near-me/"
                        target="_blank"
                      >
                        clovedental.in
                      </a>
                      or click on avail offer to book your dental appointment
                    </span>
                  </div>
                  <div className="dental-page-benefit-list">
                    <img src={stepsAvail2} alt="icon" />
                    <span>
                      {`Inform that you are a zyla ${
                        zylaZen ? "Zen" : "Premium"
                      } member during the booking
                    confirmation call`}
                    </span>
                  </div>
                  <div className="dental-page-benefit-list">
                    <img src={stepsAvail3} alt="icon" />
                    <span>
                      Show this screen for verification,
                      <b>before starting treatment</b> on your visit to the
                      clinic
                    </span>
                  </div>
                </div>
              )}
            </div>
            {showButton && (
              <div
                className="dental-page-button"
                onClick={() => {
                  if (membership) {
                    window.open(
                      `https://clovedental.in/dentist-near-me/`,
                      "_blank"
                    );
                  } else {
                    history.replace("/premium");
                  }
                }}
              >
                {membership
                  ? "Avail Offer"
                  : "Unlock benefits with zyla Premium"}
              </div>
            )}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default DentalCare;
