import APIConfig from "./APIConfig";
import axiosClient from "../app/axiosConfig";

class DeviceService {
  addDevice(deviceDetail, user) {
    let pid = `${user.patientProfile.id}`;
    deviceDetail.patientId = pid;
    deviceDetail.updatedBy = `${user.id}`;
    deviceDetail.createdBy = `${user.id}`;
    const url = APIConfig.device.new.format(pid);
    return axiosClient.post(url, deviceDetail);
  }

  getLatestReading(deviceId, user) {
    let pid = `${user.patientProfile.id}`;
    const url = APIConfig.device.getLatest.format(pid, deviceId);
    return axiosClient.get(url);
  }
  saveReading(readings, deviceId, user) {
    let pid = `${user.patientProfile.id}`;
    let req = {
      readings: readings,
      deviceId: deviceId,
      patientId: pid,
    };

    const url = APIConfig.device.saveReadings.format(pid);
    return axiosClient.post(url, req);
  }
}

let deviceService = new DeviceService();
export default deviceService;
