import BPIcon from "../../assets/icons/hp-bp.png";
import BloodSugarIcon from "../../assets/icons/hp-blood-sugar.png";
import PulseIcon from "../../assets/icons/hp-pulse.png";
import WaistlineIcon from "../../assets/icons/hp-waistline.png";
import WeightIcon from "../../assets/icons/hp-weight.png";
import tempIcon from "../../assets/icons/vitalStatTemp.png";
import spo2 from "../../assets/icons/vitalStatSpo2.png";
import moment from "moment";
export const vitalParams = [
  { name: "Blood Sugar ", icon: BloodSugarIcon, unit: "mg/dL", id: 1 },
  { name: "Blood Pressure", icon: BPIcon, unit: "mm Hg", id: 7 },
  { name: "Pulse", icon: PulseIcon, unit: "BPM", id: 8 },
  { name: "Weight", icon: WeightIcon, unit: "kg", id: 5 },
  { name: "Waistline", icon: WaistlineIcon, unit: "inches", id: 6 },
  { name: "SPO2", icon: spo2, unit: "%", id: 9 },
  { name: "Temperature", icon: tempIcon, unit: "°F", id: 10 },
];

export const processSingleDayData = (data, stDate, enDate) => {
  let datainBed = [];
  let dataAsleep = [];
  for (let i = 0; i < data.length; i++) {
    let startDate = data[i].startDate;
    let endDate = data[i].endDate;
    if (
      (moment(startDate).isBefore(stDate) && moment(endDate).isAfter(enDate)) ||
      (moment(startDate).isBefore(stDate) &&
        moment(endDate).isBefore(stDate)) ||
      (moment(startDate).isAfter(enDate) && moment(endDate).isAfter(enDate))
    ) {
      continue;
    }
    if (
      moment(startDate).isSameOrBefore(stDate) &&
      moment(endDate).isAfter(stDate) &&
      moment(endDate).isBefore(enDate)
    ) {
      let myData = { ...data[i] };
      myData.startDate = stDate;
      if (data[i].value === 0) {
        datainBed.push({ ...myData });
      } else {
        dataAsleep.push({ ...myData });
      }
    } else if (
      moment(startDate).isSameOrAfter(stDate) &&
      moment(endDate).isSameOrBefore(enDate)
    ) {
      if (data[i].value === 0) {
        datainBed.push({ ...data[i] });
      } else {
        dataAsleep.push({ ...data[i] });
      }
    } else if (
      moment(startDate).isSameOrAfter(stDate) &&
      moment(endDate).isSameOrAfter(enDate)
    ) {
      let myData = { ...data[i] };
      myData.endDate = enDate;
      if (data[i].value === 0) {
        datainBed.push({ ...myData });
      } else {
        dataAsleep.push({ ...myData });
      }
    } else {
    }
  }
  if (dataAsleep.length > 0) {
    return dataAsleep;
  }

  return datainBed;
};

export const getExactSLeepingTime = (data) => {
  data.sort((a, b) => a.startDate.localeCompare(b.startDate));

  if (data.length === 0) {
    return 0;
  }
  let startDate = data[0].startDate;
  let endDate = data[0].endDate;
  let totalTime = 0;
  for (let i = 1; i < data.length; i++) {
    if (moment(data[i].startDate).isSameOrBefore(endDate)) {
      if (moment(data[i].endDate).isAfter(endDate)) {
        endDate = data[i].endDate;
      }
    } else {
      const now = moment(startDate);
      const expiration = moment(endDate);

      // get the difference between the moments
      let diff = expiration.diff(now);

      let mins = diff / (1000 * 60);

      totalTime = totalTime + mins;
      startDate = data[i].startDate;
      endDate = data[i].endDate;
    }
  }
  const now = moment(startDate);
  const expiration = moment(endDate);

  // get the difference between the moments
  let diff = expiration.diff(now);

  let mins = diff / (1000 * 60);

  totalTime = totalTime + mins;

  return totalTime;
};

export const formatSleepData = (data) => {
  if (data && data.length > 0) {
    let newData = data.map((d) => {
      if (d.value === "sleep.inBed") {
        return { ...d, value: 0 };
      } else {
        return { ...d, value: 1 };
      }
    });
    return newData;
  }
  return data;
};
