import moment from "moment";
export const validateResponse = (groupModels) => {
  let checkArray = [];
  let returnCheck = false;
  for (let i = 0; i < groupModels.length; i++) {
    let params = groupModels[i].params;
    for (let j = 0; j < params.length; j++) {
      checkArray.push(params[j].value);
    }
  }

  for (let i = 0; i < checkArray.length; i++) {
    if (checkArray[i] !== "" && checkArray[i] !== null) {
      returnCheck = true;
      break;
    }
  }
  return returnCheck;
};

export const isNoVitalRequiredFunc = (groupModels, myDate) => {
  try {
    let isNoVitalRequired = false;

    let yesterday = moment().subtract(1, "day").date();
    let currentDate = moment().date();
    let selectedDate = moment(myDate, "Do MMMM").date();
    if (groupModels && groupModels.length > 0) {
      let paramsFound = false;
      for (let i in groupModels) {
        let groupModel = groupModels[i];
        if (selectedDate === currentDate) {
          if (groupModel.params.length > 0) {
            paramsFound = true;
            break;
          }
        } else {
          for (let j in groupModel.params) {
            let param = groupModel.params[j];
            if (selectedDate === yesterday) {
              paramsFound = true;
              break;
            } else {
              if (param.isRecommended) {
                paramsFound = true;
                break;
              }
            }
          }
        }
      }

      if (!paramsFound) {
        isNoVitalRequired = true;
      }
    }

    return isNoVitalRequired;
  } catch (err) {
    return true;
  }
};
