import styled from "@emotion/styled";
import dompurify from "dompurify";
import TimeMessage from "./time";

const Wrapper = styled.div`
  display: inline-flex;
  border: none;
  outline: none;
`;
export default function TextChatMessage({ message, type, className }) {
  const text = message.content.message.text;

  let urls = extract_urls(text);
  let textWithLink = urlify(text);

  return (
    <>
      {text && (
        <Wrapper>
          <div className={urls !== undefined ? "link-parent" : "chat-parent"}>
            {/* {urls !== undefined ? (
                              <LinkPreview urls={{ urls }}></LinkPreview>
                            ) : null} */}
            {urls !== undefined ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(textWithLink),
                }}
                className={
                  !message.mine
                    ? "bor message-text font-normal link-container"
                    : "font-normal message-text link-container"
                }
              ></div>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(textWithLink),
                }}
                className={
                  !message.mine
                    ? "bor message-text font-normal"
                    : "font-normal message-text"
                }
              ></div>
            )}

            <TimeMessage message={message} />
          </div>
        </Wrapper>
      )}
    </>
  );
}

const urlify = (text) => {
  text = text + "";
  //let aTagRegex = /<a[\s]+([^>]+)>((?:.(?!\<\/a\>))*.)<\/a>/g;

  var urlRegex = /(https?:\/\/[^\s]+)/g;
  var div = document.createElement("div");
  div.innerHTML = text;
  let newText = div.textContent || div.innerText || "";

  if (urlRegex.test(newText)) {
    newText = newText.replace(urlRegex, function (url) {
      return `<a target="_blank" href="${url}"> ${url}</a>`;
    });
    text = newText;
  }
  return text;
};

const extract_urls = (text) => {
  let urls = text.match(/(https?:\/\/[^\s]+)/g);
  if (urls) {
    return urls;
  }
  return undefined;
};
