import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import vitalServices from "../../services/VitalService";

const initialState = {
  vitalParams: [],
  vitalChartToday: [],
  dataUpdated: false,
  ui: {
    average: 0,
    normalReadingPercentage: 0,
    showChart: true,
    loading: true,
    paramGroupId: 0,
    error: false,
    errroMsg: "",
    selectedParamUnit: "",
    initialselectedEndTime: moment.now(),
    initialselectedStartTime: moment().subtract(13, "days"),
    selectedStartTime: moment().subtract(13, "days"),
    selectedEndTime: moment.now(),
    paramGroups: [],
  },
};

export const getVitalsParams = createAsyncThunk(
  "vitalsChart/params",
  async (data, { rejectWithValue }) => {
    try {
      const { patientId } = data;
      const response = await vitalServices.getVitalParams(patientId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const vitalsChartSlice = createSlice({
  name: "vitalsChartSlice",
  initialState: initialState,
  reducers: {
    updateAverageAndPercent: (state, action) => {
      state.ui = {
        ...state.ui,
        average: action.payload.average,
        normalReadingPercentage: action.payload.normalReadingPercentage,
      };
    },
    toggleShowChart: (state, action) => {
      state.ui = {
        ...state.ui,
        showChart: action.payload.value,
      };
    },
    tabChangeVitalChartReducer: (state, action) => {
      const { pgId, selectedParamUnit } = action.payload;

      state.ui = {
        ...state.ui,
        paramGroupId: pgId,
        selectedParamUnit: selectedParamUnit,
        selectedStartTime: state.ui.initialselectedStartTime,
        selectedEndTime: moment.now(),
      };
    },
    updateVitalChartTime: (state, action) => {
      state.ui = {
        ...state.ui,
        selectedStartTime: action.payload.selectedStartTime,
        selectedEndTime: action.payload.selectedEndTime,
      };
    },
    updateVitalChartEndTime: (state, action) => {
      state.ui = {
        ...state.ui,
        selectedEndTime: action.payload.selectedEndTime,
      };
    },
    updateVitalChartError: (state, action) => {
      const { value, msg } = action.payload;
      state.ui = {
        ...state.ui,
        error: value,
        errorMsg: msg ? msg : "",
      };
    },
    toggleDataUpdated: (state, action) => {
      state.dataUpdated = action.payload;
    },
  },
  extraReducers: {
    [getVitalsParams.pending]: (state, action) => {
      state.ui.loading = true;
    },
    [getVitalsParams.fulfilled]: (state, action) => {
      const response = action.payload.data;
      return {
        ...state,
        vitalParams: response,
        ui: {
          ...state.ui,
          paramGroupId: action.meta.arg.groupId || response[0].id,
          loading: false,
          showChart: true,
        },
      };
    },
    [getVitalsParams.rejected]: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: false,
          error: true,
          errorMsg: "Error while fetching data.",
        },
      };
    },
  },
});

export const {
  updateAverageAndPercent,
  toggleShowChart,
  tabChangeVitalChartReducer,
  updateVitalChartTime,
  updateVitalChartEndTime,
  updateVitalChartError,
  toggleDataUpdated,
} = vitalsChartSlice.actions;
export default vitalsChartSlice.reducer;
