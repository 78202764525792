import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import APIConfig from "../../services/APIConfig";
import userService from "../../services/UserService";
import documentService from "../../services/DocumentService";
import axios from "axios";
import moment from "moment";
import clevertapLogger from "../../clevertapLogger";
const initialState = {
  token: undefined,
  user: {
    patientProfile:{}
  },

  introImages: [],
  loggedIn: false,
  isFirstLogin: false,
  registered: false,
  savedDOB: "",
  ui: {
    showOtp: false,
    error: undefined,
    errMsg: "Something went wrong.",
    isErrorPositive: false,
    loading: false,
    loadingImage: false,
    startTimer: false,
    notEligible: false,
    showNoUserAlert: false,
  },
};

export const getOTP = createAsyncThunk(
  "login/getOTP",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userService.getOTP(
        data.phoneNo,
        data.countryCode,
        data.dob
      );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const verifyOTP = createAsyncThunk(
  "login/verifyOTP",
  async (data, { rejectWithValue }) => {
    try {
      let payload = {
        user: { ...data.user },
      };
      const response = userService.verifyOtpNew(payload);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchIntroImages = createAsyncThunk(
  "login/introImages",
  async (data, { rejectWithValue }) => {
    try {
      // const response = await UserService.getLoginImages();
      const response = await axios.get(APIConfig.freemium.loginImgs, {
        headers: {
          origin: "*",
        },
      });

      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getUserProfile = createAsyncThunk(
  "login/getUserProfile",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();

      const response = await userService.getLatestprofile(
        state.login.user.patientProfile.id
      );
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    backToPhoneForm: (state, action) => {
      state.ui.showOtp = false;
    },
    updateErrorState: (state, action) => {
      const { data, msg, isErrorPositive } = action.payload;

      state.ui = {
        ...state.ui,
        error: data,
        loading: false,
        errMsg: msg ? msg : "Something went wrong.",

        isErrorPositive: isErrorPositive ? isErrorPositive : false,
      };
    },
    updateToken: (state, action) => {
      state.token = action.payload;
    },
    updateUser: (state, action) => {
      state.user = action.payload;
    },
    updateProfileImage: (state, action) => {
      let patientProfile = {
        ...state.user.patientProfile,
        profileImage: documentService._checkHttps(action.payload),
      };

      return {
        ...state,
        user: {
          ...state.user,
          patientProfile: { ...patientProfile },
        },
      };
    },
    updatePatientProfile: (state, action) => {
      state.user = {
        ...state.user,
        patientProfile: {
          ...state.user.patientProfile,
          ...action.payload,
        },
      };
    },
    updateUserFromAutoLogin: (state, action) => {
      const user = action.payload.user;
      const token = action.payload.token;
      return {
        ...state,
        user: user,
        token: token,
        loggedIn: true,
        registered: user.patientProfile ? true : false,
      };
    },
    updateLoginAndRegister: (state, action) => {
      return {
        ...state,
        loggedIn: false,
        registered: false,
      };
    },
    updateIsRegistered: (state, action) => {
      return {
        ...state,
        registered: true,
      };
    },
    toggleShowOTP: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          showOtp: action.payload,
        },
      };
    },
    toggleNoUserAlert: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          showNoUserAlert: false,
        },
      };
    },
    toggleFirstLogin: (state, action) => {
      return {
        ...state,
        isFirstLogin: false,
      };
    },
    toggleNotEligible: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          notEligible: false,
        },
      };
    },
  },
  extraReducers: {
    [getOTP.pending]: (state, action) => {
      state.ui = {
        ...state.ui,
        startTimer: false,
        notEligible: false,
        error: undefined,
        loading: true,
      };
    },
    [getOTP.fulfilled]: (state, action) => {
      const isResend = action.meta.arg.isResend;

      clevertapLogger.push("request_OTP", {
         ph_no: action.payload.data.phoneNo,
         DOB:action.payload.data.dob,
         timestamp:Date.now(),
         MLI_API_Response:action.payload,
      },
        state.user)
      return {
        ...state,

        ...action.payload.data,
        isFirstLogin: action.payload.data?.firstTime,

        ui: {
          ...state.ui,
          startTimer: true,
          notEligible: false,
          showOtp: true,
          error: isResend ? true : undefined,
          errMsg: isResend
            ? "A new OTP has been sent to your mobile number."
            : "",
          isErrorPositive: isResend ? true : false,
          loading: false,
        },
      };
    },
    [getOTP.rejected]: (state, action) => {
      let notEligible = true;
      let err = false;
      let errMsg = "";
      let showNoUserAlert = false;

      if (action.payload?.response?.status === 406) {
        notEligible = false;
        showNoUserAlert = true;
      }
      state.ui = {
        ...state.ui,
        notEligible: notEligible,
        error: err,
        errMsg: errMsg,
        loading: false,
        showNoUserAlert: showNoUserAlert,
      };
    },
    [fetchIntroImages.pending]: (state, action) => {
      state.carouselLoading = true;
    },
    [fetchIntroImages.fulfilled]: (state, action) => {
      state.introImages = action.payload.data.screens;
      state.carouselLoading = false;
    },
    [fetchIntroImages.rejected]: (state, action) => {
      state = {
        ...state,
        carouselLoading: false,
      };
    },
    [verifyOTP.pending]: (state, action) => {
      state.ui.loading = true;
    },
    [verifyOTP.fulfilled]: (state, action) => {
      const d = action.payload.data;

      return {
        ...state,
        user: d.user,
        token: d.token,
        loggedIn: true,
        registered: d.user.patientProfile ? true : false,
        savedDOB: moment(action.meta.arg.dob, "Do MMMM YYYY")
          .format("YYYY-MM-DD")
          .toUpperCase(),
        ui: {
          ...state.ui,
          loading: false,
        },
      };
    },
    [verifyOTP.rejected]: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          error: true,
          errMsg: "Please enter a valid otp",
          loading: false,
        },
      };
    },
    [getUserProfile.fulfilled]: (state, action) => {
      const profile = action.payload.data.patientProfile;
      state.user.patientProfile = {
        ...profile,
        profileImage: documentService._checkHttps(
          profile?.profileImage + `?v=${Date.now()}`
        ),
      };
    },
  },
});
export const {
  backToPhoneForm,
  updateErrorState,
  updateToken,
  updateUser,
  updateProfileImage,
  updatePatientProfile,
  updateUserFromAutoLogin,
  updateLoginAndRegister,
  updateIsRegistered,
  toggleShowOTP,
  toggleNoUserAlert,
  toggleNotEligible,

  toggleFirstLogin,
} = loginSlice.actions;
export default loginSlice.reducer;
