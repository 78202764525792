import * as Sentry from "@sentry/react";
export default class IndexedDB {
  constructor() {
    this.chatDBName = "chatDBv2";
  }

  createLinkPreviewDB = () => {
    let idb = indexedDB.open("linkPreviewDB", 1);
    idb.onsuccess = (event) => {};
    idb.onerror = (event) => {};
    idb.onupgradeneeded = (event) => {
      let db = event.target.result;
      db.createObjectStore("links", { keyPath: "link" });
    };
  };

  setLinkInfo = (data) => {
    let idb = indexedDB.open("linkPreviewDB", 1);
    idb.onsuccess = (event) => {
      let db = event.target.result;
      let transaction = db.transaction("links", "readwrite");
      let store = transaction.objectStore("links");
      store.add(data);
    };
  };

  getLinkInfo = (key) => {
    return new Promise((resolve) => {
      let idb = indexedDB.open("linkPreviewDB", 1);
      idb.onsuccess = (event) => {
        let db = event.target.result;
        let messageTransaction = db.transaction("links", "readwrite");
        let linkStore = messageTransaction.objectStore("links");
        linkStore.get(key).onsuccess = (event) => {
          resolve(event.target.result);
        };
      };
    });
  };

  createAssessmentDB = () => {
    let idb = indexedDB.open("assesmentDB", 1);
    idb.onsuccess = (event) => {};
    idb.onerror = (event) => {};
    idb.onupgradeneeded = (event) => {
      let db = event.target.result;
      db.createObjectStore("assessment", { keyPath: "name" });
    };
  };

  createStorageService = (name) => {
    // try {
    //   indexedDB.deleteDatabase(`${name}Db`);
    // } catch (err) {
    //   Sentry.captureException(err);
    // }
    let idb = indexedDB.open(`${name}Db`, 1);
    idb.onsuccess = (event) => {};
    idb.onerror = (event) => {};
    idb.onupgradeneeded = (event) => {
      let db = event.target.result;
      db.createObjectStore(`${name}Store`, { keyPath: `${name}Value` });
    };
  };

  setStorageService = (name, value) => {
    let payload = {
      name,
      value,
    };
    try {
      indexedDB.open(`${name}Db`);
    } catch (err) {
      console.log("err", err);

      this.createStorageService(name);
    }
    let idb = indexedDB.open(`${name}Db`, 1);
    idb.onsuccess = (event) => {
      let db = event.target.result;
      let transaction = db.transaction(`${name}Store`, "readwrite");
      let store = transaction.objectStore(`${name}Store`);
      store.add(payload);
    };
  };
  getFromStorageService = (name) => {
    try {
      // return new Promise((resolve) => {
      let idb = indexedDB.open(`${name}Db`, 1);
      idb.onsuccess = (event) => {
        let db = event.target.result;

        let messageTransaction = db.transaction(`${name}Store`, "readwrite");
        let messageStore = messageTransaction.objectStore(`${name}Store`);
        messageStore.get(name).onsuccess = (event) => {};
      };
      // });
    } catch {
      this.createStorageService(name);
    }
  };

  removeFromStorage = (name) => {
    try {
      indexedDB.deleteDatabase(`${name}Db`);
    } catch (err) {
      Sentry.captureException(err);
    }
  };
  createHealthGoalDB = () => {
    try {
      indexedDB.deleteDatabase("healthGoalDB");
    } catch (err) {
      Sentry.captureException(err);
    }
    let idb = indexedDB.open("healthGoalDB2", 1);
    idb.onsuccess = (event) => {};
    idb.onerror = (event) => {};
    idb.onupgradeneeded = (event) => {
      let db = event.target.result;
      db.createObjectStore("patientGoal", { keyPath: "goalType" });
    };
  };

  setHealthGoal = (goalType, data) => {
    let payload = {
      goalType,
      data,
    };
    let idb = indexedDB.open("healthGoalDB2", 1);
    idb.onsuccess = (event) => {
      let db = event.target.result;
      let transaction = db.transaction("patientGoal", "readwrite");
      let store = transaction.objectStore("patientGoal");
      store.add(payload);
    };
  };
  updateHealthGoal = (goalType, data) => {
    let payload = {
      goalType,
      data,
    };
    let idb = indexedDB.open("healthGoalDB2", 1);
    idb.onsuccess = (event) => {
      let db = event.target.result;
      let transaction = db.transaction("patientGoal", "readwrite");
      let store = transaction.objectStore("patientGoal");
      store.put(payload);
    };
  };

  getHealthGoal = (key) => {
    return new Promise((resolve) => {
      let idb = indexedDB.open("healthGoalDB2", 1);
      idb.onsuccess = (event) => {
        let db = event.target.result;
        let messageTransaction = db.transaction("patientGoal", "readwrite");
        let messageStore = messageTransaction.objectStore("patientGoal");
        messageStore.get(key).onsuccess = (event) => {
          resolve(event.target.result);
        };
      };
    });
  };

  createHealthPlanDB = () => {
    let idb = indexedDB.open("healthPlanDB", 1);
    idb.onsuccess = (event) => {};
    idb.onerror = (event) => {};
    idb.onupgradeneeded = (event) => {
      let db = event.target.result;
      db.createObjectStore("patientPlan", { keyPath: "name" });
    };
  };

  addHealthPlan = (data, name) => {
    let payload = {
      name,
      data,
    };
    let assesmentDB = indexedDB.open("healthPlanDB", 1);
    assesmentDB.onsuccess = (event) => {
      let db = event.target.result;
      let assesmentTransaction = db.transaction("patientPlan", "readwrite");
      let assesmentStore = assesmentTransaction.objectStore("patientPlan");
      assesmentStore.add(payload);
    };
  };

  updateHealthPlan = (data, name) => {
    let payload = {
      name,
      data,
    };
    let assesmentDB = indexedDB.open("healthPlanDB", 1);
    assesmentDB.onsuccess = (event) => {
      let db = event.target.result;
      let assesmentTransaction = db.transaction("patientPlan", "readwrite");
      let assesmentStore = assesmentTransaction.objectStore("patientPlan");
      assesmentStore.put(payload);
    };
  };

  getHealthPlan = (key) => {
    return new Promise((resolve) => {
      let idb = indexedDB.open("healthPlanDB", 1);
      idb.onsuccess = (event) => {
        let db = event.target.result;
        let messageTransaction = db.transaction("patientPlan", "readwrite");
        let messageStore = messageTransaction.objectStore("patientPlan");
        messageStore.get(key).onsuccess = (event) => {
          resolve(event.target.result);
        };
      };
    });
  };

  addAssesment = (name, assessment) => {
    let payload = {
      name: name,
      data: assessment,
    };
    let assesmentDB = indexedDB.open("assesmentDB", 1);
    assesmentDB.onsuccess = (event) => {
      let db = event.target.result;
      let assesmentTransaction = db.transaction("assessment", "readwrite");
      let assesmentStore = assesmentTransaction.objectStore("assessment");
      assesmentStore.add(payload);
    };
  };

  updateCurrentAssessment = (name, assessment) => {
    let payload = {
      name: name,
      data: assessment,
    };
    let assesmentDB = indexedDB.open("assesmentDB", 1);
    assesmentDB.onsuccess = (event) => {
      let db = event.target.result;
      let assesmentTransaction = db.transaction("assessment", "readwrite");
      let assesmentStore = assesmentTransaction.objectStore("assessment");
      assesmentStore.put(payload);
    };
  };

  getPA = () => {
    return new Promise((resolve) => {
      let idb = indexedDB.open("assesmentDB", 1);
      idb.onsuccess = (event) => {
        let db = event.target.result;
        let messageTransaction = db.transaction("assessment", "readwrite");
        let messageStore = messageTransaction.objectStore("assessment");
        messageStore.getAll().onsuccess = (event) => {
          resolve(event.target.result);
        };
      };
    });
  };
  deleteOldDB = () => {
    indexedDB.deleteDatabase("chatDB");
  };
  createIndexDB = () => {
    try {
      indexedDB.deleteDatabase("chatDB");
    } catch (err) {
      Sentry.captureException(err);
    }

    let idb = indexedDB.open(this.chatDBName, 1);
    idb.onsuccess = (event) => {};
    idb.onerror = (event) => {
      indexedDB.deleteDatabase(this.chatDBName);
      window.location.reload(true);
    };
    idb.onupgradeneeded = (event) => {
      let db = event.target.result;
      try {
        db.createObjectStore("messages", {
          keyPath: "id",
        });
      } catch (err) {}

      try {
        db.createObjectStore("pendingMessages", {
          keyPath: "pid",
        });
      } catch (err) {}

      try {
        db.createObjectStore("callNotes", {
          keyPath: "id",
        });
      } catch (err) {}
      try {
        db.createObjectStore("doctorNotes", {
          keyPath: "id",
        });
      } catch (err) {}

      try {
        db.createObjectStore("meditation", {
          keyPath: "id",
        });
      } catch (err) {}
      try {
        db.createObjectStore("infoCard", {
          keyPath: "id",
        });
      } catch (err) {}
    };
  };

  storeCardData = (message, table) => {
    let idb = indexedDB.open(this.chatDBName, 1);
    idb.onsuccess = (event) => {
      let db = event.target.result;
      let messageTransaction = db.transaction(table, "readwrite");
      let messageStore = messageTransaction.objectStore(table);
      messageStore.add(message);
    };
  };

  getCardData = (id, table) => {
    return new Promise((resolve) => {
      let idb = indexedDB.open(this.chatDBName, 1);
      idb.onsuccess = (event) => {
        let db = event.target.result;
        let messageTransaction = db.transaction(table, "readwrite");
        let messageStore = messageTransaction.objectStore(table);
        messageStore.get(id).onsuccess = (event) => {
          resolve(event.target.result);
        };
      };
    });
  };
  getAllSentMessages = () => {
    return new Promise((resolve, reject) => {
      let idb = indexedDB.open(this.chatDBName, 1);
      idb.onsuccess = (event) => {
        let db = event.target.result;
        let messageTransaction = db.transaction(["messages"], "readwrite");
        let messageStore = messageTransaction.objectStore("messages");
        messageStore.getAll().onsuccess = (event) => {
          let currentMessages = event.target.result;
          resolve(currentMessages);
        };
      };
    });
  };
  getAllMessagesFromIDB = () => {
    return new Promise((resolve, reject) => {
      let idb = indexedDB.open(this.chatDBName, 1);
      idb.onsuccess = (event) => {
        let db = event.target.result;
        let messageTransaction = db.transaction(["messages"], "readwrite");
        let messageStore = messageTransaction.objectStore("messages");
        messageStore.getAll().onsuccess = (event) => {
          // resolve(event.target.result)
          let currentMessages = event.target.result;
          let transaction = db.transaction("pendingMessages", "readwrite");
          let pendingMessageStore = transaction.objectStore("pendingMessages");
          pendingMessageStore.getAll().onsuccess = (e) => {
            let pendingMessages = e.target.result;
            resolve(currentMessages.concat(pendingMessages));
          };
        };
      };
    });
  };

  getLastMessageIndex = () => {
    //gives latest message index present in IDB
    return new Promise((resolve, reject) => {
      let idb = indexedDB.open(this.chatDBName, 1);
      idb.onsuccess = (event) => {
        let db = event.target.result;
        let messageTransaction = db.transaction("messages", "readwrite");
        let messageStore = messageTransaction.objectStore("messages");
        messageStore.getAll().onsuccess = (event) => {
          let list = event.target.result;

          if (list.length === 0) {
            resolve(0);
          } else {
            let currentHighest = list[0].id;
            list.forEach((message) => {
              if (message.id > currentHighest) {
                currentHighest = message.id;
              }
            });
            resolve(currentHighest);
          }
        };
      };
    });
  };

  updateSatusOfMessageFromPendingToSent = (message) => {
    const pid = message.content.message.id;
    let idb = indexedDB.open(this.chatDBName, 1);
    idb.onsuccess = (event) => {
      let db = event.target.result;
      let messageTransaction = db.transaction("pendingMessages", "readwrite");
      let messageStore = messageTransaction.objectStore("pendingMessages");
      messageStore.delete(pid).onsuccess = (event) => {
        //on delete success
        let newMessage = {
          ...message,
        };
        delete newMessage.isTransactionPending;
        let transaction = db.transaction(["messages"], "readwrite");
        let store = transaction.objectStore("messages");
        store.add(newMessage);
      };
    };
  };

  getAllMessageCount = () => {
    return new Promise((resolve) => {
      let idb = indexedDB.open(this.chatDBName, 1);
      idb.onsuccess = (event) => {
        let db = event.target.result;
        let messageTransaction = db.transaction(["messages"], "readwrite");
        let messageStore = messageTransaction.objectStore("messages");
        messageStore.count().onsuccess = (event) => {
          resolve(event.target.result);
        };
      };
    });
  };
  clearMessageIndexedDB = () => {
    return new Promise((resolve) => {
      let idb = indexedDB.open(this.chatDBName, 1);
      idb.onsuccess = (event) => {
        let db = event.target.result;
        let messageTransaction = db.transaction(["messages"], "readwrite");
        let messageStore = messageTransaction.objectStore("messages");

        let msgClearRequest = messageStore.clear();
        msgClearRequest.onsuccess = (event) => {
          resolve(event);
        };
        msgClearRequest.onerror = (event) => {
          resolve(event);
        };
      };
    });
  };

  addMultipleMessagesToHistory = (messagesList) => {
    let idb = indexedDB.open(this.chatDBName, 1);
    idb.onsuccess = (event) => {
      let db = event.target.result;
      let messageTransaction = db.transaction("messages", "readwrite");
      let messageStore = messageTransaction.objectStore("messages");

      messagesList.forEach((messageReceived) => {
        if (messageReceived.id) {
          messageStore.add(messageReceived);
        }
      });
    };
  };

  addUnSentMessage = (message) => {
    let idb = indexedDB.open(this.chatDBName, 1);
    idb.onsuccess = (event) => {
      let db = event.target.result;
      let messageTransaction = db.transaction("pendingMessages", "readwrite");
      let messageStore = messageTransaction.objectStore("pendingMessages");
      messageStore.add(message);
    };
  };

  getAllUnsentMessageKeys = () => {
    return new Promise((resolve) => {
      let idb = indexedDB.open(this.chatDBName, 1);
      idb.onsuccess = (event) => {
        let db = event.target.result;
        let messageTransaction = db.transaction("pendingMessages", "readwrite");
        let messageStore = messageTransaction.objectStore("pendingMessages");
        messageStore.getAllKeys().onsuccess = (event) => {
          resolve(event.target.result);
        };
      };
    });
  };

  getAllUnsentMessages = () => {
    return new Promise((resolve) => {
      let idb = indexedDB.open(this.chatDBName, 1);
      idb.onsuccess = (event) => {
        let db = event.target.result;
        let messageTransaction = db.transaction("pendingMessages", "readwrite");
        let messageStore = messageTransaction.objectStore("pendingMessages");
        messageStore.getAll().onsuccess = (event) => {
          resolve(event.target.result);
        };
      };
    });
  };

  clearAll() {
    return new Promise((resolve) => {
      try {
        indexedDB.deleteDatabase(this.chatDBName);
        indexedDB.deleteDatabase("assesmentDB");
        indexedDB.deleteDatabase("healthGoalDB2");
        indexedDB.deleteDatabase("healthPlanDB");
        indexedDB.deleteDatabase("fcm_token_details_db");
        indexedDB.deleteDatabase("fcm_vapid_details_db");
        indexedDB.deleteDatabase("firebase-installations-database");
        // delete old DB in last, so that if any exception occured, it will stop other deletion of dbs

        indexedDB.deleteDatabase("chatDB");
        indexedDB.deleteDatabase("healthGoalDB");
        indexedDB.deleteDatabase("linkpreview");
        indexedDB.deleteDatabase("event-logger");
      } catch (e) {}
      resolve();
    });
  }
}
