import { PushNotifications } from "@capacitor/push-notifications";
import NotificationService from "./services/NotificationService";
import AuthenticationService from "./services/AuthenticationService";
import firebase from "firebase/app";

import "firebase/messaging";
import StorageService from "./services/StorageService";
import { Device } from "@capacitor/device";
import { LocalNotifications } from "@capacitor/local-notifications";

class ZylaNotification {
  register(handleNotificationTap, handleForegroundNotif, user) {
  

    this.notificationService = new NotificationService();
    this.authenticationService = new AuthenticationService();
    this.storageService = new StorageService();
    Device.getInfo().then((r) => {
      const platform = r.platform;

      if (platform === "android" || platform === "ios") {
        // ios and android push notification register

        try {
          PushNotifications.addListener("registration", (token) => {
            this.notificationService.updatePushCredentials(token.value, user);
          });

          // Show us the notification payload if the app is open on our device
          PushNotifications.addListener(
            "pushNotificationReceived",
            (notification) => {
              //this.notificationService._processMessage(notification);
              handleForegroundNotif(notification);
              PushNotifications.removeAllDeliveredNotifications();
            }
          );

          // Method called when tapping on a notification
          PushNotifications.addListener(
            "pushNotificationActionPerformed",
            (notification) => {
              //this.notificationService._processMessage(notification);

              handleNotificationTap(notification);
              PushNotifications.removeAllDeliveredNotifications();
            }
          );
          // Some issue with our setup and push will not work
          PushNotifications.addListener("registrationError", (error) => {});
          const registerNotifications = async () => {
            let permStatus = await PushNotifications.checkPermissions();

            if (permStatus.receive === 'prompt') {
              permStatus = await PushNotifications.requestPermissions();
            }

            if (permStatus.receive !== 'granted') {
              throw new Error('User denied permissions!');
            }

            await PushNotifications.register();
          }
          registerNotifications();
        } catch (er) {}
      } else if (platform === "web") {
        if (!firebase.apps.length) {
          firebase.initializeApp({
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId:
              process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APPID,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
          });
        }

        if (firebase.messaging.isSupported()) {
          const messaging = firebase.messaging();

          messaging
            .requestPermission()
            .then(() => {
              return messaging.getToken();
            })
            .then((token) => {
              //token send to server

              this.notificationService.updatePushCredentials(token, user);
            })
            .catch((error) => {
              if (error.code === "messaging/permission-blocked") {
                console.log("Please Unblock Notification Request  Manually");
              } else {
                console.log("Error Occurred", error);
              }
            });
          messaging.onMessage((payload) => {
            // this.notificationService.postNotifAnalytics(payload);

            this.notificationService._processNotification(payload);
          });

          messaging
            .getToken()
            .then((currentToken) => {
              if (currentToken) {
                //token send to server

                this.notificationService.updatePushCredentials(
                  currentToken,
                  user
                );
              } else {
                // Show permission request.
                console.log(
                  "No Instance ID token available. Request permission to generate one."
                );
              }
            })
            .catch((err) => {
              console.log("An error occurred while retrieving token. ", err);
            });
        }
      }
    });
  }
}

export default new ZylaNotification();
