import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { logEvent } from "../../../../app/eventSlice";
import AssesmentImage from "../../../../assets/images/illustration_doctor.png";
import TimeMessage from "./time";

const completed = "Completed";
const start = "Start Assessment";
const resume = "Resume Assessment";
export default function AssessmentStartCard({
  message,
  paCompleted,
  currentQuestion,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const text = message.content.message.text;
  const [buttonText, updateButtonText] = useState(start);

  useEffect(() => {
    if (paCompleted) {
      updateButtonText(completed);
    } else {
      updateButtonText(currentQuestion?.id === 1 ? start : resume);
    }
  }, [currentQuestion, paCompleted]);

  const startAssesmentRequest = () => {
    if (navigator.onLine && buttonText !== completed) {
      dispatch(logEvent({ action: "click_start_assessment" }));
      history.push("/assessment");
    }
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="flex">
          <img src={AssesmentImage} alt="" className="w-full h-full" />
          <div className="assesment-image-text">
            2 Minutes Primary Health Assessment
          </div>
        </div>

        {text && <div className="assesment-start-text">{text}</div>}
        <div onClick={startAssesmentRequest} className="start-button">
          {buttonText}
        </div>
      </div>
      <TimeMessage message={message} />
    </>
  );
}
