import { get } from "lodash";
import StorageService from "../services/StorageService";
import FlagIndia from "../assets/images/flag_india.png";
import FlagUAE from "../assets/images/flag_uae.png";
import FlagUSA from "../assets/images/flag_usa.png";
import FlagUK from "../assets/images/flag_uk.png";
import FlagAus from "../assets/images/flag_australia.png";
import FlagCan from "../assets/images/flag_canada.png";

export const getStateForStateMachine = () => {
  const storageService = new StorageService();
  const stateMessage = storageService.get("stateMessageFull");
  const state = get(stateMessage, "content.state", {});

  return state;
};

export const countryCodeArray = [
  {
    name: "India",
    code: "91",
    id: "IN",
    maxLength: 10,
    currency: "INR",
    price: 4400,
    image: FlagIndia,
  },
  // {
  //   name: "UAE",
  //   code: "971",
  //   id: "AE",
  //   maxLength: 9,
  //   currency: "AED",
  //   price: 220,
  //   image: FlagUAE,
  // },
  // {
  //   name: "USA",
  //   code: "1",
  //   id: "US",
  //   maxLength: 10,
  //   currency: "USD",
  //   price: 89,
  //   image: FlagUSA,
  // },
  // {
  //   name: "UK",
  //   code: "44",
  //   id: "GB",
  //   maxLength: 10,
  //   currency: "GBP",
  //   price: 89,
  //   image: FlagUK,
  // },
  // {
  //   name: "Canada",
  //   code: "1",
  //   id: "CA",
  //   maxLength: 10,
  //   currency: "CAD",
  //   price: 89,
  //   image: FlagCan,
  // },
  // {
  //   name: "Australia",
  //   code: "61",
  //   id: "AU",
  //   maxLength: 9,
  //   currency: "AUD",
  //   price: 89,
  //   image: FlagAus,
  // },
];
