import React from "react";
import NoVitalImg from "../../../assets/images/no_vital_required.png";
export default function NoVitalRequired() {
  return (
    <div className="no-vital-required">
      <img src={NoVitalImg} alt="bg-img"></img>
      <p className="mt-10">
        You are doing awesome! <br />
        No vitals required
      </p>
    </div>
  );
}
