import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import APIConfig from "../../services/APIConfig";
import axios from "axios";
import axiosClient from "../../app/axiosConfig";
import homeService from "../../services/HomeService";
import { auth } from "firebase";

const initialState = {
  data: null,
  leaderBoard: null,
  status: "idle",
  error: null,
};

export const stepathonAboutData = createAsyncThunk(
  "stepathonabout/getStepathonAboutData",
  async (id, { rejectWithValue }) => {
    try {
      const url = APIConfig.user.stepathonAbout.format(id);

      const response = await axios.get(url, { headers: { origin: "*" } });
      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const leaderBoardData = createAsyncThunk(
  "leaderBoard/getleaderBoardData",
  async (id, { rejectWithValue }) => {
    try {
      const url = APIConfig.user.leaderBoard.format(id);
      const response = axiosClient.get(url);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const stepathonSlice = createSlice({
  name: "stepathon",
  initialState,
  reducers: {},

  extraReducers: {
    [stepathonAboutData.pending]: (state, action) => {
      state.status = "loading";
    },
    [stepathonAboutData.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = "success";
    },
    [stepathonAboutData.rejected]: (state, action) => {
      state.status = "failed";
    },

    [leaderBoardData.pending]: (state, action) => {
      state.status = "loading";
    },

    [leaderBoardData.fulfilled]: (state, action) => {
      state.leaderBoard = action.payload;
      state.status = "success";
    },

    [leaderBoardData.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default stepathonSlice.reducer;
