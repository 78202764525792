import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import familyPlanServices from "../../services/FamilyPlanServices";
import userService from "../../services/UserService";
const relationAdapter = createEntityAdapter({
  selectId: (f) => f.code,
});
const initialState = {
  familyData: [],
  relations: relationAdapter.getInitialState(),
  loadedFamily: false,
  loadedRelation: false,
  fetchingFamily: false,
  fetchingRelations: false,
  ui: {
    error: false,
    errorMsg: "",
    loading: true,
    isData: false,
    layout: 1,
    drawerOpen: false,
    drawerType: 1,
    isNew: false,
    isErrorPositive: false,
    data: {},
    loadingOverview: false,
    currentMember: {},
    profileInfo: { relation: 1, mobile: "" },

    loadingProfile: true,
  },
};

export const getFamilyData = createAsyncThunk(
  "familyInfo/fData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await familyPlanServices.getAllFamilyMembers();
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getFamilyRelations = createAsyncThunk(
  "familyInfo/relations",
  async (data, { rejectWithValue }) => {
    try {
      const response = await familyPlanServices.getAllRelations();

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const uploadProfilePic = createAsyncThunk(
  "familyInfo/uploadPic",
  async (data, { getState, rejectWithValue }) => {
    try {
      const { file, fileName, pId: patientId = null } = data;
      const { login } = getState();
      const response = await userService.uploadProfilePic(
        file,
        fileName,
        patientId,
        login.user
      );
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addFamilyMember = createAsyncThunk(
  "familyInfo/addFamily",
  async (data, { rejectWithValue }) => {
    try {
      const response = familyPlanServices.createMember(data);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addMemberMinor = createAsyncThunk(
  "familyInfo/addMinor",
  async (data, { rejectWithValue }) => {
    try {
      const response = familyPlanServices.createMemberMinor(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.payload.data);
    }
  }
);

export const familySlice = createSlice({
  name: "familyInfo",
  initialState,
  reducers: {
    updateErrorState: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          loadingProfile: false,
          loadingOverview: false,
          error: action.payload.data,
          errorMsg: action.payload.msg,
          isErrorPositive: action.payload.isErrorPositive,
        },
      };
    },
    updateLayout: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          layout: action.payload,
        },
      };
    },
    updateLayoutAndLoading: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          loadingOverview: false,
          layout: action.payload.layout,
          loading: action.payload.loading,
        },
      };
    },
    openMemberInfo: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          layout: 2,
          currentMember: { ...action.payload },
        },
      };
    },
    openFamilyProfile: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          layout: 3,
          loadingProfile: false,
          data: { ...action.payload },
          currentMember: null,
          isNew: true,
        },
      };
    },
    updateSinglePropertyUI: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          [action.payload.property]: action.payload.value,
        },
      };
    },
  },
  extraReducers: {
    [getFamilyData.pending]: (state, action) => {
      return {
        ...state,

        fetchingFamily: true,
      };
    },
    [getFamilyData.fulfilled]: (state, action) => {
      let fd = [...action.payload.data];

      return {
        ...state,
        familyData: [...fd],
        loadedFamily: true,
        ui: {
          ...state.ui,

          isData: fd.length > 0 ? true : false,
        },
      };
    },
    [getFamilyData.rejected]: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          error: true,
          errorMsg: "Something went wrong while fetching family data.",
        },
      };
    },
    [getFamilyRelations.pending]: (state, actions) => {
      return {
        ...state,
        fetchingRelations: true,
      };
    },
    [getFamilyRelations.fulfilled]: (state, action) => {
      let data = action.payload.data;

      state.loadedRelation = true;
      relationAdapter.addMany(state.relations, data);
    },
    [addFamilyMember.fulfilled]: (state, action) => {
      return {
        ...state,
        familyData: [...state.familyData, { ...action.payload.data }],
        ui: {
          ...state.ui,
          isData: true,
          loadingProfile: false,
        },
      };
    },
    [addMemberMinor.fulfilled]: (state, action) => {
      return {
        ...state,
        familyData: [...state.familyData, { ...action.payload.data }],
        ui: {
          ...state.ui,
          isData: true,
          loadingProfile: false,
        },
      };
    },
    [addFamilyMember.rejected]: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          error: true,
          loadingProfile: false,
          errorMsg: action.payload.data,
        },
      };
    },
    [addMemberMinor.rejected]: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          error: true,
          loadingProfile: false,
          errorMsg: action.payload.data,
        },
      };
    },
    [addMemberMinor.pending]: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          loadingProfile: true,
        },
      };
    },
    [addFamilyMember.pending]: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          loadingProfile: true,
        },
      };
    },

    [uploadProfilePic.fulfilled]: (state, action) => {
      let pId = action.meta.arg.pId;
      let fd = state.familyData.map((fd) => {
        if (fd.memberPatientId === pId) {
          return {
            ...fd,
            profilePicURL:
              action.payload.data +
              "?random=" +
              `${Date.now() + Math.random() * 10000}`,
          };
        }
        return { ...fd };
      });
      let currentMember = {};

      if (
        state.ui.layout === 2 &&
        state.ui.currentMember &&
        state.ui.currentMember.memberPatientId == pId
      ) {
        currentMember = {
          ...state.ui.currentMember,
          profilePicURL:
            action.payload.data +
            "?random=" +
            `${Date.now() + Math.random() * 10000}`,
        };
      }

      return {
        ...state,
        familyData: fd,
        ui: {
          ...state.ui,
          currentMember: { ...currentMember },
          loadingOverview: false,
        },
      };
    },
    [uploadProfilePic.rejected]: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          loadingOverview: false,
          loadingProfile: false,
          // error: true,
          // errorMsg: "Error while uploading.",
          // isErrorPositive: false,
        },
      };
    },
  },
});

export const {
  updateErrorState,
  updateLayout,
  openMemberInfo,
  openFamilyProfile,
  updateLayoutAndLoading,
  updateSinglePropertyUI,
} = familySlice.actions;
export default familySlice.reducer;
