import React from "react";
import { IonButton } from "@ionic/react";
import { useDispatch } from "react-redux";
import { logEvent } from "../../../app/eventSlice";

const HomePageBlogs = ({ blogs }) => {
  const dispatch = useDispatch();
  return (
    <div className="blogs-wrapper">
      <h1>Health blogs</h1>
      {blogs.map((blog) => (
        <div
          key={blog.id}
          className="blog-thumbnails"
          onClick={() => {
            window.open(blog.link, "_blank");
          }}
        >
          <img className="thumbnail" src={blog.cover} alt="bg-img" />
          <div className="wrapper">
            <div className="text-wrapper">
              <h2>{blog.title}</h2>
              <p>{blog.description}</p>
            </div>
          </div>
        </div>
      ))}
      <IonButton
        expand="block"
        color="#fff"
        className="all-videos"
        onClick={() => {
          window.open("https://zyla.in/blog", "_blank");
          dispatch(
            logEvent({ action: "click_view_all_article_feed" })
            // console.log("click_view_all_article_feed")
          );
        }}
      >
        View All Articles
      </IonButton>
    </div>
  );
};

export default HomePageBlogs;
