import React from "react";
function ProgressIndicator(props) {
  return (
    <div className="progress-indicator-component">
      <div className="progress-indicator-top">
        {props.isInput ? (
          <div className="progress-indicator-left-input">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="11"
              viewBox="0 0 14 11"
              fill="none"
              className="general-non-filled-svg-class"
            >
              <path
                d="M12.3334 2L5.00002 9.33333L1.66669 6"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        ) : (
          <div className="progress-indicator-left-right">
            <span>1</span>
          </div>
        )}
        <div className="progress-indicator-mid"></div>
        <div className="progress-indicator-left-right">
          <span>2</span>
        </div>
      </div>
      <div className="progress-indicator-bottom">
        <p>Basic details</p>
        <p>Schedule</p>
      </div>
    </div>
  );
}

export default ProgressIndicator;
