import React, { Component, useCallback, useContext } from "react";
import backImage from "../../assets/icons/arrow-back-black.svg";
import blackBackImg from "../../assets/icons/arrow-back-black.svg";
import closeImg from "../../assets/images/ic_close.png";
import RippleButton from "./RippleButton";
import arrowBack from "../../assets/icons/arrow-back.png";
import { NavContext } from "@ionic/react";
import { useHistory } from "react-router";
import { useEffect } from "react";

// export default class BackButton extends Component {
//   constructor() {
//     super();
//   }

//   render() {
//     const { history, isClose, whiteButton } = this.props;
//     return(

//     )
//   }
// }

const BackButton = (props) => {
  const { goBack } = useContext(NavContext);
  const { history, isClose, whiteButton, isBlack } = props;
  const localHistory = useHistory();

  function handleRedirect() {
    if (props.redr) {
      // history.replace(`/${props.isRedirect}`);
      if (history) {
        history.goBack();
      } else {
        localHistory.goBack();
      }
    } else {
      localHistory.goBack();
      // goBack("/");
    }
  }
  let style1 = {
    width: "30px",
    height: "30px",
  };
  let style2 = {
    height: "20px",
  };

  return (
    <button className="bg-transparent p-0">
      {!whiteButton && (
        <img
          className={`${isBlack ? "black-back-img" : ""}`}
          onClick={() => {
            handleRedirect();
          }}
          src={!isClose ? (isBlack ? blackBackImg : backImage) : closeImg}
          style={isBlack ? style1 : props.style ? props.style : style2}
        />
      )}
      {whiteButton && (
        <img
          onClick={() => {
            if (props.removeMedicineDocument) {
              props.removedMedDocFunc();
            }
            goBack("/");
          }}
          src={arrowBack}
          // style={{ height: "40px" }}
        />
      )}
    </button>
  );
};
export default BackButton;
