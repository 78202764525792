import React, { useEffect, useRef, useState } from "react";
import "./partialMenu.scss";
import PartialMenuItem from "./partialMenuItem";
import { Link, useHistory, useLocation } from "react-router-dom";
import vitalsImg from "../../../../assets/images/partialMenu/vitals.png";
import meditationImg from "../../../../assets/images/partialMenu/meditation.png";
import reportsImg from "../../../../assets/images/partialMenu/reports.png";
import labTestImg from "../../../../assets/images/partialMenu/lab_test.png";
import medOrderImg from "../../../../assets/images/partialMenu/med_order.png";
import UploadDocument from "../../../documents/components/uploadDocument";
import { IonLoading, IonModal } from "@ionic/react";
import DocumentImage from "../../../../assets/images/documents.png";
import ImageIconImage from "../../../../assets/images/images.png";
import "firebase/analytics";
import "firebase/auth";
import closeIcon from "../../../../assets/icons/ic_send_primary_copy.png";
import { Drawer } from "@mui/material";

import { redirectToWebWithToken } from "../../../../utils/helperMethods";
import PrepareUploadService from "../../../../services/PrepareUploadService";
import { useDispatch, useSelector } from "react-redux";
import { logEvent } from "../../../../app/eventSlice";
import documentService from "../../../../services/DocumentService";
import { FilePicker } from "@capawesome/capacitor-file-picker";
import { Capacitor } from "@capacitor/core";

export default function PartialMenu({
  sendMesage,
  onUpload,
  uiConfig,
  open,
  insertUploadMessage,
  patientId,
  onClose,
}) {
  const clientConfig = useSelector((store) => store.tab.clientConfig);
  const patientProfile = useSelector(
    (store) => store.login.user?.patientProfile
  );
  const isPaytm = useSelector((state) => state.app.device.isPaytm);
  const [visibility, setVisibility] = useState(open);
  const [fullHeight, setFullHeight] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pdfError, setPDFError] = useState(false);
  const history = useHistory();
  const dispath = useDispatch();
  const pdfUploader = useRef(null);
  const imageUploader = useRef(null);
  const uploader = useRef(null);

  useEffect(() => {
    setVisibility(open);
  }, [open]);
  const toggleDrawer = () => {
    dispath(logEvent({ action: "toggle_partial_menu" }));

    onClose();
  };

  const toggleFullHeight = () => {
    setFullHeight(!fullHeight);
  };
  const onUploadDone = (response) => {
    if (response.status === "S") {
      let content = {
        message: {
          text: response.result.id,
          type: response.result.format === 4 ? 3 : 2,
          id: "" + new Date().getTime() + Math.random(),
          metadata: {
            id: response.result.id,
          },
        },
      };
      sendMesage(content);
      toggleDrawer();
    } else if (response.status === "F") {
      toggleDrawer();
    }
    setLoading(false);
    onUpload(response.status);
  };
  const getMessage = () => {
    var date = new Date();
    let hr = date.getHours();
    let msg = "What can I do for you ?";
    if (hr >= 5 && hr < 12) {
      msg = "Good morning! ";
    } else if (hr >= 12 && hr < 16) {
      msg = "Good afternoon! ";
    } else if (hr >= 16 && hr <= 23) {
      msg = "Good evening! ";
    }
    return msg;
  };
  const paytmGallery = () => {
    PrepareUploadService.paytmGallery((fileReq) => {
      documentService
        .uploadDocument(
          fileReq.file,
          fileReq.name,
          fileReq.document,
          null,
          patientId
        )
        .then((response) => {
          const data = response.data;
          onUploadDone({ status: "S", result: data });
          setLoading(false);
        })
        .catch((error) => {
          onUploadDone({ status: "F", result: error });
          setLoading(false);
        });
    });
  };
  const handleUploadClick = async (e, type) => {
    if (uploader && uploader.current) {
      if (window.Capacitor.platform !== "web") {
        if (type === "I") {
          PrepareUploadService.selectFiles()
            .then((res) => {
              insertUploadMessage(res.fileResponse, res.errors);
              toggleDrawer();
            })
            .catch((err) => {
              console.log("error is ", err);
            });
        } else {
          PrepareUploadService.pickFiles(
            window.Capacitor.platform == "ios" ? true : false
          )
            .then((res) => {
              insertUploadMessage(res.fileResponse, res.errors);
              toggleDrawer();
            })
            .catch((err) => {});
        }
      } else {
        uploader.current.click();
      }
    }
  };

  return (
    <IonModal
      isOpen={visibility}
      backdropDismiss={true}
      onDidDismiss={() => onClose()}
      cssClass={"ion-modal-general-class signup-container menu-partial-medium"}
      aria-labelledby="form-dialog-title"
    >
      <div className="partial-menu-content">
        <div
          className="partial-menu-open-state"
          onClick={toggleFullHeight}
        ></div>
        <div className="fs18 todo-heading text-center">
          {getMessage()}
          {!isPaytm ? (
            <img
              className="partial-menu-header"
              src={closeIcon}
              onClick={() => {
                toggleDrawer();
              }}
              alt=""
            ></img>
          ) : (
            <label
              className="partial-menu-header"
              onClick={() => {
                toggleDrawer();
              }}
            >
              close
            </label>
          )}
        </div>
        <div className="fs12 todo-text">To dos</div>

        <Link
          to="/reporting"
          onClick={() => {
            toggleDrawer();
            dispath(logEvent({ action: "click_partial_menu_reporting" }));
          }}
          style={{ color: "black" }}
        >
          <PartialMenuItem icon={reportsImg} text={`Share my routine`} />
        </Link>

        <Link
          to="/vitals"
          onClick={() => {
            toggleDrawer();
            dispath(logEvent({ action: "click_partial_menu_vitals" }));
          }}
          style={{ color: "black" }}
        >
          <PartialMenuItem icon={vitalsImg} text="Share my vitals" />
        </Link>

        <Link
          to="/meditation"
          onClick={() => {
            toggleDrawer();
            dispath(logEvent({ action: "click_partial_menu_meditations" }));
          }}
          style={{ color: "black" }}
        >
          <PartialMenuItem icon={meditationImg} text="Play meditation" />
        </Link>

        <div className="fs12 todo-text">Upload</div>
        {!isPaytm && (
          <div
            onClick={(e) => {
              dispath(logEvent({ action: "click_partial_menu_images" }));
              if (window.Capacitor.platform !== "web") {
                handleUploadClick(e, "P");
              } else {
                uploader.current.accept = "application/pdf";
                uploader.current.click();
              }
            }}
            style={{ color: "black" }}
          >
            <PartialMenuItem icon={DocumentImage} text={`Upload PDFs`} />
          </div>
        )}
        <div
          onClick={(e) => {
            if (isPaytm) {
              paytmGallery();
            } else {
              uploader.current.accept =
                "image/jpg,image/jpeg,image/png,image/gif";
              handleUploadClick(e, "I");
            }
          }}
        >
          <PartialMenuItem icon={ImageIconImage} text="Upload images" />
        </div>
        {/* <UploadDocument
          type="image/jpg,image/jpeg,image/png,image/gif"
          onUploadDone={(response) => onUploadDone(response)}
          onUploadStart={(e) => {
            insertUploadMessage(e);
            toggleDrawer();
          }}
          ref={imageUploader}
          multipleUpload={true}
        ></UploadDocument> */}

        <UploadDocument
          pId={patientId}
          type={""}
          onUploadStart={(e) => {
            insertUploadMessage(e);
            toggleDrawer();
          }}
          ref={uploader}
          paytm={isPaytm}
          onUploadDone={(response) => onUploadDone(response)}
          multipleUpload={true}
        ></UploadDocument>

        {/* <UploadDocument
          type="application/pdf"
          
          ref={pdfUploader}
          onUploadDone={(response) => onUploadDone(response)}
          multipleUpload={true}
        ></UploadDocument> */}
      </div>

      <IonLoading isOpen={loading} message={"Uploading"} duration={50000} />
    </IonModal>
  );
}
