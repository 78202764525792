import ApiService from "../services/ApiService";
import AuthenticationService from "../services/AuthenticationService";
import DocumentService from "../services/DocumentService";
import StorageService from "../services/StorageService";
import userService from "../services/UserService";
import moment from "moment";
import APIConfig from "../services/APIConfig";
import jwt_decode from "jwt-decode";
import axiosClient from "../app/axiosConfig";
import axios from "axios";
import { SocialSharing } from "@ionic-native/social-sharing";
import NavigoServices from "../services/NavigoServices";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import write_blob from "capacitor-blob-writer";

export const getCompleteURL = (link, pid, onResponse, memberPatientId = 0) => {
  const url = APIConfig.user.createRequest;
  let body = {
    patientId: pid,
    memberPatientId: memberPatientId,
  };
  axiosClient
    .post(url, body)
    .then((res) => {
      const myUrl = new URL(link);
      const searchParams = new URLSearchParams(myUrl.search);
      let newLink = link + "&request_id=" + res.data.token;
      if (searchParams.toString() === "") {
        newLink = link + "?request_id=" + res.data.token;
      }
      onResponse(newLink);
    })
    .catch((e) => {
      onResponse(link);
    });
};

export const isValidEmail = (email) => {
  if (email.length === 0) {
    return true;
  }
  email = email.trim();
  let re =
    /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  let result = re.test(email) && !email.includes(" ");
  if (result) {
    return true;
  }
  return false;
};

export const shouldRedirectToNavigo = () => {
  const storageService = new StorageService();

  let clientType = storageService.get("clientType");
  if (clientType && clientType === "B2B") {
    return true;
  }
  return false;
};

export const redirectToWebWithToken = (
  link,
  patientId,
  memberPatientId = 0
) => {
  try {
    let windowAssign = {};
    if (window.Capacitor.platform === "web") {
      windowAssign = window.open("about:blank", "_blank");
    }
    getCompleteURL(
      link,
      patientId,
      (res) => {
        let url = res;

        if (window.Capacitor.platform === "web") {
          windowAssign.location = url;
        } else {
          setTimeout(() => {
            window.open(url, "_top");
          });
        }
      },
      memberPatientId
    );
  } catch (err) {}
};

export const shouldShowFeedback = () => {
  let result = false;
  try {
    const storageService = new StorageService();
    if (window.Capacitor.platform === "web") {
      return false;
    }
    let feedbakcStatus = storageService.get("feedbackStatus");
    if (!feedbakcStatus) {
      result = true;
    }
    if (feedbakcStatus === "2") {
      let feedbackTime = Number(storageService.get("feedbackTime"));
      let nextDate = moment(feedbackTime).add(7, "days");
      if (moment().isAfter(nextDate)) {
        result = true;
      }
    }
    if (result) {
      storageService.set("feedbackStatus", "2");
      storageService.set("feedbackTime", `${Date.now()}`);
    }
    return result;
  } catch (err) {
    return false;
  }
};
export const refreshAndSaveToken = (onSuccess, onError) => {
  const authenticationService = new AuthenticationService();
  const documentService = new DocumentService();
  const storageService = new StorageService();
  const apiService = new ApiService();
  let user = authenticationService.getUserProfile();
  if (user) {
    var expTkn = authenticationService.getToken(true)?.expiryToken;

    var payload = {
      accessToken: "",
      expiryToken: expTkn,
    };

    var url = APIConfig.user.token;
    let headers = {
      client: "patient_android",
      "Content-Type": "application/json",
      phone_no: user?.patientProfile?.phoneno,
    };
    apiService.post(
      url,
      payload,
      (response) => {
        if (response.accessToken !== null) {
          authenticationService.setToken(response);
          authenticationService.activateSession(response.accessToken);
          documentService._generateToken(
            () => {
              onSuccess();
            },
            () => {
              onSuccess();
            }
          );
        } else {
          checkValidityAndSetTimeOut(expTkn, onSuccess, onError);
        }
      },
      (err) => {
        checkValidityAndSetTimeOut(expTkn, onSuccess, onError);
      },
      headers
    );
  } else {
    console.log("user not found calling on error");
    onError();
  }
};

export const refreshAndSaveTokenNew = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const eToken = localStorage.getItem("zy.etoken");
      const phoneNo = localStorage.getItem("zy.phone_no");

      if (eToken) {
        var url = APIConfig.user.token;
        var payload = {
          accessToken: "",
          expiryToken: eToken,
        };

        let headers = {
          client: "patient_android",
          "Content-Type": "application/json",
          phone_no: phoneNo,
        };

        const rs = await axios.post(url, payload, { headers: headers });
        if (rs.status === 200) {
          window.dispatchEvent(new Event("generateDocToken"));
          localStorage.setItem("zy.token", rs.data.accessToken);
          localStorage.setItem("zy.etoken", rs.data.expiryToken);
          localStorage.setItem("zy.phone_no", phoneNo);
          resolve();
        } else if (rs.status === 403) {
          window.dispatchEvent(new Event("logUserOut"));
        } else {
          reject();
        }
      }
    } catch (err) {
      reject();
    }
  });
};

export const checkValidityAndSetTimeOut = (token, onSuccess, onError) => {
  try {
    let decoded = jwt_decode(token);
    if (decoded.exp * 1000 < Date.now()) {
      onError();
    }
  } catch (err) {
    console.log("error is catched in try and catch");
  }
  setTimeout(() => {
    refreshAndSaveToken(onSuccess, onError);
  }, 3000);
};

export const processUplodedFile = (myFile) => {
  return new Promise((resolve, reject) => {
    const file = myFile;

    var reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (evt) => {
      const imgBlob = new Blob([reader.result], {
        type: file.type,
      });
      if (imgBlob.size > 20000000) {
        reject({
          result: false,
          msg: "This file is too big. maximum allowed file size is 20MB",
        });
      } else {
        resolve({ result: true, data: reader.result, blob: imgBlob });
      }
    };
    reader.onerror = (evt) => {
      reject({ result: false, msg: "Could not upload the image." });
    };
  });
};

export const checkHTMLInput = (str) => {
  let htmlTagsRegex = /<(“[^”]*”|'[^’]*’|[^'”>])*>/;
  let result = htmlTagsRegex.test(str);
  return result;
};

export const getUrlWithToken = (url, token) => {
  if (url && url.indexOf("?") !== -1) {
    url = `${url}&token=${token}`;
  } else {
    url = `${url}?token=${token}`;
  }
  return url;
};

export const deepEqual = (object1, object2) => {
  try {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if (
        (areObjects && !deepEqual(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }
    return true;
  } catch (err) {}
};
export const isObject = (object) => {
  return object != null && typeof object === "object";
};

export const myPromisSettled = (promises) => {
  let mappedPromises = promises.map((p) => {
    return p
      .then((value) => {
        return {
          status: "fulfilled",
          value,
        };
      })
      .catch((reason) => {
        return {
          status: "rejected",
          reason,
        };
      });
  });
  return Promise.all(mappedPromises);
};

export const handleBackButtonFunc = (ev) => {
  ev.detail.register(10000, () => {
    console.log("");
  });
};
export const handleFileDownload = async (
  url,
  name = "zyla-report",
  onSuccess,
  onError
) => {
  NavigoServices.getNavigoReport(
    url,
    async (res) => {
      let format = "pdf";

      if (res.type === "image/png") {
        format = "png";
      } else if (res.type === "image/jpeg") {
        format = "jpeg";
      } else if (res.type === "image/jpg") {
        format = "jpg";
      }
      let fname = name;
      let realNameArr = name.split(".");
      if (realNameArr && realNameArr.length > 1) {
        fname = realNameArr[0];
      }

      let fileName = `${fname}-${Date.now()}-${parseInt(
        Math.random() * 10000
      )}.${format}`;

      if (window.Capacitor.platform === "android") {
        try {
          write_blob({
            path: `Download/${fileName}`,
            directory: Directory.ExternalStorage,
            blob: res,
            fast_mode: true,
            recursive: true,
            on_fallback(error) {
              // onError()
            },
          })
            .then(function () {
              onSuccess();
            })
            .catch((err) => {
              onError();
            });
        } catch (err) {
          onError();
        }
      } else {
        handleIosFileDownload(fileName, res, onSuccess, onError);
      }
    },
    (err) => {
      onError();
    }
  );
};

// export const handleAndroidReadAndWrite = async (
//   fileName,
//   res,
//   directory,
//   folder,
//   onSuccess,
//   onError
// ) => {
//   try {
//     let createdFileResult = await File.createFile(
//       File.documentsDirectory + folder,
//       fileName,
//       true
//     );
//     let writeToFile = await File.writeFile(directory + folder, fileName, res, {
//       replace: true,
//       append: false,
//     });
//     onSuccess();
//   } catch (err) {
//     onError();
//   }
// };

export const getTruncatedName = (name) => {
  let fname = name;
  if (name && name.length > 0) {
    let fnameTemp = name.split(".");
    if (fnameTemp.length > 0 && fnameTemp[0].length > 10) {
      fname = fnameTemp[0].substr(0, 10) + "[...]" + "." + fnameTemp[1];
    }
  }
  return fname;
};

export const errorMessageHandler = (
  err,
  defaultMsg = "Something went wrong."
) => {
  let errMsg = defaultMsg;

  try {
    let msgArr = JSON.parse(err);

    if (msgArr.message) {
      let realMsg = JSON.parse(msgArr.message);

      if (typeof realMsg === "object" && realMsg.message) {
        errMsg = realMsg.message;
      } else {
        return realMsg;
      }
    } else {
      return msgArr;
    }
  } catch (error) {
    if (err?.message) {
      errMsg = err.message;
    }
  }
  return errMsg;
};

export const handleIosFileDownload = (fileName, res, onSuccess, onError) => {
  File.createDir(File.documentsDirectory, "myDownloads", true)
    .then((dirRes) => {
      createFile(fileName)
        .then((result) => {
          return writeToFile(fileName, res);
        })
        .then((createdFile, arg) => {
          onSuccess();
          handleFileShare(createdFile, fileName);
        })
        .catch((err) => {
          onError();
        });
    })
    .catch((err) => {
      console.log("s");
    });
};
export const createFile = async (fileName) => {
  return new Promise((resolve, reject) => {
    File.createFile(File.documentsDirectory + "myDownloads", fileName, true)
      .then((crf) => {
        resolve(true);
      })
      .catch((err) => {
        reject(true);
      });
  });
};

export const handleRemoveFile = (fileName) => {
  File.removeFile(File.documentsDirectory + "myDownloads", fileName)
    .then((res) => {})
    .catch((err) => {});
};

export const handleFileShare = (info, fileName) => {
  SocialSharing.share(null, null, info.nativeURL, null)
    .then((res) => {
      handleRemoveFile(fileName);
    })
    .catch((err) => {
      handleRemoveFile(fileName);
    });
};

export const writeToFile = (fileName, data) => {
  return new Promise((resolve, reject) => {
    File.writeFile(File.documentsDirectory + "myDownloads", fileName, data, {
      replace: true,
      append: false,
    })
      .then((createdFile) => {
        resolve(createdFile, fileName);
      })
      .catch((err) => {
        reject(false);
      });
  });
};
