export const isValidHour = (hour) => {
  if (hour) {
    hour = parseInt(hour, 10);
  }
  return hour && hour >= 1 && hour < 13;
};
export const isValidMinute = (minute) => {
  if (minute) {
    minute = parseInt(minute, 10);
    return minute < 59 && minute >= 0;
  } else {
    return false;
  }
};
export const isValidDayNight = (d) => {
  return d && (d.toUpperCase() === "AM" || d.toUpperCase() === "PM");
};
