import { IonLoading } from "@ionic/react";
import { Camera,CameraResultType,CameraSource } from "@capacitor/camera";
import { Snackbar } from "@mui/material";
import { Link } from "react-router-dom";
import PlayImage from "../../../../assets/images/group_copy.png";

import dompurify from "dompurify";
import { redirectToWebWithToken } from "../../../../utils/helperMethods";

import { useHistory } from "react-router";
import TimeMessage from "./time";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logEvent } from "../../../../app/eventSlice";
import UploadDocument from "../../../documents/components/uploadDocument";
import { sendMessage } from "../../chatSlice";
import documentService from "../../../../services/DocumentService";
import ErrorComponent from "../../../../components/common/ErrorComponent";
import PrepareUploadService from "../../../../services/PrepareUploadService";
export default function CustomCardMessage({
  message,
  patientId,
  device,
  setUpload,
}) {
  const [card, setCard] = useState({
    cta: false,
    ctaText: "",
    ctaAction: "",
    body: "",
    fileContent: "",
    form: false,
    cameraError: false,
    loading: false,
  });
  const user = useSelector((state) => state.login.user);
  const chat = useSelector((state) => state.tab.chat);
  const history = useHistory();
  const dispatch = useDispatch();
  const pdfUploader = useRef(null);
  const imageUploader = useRef(null);

  useEffect(() => {
    if (
      message &&
      !message.mine &&
      message.content &&
      message.content.message
    ) {
      const metadata = message.content.message.metadata;

      let fileContent = undefined;
      if (metadata.contents && metadata.contents.length > 0) {
        fileContent = { ...metadata.contents[0] };
        if (
          metadata.contents[0].type &&
          metadata.contents[0].type.startsWith("video")
        ) {
          // is video
          fileContent.isVideo = true;
          fileContent.isImage = false;
        } else {
          fileContent.isVideo = false;
          fileContent.isImage = true;
        }
      }
      if (metadata.cta) {
        setCard((c) => {
          return {
            ...c,
            cta: true,
            internal: metadata.cta.internal,
            ctaText: metadata.cta.title,
            ctaAction: metadata.cta.action,
            body: metadata.body,
            fileContent: fileContent,
          };
        });
      } else if (!metadata.cta && (metadata.body || metadata.contents)) {
        setCard((c) => {
          return {
            ...c,
            body: metadata.body,
            fileContent: fileContent,
          };
        });
      } else if (!metadata.cta && !metadata.body && !metadata.contents) {
        setCard((c) => {
          return {
            ...c,
            form: true,
          };
        });
      }
    }
  }, [message]);

  const titleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  };

  const returnURL = (url) => {
    if (url.startsWith("http")) {
      return url;
    } else {
      return `https://${url}`;
    }
  };
  const onUploadDone = (response) => {
    if (response.status === "S") {
      let content = {
        message: {
          text: response.result.id,
          type: response.result.format === 4 ? 3 : 2,
          id: "" + new Date().getTime() + Math.random(),
          metadata: {
            id: response.result.id,
          },
        },
      };
      dispatch(
        sendMessage({
          content: {
            message: { ...content.message },
          },
          roomId: chat.chatRoom,
          userId: chat.externalUserIdSt,
        })
      );

      setCard((prevCard) => {
        return { ...prevCard, loading: false };
      });
    } else if (response.status === "F") {
      setCard((prevCard) => {
        return { ...prevCard, pdfError: true };
      });
    }
    setCard((prevCard) => {
      return { ...prevCard, loading: false };
    });
  };

  const takePicture = async () => {
   
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      correctOrientation: false,
      source: CameraSource.Camera,
      resultType: CameraResultType.Uri,
    });
    // image.webPath will contain a path that can be set as an image src.
    // You can access the original file using image.path, which can be
    // passed to the Filesystem API to read the raw data of the image,
    // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
    if (image) {
      setCard((prevCard) => {
        return { ...prevCard, loading: true };
      });
      const blob = await fetch(image.webPath).then((r) => r.blob());

      let fileExtension = image.format;

      let fileName = Math.random() * (9999999 - 1111111) + 1111111;
      documentService
        .uploadDocument(
          blob,
          `${fileName}.${fileExtension}`,
          {
            id: "",
            title: "",
            description: "",
            url: "",
            type: 6,
            format: 1,
            status: 4,
          },
          null,
          user?.patientProfile?.id
        )
        .then((response) => {
          setCard((prevCard) => {
            return { ...prevCard, loading: false };
          });
          onDone(response.data);
        })
        .catch((error) => {
          setCard((prevCard) => {
            return { ...prevCard, loading: false };
          });
          onDone(null);
        });
    }
  };

  const onDone = (response) => {
    if (response) {
      let content = {
        message: {
          text: response.id,
          type: response.format === 4 ? 3 : 2,
          id: "" + new Date().getTime() + Math.random(),
          metadata: {
            id: response.id,
          },
        },
      };
      dispatch(
        sendMessage({
          content: {
            message: { ...content.message },
          },
          roomId: chat.chatRoom,
          userId: chat.externalUserIdSt,
        })
      );
    }
  };
  
  const getLinkTo = (value) => {
    sendReplyEvent();
    switch (value) {
      case "OPEN_CAMERA": {
        if (window.Capacitor.platform !== "web") {
          takePicture();
        } else {
          setCard((prevCard) => {
            return { ...prevCard, cameraError: true };
          });
        }
        return null;
      }
      case "OPEN_VITAL_CHART": {
        return "/vitalsChart";
      }
      case "OPEN_MEDITATION": {
        dispatch(logEvent({ action: "open_meditation_via_chat" }));

        return "/meditation";
      }
      case "OPEN_HEALTH_PLAN": {
        return "/health-plan";
      }
      case "OPEN_PROFILE": {
        return "/profile";
      }
      case "OPEN_REPORTING": {
        return "/reporting";
      }
      case "OPEN_VITAL": {
        return "/vitals";
      }
      case "OPEN_PREMIUM": {
        return "/premium";
      }
      case "BOOKING_OFFER": {
        return "/navigo";
      }
      case "OPEN_UPLOAD_OPTION": {
        setUpload(true);
        return null;
      }
      default:
        return null;
    }
  };

  const sendReplyEvent = () => {
    dispatch(
      logEvent({
        action: "REPLY_CUSTOM_MESSAGE",
        data: {
          messageId: message.id,
        },
      })
    );
  };
  const handleDocumentUpload=(type)=>{
    if(window.Capacitor.platform !== "web"){

      if(type==="I"){
        PrepareUploadService.selectFiles(false).then((res)=>{
         
            uploadFileToServer(res.fileResponse,res.errors)
          
         
        }).catch(err=>{
         
        })
      }else{
        PrepareUploadService.pickFiles(window.Capacitor.platform == "ios"?true:false).then((res)=>{
          
            uploadFileToServer(res.fileResponse,res.errors)
          
         
        }).catch(err=>{
         
        })
      }
     
     
    }else{
      if(type==="P"){
        pdfUploader.current.accept="application/pdf";
        pdfUploader.current.click();
      }else{
        imageUploader.current.accept="image/jpg,image/jpeg,image/png,image/gif";
        imageUploader.current.click();
      }
     
    }
  }

  const uploadFileToServer = (e,errors={}) => {
    if(errors.sizeError){
      onUploadDone({
        status: "F",
        result: "File size should be less than 20MB",
      });
      return;
    }
    const file = e.target.files[0];

    onUploadStart(e);
    let fileReq = PrepareUploadService.buildDocumentFromFile(file);
    if (fileReq === null) {
      return;
    }

    if (file.size <= 20000000) {
      documentService
        .uploadDocument(file.file, file.name, fileReq, undefined, patientId)
        .then((response) => {
          const data = response.data;
          if (onUploadDone) {
            onUploadDone({ status: "S", result: data });
          }
        })
        .catch((error) => {
          if (onUploadDone) {
            onUploadDone({ status: "F", result: error });
          }
        });
    } else {
      onUploadDone({
        status: "F",
        result: "File size should be less than 20MB",
      });
    }
  
  };
  const  onUploadStart=() =>{
    setCard((prevCard) => {
      return { ...prevCard, loading: true };
    })
  }

  const {
    cta,
    ctaText,
    ctaAction,
    body,
    fileContent,
    internal,
    form,
    cameraError,
    loading,
  } = card;

  let ctaTextNew = "" + ctaText;
  let docViewerURL = "";
  if (fileContent && fileContent.url) {
    let format = "5";
    if (fileContent.isImage) {
      format = "1";
    }
    docViewerURL = `/document-viewer?title=&format=${format}&url=${fileContent.url}`;
  }

  if (!form) {
    return (
      <>
        <div className="message-text-wrapper">
          <div className="request-card-container custom-message">
            {/* <img className="zyla-logo-card" src={zylaLogo} alt="zyla logo" /> */}
            <div>
              <div
                className={
                  body
                    ? "request-card-body custom-message"
                    : "request-card-body custom-message fix-border"
                }
              >
                {fileContent && fileContent.isImage && (
                  <Link target={""} to={docViewerURL} className="anchor-cls">
                    <img
                      className="icon-card"
                      id="custom-image"
                      src={fileContent.url}
                      alt="document "
                    />
                  </Link>
                )}
                {fileContent && fileContent.isVideo && (
                  <div className="video-container">
                    <Link target={""} to={docViewerURL} className="anchor-cls">
                      <video
                        playsInline
                        muted
                        className="icon-card"
                        id="custom-image"
                        src={fileContent.url}
                      />
                      <div className="video-mask">
                        <img src={PlayImage} alt="play"></img>
                      </div>
                    </Link>
                  </div>
                )}

                {body && body.trim() && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: dompurify.sanitize(body),
                    }}
                    className="request-message-content"
                    id="cm-body"
                  ></div>
                )}
                {cta && (
                  <div className="start-button font-normal">
                    {internal && (
                      <div>
                        {ctaAction !== "UPLOAD_IMAGE" &&
                          ctaAction !== "UPLOAD_DOCUMENT" && (
                            <div
                              className="btn-clz"
                              onClick={() => {
                                let nextAction = getLinkTo(ctaAction);
                                if (nextAction) {
                                  history.push(nextAction);
                                }
                              }}
                            >
                              {titleCase(ctaTextNew)}
                            </div>
                          )}
                        {ctaAction === "UPLOAD_IMAGE" && (
                          <div className="btn-clz">
                            <div
                              className="btn-clz"
                              onClick={() => {
                                sendReplyEvent();
                                handleDocumentUpload("I");
                               
                              }}
                            >
                              {titleCase(ctaTextNew)}
                            </div>

                            <UploadDocument
                              type="image/*"
                              onUploadDone={(response) =>
                                onUploadDone(response)
                              }
                              onUploadStart={() =>
                                setCard((prevCard) => {
                                  return { ...prevCard, loading: true };
                                })
                              }
                              ref={imageUploader}
                              pId={patientId}
                            ></UploadDocument>
                          </div>
                        )}
                        {ctaAction === "UPLOAD_DOCUMENT" && (
                          <div>
                            <div
                              onClick={() => {
                                sendReplyEvent();
                                handleDocumentUpload("P");
                              }}
                            >
                              {ctaTextNew.toUpperCase()}
                            </div>

                            <UploadDocument
                              type="application/pdf"
                              onUploadStart={() =>
                                setCard((prevCard) => {
                                  return { ...prevCard, loading: true };
                                })
                              }
                              ref={pdfUploader}
                              onUploadDone={(response) =>
                                onUploadDone(response)
                              }
                              pId={patientId}
                            ></UploadDocument>
                          </div>
                        )}
                      </div>
                    )}
                    {!internal && (
                      <div
                        className="btn-clz"
                        onClick={() => {
                          redirectToWebWithToken(
                            returnURL(ctaAction),
                            user?.patientProfile?.id
                          );
                        }}
                      >
                        {titleCase(ctaTextNew)}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <TimeMessage message={message} />
            </div>
          </div>
        </div>
        <div>
          <Snackbar
            className={"error-message custom-card-error-message"}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={cameraError}
            autoHideDuration={2000}
            onClose={() => {
              setCard((prevCard) => {
                return { ...prevCard, cameraError: false };
              });
            }}
            ContentProps={{
              "aria-describedby": "error-id",
            }}
            message={<span id="error-id">Camera not available on device</span>}
          />
        </div>
        {loading && (
          <IonLoading isOpen={loading} message={"Uploading"} duration={50000} />
        )}
      </>
    );
  } else {
    return null;
  }
}
