import React from "react";
import CommonCardHead from "./CommonCardHead";
import MessageContent from "./MessageContent";
import PaymentService from "../../services/PaymentService";
import moment from "moment";
import { Link } from "react-router-dom";
import alertImg from "../../assets/images/alert-image.png";
import "./ChatInput.scss";
// import lock from "../../assets/images/ic_lock_secondary_2.png";
import back from "../../assets/images/icon_arrow_back.png";
// import BackButton from "../common/BackButton";
import { IonPage, IonContent, IonSpinner } from "@ionic/react";

import Green from "../../assets/images/group.png";
import lock from "../../assets/icons/lock.png";
import reload from "../../assets/images/reload.png";

import backImage from "../../assets/images/icon_arrow_back.png";

export default class PaymentScreen extends React.Component {
  constructor(props) {
    super(props);
    this.paymentservice = new PaymentService();
    this.state = {
      loading: false,
      showError: false,
      paymentDone: false,
      orderId: null,
    };
  }

  paymentDetails() {
    let onSucess = (res) => {
      this.orderId = res.orderId;
      this.setState({ orderId: this.orderId });
      this.ready(() => {
        window.JSBridge.call("paytmPayment", res, (result) => {
          if (!result.data) {
            this.setState({
              loading: false,
              showError: false,
              paymentDone: false,
            });
          } else {
            this.startPayment(this.orderId);
            this.interval = setInterval(() => {
              this.startPayment(this.orderId);
            }, 2000);
          }
        });
      });
    };
    let onError = (error) => {
      this.setState({
        loading: false,
        showError: true,
        paymentDone: false,
      });
    };
    this.paymentservice.getPaymentDetails(onSucess, onError);
  }

  ready(callback) {
    // call if jsbridge is injected

    if (window.JSBridge) {
      callback && callback();
    } else {
      // listen to jsbridge ready event
      document.addEventListener("JSBridgeReady", callback, false);
    }
  }

  startPayment = (order) => {
    let onSucess = (res) => {
      if (res !== null) {
        if (res.status === 2 || res.status === 3) {
          this.setState({
            loading: true,
            showError: false,
            paymentDone: false,
          });
        }
        if (res.status === 4) {
          clearInterval(this.interval);
          this.setState({
            loading: false,
            showError: true,
            paymentDone: false,
          });
        }
        if (res.status === 5) {
          clearInterval(this.interval);
          this.setState({
            loading: false,
            showError: false,
            paymentDone: true,
          });
        }
      }
    };
    let onError = (error) => {
      this.setState({
        loading: false,
        showError: true,
        paymentDone: false,
      });
    };
    this.paymentservice.initiatePayment(order, onSucess, onError);
  };

  render() {
    let { loading, showError, paymentDone } = this.state;
    return (
      <IonPage>
        {loading && (
          <div>
            <div className="loading-spinner-holder">
              <IonSpinner className="loader" />
              <p className="loading-text">Do not refresh or close the page</p>
              <p className="loading-text-small">
                We are confirming your payment status
              </p>
            </div>
          </div>
        )}
        {!loading && !showError && !paymentDone && (
          <div>
            <img
              className="alert-img-payment-1"
              src={alertImg}
              alt="g-maps"
              width="360px"
              height="265px"
            />
            <p class="heading-payment-1">
              90 Days Diabetes Unlimited Care Plan
            </p>
            <p class="sub-heading-payment-1">Rs. 4400</p>
            <p class="list-p-payment-1">
              <li class="list">
                Health analysis call (40 mins) + regular calls
              </li>
              <li class="list">Personalized health plan</li>
              <li class="list">Unlimited monitoring on zyla app</li>
              <li class="list">Review calls every 15 days</li>
            </p>

            <div className="button-cover">
              <div
                class="button-pay-1"
                onClick={() => {
                  this.setState({ loading: true });
                  this.paymentDetails();
                }}
              >
                <img src={lock} alt={"lock"} height="12px" width="12px" />

                {"  Pay securely with Paytm"}
              </div>
            </div>
          </div>
        )}
        {!loading && !showError && paymentDone && (
          <IonContent>
            <div className="pDone">
              <img src={Green} width="150px" height="150px" alt={"success"} />

              <p className="payment-done">
                Your payment is successful
                <br />
                <br />
                Your 90 days of diabetes care plan is activated. You will
                receive a call shortly and your chat will get unlocked in few
                minutes.
              </p>
            </div>
            <div className="retry-cover">
              <div
                class="retry-button-1"
                onClick={() => {
                  this.props.history.goBack();
                }}
              >
                Back to Home
              </div>
            </div>
          </IonContent>
        )}
        {!loading && showError && !paymentDone && (
          <IonContent>
            <div className="relaodBackground">
              <img
                className="reload"
                src={reload}
                alt={"reload"}
                height="30px"
              />
            </div>
            <p className="payment-fail">Payment failed</p>
            <div className="retry-cover">
              <div
                class="retry-button-1"
                onClick={() => {
                  clearInterval(this.interval);
                  this.setState({
                    loading: false,
                    showError: false,
                    paymentDone: false,
                  });
                }}
              >
                Retry Payment
              </div>
            </div>
          </IonContent>
        )}
      </IonPage>
    );
  }
}
