import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router";

import MembershipBanner from "../HomePage/components/MembershipBanner";
import HealthDetails from "./components/healthDetails";
import "./screen.scss";

import APIConfig from "../../services/APIConfig";
import MenuItem from "./components/menuItem";

import FemaleActive from "../../assets/images/femaleClipped.svg";
import MaleActive from "../../assets/images/Clipped.svg";

import {
  IonAlert,
  IonContent,
  IonModal,
  IonPage,
  useIonViewDidEnter,
} from "@ionic/react";
import { Dialog, DialogContent } from "@mui/material";
import axiosClient from "../../app/axiosConfig";
import { logEvent } from "../../app/eventSlice";
import documents from "../../assets/images/Icon Documents.png";
import reporting from "../../assets/images/Icon Routine Reporting.png";
import personalDetails from "../../assets/images/Primary.png";
import addressIcon from "../../assets/images/addresss.png";
import careTeam from "../../assets/images/careTeam.png";
import lifestyle from "../../assets/images/detailsScreen/lifestyle-graph-icon.png";
import TermsAndConditions from "../../assets/images/insert.png";
import logout from "../../assets/images/logOut.png";
import no_reporting from "../../assets/images/no_reporting.png";
import familyIcon from "../../assets/images/people.png";
import coustmerSupport from "../../assets/images/phone.svg";
import Privacy from "../../assets/images/privacy.png";
import voucherCode from "../../assets/images/reedom.png";
import setting from "../../assets/images/setting.png";
import connectDevices from "../../assets/images/settings_remote-24px-2 1.png";
import orderHistory from "../../assets/images/shopping.png";
import vitals from "../../assets/images/vital.png";
import RippleButton from "../../components/common/RippleButton";
import SecureImage from "../../components/common/SecureImage";
import StorgaeService from "../../services/StorageService";
import IonModalWrapper from "../../components/common/IonModalWrapper";
const getHamburgerMenuItems = (patient, paytmFlag) => {
  let menuItems = [
    {
      id: "profile",
      name: "Personal details",
      icon: personalDetails,
      path: "/profile",
      disabledIcon: personalDetails,
    },
    // {
    //   id: "MyTeam",
    //   name: "My team",
    //   icon: careTeam,
    //   path: "/careTeam",
    //   disabledIcon: careTeam,
    // },
    {
      id: "documents",
      name: "Documents",
      icon: documents,
      path: "/documents",
      disabledIcon: documents,
      event_name: "click_profile_documents",
    },
    {
      id: "reporting",
      name: "Routine reportings",
      icon: reporting,
      path: "/submittedReporting",
      disabledIcon: no_reporting,
    },

    {
      id: "vital-chart",
      name: "Vital charts",
      icon: vitals,
      path: "/vitalsChart",
      disabledIcon: vitals,
    },
    {
      id: "Family members",
      name: "Family members",
      icon: familyIcon,
      onClick: "family",
      path: "/family",
      disabledIcon: setting,
    },
    {
      id: "Address book",
      name: "Address book",
      icon: addressIcon,
      onClick: "address",
      path: "/address",
      disabledIcon: setting,
    },
    {
      id: "Order history",
      name: "Order history",
      icon: orderHistory,
      onClick: "order-history",
      path: "/order-history",
      disabledIcon: setting,
    },
    // {
    //   id: "Connect Device",
    //   name: "Connect Device",
    //   icon: connectDevices,
    //   onClick: "Connect Device",
    //   path: "/syncedVitals",
    //   disabledIcon: setting,
    // },
    {
      id: "Voucher code",
      name: "Voucher code",
      icon: voucherCode,
      onClick: "generate-voucher",
      path: "/registervoucher",
      disabledIcon: setting,
    },

    // {
    //   id: "facebook",
    //   name: "Community",
    //   icon: Facebook,
    //   externalLink: `https://www.facebook.com/groups/zylahealth/`,
    //   disabledIcon: Facebook,
    // },
    {
      id: "logout",
      name: "Logout",
      icon: logout,
      onClick: "logout",
      disabledIcon: logout,
    },
    {
      id: "settings",
      name: "Settings",
      icon: setting,
      onClick: "settings",
      path: "/settings",
      disabledIcon: setting,
    },
    {
      id: "Coustmer support",
      name: "Customer support",
      icon: coustmerSupport,
      onClick: "coustmer-support",
      path: "/coustmer-support",
      disabledIcon: setting,
    },
    {
      id: "TermsOfService",
      name: "Terms of service",
      icon: TermsAndConditions,
      onClick: "terms",
      path: "/terms",

      disabledIcon: TermsAndConditions,
    },
    {
      id: "PrivacyPolicy",
      name: "Privacy policy",
      icon: Privacy,
      onClick: "privacy",
      path: "/privacy",
      // externalLink: "https://zyla.in/privacy",
      disabledIcon: Privacy,
    },
  ];

  const oneMg = patient?.clientCode === "MG" ? true : false;
  const logoutFlag = paytmFlag || oneMg ? true : false;
  if (patient?.clientCode === "DI" || logoutFlag) {
    menuItems = menuItems.filter((ele) => ele.id !== "logout");
  }
  if (patient && patient.countryCode != "91") {
    menuItems = menuItems.filter((ele) => ele.id !== "Address book");
  }
  return menuItems;
};
export default function HamburgerPage() {
  const patientProfile = useSelector(
    (store) => store.login?.user?.patientProfile
  );
  const token = useSelector((store) => store.login.token);
  const paytmFlag = useSelector((store) => store.app.device.isPaytm);
  const uiConfig = useSelector((store) => store.tab.uiConfig);

  const [menuItems, setMenuItems] = useState(() =>
    getHamburgerMenuItems(patientProfile, paytmFlag)
  );
  const [showAlert, setShowAlert] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useIonViewDidEnter(() => {
    dispatch(logEvent({ action: "open_profile" }));
  }, []);

  const handleClick = (item) => {
    if (item.event_name) {
      dispatch(logEvent({ action: item.event_name }));
    }
    if (item.path) {
      history.push(item.path);
    } else if (item.onClick === "logout") {
      beforeLogout();
    } else if (item.externalLink) {
      window.open(item.externalLink, "_blank");
    }
  };

  const logoutApp = () => {
    const storageService = new StorgaeService();
    let user_id = patientProfile.id;
    let identity = storageService.get("identity");
    let url = APIConfig.user.logOut.format(user_id);
    let params = { token: identity };
    let expireUrl = APIConfig.user.expireToken;
    axiosClient
      .post(expireUrl, token)
      .then((res) => {})
      .catch((err) => {});

    if (identity === null) {
      dispatch({ type: "logout" });
      return;
    } else {
      axiosClient
        .post(url, params)
        .then((res) => {
          dispatch({ type: "logout" });
        })
        .catch((err) => {
          dispatch({ type: "logout" });
        });
    }
  };
  const beforeLogout = () => {
    setShowAlert(true);
  };

  return (
    <IonPage className="ion-tab-page">
      <IonContent style={{ "--background": "#F8F9FA" }}>
        <div className="name-details">
          <RippleButton>
            <div
              className="name-image"
              onClick={() => handleClick({ path: "/profile" })}
            >
              <div>
                <SecureImage
                  classN="avatar-profile"
                  src={patientProfile?.profileImage}
                  defaultImage={
                    patientProfile
                      ? patientProfile.gender === 1
                        ? FemaleActive
                        : MaleActive
                      : FemaleActive
                  }
                />
              </div>

              <div
                className={`profile-name fw-bold ${
                  (patientProfile.lastName
                    ? patientProfile.lastName.length
                    : 0) +
                    patientProfile.firstName.length >
                  24
                    ? "fs15"
                    : "fs24"
                }`}
              >
                <div>
                  {patientProfile.firstName}&nbsp;
                  {(patientProfile.lastName
                    ? patientProfile.lastName.length
                    : 0) +
                    patientProfile.firstName.length >
                  14 ? (
                    <br />
                  ) : null}
                  {patientProfile.lastName}
                </div>
                {/* <div>{patient.lastName}</div> */}
              </div>
            </div>
          </RippleButton>
        </div>
        <MembershipBanner stateMessage={uiConfig} />

        <div className="detail-screen">
          <div className="details-plan">
            <HealthDetails data={"goals"} />
            <HealthDetails data={"plan"} />
          </div>
          <div className="detail-head">
            {menuItems.map((item) => {
              let disabled = false;
              if (item.id === "reporting" && uiConfig.lockReporting) {
                disabled = true;
              }
              return (
                <MenuItem
                  key={item.id}
                  handleClick={handleClick}
                  disabled={disabled}
                  item={item}
                />
              );
            })}
          </div>

          <IonModal
            backdropDismiss={true}
            mode="md"
            showBackdrop={true}
            isOpen={showAlert}
            onClick={() => {
              console.log("clicked");
            }}
            // onWillPresent={()}
            onDidDismiss={() => setShowAlert(false)}
            cssClass={
              "ion-modal-general-class ion-modal-transparent-background"
            }
            aria-labelledby="form-dialog-title"
          >
            <IonModalWrapper>
              <div className="detail-screen-logout">
                <div className="logout__text text-[#171A21]">
                  Are you sure you want to log out of Max Fit app?
                </div>
                <div className="logout__button__div">
                  <p
                    className="logout__button__yes text-[#F27930]"
                    onClick={() => {
                      setShowAlert(false);

                      logoutApp();
                    }}
                  >
                    Yes Logout
                  </p>
                  <p
                    className="logout__button__cancel text-[#171A21]"
                    onClick={() => setShowAlert(false)}
                  >
                    Cancel
                  </p>
                </div>
              </div>
            </IonModalWrapper>
          </IonModal>
        </div>
      </IonContent>
    </IonPage>
  );
}
