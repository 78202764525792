import React from "react";
import foodPlate from "../../../assets/images/no-food-hp.png";
export default function NoSearchResult({}) {
  return (
    <div className="healthplan-foodlist-no-content-box">
      <img src={foodPlate} className="" alt="bg-img" />
      <p className="foodlist-search-no-food-found">No match found</p>
      <span className="foodlist-search-no-food-found-desc">
        We cannot find what you are looking for. Please search for something
        else
      </span>
    </div>
  );
}
