import AccessImage from "../../assets/images/reward-point.png";
import arrowBlack from "../../assets/icons/arrow-back-black.svg";
import { useHistory } from "react-router";
import { IonContent, IonPage } from "@ionic/react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { Route } from "react-router";
export default function RewardPoint() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  return (
    <IonPage>
      <IonContent>
        <>
          <div className="  items-center justify-center text-lightblack h-full">
            <div class=" flex p-3 items-center">
              <img
                src={arrowBlack}
                className="w-6 h-6"
                onClick={() => {
                  history.goBack();
                }}
              />
              <div className="px-3 font-bold text-base leading-5"> </div>
            </div>
            <div className="mt-20">
              {/* <Link to="/rewardPointPage"> */}
              <img
                src={AccessImage}
                alt="image"
                className="w-32 h-32 mx-auto pt-3 block"
                onClick={() => history.push("/rewardPointPage")}
              />
              {/* </Link> */}
              <div className="text-center justify-center p-5 text-xs font-medium text-lightblack mt-12">
                <span className="font-medium">
                  Your rewards are getting ready and will be accessible soon!
                </span>
                <br />
                <br />
                <span className="font-medium">
                  Till then continue your wellness journey with Max Fit.
                </span>
              </div>
            </div>
          </div>
        </>
      </IonContent>
    </IonPage>
  );
}
