import React from "react";
import { IonSlides, IonSlide } from "@ionic/react";
import ReduceMedicine from "../../assets/images/longTermGoals/1-small.png";
import BloodSugar from "../../assets/images/longTermGoals/2-small.png";
import ReduceHb1ac from "../../assets/images/longTermGoals/3-small.png";
import ImproveHealth from "../../assets/images/longTermGoals/4-small.png";
import ImproveSymptoms from "../../assets/images/longTermGoals/5-small.png";
import ReduceWeight from "../../assets/images/longTermGoals/6-small.png";

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  spaceBetween: -18,
};

export default class LongTermGoalsSlider extends React.Component {
  getImageSrcByName(name) {
    if (name === "Reduce Medicines") {
      return ReduceMedicine;
    } else if (name === "Improve Symptoms") {
      return ImproveSymptoms;
    } else if (name === "Improve Health") {
      return ImproveHealth;
    } else if (name === "Reduce weight") {
      return ReduceWeight;
    } else if (name.toLowerCase().indexOf("hb1ac") != -1) {
      return ReduceHb1ac;
    } else {
      return BloodSugar;
    }
  }

  render() {
    const { longTermGoals } = this.props;
    return longTermGoals.length ? (
      <IonSlides
        className="hide-bullet"
        pager={true}
        options={slideOpts}
        spaceBetween="20"
        onIonSlideReachEnd={() => {
          this.props.lastSlide();
        }}
      >
        {longTermGoals.map((goal, index) => {
          return (
            <IonSlide key={index}>
              <div className="slider-body leading-tight">
                <div className="slider-data">
                  <div className="fs18 fw-semibold slider-goal-name">
                    {goal.title}
                  </div>
                  <img
                    alt=""
                    className="slider-pic"
                    src={
                      goal.imageURL
                        ? goal.imageURL
                        : this.getImageSrcByName(goal.title)
                    }
                  ></img>

                  <div className="slider-desc">{goal.text}</div>
                </div>
              </div>
            </IonSlide>
          );
        })}
      </IonSlides>
    ) : null;
  }
}
