import React, { useEffect, useState } from "react";
import "./healthGoals.scss";

import LongTermGoals from "./longTermGoals";
import ShortTermCard from "./components/shortTermCard";
import { useHistory } from "react-router-dom";

import { IonSlides, IonSlide, IonPage, IonContent } from "@ionic/react";

import BackButton from "../../components/common/BackButton";

import { useDispatch, useSelector } from "react-redux";
import { logEvent } from "../../app/eventSlice";
import { fetchLongTermGoalsApi, fetchShortTermGoalsApi } from "./goalSlice";

let swiper = null;
const slideOpts = {
  initialSlide: 0,
  speed: 400,
  on: {
    beforeInit() {
      swiper = this;
    },
  },
};

function HealthGoalScreen() {
  const [currentTab, setCurrentTab] = useState(0);
  const longTermGoals = useSelector((store) => store.goals.longTermGoals);
  const shortTermGoals = useSelector((store) => store.goals.shortTermGoals);
  const paytmFlag = useSelector((store) => store.app.device.isPaytm);
  const patientProfile = useSelector(
    (store) => store.login.user?.patientProfile
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLongTermGoalsApi({ patientId: patientProfile?.id }));
    dispatch(fetchShortTermGoalsApi({ patientId: patientProfile?.id }));
  }, []);

  const setTab = (tab) => {
    swiper.slideTo(tab);
    setCurrentTab(tab);

    if (tab === 0) {
      dispatch(logEvent({ action: "open_long_term_goals_via_menu" }));
    } else {
      dispatch(logEvent({ action: "open_short_term_goals_via_menu" }));
    }
  };

  const slideChange = (e) => {
    setCurrentTab(swiper.activeIndex);
  };

  return (
    <IonPage>
      <IonContent className="hg-screen-container">
        <div className="hg-section">
          <div className="hg-header">
            {!paytmFlag && <BackButton></BackButton>}
            <div className="fw-semibold" style={{ marginLeft: "10px" }}>
              Health goals
            </div>
          </div>
          <div className="hg-tabs">
            <div
              onClick={() => setTab(0)}
              className={currentTab === 0 ? "hg-tab hg-selected-tab" : "hg-tab"}
            >
              Long term
            </div>
            <div
              onClick={() => setTab(1)}
              className={currentTab === 1 ? "hg-tab hg-selected-tab" : "hg-tab"}
            >
              For Next 15 Days
            </div>
          </div>
        </div>
        <div className="hg-body">
          <IonSlides
            className="hide-bullet"
            onIonSlideWillChange={() => {
              slideChange();
            }}
            pager={true}
            options={slideOpts}
          >
            <IonSlide key={1}>
              <div className="hg-long-term-content">
                <LongTermGoals longTermGoals={longTermGoals} />
              </div>
            </IonSlide>
            <IonSlide key={2}>
              <div className="hg-long-term-content">
                <div className="hg-short-header"></div>
                <div className="st-goals-list">
                  {shortTermGoals.map((goal) => {
                    return <ShortTermCard goal={goal} />;
                  })}
                </div>
              </div>
            </IonSlide>
          </IonSlides>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default HealthGoalScreen;
