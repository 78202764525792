import React, { Component } from "react";
import * as Sentry from "@sentry/react";
import clevertapLogger from "./clevertapLogger";
import "./errorPage.scss";

export default class ErrorPage extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
    });

    clevertapLogger.push("crash_event", { info: errorInfo, err: error });
    // cleverTapLogger.pushEvent("crash", { info: errorInfo, err: error });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="error_div">
          <h3 className="error_heading">Uh Oh!...Error 500!</h3>
          <span className="error_text">
            Something went wrong at our end. Don't worry, it's not you, it's us.
            Sorry about that.
          </span>
          <button
            className="homepage"
            onClick={() => window.open("/tab/chat", "_self")}
          >
            Go Back To Homepage
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}
