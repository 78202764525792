export function DiscountCard({ closeDiscountModel, showDiscount }) {
  return (
    <>
      <div
        className="fixed inset-0 bg-lightblack bg-opacity-50 transition-opacity cursor-pointer"
        id="modal-title"
      ></div>

      <div
        className="fixed inset-0 z-10 overflow-y-auto cursor-pointer "
        onClick={closeDiscountModel}
      >
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0 ">
          <div
            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl sm:my-8 sm:w-full sm:max-w-lg"
            onClick={(e) => {
              // do not close modal if anything inside modal content is clicked
              e.stopPropagation();
            }}
          >
            <div
              className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4  h-44 w-full
            "
            >
              <div className="text-base font-bold leading-6 text-lightblack">
                Discount
              </div>
              <div className="mt-2">
                <p className="text-sm text-lightblack">
                  <span className="text-base leading-5 font-normals">
                    Discount as % of
                    <br /> Annualised premium <br />
                    <br />
                    (For regular pay variant)
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
