import React from "react";
import noAppointmemt from "../../../assets/images/no-appointment-found.svg";
export function NoAppointmentFound({ msg }) {
  return (
    <div className="general-no-appointment-found-div">
      <h2
        className="general-no-appointment-found-h2"
        style={{ textAlign: `${msg ? "center" : "left"}` }}
      >
        {msg ? msg : "No appointment scheduled yet. Book an appointment now"}
      </h2>
      {/* <img src={noAppointmemt} className="no-past-appointment-found-img" /> */}
    </div>
  );
}
