import React from "react";
import noDocument from "../../../assets/images/no_document.png";
import { useSelector } from "react-redux";
export default function NoDocumentFolderExists({ onClickUpload }) {
  const paytmFlag = useSelector((store) => store.app.device.isPaytm);

  return (
    <div className="no-doc">
      <img src={noDocument} alt="bg-img"></img>
      {!paytmFlag && (
        <div className="no-doc-text">
          <p>Upload your first Document here.</p>
        </div>
      )}
      {paytmFlag && (
        <div className="no-doc-text">
          <p>No Documents uploaded </p>
        </div>
      )}
      {!paytmFlag && (
        <button className="prime-btn" onClick={() => onClickUpload()}>
          Upload Now
        </button>
      )}
    </div>
  );
}
