import React, { useRef, useState } from "react";
import backImage from "../../../assets/images/arrow-back.png";
import MaleActive from "../../../assets/images/Clipped.svg";
import FemaleActive from "../../../assets/images/femaleClipped.svg";
import { IonDatetime, IonSpinner } from "@ionic/react";
import { dateFormat } from "../../../utils/dateManager";

import ApiConfig from "../../../services/APIConfig";
import lockImage from "../../../assets/images/ic_lock_secondary.png";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import userService from "../../../services/UserService";
import moment from "moment";
import RippleButton from "../../../components/common/RippleButton";
import { useDispatch, useSelector } from "react-redux";
import { isvalidCred, isValidDOB } from "../profileUtilityFunc";
import { isValidEmail, processUplodedFile } from "../../../utils/helperMethods";
import ErrorComponent from "../../../components/common/ErrorComponent";
import SecureImage from "../../../components/common/SecureImage";
import { updateProfileImage } from "../../login/loginSlice";
import axiosClient from "../../../app/axiosConfig";
import { Link } from "react-router-dom";
import arrowBlack from "../../../assets/icons/arrow-back-black.svg";
import PrepareUploadService from "../../../services/PrepareUploadService";

export default function EditProfile({ hideEditMode }) {
  const platform = useSelector((store) => store.app.device.platform);
  const paytmFlag = useSelector((store) => store.app.device.isPaytm);
  const user = useSelector((store) => store.login.user);
  const patientProfile = useSelector(
    (store) => store.login.user?.patientProfile
  );
  const [firstName, setFirstName] = useState(patientProfile?.firstName);
  const [lastName, setLastName] = useState(patientProfile?.lastName);
  const [emailId, setEmailId] = useState(patientProfile?.email);
  const [contact, setContact] = useState(patientProfile?.phoneno);
  const [dateOfBirth, setDateOfBirth] = useState(patientProfile?.dateOfBirth);
  const [image, setImage] = useState(patientProfile?.profileImage);
  const [gender, setGender] = useState(patientProfile?.gender);
  const [imageSrc, setImageSrc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [isImageUpdated, setIsImageUpdated] = useState(false);
  const inputFileRef = useRef(null);
  const maxDateRef = useRef(
    moment().subtract(18, "years").format("YYYY-MM-DD")
  );
  const minDateRef = useRef(
    moment().subtract(100, "years").format("YYYY-MM-DD")
  );
  const dispatch = useDispatch();

  const handleDateChanged = (e) => {
    let selected = moment(e.target.value).format("YYYY-MM-DD");
    let today = moment().format("YYYY-MM-DD");
    if (moment(selected).isBefore(today)) {
      setDateOfBirth(e.target.value);
    } else {
      handleProfileError(true, "Please select valid Date of birth");
    }
  };

  const handleProfileError = (err, msg) => {
    setError(err);
    setErrorMsg(msg);
  };

  const handleSave = () => {
    let pId = Number(patientProfile.id);
    let payload = {
      firstName: firstName,
      lastName: lastName,
      gender,
      email: emailId.trim(),
      dateOfBirth,
    };
    if (!isValidDOB(dateOfBirth)) {
      //set error msg here
      handleProfileError(true, "Please Select Correct Date of birth");
    } else if (!isvalidCred(firstName)) {
      handleProfileError(true, "Please enter a valid first name");
    } else if (!isvalidCred(lastName)) {
      handleProfileError(true, "Please enter a valid last name");
    } else if (!isValidEmail(emailId)) {
      handleProfileError(true, "Please enter a valid Email Id");
    } else {
      setLoading(true);
      let url = ApiConfig.patientsData.updatePatientData.format(pId);
      axiosClient
        .put(url, payload)
        .then((res) => {
          setLoading(false);
          hideEditMode();
        })
        .catch((err) => {
          setLoading(false);
          setError(true);
          setErrorMsg("Error while updating profile.");
        });
    }
  };

  const updateImage = () => {
    if (window.Capacitor.platform === "ios") {
      takePicture();
    } else {
      if (window.Capacitor.platform === "android") {
        PrepareUploadService.selectFiles(false)
          .then((res) => {
            let file = null;
            if (
              res.fileResponse.target.files &&
              res.fileResponse.target.files.length > 0
            ) {
              uploadImageToServer(
                res.fileResponse.target.files[0].file,
                res.errors
              );
            } else {
              handleProfileError(true, "No Image selected");
            }
          })
          .catch((err) => {
            handleProfileError(true, "Error while selecting image.");
          });
      } else {
        if (inputFileRef.current) {
          inputFileRef.current.click();
        }
      }
    }
  };
  const uploadImageToServer = (file, filename) => {
    setLoading(true);
    userService
      .uploadProfilePic(file, filename, null, user)
      .then((result) => {
        const response = result.data;
        var objectURL = URL.createObjectURL(file);
        let random = Math.random().toString(36).substring(7);
        dispatch(updateProfileImage(`${response}?v=${random}`));
        setImageSrc(objectURL);
        setLoading(false);
        setIsImageUpdated(true);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const onSelectFileToUpload = (e) => {
    const file = e.target.files[0];
    processUplodedFile(file)
      .then((res) => {
        uploadImageToServer(res.blob, file.name);
      })
      .catch((err) => {
        handleProfileError(true, "Error while processing image.");
      });
  };
  const takePicture = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      correctOrientation: false,

      resultType: CameraResultType.Uri,
    });

    if (image) {
      let fileExtension = image.webPath.substring(
        image.webPath.lastIndexOf(".") + 1,
        image.webPath.length
      );
      let fileName = Math.random() * (9999999 - 1111111) + 1111111;

      const blob = await fetch(image.webPath).then((r) => r.blob());
      uploadImageToServer(blob, `${fileName}.${fileExtension}`);
    }
  };

  return (
    <div className="edit-profile-data">
      <ErrorComponent
        show={error}
        msg={errorMsg}
        errorClose={() => {
          handleProfileError(false, "");
        }}
      />
      {loading && (
        <div className="edit-profile-loading-class">
          <IonSpinner />
        </div>
      )}

      {!loading && (
        <div>
          <div className="edit-head">
            <div className="header-edit ">
              <div className="flex items-center py-3 px-2">
                {!paytmFlag && (
                  <RippleButton nospace onClick={hideEditMode}>
                    <Link to="/tab/hamburger">
                      <img src={arrowBlack} className="w-6 h-6 mr-3" />
                    </Link>
                  </RippleButton>
                )}

                <div className="fs18 fw-semibold header-edit-title -mt-1">
                  Personal details
                </div>
              </div>
              {/* <div className="edit-save">
                <button onClick={handleSave} className="prime-btn">
                  <RippleButton>Save</RippleButton>
                </button>
              </div> */}
            </div>
          </div>
          <div className="upload-img-content">
            <div className="img-backcover">
              <div onClick={updateImage} className="edit-image">
                Edit
              </div>

              {isImageUpdated ? (
                <img
                  onClick={updateImage}
                  className={"upload-img-avatar"}
                  src={imageSrc}
                  alt="profile-img"
                />
              ) : (
                <SecureImage
                  src={image}
                  defaultImage={gender === 1 ? FemaleActive : MaleActive}
                />
              )}
            </div>

            <input
              ref={(ref) => (inputFileRef.current = ref)}
              hidden
              accept="image/*"
              onChange={(e) => {
                onSelectFileToUpload(e);
              }}
              type="file"
            ></input>
          </div>
          <div className="edit-profile">
            <div className="fs12 placehol-profile">First name</div>
            <input
              style={{ textTransform: "capitalize" }}
              onChange={(e) => setFirstName(e.target.value)}
              disabled={true}
              value={firstName}
              className="data-profile fs18 fw-light disabled:bg-white"
            />
            <div className="fs12 placehol-profile">Last name </div>
            <input
              style={{ textTransform: "capitalize" }}
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              disabled={true}
              className="data-profile fs18 fw-light disabled:bg-white"
            />
            <div className="fs12 placehol-profile">Email Id</div>
            <div className="disabled-selection">
              <input
                onChange={(e) => setEmailId(e.target.value)}
                value={emailId}
                disabled={true}
                className="data-profile fs18 fw-light disabled:bg-white"
                placeholder="Email Id"
              />
            </div>
            <div className="fs12 placehol-profile">Mobile number</div>
            <div className="disabled-selection contact-ip-profile">
              <input
                value={`+91-${contact}`}
                onChange={(e) => setContact(e.target.value)}
                className="data-profile contact fs18 fw-light"
              />
            </div>
            <div className="fs12 placehol-profile">Gender</div>
            <div className="fs18 gender-tabs">
              <div
                // onClick={() => setGender(1)}
                className={
                  gender === 1 ? "selected-gender-tab gender-tab" : "gender-tab"
                }
              >
                Female
              </div>
              <div
                // onClick={() => setGender(2)}
                className={
                  gender === 2 ? "selected-gender-tab gender-tab" : "gender-tab"
                }
              >
                Male
              </div>
            </div>
            {/* <div className="fs12 placehol-profile">Date of Birth</div>
            <div className="dob-info">
              <IonDatetime
                value={dateFormat(dateOfBirth)}
                onIonChange={handleDateChanged}
                disabled={true}
                displayFormat="DD MMMM YYYY"
                monthShortNames="Jan, Feb, Mar, Apr, May, June, July, Aug, Sep, Oct, Nov, Dec"
                placeholder="10/08/1980"
                max={maxDateRef.current}
                min={minDateRef.current}
              />
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
}
