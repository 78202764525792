import React, { useEffect } from "react";
import ReduceMedicine from "../../assets/images/longTermGoals/1.png";
import BloodSugar from "../../assets/images/longTermGoals/2.png";
import ReduceHb1ac from "../../assets/images/longTermGoals/3.png";
import ImproveHealth from "../../assets/images/longTermGoals/4.png";
import ImproveSymptoms from "../../assets/images/longTermGoals/5.png";
import ReduceWeight from "../../assets/images/longTermGoals/6.png";

import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { logEvent } from "../../app/eventSlice";

export default function LongTermGoals({ longTermGoals }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logEvent({ action: "click_long_term_goal" }));
  }, []);

  const getImageSrcByName = (name) => {
    if (name === "Reduce Medicines") {
      return ReduceMedicine;
    } else if (name === "Improve Symptoms") {
      return ImproveSymptoms;
    } else if (name === "Improve Health") {
      return ImproveHealth;
    } else if (name === "Reduce weight") {
      return ReduceWeight;
    } else if (name.toLowerCase().indexOf("hb1ac") != -1) {
      return ReduceHb1ac;
    } else {
      return BloodSugar;
    }
  };

  return (
    <Grid container className="long-term-goal-list">
      {longTermGoals &&
        longTermGoals.map((longTermGoal) => {
          return (
            <Grid item xs={6} className="card-long-term" key={longTermGoal.id}>
              <div className="fw-semibold long-goal-name">
                {longTermGoal.title}
              </div>
              <img
                alt=""
                className="long-term-img"
                src={
                  longTermGoal.thumbnailURL
                    ? longTermGoal.thumbnailURL
                    : getImageSrcByName(longTermGoal.title)
                }
              />
            </Grid>
          );
        })}
    </Grid>
  );
}
