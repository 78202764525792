import React, { useState } from "react";
import ratingImg from "../../../../assets/images/rating-top-img.svg";
import ratingTopStar from "../../../../assets/images/rating-top-star.svg";
import filledStar from "../../../../assets/icons/filled-star-icon.svg";
import unfilledStar from "../../../../assets/icons/unfilled-star-icon.svg";
import poorEmoji from "../../../../assets/icons/poor-rating-icon.svg";
import unsatEmoji from "../../../../assets/icons/unsat-rating-icon.svg";
import satEmoji from "../../../../assets/icons/sat-rating-icon.svg";
import goodEmoji from "../../../../assets/icons/good-rating-icon.svg";
import exceEmoji from "../../../../assets/icons/exce-rating-icon.svg";
import "./rating.scss";
import { useDispatch } from "react-redux";
import { sendFeedback, setNextFeedbackTime } from "../../chatSlice";
import { logEvent } from "../../../../app/eventSlice";

const ratingStarsArr = [
  {
    id: 1,
    text: "Poor",
    description: "Nah! Not liking it.",
    emoji: poorEmoji,
  },
  {
    id: 2,
    text: "Unsatisfactory",
    description: "Umm... Not enoying much.",
    emoji: unsatEmoji,
  },
  {
    id: 3,
    text: "Satisfactory",
    description: "Average, I expected more. ",
    emoji: satEmoji,
  },
  {
    id: 4,
    text: "Good",
    description: "Nice, I like the experience.",

    emoji: goodEmoji,
  },
  {
    id: 5,
    text: "Excellent",
    description: "Amazing, I love the experience!",
    emoji: exceEmoji,
  },
];

export default function RatingDialog({ handleClose, user, errorRating }) {
  const dispatch = useDispatch();
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [selectedRating, setSelectedRating] = useState({
    ...ratingStarsArr[4],
  });

  const handleFinalSubmit = () => {
    if (feedbackSubmitted) {
      dispatch(
        logEvent({
          action: "click_rate_now",
        })
      );

      if (window.Capacitor.platform !== "ios") {
        window.open(
          // "https://play.google.com/store/apps/details?id=com.zylahealth.zylapatient",
          // "_blank"
          " https://play.google.com/store/apps/details?id=com.max.fit.zyla",
          "_blank"
        );
      } else {
        window.open(
          // "https://apps.apple.com/us/app/zyla-your-24x7-health-expert/id1496316750",
          // "_blank"
          "https://apps.apple.com/in/app/max-fit/id6464424564",
          "_blank"
        );
      }
      handleClose();
    } else {
      let ratText = "";
      try {
        let textNode = document.getElementById("rating-content-editable-text");
        let tempText = textNode.textContent;
        if (tempText && tempText.length > 0) {
          ratText = tempText;
        }
      } catch (err) {}
      let finalFeedbackText = `${ratText} Rating: ${selectedRating.id}`;
      const req = {
        feedback: finalFeedbackText,
        who: "patient",
        phoneno: `${user?.phoneNo}`,
        patientId: `${user?.patientProfile?.id}`,
        countryCode: `${user?.countrycode}`,
      };
      dispatch(sendFeedback(req))
        .unwrap()
        .then((res) => {
          setFeedbackSubmitted(true);
        })
        .catch((err) => {
          handleClose();
        });
    }
  };

  return (
    <div className="rating-star-rating-dialog-container">
      <div className="rating-star-rating-dialog-screen-1">
        <div className="rating-star-rating-dialog-screen-1-top">
          {/* <img
            src={ratingImg}
            alt=""
            className="rating-star-top-rating-screen-1-top-main-img"
          /> */}
          {/* <img
            src={ratingTopStar}
            alt=""
            className="rating-star-top-rating-screen-1-top-star-img"
          /> */}
        </div>
        <div className="rating-star-rating-dialog-screen-1-bottom">
          {!feedbackSubmitted && (
            <React.Fragment>
              <p className="rating-star-rating-dialog-screen-1-bottom-top">
                We’d love your feedback!
              </p>
              <p className="rating-star-rating-dialog-screen-1-bottom-mid">
                Rate your Max Fit experience on a scale of 1-5 stars. 5 being
                the highest.
              </p>
              <div className="rating-star-rating-dialog-screen-1-2-star-list ">
                {ratingStarsArr.map((starEle, index) => {
                  return (
                    <div
                      className="rating-star-rating-dialog-screen-1-2-star-list-ele"
                      key={starEle.id}
                      onClick={() => {
                        setSelectedRating({ ...starEle });
                        dispatch(logEvent({ action: "show_popup_rating" }));
                      }}
                    >
                      <img
                        alt=""
                        src={
                          index < selectedRating.id ? filledStar : unfilledStar
                        }
                      />
                      <p>{starEle.text}</p>
                    </div>
                  );
                })}
              </div>
              <div className="rating-dialog-screen-2-mid">
                <img alt="" src={selectedRating.emoji} />
                <p>{selectedRating.description}</p>
              </div>
              <div className="rating-dialog-screen-2-bottom">
                <p
                  data-placeholder="Share your feedback here..."
                  contentEditable
                  id="rating-content-editable-text"
                ></p>
              </div>
              {errorRating && (
                <p className="rating-dialog-error">
                  Something went wrong.please try again later.
                </p>
              )}
            </React.Fragment>
          )}
          {feedbackSubmitted && (
            <>
              <p className="rating-dialog-screen-2-top-text">
                Thank you for sharing your feedback!
              </p>
              <p className="rating-dialog-screen-2-mid-text">
                Share your kind words with the world and encourage them to have
                the same experience
              </p>
            </>
          )}
          <div className="rating-star-rating-dialog-screen-1-bottom-bottom">
            <button
              className="rating-star-rating-dialog-screen-1-bottom-submit rating-button-common-css"
              onClick={handleFinalSubmit}
            >
              {feedbackSubmitted
                ? window.Capacitor.platform !== "ios"
                  ? "Rate now on Play store"
                  : "Rate now on App store"
                : "Submit"}
            </button>
            {!feedbackSubmitted && (
              <button
                className="rating-star-rating-dialog-screen-1-bottom-remind rating-button-common-css"
                onClick={() => {
                  dispatch(
                    setNextFeedbackTime({ submitted: false, time: Date.now() })
                  );
                  handleClose();
                }}
              >
                Remind me later
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
