import { useState } from "react";
import dropdownImage from "../../../assets/images/forwardRed.png";
import { IonContent, IonPage } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { homePlanData } from "../homePageSlice";
import { data } from "jquery";
import DOMPurify from "dompurify";
import moment from "moment";
const policyDetail = [
  {
    text: "Premium payment term:",
    key: "premiumPaymentTerm",
    unit: "year",
  },
  {
    text: "Policy term:",
    key: "policyTerm",
    unit: "year",
  },
  {
    text: " Next due date : ",
    key: "nextDueDate",
    unit: "",
  },
  {
    text: "Policy modal premium (excl of GST):   ",
    key: "policyModalPremium",
    unit: "Rs.",
  },
  {
    text: "Accrued benefits:",
    key: "policyAccountValue",
    unit: "Rs.",
  },
  {
    text: "Health sum assured:  ",
    key: "sumAssured",
    unit: "Rs.",
  },
  {
    text: "Death benefit at inception:",
    key: "deathBenefit",
    unit: "Rs.",
  },
  {
    text: "Hospitalization claim left (Days in a policy year): ",
    key: "hospitalizationClaimLeft",
    unit: "",
  },
  {
    text: "Surgical claim left <sup>#</sup> (No. of surgeries):",
    key: "surgicalClaimLeft",
    unit: "",
  },
];
function PolicyItemDetail({ text, value, unit }) {
  return (
    <div className="rounded-md  font-bold text-xs   grid grid-cols-2  space-x-[76px]  py-2  ">
      <div className="px-5">
        {" "}
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(text),
          }}
        ></span>
      </div>

      <div className="text-xs leading-4 font-medium text-lightblack  px-1    ">
        {unit === "Rs." ? (
          <>
            <span className="mr-1"> {unit}</span>
            <span>{value}</span>
          </>
        ) : (
          <>
            <span>{value}</span>
            <span className="ml-1">{unit}</span>
          </>
        )}
      </div>
    </div>
  );
}
export const OtherPolicyDetail = ({ selector, selectedIndex }) => {
  data = selector.response.payload.hsaPlan[selectedIndex];

  const [isOpen, setIsOpen] = useState(true);

  return (
    <div
      className="outline outline-2 rounded-md  mt-8 text-lightblack "
      style={{ outlineColor: "#D9D9D9" }}
    >
      <div
        className="p-3 text-base leading-5 font-bold flex justify-between cursor-pointer "
        onClick={() => setIsOpen(!isOpen)}
      >
        Other policy details
        <img
          src={dropdownImage}
          alt=""
          className={`w-6 h-6 ${isOpen ? "-rotate-90" : "rotate-90 "}`}
        />
      </div>
      <hr className="bg-gray-200 w-full" />
      {isOpen && (
        <>
          {policyDetail.map((d) => {
            return (
              <PolicyItemDetail
                text={d.text}
                // value={data[d.key]}
                value={
                  d.key === "nextDueDate"
                    ? moment(data[d.key]).format("DD MMM YYYY")
                    : data[d.key] || d.key === "premiumPaymentTerm"
                    ? parseInt(data[d.key])
                    : data[d.key] ||
                      (d.key === "policyAccountValue" &&
                        (data[d.key] === undefined || data[d.key] === ""))
                    ? "NA"
                    : data[d.key]
                }
                unit={
                  d.key === "policyAccountValue" &&
                  (data[d.key] === undefined || data[d.key] === "")
                    ? ""
                    : d.unit
                }
              />
            );
          })}
        </>
      )}
      <hr className="bg-gray-200 w-full " />
      <div className="pt-8 px-3 py-5 font-semibold text-xs">
        *For claiming money under fixed hospitalization cash benefit or fixed
        surgical care benefit, please call 1800-210-6862 or mail to
        customercare@mdindia.com
        <br />
        <br /> #Subject to max. of 20% of sum assured in a<br /> policy year.
      </div>
    </div>
  );
};
