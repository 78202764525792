import React, { useEffect } from "react";
import { IonContent, IonPage } from "@ionic/react";
import "./index.scss";
import { useDispatch } from "react-redux";

function SessionExpired() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "logout" });
  }, []);

  return (
    <IonPage>
      <IonContent>
        <div className="error-page-session-expired">
          <h3>Session Expired !</h3>
          <h6>
            Your session has been expired. Please close the app and try again.
          </h6>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default SessionExpired;
