import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axiosClient from "../../app/axiosConfig";
import APIConfig from "../../services/APIConfig";

const documentAdapter = createEntityAdapter({
  selectId: (f) => f.type,
});

const initialState = {
  documents: documentAdapter.getInitialState(),
  documentCached: {},
  documentsFetched: false,
  ui: {
    error: undefined,
    errorMsg: "",
    loading: true,
    loaded: false,
    uploadLoading: false,
  },
};

export const getDocumentById = createAsyncThunk(
  "document/getDocumentById",
  async (pid, { rejectWithValue }) => {
    try {
      const url = APIConfig.document.getDoc.format(pid);
      const response = axiosClient.get(url);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllDocuments = createAsyncThunk(
  "document/getAllDocuments",
  async (data, { rejectWithValue }) => {
    try {
      let url = APIConfig.document.getByPatientDoc.format(data.id);
      const response = await axiosClient.get(url);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    toggleDocumentError: (state, action) => {
      state.ui = {
        ...state.ui,
        error: action.payload.data,
        errorMsg: action.payload.msg,
      };
    },
  },
  extraReducers: {
    [getDocumentById.fulfilled]: (state, action) => {
      state.documentCached = {
        ...state.documentCached,
        [action.meta.arg]: action.payload.data,
      };
    },

    [getAllDocuments.fulfilled]: (state, action) => {
      let data = action.payload.data;
      state.documentsFetched = true;
      // let allDocs = [];
      // if (data && data.length > 0) {
      //   for (let i = 0; i < data.length; i++) {
      //     allDocs.push(...data[i].all);
      //   }
      // }
      documentAdapter.removeAll(state.documents);
      documentAdapter.addMany(state.documents, data);
    },
    // [getAllDocuments.rejected]: (state, action) => {
    //   state = {
    //     documentsFetched: false,
    //     ui: {
    //       ...state.ui,
    //       loading: false,
    //       loaded: false,
    //       error: true,
    //       errorMsg: "Error while fetching documents.",
    //     },
    //   };
    // },
  },
});
export const { toggleDocumentError } = documentSlice.actions;
export default documentSlice.reducer;
