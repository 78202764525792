import React, { useState } from "react";
import { IonModal } from "@ionic/react";
import YogaPage from "./YogaPage";
import { useDispatch } from "react-redux";
import { logEvent } from "../../../app/eventSlice";

export default function OfferCard({ carousel, sendMessage }) {
  const dispatch = useDispatch();
  const [openYoga, setOpenYoga] = useState(false);

  const handleOfferClick = (offer) => {
    if (offer.action === "webview" || offer.action === "offer") {
      dispatch(
        logEvent({ action: "click_offer_banner", data: { url: offer.url } })
      );
      window.open(offer.url);
    } else if (offer.action === "yoga") {
      dispatch(
        logEvent({
          action: "click_offer_banner_yoga",
        })
      );

      setOpenYoga(true);
    } else if (offer.action === "chat") {
      if (offer.title) {
        sendMessage(offer.title);
      }
    }
  };
  const handleYoga = () => {
    setOpenYoga(false);
  };

  return (
    <>
      {carousel && carousel.length > 0 && (
        <p className="offer-preview-card-box-heading">My plan</p>
      )}
      <div className="offer-preview-card-box">
        {carousel &&
          carousel.length > 0 &&
          carousel.map((carouselEle) => {
            return (
              <div
                className="offer-preview-card"
                onClick={() => {
                  handleOfferClick(carouselEle);
                }}
              >
                <div className="offer-preview-card-container">
                  <img
                    alt=""
                    src={
                      carouselEle.action === "offer"
                        ? carouselEle.cover
                        : carouselEle.image
                    }
                  />
                </div>
              </div>
            );
          })}
        <IonModal isOpen={openYoga} className="home-yoga-page-modal">
          <YogaPage handleClose={handleYoga} />
        </IonModal>
      </div>
    </>
  );
}
