import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import walletServices from "../../services/WalletServices";
import moment from "moment";
const intialState = {
  walletInfo: {},
  walletId: null,
  walletLoaded: false,
  walletLastFiveTransactions: [],
  rangeTransactions: [],
  loading: false,
  ui: {
    error: false,
    errorMsg: "",
    isErrorPositive: false,
    screen: 1,
  },
};

export const getWalletData = createAsyncThunk(
  "wallet/data",
  async (data, { rejectWithValue }) => {
    try {
      const response = await walletServices.getOrCreateWallet();
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getWalletTransactionByRange = createAsyncThunk(
  "wallet/rangeTransacations",
  async (data, { rejectWithValue }) => {
    try {
      const response = await walletServices.getWalletTransactions(
        data.id,
        data.count,
        data.range
      );
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const walletSlice = createSlice({
  initialState: intialState,
  name: "wallet",
  reducers: {
    updateErrorState: (state, actions) => {
      const { data, msg, isErrorPositive } = actions.payload;
      return {
        ...state,
        ui: {
          ...state.ui,
          error: data,
          errorMsg: msg,
          isErrorPositive: isErrorPositive,
        },
      };
    },
    handleScreenChanges: (state, actions) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          screen: actions.payload,
        },
      };
    },
  },
  extraReducers: {
    [getWalletData.fulfilled]: (state, actions) => {
      return {
        ...state,
        walletInfo: { ...actions.payload.data },
        walletId: actions.payload.data.id,
        walletLoaded: true,
      };
    },
    [getWalletData.rejected]: (state, actions) => {
      return {
        ...state,
        walletLoaded: true,
        ui: {
          ...state.ui,
          error: true,
          errorMsg: "",
          isErrorPositive: false,
        },
      };
    },
    [getWalletTransactionByRange.fulfilled]: (state, actions) => {
      const result = actions.payload.data;
      let transactions = [];
      if (result && result.data && result.data.length > 0) {
        transactions = [...result.data];
      }
      let type = 1;
      let range = "Do YYYY";
      if (actions.meta.arg.isHome) {
        range = "DD/MM/YYYY";
        type = 2;
      }
      let allTransactions = transactions.map((tr) => {
        return { ...tr, date: moment(tr.createdAt).format(range) };
      });
      return {
        ...state,
        walletLastFiveTransactions:
          type === 2 ? allTransactions : state.walletLastFiveTransactions,
        rangeTransactions:
          type === 1 ? allTransactions : state.rangeTransactions,
        loading: false,
      };
    },
    [getWalletTransactionByRange.rejected]: (state, actions) => {
      return {
        ...state,
        loading: false,
        ui: {
          ...state.ui,
          error: true,
          errorMsg: "Error while fetching wallet transactions.",
          isErrorPositive: false,
        },
      };
    },
  },
});

export const { updateErrorState, handleScreenChanges } = walletSlice.actions;
export default walletSlice.reducer;
