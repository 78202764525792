import backImage from "../../../assets/images/back.png";
import dropdownImage from "../../../assets/images/dropdownRed.png";
import Successimage from "../../../assets/images/greenSuccess.png";
import correctImage from "../../../assets/images/correct.png";
import { IonContent, IonPage } from "@ionic/react";
import { Link } from "react-router-dom";
import { useState } from "react";
function MyPlan({ v, index }) {
  return (
    <>
      <div className=" flex p-3 items-center space-x-1">
        <span className="font-bold text-xs leading-5"> {v.heading}</span>
        <div className="text-xs leading-5 font-medium  ">{v.text}</div>
        {index === 1 ? (
          <img src={v.image.image} alt="" className="h-3 w-3 " />
        ) : null}
      </div>
      {index === 4 ? (
        <>
          <div className="font-bold text-xs leading-5 px-3 ">
            {v.wellness.heading}
          </div>
          <div className=" flex items-center px-3 text-xs leading-5 font-normal">
            <img src={v.wellness.image} alt="" className="w-4 h-4" />
            <div className="px-3"> {v.wellness.text1}</div>
          </div>
          <div className="flex items-center px-3 text-xs leading-5 font-normal">
            <img src={v.wellness.image} alt="" className="w-4 h-4" />
            <div className="px-3">{v.wellness.text2}</div>
          </div>
          <div className=" flex items-center px-3 text-xs leading-5 font-normal">
            <img src={v.wellness.image} alt="" className="w-4 h-4" />
            <div className="px-3"> {v.wellness.text3}</div>
          </div>
        </>
      ) : null}
    </>
  );
}
export const MYPlan2 = () => {
  const [isOpen, setIsOpen] = useState(true);
  const policy = [
    {
      id: 1,
      heading: "Product name:",
      text: "Max Life Critical Illness and Disability Rider",
    },
    {
      id: 2,
      heading: "Policy status: ",
      text: "Active",
      image: {
        image: Successimage,
      },
    },
    {
      id: 3,
      heading: "Issuance date: ",
      text: "10 Jan 2023",
    },
    {
      id: 4,
      heading: "Expiry date: ",
      text: "09 Jan 2024",
    },
    {
      id: 5,
      heading: "Healthy Weeks count: ",
      text: "12 Weeks",
      wellness: {
        heading: "Wellness Benefits: ",
        image: correctImage,
        text1: "Doctor on call",
        text2: "24*7 chat",
        text3: "  Health Risk Assessment and much more",
      },
    },
  ];
  return (
    <IonPage>
      <IonContent>
        <div className="text-lightblack px-4">
          <div className="flex items-center p-2">
            <Link to="/tab/home">
              <img src={backImage} alt="icon" className="w-6 h-6" />
            </Link>
            <div className="px-2 font-bold text-base leading-7 ">My plan</div>
          </div>
          <div
            className="outline outline-2 rounded-md flex justify-between mt-2"
            style={{ outlineColor: "#D9D9D9" }}
          >
            <div className="p-2 text-xs leading-5 font-normal">
              <span className="font-bold text-xs leading-5">Policy:</span>
              Gh126X1201
            </div>
            <div
              className="p-2 cursor-pointer"
              onClick={() => setIsOpen(!isOpen)}
            >
              <img
                src={dropdownImage}
                alt=""
                className={` w-6 h-6 ${isOpen ? "rotate-180 delay-75" : ""}`}
              />
            </div>
          </div>
          {isOpen && (
            <>
              {policy.map((v, index) => {
                return <MyPlan v={v} index={index} />;
              })}
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};
