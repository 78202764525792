import axios from "axios";
import axiosClient from "../app/axiosConfig";
import APIConfig from "./APIConfig";
class HomeService {
  getTestimonyByPatientId(id) {
    let url = APIConfig.premium.testimonials.format(id);
    return axiosClient.get(url);
  }
  getHomePageData(id, onSuccess, onError) {
    let url = APIConfig.freemium.homepage.format(id);
    return axios.get(url, {
      headers: {
        origin: "*",
      },
    });
  }
  getHomePagePlanData(clientId) {
    let url = APIConfig.user.getPlans.format(clientId);
    return axiosClient.get(url);
  }

  getRecipeData(id) {
    const url = APIConfig.freemium.recipe.format(id);
    return axios.get(url, {
      headers: {
        origin: "*",
      },
    });
  }
  getUpcomingYoga(id) {
    const url = APIConfig.freemium.yoga.upcoming.format(id);
    return axios.get(url, {
      headers: {
        origin: "*",
      },
    });
  }
  getPastYoga(id) {
    const url = APIConfig.freemium.yoga.past.format(id);
    return axios.get(url, {
      headers: {
        origin: "*",
      },
    });
  }
  getHomePageBlogs(id) {
    const url = APIConfig.premium.blogs.format(id);
    return axios.get(url, {
      headers: {
        origin: "*",
      },
    });
  }
}

const homeService = new HomeService();
export default homeService;
