import axiosClient from "../app/axiosConfig";
import APIConfig from "./APIConfig";

class TrackerService {
  getStepsByDate(fromdate, todate, id) {
    let patientId = id;
    let url = APIConfig.tracker.chart.getSteps.format(
      patientId,
      new Date(fromdate).toISOString(),
      new Date(todate).toISOString()
    );
    return axiosClient.get(url);
  }
  getSleepByDate(fromdate, todate, id) {
    let patientId = id;
    let url = APIConfig.tracker.chart.getSleep.format(
      patientId,
      new Date(fromdate).toISOString(),
      new Date(todate).toISOString()
    );
    return axiosClient.get(url);
  }
  getTargetByDate = (id, date) => {
    let url = APIConfig.tracker.dailyTargets.format(id, date);
    return axiosClient.get(url);
  };
  getLastSynced = (id) => {
    let url = APIConfig.tracker.sync.lastSync.format(id);
    return axiosClient.get(url);
  };
  postSyncedSteps = (id, params) => {
    let url = APIConfig.tracker.sync.syncSteps.format(id);
    return axiosClient.post(url, params);
  };
  postSyncedSleep = (id, params, onSuccess, onError) => {
    let url = APIConfig.tracker.sync.syncSleep;
    return axiosClient.post(url, params);
  };

  postSleepData = (params, onSuccess, onError) => {
    let url = APIConfig.tracker.sendData.sleep;
    return axiosClient.post(url, params);
  };
  postStepsData = (params, onSuccess, onError) => {
    let url = APIConfig.tracker.sendData.steps;
    return axiosClient.post(url, params);
  };
}

let trackerService = new TrackerService();
export default trackerService;
