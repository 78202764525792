import { IonContent, IonPage, IonSpinner } from "@ionic/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import AuthenticationService from "../../services/AuthenticationService";
import digitService from "../../services/DigitService";
import { updateUserFromAutoLogin } from "../login/loginSlice";

function Digit() {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    let url = window.location.search;
    let searchParams = new URLSearchParams(url);
    let redirect = searchParams.get("redirect");
    let phoneNo = searchParams.get("user_id");
    let token = searchParams.get("idToken");

    if (phoneNo && token) {
      digitService
        .digitAutoLogin(phoneNo, token)
        .then((res) => {
          const authService = new AuthenticationService();
          const data = res.data;
          let profile = data.user;
          authService.activateLogin(data);
          dispatch(
            updateUserFromAutoLogin({ user: data.user, token: data.token })
          );
          digitService.getPatientPlan(
            profile.patientProfile.id,
            (res) => {
              if (res) {
                history.replace("/tab");
              } else {
                if (redirect === "diabetes") {
                  history.replace("/diabetes");
                } else if (redirect === "womens") {
                  history.replace("/womenhealth");
                } else if (redirect === "covid") {
                  history.replace("/covid19-care");
                } else if (redirect === "weight") {
                  history.replace("/weight-management");
                } else if (redirect === "hearthealth") {
                  history.replace("/hearthealth");
                } else {
                  history.replace("/diabetes");
                }
              }
            },
            (err) => {
              console.log("cannot get patient current status");
              history.replace("/diabetes");
            }
          );
        })
        .catch((err) => {
          //TODO clear cookies as well here
          history.replace("/login");
        });
    } else {
      history.replace("/login");
    }
  }, []);

  return (
    <IonPage>
      <IonContent>
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IonSpinner />
        </div>
      </IonContent>
    </IonPage>
  );
}

export default Digit;
