import ApiService from "./ApiService";
import StorageService from "./StorageService";
import APIConfig from "./APIConfig";
import moment from "moment";
import axiosClient from "../app/axiosConfig";

class DocumentService {
  constructor() {
    this.apiService = new ApiService();
    this.storageService = new StorageService();
  }
  getToken(onSuccess) {
    let tokenObj = this.storageService.get("TOKEN");
    if (tokenObj && tokenObj.expiredTime > moment.utc().valueOf()) {
      onSuccess(tokenObj.token);
    } else {
      this._generateToken(onSuccess);
    }
  }
  _generateToken(onSuccess, onError) {
    this.apiService.get(
      APIConfig.document.tokenGenerate,
      (response) => {
        this.storageService.set("TOKEN", response);
        onSuccess(response.token);
      },
      (error) => {
        if (onError) {
          onError();
        }
      }
    );
  }

  // get all documents of a Patient
  // getDocuments(onSuccess, onError, pid) {
  //   let url=APIConfig.document.getByPatientDoc.format(pid);
  //   this.apiService.get(

  //     (response) => {
  //       if (response) {
  //         this.documents = [...response];
  //       }

  //       onSuccess(response);
  //     },
  //     (error) => {
  //       onError({
  //         message: "Looks like something went wrong. Please try again.",
  //       });
  //     }
  //   );
  // }
  // get all documents of a folder based on type
  getChildDocuments(type, onSuccess, onError, pid) {
    if (!this.documents) {
      this.getDocuments(
        (res) => {
          this.documents = res;
          this._extractChild(type, onSuccess);
        },
        (error) => {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        },
        pid
      );
    } else {
      this._extractChild(type, onSuccess);
    }
  }
  _extractChild(type, onSuccess) {
    let childDoc = this.documents.filter((doc) => {
      return doc.type === type;
    });
    if (childDoc.length > 0) {
      onSuccess(childDoc[0]);
    } else {
      onSuccess(undefined);
    }
  }
  // Upload Document
  uploadDocument(fileUrl, fileName, document, signal, pId) {
    let reqPid = pId ? pId : -1;
    let documentRequest = new FormData();
    let documentJSON = {};
    // documentJSON.id = document.id;

    documentJSON.patientId = reqPid;
    documentJSON.title = fileName;
    documentJSON.description = document.description;
    documentJSON.type = document.type;
    documentJSON.format = document.format;
    documentJSON.status = document.status;

    documentRequest.append("file", fileUrl);
    documentRequest.append("fileName", fileName);
    documentRequest.append("document", JSON.stringify(documentJSON));
    documentRequest.append("patientId", parseInt(reqPid, 10));

    const url = APIConfig.document.uploadDoc;
    let config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    if (signal) {
      config = {
        ...config,
        signal: signal,
      };
    }

    return axiosClient.postForm(url, documentRequest, config);
  }
  _checkHttps(url) {
    let httpsURL = url;
    if (url && !url.includes("https")) {
      httpsURL = "https".concat(url.slice(4));
    }

    httpsURL = httpsURL.replace(
      "staging.fs.zyla.in/",
      "feature.pwa.zyla.in/docs/"
    );
    // httpsURL = httpsURL.replace("fs.zyla.in/", "m.zyla.in/docs/");
    return httpsURL;
  }
  getDocumentById(id, onSuccess, onError) {
    const url = APIConfig.document.getDoc.format(id);
    this.apiService.get(
      url,
      (doc) => {
        if (doc) {
          doc.thumbnail = this._checkHttps(doc.thumbnail);
          doc.url = this._checkHttps(doc.url);
        }
        onSuccess(doc);
      },
      onError
    );
  }
  getMedicineOffer(onSuccess, onError) {
    let url = APIConfig.document.getMedicine;
    return axiosClient.get(url);
  }
  postMedicineOffer(data) {
    let url = APIConfig.document.postMedicine;
    return axiosClient.post(url, data);
  }
}

const documentService = new DocumentService();
export default documentService;
