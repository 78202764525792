import React from "react";
import backicon from "../../../assets/icons/arrow-left-orange.png";

function FeatureHeader({ handleClose, title }) {
  return (
    <div className="modal-page-header">
      <img src={backicon} alt="" onClick={handleClose} />
      {title}
    </div>
  );
}

export default FeatureHeader;
