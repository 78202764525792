import React, { Component } from "react";
import { IonContent, IonPage, IonSpinner } from "@ionic/react";
import TestimonialSlides from "./testimonialSlides";
import { CircularProgress } from "@mui/material";

class staticTestimonial extends Component {
  render() {
    return (
      <IonContent class="content-background">
        <div id="testimonial-wrapper">
          <CircularProgress className="ion-router-circular-progress"></CircularProgress>
        </div>
      </IonContent>
    );
  }
}

export default staticTestimonial;
