import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import meditationService from "../../services/MeditationService";

const initialState = {
  meditationMedia: [],
  recommendedMediVideo: [],
  ui: {
    loading: true,
    recomededloaded: false,
    meditateNow: false,
  },
};

export const getAllMeditation = createAsyncThunk(
  "meditation/getAl",
  async (data, { getState, rejectWithValue }) => {
    try {
      const { login } = getState();

      const response = await meditationService.getAllMeditations(
        login.user?.patientProfile.id
      );
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const meditationSlice = createSlice({
  name: "meditation",
  initialState: initialState,
  reducers: {
    toggleMeditateNow: (state, action) => {
      state.ui.meditateNow = action.payload;
    },
  },
  extraReducers: {
    [getAllMeditation.pending]: (state, action) => {
      state.ui.loading = true;
    },
    [getAllMeditation.fulfilled]: (state, action) => {
      let isPremium = action.meta.arg.isPremium;
      const response = action.payload.data;
      let medMedia = [];
      let recomMedia = [];

      if (!isPremium) {
        let recommedVal = [];

        let id;
        for (let i = 0; i < response.length; i++) {
          if (response[i].locked === false) {
            recommedVal.push({ ...response[i] });
            id = response[i].id;
            break;
          }
        }

        medMedia = response.filter((res) => res.id !== id);
        recomMedia = [...recommedVal];
      } else {
        let recommendedMediVideoTemp = [{ ...response[0] }];
        for (let i = 0; i < response.length; i++) {
          if (response[i].recommended === true) {
            recommendedMediVideoTemp[0] = { ...response[i] };
          }
        }
        medMedia = response;
        recomMedia = recommendedMediVideoTemp;
      }
      return {
        ...state,
        meditationMedia: medMedia,
        recommendedMediVideo: recomMedia,
        ui: {
          ...state.ui,
          loading: false,
          recomededloaded: true,
        },
      };
    },
    [getAllMeditation.rejected]: (state, action) => {
      state.ui.loading = false;
    },
  },
});

export const { toggleMeditateNow } = meditationSlice.actions;
export default meditationSlice.reducer;
