import React from "react";
class IframeComponent extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <iframe
        src={this.props.url}
        frameBorder="0"
        width="100%"
        height="100%"
      ></iframe>
    );
  }
}

export default IframeComponent;
