import React from "react";
import PACompletedImage from "../../assets/images/pa_done.png";
import { useHistory, withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logEvent } from "../../app/eventSlice";

const PACompleted = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(logEvent({ action: "complete_assessment" }));
    history.replace("/tab");
  };

  return (
    <div className="pa-completed">
      <div>
        <img className="pa-done" src={PACompletedImage} />
      </div>
      <div className="pa-done-content">
        <div className="fs20 fw-semibold">Congrats!</div>
        <div className="pa-text">
          I am now assessing your responses and designing your Health Goals
        </div>
      </div>
      <div className="pa-done-content">
        <div>
          <button onClick={handleClick} className="prime-btn fs20">
            Great, Let's Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PACompleted);
