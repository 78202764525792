import React from "react";
import PACompletedImage from "../../../assets/icons/ic_completed_Success.png";
import { useHistory } from "react-router";

import { useDispatch, useSelector } from "react-redux";
import { toggleRequestSent } from "../premiumSlice";
const getName = (patient) => {
  let lastName = patient && patient.lastName;
  let firstName = patient && patient.firstName;
  return lastName ? lastName : firstName;
};

const RequestCompleted = ({ handleRequestSent, isDigit }) => {
  const patient = useSelector((store) => store.login.user.patientProfile);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleClick = () => {
    if (isDigit) {
      handleRequestSent();
    } else {
      dispatch(toggleRequestSent());
      history.replace("/tab/chat");
    }
  };

  return (
    <div className="request-sent-wrapper">
      <div className="request-sent-completed">
        <div className="rc-image-wrapper">
          <img src={PACompletedImage} alt="bg-img" />
        </div>
        <div className="pa-done-content">
          <div className="orange-title">
            {patient.gender === 2 ? "Mr" : "Mrs"}. {getName(patient)}, You will
            receive a call shortly.
          </div>
          <div className="pa-text">
            Thank you for taking a step towards better health.
          </div>
        </div>
        <div className="pa-done-content"></div>
      </div>
      <button onClick={handleClick} className="back-to-chat fs20">
        Thank You
      </button>
    </div>
  );
};

export default RequestCompleted;
