import homeIcon from "../../../assets/icons/home-selected.png";
import workIcon from "../../../assets/icons/address-office-icon.svg";
import otherIcon from "../../../assets/icons/address-others-icon.svg";

import { useDispatch } from "react-redux";
import {
  handleEditAddress,
  handleEditRemove,
  handleRadioBoxChange,
} from "../addressSlice";
import { useEffect, useRef } from "react";
import { Capacitor } from "@capacitor/core";
function AddressElement({
  addr,
  handleOpenRemoveDialog,
  index,
  showRemoveOption,
  editRemoveIndex,
  selectMedicineOrderAddress,
  isRadio,
}) {
  const dispatch = useDispatch();

  const editBoxRef = useRef(null);

  return (
    <div className="address-book-element" key={addr.id}>
      <div className="address-book-element-top">
        {isRadio ? (
          <input
            type="radio"
            className="address-book-element-top-type"
            onChange={() => {
              dispatch(handleRadioBoxChange(index));
            }}
            checked={selectMedicineOrderAddress === index}
          />
        ) : (
          <img
            src={
              addr.type === "Home"
                ? homeIcon
                : addr.type === "Work"
                ? workIcon
                : otherIcon
            }
            className="address-book-element-top-type"
            alt="icon"
          />
        )}
        <p className="address-book-element-top-name">{addr.name}</p>

        <div
          className="address-book-element-top-threeIcon-layout-1"
          onClick={(e) => {
            dispatch(handleEditRemove(index));
          }}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        {index === editRemoveIndex && (
          <div className="address-book-open-edit-box" ref={editBoxRef}>
            <button
              onClick={() => {
                dispatch(handleEditAddress(addr));
              }}
            >
              Edit
            </button>
            {showRemoveOption && (
              <button
                onClick={() => {
                  handleOpenRemoveDialog(true, addr, index);
                }}
              >
                Remove
              </button>
            )}
          </div>
        )}
      </div>
      <div className="address-book-element-bottom">
        <p>
          {`${addr.line1},${addr.line2}, ${addr.landmark ? addr.landmark : ""}`}
          <br />
          {`${addr.district}, ${addr.city},${addr.state}-${addr.pincode}`}
        </p>
        <p>Phone number- {addr.phoneNo}</p>
      </div>
    </div>
  );
}
export default AddressElement;
