import { useEffect } from "react";
import { useDispatch, useStore } from "react-redux";
import { Geolocation } from '@capacitor/geolocation';
import { Device } from "@capacitor/device";
import { Network } from "@capacitor/network";
import { App } from "@capacitor/app";

import { setAppInfo, setDevice, setLocation, setNetwork } from "./appSlice";
import { logEvent } from "./eventSlice";

export default function AppWrapper({ children }) {
  const dispatch = useDispatch();

  useEffect(() => {
   
    Device.getInfo().then((i) => {
      // set if paytm
      const ua = window.navigator.userAgent;
      const paytmFlag = /AppContainer/i.test(ua);
      i.isPaytm = paytmFlag;
      // set if ios14
      if (i.operatingSystem === "ios" && i.osVersion.startsWith("14")) {
        i.ios14 = true;
      } else {
        i.ios14 = false;
      }
      dispatch(setDevice(i));
    });
    App.getInfo().then((res)=>{
      dispatch(setAppInfo(res));

    }).catch((err)=>{})
    Network.getStatus()
      .then((status) => {
        dispatch(setNetwork(status));
        // if (status.connected === false) {
        //   this.setNetworkInterval();
        // } else {
        //   this.compMountUtilFunc();
        // }
      })
      .catch((err) => {
        //this.compMountUtilFunc();
        console.log("cannot detetct status");
      });
  }, []);

  useEffect(() => {
    Geolocation.getCurrentPosition()
      .then((data) => {
        fetch(
          `https://nominatim.openstreetmap.org/reverse?lat=${data.coords.latitude}&lon=${data.coords.longitude}&format=json`
        )
          .then((response) => response.json())
          .then((data) => {
            dispatch(setLocation(data));
          })
          .catch((err) => {
            console.log(err);
          });
        dispatch(logEvent({ action: "location_permission_allow" }));
      })
      .catch((err) => {
        dispatch(logEvent({ action: "location_permission_deny" }));
      });
  }, []);
  return { ...children };
}
