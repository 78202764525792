import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import dropdownImage from "../../../../assets/images/forwardRed.png";
import BackButton from "../../../../components/common/BackButton";
import { setSelectedPolicy } from "../rewardSlice";
// import { setSelectedPolicy } from "../rewardSlice";

export const RewardPolicyMenu = ({ data, setPolicy }) => {
  const dispatch = useDispatch();
  const [internalPolicy, setInternalPolicy] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [policies, setPolicies] = useState(null);

  useEffect(() => {
    const cidrReward = data?.data?.cidrRewards?.cidr;
    const sewaReward = data?.data?.sewaRewards?.sewa;
    let policyNos = Object.keys(sewaReward || {});
    policyNos = [...policyNos, ...Object.keys(cidrReward || {})];
    let policyDatas = [];
    policyNos.forEach((policyNo) => {
      if (sewaReward && sewaReward[policyNo]) {
        policyDatas = [
          ...policyDatas,
          {
            policyNo: policyNo,
            type: "SEWA",
            data: sewaReward[policyNo],
          },
        ];
      } else if (cidrReward && cidrReward[policyNo]) {
        policyDatas = [
          ...policyDatas,
          {
            policyNo: policyNo,
            type: "CIDR",
            data: cidrReward[policyNo],
          },
        ];
      }
    });
    setPolicies(policyDatas);
    if (policyDatas.length > 0) {
      setInternalPolicy(policyDatas[0]);
      setPolicy(policyDatas[0]);
    }
    dispatch(setSelectedPolicy(policyNos[0]));
  }, [data]);

  return (
    <>
      {data && internalPolicy && (
        <>
          <div className="pb-0 flex justify-between relative text-lightblack ">
            <div className="flex flex-col w-full gap-y-2">
              <div className="flex font-bold text-base  items-center leading-5">
                <BackButton />
                <span className="px-2">My rewards</span>
              </div>
              <div className="outline  outline-1 outline-[#D9D9D9] rounded-md flex justify-between mt-2  relative p-2 items-center">
                <div
                  className="flex justify-between w-full items-center "
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <span className="px-3 font-semibold ">
                    Policy number: {internalPolicy.policyNo}
                    
                  </span>
                  <div>
                    <img
                      src={dropdownImage}
                      alt=""
                      className={`w-6 h-6 ${
                        isOpen ? "-rotate-90" : "rotate-90"
                      } mr-5`}
                    />
                  </div>
                </div>

                {isOpen && (
                  <div
                    className="absolute bg-white z-100  translate-y-full  rounded-lg border-2 border-slate-300 w-full overflow-hidden gap-y-px -bottom-5 flex flex-col justify-start left-0"
                    style={{ border: "1px solid #d9d9d9" }}
                  >
                    {policies.map((policy) => {
                      return (
                        <button
                          className="text-lightblack text-xs bg-white w-full h-full p-4 text-left"
                          onClick={() => {
                            setIsOpen(!isOpen);
                            setPolicy(policy);
                            dispatch(setSelectedPolicy(policy.policyNo));
                            setInternalPolicy(policy);
                          }}
                          style={{ borderBottom: "1px solid #d9d9d9" }}
                        >
                          <span className="font-semibold text-xs leading-5">
                            {policy.policyNo}
                          </span>
                        </button>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
