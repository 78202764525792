import Types from "./types";

const updateChatAction = (data) => ({
  type: Types.UPDATE_CHAT,
  data: data,
});
const updateUiConfig = (data) => ({
  type: Types.UPDATE_UI_CONFIG,
  uiConfig: data,
});
const setupChatAction = (data) => ({
  type: Types.SETUP_CHAT,
});
const clearChatAction = (data) => ({
  type: Types.CLEAR_SETUP_CHAT,
});

const sendMessageAction = (message) => ({
  type: Types.SEND_MESSAGE,
  data: message,
});

const toggleAllowMessageSendAction = (data) => ({
  type: Types.TOGGLE_MESSAGE_ABILITY,
  data: data,
});

const toggleOnlineStatusAction = (status) => ({
  type: Types.TOGGLE_ONLINE_STATUS,
  data: status,
});

const updatePendingChatAction = (data) => ({
  type: Types.UPDATE_PENDING_CHAT,
  data: data,
});

const updateMessageStatusAction = (data) => ({
  type: Types.UPDATE_MESSAGE_STATUS,
  data: data,
});

const deviceReadingPushAction = (data) => ({
  type: Types.DEVICE_READING_PUSH,
  data: data,
});
const deviceReadingClearAction = () => ({
  type: Types.DEVICE_READING_CLEAR,
});
const deviceSetCurrentAction = (data) => ({
  type: Types.DEVICE_SET_CURRENT,
  data: data,
});

export default {
  updateChatAction,
  setupChatAction,
  clearChatAction,
  sendMessageAction,
  toggleAllowMessageSendAction,
  toggleOnlineStatusAction,
  updatePendingChatAction,
  updateMessageStatusAction,
  updateUiConfig,
  deviceReadingPushAction,
  deviceReadingClearAction,
  deviceSetCurrentAction,
};
