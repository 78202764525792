import React from "react";
import docLogo from "../../../assets/images/ic_documents.png";
import { IonCol } from "@ionic/react";
export default function DocumentFolder({ onClickFolder, document }) {
  return (
    <IonCol
      sizeXs={6}
      onClick={() => onClickFolder()}
      style={{ padding: "10px" }}
    >
      <div className={"doc-folder"}>
        <img src={docLogo} alt="icon"></img>
        <p className="name"> {document.title}</p>
        <p className="size"> {document.count} Files</p>
      </div>
    </IonCol>
  );
}
