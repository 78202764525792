import React, { Component } from "react";
import { Drawer } from "@mui/material";
import SuccessCompleteIcon from "../../../assets/images/ic_completed_success.png";
import { IonModal } from "@ionic/react";
export default function VitalCompleteSuccess({ open, onClose, date }) {
  return (
    <IonModal
      className={"menu-partial-full-height reporting-container"}
      anchor="bottom"
      isOpen={open}
      onDidDismiss={() => onClose()}
    >
      <div className="reporting-success-page">
        <img src={SuccessCompleteIcon} />
        <p>Thank you for submitting your Vitals for {date}</p>
      </div>
    </IonModal>
  );
}
