import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { backToPhoneForm, toggleNotEligible } from "../loginSlice";
import AccessImage from "../../../assets/images/AccessDen.png";
import arrowBlack from "../../../assets/icons/arrow-back-black.svg";
import { useHistory } from "react-router";
import { IonContent, IonPage } from "@ionic/react";
import { useDispatch } from "react-redux";
import BackButton from "../../../components/common/BackButton";
export function EligibilityCheck() {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <IonPage>
      <IonContent>
        <>
          <div className="  items-center justify-center text-lightblack">
            <div
              class=" flex p-3 items-center"
              onClick={() => {
                dispatch(toggleNotEligible());
                console.log(" toggleNotEligible is ");
              }}
            >
              <BackButton />
              <div className="px-3 font-bold text-base leading-5">
                Eligibility check
              </div>
            </div>
            <img src={AccessImage} alt="image" className="w-full pt-3" />
            <div className="text-center justify-center p-5 text-xs font-medium text-lightblack">
              <span>
                The details entered by you are not eligible for accessing the
                Max Fit app. To check your eligibility, request you to please
                connect with your Max Life representative or visit your nearest
                branch or call us at 1860 120 5577.
              </span>
            </div>
          </div>
        </>
      </IonContent>
    </IonPage>
  );
}
