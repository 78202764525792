import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ChevronRight from "../../../assets/icons/white-chevron-right.png";
import vitalService from "../../../services/VitalService";
import * as moment from "moment";
import { vitalParams } from "../homeUtilityFunc";
import { useDispatch, useSelector } from "react-redux";
import { logEvent } from "../../../app/eventSlice";

const today = new Date().setHours(0, 0, 0, 0);
const twoWeeksAgo = new Date(
  new Date().setDate(new Date().getDate() - 13)
).setHours(0, 0, 0, 0);

function VitalStats({ day }) {
  const patientProfile = useSelector(
    (store) => store.login.user?.patientProfile
  );
  const paramGroups = useSelector((store) => store.vitalsChart.vitalParams);
  const dataUpdated = useSelector((store) => store.vitalsChart.dataUpdated);
  const [vitals, setVitals] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (paramGroups && paramGroups.length > 0) {
      paramGroups.forEach((param) => {
        vitalService
          .getVitalCharts(
            param.id,
            new Date().setHours(0, 0, 0, 0),
            patientProfile?.id
          )
          .then((result) => {
            const res = result.data;
            setVitals((prevData) => {
              if (prevData.length > 0) {
                let newData = prevData.filter((currVital) => {
                  if (currVital.id !== param.id) {
                    return { ...currVital };
                  }
                });
                newData.push({ ...param, average: res.average });
                return newData;
              } else {
                return [{ ...param, average: res.average }];
              }
            });
          })
          .catch((err) => {});
      });
    }
  }, [paramGroups, dataUpdated]);

  const getIcon = (id) => {
    let vitals = vitalParams.find((vital) => vital.id === id);
    return vitals?.icon;
  };
  const redirectToVitalCharts = (vitalName) => {
    dispatch(logEvent({ action: "click_vital_chart_home" }));
    let selectedVital = vitals.find((vital) => vital.name === vitalName);
    history.push(`/vitalsChart?${selectedVital?.id}`);
  };

  return (
    <>
      {vitals.length !== 0 && (
        <>
          {/* <h1 id="vitals-heading">Vital Progress</h1>
          <div className="vital-stats-wrapper">
            {vitals.map((vital) => (
              <div
                key={vital.id}
                className="reading-category-average"
                onClick={() => {
                  if (day !== "tommorow") {
                    redirectToVitalCharts(vital.name);
                  }
                }}
              >
                <div className="category">
                  <img src={getIcon(vital.id)} id={`${vital.id}`} alt="icon" />
                  <span>{vital.name}</span>
                </div>
                <img src={ChevronRight} className="chevron-right" alt="icon" />
                <div className="reading-div">
                  <span className="average">Average {vital.name}</span>
                  <span className="average-reading">
                    {vital.average ? vital.average : "--"} {vital.unit}
                  </span>
                </div>
                <span className="date">
                  {moment(twoWeeksAgo).format("D MMM")}&nbsp;-&nbsp;
                  {moment(today).format("D MMM")}
                </span>
              </div>
            ))}
          </div> */}
        </>
      )}
    </>
  );
}

export default VitalStats;
