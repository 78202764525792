import React, { Component } from "react";
import ContentLoader from "react-content-loader";
import "./commonComponent.scss"
export default class SkeletonLoader extends Component {
  render() {
    return (
      <div className="skeleton-loader-container">
        <ContentLoader>
          <rect width="100%" height="12" x="10" y="12" fill="#EDF1F7" rx="2" />
          <rect width="100%" height="12" x="10" y="30" fill="#EDF1F7" rx="2" /> 
        </ContentLoader>
      </div>
    );
  }
}
