import React, { useEffect, useState } from "react";
import PlusIcon from "../../../assets/images/ic_up_arrow.png";
import attachmentIcon from "../../../assets/icons/attachment-icon.png";
import SendIcon from "../../../assets/images/ic_send_grey_3.png";
import SendIconActive from "../../../assets/images/ic_send_primary.png";
import {Keyboard} from "@capacitor/keyboard"
import { Device } from "@capacitor/device";
import { Camera, CameraResultType,CameraSource } from '@capacitor/camera';
import RippleButton from "../../../components/common/RippleButton";
import { logEvent } from "../../../app/eventSlice";
import { useDispatch } from "react-redux";

import PartialMenu from "./partialMenu";
export default function ChatInputBox({
  uiConfig,
  onClickSend,
  onClickUpload,
  patientId,
  insertUploadMessage,
}) {
  const [showCamera, setShowCamera] = useState(true);
  const [partialMenuVisibility, setPartialMenuVisibility] = useState(false);
  const [text, setText] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    setShowCamera(text ? false : true);
  }, [text]);
  // this.partialMenu = React.createRef();
  // this.CleverTapLogger = new CleverTapLogger();
  // this.helper = new Helper();
  // this.state = {
  //   input: "",
  //   message: {},
  //   sheetVisible: false,
  //   showCamera: false,
  //   showAlert: false,
  //   alertMessage: "Request a Callback",
  //   alertHeader: "Go Premium",
  //   requestCallBack: false,
  //   uploading: false,
  //   uploadCta: "",
  //   partialtoggled: false,
  // };

  // this.storageService = new StorageService();
  // this.documentService = new DocumentService();
  // this.userService = new UserService();
  // this.eventLoggerService = new EventLoggerService();

  const componentDidMount = () => {
 
    Device.getInfo().then((r) => {
      if (r.platform !== "web") {
        Keyboard.addListener("keyboardDidShow", (info) => {
          this.textIn = document.getElementById("textInput");
          if (this.textIn) {
            this.textIn.style.bottom = "50px";
          }
        });
        Keyboard.addListener("keyboardDidHide", (info) => {
          this.textIn = document.getElementById("textInput");
          if (this.textIn) {
            this.textIn.style.bottom = "10px";
          }
        });
      }
    });
  };
  const componentDidUpdate = (prevProps, prevState) => {
    if (this.props.ctaTrigger === "camera") {
      //TODO camera upload
    }

    if (
      (this.props.RequestCallback && !prevProps.RequestCallback) ||
      (!this.props.RequestCallback && prevProps.RequestCallback)
    ) {
      this.requestCallBack();
    }
  };
  const onChangeInput = (e) => {
    setText(e.target.value);
  };

  // onPressEnter = e => {
  //   if (e.key == "Enter") {
  //     this.onClickSubmit();
  //     this.setState({
  //       input: ""
  //     });
  //   }
  // };

  const onClickSubmit = () => {
    dispatch(logEvent({ action: "click_send_message" }));

    let htmlTagsRegex = /<(“[^”]*”|'[^’]*’|[^'”>])*>/;
    let result = htmlTagsRegex.test(text);
    if (result) {
      //renderError(); //TODO  error text validation
      return;
    }

    if (text.trim()) {
      let content = {
        message: {
          text: text.trim(),
          type: 1,
          id: "" + new Date().getTime() + Math.random(),
        },
      };

      onClickSend(content);
      setText("");
    }
  };

  const toggleDrawer = () => {
    setPartialMenuVisibility(true);
    dispatch(logEvent({ action: "OPEN_PARTIAL_MENU" }));
  };

  const handleBubbleClick = (msg) => {
    const { lastMessage, onClickSubmit } = this.props;

    let currentState = lastMessage?.content.state;

    if (lastMessage.content.message.type === 20) {
      this.eventLoggerService.log("REPLY_CUSTOM_MESSAGE", {
        messageId: lastMessage.content.message.metadata.id,
        replyText: msg,
      });
    }

    let messageBody = {
      message: {
        id: "" + new Date().getTime() + Math.random(),
        text: msg,
        type: 1, //msg.data.type, bubble always gives a text back!
      },
    };
    if (currentState) {
      messageBody.state = currentState;
    }
    onClickSubmit(messageBody);
  };

  const handleSheet = () => {
    this.setState({
      sheetVisible: true,
    });
  };

  const handleSheetChange = (isOpened) => {
    this.setState({
      sheetVisible: isOpened,
    });
  };

  const takePicture = async () => {
    
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      correctOrientation: false,
      source: CameraSource.Camera,
      resultType: CameraResultType.Uri,
      webUseInput: false,
    });
    // image.webPath will contain a path that can be set as an image src.
    // You can access the original file using image.path, which can be
    // passed to the Filesystem API to read the raw data of the image,
    // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
    if (image) {
      this.setState({ uploading: true });
      const blob = await fetch(image.webPath).then((r) => r.blob());

      // let fileExtension = image.webPath.substring(
      //   image.webPath.lastIndexOf(".") + 1,
      //   image.webPath.length
      // );
      let fileName = Math.random() * (9999999 - 1111111) + 1111111;
      let fileExtension = image.format;

      this.documentService.uploadDocument(
        blob,
        `${fileName}.${fileExtension}`,
        {
          id: "",
          title: "",
          description: "",
          url: "",
          type: 6,
          format: 1,
          status: 4,
        },
        (response) => {
          this.setState({ uploading: false });
          this.onDone(response);
        },
        (error) => {
          this.setState({ uploading: false });
          this.onDone(null);
        }
      );
    }
  };

  const onDone = (response) => {
    const { sendMesage } = this.props;
    if (response) {
      let content = {
        message: {
          text: response.id,
          type: response.format === 4 ? 3 : 2,
          id: "" + new Date().getTime() + Math.random(),
          metadata: {
            id: response.id,
          },
        },
      };
      sendMesage(content);
    }
  };
  const submitRequestCallback = () => {
    this.userService.requestCallBack(
      (response) => {
        this.setState({ requestCallBack: true });
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const requestCallBack = () => {
    if (this.userService.checkCallBackStatus()) {
      this.setState({
        showAlert: true,
        alertHeader: "Call scheduled",
        alertMessage: "You will receive a call shortly.",
      });
    } else {
      this.setState({
        showAlert: true,
        alertHeader: "Go Premium",
        alertMessage: "Request a Callback ",
      });
    }
  };
  const getAlertButton = () => {
    if (this.userService.checkCallBackStatus()) {
      return [
        {
          text: "CLOSE",
          handler: () => {
            this.setState({ showAlert: false });
          },
        },
      ];
    } else {
      return [
        {
          text: "MAY BE LATER",
          handler: () => {
            this.setState({ showAlert: false });
          },
        },
        {
          text: "YES",
          handler: () => {
            this.setState({ showAlert: false });
            this.submitRequestCallback();
          },
        },
      ];
    }
  };
  const openUploaderBox = () => {};
  // clearState = () => {
  //   this.setState({ uploadCta: null});
  // };

  // const { disabledMessageSend, sendMesage, uiConfig, openUploaderBox } =
  //   this.props;

  // const {
  //   sheetVisible,
  //   showCamera,
  //   showAlert,

  //   uploading,
  //   uploadCta,
  //   partialtoggled,
  // } = this.state;

  return (
    <div id="textInput">
      <div className="chat-input">
        <div className="chat-input-form">
          <RippleButton>
            <img
              className="partial-menu"
              onClick={toggleDrawer}
              src={PlusIcon}
              alt="partial menu"
            />
          </RippleButton>

          <div className="input-lock-wrapper">
            <div className="input-lock-form">
              <textarea
                className="extra-padding-lock"
                placeholder="Ask me anything"
                value={text}
                onChange={(e) => onChangeInput(e)}
              ></textarea>
            </div>
          </div>
          {showCamera && (
            <RippleButton nospace>
              <img
                className="camera-icon"
                style={{ marginLeft: "10px" }}
                src={attachmentIcon}
                alt="camera"
                onClick={(e) => showCamera && onClickUpload()}
              ></img>
            </RippleButton>
          )}
          <RippleButton nospace>
            <img
              className="send-key"
              src={text ? SendIconActive : SendIcon}
              alt="sent-msg"
              onClick={(e) => onClickSubmit()}
            ></img>
          </RippleButton>
        </div>
      </div>

      {/* <IonLoading isOpen={uploading} message={"Uploading"} duration={50000} /> */}
      <PartialMenu
        open={partialMenuVisibility}
        patientId={patientId}
        uiConfig={uiConfig}
        sendMesage={(r) => {
          console.log("sendMesage", r);
        }}
        onUpload={(e) => {
          console.log("onUpload", e);
        }}
        onClose={() => {
          setPartialMenuVisibility(false);
        }}
        insertUploadMessage={(e,error={}) => {
          insertUploadMessage(e,error);
        }}
      />
    </div>
  );
}
