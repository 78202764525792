import React from "react";
import { Link } from "react-router-dom";
import bgImage from "../../../assets/images/bg_pattern.png";
import SecureImage from "../../../components/common/SecureImage";
import "./documentPreview.scss";
import pdfIcon from "../../../assets/images/pdf-image.png";
import moment from "moment";
import { useSelector } from "react-redux";

export default function DocumentPreview({
  document,
  isTable,
  redirect,
  internal,
  mine,
}) {
  const device = useSelector((state) => state.app.device);
  const token = useSelector((state) => state.tab.docToken.token);

  const getUrlWithToken = (url) => {
    if (url && url.indexOf("?") !== -1) {
      if (token) {
        url = `${url}&token=${token}`;
      }
    } else {
      if (token) {
        url = `${url}?token=${token}`;
      }
    }

    return url;
  };

  // thumbnailUrl(thumbnail) {
  //   if (thumbnail && !thumbnail.includes("https")) {
  //     return "https".concat(thumbnail.slice(4));
  //   } else return thumbnail;
  // }

  const borderFix = mine ? "border-all" : "";
  const isApp = window.Capacitor.platform === "ios";
  // const isApp =
  // window.Capacitor.platform === "android" ||
  // window.Capacitor.platform === "ios";
  let url = getUrlWithToken(document.url, token);

  const thumbnail = getUrlWithToken(document.thumbnail, token);

  let target = "";
  let docViewerURL;
  if (redirect) {
    docViewerURL = `/document-viewer?title=${document.title}&format=${document.format}&redirect=${redirect}&pathName=${window.location.pathname}&url=${url}`;
  } else {
    docViewerURL = `/document-viewer?title=${document.title}&format=${document.format}&pathName=${window.location.pathname}&url=${url}`;
  }

  if (isApp && document.format === 4) {
    target = "_blank";
    docViewerURL = url;
  }

  return (
    <>
      {isTable ? (
        <div className="doc-preview document-table-card">
          {isApp && document.format === 4 && (
            <a
              rel="noreferrer"
              href={url}
              target="_blank"
              className="anchor-cls"
              height={
                internal
                  ? window.screen.width / 3
                  : (window.screen.width * 4) / 5
              }
            >
              <SecureImage
                height={
                  internal
                    ? window.screen.width / 3
                    : (window.screen.width * 4) / 5
                }
                classN="doc-preview-img"
                defaultImage={bgImage}
                src={thumbnail}
              ></SecureImage>
              {document.title && document.format === 4 && (
                <div className="doc-info">
                  <div className="doc-info-text ">
                    <small className="name-title">{document.title}</small>
                  </div>
                </div>
              )}
            </a>
          )}
          {!(isApp && document.format === 4) && (
            <Link
              target={target}
              to={docViewerURL}
              className="anchor-cls"
              height={
                internal
                  ? window.screen.width / 3
                  : (window.screen.width * 4) / 5
              }
            >
              <SecureImage
                token={token}
                height={
                  internal
                    ? window.screen.width / 3
                    : (window.screen.width * 4) / 5
                }
                classN="doc-preview-img"
                defaultImage={bgImage}
                src={thumbnail}
              ></SecureImage>
              {document.title && document.format === 4 && (
                <div className="doc-info">
                  {/* <div className="doc-info-icon">
                  <img src={pdfIcon}></img>
                  </div> */}

                  <div className="doc-info-text ">
                    <small className="name-title">{document.title}</small>
                    <small className="doc-last-updated">
                      Last Updated
                      {moment(document.updatedAt).format("MMM Do YY")}
                    </small>
                  </div>
                </div>
              )}
            </Link>
          )}
        </div>
      ) : (
        <div className="doc-preview document-chat-card">
          {isApp && document.format === 4 && (
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              className={
                document.format === 4
                  ? "anchor-cls pdf-border my-blue-border-t"
                  : "anchor-cls"
              }
              height={
                internal
                  ? window.screen.width / 3
                  : (window.screen.width * 4) / 5
              }
            >
              <SecureImage
                token={token}
                height={
                  internal
                    ? window.screen.width / 3
                    : (window.screen.width * 4) / 5
                }
                classN={
                  document.format === 4
                    ? "doc-preview-img "
                    : "doc-preview-img img-border"
                }
                defaultImage={bgImage}
                src={thumbnail}
              ></SecureImage>
              {document.title && document.format === 4 && (
                <div className="doc-info">
                  <div className="doc-info-icon">
                    <img src={pdfIcon} alt=""></img>
                  </div>

                  <div className="doc-info-text ">
                    <small className="name-title">
                      {/* {this.truncate(document.title, 25)}
                       */}
                      {document.title}
                    </small>
                  </div>
                </div>
              )}
            </a>
          )}
          {!(isApp && document.format === 4) && (
            <Link
              target={target}
              to={docViewerURL}
              className={
                document.format === 4 ? "anchor-cls pdf-border" : "anchor-cls"
              }
              height={
                internal
                  ? window.screen.width / 3
                  : (window.screen.width * 4) / 5
              }
            >
              <SecureImage
                token={token}
                height={
                  internal
                    ? window.screen.width / 3
                    : (window.screen.width * 4) / 5
                }
                classN={
                  document.format === 4
                    ? "doc-preview-img border-t"
                    : `doc-preview-img img-border ${borderFix} br-15`
                }
                defaultImage={bgImage}
                src={thumbnail}
              ></SecureImage>
              {document.title && document.format === 4 && (
                <div className="doc-info">
                  <div className="doc-info-icon">
                    <img alt="" src={pdfIcon}></img>
                  </div>

                  <div className="doc-info-text ">
                    <small className="name-title">
                      {/* {this.truncate(document.title, 25)} */}
                      {document.title}
                    </small>
                    <small className="xs-footer"></small>
                  </div>
                </div>
              )}
            </Link>
          )}
        </div>
      )}
    </>
  );
}
