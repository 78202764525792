import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import APIConfig from "../../services/APIConfig";
import axiosClient from "../../app/axiosConfig";

const initialState = {
  healthPlan: {},
  healthCategory: undefined,
  ui: {
    hpExist: false,
    error: false,
    loading: true,
    loaded: false,
  },
};

export const fetchHealthPlan = createAsyncThunk(
  "healthPlan/fetchHealthPlan",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(
        APIConfig.healthPlan.getPlan.format(data.id)
      );
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchHealthPlanProfile = createAsyncThunk(
  "healthPlan/fetchHealthPlanProfile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(
        APIConfig.healthPlan.getPlan.format(data.id)
      );
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchHealthPlanCategories = createAsyncThunk(
  "healthPlan/fetchHealthPlanCategories",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(APIConfig.healthPlan.getCategory);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const healthPlanSlice = createSlice({
  name: "healthPlan",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [fetchHealthPlanCategories.fulfilled]: (state, action) => {
      const cats = action.payload.data;

      if (cats) {
        let newCat = {};
        cats.forEach((ctg) => {
          newCat[ctg.id] = ctg.name;
        });
        state.healthCategory = newCat;
      }
    },
    [fetchHealthPlan.pending]: (state, action) => {
      state.ui = {
        ...state.ui,
        loading: true,
        loaded: false,
        error: false,
      };
    },
    [fetchHealthPlan.rejected]: (state, action) => {
      state.ui = {
        ...state.ui,
        loading: false,
        loaded: true,
        error: action.payload?.response?.status === 404 ? false : true,
      };
      state.healthPlan = {
        current_level: null,
        data: null,
        level: 1,
        levels: [],
        links: {},
        min_req_progress: 100,
        patientId: 48778,
        progress: 46,
        type: 2,
      };
    },
    [fetchHealthPlan.fulfilled]: (state, action) => {
      const hp = action.payload.data;
      let healthPlan = {};
      const category = state.healthCategory;
      let hpExist = false;
      for (let key in hp) {
        if (String(key) === "data") {
          healthPlan.data = {};
          for (let dataKey in hp.data) {
            hpExist = true;
            healthPlan.data[category[dataKey]] = hp["data"][dataKey];
          }
        } else {
          healthPlan[key] = hp[key];
        }
      }
      return {
        ...state,
        healthPlan: healthPlan,
        ui: {
          ...state.ui,
          hpExist: hpExist,
          loading: false,
          loaded: true,
          error: false,
        },
      };
    },
  },
});

export const {} = healthPlanSlice.actions;
export default healthPlanSlice.reducer;
