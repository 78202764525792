import React, { useEffect } from "react";
import vitalService from "../../../services/VitalService";
import Chart from "chart.js/auto";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleShowChart,
  updateAverageAndPercent,
  updateVitalChartError,
} from "../vitalsChartSlice";

export default function LineGraph() {
  const allParams = useSelector((store) => store.vitalsChart.vitalParams);
  const paramGroupId = useSelector(
    (store) => store.vitalsChart.ui.paramGroupId
  );
  const time = useSelector((store) => store.vitalsChart.ui.selectedEndTime);
  const patientProfile = useSelector(
    (store) => store.login.user.patientProfile
  );
  const dispatch = useDispatch();
  useEffect(() => {
    getVitalChartData(paramGroupId, time);
  }, [paramGroupId, time]);

  const getVitalChartData = (paramGroupId, time) => {
    if (paramGroupId && time) {
      vitalService
        .getVitalCharts(paramGroupId, time, patientProfile.id)
        .then((result) => {
          const response = result.data;
          if (!response.vitalEverSubmitted) {
            dispatch(toggleShowChart({ value: false }));
          } else {
            dispatch(toggleShowChart({ value: true }));
            collectChartData(response);
          }
        })
        .catch((error) => {
          dispatch(
            updateVitalChartError({
              value: true,
              msg: "Something went wrong.",
            })
          );
        });
    }
  };
  const collectChartData = (response) => {
    let paramLabelArray = allParams.filter((e) => e.id === +paramGroupId);
    let paramDataArray = [];
    dispatch(
      updateAverageAndPercent({
        average: response.average,
        normalReadingPercentage: response.normalReadingPercentage,
      })
    );
    const chartParams = response.params;
    const paramLabels = paramLabelArray[0].params;
    var startOfWeek = moment(time).subtract(14, "days");
    var endOfWeek = time;

    var days = [];
    var fullDates = [];
    var day = startOfWeek;

    while (day < endOfWeek) {
      day = day.clone().add(1, "d");
      days.push(day.toDate().getDate());
      fullDates.push(day.toDate());
    }

    for (let i = 0; i < paramLabels.length; i++) {
      let label = paramLabels[i].name;

      let paramValues = [];
      let found =
        chartParams &&
        chartParams.find((ob) => {
          return ob.paramId === paramLabels[i].id;
        });

      if (found) {
        const chartParamsReadings = found.paramReadings;

        days.forEach((date) => {
          let dataExistForDate = chartParamsReadings.find((reading) => {
            return new Date(reading.valueReadOn).getDate() === date;
          });

          if (dataExistForDate) {
            var minValue = Math.min(dataExistForDate.value, response.maxRange);

            if (minValue < response.minRange) {
              paramValues.push(null);
            } else {
              paramValues.push(minValue);
            }
          } else {
            paramValues.push(null);
          }
        });
      }
      createVariables(
        label,
        paramValues,
        i,
        paramDataArray,
        fullDates,
        paramLabels[0].unit.name
      );
    }

    let vitalsData = {
      labels: days,
      datasets: paramDataArray,
    };

    chartRender(
      vitalsData,
      response.scale,
      response.minRange,
      response.maxRange,
      paramLabels[0].unit.name
    );
  };

  const createVariables = (
    label,
    paramValues,
    i,
    paramDataArray,
    fullDates,
    unit
  ) => {
    const colorArray = [
      "#8baeff",
      "#ec8787",
      "#060079",
      "#dc32ff",
      "#1c9577",
      "#813818",
      "#ffce76",
      "#a6d577",
    ];
    let paramElement = {
      label: label,
      data: paramValues,
      lineTension: 0,
      fill: false,
      borderColor: colorArray[i],
      backgroundColor: colorArray[i],
      fullDates: fullDates,
      unit: unit,
    };
    paramDataArray[i] = paramElement;
  };

  const chartRender = (vitalsData, scale, min, max, unit) => {
    let canvas = document.querySelector("canvas");
    if (canvas) {
      canvas = canvas.getContext("2d");
    } else {
      return;
    }
    if (window.myLine !== undefined && window.myLine !== null) {
      window.myLine.destroy();
    }

    Chart.defaults.elements.point.radius = 5;
    window.myLine = new Chart(canvas, {
      type: "line",
      data: vitalsData,
      options: {
        maintainAspectRatio: false,
        aspectRatio: 1,
        responsive: true,
        showLine: true,
        spanGaps: true,
        layout: {
          padding: 25,
        },
        plugins: {
          tooltip: {
            intersect: false,
            enabled: true,
            callbacks: {
              title: function (tooltipitem) {
                let d = moment(
                  tooltipitem[0].dataset.fullDates[tooltipitem[0].dataIndex]
                ).format("DD-MMM");

                return d;
              },
              label: function (tooltipitem) {
                let ds = tooltipitem.dataset.data[tooltipitem.dataIndex];

                return `${tooltipitem.dataset.label} : ${
                  tooltipitem.dataset.data[tooltipitem.dataIndex]
                } ${tooltipitem.dataset.unit}`;
              },
            },
          },
          legend: {
            display: true,
            position: "bottom",
            fullWidth: true,
            align: "center",

            labels: {
              boxWidth: 4,
              boxHeight: 4,
              fontColor: "black",
              usePointStyle: true,
            },
          },
          // title: {
          //   display: true,
          //   text: "Zyla",
          //   color: "#7479FB",
          //   position: "bottom",
          //   align: "center",
          //   font: {
          //     size: 16,
          //   },
          // },
        },
        scales: {
          y: {
            min: min,
            max: max,
            title: {
              display: true,
              text: unit,
            },
            ticks: {
              stepSize: scale,
            },
            grid: {
              borderDash: [5, 10],
              drawBorder: false,
              borderDashOffset: [20],
            },
          },

          x: {
            title: {
              display: true,
              text: "Date",
            },

            distribution: "linear",

            grid: {
              display: false,
            },
          },
        },
      },
    });
  };

  return <canvas className="chart"></canvas>;
}
