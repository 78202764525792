import React from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import DocumentPreview from "./documentPreview";
import "../../../assets/css/doc.scss";

export default function DocumentTabPanel({ documents, cols, detail }) {
  if (!cols) {
    cols = "6";
    detail = detail ? detail : true;
  }
  return (
    <IonGrid>
      <IonRow>
        {documents &&
          documents.map((item, index) => {
            return (
              <IonCol size={cols}>
                <DocumentPreview
                  internal={true}
                  detail={detail}
                  document={item}
                  isTable={true}
                ></DocumentPreview>
              </IonCol>
            );
          })}
      </IonRow>
    </IonGrid>
  );
}
