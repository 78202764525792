import React from "react";
import HeartHealthImg from "../../assets/images/Digit/heartHealth.jpeg";
import dollyBagai from "../../assets/images/Digit/Dr_Dolly_Bagai.svg";
import dikshaGoyal from "../../assets/images/Digit/Diksha Goyal.jpg";
import priyankaPaul from "../../assets/images/Digit/Dr Priyanka Paull.jpeg";
import kaminiMishra from "../../assets/images/Digit/Kamini Mishra.JPG";
import karan from "../../assets/images/Digit/Dr Karan.png";
import rituSheokand from "../../assets/images/Digit/Ritu Sheokand.jpg";
import ketkiTawade from "../../assets/images/Digit/Ketki Tawade.jpg";
import rohini from "../../assets/images/Digit/Rohini GV.jpg";
import CommonComponent from "./components/CommonComponent";

const middleList = [
  {
    id: 1,
    p1: "Heart healthy nutrition ",
    p2: "and Ayurveda (Natural home remedies)",
  },
  { id: 2, p1: "Cardiac Rehabilitation", p2: "Physiotherapy and Yoga" },
  {
    id: 3,
    p1: "Medicine prescriptions &",
    p2: "guidance under Senior Cardiologists",
  },
];
const Doctors = [
  {
    name: "Dr Karan Chopra",
    image: karan,
    meta: "MBBS, MD (Medicine), DM Cardiology. Extensive experience in managing wide array of cardiovascular diseases including structural & coronary artery disease, rhythm abnormalities",
    text: "MD (Medicine), FIACM, FIAMS 	Ex-Prof of Medicine, FH Medical College",
    exp: "16+ years of experience",
  },
  {
    name: "Dr Priyanka Paul",
    image: priyankaPaul,
    meta: "BAMS,PG (Diploma) in Clinical Nutrition and Dietetics with Fellowship in Pulmonary Rehabilitationand Nutrition and Dietetics ",
    text: "10+ years of rich experiene at renonwed hospitals such as Jyoti Punj, Fortis  and Apollo Hospitals. PG in Clinical Nutrition and Fellowship in Pulmonary Rehabilitation from Apollo Educational and Research Foundation. Specialises in managing chronic health disorders in patients.",
    exp: "10+ yrs experience",
  },
  {
    name: "Dr Kamini Mishra",
    image: kaminiMishra,
    meta: "MPT (Orthopaedics), Invasive Pelvic Floor Practitioner, Women health Physical Therapist",
    text: "9+ years of experience in treating patients with chronic health issues. Masters in Orthopedics, with previous work experience at NHI, Cloudnine and Apollo. Specializes in Physiotherapy modalities especially in chronic diseases such as Diabetes and Hypertension, an Invasive Pelvic Floor practitioner and an expert in Women health management.",
    exp: "9+ yrs experience",
  },
  {
    name: "Diksha Goyal",
    image: dikshaGoyal,
    meta: "BSc in Dietetics and Applied Nutrition. Certified Clinical Nutritionist with experience of designing disease-centric dietary suggestions for lakhs of patients",
    text: "An expert in Nutrition with Masters from distinguished universities like IGNOU and University of Leeds, UK. Skilled in analysing the routine & lifestyle of patients dealing with chronic diseases and helping them get make improvements in their acute and chronic symptoms.",
  },
  {
    name: "Ritu Sheokand",
    image: rituSheokand,
    meta: "BSc. Home Science Hons., MSc. in Dietetics & Food Service Management",
    text: "An expert in Nutrition with Masters from distinguished universities like IGNOU and University of Leeds, UK. Skilled in analysing the routine & lifestyle of patients dealing with chronic diseases and helping them get make improvements in their acute and chronic symptoms.",
  },
  {
    name: "Ketki Tawade",
    image: ketkiTawade,
    meta: "MA in Applied Psychology with specialization in Clinical Psychology",
    text: "Qualified in Therapeutic Nutrition with distinction from the prestigious Lady Irwin College, Delhi University. A Diploma holder in Dietetics from VLCC and MSc. in Dietetics from IGNOU. Previous experience at Metro Hospital, Faridabad focused on chronic diseases.",
  },
];
const SecondList1 = [
  "24x7 analysis of BP/ Pulse readings & cardiac symptoms and guidance",
  "24x7 unlimited queries and support on chat",
  "Personalized healthy-heart plan (Diet & Exercise) based on your current routine with no restrictions/ fasting",
  "Consultation calls with experts:",
];
const SecondList2 = [
  "Stress/ anxiety management under leading Psychotherapy experts for improved heart health",
  "Live Yoga sessions and live webinars with MD / DM doctors",
  "Educational content – Meditation, Recipes, Educational videos, Blogs",
  "Savings on lab tests and medicines through trusted partners",
];
const innerList = [
  {
    id: 1,
    day: "DAY 1",
    text: " - Detailed health assessment call (30-45 mins) for medical history",
  },
  { id: 2, day: "DAY 2", text: " - App training call" },
  {
    id: 3,
    day: "DAY 7",
    text: " - Progress review call with Clinical Nutritionist",
  },
  { id: 4, day: "DAY 21", text: " - Physiotherapist call and exercise plan" },
  {
    id: 5,
    day: "Every 15 days",
    text: " - Clinical Nutritionist/Physiotherapist call",
  },
  {
    id: 6,
    day: "Pre-scheduled Senior Doctor (Cardiologist) consultation call",
    text: "",
  },
];
const MainList = [
  `<b>Patients with out-of-range Total Cholesterol, LDL, HDL, Triglycerides</b><br />
    With the best healthy heart plan and continuous monitoring in the Zyla program, most patients are able to control their Cholesterol and Triglyceride levels easily. This ensures prevention of any heart event in the future.
    `,
  `<b>Patients with Hypertension (High BP)</b><br />
    People who want to control high Blood Pressure or Tachycardia (high pulse) to prevent damage to delicate heart vessels. People facing high BP symptoms such as frequent headaches, palpitations.`,
  `<b>Patients with history of PTCA/ CABG</b><br />
    People who want to avoid any future heart event, through focus on minutest details of fluid intake allowed, cardiac rehabilitation exercises, heart healthy diet.`,
  `<b>People with smoking history</b><br />
    Smoking history increases the risk of heart-related events multi fold. We will work with you smoking remission and reversing the damage to the heart & lungs as much as possible.`,
];
const Patients = [
  {
    image: rohini,
    name: "Rohini GV",
    content:
      "Timely monitor suggested by the app..able to check the fluctuations both in bp and sugar levels with vital charts...taking care of the diet and vitals by the care team..immediate reply to your messages...a good app for diabetics",
  },
];
const FAQs = [
  {
    title: "How is the Zyla program different?",
    content: `A personal team of Senior Doctors, Nutritionists, Physiotherapists and Stress Counselors a message away, 24*7 on your phone. Your health plan in the app is completely personalized for your schedule and updated regularly, so it’s super easy to follow. Easy reminders on readings to measure today with immediate guidance. What’s more? Audio-guided meditations so that you sleep like a baby.
        We will be with you throughout to ensure your health improves - Zyla’s care team spends 300+ minutes with you every month to correct your health (30 times that of a monthly doctor visit).
        `,
  },
  {
    title: "How do I pay for the program?",
    content:
      "It will be a 1-step safe online payment gateway. You can complete your registration in under 1-minute by simply clicking on ‘Register Now’, choosing your payment method such as net banking, credit card, debit card and complete the transaction. Soon after you complete the transaction, you will receive a call from our team to setup everything.",
  },
  {
    title: "Which other health programs do you offer?",
    content: `Here are the Zyla programs that can help you with the best of health <We will hyperlink all these when we have all pages><br /><br />
        <ul className="list-disc">
          <li><b>Covid care program:</b> To help patients who have been diagnosed with mild/ moderate Covid or showcasing Covid symptoms and now recovering at home. A holistic care plan under leading Covid specialist doctors with 24x7 support. For any queries</li>
          <li><b>Defeating Diabetes Program:</b> For patients dealing with Type 1 or Type 2 Diabetes and related complications such as Neuropathy, Nephropathy, Retinopathy</li>
          <li><b>Healthy Heart Program:</b> To treat out-of-range Total Cholesterol, LDL, HDL, Triglycerides or for patients who have undergone PTCA/ CABG heart procedures or for patients dealing with Hypertension (High BP)</li>
          <li><b>Ova Women’s Health Program:</b> For women dealing with PCOS, PCOD, Thyroid disorders, Post Menopausal Syndrome, Anemia, Osteoporosis, Arthritis</li>
          <li><b>Ova Safe Pregnancy Program:</b> For a stress-free pregnancy journey under Senior Gynecologists & experts, with pre-natal nutrition & exercise support, pre-natal community yoga, 24x7 chat support and meditation support.</li>
          <li><b>Liver Lifeline Program:</b> For patients dealing with Grade 1/ 2 Fatty liver (higher SGOT/ SGPT/ Alkaline Phosphatase) and patients who have alcoholic liver damage</li>
          <li><b>Kidney Care Program:</b> for patients dealing with Chronic Kidney Disease (Higher Creatinine/ Uric Acid or Low EGFR)</li>
          <li><b>Holistic Health program:</b> To work on weight reduction, vitamin deficiencies, low Hemoglobin, frequent allergies/ infections, and overall health for disease prevention</li>
        </ul>
        `,
  },
  {
    title: "What happens after the completion of my program?",
    content:
      "The primary goal of the Zyla Program is to get your health parameters back in range and reduce your dependence on medicines. You can stay on it for the maintenance of your health for as long as you would like.",
  },
  {
    title: "I am very busy with work, how will I manage the phone calls?",
    content:
      "We understand, and that is exactly the reason the Zyla Program is designed to completely suit your busy life. All your Progress Review Calls are pre-scheduled to suit your work timings. While your first call may take 30 minutes, all follow-up calls usually wrap up in 10-15 minutes. At special occasions, if you are unable to take the calls, you can simply inform on the app and we will make all efforts to re-schedule based on the team’s availability.",
  },
  {
    title:
      "This program is for my father/ mother how will you manage them, they are not even comfortable in using the mobile app?",
    content: `Do not worry, we have handled thousands of parents through their caregivers. In fact, the program will be very helpful as you can pass on the stress of their health to us and the team will ensure they achieve their health goals. Without a doubt, the Zyla app will be the simplest mobile app you have ever used! It is designed keeping in mind that you are busy or may not have time to learn a complex application. What’s more? We will help you at every step and make this super easy and seamless for you.
          Read success stories of our Diabetes award winners `,
  },
];
function HeartHealth(props) {
  return (
    <CommonComponent
      topImg={HeartHealthImg}
      programName={"Healthy Heart Program"}
      programDesc={
        "For patients dealing with out-of-range Total Cholesterol, LDL, HDL, Triglycerides or for patients who have undergone PTCA/ CABG heart procedures or for patients dealing with Hypertension (High BP), looking to keep their heart healthy."
      }
      middleListHeading={
        "The most effective heart health program, with a triple combination approach:"
      }
      middleListContent={middleList}
      doctorsList={Doctors}
      box4={{
        heading: "24*7 care from a leading medical team: Join Now!",
        heading1: "Healthy Heart Program",
        heading2: "The 3 month program includes:",
        secondList1: SecondList1,
        secondList2: SecondList2,
        innerList: innerList,
        mainList: MainList,
      }}
      patients={Patients}
      therapy={"hearthealth"}
      faqs={FAQs}
      requestbox={{
        heading: "Ready to get started?",
        desc: "",
      }}
      blogHeading={"Read More on keeping heart healthy"}
    />
  );
}

export default HeartHealth;
