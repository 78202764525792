import React, { useEffect } from "react";
import "./assesment.scss";
import DoctorImage from "../../assets/images/doctor.png";
import PAQuestion from "./paQuestion";
import PACompletedComponent from "./paCompleted";
import { IonPage, IonContent } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { logEvent } from "../../app/eventSlice";
import {
  fetchAssessmentApi,
  markComplete,
  setValError,
} from "./assessmentSlice";
import { sendMessage } from "../chat/chatSlice";

export default function Assessment() {
  const dispatch = useDispatch();
  const assessmentStore = useSelector((state) => state.assessment);
  const messages = useSelector((state) => state.chat.messages);
  const chat = useSelector((state) => state.tab.chat);
  const loginStore = useSelector((state) => state.login);

  let lastMessage = null;
  if (messages && messages.ids.length > 0) {
    lastMessage = messages.entities[messages.ids[messages.ids.length - 1]];
  }

  const { user } = loginStore;
  const patientId = user.patientProfile.id;
  const { paCompleted, currentQuestion, ui, metadata, height, weight } =
    assessmentStore;
  const { loading, error } = ui;
  useEffect(() => {
    dispatch(logEvent({ action: "open_assessment" }));
    dispatch(fetchAssessmentApi({ patientId: patientId }));
  }, [dispatch, patientId]);

  const handleInvalidValue = (errorObject) => {
    const { error } = errorObject;
    dispatch(setValError(error));
  };

  const getProgress = () => {
    return (
      <div>
        <div className="fs40 fw-medium">{metadata.progress}%</div>
        <div>
          <div className="total-progress-possible">
            <div
              style={{ width: `${metadata.progress}%` }}
              className="current-progress"
            />
          </div>
        </div>
      </div>
    );
  };
  const markCompletePA = () => {
    if (
      lastMessage &&
      lastMessage.content.state &&
      lastMessage.content.state.type === "assessment"
    ) {
      const previousState = lastMessage.content.state;
      let message = {
        message: {
          text: "",
          type: 14,
          id: "" + new Date().getTime() + Math.random(),
        },
      };
      if (previousState && previousState.type) {
        message.state = previousState;
      }
      const req = {
        roomId: chat.chatRoom,
        userId: chat.externalUserIdSt,
        content: message,
      };
      dispatch(sendMessage(req)).then((r) => {
        dispatch(markComplete());
      });
    }
  };

  return (
    <IonPage>
      <IonContent>
        {!paCompleted ? (
          <div className="pa-container" onAnimationEnd={() => {}}>
            {error && (
              <div className="error-box">
                <span>{error}</span>
              </div>
            )}

            <div className="pa-header">
              <img className="doc-img-pa" alt="icon" src={DoctorImage} />
              <div className="progress-container">
                <div className="pa-heading fw-semibold">
                  Preliminary Assessment
                </div>
                <div>{getProgress()}</div>
              </div>
            </div>
            {currentQuestion && (
              <PAQuestion
                markComplete={() => markCompletePA()}
                lastMessage={lastMessage}
                loading={loading}
                patientId={patientId}
                height={height}
                weight={weight}
                currentQuestion={currentQuestion}
                questionMetadata={metadata}
                handleInvalidValue={(errorObject) => {
                  handleInvalidValue(errorObject);
                }}
              />
            )}
          </div>
        ) : (
          <PACompletedComponent />
        )}
      </IonContent>
    </IonPage>
  );
}
