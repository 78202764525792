import {
  IonContent,
  IonPage,
  IonSlide,
  IonSlides,
  useIonRouter,
} from "@ionic/react";
import React from "react";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import OfferCard from "./components/OfferCard";
import FeatureCardSection from "./components/featureCardSection";
import HomePageHeader from "./components/header";
import WalletWrapper from "./components/walletWrapper";
import DailyTargets from "./components/DailyTargets";
import "./home-page.scss";
import {
  fetchBannerData,
  getHomePageData,
  homePlanData,
  showNoDataAlert,
} from "./homePageSlice";
import MeditationCard from "./components/MeditationCard";
import VitalStats from "./components/VitalStats";
import VitalSubmission2 from "./components/VitalSubmission2";
import HomePageHPCards from "./components/healthPlan";
import { getVitalsParams } from "../vitalsChart/vitalsChartSlice";
import { IonAlert } from "@ionic/react";
import RecipeCard from "./components/RecipeCard";
import ZylaOffer from "./components/ZylaOffer";
import ZylaTV from "./zylaTV";
import HomePageBlogs from "./components/HomePageBlogs";
import closeIcon from "../../assets/images/ic_close_white.png";
import vegIcon from "../../assets/icons/veg.png";
import nonvegIcon from "../../assets/icons/non-veg.png";
import askGroup from "../../assets/images/ask-zyla-grouped.png";
import greenRecipeIcon from "../../assets/icons/green-recipe-icon-2.png";
import clockIcon from "../../assets/images/clock_icon_recipe.png";
import { CircularProgress, Drawer } from "@mui/material";
import { logEvent } from "../../app/eventSlice";
import { sendMessage } from "../chat/chatSlice";
import { getWalletData } from "../zylaWallet/walletSlice";
import { App } from "@capacitor/app";
import userService from "../../services/UserService";
import { clearHomeDot } from "../tab/tabSlice";
import homeService from "../../services/HomeService";
import { SliderImage } from "./components/SliderImage";
import { BookingNow } from "./components/booking";
import { MyRewardPoint } from "./components/MyRewardPoint";
import MyPlan from "./components/myPlan";
import { MyVitals } from "./components/myVital";

let swiper = null;
const slideOpts = {
  initialSlide: 0,
  speed: 500,
  autoplay: {
    delay: 5000,
  },
  on: {
    beforeInit() {
      swiper = this;
    },
  },
};

function NewHomePage({ zylaTabBtn }) {
  const chat = useSelector((state) => state.tab.chat);
  const isPremium = useSelector((store) => store.tab.uiConfig.isPremium);
  const isZylaZen = useSelector((store) => store.tab.uiConfig.isZylaZen);
  const showRedDotHome = useSelector((store) => store.tab.showRedDotHome);
  const walletLoaded = useSelector((store) => store.wallet.walletLoaded);
  const walletData = useSelector((store) => store.wallet.walletInfo);
  const homeStore = useSelector((state) => state.home);
  const device = useSelector((state) => state.app.device);
  const patientProfile = useSelector(
    (state) => state.login.user.patientProfile
  );
  const [recipe, setRecipe] = useState([]);
  const [openRecipe, setOpenRecipe] = useState(false);
  const [openRecipeImage, setOpenRecipeImage] = useState(false);
  const [recipesImageURL, setRecipesImageURL] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const ionRouter = useIonRouter();

  const { ui, noSleepDataAlert } = homeStore;
  const { testimonials, videos, blogs, featureTiles, carousel, info } = ui;
  const clientId = useSelector((state) => state.login.clientId);
  useEffect(() => {
    if (showRedDotHome) {
      dispatch(clearHomeDot());
    }
    dispatch(homePlanData(clientId));
    dispatch(getWalletData());
    dispatch(getVitalsParams({ patientId: patientProfile.id }));
    dispatch(getHomePageData(patientProfile.id));
    dispatch(fetchBannerData(patientProfile.id));

    App.addListener("appUrlOpen", (data) => {
      userService
        .getLatestSettings(patientProfile?.id)
        .then((res) => {
          if (res.data?.isChatVisible) {
            const myURL = new URL(data.url);
            const pathname = myURL.pathname;
            if (pathname === "/HNyL" || pathname === "/careTeam") {
              history.push("/careTeam");
            } else if (
              pathname === "/qh74" ||
              pathname === "/health-goals-screen"
            ) {
              history.push("/health-goals-screen");
            } else if (pathname === "/1srt" || pathname === "/health-plan") {
              history.push("/health-plan");
            } else if (pathname === "/ECQa" || pathname === "/medicine") {
              history.push("/medicine");
            } else if (pathname === "/3Pja" || pathname === "/vitals") {
              history.push("/vitals");
            } else if (pathname === "/6PA7" || pathname === "/premium") {
              history.push("/premium");
            } else if (pathname === "/Zk8q" || pathname === "/reporting") {
              history.push("/reporting");
            } else if (pathname === "/TtnJ" || pathname === "/documents") {
              history.push("/documents");
            } else if (pathname === "/h4bb" || pathname === "/vitalsChart") {
              history.push("/vitalsChart");
            } else if (pathname === "/8u31" || pathname === "/meditation") {
              history.push("/meditation");
            } else if (pathname === "/eB8Y" || pathname === "/dentalCare") {
              history.push("/dentalCare");
            } else if (pathname === "/iwR9" || pathname === "/trackingChart") {
              history.push("/trackingChart");
            } else if (pathname === "/qAZp" || pathname === "/devices") {
              history.push("/devices");
            } else if (pathname === "/K2FE" || pathname === "/profile") {
              history.push("/profile");
            } else if (pathname === "/Hdio" || pathname === "/tab/hamburger") {
              history.push("/tab/hamburger");
            } else if (pathname === "/y1i9" || pathname === "/family") {
              history.push("/family");
            } else if (pathname === "/navigo") {
              history.push("/navigo");
            } else if (pathname === "/address") {
              history.push("/address");
            } else if (pathname === "/nuMn" || pathname === "/tab/home") {
            } else {
              history.push("/tab/chat");
            }
          }
        })
        .catch((err) => {
          console.log("error is ", err);
        });
    });
    getRecipeData();
  }, []);

  const sendMesageRequest = (msg) => {
    dispatch(
      sendMessage({
        content: {
          message: {
            text: msg,
            type: 1,
            id: "" + new Date().getTime() + Math.random(),
          },
        },
        roomId: chat.chatRoom,
        userId: chat.externalUserIdSt,
      })
    );
    goToChatByButton();
  };
  const goToChatByButton = () => {
    try {
      if (zylaTabBtn && zylaTabBtn.current) {
        zylaTabBtn.current.props.onClick({
          detail: {
            tab: "chat",
            href: "/tab/chat",
          },
          href: "/tab/chat",
        });
      }
    } catch (err) {
      history.push("/tab/chat");
    }
  };

  const getRecipeData = () => {
    homeService
      .getRecipeData(patientProfile?.id)
      .then((res) => {
        setRecipe(res.data.recipes);
      })
      .catch((err) => {
        console.log("cannot get the recipes", err);
      });
  };

  return (
    <IonPage className="ion-tab-page">
      <IonContent style={{ "--background": "#F8F9FA" }}>
        <div className="home-page-wrapper">
          {/* <HomePageHeader
            patientProfile={patientProfile}
            // uiConfig={uiConfig}
            location={location}
          /> */}
          <div className="p-4">
            <SliderImage />
            <BookingNow />
            <MyRewardPoint />
            <MyPlan />
          </div>

          <div className="home-page-components responsive-600">
            {walletLoaded ? (
              walletData.id && <WalletWrapper />
            ) : (
              <CircularProgress
                className="ion-router-circular-progress"
                style={{ marginTop: "0px" }}
              />
            )}
            <OfferCard
              carousel={carousel}
              sendMessage={(a) => {
                sendMesageRequest(a);
              }}
            />

            <VitalStats />
            {window.Capacitor.platform !== "web" && <DailyTargets />}

            {window.Capacitor.platform === "web" && (
              <div className="error_resolve_div">
                <VitalSubmission2 />
              </div>
            )}
            <MeditationCard />
            <HomePageHPCards />
            {!isPremium && !isZylaZen && (
              <RecipeCard
                openRecipeDrawer={() => {
                  setOpenRecipe(true);
                }}
                recipe={recipe}
              />
            )}

            <ZylaOffer />
            <ZylaTV videos={videos} />
            <HomePageBlogs blogs={blogs} />
          </div>

          {info && (
            <div className="homepage__slides responsive-600">
              <IonSlides
                // className="homepage__slides__progress__bar"
                onIonSlideDidChange={() => {}}
                pager={true}
                mode="ios"
                options={slideOpts}
              >
                {testimonials.map((testimony, index) => {
                  return (
                    <IonSlide key={index + 0}>
                      <div className="home__testimony__slide">
                        <div className="slide__review__text">
                          "{testimony.body}"
                        </div>
                        <div className="img__wrapper">
                          <div className="slide__img">
                            <img src={testimony.image} alt="image" />
                          </div>
                        </div>
                        <div className="slide__review__name">
                          {testimony.name} <span>({testimony.age})</span>
                        </div>
                      </div>
                    </IonSlide>
                  );
                })}
              </IonSlides>
            </div>
          )}
          <div className="ask-zyla-wrapper responsive-600">
            <div className="ask-zyla-container">
              <div className="ask-zyla-left">
                <img src={askGroup} alt="icon" />
              </div>
              <div className="ask-zyla-right">
                <div className="ask-zyla-right-top">
                  <div className="top">
                    <span>Didn't find what you were looking for?</span>

                    <br />
                  </div>
                  <div className="bottom">
                    <span>Get answer to all your health questions.</span>
                  </div>
                </div>
                <div
                  className="ask-zyla-right-bottom"
                  onClick={() => {
                    dispatch(logEvent({ action: "click_ask_zyla_home" }));
                    goToChatByButton();
                  }}
                >
                  <div className="ask-zyla-button">
                    <span>Ask zyla</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {recipe && (
          <Drawer
            role="presentation"
            className="recipe__card__drawer"
            anchor="bottom"
            disableEnforceFocus
            open={openRecipe}
          >
            <div className="recipe-card-drawer-icon">
              <img src={greenRecipeIcon} alt="icon" />
              <span>Recipe</span>
            </div>
            <IonSlides
              className="recipe_drawer_ion_slides"
              onIonSlideDidChange={(e) => {}}
              pager={true}
            >
              {recipe.map((recipes) => {
                return (
                  <IonSlide>
                    <div className="recipe_drawer_container">
                      <div className="recipe_drawer_header">
                        <img
                          alt=""
                          src={recipes["image"]}
                          className="recipe_drawer_header_img"
                          onClick={() => {
                            setOpenRecipeImage(true);
                            setRecipesImageURL(recipes.image);
                          }}
                        />
                        <img
                          alt=""
                          src={closeIcon}
                          className="recipe-close-icon"
                          onClick={() => {
                            setOpenRecipe(false);
                          }}
                        />
                        <div className="recipe_drawer_header_timing">
                          <img src={clockIcon} alt="icon" />
                          <div>{recipes.cooking_time} min</div>
                        </div>
                        <div className="recipe_drawer_header_cooking_tag">
                          Cooking time
                        </div>
                      </div>
                      <div className="recipe_name_div">
                        <div className="recipe_name_div_top">
                          <div>{recipes.name}</div>
                          <img
                            alt=""
                            src={recipes.type === "veg" ? vegIcon : nonvegIcon}
                          />
                        </div>
                        <div className="recipe_name_div_bottom"></div>
                      </div>
                      <div className="recipe_drawer_main">
                        <div className="recipe_drawer_main_ingredients">
                          <div className="recipe_drawer_main_ingredients_header">
                            <div className="fw-semibold">
                              Serving size: {recipes.serving_size}
                            </div>
                            <div className="fw-semibold">Ingredients: </div>
                          </div>
                          <ul className="recipe_drawer_list">
                            {recipes.ingredients.map((ing) => {
                              return (
                                <li key={ing.id}>{`${
                                  ing.amount_min.length > 0
                                    ? ing.amount_min + " - "
                                    : ""
                                }
                                    ${
                                      ing.amount_max +
                                      " " +
                                      ing.unit +
                                      " " +
                                      ing.name
                                    } ${
                                  ing.instruction.length > 0 ? "-" : ""
                                } ${ing.instruction}`}</li>
                              );
                            })}
                          </ul>
                        </div>
                        <div className="recipe_drawer_main_ingredients">
                          <div className="fw-semibold">Method:</div>
                          <ul className="recipe_drawer_list ">
                            {recipes.method.split("\n").map((met) => {
                              return <li>{met}</li>;
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </IonSlide>
                );
              })}
            </IonSlides>
            {openRecipeImage && (
              <div className="recipe-image-drawer-container">
                <img
                  alt=""
                  src={recipesImageURL}
                  className="recipe-image-drawer-container-main-img"
                />
                <img
                  alt=""
                  src={closeIcon}
                  className="recipe-image-drawer-container-close-icon"
                  onClick={() => {
                    setOpenRecipeImage(false);
                  }}
                />
              </div>
            )}
          </Drawer>
        )}
        {noSleepDataAlert && (
          <IonAlert
            className="no_data_alert"
            isOpen={noSleepDataAlert}
            onDidDismiss={() => dispatch(showNoDataAlert(false))}
            message={
              "In order to track your step/sleep data accurately, make sure that your schedule is updated on the Health app."
            }
            buttons={[
              {
                text: "OK",
                cssClass: "no_data_alert_ok",
                handler: () => {
                  dispatch(showNoDataAlert(false));
                },
              },
            ]}
          />
        )}
      </IonContent>
    </IonPage>
  );
}

export default NewHomePage;
