import React from "react";
import { IonSpinner } from "@ionic/react";

export default function LoadingVital() {
  return (
    <div className="no-vital-required text-center">
      <IonSpinner name="crescent" color="secondary" />
    </div>
  );
}
