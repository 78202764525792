import React from "react";
import { IonContent, IonModal } from "@ionic/react";
import { useSelector } from "react-redux";

export default function CountryModal({ showModal, onClose, onChange }) {
  const appStore = useSelector((state) => state.app);
  return (
    <IonModal
      isOpen={showModal}
      cssClass="selection-modal"
      onDidDismiss={() => {
        onClose();
      }}
    >
      <IonContent>
        <div className="w-full flex flex-col justify-evenly h-full">
          <p id="select-country">Select country</p>
          {appStore.country.list.map((country) => (
            <button
              onClick={() => {
                onChange(country);
              }}
              key={country.id}
              className="country-button"
            >
              <div>
                <img src={country.image} alt={country.name} />
                <span>{country.name}</span>
              </div>
              <span>+{country.code}</span>
            </button>
          ))}
        </div>
      </IonContent>
    </IonModal>
  );
}
