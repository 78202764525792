import React, { useEffect, useState } from "react";
import walletService from "../../../services/WalletServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Typography,
} from "@mui/material";
import backImg from "../../../assets/images/icon_arrow_back.png";
import { useDispatch } from "react-redux";
import { handleScreenChanges } from "../walletSlice";

const walletFAQSLocal = [
  {
    id: 1,
    question: "What is Zyla wallet?",
    answer:
      "Zyla wallet is a Pre-loaded wellness card/wallet provided by a corporate employer to their employees, this can be redeemed for order/booking made via the Zyla app and network partners only.",
  },
  {
    id: 2,
    question: "What is Zyla coin?",
    answer:
      "Zyla coins are simply virtual money with value equivalent to 1 Indian rupee.",
  },
  {
    id: 3,
    question: "Can I add money to the Zyla wallet?",
    answer:
      "Currently, you cannot add money to the Zyla Wallet. It is a pre-loaded wellness card/wallet provided by a corporate employer to their employees.",
  },
  {
    id: 4,
    question: "Where can I check my Zyla coin balance?",
    answer:
      "Go to Account. You'll be able to see your balance reflected next to Zyla wallet. Click on Zyla wallet to view wallet history.",
  },
  {
    id: 5,
    question: "What services can I use my Zyla Coin on?",
    answer:
      "Zyla coins could be redeemed for orders/bookings made via the Zyla app and other network partners only.",
  },
  {
    id: 6,
    question:
      "What is the difference between other payment methods and Zyla coin?",
    answer:
      "Unlike other payment methods, payment via Zyla coins is eligible for attractive discounts and tax rebates, as per applicability.",
  },
  {
    id: 7,
    question: "What is the balance limit for the Zyla coin?",
    answer:
      "Zyla wallet balance limit Extends up to Rs 50,000 equivalent Zyla coins.",
  },
  {
    id: 8,
    question: "What is the validity of the Zyla coin?",
    answer:
      "The validity of Zyla coins is for the ongoing financial year from the date of issuance.",
  },
  {
    id: 9,
    question: "Are there any additional or hidden charges on the Zyla wallet?",
    answer:
      "No additional or hidden charges are applicable to the Zyla wallet.",
  },
  {
    id: 10,
    question: "How many Zyla coin can I use per transaction?",
    answer: "There is no such limit per transaction.",
  },
  {
    id: 11,
    question:
      "What should I do if the balance in my Zyla coin is not enough to pay for my order?",
    answer:
      "If the balance in your Zyla wallet is insufficient, you can pay the rest of the transaction value through other applicable modes of payment.",
  },
  {
    id: 12,
    question: "Can I decide not to pay for an order via Zyla coin?",
    answer: "No, it is not possible.",
  },
  {
    id: 13,
    question: "Can I decide how much to pay for an order via Zyla coin?",
    answer:
      "No, it is not possible partial payment via other modes of payment is only possible, if Zyla wallet balance is less than order value.",
  },
  {
    id: 14,
    question: "Cancellation & refund of Zyla coin?",
    answer:
      "For the successfully canceled booking, we will initiate your refund within 1 working day. The amount should reflect in your original source account within 5–7 working days.",
  },
  {
    id: 15,
    question:
      "How can I check the refund against my order being credited to the Zyla wallet balance?",
    answer:
      "You can check your credited refund in the Wallet History section of Zyla wallet.",
  },
  {
    id: 16,
    question: "Can I transfer the Zyla coin balance to my bank account?",
    answer: "No. The Zyla wallet is a non-transferable digital wallet.",
  },
  {
    id: 17,
    question:
      "Is my Zyla coin balance transferable to my family and/or friends?",
    answer:
      "No. Zyla wallet is non-transferable to another Zyla account. However, based on eligibility you can book services for the eligible family members from your Zyla account.",
  },
  {
    id: 18,
    question:
      "Will I get any interest on the balance of the Zyla wallet similar to a bank's Savings Account balance?",
    answer:
      "No, there will be no interest paid for the Zyla wallet balance as it is a virtual digital cash wallet.",
  },
  {
    id: 19,
    question: "Will my payment through Zyla wallet reflect on the invoice?",
    answer:
      "Yes. Amount paid through Zyla wallet will reflect on the invoice along with other payment methods used, if any.",
  },
];

function WalletFAQ(props) {
  const [loading, setLoading] = useState(true);
  const [walletFAQS, setWalletFAQs] = useState([...walletFAQSLocal]);
  const dispatch = useDispatch();

  useEffect(() => {
    walletService
      .getWalletFAQs()
      .then((res) => {
        setWalletFAQs([...res.data]);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);
  return (
    <div className="zyla-wallet-faq-container">
      <div className="zyla-wallet-faq-header">
        <img
          src={backImg}
          onClick={() => dispatch(handleScreenChanges(1))}
          alt="icon"
        />
      </div>
      <div className="zyla-wallet-faq-mid">
        <h1>Frequently Asked Questions</h1>
      </div>
      {loading && (
        <div className="">
          <CircularProgress className="ion-router-circular-progress"></CircularProgress>
        </div>
      )}
      {!loading && (
        <div>
          {walletFAQS.map((element) => (
            <Accordion>
              <AccordionSummary
                expandIcon={
                  <span>
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className="zyla-wallet-faq-chevron-down"
                    />
                  </span>
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="zyla-wallet-faq-accordian-question">
                  {element.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="zyla-wallet-faq-accordian-answer">
                  {element.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}
    </div>
  );
}

export default WalletFAQ;
