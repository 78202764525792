import react from "react";
import { IonSpinner } from "@ionic/react";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { cancelAppointement, setStateFromPayload } from "../navigoSlice";
import closeIcon from "../../../assets/icons/ic_send_primary_copy.png";
export default function NavigoDrawerSection() {
  const navigoStore = useSelector((store) => store.navigo);
  const dispatch = useDispatch();
  const { drawerType, currentFamilyMemberCount, cancelAppointmentId } =
    navigoStore.ui;
  const handleAppointmentCancel = (id) => {
    let updatedBody = {
      appointmentID: id,
      status: 3,
    };
    
    dispatch(cancelAppointement(updatedBody));
  };
  return (
    <div
      className={`navigo-booking-general-drawer ${
        drawerType === 4 ? "navigo-booking-general-drawer-white" : ""
      }`}
    >
      {drawerType === 1 && (
        <div className="navigo-booking-current-service-know-more">
          <div className="navigo-booking-current-service-know-more-top">
            <img
              src={closeIcon}
              alt="icon"
              onClick={() => {
                dispatch(
                  setStateFromPayload({
                    openDrawer: false,
                    drawerType: 0,
                    currentFamilyMemberCount: 0,
                  })
                );
              }}
            />
          </div>
          <p className="navigo-booking-current-service-know-more-description">
            Already availed for {currentFamilyMemberCount} family members
          </p>
        </div>
      )}
      {drawerType === 2 && (
        <div className="navigo-booking-cancel-modal">
          <p className="navigo-booking-cancel-modal-top">
            Are you sure you want to cancel <br />
            this appointment?
          </p>
          <div className="navigo-booking-cancel-modal-bottom">
            <button
              className="navigo-booking-cancel-modal-bottom-cancel"
              onClick={() => {
                handleAppointmentCancel(cancelAppointmentId);
              }}
            >
              Cancel appointment
            </button>
            <button
              className="navigo-booking-cancel-modal-bottom-back"
              onClick={() => {
                dispatch(
                  setStateFromPayload({ openDrawer: false, drawerType: 0 })
                );
              }}
            >
              Back
            </button>
          </div>
        </div>
      )}
      {drawerType === 3 && (
        <div className="general-central-loader">
          <CircularProgress className="general-central-circular-progress"></CircularProgress>
        </div>
      )}
      {drawerType === 4 && (
        <div className="general-navigo-downloading-loader">
          <p>Downloading</p>
          <IonSpinner />
        </div>
      )}
      {drawerType === 5 && (
        <div className="navigo-booking-cancel-modal ">
          <p className="navigo-booking-cancel-modal-top ">
            <strong>Important</strong>
          </p>
          <p className="navigo-booking-cancel-modal-top ">
            Cancellation is not available for the specified service.
          </p>
          <div className="navigo-booking-cancel-modal-bottom navigo-booking-margin-left-auto">
            <button
              onClick={() => {
                dispatch(
                  setStateFromPayload({ openDrawer: false, drawerType: 0 })
                );
              }}
              className="navigo-booking-cancel-modal-bottom-back"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                dispatch(
                  setStateFromPayload({
                    openDrawer: false,
                    drawerType: 0,
                    callHandleAvailNow: true,
                  })
                );
              }}
              className="navigo-booking-cancel-modal-bottom-general"
            >
              Continue
            </button>
          </div>
        </div>
      )}
      {drawerType === 6 && (
        <div className="navigo-booking-cancel-modal ">
          <p className="navigo-booking-cancel-modal-top ">
            Cancellation is not available.please contact zyla chat for details
          </p>
          <div className="navigo-booking-cancel-modal-bottom">
            <button
              className="navigo-booking-cancel-modal-bottom-general navigo-general-margin-left-0"
              onClick={() => {
                dispatch(
                  setStateFromPayload({ openDrawer: false, drawerType: 0 })
                );
              }}
            >
              Ok
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
