import moment from "moment";

export const currentTimeFormat = time => {
  return new moment(time).format("LT");
};

export const dateFormat = time => {
  return new moment(time).format("YYYY-MM-DD");
};

export const dateInDDMMYYYY = time => {
  return new moment(time).format("DDMMYYYY");
};
export const miliSeconds = time => {
  return new moment(time).valueOf();
};
