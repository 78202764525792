import calenderImage from "../../../assets/images/calender.png";
import clockImage from "../../../assets/images/clock-black.png";
import selfImage from "../../../assets/images/self.png";
import Clock from "../../../assets/images/clock.png";
import xImage from "../../../assets/images/x.png";
import { Link } from "react-router-dom";

export function AppoinmentCard({ doctor }) {
  return (
    <>
      <div className="font-bold text-base leading-5 p-4">
        Upcoming appointments
      </div>

      <div className="p-4 bg-white no-underline">
        <div
          className=" w-full font-bold text-xs leading-7   px-6 h-32 shadow-md rounded-md pt-2"
          style={{ color: "#171A21" }}
        >
          {doctor}
          <div className="flex justify-between ">
            <div className="flex items-center">
              <img src={calenderImage} alt="" className="w-4 h-4 " />
              <span className="px-2  font-semibold text-xs leading-5">
                02 Aug, 2022
              </span>
            </div>
            <div className="flex items-center">
              <img src={clockImage} alt="" className="w-4 h-4" />
              <span className="px-2 font-semibold text-xs leading-5">
                12:30 PM
              </span>
            </div>
            <div className="flex items-center">
              <img src={selfImage} alt="" className="w-4 h-4" />
              <span className="px-2 font-semibold text-xs leading-5">Self</span>
            </div>
          </div>
          <div className="flex pt-4 items-center ">
            <button
              className="px-3 py-1 rounded-md items-center text-center"
              style={{ backgroundColor: "#EDF2FF" }}
            >
              <img src={Clock} alt="" className="w-3 h-3" />
              <span
                style={{ color: "#364FC7" }}
                className="font-semibold text-xs leading-5 px-1"
              >
                Reschedule
              </span>
            </button>
            <div className="px-3">
              <button
                className="px-3 py-1 rounded-md items-center text-center "
                style={{ backgroundColor: "#FFE3E3" }}
              >
                <img src={xImage} alt="" className="w-3 h-3" />
                <span
                  style={{ color: "#E03131" }}
                  className="font-semibold text-xs leading-5 px-1"
                >
                  Cancel
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
