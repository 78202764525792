import React from "react";
const ErrorBox = () => {
  return (
    <div
      style={{
        height: "25px",
        display: "flex",
        alignItems: "center",
        position: "absolute",
        zIndex: 9999,
        backgroundColor: "#e64545",
        color: "white",
        fontSize: "14px",
        justifyContent: "center",
        width: "100%",
        left: "0",
        right: "0",
        top: "var(--ion-safe-area-top)",
        animation: "FadeAnimation 1s ease-in 3s forwards",
      }}
      className="error-box"
    >
      <span>Something went wrong on our end</span>
    </div>
  );
};

export default ErrorBox;
