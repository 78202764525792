import React, { useEffect, useRef, useState } from "react";
import PDFicon from "../../../../assets/images/pdf-image.png";
import no_img from "../../../../assets/images/no_image_doc.png";
import whiteSync from "../../../../assets/icons/white-sync-icon.png";
import closeWhite from "../../../../assets/images/ic_close_white.png";
import previewImg from "../../../../assets/icons/orange_no_doc_icon.png";
import crossIcon from "../../../../assets/images/ic_close_white.png";
import { CircularProgress } from "@mui/material";
import documentService from "../../../../services/DocumentService";

import { Network } from "@capacitor/network";
import PrepareUploadService from "../../../../services/PrepareUploadService";
import { useDispatch, useSelector } from "react-redux";
import { sendMessage } from "../../chatSlice";

function UploadingCard({ msg,name, sendUploadMsg, handleError }) {
  const patientId = useSelector(
    (store) => store.login.user?.patientProfile?.id
  );
  const [loaded, setLoaded] = useState(true);
  const controller = useRef(null);
  const imgsrc = useRef("");
  const imgRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (msg.format !== 4) {
      var reader = new FileReader();
      // reader.readAsArrayBuffer(this.props.msg.file);
      reader.onload = (evt) => {
        imgRef.current.src = reader.result;
        imgsrc.current = reader.result;

        uploadDocumentAtBackend();
      };
      reader.readAsDataURL(msg.file);
    } else {
      uploadDocumentAtBackend();
    }
  }, []);

  const uploadDocumentAtBackend = () => {
    const myController = new AbortController();
    controller.current = myController;
    const signal = controller.current.signal;

    const file = msg.file;
    const document = PrepareUploadService.buildDocumentFromFile(file);

    if (document === null) {
      return;
    }
    if (file.size <= 20000000) {
      documentService
        .uploadDocument(file, name, document, signal, patientId)
        .then((response) => {
          const data = response.data;
          let id = "" + new Date().getTime() + Math.random();
          let content = {
            message: {
              text: data.id,
              type: data.format === 4 ? 3 : 2,
              id: id,
              metadata: {
                id: data.id,
              },
            },
          };
          sendUploadMsg(content, msg.id, true);
          // dispatch(sendMessage({ content: content }));
        })
        .catch((error) => {
        
          Network.getStatus()
            .then((status) => {
              if (!status.connected) {
                handleError(true, `No internet connection, please try again.`);
              } else {
                handleError(true, `Something went wrong, please try again.`);
              }
            })
            .catch((err) => {
              handleError(true, `Something went wrong, please try again.`);
            });
          setLoaded(false);
        });
    } else {
      handleError(
        true,
        `File size exceeds 20 MB, please resize file and try again.`
      );
    }
  };

  const truncate = (n, len) => {
    var ext = n.substring(n.lastIndexOf(".") + 1, n.length).toLowerCase();
    var filename = n.replace("." + ext, "");
    if (filename.length <= len) {
      return n;
    }
    filename = filename.substr(0, len) + (n.length > len ? "[...]" : "");
    return filename + "." + ext;
  };
  const getFailedStateUI = (isImg) => {
    return (
      <div className="failed-document-upload">
        <div className="failed-document-upload-top">
          <div className="failed-document-upload-left">
            {!isImg ? (
              <div className="failed-document-upload-left-top">
                <img src={previewImg} alt="" />
              </div>
            ) : (
              <div className="failed-document-upload-left-top-img">
                <img src={imgsrc.current} alt="" />
              </div>
            )}
            <div
              className="failed-document-upload-left-top-close"
              onClick={() => {
                sendUploadMsg({}, msg.id, false);
              }}
            >
              <img src={closeWhite} alt="" />
            </div>
          </div>
          <div className="failed-docunment-upload-right">
            <div className="failed-document-upload-right-top">
              <span>Upload failed</span>
            </div>
            <div
              className="failed-document-upload-right-bottom"
              onClick={() => {
                setLoaded(true);
                uploadDocumentAtBackend();
              }}
            >
              <img src={whiteSync} alt="" />
              <span>Retry</span>
            </div>
          </div>
        </div>
        <div className="failed-document-upload-bottom">
          <div className="failed-document-upload-left-bottom">
            <span>{truncate(msg.name, 25)}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className={""}>
        <div className="uploading-document-card-container">
          {msg.format === 4 ? (
            <>
              {loaded ? (
                <div className="uploading-doc-preview">
                  <div className="uploading-doc-preview-img">
                    <div className="uploading-doc-preview-img-parent">
                      <img src={previewImg} alt="" />
                    </div>
                  </div>

                  <div className="uploading-doc-preview-info">
                    <img src={PDFicon} alt="" />
                    <span>{msg.name}</span>
                  </div>
                </div>
              ) : (
                getFailedStateUI()
              )}
            </>
          ) : (
            <>
              {loaded ? (
                <div className="uploading-image-preview">
                  <img
                    src={imgsrc.current ? imgsrc.current : no_img}
                    ref={imgRef}
                    alt=""
                  />
                </div>
              ) : (
                getFailedStateUI(true)
              )}
            </>
          )}
          <div className="upload-doc-loading-spinner">
            {loaded ? (
              <div
                className="upload-doc-loading-spinner-active-container"
                onClick={() => {
                  if (controller.current) {
                    controller.current.abort();
                  }
                  setLoaded(false);
                }}
              >
                <div className="upload-doc-loading-spinner-active">
                  <img src={crossIcon} alt="" />
                  <CircularProgress></CircularProgress>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
export default UploadingCard;
