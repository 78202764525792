import React, { useEffect } from "react";
export default function Toast({ msg, close }) {
  useEffect(() => {
    setTimeout(() => {
      close();
    }, 2000);
  });
  return (
    <div className="general-toast-component">
      <span>{msg}</span>
    </div>
  );
}
