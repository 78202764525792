import { IonPage, IonContent } from "@ionic/react";
import backImage from "../../../assets/images/back.png";
import upIconImage from "../../../assets/images/arrow-ios-up.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import BackButton from "../../../components/common/BackButton";

export default function Faqs() {
  const [showRewardPoints, setshowRewardPoints] = useState(true);
  const [showPointBalance, setshowPointBalance] = useState(true);
  const [showRewardValidity, setshowRewardValidity] = useState(true);
  const [showPolicyYear, setshowPolicyYear] = useState(true);
  const [showHRA, setShowHRA] = useState(true);
  const [showCHC, setShowCHC] = useState(true);
  const [showStep, setShowStep] = useState(true);
  const [showMHC, setShowMHC] = useState(true);

  return (
    <IonPage>
      <IonContent>
        <div className="">
          <div className="px-5 pb-5 mt-3 ">
            <BackButton />

            <div className="py-3 font-bold text-lg leading-7 text-lightblack flex ">
              <div className=" mb-1"> Frequently asked questions</div>
            </div>
            <div className="flex justify-between  items-center">
              <div className="font-bold text-lightblack text-xs">
                What are reward points?
              </div>
              <img
                src={upIconImage}
                alt=""
                className={`h-6 w-6 ${
                  showRewardPoints ? "delay-70  " : "rotate-180"
                } `}
                onClick={() => setshowRewardPoints(!showRewardPoints)}
              />
            </div>

            {showRewardPoints && (
              <div className="py-3 font-normal text-xs text-lightblack ">
                The Max Fit app provides our policyholders an opportunity to
                earn reward points through healthy activities including health
                risk assessment, steps tracking, health checkup and
                participation in health challenges. Basis your reward points,
                you will be placed in a particular wellness tier which will
                determine your Wellness Booster (as a % of AP) for the year.
              </div>
            )}

            <div className="flex justify-between  items-center">
              <div className="font-bold text-lightblack  text-xs">
                Where can I check my reward points balance?
              </div>
              <img
                src={upIconImage}
                alt=""
                className={`h-6 w-6 ${
                  showPointBalance ? "delay-70 " : "rotate-180"
                } `}
                onClick={() => setshowPointBalance(!showPointBalance)}
              />
            </div>
            {showPointBalance && (
              <div className="py-3 font-normal text-xs text-lightblack ">
                Go to my reward points section on the Home screen. You'll be
                able to view your reward points along with your wellness tier
                for your policy.
              </div>
            )}

            <div className="flex justify-between  items-center">
              <div className="font-bold text-lightblack text-xs">
                What is the validity of reward points?
              </div>
              <img
                src={upIconImage}
                alt=""
                className={`h-6 w-6 ${
                  showRewardValidity ? "delay-70 " : "rotate-180"
                } `}
                onClick={() => setshowRewardValidity(!showRewardValidity)}
              />
            </div>
            {showRewardValidity && (
              <div className="py-3 font-normal text-xs text-lightblack  ">
                On policy renewal, reward points will reset to 0 for the
                specific policy.
              </div>
            )}

            <div className="flex justify-between  items-center">
              <div className="font-bold text-lightblack text-xs">
                What will be considered as policy year?
              </div>
              <img
                src={upIconImage}
                alt=""
                className={`h-6 w-6 ${
                  showPolicyYear ? "delay-70 " : "rotate-180"
                } `}
                onClick={() => setshowPolicyYear(!showPolicyYear)}
              />
            </div>
            {showPolicyYear && (
              <div className="py-3 font-normal text-xs text-lightblack ">
                For each Secure Earnings and Wellness Advantage Plan, policy
                year would start from the policy inception date.
              </div>
            )}
            <div className="flex justify-between  items-center">
              <div className="font-bold text-lightblack text-xs">
                How can I earn reward points for health risk assessment?
              </div>
              <img
                src={upIconImage}
                alt=""
                className={`h-6 w-6 ${showHRA ? "delay-70 " : "rotate-180"} `}
                onClick={() => setShowHRA(!showHRA)}
              />
            </div>
            {showHRA && (
              <div className="py-3 font-normal text-xs text-lightblack ">
                Book your complimentary health risk assessment call listed on
                the homepage as per your schedule. Max Fit health expert will
                call you at the scheduled time. Once successfully completed,{" "}
                <strong>3000 reward points</strong> will be credited within 48
                hours.
              </div>
            )}

            <div className="flex justify-between  items-center">
              <div className="font-bold text-lightblack text-xs">
                How can I earn reward points for a complimentary health checkup?
              </div>
              <img
                src={upIconImage}
                alt=""
                className={`h-6 w-6 ${showCHC ? "delay-70 " : "rotate-180"} `}
                onClick={() => setShowCHC(!showCHC)}
              />
            </div>
            {showCHC && (
              <div className="py-3 font-normal text-xs text-lightblack ">
                Book your “Complimentary health checkup- Maxfit” listed under
                the book diagnostic section listed on the homepage. The
                phlebotomist will visit your home for blood sample collection,
                once the report is generated reward points (maximum up to 10,000
                annually) will be credited.
                <ol className="pl-4">
                  {" "}
                  <li>
                    <strong>5000 points</strong> for successful sample
                    collection and report generation
                  </li>{" "}
                  <li>
                    <strong>1250 points</strong> each for blood sugar, blood
                    pressure, BMI, and total cholesterol within normal
                    parameters.
                  </li>
                </ol>
              </div>
            )}
            <div className="flex justify-between  items-center">
              <div className="font-bold text-lightblack text-xs">
                How can I earn reward points for daily step count?
              </div>
              <img
                src={upIconImage}
                alt=""
                className={`h-6 w-6 ${showStep ? "delay-70 " : "rotate-180"} `}
                onClick={() => setShowStep(!showStep)}
              />
            </div>
            {showStep && (
              <div className="py-3 font-normal text-xs text-lightblack ">
                Daily reward points are allocated based on steps recorded in the
                step tracker (maximum up to 12,000 points annually).
                <ol className="pl-4">
                  <li>
                    Total steps per day: <strong>{"< 5000 - 0 points"}</strong>
                  </li>{" "}
                  <li>
                    Total steps per day:{" "}
                    <strong>{"5000-9999 - 50 points"}</strong>
                  </li>{" "}
                  <li>
                    Total steps per day:{" "}
                    <strong>{"10000-14999 - 100 points"}</strong>
                  </li>{" "}
                  <li>
                    Total steps per day:{" "}
                    <strong>{"≥ 15000 - 150 points"}</strong>
                  </li>{" "}
                </ol>
              </div>
            )}
            <div className="flex justify-between  items-center">
              <div className="font-bold text-lightblack text-xs">
                How can I earn reward points for Monthly health challenges?
              </div>
              <img
                src={upIconImage}
                alt=""
                className={`h-6 w-6 ${showMHC ? "delay-70 " : "rotate-180"} `}
                onClick={() => setShowMHC(!showMHC)}
              />
            </div>
            {showMHC && (
              <div className="py-3 font-normal text-xs text-lightblack ">
                Reward points are allocated based on your participation &
                performance in the monthly health challenges (maximum up to
                18,000 points annually).{" "}
                <ol className="pl-4">
                  <li>
                    Participation in monthly health challenge:{" "}
                    <strong>500 points</strong>
                  </li>{" "}
                  <li>
                    On featuring in the top ranks for the challenge:{" "}
                    <strong>1000 points</strong>
                  </li>
                </ol>
              </div>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
