import axiosClient from "../app/axiosConfig";
import APIConfig from "./APIConfig";

class ChatService {
  getInformationCard(id) {
    let url = APIConfig.feed.getInformationCard.format(id);
    return axiosClient.get(url);
  }

  getDoctorCallNotes(id) {
    let url = APIConfig.callNote.detailedNote.format(id);
    return axiosClient.get(url);
  }
  getDoctorNotes(id) {
    let url = APIConfig.doctorNotes.get.format(id);
    return axiosClient.get(url);
  }

  getDynImage = (src, title, summary) => {
    let url = APIConfig.shareInfo.sharing;
    return axiosClient.post(url, { src: src, title: title, summary: summary });
  };
  getDynImage2 = (src, title, summary, onSuccess, onError) => {
    let url = APIConfig.shareInfo.sharingImg;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        src: src,
        title: title,
        summary: summary,
      }),
    })
      .then((res) => {
        onSuccess(res);
      })
      .catch((err) => {
        onError(err);
      });
  };
}

export default ChatService;
