import moment from "moment";
import React from "react";

const buttons = [
  {
    id: 0,
    text: "Yesterday",
    date: moment().subtract(1, "day").format("Do MMMM"),
  },
  {
    id: 1,
    text: "Today",
    date: moment().format("Do MMMM"),
  },
  {
    id: 2,
    text: "Tomorrow",
    date: moment().add(1, "day").format("Do MMMM"),
  },
];

export default function CreateHeaderButton({ onClickChangeDate, active }) {
  return (
    <div className="subheader">
      {buttons.map((value) => {
        return (
          <button
            onClick={(e) => onClickChangeDate(value.date, value.id)}
            className={active === value.id ? "button-active" : ""}
          >
            <div>{value.text}</div>
            <div className="small-text">{value.date}</div>
          </button>
        );
      })}
    </div>
  );
}
