import React from "react";
import TimeIcon from "../../../assets/images/ic_in_progress_white.png";

export default function HPCard({ categoryFields, setSliderData, index }) {
  const viewHealthPlanOptions = (initialSlide) => {
    setSliderData(categoryFields, initialSlide);
  };

  return (
    <div className="hp-category-fields">
      <div className="hp-option-name">
        {index + 1 + ". " + categoryFields.name}
      </div>
      {categoryFields.time && (
        <div className="hp-option-time">
          <img src={TimeIcon} alt="" /> {categoryFields.time}
        </div>
      )}
      <div className="hp-category-options">
        {categoryFields.options.map((categoryOptions, optionIndex) => {
          if (optionIndex < 2) {
            return (
              <div
                onClick={() => viewHealthPlanOptions(optionIndex)}
                className="hp-options-card"
              >
                <img
                  alt=""
                  className="hp-options-img"
                  src={
                    categoryOptions && categoryOptions.images
                      ? categoryOptions.images[0]
                      : null
                  }
                />
                <div className="hp-image-desc fs12">{categoryOptions.name}</div>
              </div>
            );
          }
        })}
        {categoryFields.options && categoryFields.options.length > 2 && (
          <div
            onClick={() => viewHealthPlanOptions(2)}
            className="see-all-hp-options"
          >
            <div>+ {categoryFields.options.length - 2}</div>
            <div className="fs12 see-all">See all</div>
          </div>
        )}
      </div>
    </div>
  );
}
