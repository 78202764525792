import { IonRippleEffect } from "@ionic/react";
import React from "react";
const RippleButton = ({ className, nospace, onClick, disabled, children }) => {
  let cls = className ? `${className} ion-activatable` : "ion-activatable";
  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        // margin: nospace ? "" : "-10px",
        // padding: nospace ? "" : "10px",
      }}
      className={cls}
      onClick={onClick ? onClick : () => {}}
    >
      {children}
      {!disabled && <IonRippleEffect type="bounded"></IonRippleEffect>}
    </div>
  );
};

export default RippleButton;
