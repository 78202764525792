import React, { useEffect } from "react";
import SecureImage from "../../../components/common/SecureImage";
import defaultImage from "../../../assets/images/bg_pattern.png";
import PlayImage from "../../../assets/images/group_copy.png";
import overlayLock from "../../../assets/images/overlay-lock.png";
import { useDispatch, useSelector } from "react-redux";
import { logEventWithData } from "../../../app/eventSlice";
import { toggleMeditateNow } from "../meditationSlice";
export default function MeditationVideoCard({
  media,
  togglePlay,
  isRecomShow,
  isFromMeditation,
  currentMedia,
  name,
}) {
  const dispatch = useDispatch();
  const { ui } = useSelector((store) => store.meditation);
  const { meditateNow } = ui;
  useEffect(() => {
    if (meditateNow && meditateNow == media.id && name) {
      togglePlay(media);
    }
  }, [meditateNow]);
  return (
    <div
      className="meditation-chat-card"
      style={{ marginBottom: isFromMeditation ? "20px" : "0px" }}
    >
      <div className="meditation-container">
        {isRecomShow ? (
          <div className="recommended-tag">Recommended</div>
        ) : null}
        <div
          onClick={() => {
            if (window.location.pathname === "/tab/home") {
              dispatch(
                logEventWithData({
                  action: "click_recommended_meditation_home",
                  data: { id: media.id, title: media.title },
                })
              );
            } else {
              dispatch(
                logEventWithData({
                  action: "click_meditation_recommended",
                  data: { id: media.id, title: media.title },
                })
              );
            }

            togglePlay(media);
          }}
          className="media-container"
        >
          <SecureImage
            classN="media-thumbnail"
            defaultImage={defaultImage}
            src={media.artwork}
          ></SecureImage>

          <div className="media-list-data">
            <div className="content-data">
              <div className="media-text-content">
                <div className="media__language">{media.language}</div>
                <div className="media-title  fw-medium">{media.title}</div>
                <div className="fs12">{media.description}</div>
              </div>
              <div>
                <img className="common-img" src={PlayImage} alt="icon" />
              </div>
            </div>
          </div>
        </div>
        {media.locked && (
          <div className="meditation-overlay" onClick={() => togglePlay(media)}>
            <img src={overlayLock} alt="icon" />
          </div>
        )}
      </div>
    </div>
  );
}
