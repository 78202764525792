import React, { useCallback, useRef } from "react";

import { useHistory } from "react-router";

import whiteArrow from "../../../assets/images/icon_arrow_back_white.png";
import whiteLock from "../../../assets/images/unlock_grey.png";
import cyanLock from "../../../assets/images/ic_lock_secondary_2.png";

import { IonContent, IonPage } from "@ionic/react";

function HPLevel({ handleDrawerClick, hp }) {
  const middleRef = useRef();
  const lastScroll = useRef(0);
  const lastOpacityContent = useRef(1);
  const lastOpacityHeader = useRef(0);
  const history = useHistory();

  const scale = useCallback((number, inMin, inMax, outMin, outMax) => {
    return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
  }, []);
  const handleScroll = (e) => {
    let opacityContent;
    let opacityHeader;
    let val = document.getElementsByClassName("health-plan-level-container")[0]
      .scrollTop;
    let val2 =
      window.innerHeight -
      document.getElementsByClassName("list-container")[0].offsetHeight;

    let myheight = document.getElementsByClassName(
      "health-plan-level-details"
    )[0].offsetHeight;
    if (val > lastScroll.current) {
      let t = this.scale(val, 0, myheight - val2, 0, 1);
      opacityContent = lastOpacityContent.current - t;
      document.getElementsByClassName(
        "health-plan-level-details"
      )[0].style.opacity = opacityContent;
      opacityHeader = lastOpacityHeader.current + t;

      //for header
      document.getElementsByClassName(
        "health-plan-level-header"
      )[0].style.opacity = opacityContent;

      document.getElementsByClassName(
        "my-health-plan-header-2"
      )[0].style.opacity = opacityHeader;
    } else {
      let g = scale(Math.min(val, myheight), 0, myheight, 0, 1);
      opacityContent = 1 - g;
      document.getElementsByClassName(
        "health-plan-level-details"
      )[0].style.opacity = opacityContent;
      opacityHeader = g;

      //for header
      document.getElementsByClassName(
        "health-plan-level-header"
      )[0].style.opacity = opacityContent;

      document.getElementsByClassName(
        "my-health-plan-header-2"
      )[0].style.opacity = opacityHeader;
    }
    lastScroll.current = val;
  };

  let lvl;
  let txt;

  hp.levels.forEach((element, index) => {
    if (element.locked === false) {
      lvl = index + 1;
      txt = element.description;
    }
  });
  let linkText, link;
  if (lvl == 1) {
    linkText = "Share Vitals";
    link = "/vitals";
  } else if (lvl === 2) {
    linkText = "Share Routine";
    link = "/submittedReporting";
  } else {
    linkText = "";
  }
  return (
    <div
      className="health-plan-level-container"
      onScroll={(e) => handleScroll(e)}
    >
      <div
        style={{
          flexDirection: "column",
          position: "absolute",
          top: "0",
          width: "100%",
          backgroundColor: "#7479FB",
          padding: "10px",
          zIndex: 100,
          opacity: "0",
          display: "flex",
        }}
        className="my-health-plan-header-2"
      >
        <img
          src={whiteArrow}
          style={{ width: "20px", height: "20px" }}
          onClick={() => handleDrawerClick()}
          alt="icon"
        />
        <span
          id="2"
          style={{
            color: "white",
            fontSize: "20px",
            fontWeight: "bold",
            marginTop: "15px",
          }}
        >
          Health Plan Level
        </span>
      </div>
      <div className="health-plan-level-header">
        <img src={whiteArrow} onClick={() => handleDrawerClick()} alt="icon" />
        <span> Health Plan Level</span>
      </div>
      <div className="health-plan-level-details" ref={middleRef}>
        <div className="health-plan-level-detail-content">
          {/* <span> Health Plan Level</span> */}
          <div className="level">Level</div>
          <div className="number">{lvl}</div>
          <div className="level-message">{txt}</div>
          {linkText.length > 0 && (
            <div
              className="next-level-button"
              onClick={() => {
                handleDrawerClick();
                history.push(`${link}`);
              }}
            >
              {linkText}
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          position: "sticky",
          top: "79px",
          overflowY: "scroll",
          minHeight: "15px",
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
          borderTopLeftRadius: "7px",
          borderTopRightRadius: "7px",
          width: "100%",
          maxWidth: "600px",
          margin: "auto",
        }}
      >
        <div className="minor-div"></div>
      </div>
      <div className="list-container">
        <div className="health-plan-level-list">
          {hp.levels.map((level, index) => {
            return (
              <div className="health-plan-level-list-container">
                <div className="health-plan-level-list-container-top">
                  <div className="health-plan-level-list-container-top-left">
                    <span>Level {index + 1}</span>
                    {level.premium && (
                      <div className="premium-tag">Premium</div>
                    )}
                  </div>
                  <img src={level.locked ? cyanLock : whiteLock} alt="icon" />
                </div>
                <div className="health-plan-level-list-container-bottom">
                  {level.description}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default HPLevel;
