import React, { useEffect, useState } from "react";

import "../../assets/css/doc.scss";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonGrid,
  IonRow,
  IonPage,
  IonButtons,
  IonSpinner,
} from "@ionic/react";
import DocumentTab from "./components/documentTab";

import DocumentTabPanel from "./components/documentTabPanel";
import noPDFIcon from "../../assets/images/no_pdf_doc.png";
import noImageIcon from "../../assets/images/no_image_doc.png";
import NoDocumentExists from "./components/noDocumentExists";
import BackButton from "../../components/common/BackButton";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { getAllDocuments } from "./documentSlice";
export default function DocumentViewContainer() {
  const { documents, documentsFetched } = useSelector(
    (store) => store.documents
  );
  const patient = useSelector((store) => store.login.user?.patientProfile);
  const paytmFlag = useSelector((store) => store.app.device.isPaytm);
  const [document, setDocument] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { type } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    let { state } = location;

    let id = null;
    if (state && state.isMember) {
      id = state.memberPatientId;
    }

    if (documentsFetched) {
      dispatch(getAllDocuments({ id: id ? id : patient?.id }));

      setDocument(documents.entities[type]);
      setLoading(false);
      setLoaded(true);
    } else {
      setLoading(true);
      dispatch(getAllDocuments({ id: id ? id : patient?.id }))
        .unwrap()
        .then((res) => {
          let currentDoc = res.data.filter((docs) => docs.type === type);
          setDocument(currentDoc);
          setLoading(false);
          setLoaded(res.data && res.data.length > 0);
        })
        .catch((err) => {
          setLoading(false);
          setLoaded(false);
        });
    }
  }, []);

  useEffect(() => {
    setDocument(documents.entities[type]);
  }, [documents, type]);

  const onClickTab = (selectedTab) => {
    setSelectedTab(selectedTab);
  };

  let isImages = document.images && document.images.length > 0;
  let isPdfs = document.pdfs && document.pdfs.length > 0;

  let isAudios = document.audios && document.audios.length > 0;
  let isVideos = document.videos && document.videos.length > 0;

  return (
    <IonPage className="document-view-container-wrapper">
      <IonContent>
        <IonHeader>
          <IonToolbar>
            {!paytmFlag && (
              <IonButtons slot="start">
                <div className="button-wrapper ">
                  <BackButton history={history}></BackButton>
                </div>
              </IonButtons>
            )}
            <div style={{ fontWeight: 600 }}>{document.title}</div>
          </IonToolbar>
        </IonHeader>
        {loading ? (
          <div className="loading-spinner-holder">
            <IonSpinner />
          </div>
        ) : loaded ? (
          <div>
            <IonGrid className="document-view-container">
              <IonRow>
                <DocumentTab
                  onClickTab={() => onClickTab(0)}
                  title="PDFs"
                  selected={selectedTab === 0}
                ></DocumentTab>

                <DocumentTab
                  onClickTab={() => onClickTab(1)}
                  title="Images"
                  selected={selectedTab === 1}
                ></DocumentTab>

                <DocumentTab
                  onClickTab={() => onClickTab(2)}
                  title="Audios"
                  selected={selectedTab === 2}
                ></DocumentTab>

                <DocumentTab
                  onClickTab={() => onClickTab(3)}
                  title="Videos"
                  selected={selectedTab === 3}
                ></DocumentTab>
              </IonRow>
            </IonGrid>
            <section className="scrollable-doc-viewer">
              {selectedTab === 1 &&
                (isImages ? (
                  <DocumentTabPanel
                    cols="4"
                    detail={false}
                    documents={document.images}
                  ></DocumentTabPanel>
                ) : (
                  <NoDocumentExists
                    text="you don't have any images yet"
                    imgIcon={noImageIcon}
                  ></NoDocumentExists>
                ))}
              {selectedTab === 0 &&
                (isPdfs ? (
                  <DocumentTabPanel
                    documents={document.pdfs}
                  ></DocumentTabPanel>
                ) : (
                  <NoDocumentExists
                    text="You don't have any PDFs yet"
                    imgIcon={noPDFIcon}
                  ></NoDocumentExists>
                ))}
              {selectedTab === 2 &&
                (isAudios ? (
                  <DocumentTabPanel
                    documents={document.audios}
                  ></DocumentTabPanel>
                ) : (
                  <NoDocumentExists
                    text="You don't have any Audio yet"
                    imgIcon={noPDFIcon}
                  ></NoDocumentExists>
                ))}
              {selectedTab === 3 &&
                (isVideos ? (
                  <DocumentTabPanel
                    documents={document.videos}
                  ></DocumentTabPanel>
                ) : (
                  <NoDocumentExists
                    text="You don't have any Videos yet"
                    imgIcon={noPDFIcon}
                  ></NoDocumentExists>
                ))}
            </section>
          </div>
        ) : (
          <NoDocumentExists
            text="No Documents Available"
            imgIcon={noPDFIcon}
          ></NoDocumentExists>
        )}
      </IonContent>
    </IonPage>
  );
}
