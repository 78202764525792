import ApiService from "./ApiService";
import AuthenticationService from "./AuthenticationService";
import APIConfig from "./APIConfig";
import axiosClient from "../app/axiosConfig";

class VitalService {
  constructor() {
    this.apiService = new ApiService();
    this.authenticationService = new AuthenticationService();
  }

  getVitalsByDate(date, onSuccess, onError) {
    const user = this.authenticationService.getUserProfile();
    let patientId = user.patientProfile.id;
    let url = APIConfig.testReading.get.format(patientId, date);

    this.apiService.get(
      url,
      (response) => {
        if (Array.isArray(response)) {
          onSuccess(response);
        } else {
          onError({ message: response });
        }
      },
      (error) => {
        onError(error);
      }
    );
  }

  submitVital(date, data, user) {
    let url = APIConfig.testReading.update.format(user.patientProfile.id, date);
    const rawData = {};
    rawData.vitals = data;
    rawData.updatedBy = user.id;
    return axiosClient.put(url, rawData);
  }

  getVitalParams(patientId) {
    let url = APIConfig.testReading.chart.getVitals.format(patientId);
    return axiosClient.get(url);
  }

  getVitalCharts(paramGroupId, forDate, patientId) {
    let url = APIConfig.testReading.chart.getChart.format(
      patientId,
      paramGroupId,
      forDate
    );
    return axiosClient.get(url);
  }
}

const vitalServices = new VitalService();
export default vitalServices;
