import React, { useEffect, useState } from "react";
import FeatureHeader from "./FeatureHeader";
import arrowRight from "../../../assets/icons/arrow-right.png";
import "./FeaturePage.scss";
import homeService from "../../../services/HomeService";
import { useHistory } from "react-router";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { logEventWithData } from "../../../app/eventSlice";

function YogaPage({ handleClose }) {
  const isPremium = useSelector((store) => store.tab.uiConfig.isPremium);
  const patientProfile = useSelector(
    (store) => store.login.user?.patientProfile
  );
  let [selectedOpt, setSelectedOpt] = useState("past");
  let [bannerActive, setBannerActive] = useState(true);
  let [upComing, setupComing] = useState([]);
  let [past, setPast] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    homeService
      .getUpcomingYoga(patientProfile?.id)
      .then((res) => {
        setupComing(res.data.yoga);
      })
      .catch((err) => {
        console.log("Error while fetching Upcoming Yoga Session");
      });

    homeService
      .getPastYoga(patientProfile?.id)
      .then((res) => {
        setPast(res.data.yoga);
      })
      .catch((err) => {
        console.log("error while fetching past yoga sessions");
      });
  }, []);

  const OptionBar = () => (
    <div className="date-btns">
      <button
        disabled={!isPremium}
        className={
          !isPremium
            ? "disabled-button date-btn"
            : selectedOpt == "upcoming"
            ? "date-btn selected-opt"
            : "date-btn"
        }
        onClick={() => {
          setSelectedOpt("upcoming");
        }}
      >
        Upcoming
      </button>
      <div
        className={selectedOpt == "past" ? "date-btn selected-opt" : "date-btn"}
        onClick={() => {
          setSelectedOpt("past");
        }}
      >
        Past
      </div>
    </div>
  );

  const CtaBanner = () => (
    <div
      className={bannerActive ? "yoga-banner" : "yoga-banner-inactive"}
      onClick={() => {
        handleClose();
        history.push("/premium");
      }}
    >
      <div>
        <div className="banner-head">Attend Live Session</div>
        <div className="banner-body"></div>
        Get access to unlimited Live Yoga Sessions with Zyla’s Healthcare
        Programs.
      </div>
      <div className="cta-icon">
        <img src={arrowRight} alt="icon" />
      </div>
    </div>
  );
  const YogaCard = ({ img, title, showLive, id, watch }) => (
    <div className="yoga-option-card">
      <img src={img} className="yoga-option-card-img" alt="bg-img" />
      <div className="yoga-title">{title}</div>
      {showLive && <div className="yoga-live">LIVE</div>}
      <div className="yoga-card-toolbar">
        <div
          className="yoga-card-toolbar-div"
          onClick={() => {
            if (selectedOpt === "upcoming") {
              dispatch(
                logEventWithData({
                  action: "yoga_session_join",
                  data: { id: id, title: title },
                })
              );
            } else {
              dispatch(
                logEventWithData({
                  action: "yoga_session_watch",
                  data: { id: id, title: title },
                })
              );
            }
            window.open(watch);
          }}
        >
          {selectedOpt === "upcoming" ? "JOIN" : "WATCH"}
        </div>
      </div>
    </div>
  );

  return (
    <div className="modal-page">
      <FeatureHeader handleClose={handleClose} title="Live Yoga Sessions" />
      <div className="page-body">
        <OptionBar />
        {!isPremium ? <CtaBanner /> : null}
        <div className="yoga-cards">
          {selectedOpt === "upcoming" ? (
            <>
              {upComing &&
                upComing.length > 0 &&
                upComing.map((yoga) => {
                  let startDate = new Date(yoga.start);
                  let endDate = new Date();

                  const now = moment(startDate);
                  const expiration = moment(endDate);
                  let diff = expiration.diff(now);
                  let shoudlShow = false;
                  if (diff >= 0 && diff <= 60 * 60 * 1000) {
                    shoudlShow = true;
                  }

                  return (
                    <YogaCard
                      img={yoga.cover}
                      watch={yoga.join_url}
                      title={yoga.title}
                      showLive={shoudlShow}
                      id={yoga.id}
                    />
                  );
                })}
            </>
          ) : (
            <>
              {past &&
                past.length > 0 &&
                past.map((yoga) => {
                  return (
                    <YogaCard
                      img={yoga.cover}
                      watch={yoga.youtube_url}
                      key={yoga.id}
                      title={yoga.title}
                      id={yoga.id}
                    />
                  );
                })}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default YogaPage;
