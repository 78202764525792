import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import TimeMessage from "./time";
import CommonCardMessage from "./commonCard";
import { useDispatch, useSelector } from "react-redux";
import {
  cacheVitalsFromResponse,
  getVitalByDate,
} from "../../../vitals/vitalSlice";
export default function VitalCardMessage({ message, type, className }) {
  const [showOptions, setShowOptions] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [vitalDate, setVitalDate] = useState(null);
  const [vitalsDateInMs, setVitalsDateInMs] = useState("");
  const containerRef = useRef();
  const dispatch = useDispatch();

  const vitalStore = useSelector((state) => state.vitals);
  const loginStore = useSelector((state) => state.login);
  const { user } = loginStore;
  const patientId = user.patientProfile.id;
  const { vitals } = vitalStore;

  useEffect(() => {
    const content = message.content.message;

    if (content && content.metadata && content.metadata.vitalsDate) {
      const vd = content.metadata.vitalsDate;
      let requestDate = vd;
      if (requestDate.length === 10) {
        requestDate = moment(requestDate, "DD-MM-YYYY").valueOf();
      } else {
        requestDate = moment(requestDate).toDate().valueOf();
      }

      let formattedDate = moment(requestDate).format("Do MMMM");
      checkHeight();
      setVitalsDateInMs(requestDate);
      setVitalDate(moment(requestDate).format("Do MMMM"));

      if (!vitals[formattedDate]) {
        dispatch(
          getVitalByDate({
            user: user,
            date: moment(requestDate).format("Do MMMM"),
          })
        )
          .unwrap()
          .then((res) => {
            const data = res.data;
            dispatch(
              cacheVitalsFromResponse({ date: formattedDate, data: data })
            );
          })
          .catch((err) => {});
      }
    }
  }, [message, patientId, dispatch]);

  const checkHeight = () => {
    if (containerRef.current) {
      let cardDimensions = containerRef.current.getBoundingClientRect();
      let cardHeight = cardDimensions.height;

      if (cardHeight > 200) {
        setIsExpanded(false);
        setShowOptions(true);
      }
    }
  };

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  //Message type 8

  return (
    <div style={{ minHeight: "200px" }}>
      <div className="content-card">
        <CommonCardMessage title={"Vitals"} time={vitalsDateInMs} />
        <div
          className={
            !isExpanded && showOptions
              ? "card-border-vital-fix"
              : "card-border-radius-fix"
          }
        >
          <div
            className={`content-of-card ${
              !isExpanded && showOptions && "partial-view"
            }`}
            ref={containerRef}
            style={{ minHeight: "130px" }}
          >
            {vitals &&
              vitals[vitalDate] &&
              vitals[vitalDate].map((vital, index) => {
                let isBlank = true;
                vital.params.forEach((v) => {
                  if (v.value) {
                    isBlank = false;
                  }
                });

                if (vital.params.length > 0 && !isBlank) {
                  return (
                    <div
                      key={index}
                      className="text-to-left gap-below-sections"
                    >
                      <div className="vitals-head-name">
                        {`${vital.name} (${vital.unit})`}
                      </div>
                      {vital.params.map((vitalParam, innerKey) => {
                        if (vitalParam.value) {
                          return (
                            <div key={innerKey} className="vitals-item">
                              {`${vitalParam.name} -`} {`${vitalParam.value}`}
                            </div>
                          );
                        } else {
                          return <></>;
                        }
                      })}
                    </div>
                  );
                } else {
                  return <></>;
                }
              })}
          </div>
          {showOptions ? (
            <div className="show-options" onClick={toggleExpanded}>
              {isExpanded ? "Show Less..." : "Show More..."}
            </div>
          ) : null}
        </div>
        <div className="start-button">
          <Link
            to="/vitalsChart"
            style={{
              color: "white",
              textDecoration: "none",
              fontWeight: 600,
            }}
          >
            View Vital Charts
          </Link>
        </div>
      </div>

      <TimeMessage message={message} />
    </div>
  );
}
