import React from "react";
import documentService from "../../../services/DocumentService";
import PrepareUploadService from "../../../services/PrepareUploadService";
function UploadDocument(props, ref) {
  const { type, paytm, multipleUpload, onUploadStart, onUploadDone, pId } =
    props;
  const paytmGallery = () => {
    PrepareUploadService.paytmGallery((fileReq) => {
      documentService
        .uploadDocument(
          fileReq.file,
          fileReq.name,
          fileReq.document || {},
          null,
          pId
        )
        .then((response) => {
          const data = response.data;
          onUploadDone({ status: "S", result: data });
        })
        .catch((error) => {
          onUploadDone({ status: "F", result: error });
        });
    });
  };
  const selectMultipleUpload = (e) => {
    onUploadStart(e);
    return;
  };

  const onSelectFileToUpload = (e) => {
    const file = e.target.files[0];
    let typeArr = type.split(",").includes(file.type);

    // if (typeArr) {
    onUploadStart(e);
    let fileReq = PrepareUploadService.buildDocumentFromFile(file);
    if (fileReq === null) {
      return;
    }

    if (file.size <= 20000000) {
      documentService
        .uploadDocument(file, file.name, fileReq, undefined, pId)
        .then((response) => {
          const data = response.data;
          if (onUploadDone) {
            onUploadDone({ status: "S", result: data });
          }
        })
        .catch((error) => {
          if (onUploadDone) {
            onUploadDone({ status: "F", result: error });
          }
        });
    } else {
      onUploadDone({
        status: "F",
        result: "File size should be less than 20MB",
      });
    }
    // } else {
    //   onUploadDone({
    //     status: "F",
    //     result: `Please upload file of type ${type}`,
    //   });
    // }
  };

  //image/* , audio/mp3 , video/mp4 , application/pdf
  return (
    <input
      ref={ref}
      hidden
      accept={type}
      onChange={(e) => {
        paytm
          ? paytmGallery()
          : multipleUpload
          ? selectMultipleUpload(e)
          : onSelectFileToUpload(e);
      }}
      type="file"
      multiple={multipleUpload}
    ></input>
  );
}

export default React.forwardRef(UploadDocument);
