import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeAddress } from "../addressSlice";
export default function RemoveDialog({ handleOpenRemoveDialog }) {
  const addressStore = useSelector((store) => store.address);
  const dispatch = useDispatch();
  const { memberPatientId, addressToRemove } = addressStore;
  return (
    <div className="address-remove-dialog">
      <div className="address-remove-dialog-content">
        <p className="address-remove-dialog-top">Remove address?</p>
        <p className="address-remove-dialog-mid">
          Selected address will be completely removed from the address book
        </p>
        <div className="address-remove-dialog-bottom">
          <button
            className="address-remove-dialog-bottom-cancel"
            onClick={() => {
              handleOpenRemoveDialog(false);
            }}
          >
            Cancel
          </button>
          <button
            className="address-remove-dialog-bottom-remove"
            onClick={() =>
              dispatch(
                removeAddress({
                  memberPatientId: memberPatientId,
                  id: addressToRemove.id,
                })
              )
            }
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );
}
