import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import "./tracker_chart.scss";
import back_arrow from "../../../assets/icons/arrow-back-black.svg";
import next_button from "../../../assets/images/ic_next_button.png";
import previous_button from "../../../assets/images/ic_previous_button.png";

import { IonPage, IonContent, IonSpinner } from "@ionic/react";

import TrackerTabs from "./TrackerTabs";
import LineGraph from "./TrackerLineChart";
import moment from "moment";
import { useSelector } from "react-redux";
import ErrorComponent from "../../../components/common/ErrorComponent";
import TrackerChartGraph from "../components/trackerChartGraph";

const createAndReturnParamGroups = () => {
  let trackerParamGroups = [{ id: 1, name: "Steps" }];
  if (window.Capacitor.platform === "ios") {
    trackerParamGroups.push({ id: 2, name: "Sleep" });
  }
  return trackerParamGroups;
};

function TrackerChart() {
  const paytmFlag = useSelector((store) => store.app.device.isPaytm);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);
  const [showChart, setShowChart] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [trackerParamGroups, setTrackerParamGroups] = useState(() =>
    createAndReturnParamGroups()
  );
  const history = useHistory();

  const handleBackClick = () => {
    history.goBack();
  };

  return (
    <IonPage>
      <IonContent>
        {loading && (
          <div className="loading-wrapper">
            <IonSpinner />
          </div>
        )}
        <div style={{ height: "100%" }} className="tracker-container">
          <ErrorComponent
            show={error}
            msg={errorMsg}
            errorClose={() => {
              setError(false);
              setErrorMsg("");
            }}
          />
          <div className="tracker_chart_blue_box ">
            <div
              style={{
                display: "flex",
                "align-items": "center",
              }}
            >
              {!paytmFlag && (
                <img
                  src={back_arrow}
                  onClick={() => handleBackClick()}
                  alt="icon"
                  className="back_arrow"
                ></img>
              )}
              <span className="vital-chart-span"> Lifestyle progress</span>
            </div>

            {/* <TrackerTabs
              paramGroupId={paramGroupId}
              onTabChange={(paramGroupId) => onTabChange(paramGroupId)}
              paramGroups={trackerParamGroups}
            ></TrackerTabs> */}
          </div>

          <TrackerChartGraph />
        </div>
      </IonContent>
    </IonPage>
  );
}

export default TrackerChart;
