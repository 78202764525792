import React, { useEffect } from "react";

import "./testimonial.scss";
import StaticTestimonial from "./staticTestimonial";
import { IonPage, useIonViewDidEnter } from "@ionic/react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import userService from "../../services/UserService";
import { handleBackButtonFunc } from "../../utils/helperMethods";

export default function Testimonials() {
  const patientProfile = useSelector(
    (store) => store.login.user?.patientProfile
  );
  const history = useHistory();

  const getUserPaStatus = (url) => {
    if (patientProfile && patientProfile.id) {
      userService
        .getConsentStatus(patientProfile.id)
        .then((res) => {
          if (res.data) {
            userService
              .getLatestSettings(patientProfile.id)
              .then((res) => {
                if (res.data.isChatVisible) {
                  history.replace("/tab/home");
                } else {
                  history.replace("/tab/chat");
                }
              })
              .catch((err) => {
                history.replace("/tab/chat");
              });
          } else {
            history.replace("/terms-condition");
          }
        })
        .catch((err) => {
          userService
            .getLatestSettings(patientProfile.id)
            .then((res) => {
              if (res.data.isChatVisible) {
                history.replace("/tab/home");
              } else {
                history.replace("/tab/chat");
              }
            })
            .catch((err) => {
              history.replace("/tab/chat");
            });
        });
    } else {
      history.replace("/tab/chat");
    }
  };

  useIonViewDidEnter(() => {
    if (window.Capacitor.platform === "android") {
      document.removeEventListener("ionBackButton", handleBackButtonFunc);
    }
    setTimeout(() => {
      getUserPaStatus();
    }, 2000);
  }, []);

  return (
    <IonPage>
      <StaticTestimonial></StaticTestimonial>
    </IonPage>
  );
}
