import { ChevronDoubleRightIcon } from "@heroicons/react/24/solid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setStateFromPayload } from "../navigoSlice";
import { logEvent, logEventWithData } from "../../../app/eventSlice";

const GeneralNavigoCard = ({ handleGotoAddress, service }) => {
  const navigoStore = useSelector((store) => store.navigo);
  const {
    familyMemberSelected,
    isForFamily,
    currentServices,
    familyMemberSelectedData,
    callHandleAvailNow,
  } = navigoStore.ui;
  const dispatch = useDispatch();
  useEffect(() => {
    if (callHandleAvailNow) {
      handleAvailNowContinue(null);
    }
  }, [callHandleAvailNow]);
  const handleGeneralKnowMore = () => {
    dispatch(
      setStateFromPayload({
        currentServices: { ...service },
        currScreen: 4,
      })
    );
    dispatch(
      logEventWithData({
        action: "navigo_view_service",
        data: {
          serviceID: service?.ID,
          serviceName: service?.name,
          is_app_event: true,
        },
      })
    );
  };
  const handleAvailNowContinue = (service = null) => {
    if (!service) {
      service = { ...currentServices };
    }

    if (isForFamily) {
      if (service.addressRequired) {
        dispatch(
          setStateFromPayload({
            currentServices: { ...service },
            callHandleAvailNow: false,
          })
        );
        handleGotoAddress(familyMemberSelected, familyMemberSelectedData);
      } else {
        dispatch(
          setStateFromPayload({
            currentServices: { ...service },
            currScreen: 3,
            callHandleAvailNow: false,
          })
        );
      }
    } else {
      let fm = familyMemberSelected;
      if (familyMemberSelected !== -2) {
        if (familyMemberSelected === -1 && service.availedForObj[0]) {
          fm = -2;
        } else if (service.availedForObj[familyMemberSelected]) {
          fm = -2;
        }
      }
      dispatch(
        setStateFromPayload({
          currentServices: { ...service },
          currScreen: 2,
          familyMemberSelected: fm,
          callHandleAvailNow: false,
        })
      );
    }
  };

  const handleAvailNow = (service) => {
    dispatch(
      logEvent({
        action: `click book navigo appointment_${service.type}`,
      }))
    if (service.discountedPrice !== 0 && service.discountedPrice !== null) {
      dispatch(
        setStateFromPayload({
          openDrawer: true,
          drawerType: 5,
          currentServices: { ...service },
        })
      );
    } else {
      handleAvailNowContinue(service);
    }
  };

  if (!service) {
    return "";
  }

  const {
    image,
    name,
    description,
    discountedPrice,
    price,
    serviceTypeId,
    availed,
    availedForFamily,
    familyMemberCount,
  } = service;

  return (
    <div className="navigo-general-card-container">
      <div className="navigo-general-card-container-top">
        <div className="navigo-general-card-container-top-left">
          <img src={image} alt="card-img" />
        </div>
        <div className="navigo-general-card-container-top-right">
          <div className="navigo-general-card-container-top-right-top">
            <p className="navigo-general-card-container-top-right-top-title">
              {name}
            </p>
            <p className="navigo-general-card-container-top-right-top-subtitle clamp-down-para-class">
              {description}
            </p>
          </div>
          <div className="navigo-general-card-container-top-right-bottom">
            {discountedPrice !== null && discountedPrice != 0 ? (
              <div className="navigo-general-card-container-top-right-bottom-price">
                <span className="fw-semibold">
                  {serviceTypeId === 1 ? "Price" : "Fee: Rs "}
                </span>
                <span className="font-strikethrough">{price}</span>&nbsp;
                <span className="fw-semibold">{discountedPrice}</span>
              </div>
            ) : (
              <p className="navigo-general-card-container-top-right-bottom-price">
                Included in your plan
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="navigo-general-card-container-bottom">
        <button
          className="navigo-general-card-container-bottom-know-more"
          onClick={handleGeneralKnowMore}
        >
          Know more <ChevronDoubleRightIcon />
        </button>

        {!service.availed && (
          <button
            className="navigo-general-card-container-bottom-book-now"
            onClick={() => {
              handleAvailNow(service);
            }}
          >
            Book now
          </button>
        )}
        {availed && (
          <div className="navigo-booking-container-section-2-ele-bottom-availed-container">
            <button className="navigo-booking-container-section-2-ele-bottom-availed">
              <span>Availed</span>
            </button>
            {availedForFamily && familyMemberSelected !== -2 && (
              <button
                className="navigo-booking-container-section-2-ele-bottom-availedForFamily"
                onClick={() => {
                  dispatch(
                    setStateFromPayload({
                      openDrawer: true,
                      drawerType: 1,
                      currentFamilyMemberCount: familyMemberCount,
                    })
                  );
                }}
              >
                !
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default GeneralNavigoCard;
