import React from "react";
import "./Base.scss";
import { Provider } from "react-redux";

// "https://cdn.branch.io/branch-2.47.1.min.js
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import ErrorPage from "./ErrorPage";
import firebase from "@firebase/app";
import { IonReactRouter } from "@ionic/react-router";

import { Device } from "@capacitor/device";
import Routes from "./app/route";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import AppWrapper from "./app/index";
import { persistor, store } from "./app/store";
import { IonApp, IonSpinner } from "@ionic/react";
import { PersistGate } from "redux-persist/integration/react";
import {
  AppTrackingTransparency,
  AppTrackingStatusResponse,
} from 'capacitor-plugin-app-tracking-transparency';

export default class BaseComponent extends React.Component {
  constructor() {
    super();
    // this.eventLoggerService = new EventLoggerService();
    // this.eventLoggerService.init();
    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APPID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
      });
    } else {
      firebase.app();
    }

    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.5,
    });
    // load Branch
    // (function (b, r, a, n, c, h, _, s, d, k) {
    //   if (!b[n] || !b[n]._q) {
    //     for (; s < _.length; ) c(h, _[s++]);
    //     d = r.createElement(a);
    //     d.async = 1;
    //     d.src = "https://cdn.branch.io/branch-latest.min.js";
    //     k = r.getElementsByTagName(a)[0];
    //     k.parentNode.insertBefore(d, k);
    //     b[n] = h;
    //   }
    // })(
    //   window,
    //   document,
    //   "script",
    //   "branch",
    //   function (b, r) {
    //     b[r] = function () {
    //       b._q.push([r, arguments]);
    //     };
    //   },
    //   { _q: [], _v: 1 },
    //   "addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(
    //     " "
    //   ),
    //   0
    // );
    // branch.init("key_live_peTMCMl8jOOe8h0DafSEZmgpEtchAfHd");

    // window.fbAsyncInit = function () {
    //   window.FB.init({
    //     appId: "536209287266751",
    //     autoLogAppEvents: true,
    //     xfbml: true,
    //     version: "v6.0",
    //   });
    // };

    // (function (d, s, id) {
    //   var js,
    //     fjs = d.getElementsByTagName(s)[0];
    //   if (d.getElementById(id)) {
    //     return;
    //   }
    //   js = d.createElement(s);
    //   js.id = id;
    //   js.src = "https://connect.facebook.net/en_US/sdk.js";
    //   fjs.parentNode.insertBefore(js, fjs);
    // })(document, "script", "facebook-jssdk");

   
    Device.getInfo().then((info) => {
      if (info.platform === "ios") {
        AppTrackingTransparency.getStatus().then((res) => {
          if (res.status !== "authorized") {
            AppTrackingTransparency.requestPermission().then((res) => {});
          }
        });
      } else {
        // let body = document.getElementsByTagName("body")[0];
        // body.style.left = "50%";
        // body.style.transform = "translate(-50%)";
        // body.style.maxWidth = "500px";
      }
    });
  }

  render() {
    return (
      <IonApp>
        <IonReactRouter>
          <Provider store={store}>
            <PersistGate loading={<IonSpinner />} persistor={persistor}>
              <AppWrapper>
                <ErrorPage>
                  <Routes />
                </ErrorPage>
              </AppWrapper>
            </PersistGate>
          </Provider>
        </IonReactRouter>
      </IonApp>
    );
  }
}
