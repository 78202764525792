import React from "react";

export default class PDFViewer extends React.Component {
  constructor(props) {
    super(props);
    this.viewerRef = React.createRef();
  }

  render() {
    const { src } = this.props;
    return (
      <div
        ref={this.viewerRef}
        id="viewer"
        style={{ width: "100%", height: "100%" }}
      >
        <iframe
          src={`/pdf/web/viewer.html?file=${src}`}
          width="100%"
          height="100%"
        ></iframe>
      </div>
    );
  }
}
