import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosClient from "../../app/axiosConfig";
import APIConfig from "../../services/APIConfig";
import homeService from "../../services/HomeService";

const initialState = {
  noSleepDataAlert: false,
  trackingEnabled: false,
  bannerImages: [],
  ui: {
    hasActiveWallet: false,
    walletLoaded: false,
    wallet: undefined,
    testimonials: [],
    videos: [],
    blogs: [],
    featureTiles: [],
    carousel: [],

    info: false,
    recipe: null,
  },

  plans: {},
};

export const getHomePageData = createAsyncThunk(
  "home/getHomePageData",
  async (id, { rejectWithValue }) => {
    try {
      const url = APIConfig.freemium.homepage.format(id);
      const response = axios.get(url, { headers: { origin: "*" } });
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const homePlanData = createAsyncThunk(
  "home/homePlanData",
  async (clientId, { rejectWithValue }) => {
    try {
      const response = await homeService.getHomePagePlanData(clientId);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchBannerData = createAsyncThunk(
  "home/fetchBannerData",
  async (id, { rejectWithValue }) => {
    try {
      const url = APIConfig.user.bannerImages.format(id);
      const response = axios.get(url, { headers: { origin: "*" } });
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const homePageSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    showNoDataAlert: (state, action) => {
      state.noSleepDataAlert = action.payload;
    },
    toggleTrackingEnabled: (state, action) => {
      state.trackingEnabled = action.payload;
    },
  },
  extraReducers: {
    [getHomePageData.fulfilled]: (state, action) => {
      const data = action.payload.data;
      return {
        ...state,
        ui: {
          ...data.content,
          info: true,
        },
      };
    },
    [homePlanData.pending]: (state, action) => {},
    [homePlanData.fulfilled]: (state, action) => {
      state.plans = action.payload.data;
    },
    [homePlanData.rejected]: (state, action) => {},

    [fetchBannerData.fulfilled]: (state, action) => {
      return {
        ...state,
        bannerImages: action.payload.data.mli_offer,
      };
    },
  },
});

export const { showNoDataAlert, toggleTrackingEnabled } = homePageSlice.actions;

export default homePageSlice.reducer;
