import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import moment from "moment/moment";
import axiosClient from "../../app/axiosConfig";
import APIConfig from "../../services/APIConfig";

const PAGE_SIZE = 30;
const INITIAL_PAGE_SIZE = 30;
const adapter = createEntityAdapter({
  sortComparer: (a, b) => a.id.localeCompare(b.id),
});
const pendingAdapter = createEntityAdapter();
const initialState = {
  user: undefined,
  messages: adapter.getInitialState(),
  metadata: undefined,
  prevMetadata: undefined,
  pendingMessages: pendingAdapter.getInitialState(),
  pageSize: 30,
  pageNo: 1,
  feedback: {
    submitted: false,
    nextReminderTime: null,
  },
  dynCards: {},
  callNotes: {},
  reviewNotes: {},
  mediCards: {},
  isStartTourShown: false,
  ui: {
    disableSend: false,
    loading: false,
    error: undefined,
    showTour: true,
    errorRating: false,
    hasPreviousMessages: false,
    showLoadMore: false,
    loadMoreLoading: false,
    loadMoreLoaded: false,
    scrollToMsg: "",
    action: "",
    enableAutoScroll: true,
  },
};

export const getMessages = createAsyncThunk(
  "chat/getMessages",
  async (data, { rejectWithValue }) => {
    try {
      const url = `${APIConfig.chat.message}/${data.roomId}?size=${INITIAL_PAGE_SIZE}`;
      const response = axiosClient.get(url);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getNextMessages = createAsyncThunk(
  "chat/getNextMessages",
  async (data, { rejectWithValue }) => {
    try {
      const url = `${APIConfig.chat.message}/${data.roomId}?minId=${data.maxId}&size=${PAGE_SIZE}`;
      const response = axiosClient.get(url);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getPreviousMessages = createAsyncThunk(
  "chat/getPreviousMessages",
  async (data, { getState, rejectWithValue }) => {
    try {
      const url = `${APIConfig.chat.message}/${data.roomId}?maxId=${data.maxId}&size=${PAGE_SIZE}`;
      const response = axiosClient.get(url);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const sendMessage = createAsyncThunk(
  "chat/sendMessage",
  async (data, { getState, rejectWithValue }) => {
    try {
      const { tab } = getState();
      let userId = tab.chat.externalUserIdSt;
      let roomId = tab.chat.chatRoom;

      let content = {
        content: data.content,
        sendAs: userId,
      };
      let url = APIConfig.chat.putMessage.format(roomId);
      const response = axiosClient.put(url, content);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const sendFeedback = createAsyncThunk(
  "chat/sendFeedback",
  async (data, { rejectWithValue }) => {
    try {
      let url = APIConfig.user.feedback;
      const response = axiosClient.post(url, data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    toggleTour: (state, action) => {
      state.ui.showTour = !state.ui.showTour;
    },
    toggleDisabledSend: (state, action) => {
      state.ui.disableSend = action.payload;
    },
    setNextFeedbackTime: (state, action) => {
      state.feedback = {
        ...state.feedback,
        submitted: action.payload.submitted,
        nextReminderTime: action.payload.time,
      };
    },
    toggleEnableAutoScroll: (state, action) => {
      state.ui.enableAutoScroll = action.payload;
    },
    toggleAction: (state, action) => {
      state.ui.action = "next";
    },
    updateCardInfo: (state, action) => {
      return {
        ...state,
        [action.payload.cardName]: {
          ...state[action.payload.cardName],
          [action.payload.data.id]: { ...action.payload.data },
        },
      };
    },
    updateStartTourShown: (state, actions) => {
      return {
        ...state,
        isStartTourShown: true,
      };
    },
  },
  extraReducers: {
    [sendMessage.fulfilled]: (state, action) => {
      const currentUser = action.meta.arg.userId;
      const newMsg = action.payload.data;
      newMsg.mine = currentUser === newMsg.sender;
      state.ui.action = "send";
      pendingAdapter.removeOne(
        state.pendingMessages,
        action.meta.arg.content.message.id
      );
      // pendingAdapter.removeAll(state.pendingMessages);
      adapter.addOne(state.messages, newMsg);
      state.metadata.maxId = newMsg.id;
      state.ui.disableSend = false;
      state.ui.enableAutoScroll = true;
    },
    [sendMessage.pending]: (state, action) => {
      //const currentUser = action.meta.arg.userId;

      if (!action.meta.arg.isResend) {
        const newPendingMessage = {
          content: { ...action.meta.arg.content },
          mine: true,
          id: action.meta.arg.content.message.id,
          isTransactionPending: true,
          createdAt: new Date().toISOString(),
        };
        // console.log("new pendingmmessga eis ", newPendingMessage);
        pendingAdapter.addOne(state.pendingMessages, newPendingMessage);
      }
    },
    [sendMessage.failed]: (state, action) => {
      //const currentUser = action.meta.arg.userId;

      state.ui.disableSend = false;
    },
    [getNextMessages.fulfilled]: (state, action) => {
      const currentUser = action.meta.arg.userId;
      const msgs = action.payload.data.messages;

      if (msgs && msgs.length > 0) {
        state.ui.action = "next";
        let messages = postProcess(msgs, currentUser);
        adapter.addMany(state.messages, messages);
        state.metadata = action.payload.data.metadata;
      }
    },
    [getPreviousMessages.fulfilled]: (state, action) => {
      const currentUser = action.meta.arg.userId;
      const msgs = action.payload.data.messages;
      state.ui.loadMoreLoading = false;
      state.ui.action = "previous";
      if (msgs) {
        state.ui.scrollToMsg = state.messages.ids[0];
        let messages = postProcess(msgs, currentUser);
        adapter.addMany(state.messages, messages);
        state.prevMetadata = action.payload.data.metadata;

        if (PAGE_SIZE === msgs.length) {
          // returns records equals to page size.. check if old messages are there
          state.ui.hasPreviousMessages = true;
        } else {
          state.ui.hasPreviousMessages = false;
        }
      }
    },
    [getPreviousMessages.pending]: (state, action) => {
      state.ui.loadMoreLoading = true;
      state.ui.loadMoreLoaded = false;
    },
    [getPreviousMessages.rejected]: (state, action) => {
      state.ui.loadMoreLoading = false;
      state.ui.loadMoreLoaded = true;
    },
    [getMessages.fulfilled]: (state, action) => {
      const currentUser = action.meta.arg.userId;
      const msgs = action.payload.data.messages;
      state.ui.action = "initial";
      if (msgs) {
        let messages = postProcess(msgs, currentUser);
        adapter.setAll(state.messages, messages);
        state.metadata = action.payload.data.metadata;
        state.prevMetadata = action.payload.data.metadata;
        if (INITIAL_PAGE_SIZE === msgs.length) {
          // returns records equals to page size.. check if old messages are there
          state.ui.hasPreviousMessages = true;
        } else {
          state.ui.hasPreviousMessages = false;
        }
      }
    },
    [sendFeedback.fulfilled]: (state, action) => {
      state.ui = {
        ...state.ui,
        errorRating: false,
      };
      state.feedback = {
        ...state.feedback,
        submitted: true,
      };
    },
    [sendFeedback.rejected]: (state, action) => {
      state.ui = {
        ...state.ui,
        errorRating: true,
      };
    },
  },
});
const postProcess = (msgs, currentUser) => {
  let messages = [];
  if (msgs) {
    let firstMsgTime = null;
    msgs.forEach((msg) => {
      msg.mine = currentUser === msg.sender;
      if (firstMsgTime == null) {
        firstMsgTime = parseInt(moment(msg.createdAt).format("YYYYMMDD"));
      } else {
        const currentTime = parseInt(moment(msg.createdAt).format("YYYYMMDD"));
        if (currentTime !== firstMsgTime) {
          let lastNumber = parseInt(msg.id.substring(msg.id.length - 3), 10);

          lastNumber = lastNumber - 1;

          const ob = {
            id: `${msg.id.substring(0, msg.id.length - 3)}${lastNumber}`,
            content: {
              message: {
                id: `${new Date().getTime() + Math.random()}`,
                type: -1,
                text: moment(currentTime, "YYYYMMDD").format(
                  "dddd | Do MMMM, YYYY"
                ),
              },
            },
          };
          messages = messages.concat(ob);
          firstMsgTime = currentTime;
        }
      }
      messages = messages.concat(msg);
    });
  }
  return messages;
};

export const {
  toggleTour,
  setNextFeedbackTime,
  toggleDisabledSend,
  toggleEnableAutoScroll,
  updateCardInfo,
  toggleAction,
  updateStartTourShown,
} = chatSlice.actions;
export default chatSlice.reducer;
