import React from "react";
import { useHistory, withRouter } from "react-router-dom";
import sleepIcon from "../../../assets/icons/sleep-icon.png";
import OrangeChevron from "../../../assets/icons/orange-circle-right-chevron.png";
import { useDispatch } from "react-redux";
const messages = {
  tommorrow: "GOAL TO ACHIEVE",
  normal: "TIME IN BED",
};
function Sleep({
  trackingEnabled,
  day,
  sleep,
  initializeStepAndSleep,
  sleepTargetMin,
  sleepTargetMax,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  let emptySleep = "";
  if (trackingEnabled === true) {
    if (day === 1) {
      emptySleep = "7hr-9hr";
    } else {
      let hr = sleep / 60;
      let min = sleep % 60;
      emptySleep = `${Math.floor(hr)}hr ${min}min`;
    }
  } else {
    emptySleep = "--hr --min";
  }
  let message = messages.normal;
  if (day === 1) {
    message = messages.tommorrow;
    emptySleep = `${Math.floor(sleepTargetMin / 60)}hr-${Math.floor(
      sleepTargetMax / 60
    )}hr`;
  }

  return (
    <div className="sleep-tracker-wrapper">
      <div
        className="sleep-tracker-detail"
        onClick={() => {
          if (trackingEnabled === true && day !== 1) {
            history.push("/trackingChart?2");
          }
        }}
      >
        <div className="sleep">
          <div className="icon__wrapper">
            <img src={sleepIcon} alt="icon" />
            <span>Sleep</span>
          </div>
        </div>
        <div className="reading-div">
          <div className="reading-div-left">
            <span className="message">{message}</span>
            <div className="daily_target_value_wrapper">
              <span className="sleep-reading">{emptySleep}</span>
              {day !== 1 && sleep !== 0 && trackingEnabled && (
                <>
                  {sleep >= sleepTargetMin && sleep <= sleepTargetMax && (
                    <div className="daily_target_value_tag">NORMAL</div>
                  )}
                  {(sleep < sleepTargetMin || sleep > sleepTargetMax) && (
                    <div className="daily_target_value_tag" id="out-of-range">
                      {`${sleep < sleepTargetMin ? "LOW" : "OVERSLEPT"}`}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {trackingEnabled === true ? (
            <div className="progress-bar">
              {day !== 1 ? <img src={OrangeChevron} alt="icon" /> : null}
            </div>
          ) : trackingEnabled === false && day !== 1 ? (
            <div
              className="start-tracking"
              onClick={() =>
                initializeStepAndSleep({
                  lastSyncedSteps: "1970-01-01T00:00:00Z",
                  lastSyncedSleep: "1970-01-01T00:00:00Z",
                })
              }
            >
              <p>Start Tracking</p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default withRouter(Sleep);
