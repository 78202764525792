import {
  ChevronDoubleDownIcon,
  PlusIcon,
  MinusIcon,
} from "@heroicons/react/24/solid";

import { CircularProgress } from "@mui/material";

import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import moneyIcon from "../../../assets/icons/money-icon.svg";
import backImg from "../../../assets/images/icon_arrow_back.png";

import {
  getWalletTransactionByRange,
  handleScreenChanges,
} from "../walletSlice";

const walletFilterArr = [
  { id: 1, name: "Last month", value: "month" },
  { id: 2, name: "Last 6 month", value: "half_year" },
  { id: 3, name: "Last 12 month", value: "year" },
  // {id:4,name:"All",value:"all"}
];
function WalletTransactions(props) {
  const [filterName, setFilterName] = useState("Last month");
  const [showDialog, setShowDialog] = useState(false);
  const walletStore = useSelector((store) => store.wallet);

  const dispatch = useDispatch();
  const { walletId, rangeTransactions, loading, walletInfo } = walletStore;

  useEffect(() => {
    dispatch(
      getWalletTransactionByRange({ id: walletId, count: -1, range: "month" })
    );
  }, [walletId]);

  return (
    <div className="zyla-wallet-transaction-container">
      <div className="zyla-wallet-transaction-header">
        <img
          src={backImg}
          onClick={() => {
            dispatch(handleScreenChanges(1));
          }}
          alt="icon"
        />
      </div>
      <div className="zyla-wallet-transaction-section-1">
        <img src={moneyIcon} alt="icon" />
        <p className="zyla-wallet-transaction-section-1-amount">
          {walletInfo?.amount}
        </p>
        <p className="zyla-wallet-transaction-section-1-desc">
          Total cash balance
        </p>
      </div>
      <div className="zyla-wallet-transaction-section-2">
        <p className="font-12px-zy-wallet font-weight-700-zy-wallet">
          Cash ledger
        </p>
        <div
          className="zyla-wallet-transaction-filter-select-wrapper"
          onClick={() => {
            setShowDialog(true);
          }}
        >
          <span className="zyla-wallet-transaction-filter-select-button">
            {filterName}
          </span>
          <ChevronDoubleDownIcon />
        </div>
      </div>
      <div className="zyla-wallet-transaction-section-3">
        {!loading &&
          rangeTransactions.map((trs) => {
            return (
              <div className="zyla-wallet-transaction-section-3-ele">
                <div className="zyla-wallet-transaction-section-3-ele-left">
                  <div className="zyla-wallet-transaction-section-3-ele-left-left">
                    {trs.transactionType === 2 ? (
                      <MinusIcon className=" zyla-wallet-transaction-debit-icon" />
                    ) : (
                      <PlusIcon className="zyla-wallet-transaction-credit-icon" />
                    )}
                  </div>
                  <div className="zyla-wallet-transaction-section-3-ele-left-right">
                    <p className="zyla-wallet-transaction-section-3-ele-left-right-top font-12px-zy-wallet font-weight-700-zy-wallet">
                      {trs.amount}
                    </p>
                    <p className="zyla-wallet-transaction-section-3-ele-left-right-bottom">
                      {trs.comment}
                    </p>
                  </div>
                </div>
                <div className="zyla-wallet-transaction-section-3-ele-right">
                  <p>{trs.date}</p>
                </div>
              </div>
            );
          })}
        {loading && (
          <CircularProgress className="ion-router-circular-progress"></CircularProgress>
        )}
      </div>
      {showDialog && (
        <div className="zyla-wallet-transaction-dialog">
          <div className="zyla-wallet-transaction-content">
            <p className="zyla-wallet-transaction-content-heading">
              Choose filter range
            </p>
            {walletFilterArr.map((ele) => {
              return (
                <button
                  key={ele.id}
                  onClick={() => {
                    setFilterName(ele.name);

                    setShowDialog(false);
                    dispatch(
                      getWalletTransactionByRange({
                        id: walletId,
                        count: -1,
                        range: ele.value,
                      })
                    );
                  }}
                >
                  {ele.name}
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default WalletTransactions;
