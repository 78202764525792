import React, { useEffect, useState } from "react";
import "../../diabetesDigit/index.scss";
import { IonContent, IonModal, IonPage } from "@ionic/react";
import { useHistory } from "react-router";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import digitService from "../../../services/DigitService";

import zylaLogo from "../../../assets/images/zyla-logo.png";

import PACompletedImage from "../../../assets/icons/ic_completed_Success.png";
import PaymentDialog from "../../../components/common/PaymentDialog";
import RequestCompleted from "../../premium/components/requestCompleted";
import razorPayHelper from "../../../utils/RazorPayHelper";

import { useSelector } from "react-redux";

import { BlogPreviewAlt, Check, FAQ } from "./helperComponent";

const settings2 = {
  dots: true,
  arrows: true,
  autoplay: false,
  autoplaySpeed: 2500,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

function CommonComponent({
  therapy,
  topImg,
  programName,
  programDesc,
  middleListHeading,
  middleListContent,
  doctorsList,
  box4,
  patients,
  requestbox,
  blogHeading,
  faqs,
  isCovid,
}) {
  const patientProfile = useSelector(
    (store) => store.login.user?.patientProfile
  );
  const [blogData, setBlogData] = useState(null);
  const [openPayment, setOpenPayment] = useState(false);
  const [paymentResult, setPaymentResult] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const [callBackRequested, setCallBackRequested] = useState(
    localStorage.getItem("callbackRequested") !== null ? true : false
  );

  const history = useHistory();

  useEffect(() => {
    if (!openPayment && paymentResult) {
      history.replace("/tab");
    }
  }, [openPayment]);

  useEffect(() => {
    digitService
      .getBlogsforTherapy(therapy)
      .then((result) => {
        const res = result.data;

        const shuffle = (array) => array.sort(() => Math.random() - 0.5);
        shuffle(res);
        setBlogData(res ? res.slice(0, 10) : null);
      })
      .catch((err) => {
        console.log("something went wrong while fetching data");
      });

    if (!window.rzploaded) {
      razorPayHelper.loadScript();
    }
  }, []);

  const handleCompletion = (res) => {
    setOpenPayment(true);
    setPaymentResult(res);
  };

  const paymentStarted = () => {
    if (window.rzploaded !== true) {
      return;
    }
    digitService
      .getOrderId(patientProfile?.id, {
        planId: parseInt(process.env.REACT_APP_RAZORPAY_PLAN_ID),
        currency: "INR",
      })
      .then((result) => {
        const res = result.data;
        if (!res) {
          return;
        }

        const options = {
          key: res.rzpKeyId, // Enter the Key ID generated from the Dashboard
          amount: parseFloat(res.amount),
          currency: res.currency,
          name: "Zyla Health",
          description: "Digit customer payment Transaction",
          image: { zylaLogo },
          order_id: res.id,
          handler: (response) => {
            const data = {
              orderCreationId: res.order_id,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            };
            handleCompletion(true);
          },
          prefill: {
            name: patientProfile?.firstname,
            email: patientProfile?.email,
          },
          notes: {
            address:
              "LGF, Saket Salcon, Rasvilas, Saket District Centre, Sec 6, Pushp Vihar, New Delhi- 110017",
          },
          theme: {
            color: "#6E5ABB",
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.on("payment.failed", (response) => {
          handleCompletion(false);
        });
        paymentObject.open();
      })
      .catch((err) => {});
  };
  const hideModal = () => {
    setOpenPayment(false);
  };

  const requestCallback = () => {
    digitService
      .requestCallBack()
      .then((res) => {
        localStorage.setItem("callbackRequested", true);
        setRequestSent(true);
      })
      .catch((err) => {
        console.log("call back cannot be requested");
      });
  };
  const handleRequestSent = () => {
    setRequestSent(false);
    setCallBackRequested(true);
  };

  return (
    <IonPage>
      <IonContent>
        {!requestSent && (
          <div className="diabetes-container">
            <div className="diabetes-container-box-1">
              <div className="diabetes-container-box-1-top">
                <img src={topImg} className="main-img" />
              </div>

              <div className="diabetes-container-box-1-middle">
                <div className="diabetes-container-box-1-top-bottom">
                  <img src={zylaLogo} />
                  <span>Your personal health expert</span>
                </div>
                <div className="diabetes-container-box-1-middle-ddp">
                  <h2>{programName}</h2>
                  <p>{programDesc}</p>
                </div>
                <div className="diabetes-container-box-1-middle-list">
                  <h2>{middleListHeading}</h2>
                  <div className="diabetes-container-box-1-middle-list-conatiner">
                    <ol type="1">
                      {middleListContent.map((listObj) => {
                        return (
                          <li key={listObj.id}>
                            {listObj.p1}
                            <br /> {listObj.p2}
                          </li>
                        );
                      })}
                    </ol>
                  </div>
                </div>
                {!callBackRequested ? (
                  <div
                    className="diabetes-container-box-1-button"
                    onClick={() => requestCallback()}
                  >
                    <span>Request Callback</span>
                  </div>
                ) : (
                  <div className="diabetes-container-box-1-button-2">
                    <img src={PACompletedImage} />
                    <span>Call Scheduled</span>
                  </div>
                )}
              </div>
            </div>
            <div className="diabetes-container-box-2">
              <p className="diabetes-container-box-2-heading">
                Meet our experts
              </p>
              <Slider {...settings2} className="doctor-slider-box-2">
                {doctorsList.map((ele) => {
                  return (
                    <div className="diabetes-container-box-2-doctor">
                      <div className="diabetes-container-box-2-doctor-img">
                        <img src={ele.image} />
                      </div>
                      <div className="diabetes-container-box-2-doctor-desc">
                        <span className="doctor-desc-name">{ele.name}</span>
                        <span className="doctor-desc-meta">{ele.meta}</span>
                        <span className="doctor-desc-exp">{ele.exp}</span>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
            <div className="diabetes-container-box-4">
              <p className="diabetes-container-box-4-heading">{box4.heading}</p>
              <div className="diabetes-container-box-4-inner">
                <h3 className="diabetes-container-box-4-inner-heading-1">
                  {box4.heading1}
                </h3>
                <span className="diabetes-container-box-4-inner-heading-2">
                  {box4.heading2}
                </span>
                <div className="diabetes-container-box-4-inner-list">
                  {box4.secondList1.map((a) => (
                    <Check content={a} />
                  ))}
                  <div className="diabetes-container-box-4-inner-list-list">
                    <ul>
                      {box4.innerList.map((ele) => {
                        return (
                          <li key={ele.id}>
                            <p className="text-gray-900">
                              <b>{ele.day}</b>
                              {ele.text}
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  {box4.secondList2.map((a) => (
                    <Check content={a} />
                  ))}
                  <div
                    className="diabetes-container-box-4-inner-button"
                    onClick={() => paymentStarted()}
                  >
                    <span className="inner-button-span-1">Register Now</span>
                    <span className="inner-button-span-2">
                      Secured by Razorpay
                    </span>
                  </div>
                </div>
              </div>
              <hr
                style={{
                  backgroundColor: "rgba(243,244,246,1)",
                  height: "2px",
                  marginTop: "20px",
                }}
              />
              <div className="digit-common-component-box4-ideal-section">
                <h3 className="digit-common-component-box4-ideal-section">
                  Ideal for
                </h3>
                {box4.mainList.map((a) => (
                  <Check content={a} />
                ))}
              </div>
            </div>
            <div className="diabetes-container-box-5">
              <p className="diabetes-container-box-5-heading">
                See how Zyla program has changed lives
              </p>
              <Slider {...settings2} className="patient-slider-box-5">
                {patients.map((ele) => {
                  return (
                    <div className="patient-slide-container">
                      <span className="patient-slide-top">{ele.content}</span>
                      <div className="patient-slide-container-bottom">
                        <img src={ele.image} />
                        <span>{ele.name}</span>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
            <div className="diabetes-container-box-7">
              <p>{requestbox.heading}</p>
              <p>{requestbox.desc}</p>
              {!callBackRequested ? (
                <div
                  className="diabetes-container-box-7-button"
                  onClick={() => requestCallback()}
                >
                  <span>Request Callback</span>
                </div>
              ) : (
                <div className="diabetes-container-box-7-button-2">
                  <img src={PACompletedImage} />
                  <span>Call Scheduled</span>
                </div>
              )}
            </div>
            <div className="diabetes-conatiner-box-6">
              <p className="diabetes-conatiner-box-6-heading">{blogHeading}</p>
              <div className="diabetes-conatiner-box-6-main">
                {blogData &&
                  blogData.map((r) => (
                    <BlogPreviewAlt
                      title={{ rendered: r.title }}
                      link={r.link}
                      image={r.cover}
                      body={r.content}
                    />
                  ))}
              </div>
            </div>

            <div className="diabetes-container-box-3">
              <h3 className="">Frequently Asked Questions</h3>
              {faqs.map((f) => (
                <FAQ title={f.title}>{f.content}</FAQ>
              ))}
            </div>
            {isCovid && (
              <div>
                <div className="diabetes-container-covid-special-msg">
                  <h3 className="diabetes-container-covid-special-msg-heading">
                    Share the care
                  </h3>
                  <h3 className="diabetes-container-covid-special-msg-desc">
                    Please note that this program is being run at a very
                    affordable price only to support the healthcare systems by
                    reducing pressure on hospitals for infrastructure. Do share
                    this with as many people to free up hospital beds, help
                    COVID patients recover faster & help save lives!
                  </h3>
                  <h3 className="diabetes-container-covid-special-msg-phone">
                    Have questions? Call
                    <a href="tel:+91 95559 75306">+91 95559 75306</a> and we
                    will be happy to assist.
                  </h3>
                </div>
              </div>
            )}
            {openPayment && (
              <IonModal cssClass="diabetes-payment-modal" isOpen={true}>
                <PaymentDialog result={paymentResult} hideModal={hideModal} />
              </IonModal>
            )}
          </div>
        )}
        {requestSent && (
          <RequestCompleted
            handleRequestSent={handleRequestSent}
            isDigit={true}
          />
        )}
      </IonContent>
    </IonPage>
  );
}

export default CommonComponent;
