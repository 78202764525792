import { createSlice } from "@reduxjs/toolkit";
import { countries } from "./list";

let isDarkMode = false;
if (
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches
) {
  isDarkMode = true;
}

const initialState = {
  darkMode: isDarkMode,
  device: {
    model: "",
    platform: "web",
    appVersion: "",
    appBuild: "",
    appId: "",
    appName: "",
    operatingSystem: "",
    osVersion: "",
    manufacturer: "",
    isVirtual: false,
    uuid: "",
    isPaytm: false,
    ios14: false,
  },
  appInfo:{
    version:""
  },
  network: undefined,
  location: {
    address: {
      country_code: "IN",
    },
  },

  country: {
    default: { ...countries[0] },
    list: countries,
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    toggleDarkMode: (state) => {
      state.darkMode = !state.darkMode;
    },
    setDarkMode: (state, action) => {
      state.darkMode = action.payload;
    },
    setDevice: (state, action) => {
      state.device = action.payload;
    },
    setAppInfo: (state, action) => {
      state.appInfo = action.payload;
    },
    setNetwork: (state, action) => {
      state.network = action.payload;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
      let cc = action.payload?.address.country_code;
      if (cc) {
        const r = state.country.list.find((c) => c.id === cc);
        if (r) {
          state.country.default = r;
        }
      }
    },
    setDefaultCountry: (state, action) => {
      state.country = {
        ...state.country,
        default: action.payload,
      };
    },
    updateDefaultCountryCode: (state, action) => {
      const { patientProfile } = action.payload;
      let c = countries.find(
        (country) => country.code === `${patientProfile.countryCode}`
      );
      state.country = {
        ...state.country,
        default: c,
      };
    },
  },
});

export const {
  toggleDarkMode,
  setDarkMode,
  setDevice,
  setNetwork,
  setLocation,
  setDefaultCountry,
  updateDefaultCountryCode,
  setAppInfo
} = appSlice.actions;
export default appSlice.reducer;
