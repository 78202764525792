import React, { useEffect, useState } from "react";
import DetailTab from "./components/detailTab";
import calendar from "../../assets/images/profile/ic_calender.png";
import contact from "../../assets/images/profile/ic_contact.png";
import gender from "../../assets/images/profile/ic_gender.png";
import MaleActive from "../../assets/images/ic_male_active.png";
import FemaleActive from "../../assets/images/ic_female_active.png";
import email from "../../assets/images/profile/icon_email.png";
import EditProfile from "./components/editProfile";
import { useHistory } from "react-router-dom";
import "./index.scss";
import { IonPage, IonContent } from "@ionic/react";
import SecureImage from "../../components/common/SecureImage";
import BackButton from "../../components/common/BackButton";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../login/loginSlice";

const profileInfo = [
  {
    title: "Contact Details",
    fields: [
      {
        icon: email,
        data: "email",
        placeHolder: "Add Email",
      },
      {
        icon: contact,
        data: "phoneNo",
        placeHolder: "Add contact",
      },
    ],
  },
  {
    title: "Gender & Date of Birth",
    fields: [
      {
        icon: gender,
        data: "gender",
      },
      {
        icon: calendar,
        data: "DOB",
        placeHolder: "Add Date of Birth",
      },
    ],
  },
];

export default function PatientProfiler() {
  const paytmFlag = useSelector((store) => store.app.device.isPaytm);
  const user = useSelector((store) => store.login.user);
  const patientProfile = useSelector(
    (store) => store.login.user?.patientProfile
  );
  const [name, setName] = useState("");
  // const [profileImage, setProfileImage] = useState("");
  const [showAddress, setShowAddress] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const urlParams = window.location.search;
    if (urlParams) {
      enableProfileEditing();
    }
    if (patientProfile.countryCode !== 91) {
      setShowAddress(false);
    }
  }, []);

  const enableProfileEditing = () => {
    setEditMode(true);
  };

  const hideEditMode = () => {
    setEditMode(false);
    dispatch(getUserProfile());
    //TODO user profile has issues
  };

  return !editMode ? (
    <IonPage className="profile-page-container">
      <IonContent>
        {/* <header>
          {!paytmFlag && (
            <div className="back-button-wrapper">
              <BackButton history={history}></BackButton>
            </div>
          )}

          <div className="profile-header">
            <SecureImage
              classN="avatar-profile"
              src={patientProfile?.profileImage}
              defaultImage={
                patientProfile
                  ? patientProfile.gender === 1
                    ? FemaleActive
                    : MaleActive
                  : FemaleActive
              }
            />

            <div
              className="fs20 fw-semibold"
              style={{ textTransform: "capitalize" }}
            >
              {name}
            </div>
            <div style={{ width: "155px" }}>
              <button onClick={enableProfileEditing} className="prime-btn">
                Edit Profile
              </button>
            </div>
          </div>
        </header> */}
        <div className="profile-data-content">
          <EditProfile
            enableProfileEditing={enableProfileEditing}
            patientProfile={patientProfile}
            showAddress={showAddress}
          />
        </div>
      </IonContent>
    </IonPage>
  ) : (
    <IonPage className="profile-page-container">
      <IonContent>
        <EditProfile hideEditMode={hideEditMode} showAddress={showAddress} />
      </IonContent>
    </IonPage>
  );
}
