import React, { useCallback, useEffect, useState } from "react";
import AudioPlayer from "./audioPlayer";
import { getUrlWithToken } from "../../../utils/helperMethods";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import BackArrowImage from "../../../assets/images/icon_arrow_back_white.png";
import SecureImage from "../../../components/common/SecureImage";
import defaultImage from "../../../assets/images/bg_pattern.png";
import { IonModal, IonSpinner } from "@ionic/react";
import RippleButton from "../../../components/common/RippleButton";
import meditationLock from "../../../assets/images/meditation-lock.png";

import { Drawer } from "@mui/material";

import { logEvent } from "../../../app/eventSlice";
import { toggleMeditateNow } from "../meditationSlice";

const MedDrawer = ({
  media,
  isPlaying,
  onPrevious,
  onNext,
  togglePlay,
  isNext,
  isPrevious,
  token,
  isRecomShow,
}) => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    document.addEventListener("ionBackButton", mediHandleBack);

    return () => {
      document.removeEventListener("ionBackButton", mediHandleBack);
    };
  }, []);

  const mediHandleBack = useCallback((ev) => {
    ev.detail.register(10000, () => {
      dispatch(toggleMeditateNow(null));
      togglePlay(media);
    });
  }, []);
  return (
    <IonModal
      className={"meditation-container"}
      anchor="bottom"
      isOpen={isPlaying}
      onDidDismiss={() => {
        // dispatch(toggleMeditateNow(null));
        // togglePlay(media, true);
      }}
    >
      {!media.locked && loading && (
        <div className="med-loading-wrapper">
          <IonSpinner color="light"></IonSpinner>
        </div>
      )}
      <SecureImage
        classN="playing-current-media"
        defaultImage={defaultImage}
        src={media.artwork}
      ></SecureImage>

      <div className="media-content-data">
        <div className="button-wrapper">
          <RippleButton className="button-wrapper-ripple-button">
            <img
              onClick={() => {
                dispatch(logEvent({ action: "click_back_meditation" }));
                dispatch(toggleMeditateNow(null));
                togglePlay(media);
              }}
              className="back-icon"
              alt="icon"
              src={BackArrowImage}
            />
          </RippleButton>
        </div>

        <div className="playing-page-content responsive-600">
          <div className="paying-media-data">
            <div className="media__language">{media.language}</div>
            <div className="title-playing">{media.title}</div>
            <div className="description-playing">{media.description}</div>
          </div>
          {media.locked ? (
            <div className="locked-meditation-container">
              <div className="meditation-overlay"></div>
              <div className="locked-meditation-info">
                <div
                  className="locked-meditation-info-button"
                  onClick={() => {
                    togglePlay(media);
                    history.push("/premium");
                  }}
                >
                  <img src={meditationLock} alt="icon" />
                  <span>Unlock with Zyla Premium</span>
                </div>
                <div className="locked-meditation-info-desc">
                  Get Zyla Premium to enjoy this meditation and more features
                </div>
              </div>
            </div>
          ) : (
            <AudioPlayer
              file={getUrlWithToken(media.url, token)}
              onPrevious={() => onPrevious(media)}
              onNext={() => {
                onNext(media);
              }}
              isNext={isNext}
              isPrevious={isPrevious}
              onDoneLoading={() => setLoading(false)}
            ></AudioPlayer>
          )}
        </div>
      </div>
    </IonModal>
  );
};

export default MedDrawer;
