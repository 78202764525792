import axios from "axios";
import axiosClient from "../app/axiosConfig";
import APIConfig from "./APIConfig";

class DigitService {
  requestCallBack(user) {
    const url = APIConfig.patient.requestCall.format(user.patientProfile.id);
    return axiosClient.put(url, null);
  }
  getBlogsforTherapy = (therapy) => {
    let url = `https://strapi.prod.zyla.in/blogs?therapy=${therapy}`;
    return axiosClient.get(url);
  };
  getOrderId = (patientId, param) => {
    let url = APIConfig.razorPay.orders.format(patientId);
    return axiosClient.post(url, param);
  };
  getPatientPlan = (id, onSuccess, onError) => {
    let url = APIConfig.digit.plan.format(id);
    return axiosClient.get(url);
  };

  digitAutoLogin = (phoneNo, token) => {
    let url = APIConfig.user.autoLoginDigit.format(phoneNo, token);
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        phone_no: phoneNo,
        Authorization: `Bearer ${token}`,
      },
    });
  };
}

const digitService = new DigitService();
export default digitService;
