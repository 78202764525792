import React from "react";
import { useDispatch, useSelector } from "react-redux";
import backImage from "../../../assets/icons/arrow-back-black.svg";
import { updateCurrentScreen } from "../navigoSlice";
import { IonModal } from "@ionic/react";
export default function ServiceDetails() {
  const navigoStore = useSelector((store) => store.navigo);
  const dispatch = useDispatch();
  const { currentServices } = navigoStore.ui;

  return (
    <IonModal
      className={"menu-partial-full-height reporting-container"}
      anchor="bottom"
      isOpen={true}
      onDidDismiss={() => dispatch(updateCurrentScreen(1))}
    >
      <div className="navigo-booking-know-more-container">
        <div className="navigo-know-more-header">
          <img
            src={backImage}
            alt="icon"
            onClick={() => {
              dispatch(updateCurrentScreen(1));
            }}
          />
        </div>
        <p className="navigo-booking-know-more-title">{currentServices.name}</p>
        <div className="navigo-booking-know-more-main">
          <div className="navigo-booking-know-more-main-ele">
            {/* <img
              src={currentServices.image}
              alt="service-img"
              className="navigo-booking-container-section-1-bottom"
            /> */}
          </div>

          <p className="healthplan-slider-2-bottom-last-last">
            {currentServices.description}
          </p>
        </div>
      </div>
    </IonModal>
  );
}
