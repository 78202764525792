import { IonContent, IonPage } from "@ionic/react";
import StepathonStepCard from "./components/stepathonStepCrad";
import Steps from "../HomePage/components/StepsTracker";
import { CircularProgress } from "@mui/material";

import { LeaderBoardCard } from "../stepathon/components/leadreboardCard";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { leaderBoardData, stepathonAboutData } from "./stepathonSlice";
import { useSelector } from "react-redux";
import DailyTarget from "../HomePage/components/DailyTargets";
import TrackerChart from "../HomePage/StepAndSleepChart";
import TrackerChartGraph from "../HomePage/components/trackerChartGraph";
import "./stepathon.scss";

const StepathonPage = () => {
  const [loading, setLoading] = useState(true);

  const data = useSelector((state) => state.stepathon.leaderBoard);
  const status = useSelector((state) => state.stepathon.status);
  const trackingEnabled = useSelector((store) => store.home.trackingEnabled);
  const [topTenLeaderBoardData, setTopTenLeaderBoardData] = useState(null);

  const patientProfile = useSelector(
    (state) => state.login?.user?.patientProfile
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (status === "idle") {
      dispatch(stepathonAboutData());

      setLoading(true);
      dispatch(leaderBoardData(patientProfile.id))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  }, []);

  useEffect(() => {
    setTopTenLeaderBoardData(data?.data?.slice(0, 11));
  }, [data]);
  useEffect(() => {
    dispatch(stepathonAboutData());
    dispatch(leaderBoardData(patientProfile.id))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [dispatch, patientProfile.id]);

  return (
    <IonPage>
      <IonContent>
        {loading ? (
          <div className="relative flex items-center justify-center h-full">
            <CircularProgress />
          </div>
        ) : (
          <>
            <StepathonStepCard />
            <div className="font-bold text-base leading-5 text-lightblack  px-4 mb-6">
              Your step tracker
              {trackingEnabled || true ? (
                <div
                  style={{ maxHeight: "500px" }}
                  className="tracker-container stepathon-tracker-chart-graph"
                >
                  <TrackerChartGraph />
                </div>
              ) : (
                <DailyTarget />
              )}
              {window.Capacitor.platform === "ios" && (
                <p className="text-[10px] text-[#777777] mt-[4px] mb-0">
                  We use HealthKit APIs to fetch your step count.
                </p>
              )}
              <br />
              <div className="font-bold leading-5 text-base mb-3">
                Leader board
              </div>
            </div>
            {status === "success" &&
              topTenLeaderBoardData != null &&
              topTenLeaderBoardData.map((item) => {
                return (
                  <>
                    <LeaderBoardCard item={item} />
                  </>
                );
              })}
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default StepathonPage;
