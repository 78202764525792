import React, { useEffect, useRef } from "react";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { tabChangeVitalChartReducer } from "../vitalsChartSlice";
export default function VitalsTabs() {
  const paramGroups = useSelector((store) => store.vitalsChart.vitalParams);
  const paramGroupId = useSelector(
    (store) => store.vitalsChart.ui.paramGroupId
  );
  const dispatch = useDispatch();
  const myRef = useRef();
  useEffect(() => {
    let id = paramGroupId;
    let list = myRef.current;
    if (list) {
      for (let i = 0; i < list.childNodes.length; i++) {
        if (list.childNodes[i].id === id) {
          list.childNodes[i].scrollIntoView({ block: "center" });
        }
      }
    }
  }, [paramGroupId]);
  useEffect(() => {
    dispatch(
      tabChangeVitalChartReducer({
        pgId: paramGroups[0].id.toString(),
        selectedParamUnit: paramGroups[0].unit,
      })
    );
  }, []);

  const onTabChange = (pgId) => {
    if (pgId) {
      let selectedParamUnit = "";
      if (paramGroups) {
        let paramLabelArray = paramGroups.find((e) => e.id === +pgId);

        if (
          paramLabelArray &&
          paramLabelArray.params &&
          paramLabelArray.params.length > 0
        ) {
          selectedParamUnit = paramLabelArray.params[0].unit.name;
        }
      }
      dispatch(
        tabChangeVitalChartReducer({
          pgId: pgId,
          selectedParamUnit: selectedParamUnit,
        })
      );
    }
  };

  return (
    <IonSegment
      style={{ marginTop: "10px" }}
      className="bg-white"
      mode="ios"
      scrollable
      value={paramGroupId}
      ref={myRef.current}
      onIonChange={(e) => {
        onTabChange(e.detail.value);
      }}
    >
      {paramGroups &&
        paramGroups.map((k, i) => {
          return (
            <div id={`${k.id}`} key={`${k.id}`}>
              <IonSegmentButton
                key={`${k.id}`}
                className="scroll_tab "
                value={`${k.id}`}
              >
                <IonLabel className="ion-label-class">{k.name} </IonLabel>
              </IonSegmentButton>
            </div>
          );
        })}
    </IonSegment>
  );
}
