import React from "react";
import mntIcon from "../../../assets/icons/mnt.png";
import naturopathyIcon from "../../../assets/icons/naturopathy.png";
import allopathyIcon from "../../../assets/icons/allopathy.png";
const tctCards = [
  {
    title: "Medical Nutrition Therapy (MNT)",
    text: "Personalized diabetes food plan",
    image: mntIcon,
    id: 1,
  },
  {
    title: "Naturo \n Therapy",
    text: "Ayurvedic healing remedies from your kitchen",
    image: naturopathyIcon,
    id: 2,
  },
  {
    title: "Allopathic \n Therapy",
    text: "Continuous guidance from Senior doctors",
    image: allopathyIcon,
    id: 3,
  },
];

export default function PremiumTCTCards({}) {
  return (
    <div className="section-wrapper background-color-white-class margin-Bottom-15">
      <h2>Zyla’s unique Triple Combination Therapy (TCT)™</h2>
      <p>
        Lakhs of patients across the world have adopted Zyla’s TCT approach &
        technology to manage their health issues right at home.
      </p>
      <div className="scroll-div">
        {tctCards.map((card) => (
          <div className="tct-cards" key={card.id}>
            <div className="tct-image-background-div">
              <img src={card.image} />
            </div>
            <h3>{card.title}</h3>
            <p>{card.text}</p>
          </div>
        ))}
        <div className="pseudo-margin"> &nbsp;</div>
      </div>
    </div>
  );
}
