import { Link, useHistory } from "react-router-dom";
import backImage from "../../../assets/icons/arrow-back-black.svg";
import copyIcon from "../../../assets/images/copyIcon.png";
import voucherImage from "../../../assets/images/voucherImage.png";
import { IonContent, IonPage } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { clearRegisterData } from "../../registration/registrationSlice";
import Toast from "../../../components/common/toast";
import BackButton from "../../../components/common/BackButton";

export default function GenerateVoucher() {
  const registrationStore = useSelector((state) => state.registration);
  const isLoggedIn = useSelector((state) => state.login.loggedIn);
  const [isSuccess, setIsSuccess] = useState(false);
  const [voucher, setVoucher] = useState("");
  const [showToast, setShowToast] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const textStyle = {
    fontSize: "10px",
    lineHeight: "14px",
    fontWeight: "400",
    color: "#464646",
  };
  const { registerData } = registrationStore;
  useEffect(() => {
    if (registerData) {
      let temp = moment(registerData.dob, "Do MMM YYYY").format("YYYY/MM/DD");
      let month = moment(temp).month() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      let v = `MAZY${registerData.firstName
        .substring(0, 1)
        .toUpperCase()}${month}${Math.floor(Math.random() * 90) + 10}${String(
        moment(temp).year()
      ).substring(2)}`;
      setVoucher(v);
      dispatch(clearRegisterData());
      setIsSuccess(true);
    } else {
      if (isLoggedIn && !isSuccess) {
        history.replace("/registervoucher");
      }
    }
  }, [registerData]);

  return (
    <IonPage>
      <IonContent>
        <div className="p-5">
          <BackButton />
          <div className="font-bold text-base leading-6">
            Thank you for generating the voucher Code for Max Life Secure
            Earnings and Wellness Advantage Plan (SEWA)(UIN:104N136V01).
          </div>
        </div>
        <div className="h-48">
          <img src={voucherImage} alt="" />
          <div className="text-center p-8 text-lightblack text-xs font-medium">
            Use this exclusive voucher code during Secure Earnings and Wellness
            Advantage Plan (SEWA) purchase and avail 5% upfront discount on your
            first year premium.
          </div>
          <div className="p-5 ">
            <button
              className="rounded-full w-full p-5 flex justify-between items-center"
              style={{ backgroundColor: "#F8F8F8" }}
            >
              <div className="text-lightblack font-bold text-base leading-7 tracking-wider">
                {voucher}
              </div>
              <img
                src={copyIcon}
                onClick={() => {
                  if (navigator.clipboard) {
                    navigator.clipboard.writeText(voucher);
                    setShowToast(true);
                  }
                }}
                alt=""
                className="h-6 w-6"
              />
              {showToast && (
                <Toast
                  msg={"Copied from clipboard"}
                  close={() => setShowToast(false)}
                />
              )}
            </button>
          </div>
          <div className="p-8 text-center " style={textStyle}>
            We will send an SMS to you once your subscription to the app becomes
            active.
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
