import { IonContent, IonPage } from "@ionic/react";
import BackButton from "../../components/common/BackButton";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { stepathonAboutData } from "./stepathonSlice";

const StepathonAboutPage = () => {
  const dispatch = useDispatch();

  const datas = useSelector((state) => state.stepathon.data);
  const [bulletPoints, setBulletPoints] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const status = useSelector((state) => state.stepathon.status);

  useEffect(() => {
    if (status === "idle") {
      dispatch(stepathonAboutData());
    }
    if (status === "success") {
      setIsSuccess(true);
      if (datas && datas.data && datas.data.mli_offer) {
        setBulletPoints(datas.data.mli_offer[0].Content.split("\n"));
      }
    }
  }, [status, datas]);

  useEffect(() => {
    if (datas && datas.data && datas.data.mli_offer) {
      dispatch(stepathonAboutData());
      setBulletPoints(datas.data.mli_offer[0].Content.split("\n"));
    }
  }, [dispatch]);

  return (
    <IonPage>
      <IonContent>
        <div className="px-4 py-7 font-[Verdana] text-lightblack tracking-[-2%]">
          <BackButton />
          <div className="font-bold text-lightblack text-[14px] leading-6 py-3 ">
            {isSuccess && datas.data.mli_offer[0].Title}
          </div>
          <div className=" text-xs leading-5 font-normal tracking-[-2%]">
            <ol className="pl-0 list-none mt-1">
              {isSuccess &&
                bulletPoints.map((e) => {
                  return (
                    <li className="text-lightblack font-medium mb-1">{e}</li>
                  );
                })}
            </ol>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default StepathonAboutPage;
