import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../app/axiosConfig";
import APIConfig from "../../services/APIConfig";
import reportingServices from "../../services/ReportingService";

const initialState = {
  reports: {},

  ui: {
    loading: false,
    loaded: false,
    error: undefined,
    errorMsg: "",
    rowChanged: false,
    openDrawer: false,
    tableError: false,
    tableErrorMsg: "",
    submitted: false,
    submitting: false,
    id: "",
    items: [],
  },
};

export const getReportByDate = createAsyncThunk(
  "reporting/getReportByDate",
  async (data, { rejectWithValue }) => {
    try {
      // date in DD-MM-YYYY format
      const url = APIConfig.report.getByDate.format(data.patientId, data.date);
      const response = axiosClient.get(url);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveReport = createAsyncThunk(
  "reporting/saveReport",
  async (data, { rejectWithValue }) => {
    try {
      const response = await reportingServices.saveAndUpdateReport(data.body);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const reportSlice = createSlice({
  name: "reporting",
  initialState,
  reducers: {
    reportingShowError: (state, action) => {
      state.ui = {
        ...state.ui,
        error: true,
        loading: false,
        errorMsg: action.payload.msg
          ? action.payload.msg
          : "Something went wrong.",
      };
    },
    reportingClearError: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          error: false,
          errorMsg: "",
        },
      };
    },
    tableErrorHandle: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          tableError: action.payload.value,
          tableErrorMsg: action.payload.msg,
        },
      };
    },
    handleAddLineDrawer: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          openDrawer: action.payload.value,
        },
      };
    },
    updateReportItems: (state, action) => {
      return {
        ...state,

        ui: {
          ...state.ui,
          items: [...action.payload.items],
          rowChanged: action.payload.isNew,
          tableError: false,
          tableErrorMsg: "",
        },
      };
    },
    updateReportingId:(state,action)=>{
      
      return {
        ...state,
        ui: {
          ...state.ui,
          id:action.payload
        },
      };
    },
    toggleSubmitted: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          submitted: false,
        },
      };
    },
    updateAndSaveReports: (state, action) => {
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.payload.date]: action.payload.report,
        },
      };
    },
    updateItemsFromSavedReports: (state, action) => {
      let items = action.payload.data.data;

      if (
        (items && items.length === 0) ||
        (items.length > 0 && items[items.length - 1].isNew !== true)
      ) {
        items = [
          ...items,
          {
            hour: "",
            minute: "",
            dayNight: "",
            activity: "",
            time: "",
            isNew: true,
          },
        ];
      }
      return {
        ...state,

        ui: {
          ...state.ui,
          loading: false,
          loaded: true,
          items: items,
          id: action.payload.data._id,
        },
      };
    },
  },
  extraReducers: {
    [getReportByDate.pending]: (state, action) => {
      state.loading = true;
      state.loaded = false;
    },
    [getReportByDate.rejected]: (state, action) => {
      let newRecord = {
        hour: "",
        minute: "",
        dayNight: "",
        activity: "",
        time: "",
        isNew: true,
      };
      return {
        ...state,
        ui: {
          ...state.ui,
          items: [newRecord],
        },
      };
    },
    [getReportByDate.fulfilled]: (state, action) => {
      const reportDate = action.meta.arg.date;
      let newRecord = {
        hour: "",
        minute: "",
        dayNight: "",
        activity: "",
        time: "",
        isNew: true,
      };
      let items = reportingServices._postProcessResponse(action.payload.data);

      let _id = action.payload.data._id;

      return {
        ...state,
        reports: { ...state.reports, [reportDate]: { data: items, _id: _id } },
        ui: {
          ...state.ui,
          id: _id,
          items: [...items, { ...newRecord }],
          loading: false,
          loaded: true,
        },
      };
    },
    [saveReport.pending]: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: true,
          loaded: true,
        },
      };
    },
    [saveReport.fulfilled]: (state, action) => {
      let items = reportingServices._postProcessResponse(action.payload.data);
      let _id = action.payload.data._id;

      return {
        ...state,
        reports: {
          ...state.reports,
          [action.meta.arg.body.data[0].date]: {
            data: items,
            _id: _id,
          },
        },

        ui: {
          ...state.ui,
          loading: false,
          loaded: false,
          submitted: true,
        },
      };
    },
    [saveReport.rejected]: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          error: true,
          loading: false,
          loaded: false,
          errorMsg: "Error while saving report.",
        },
      };
    },
  },
});

export const {
  reportingShowError,
  reportingClearError,
  handleAddLineDrawer,
  tableErrorHandle,
  updateReportItems,
  toggleSubmitted,
  updateAndSaveReports,
  updateReportingId,
  updateItemsFromSavedReports,
} = reportSlice.actions;
export default reportSlice.reducer;
