import React from "react";
export const HistoryCard = ({
  name,
  status,
  bookingId,
  consultDate,
  bookDate,
  price,
}) => {
  return (
    <div className="booking-history-card">
      <div className="booking-history-card-ele">
        <b>{name}</b>
        <p
          className={
            status === 2
              ? "status-completed"
              : status === 3
              ? "status-cancelled"
              : "status-ongoing"
          }
        >
          {status === 2 ? "Completed" : status === 3 ? "Cancelled" : "Ongoing"}
        </p>
      </div>
      <div className="booking-history-card-ele">
        <p>Booking ID</p>
        <p>{bookingId}</p>
      </div>
      <div className="booking-history-card-ele">
        <p>Consultation date</p>
        <p>{consultDate}</p>
      </div>
      <div className="booking-history-card-ele">
        <p>Booking date</p>
        <p>{bookDate}</p>
      </div>
      <div className="booking-history-card-ele booking-card-price">
        <p>₹{price}</p>
      </div>
    </div>
  );
};
