import React, { useEffect, useState } from "react";
import icon_Medical from "../../../assets/icons/icon_medicine_2.png";
import icon_exercise from "../../../assets/icons/icon_exercise.png";
import icon_Nutrition from "../../../assets/icons/Icon_Nutrition.png";
import icon_Meditation from "../../../assets/icons/icon_Meditation.png";
import { useDispatch, useSelector } from "react-redux";
import { logEvent } from "../../../app/eventSlice";
import { useHistory } from "react-router";
import {
  fetchHealthPlan,
  fetchHealthPlanCategories,
} from "../../healthPlan/healthPlanSlice";

const cards = [
  {
    title: "Nutrition",
    icon: icon_Nutrition,
    id: "Nutrition",
    name: "NUTRITION",
  },
  {
    title: "Exercise",
    icon: icon_exercise,
    id: "Exercise",
    name: "EXERCISE",
  },
  {
    title: "Medical",
    icon: icon_Medical,
    id: "Medical",
    name: "MEDICINE",
  },
  {
    title: "Meditation",
    icon: icon_Meditation,
    id: "Meditation",
    name: "MEDITATION",
  },
];
const HomePageHPCards = (props) => {
  const patientProfile = useSelector(
    (store) => store.login.user?.patientProfile
  );
  const { ui, healthPlan, healthCategory } = useSelector(
    (state) => state.healthPlan
  );
  const [finalHpArray, setFinalHpArray] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch();
  const RedirectToHP = (section) => {
    switch (section) {
      case "Exercise":
        dispatch(logEvent({ action: "open_home_exercise_plan" }));

        break;
      case "Meditation":
        dispatch(logEvent({ action: "open_home_meditation_plan" }));

        break;
      case "Medical":
        dispatch(logEvent({ action: "open_home_medical_plan" }));

        break;
      case "Nutrition":
        dispatch(logEvent({ action: "open_home_nutrition_plan" }));

        break;
      default:
    }
    history.push(`/health-plan?${section}`);
  };

  useEffect(() => {
    dispatch(fetchHealthPlanCategories());
  }, []);

  useEffect(() => {
    if (healthCategory) {
      dispatch(fetchHealthPlan({ id: patientProfile.id }));
    }
  }, [healthCategory]);

  return (
    <React.Fragment>
      {ui.hpExist && healthPlan && healthPlan.data ? (
        <div className="homepage-hp-wrapper mt-8">
          <h1>My health plan</h1>
          <p className="homepage-hp-desc">
            Follow recommended health plan to achieve your health goals!
          </p>
          <div
            className="homepage-hp-cards"
            onClick={() => {
              dispatch(
                logEvent({
                  action: "open_health_plan_home",
                })
                // console.log("open_health_plan_home")
              );
            }}
          >
            {cards.map((card) => {
              if (healthPlan.data[card.id]) {
                return (
                  <button
                    className="home-page-hp-card"
                    onClick={() => {
                      RedirectToHP(card.id);
                    }}
                  >
                    <div className="home-page-hp-card-top">
                      <img src={card.icon} alt="icon" />
                    </div>

                    <div className="home-page-hp-card-bottom">
                      <p>{card.title}</p>
                    </div>
                  </button>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default HomePageHPCards;
