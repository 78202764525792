import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import axiosClient from "../../app/axiosConfig";
import APIConfig from "../../services/APIConfig";
import vitalServices from "../../services/VitalService";

const initialState = {
  vitals: {},
  id: "",
  ui: {
    loading: true,
    loaded: false,
    groupModels: [],
    showDetail: false,
    currentDetail: undefined,
    isNoVitalRequired: false,
    error: false,
    errorMsg: "",
    btnActive: false,
    isSendActive: false,
  },
};

export const getVitalByDate = createAsyncThunk(
  "vital/getVitalByDate",
  async (data, { rejectWithValue }) => {
    try {
      // date in DD-MM-YYYY format

      const url = APIConfig.testReading.get.format(
        data.user.patientProfile.id,
        moment(data.date, "Do MMMM").valueOf()
      );
      const response = axiosClient.get(url);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitVitals = createAsyncThunk(
  "vital/submit",
  async (data, { rejectWithValue }) => {
    try {
      const { date, params, user } = data;

      const response = await vitalServices.submitVital(
        moment(date, "Do MMMM").valueOf(),
        params,
        user
      );
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const vitalSlice = createSlice({
  name: "vital",
  initialState: initialState,
  reducers: {
    handleGetVitalFullfill: (state, action) => {
      const { groupModels, isNoVitalRequired, date } = action.payload;

      return {
        ...state,
        vitals: { ...state.vitals, [date]: groupModels },
        ui: {
          ...state.ui,
          loading: false,
          loaded: true,
          submitted: false,
          groupModels: groupModels,
          isSendActive: false,
          isNoVitalRequired: isNoVitalRequired,
        },
      };
    },
    handleVitalError: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          error: action.payload.data,
          errorMsg: action.payload.msg,
          loading: false,
        },
      };
    },

    handleUpdateParamValues: (state, action) => {
      const { paramUpdate, paramIndex, parentIndex } = action.payload;

      let newGroupModel = state.ui.groupModels[parentIndex];

      let newParams = newGroupModel.params.map((gm, index) => {
        if (index === paramIndex) {
          return {
            ...gm,
            value: paramUpdate,
          };
        }
        return { ...gm };
      });

      let newGroupModels = state.ui.groupModels.map((grm, ind) => {
        if (ind === parentIndex) {
          return { ...grm, params: newParams };
        }
        return { ...grm };
      });
      return {
        ...state,
        ui: {
          ...state.ui,
          groupModels: newGroupModels,
          isSendActive: true,
        },
      };
    },
    handleVitalLoaders: (state, action) => {
      state.ui.loading = action.payload.data;
    },
    cacheVitalsFromResponse: (state, action) => {
      return {
        ...state,
        vitals: { ...state.vitals, [action.payload.date]: action.payload.data },
      };
    },
  },
  extraReducers: {
    [submitVitals.fulfilled]: (state, action) => {
      let response = action.payload.data;
      let saveDate = action.meta.arg.date;

      return {
        ...state,
        vitals: { ...state.vitals, [saveDate]: response },
        ui: {
          ...state.ui,
          loading: false,
        },
      };
    },
  },
});

export const {
  handleGetVitalFullfill,
  handleVitalError,
  handleVitalLoaders,
  handleUpdateParamValues,
  cacheVitalsFromResponse,
} = vitalSlice.actions;
export default vitalSlice.reducer;
