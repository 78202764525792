import { Drawer } from "@mui/material";
import { useState } from "react";
import React from "react";
import { useDispatch } from "react-redux";
import chatBoxIcon from "../../../assets/images/tourImages/chat-box-icon.png";
import profileBoxIcon from "../../../assets/images/tourImages/profile-box-icon.png";
import arrowProfile from "../../../assets/images/tourImages/arrow-profile.png";
import feedBoxIcon from "../../../assets/images/feed-box-icon.svg";
import arrowChat from "../../../assets/images/tourImages/arrow-chat.png";
import tourUpArrow from "../../../assets/images/ic_up_arrow.png";
import yellowStar from "../../../assets/icons/yellow-star.png";
import forwardArrow from "../../../assets/images/ic_arrow_forward.png";
import cameraIcon from "../../../assets/images/camera.png";
import homeBoxIcon from "../../../assets/images/tourImages/home-box-icon.png";
import tourHomeIcon from "../../../assets/icons/tour-home-icon.svg";
import tourChatIcon from "../../../assets/icons/tour-chat-icon.svg";
import tourFeedIcon from "../../../assets/icons/tour-feed-icon.svg";
import { updateStartTourShown } from "../chatSlice";
import { logEvent } from "../../../app/eventSlice";
import { IonModal } from "@ionic/react";
export default function ChatPageTour({ showTour, onClose }) {
  const [tourState, setTourState] = useState(4);
  const dispatch = useDispatch();
  return (
    <IonModal
      role="presentation"
      className={`chat-room-tour ion-modal-general-class ion-modal-transparent-background ${
        tourState !== 4 ? "backdrop-white-class" : ""
      }`}
      anchor="bottom"
      isOpen={showTour}
      onDidDismiss={() => {
        onClose();
      }}
    >
      {tourState === 1 && (
        <div className="chat-tour-stage-3">
          <div className="chat-tour-content-box">
            <span className="chat-tour-content-box-heading chat-tour-heading-320">
              Zyla
            </span>
            <span className="chat-tour-content-body chat-tour-summary">
              I’ll answer all your health queries related to nutrition, exercise
              and medications! Click on the orange arrow for personalised
              guidance on routine, vitals and reports.
            </span>
            <div
              className="chat-tour-next-button"
              onClick={() => {
                setTourState(2);
                dispatch(logEvent({ action: "click_tour_item_2" }));
              }}
            >
              Next
            </div>
          </div>
          <div className="tour-chat-box">
            <img src={tourUpArrow} alt="" className="tour-chat-box-left" />
            <div className="tour-chat-box-right">
              <span>Ask me anything</span>
              <img src={cameraIcon} alt="" />
            </div>
          </div>
          <img src={arrowChat} alt="" className="tour-chat-arrow" />
          <TourIconBox
            image={tourChatIcon}
            title={"Zyla"}
            justifyClass={"justify-center"}
          />
          {/* <img src={chatBoxIcon} alt="" className="tour-chat-box-icon" /> */}
        </div>
      )}
      {tourState === 2 && (
        <div className="chat-tour-stage-2">
          <div className="chat-tour-content-box">
            <span className="chat-tour-content-box-heading chat-tour-heading-320">
              Feed
            </span>
            <span className="chat-tour-content-body chat-tour-summary">
              Visit feed anytime to read health blogs and watch videos
              personalised as per your health goals.
            </span>
            <div className="chat-tour-next-button-home">
              <button
                className="chat-tour-next-button-retour"
                onClick={() => {
                  setTourState(0);
                }}
              >
                Retour
              </button>
              <button
                className="chat-tour-next-button"
                onClick={() => {
                  dispatch(
                    updateStartTourShown(),
                    logEvent({ action: "click_tour_item_3" })
                  );

                  onClose();
                }}
              >
                <b>Got it </b>
              </button>
            </div>
          </div>

          <img src={arrowProfile} alt="" className="tour-chat-arrow" />
          <TourIconBox
            image={tourFeedIcon}
            title={"Feed"}
            justifyClass={"justify-end"}
          />
          {/* <img src={feedBoxIcon} alt="" className="tour-chat-box-icon" /> */}
        </div>
      )}
      {tourState === 0 && (
        <div className="chat-tour-stage-3">
          <div className="chat-tour-content-box">
            <span className="chat-tour-content-box-heading chat-tour-heading-320">
              Home
            </span>
            <span className="chat-tour-content-body chat-tour-summary">
              View your plan, book health services, monitor your vital and
              listen to meditations at the comfort of your home.
            </span>

            <div
              className="chat-tour-next-button"
              onClick={() => {
                setTourState(1);
                dispatch(logEvent({ action: "click_tour_item_1" }));
              }}
            >
              Next
            </div>
          </div>

          <img src={arrowChat} alt="" className="tour-chat-arrow" />
          <TourIconBox
            image={tourHomeIcon}
            title={"Home"}
            justifyClass={"justify-start"}
          />
          {/* <img src={homeBoxIcon} alt="" className="tour-chat-box-icon-home" /> */}
        </div>
      )}

      {tourState === 4 && (
        <div className="show-tour-slide-main">
          <div className="show-tour-slide-container">
            <div className="show-tour-slide-container-box-1">
              <img src={yellowStar} alt="" className="first-yellow" />
              <img src={yellowStar} alt="" className="second-yellow" />
            </div>
            <div className="show-tour-slide-container-box-2">
              <p>Congratulations! Welcome to Max Fit.</p>
              <span>
                You have unlocked exciting new features. Let me introduce these
                to you.
              </span>
            </div>
            <div
              className="show-tour-slide-container-box-3"
              onClick={() => {
                setTourState(0);
                dispatch(logEvent({ action: "click_start_tour_l1" }));
              }}
            >
              <span>Start Tour</span>
              <img alt="" src={forwardArrow} />
            </div>
            <div
              className="show-tour-slide-container-box-4"
              onClick={() => {
                dispatch(updateStartTourShown());
                onClose();
              }}
            >
              I will explore on my own
            </div>
          </div>
        </div>
      )}
    </IonModal>
  );
}

const TourIconBox = ({ image, title, justifyClass }) => {
  return (
    <div
      className={`bg-white w-full absolute bottom-0 flex py-2 px-8 ${justifyClass}`}
    >
      <div className="flex flex-col items-center gap-y-1">
        <img src={image} className="w-5 h-5" />
        <p className="text-xs text-lightblack m-0">{title}</p>
      </div>
    </div>
  );
};
