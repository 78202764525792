import clevertapGlobal from "./clevertap.js";
// import { CleverTap } from "@ionic-native/clevertap";
import EventLoggerService from "./services/EventLoggerService.js";
import "firebase/analytics";
import "firebase/auth";
import firebase from "@firebase/app";

import "@capacitor-community/firebase-analytics";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

// const cleverTap = CleverTap;
class CleverTapLogger {
  constructor() {
    this.eventLoggerService = new EventLoggerService();
  }

  pushEvent = (event, payload) => {
    // this.eventLoggerService.log(event, payload);
  };

  push = (eventName, payload = null, user) => {
    // this.eventLoggerService.log(eventName, payload, user);

    if (window.Capacitor.platform === "web") {
      if (payload) {
        firebase.analytics().logEvent(eventName, {
          name: eventName,
          ...payload,
          platform: "web",
        });
      } else {
        firebase
          .analytics()
          .logEvent(eventName, { name: eventName, platform: "web" });
      }
    } else {
      if (payload) {
        FirebaseAnalytics.logEvent({
          name: eventName,
          params: { ...payload, pId: user?.patientProfile?.id },
        })
          .then((res) => {})
          .catch((err) => {});
      } else {
        FirebaseAnalytics.logEvent({
          name: eventName,
          params: { pId: user?.patientProfile?.id },
        })
          .then((res) => {})
          .catch((err) => {});
      }
    }
  };
  signUp = (user) => {
    try {
      if (window.Capacitor.platform === "web") {
        firebase.analytics().setUserProperties({
          user_id: user.patientProfile.id.toString(),
        });
      } else {
        FirebaseAnalytics.setUserId({ userId: String(user.patientProfile.id) })
          .then((res) => {})
          .catch((err) => {});
      }
    } catch (err) {}
  };
}

export default new CleverTapLogger();
