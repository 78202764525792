import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  medicineAddress: null,
};

export const medicineSlice = createSlice({
  name: "medicine",
  initialState: initialState,
  reducers: {
    setMedicineAddress: (state, action) => {
      state.medicineAddress = action.payload.address;
    },
  },
  extraReducers: {},
});

export const { setMedicineAddress } = medicineSlice.actions;
export default medicineSlice.reducer;
