import React from "react";
import { Dialog } from "@mui/material";
import RatingDialog from "./rating/ratingDialog";
import { IonModal } from "@ionic/react";
import IonModalWrapper from "../../../components/common/IonModalWrapper";

export default function Feedback({ showFeedback, onClose, user, errorRating }) {
  return (
    <IonModal
      cssClass={
        "rating-show-feedback-dialog-class ion-modal-general-class ion-modal-transparent-background"
      }
      backdropDismiss
      isOpen={showFeedback}
      onDidDismiss={onClose}
    >
      <IonModalWrapper>
        <RatingDialog
          handleClose={onClose}
          errorRating={errorRating}
          user={user}
        />
      </IonModalWrapper>
    </IonModal>
  );
}
