import React, { useState } from "react";
import Diagnosis from "../../../assets/images/shortTermGoals/1.png";
import FeelEnergetic from "../../../assets/images/shortTermGoals/2.png";
import RoutineChange from "../../../assets/images/shortTermGoals/3.png";
import { IonAlert } from "@ionic/react";
import Info from "../../../assets/images/info.png";

const ShortTermCard = ({ goal }) => {
  const [show, toggleShow] = useState(false);

  const details = () => {
    toggleShow(true);
  };

  const getImageSrcByName = (name) => {
    if (name === "diagnosis") {
      return Diagnosis;
    } else if (name === "feel_energetic") {
      return FeelEnergetic;
    } else if (name === "routine_changes") {
      return RoutineChange;
    }
  };

  return (
    <div className="short-term-card">
      {show && (
        <IonAlert
          cssClass="short-goal-desc"
          isOpen={true}
          onDidDismiss={() => toggleShow(false)}
          header={goal.title}
          message={goal.text}
          buttons={[
            {
              text: "GOT IT",
              handler: () => {
                toggleShow(false);
              },
            },
          ]}
        />
      )}
      <div className="short-term-details">
        <div className="short-data">
          <div className="upper-info-short">
            <div className="fw-semibold shot-name">{goal.title}</div>
            <div onClick={details}>
              <img className="info-icon" alt="" src={Info} />
            </div>
          </div>
        </div>
      </div>
      <img
        alt=""
        className="st-img"
        src={goal.imageURL ? goal.imageURL : getImageSrcByName(goal.tags[0])}
      />
    </div>
  );
};

export default ShortTermCard;
