import React, { useEffect, useState } from "react";

import Meditation from "../../meditation/components/meditation";
import "../../meditation/index.scss";
import "../home-page.scss";
import { IonSpinner } from "@ionic/react";
import meditationService from "../../../services/MeditationService";
import { useDispatch, useSelector } from "react-redux";
import { logEvent } from "../../../app/eventSlice";
import { useHistory } from "react-router";

function HomepageMeditation() {
  const patientProfile = useSelector(
    (store) => store.login.user?.patientProfile
  );
  const isPremium = useSelector((store) => store.tab.uiConfig.isPremium);
  const token = useSelector((store) => store.tab.docToken?.token);
  const [meditationMedia, setMeditationMedia] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
 
    setLoading(true);
    meditationService
      .getRecommendedMeditation(patientProfile?.id)
      .then((res) => {
        const response = res.data;
        setLoading(false);
        setMeditationMedia([{ ...response.recommended[0] }]);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <div className="font-bold text-base leading-5 text-lightblack meditation-heading-hp mt-8">
        <span>Recommended meditation</span>
        <button
          className="underline"
          onClick={() => {
            if (isPremium) {
              dispatch(logEvent({ action: "click_view_all_meditation" }));
            } else {
              dispatch(logEvent({ action: "click_unlock_all_meditation" }));
            }

            history.push("/meditation");
          }}
        >
          {isPremium ? "View all" : "View all"}
        </button>
      </div>
      <div className="meditation-container">
        {loading ? (
          <div className="loading-spinner-holder">
            <IonSpinner></IonSpinner>
          </div>
        ) : (
          <Meditation meditationMedia={meditationMedia} token={token} />
        )}
      </div>
    </div>
  );
}

export default HomepageMeditation;
