const IonModalWrapper = ({ children }) => {
  const handleClick = (e) => {
    if (e.target.id === "ion-modal-content-wrapper") {
      let ele = document.getElementsByTagName("ion-backdrop")[0];
      ele.click();
    }
  };
  return (
    <div
      className="ion-modal-content-wrapper"
      id="ion-modal-content-wrapper"
      onClick={handleClick}
    >
      {children}
    </div>
  );
};

export default IonModalWrapper;
