import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../app/axiosConfig";
import APIConfig from "../../services/APIConfig";

const initialState = {
  longTermGoals: [],
  shortTermGoals: [],
  ui: {
    error: null,
    loading: false,
    loaded: false,
  },
};

export const fetchLongTermGoalsApi = createAsyncThunk(
  "goal/fetchLongTermGoalsApi",
  async (data, { rejectWithValue }) => {
    try {
      const url = APIConfig.healthGoals.getLongTermGoals.format(data.patientId);
      const response = axiosClient.get(url);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchShortTermGoalsApi = createAsyncThunk(
  "goal/fetchShortTermGoalsApi",
  async (data, { rejectWithValue }) => {
    try {
      const url = APIConfig.healthGoals.getShortTermGoals.format(
        data.patientId
      );
      const response = axiosClient.get(url);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const goalSlice = createSlice({
  name: "goal",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchLongTermGoalsApi.pending]: (state, action) => {
      state.ui = {
        ...state.ui,
        loading: true,
        error: null,
      };
    },
    [fetchLongTermGoalsApi.rejected]: (state, action) => {
      state.ui = {
        ...state.ui,
        loading: false,
        loaded: true,
        error: action.payload?.data,
      };
    },
    [fetchLongTermGoalsApi.fulfilled]: (state, action) => {
      state.ui = {
        ...state.ui,
        loaded: true,
        loading: false,
        error: null,
      };

      state.longTermGoals = action.payload.data;
    },
    [fetchShortTermGoalsApi.pending]: (state, action) => {
      state.ui = {
        ...state.ui,
        loading: true,
        error: null,
      };
    },
    [fetchShortTermGoalsApi.rejected]: (state, action) => {
      state.ui = {
        ...state.ui,
        loading: false,
        error: action.payload?.data,
      };
    },
    [fetchShortTermGoalsApi.fulfilled]: (state, action) => {
      state.ui = {
        ...state.ui,
        loaded: true,
        loading: false,
        error: null,
      };

      state.shortTermGoals = action.payload.data;
    },
  },
});
export const {} = goalSlice.actions;
export default goalSlice.reducer;
