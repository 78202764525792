import { IonContent } from "@ionic/react";
import infoImage from "../../../assets/images/info.png";
import BackButton from "../../../components/common/BackButton";
import { stepathonAboutData } from "../stepathonSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function StepathonStepCard() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.stepathon.data);
  const status = useSelector((state) => state.stepathon.status);
  const patientProfile = useSelector(
    (state) => state.login?.user?.patientProfile
  );

  useEffect(() => {
    if (status === "idle") {
      dispatch(stepathonAboutData(patientProfile.id));
    }
  }, []);
  const backStyle = {
    height: "24px",
    width: "24px",
    marginRight: "12px",
  };
  console.log("status", status);
  let subHeading = "";
  if (data && data.data.mli_offer && data.data.mli_offer.length > 0) {
    subHeading = data.data.mli_offer[0].Title;
  }
  return (
    <>
      <div className="py-5 px-4 text-lightblack">
        <div className="flex items-center">
          <BackButton style={backStyle} />
          <div className="font-bold text-base leading-7 ">Challenges</div>
        </div>
        <div className=" flex font-bold  leading-7  tracking-{-2%} py-2 items-start justify-between">
          <span className="font-bold max-w-[70%]">{subHeading}</span>
          <br />
          <div className="flex items-center ">
            <Link to="/stepathonabout">
              <img src={infoImage} className="h-4 w-4 cursor-pointer " />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
