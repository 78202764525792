import React, { Component } from "react";
import { IonModal } from "@ionic/react";
import "./webview.scss";
import { withRouter } from "react-router";
import BackButton from "../common/BackButton";
import PDFViewer from "../pdfviewer";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { App } from "@capacitor/app";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import {
  getTruncatedName,
  getUrlWithToken,
  handleFileDownload,
} from "../../utils/helperMethods";
import { Device } from "@capacitor/device";

class WebView extends Component {
  constructor() {
    super();
    this.state = {
      url: "",
      title: "",
      format: "",
      visible: false,
      isWeb: true,
      showMsgDiv: false,
      currentMsg: "Download in progress",
      isPositive: true,
      showDownload: true,
      showDancingDots: false,
    };
  }
  componentDidMount() {
    document.addEventListener("ionBackButton", this.webViewHandleBack);
    this._updateState(this.props);
    try {
      let urlParams = new URLSearchParams(this.props.history.location.search);
      if (
        urlParams.has("pathName") &&
        urlParams.get("pathName") === "/tab/chat"
      ) {
        localStorage.setItem("zy.isWebview", "1");
      }
    } catch (err) {}
  }
  _updateState(props) {
    const { history } = props;
 
    Device.getInfo().then((r) => {
      this.setState({
        url: this.getQueryVariable(history.location.search, "url"),
        title: getTruncatedName(
          this.getQueryVariable(history.location.search, "title")
        ),
        format: this.getQueryVariable(history.location.search, "format"),
        visible: true,
        isWeb: r.platform === "web" ? true : false,
        showDownload: r.platform === "android" ? true : false,
      });
    });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.history.location.pathname === "/document-viewer") {
      // if document-viewer page is visible

      this._updateState(nextProps);
      this.setState({ visible: true });
    } else {
      this.setState({ visible: false });
    }
  }
  getQueryVariable(url, variable) {
    var query = url.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        let value = pair[1];
        for (var j = 2; j < pair.length; j++) {
          value = value + "=" + pair[j];
        }
        return value;
      }
    }
    return false;
  }
  handleRemoveMsgDiv = () => {
    setTimeout(() => {
      this.setState({ showMsgDiv: false, currentMsg: "", isPositive: false });
    }, 4000);
  };
  handleDownloadComplete = () => {
    this.setState(
      {
        showMsgDiv: true,
        currentMsg:
          "Download complete.check the download folder for the downloaded file.",
        isPositive: true,
        showDancingDots: false,
      },
      () => {
        this.handleRemoveMsgDiv();
      }
    );
  };
  handleDownloadFailure = () => {
    this.setState(
      {
        showMsgDiv: true,
        currentMsg: "Error while downloading.Please try again later.",
        isPositive: false,
        showDancingDots: false,
      },
      () => {
        this.handleRemoveMsgDiv();
      }
    );
  };
  webViewHandleBack = (ev) => {
    ev.detail.register(10000, () => {
      this.props.history.goBack();
    });
  };

  handleDidDismiss = () => {
    document.removeEventListener("ionBackButton", this.webViewHandleBack);
  };
  handleWebViewDownload = () => {
    this.setState(
      {
        showMsgDiv: true,
        isPositive: true,
        currentMsg: "Download in progress",
        showDancingDots: true,
      },
      () => {
        handleFileDownload(
          this.state.url,
          this.state.title,
          () => {
            this.handleDownloadComplete();
          },
          (err) => {
            this.handleDownloadFailure();
          }
        );
      }
    );
  };

  render() {
    const style = {
      backgroundColor: "white",
    };
    const {
      url,
      title,
      visible,
      isWeb,
      format,
      showMsgDiv,
      currentMsg,
      isPositive,
      showDancingDots,
      showDownload,
    } = this.state;

    const isRedirect = new URLSearchParams(this.props.location.search).get(
      "redirect"
    );
    let redr = false;
    if (isRedirect) {
      redr = true;
    }
    const ua = window.navigator.userAgent;
    const paytmFlag = /AppContainer/i.test(ua);

    return (
      <IonModal
        mode="ios"
        backdropDismiss={true}
        swipeToClose={false}
        isOpen={visible}
        onWillDismiss={this.handleDidDismiss}
      >
        <section className="webview-container">
          <header>
            <div className="back-button-wrapper">
              <BackButton
                isClose={!paytmFlag}
                history={this.props.history}
                redr={redr}
                isRedirect={isRedirect}
              ></BackButton>
            </div>
            <div className="view-title">{title}</div>
            {showDownload ? (
              <button
                onClick={this.handleWebViewDownload}
                className="download-button-icon"
              >
                <ArrowDownTrayIcon />
              </button>
            ) : (
              <a
                href={url}
                target="_blank"
                download={`${title}${Date.now()}`}
                className="download-button-icon"
              >
                <ArrowDownTrayIcon />
              </a>
            )}
          </header>
          {showMsgDiv && (
            <div
              className="webview-download-msg-div"
              style={{ backgroundColor: isPositive ? "#51ac95" : "#d0021b" }}
            >
              <p>{currentMsg}</p>
              {showDancingDots && (
                <div className="wave-animation-webview">
                  <span className="wave-animation-dot"></span>
                  <span className="wave-animation-dot"></span>
                  <span className="wave-animation-dot"></span>
                </div>
              )}
            </div>
          )}

          <main>
            {format === "4" && (
              <PDFViewer src={encodeURIComponent(url)}></PDFViewer>
            )}
            {(format === "1" || format === "2") && (
              <TransformWrapper
                defaultScale={1}
                options={{
                  limitToBounds: false,
                  limitToWrapper: true,
                  centerContent: true,
                }}
                scalePadding={{
                  size: 0.9,
                }}
                zoomIn={{
                  step: 250,
                }}
                doubleClick={{
                  disabled: false,
                  step: 250,
                }}
              >
                <TransformComponent>
                  <img src={url} />
                </TransformComponent>
              </TransformWrapper>
            )}
            {format === "5" && (
              <video
                controls
                autoPlay
                playsInline
                muted
                className="icon-card"
                id="custom-image"
                src={url}
              />
            )}
          </main>
        </section>
      </IonModal>
    );
  }
}

export default withRouter(WebView);
