import { IonContent, IonPage, IonModal } from "@ionic/react";
import React, { useEffect, useState } from "react";
import "./index.scss";
import BackButton from "../../components/common/BackButton";
import RequestCompleted from "./components/requestCompleted";
import PACompletedImage from "../../assets/icons/ic_completed_Success.png";
import PaymentDialog from "../../components/common/PaymentDialog";
import ZylaPay from "../../components/zylaPay/zylaPay";
import PayLoader from "../../components/PayLoader";
import walletService from "../../services/WalletServices";
import PremiumFAQS from "./components/premiumFAQs";
import PremiumPatientStories from "./components/premiumPatientStories";
import PremiumTCTCards from "./components/premiumTCTCards";
import PremiumBuyCard from "./components/premiumBuyCard";
import PremiumOutcomeCard from "./components/premiumOutcomeCard";
import PremiumDoctorSection from "./components/premiumDoctorSection";
import { useDispatch, useSelector } from "react-redux";
import {
  getOutcomes,
  getTestimonials,
  handlePremiumError,
  sendCallbackRequest,
} from "./premiumSlice";
import { useHistory } from "react-router";
import { logEvent } from "../../app/eventSlice";
import ErrorComponent from "../../components/common/ErrorComponent";
import { fetchGenericSetting, getUIConfig } from "../tab/tabSlice";
import { getAllMeditation } from "../meditation/meditationSlice";
import {
  setDefaultCountry,
  updateDefaultCountryCode,
} from "../../app/appSlice";

export default function PremiumPage() {
  const patientProfile = useSelector(
    (store) => store.login.user.patientProfile
  );

  const country = useSelector((store) => store.app.country.default);
  const isPremium = useSelector((store) => store.tab.uiConfig.isPremium);
  const isZylaZen = useSelector((store) => store.tab.uiConfig.isZylaZen);
  const { callbackRequested, ui } = useSelector((store) => store.premium);
  const [openzylaPay, setOpenzylaPay] = useState(false);
  const [openPaymentLoader, setOpenPaymentLoader] = useState(false);
  const [paymentLoaderOrderId, setPaymentLoadedOrderId] = useState("");
  const [localPremium, setLocalPremium] = useState(isPremium);
  const [openPayment, setOpenPayment] = useState(false);
  const [paymentResult, setPaymentResult] = useState(false);
  const { requestSent, error, errorMsg } = ui;
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOutcomes({ id: patientProfile.id }));
    dispatch(getTestimonials({ id: patientProfile.id }));
    dispatch(logEvent({ action: "open_program_purchase" }));
    if (!country) {
      dispatch(updateDefaultCountryCode({ patientProfile: patientProfile }));
    }
  }, []);

  const handleCompletion = (result, isCancel = false) => {
    if (isCancel) {
      setOpenzylaPay(false);
      setOpenPaymentLoader(false);
      return;
    }
    setOpenPayment(true);
    setPaymentResult(result);
    setOpenzylaPay(false);
    setOpenPaymentLoader(false);
    if (result) {
      // setShowBuyCard(false);
      setLocalPremium(true);
      dispatch(getUIConfig(patientProfile?.id));
      dispatch(getAllMeditation({ isPremium: true }));
    }
  };
  const hideModal = () => {
    setOpenPayment(false);
  };
  const startPayment = () => {
    let currency = "INR";
    if (country && country.currency) {
      currency = country.currency;
    }

    let fName = patientProfile?.firstName;
    let lName = patientProfile?.lastName;
    walletService
      .getOrderId({
        planId: parseInt(process.env.REACT_APP_RAZORPAY_PLAN_ID),

        currency: currency,
        customerId: `${patientProfile.id}`,
        customerName: `${fName ? fName : ""} ${lName ? lName : ""}`,
        customerPhoneNo: `${patientProfile.phoneno}`,
        customerEmail: `${patientProfile?.email}`,
        option: "EXTERNAL",
      })
      .then((result) => {
        const res = result.data;
        if (window.Capacitor.platform !== "web") {
          setTimeout(() => {
            let url = `${process.env.REACT_APP_zyla_PAY_URL}?orderId=${res.id}`;
            window.open(url, "_top");
            // setOpenPaymentLoader(true);
            // setPaymentLoadedOrderId(res.id);
          });
        }
        setPaymentLoadedOrderId(res.id);
        setOpenzylaPay(true);
      })
      .catch((err) => {
        dispatch(
          handlePremiumError({ value: true, msg: "Something went wrong." })
        );
      });
  };

  return (
    <IonPage>
      <IonContent className="premium-container">
        <ErrorComponent
          show={error}
          msg={errorMsg}
          errorClose={() =>
            dispatch(handlePremiumError({ value: false, msg: "" }))
          }
        />

        {!requestSent && (
          <div>
            <div className="premium-wrapper">
              <div className="back-button-wrapper-premium background-color-white-class">
                <BackButton isBlack></BackButton>
              </div>
              <div className="section-wrapper background-color-white-class">
                {!localPremium ? (
                  isZylaZen ? null : (
                    <h2>Manage health with zyla Premium.</h2>
                  )
                ) : null}
                {(localPremium || isZylaZen) && (
                  <h2>{`Congrats! You are zyla ${
                    isZylaZen ? "Zen member" : "Premium member"
                  }.`}</h2>
                )}
                <div
                  onClick={() =>
                    dispatch(logEvent({ action: "play_sales_video" }))
                  }
                  className="premium-page-iframe-container"
                >
                  <iframe
                    src="https://www.youtube.com/embed/ewNO6uedc3M"
                    width="560"
                    height="315"
                    title="testimony"
                  ></iframe>
                </div>
              </div>
              <PremiumTCTCards />
              <PremiumDoctorSection />
              <PremiumPatientStories />
              {!localPremium && (
                <PremiumBuyCard country={country} startPayment={startPayment} />
              )}
              <PremiumOutcomeCard />
              <PremiumFAQS />
              {callbackRequested === false ? (
                <button
                  slot="fixed"
                  className="request-callback"
                  onClick={() => {
                    dispatch(logEvent({ action: "click_schedule_callback" }));
                    dispatch(sendCallbackRequest({ id: patientProfile.id }));
                  }}
                >
                  Request a Callback
                </button>
              ) : (
                <button
                  slot="fixed"
                  className="request-callback"
                  id="callback-sent"
                  onClick={() => history.goBack()}
                >
                  <img src={PACompletedImage} alt="success-img" /> Call
                  Scheduled
                </button>
              )}
            </div>
          </div>
        )}
        {requestSent && <RequestCompleted></RequestCompleted>}
        {openPayment && (
          <IonModal
            cssClass="razor-pay-payment-modal premium-payment-sucessfull-modal"
            isOpen={true}
            animated={false}
          >
            <PaymentDialog result={paymentResult} hideModal={hideModal} />
          </IonModal>
        )}
        {/* {openPaymentLoader && (
          <IonModal
            cssClass="razor-pay-payment-modal"
            isOpen={openPaymentLoader}
            animated={false}
          >
            <PayLoader
              handleClose={handleCompletion}
              orderId={paymentLoaderOrderId}
            />
          </IonModal>
        )} */}
        {openzylaPay && (
          <ZylaPay
            orderId={paymentLoaderOrderId}
            openzylaPay={openzylaPay}
            handleClose={(result, isCancel = false) => {
              handleCompletion(result, isCancel);
            }}
          />
        )}
      </IonContent>
    </IonPage>
  );
}
