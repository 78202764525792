import React, { useEffect } from "react";
import { Drawer } from "@mui/material";
import SuccessCompleteIcon from "../../../assets/images/ic_completed_success.png";
import { useDispatch } from "react-redux";
import { logEvent } from "../../../app/eventSlice";
import { IonModal } from "@ionic/react";
export default function ReportingCompleteSuccess({ open, onClose, date }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logEvent({ action: "show_submit_reporting" }));
  }, []);

  return (
    <IonModal
      className={"menu-partial-full-height reporting-container"}
      anchor="bottom"
      isOpen={open}
      onDidDismiss={()=>onClose()}
     
    >
      <div className="reporting-success-page">
        <img src={SuccessCompleteIcon} alt="icon" />
        <p>Thank you for submitting your routine reporting for {date}</p>
      </div>
    </IonModal>
  );
}
