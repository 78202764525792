import React from "react";
import { useHistory } from "react-router";
import SubmitIcon from "../../../assets/images/ic_plus_button_primary_2.png";
import OrangeChevron from "../../../assets/icons/orange-circle-right-chevron.png";
import { vitalParams } from "../homeUtilityFunc";
import { useDispatch } from "react-redux";
import { logEvent } from "../../../app/eventSlice";

function VitalSubmission({ day, recommendedVital }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const getIcon = (unit) => {
    let vitals = vitalParams.find((vital) => vital.unit === unit);
    return vitals ? vitals.icon : vitalParams[0].icon;
  };
  const getName = (unit) => {
    let vitals = vitalParams.find((vital) => vital.unit === unit);
    return vitals?.name;
  };
  const redirectToVitalCharts = (unit) => {
    let selectedVital = vitalParams.find((vital) => vital.unit === unit);
    let id = selectedVital?.id;
    if (id === 9 || id === 10) {
      history.push(`/vitalsChart`);
    } else {
      history.push(`/vitalsChart?${id}`);
    }
  };

  return (
    <div
      className="daily-target-vital-wrapper"
      onClick={() => {
        if (day === 1) {
          return;
        }
        if (recommendedVital.value === null) {
          dispatch(logEvent({ action: "first_add-recommended_vital" }));
          history.push("/vitals");
        } else {
          dispatch(logEvent({ action: "click_add_recommended_vital" }));

          redirectToVitalCharts(recommendedVital?.unit?.name);
        }
      }}
    >
      <div className="daily-target-vital-submission">
        <div className="vital-submission-upper-div">
          <div className="upper-div-left">
            <img src={getIcon(recommendedVital.unit.name)} alt="icon" />
            <span>{getName(recommendedVital.unit.name)}</span>
          </div>
        </div>
        <div className="vital-submission-lower-div">
          <div className="lower-div-left">
            {recommendedVital.value === null ? (
              <span className="recommended-heading">RECOMMENDED VITAL</span>
            ) : (
              <span className="recommended-heading">
                {recommendedVital.name}
              </span>
            )}
            {recommendedVital.value === null ? (
              <span className="vital-name">{recommendedVital.name}</span>
            ) : (
              <div className="vital-name-container">
                <span className="vital-name-span">
                  {`${recommendedVital.value} ${recommendedVital.unit.name}`}

                  {recommendedVital.value && (
                    <div className="daily-target-range-badges-container">
                      {recommendedVital.inRange === true && (
                        <div className="daily-target-range-badges">
                          IN RANGE
                        </div>
                      )}
                      {recommendedVital.inRange === false && (
                        <div
                          className="daily-target-range-badges"
                          id="out-of-range"
                        >
                          OUT OF RANGE
                        </div>
                      )}
                    </div>
                  )}
                </span>
              </div>
            )}
          </div>
          {day !== 1 ? (
            <div className="lower-div-right">
              {recommendedVital.value === null && day !== 1 ? (
                <img src={SubmitIcon} alt="icon" />
              ) : (
                <img src={OrangeChevron} alt="icon" />
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default VitalSubmission;
