import "./cameraPreview.scss";
import { IonContent, IonModal, IonPage } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

import "@capacitor-community/camera-preview";
import PrepareUploadService from "../../services/PrepareUploadService";
import documentService from "../../services/DocumentService";
import { Drawer } from "@mui/material";
import { CameraPreview, CameraPreviewOptions } from '@capacitor-community/camera-preview';

export default function CameraPreviewComponent({
  onCloseCamera,

  onUploadStart,
  onUploadDone,

  patientId,
  isChatRoom,
  handleCameraStart,
  handleCameraStop,
  doNotUpload,
}) {
  const [imgCaptured, setImgCaptured] = useState(null);
  const [cameraVisible, setCameraVisible] = useState(false);
  const isFlash = useRef(false);
  const currentCamera = useRef("rear");
  useEffect(() => {
    document.addEventListener("ionBackButton", handleCameraButtonBack);
    handleCameraPreviewStart();

    return () => {
      document.removeEventListener("ionBackButton", handleCameraButtonBack);
    };
  }, []);

  useEffect(() => {
    if (imgCaptured) {
      handleCameraStopped();
    }
  }, [imgCaptured]);

  const handleCameraPreviewStart = () => {
    const cameraPreviewOptions = {
      position: currentCamera.current, // front or rear
      parent: "camera-preview-global-content", // the id on the ion-content
      className: "",
      // width: window.screen.width - 200, //width of the camera display
      height: window.screen.height - 200, //height of the camera
      toBack: false,
    };
    CameraPreview.start(cameraPreviewOptions)
      .then((res) => {
        setCameraVisible(true);
      })
      .catch((err) => {
        onCloseCamera(false);
      });
  };

  const handleCameraStopped = async () => {
    await CameraPreview.stop();
  };

  const handleCameraButtonBack = (ev) => {
    console.log("back button called 1");
    ev.detail.register(10000, async () => {
      await CameraPreview.stop();
      console.log("back button called");
      onCloseCamera(false);
    });
  };

  const handleFlipCamera = async () => {
    if (currentCamera.current === "rear") {
      currentCamera.current = "front";
    } else {
      currentCamera.current = "rear";
    }
    await CameraPreview.flip();
  };
  const handleStartFlash = () => {
    if (isFlash.current) {
      CameraPreview.setFlashMode({ flashMode: "off" });
    } else {
      CameraPreview.setFlashMode({ flashMode: "on" });
    }
  };

  const handleImageCapture = async () => {
    const cameraPreviewPictureOptions = {
      quality: 100,
      width: window.screen.width,
      height: window.screen.height,
    };
    const result = await CameraPreview.capture(cameraPreviewPictureOptions);
    setImgCaptured(`data:image/jpeg;base64,${result.value}`);
    setCameraVisible(false);
  };

  const handleImageCapturedOk = () => {};

  const handleImageCapturedCancel = () => {
    setImgCaptured(null);
    handleCameraPreviewStart();
  };

  const sendPicture = async () => {
    let url = imgCaptured;
    const tempResult = await fetch(url);
    console.log("temp blob is ", tempResult);
    const finalblob = await tempResult.blob();
    let fileReq = {
      id: "",
      title: "",
      description: "",
      url: "",
      type: 6,
      format: 1,
      status: 4,
      file: finalblob,
    };

    console.log("final blob is ", finalblob);

    if (doNotUpload) {
      onUploadDone && onUploadDone(fileReq);
      return;
    }

    if (fileReq !== null) {
      if (isChatRoom) {
        handleCameraStart();

        if (fileReq?.file.size > 20000000) {
          handleCameraStop({
            status: "F",
            result: "This file is too big. maximum allowed file size is 20MB",
          });
          return;
        }
      } else {
        onUploadStart && onUploadStart();
      }

      documentService
        .uploadDocument(fileReq.file, fileReq.name, fileReq, null, patientId)
        .then((response) => {
          const data = response.data;
          if (isChatRoom) {
            handleCameraStop({ status: "S", result: data });
          } else {
            onUploadDone && onUploadDone({ status: "S", result: data });
          }
        })
        .catch((error) => {
          if (isChatRoom) {
            handleCameraStop({ status: "F", result: error });
          } else {
            onUploadDone && onUploadDone({ status: "F", result: error });
          }
        });
    } else {
      if (isChatRoom) {
        handleCameraStop({
          status: "F",
          result: "Image is null",
        });
      } else {
        onUploadDone && onUploadDone({ status: "F", result: "Image is null" });
      }
    }
  };

  return (
    <IonModal isOpen={true} cssClass={"myIonModalClass"}>
      <IonContent
        id="camera-preview-global-content"
        style={{
          "--background": "transparent",
        }}
        className="myIonModalClass"
      >
        {!imgCaptured && (
          <div className="camera-preview-button-container">
            <button onClick={handleStartFlash} disabled={!cameraVisible}>
              flash
            </button>
            <button onClick={handleImageCapture} disabled={!cameraVisible}>
              Capture
            </button>
            <button onClick={handleFlipCamera} disabled={!cameraVisible}>
              Flip
            </button>
          </div>
        )}
        {imgCaptured && (
          <div className="camera-preview-captured-image-container">
            <div>
              <img src={imgCaptured} />
              <div className="camera-preview-captured-image-container-buttons">
                <button onClick={sendPicture}>ok</button>
                <button onClick={handleImageCapturedCancel}>Cancel</button>
              </div>
            </div>
          </div>
        )}
      </IonContent>
    </IonModal>
  );
}
