import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { logEventWithData } from "../../../app/eventSlice";
import "./home2.0.scss";
function MembershipBanner() {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div>
        <div
          className="freemium-membership-banner membership-banner-common"
        >
          <span>{`Max Fit is powered by Zyla Health`}</span>
        </div>
      
    </div>
  );
}

export default MembershipBanner;
