import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosClient from "../../app/axiosConfig";
import APIConfig from "../../services/APIConfig";
import userService from "../../services/UserService";

const initialState = {
  callbackRequested: false,
  outcomes: [],
  testimonials: [],

  ui: {
    requestSent: false,
    error: false,
    errorMsg: "",
  },
};

export const getOutcomes = createAsyncThunk(
  "premium/outcomes",
  async (data, { rejectWithValue }) => {
    try {
      const { id } = data;
      let url = APIConfig.premium.outcomes.format(id);
      const response = await axios.get(url, { headers: { origin: "*" } });
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getTestimonials = createAsyncThunk(
  "premium/testimonials",
  async (data, { rejectWithValue }) => {
    try {
      const { id } = data;
      let url = APIConfig.premium.testimonials.format(id);
      const response = axios.get(url, { headers: { origin: "*" } });
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const sendCallbackRequest = createAsyncThunk(
  "premium/callback",
  async (data, { rejectWithValue }) => {
    try {
      const { id } = data;
      const response = await userService.requestCallBack(id);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const premiumSlice = createSlice({
  name: "premium",
  initialState: initialState,
  reducers: {
    toggleRequestSent: (state, action) => {
      state.ui.requestSent = false;
    },
    handlePremiumError: (state, action) => {
      state.ui = {
        ...state.ui,
        error: action.payload.value,
        errorMsg: action.payload.msg,
      };
    },
  },
  extraReducers: {
    [getOutcomes.fulfilled]: (state, action) => {
      const response = action.payload.data;

      state.outcomes = response.data[0].outcomes;
    },

    [getTestimonials.fulfilled]: (state, action) => {
      const response = action.payload.data;

      state.testimonials = response.testimonials;
    },
    [sendCallbackRequest.fulfilled]: (state, action) => {
      return {
        ...state,
        callbackRequested: true,
        ui: {
          ...state.ui,
          requestSent: true,
        },
      };
    },
  },
});

export const { toggleRequestSent, handlePremiumError } = premiumSlice.actions;
export default premiumSlice.reducer;
