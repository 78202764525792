import React, { useEffect, useRef, useState } from "react";
import DocumentList from "./components/documentList";
import "../../assets/css/doc.scss";
import { useHistory, useLocation, useParams } from "react-router";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonSpinner,
  IonLoading,
} from "@ionic/react";
import NoDocumentFolderExists from "./components/noDocumentFolderExists";
import UploadDocument from "./components/uploadDocument";
import BackButton from "../../components/common/BackButton";
import { useDispatch, useSelector } from "react-redux";
import { getAllDocuments } from "./documentSlice";
import { sendMessage } from "../chat/chatSlice";
import ErrorComponent from "../../components/common/ErrorComponent";
import { logEventWithData } from "../../app/eventSlice";
import PrepareUploadService from "../../services/PrepareUploadService";
import documentService from "../../services/DocumentService";
import { DocumentChooseAndUpload } from "./components/documentChooseAndUpload";
export default function DocumentContainer() {
  const patient = useSelector((store) => store.login.user?.patientProfile);
  const paytmFlag = useSelector((store) => store.app.device.isPaytm);
  const chat = useSelector((state) => state.tab.chat);
  const { documents, documentsFetched } = useSelector(
    (store) => store.documents
  );
  const [uploadLoading, setUploadLoading] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [currentPatientId, setCurrentPatientId] = useState(null);
  const mpId = useRef(null);
  const docUploader = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    let { state: locationState } = location;
    if (locationState && locationState.isMember) {
      mpId.current = locationState.memberPatientId;
    } else {
      mpId.current = null;
    }
    const pid = mpId.current ? mpId.current : patient?.id;
    if (currentPatientId != pid) {
      setLoading(true);
    }
    if (documentsFetched) {
      dispatch(
        getAllDocuments({ id: mpId.current ? mpId.current : patient?.id })
      )
        .unwrap()
        .then((res) => {
          setLoaded(res.data && res.data.length > 0);
          setLoading(false);
          setCurrentPatientId(pid);
        })
        .catch((err) => {
          setError(true);
          setLoading(false);
          setLoaded(false);
          setCurrentPatientId(pid);
          setErrorMsg("Error while fetching documents.");
        });
    } else {
      setLoading(true);
      setLoaded(false);
      dispatch(
        getAllDocuments({ id: mpId.current ? mpId.current : patient?.id })
      )
        .unwrap()
        .then((res) => {
          setLoading(false);
          setCurrentPatientId(pid);
          setLoaded(res.data && res.data.length > 0);
        })
        .catch((err) => {
          setError(true);
          setLoading(false);
          setLoaded(false);
          setCurrentPatientId(pid);
          setErrorMsg("Error while fetching documents.");
        });
    }
  }, [location]);

  const onClickFolder = (document) => {
    dispatch(
      logEventWithData({
        action: `click_document_folder_${document.title}`,
        data: { isSelf: mpId.current ? false : true, app: "mli" },
      })
    );
    if (mpId.current) {
      history.push({
        pathname: `/documents/${document.type}`,
        state: {
          isMember: true,
          memberPatientId: mpId.current,
        }, // your data array of objects
      });
    } else {
      history.push(`/documents/${document.type}`);
    }
  };
  const onClickUpload = () => {
    if (window.Capacitor.platform !== "web") {
      setShowUpload(true);
    } else {
      docUploader.current.click();
    }
  };
  const uploadFileToServer = (e, errors = {}) => {
    if (errors.sizeError) {
      onUploadDone({
        status: "F",
        result: "File size should be less than 20MB",
      });
    }
    const file = e.target.files[0];

    setUploadLoading(true);
    let fileReq = PrepareUploadService.buildDocumentFromFile(file);
    if (fileReq === null) {
      return;
    }

    if (file.size <= 20000000) {
      documentService
        .uploadDocument(
          file.file,
          file.name,
          fileReq,
          undefined,
          mpId.current ? mpId.current : patient.id
        )
        .then((response) => {
          const data = response.data;
          if (onUploadDone) {
            onUploadDone({ status: "S", result: data });
          }
        })
        .catch((error) => {
          if (onUploadDone) {
            onUploadDone({ status: "F", result: error });
          }
        });
    } else {
      onUploadDone({
        status: "F",
        result: "File size should be less than 20MB",
      });
    }
  };

  const onUploadDone = (response) => {
    if (response && response.status === "F") {
      setError(true);
      setErrorMsg("File size should be less than 20MB");
      setShowUpload(false);
      return;
    }
    if (mpId.current) {
      setUploadLoading(false);
      dispatch(getAllDocuments({ id: mpId.current }));

      return;
    }

    if (response.status === "S") {
      let message = {
        text: response.result.id,
        type: response.result.format === 4 ? 3 : 2,
        id: "" + new Date().getTime() + Math.random(),
        metadata: {
          id: response.result.id,
        },
      };
      dispatch(
        sendMessage({
          content: {
            message: message,
          },
          roomId: chat.chatRoom,
          userId: chat.externalUserIdSt,
        })
      );
      dispatch(getAllDocuments({ id: patient.id }));
    }
    setUploadLoading(false);
  };

  return (
    <IonPage className="doc-container">
      <IonContent>
        <ErrorComponent
          show={error}
          msg={errorMsg}
          errorClose={() => {
            setError(false);
            setErrorMsg("");
          }}
        />
        <IonHeader>
          <div className="document-header-div">
            <IonButtons slot="start">
              <div className="button-wrapper">
                {!paytmFlag && <BackButton history={history}></BackButton>}
              </div>
            </IonButtons>

            <div className="document-header-upload-document-wrapper">
              <p>Documents</p>
            </div>
          </div>
        </IonHeader>
        <IonLoading
          isOpen={uploadLoading}
          message={"Uploading"}
          duration={50000}
        />
        <UploadDocument
          ref={docUploader}
          type="application/pdf,image/jpg,image/jpeg,image/png,image/gif"
          onUploadDone={(response) => onUploadDone(response)}
          onUploadStart={() => setUploadLoading(true)}
          pId={mpId.current ? mpId.current : patient.id}
        ></UploadDocument>
        {loading ? (
          <div className="loading-spinner-holder">
            <IonSpinner />
          </div>
        ) : documents && documents.ids && documents.ids.length > 0 ? (
          <DocumentList
            onClickFolder={(document) => onClickFolder(document)}
          ></DocumentList>
        ) : (
          <NoDocumentFolderExists
            onClickUpload={() => onClickUpload()}
          ></NoDocumentFolderExists>
        )}
        <DocumentChooseAndUpload
          onUploadStart={() => {
            setUploadLoading(true);
            setShowUpload(false);
          }}
          onUploadDone={onUploadDone}
          fileChooser={showUpload}
          hideFileTypeOption={() => {
            setShowUpload(false);
            setUploadLoading(false);
          }}
          patientId={mpId.current ? mpId.current : patient.id}
        ></DocumentChooseAndUpload>
      </IonContent>
    </IonPage>
  );
}
