import {
  IonPage,
  IonContent,
  IonProgressBar,
  useIonViewWillEnter,
} from "@ionic/react";
import { CircularProgress } from "@mui/material";
import { useLocation, useParams } from "react-router";
import { rewardData, setDefaultPolicy } from "./rewardSlice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { SewaSection } from "./component/sewa/SewaSection";
import { RewardPolicyMenu } from "./component/RewardPolicyMenu";
import { CidrSection } from "./component/cidr/CidrSection";
export default function RewardPointPage() {
  const data = useSelector((state) => state.reward?.data);
  const cidrReward = data?.data?.cidrRewards?.cidr;
  const sewaReward = data?.data?.sewaRewards?.sewa;
  const firstKey = Object.keys(sewaReward || {})[0];
  const secondKey = Object.keys(sewaReward || {})[1];
  // let policies = Object.keys(sewaReward || {});
  // policies = policies.concat(Object.keys(cidrReward || {}));
  // console.log("policcies", policies);
  // let policies = Object.keys(sewaReward || {});
  // policies = [...policies, ...Object.keys(cidrReward || {})];
  // console.log("policies", policies);

  const [SelectedIndex, SetSelectedIndex] = useState(null);
  const location = useLocation();
  const firstKeyData = sewaReward ? sewaReward[firstKey] : null;
  const secondKeyData = sewaReward ? sewaReward[secondKey] : null;

  const [selectedPolicy, setSelectedPolicy] = useState(null);

  const status = useSelector((state) => state.reward.status);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const patientProfile = useSelector(
    (state) => state.login?.user?.patientProfile
  );
  // const patientPhone = useSelector(
  //   (state) => state.login?.user?.patientProfile.phoneno
  // );
  // console.log("id:", patientPhone);

  useEffect(() => {
    dispatch(rewardData(patientProfile.id));
    // dispatch(setDefaultPolicy(firstKey));
  }, []);

  useIonViewWillEnter(() => {
    const urlParams = new URLSearchParams(location.search);
    const paramValue = urlParams.get("selectedIndex");

    if (paramValue && !isNaN(paramValue)) {
      SetSelectedIndex(paramValue);
    }
  }, []);

  return (
    <>
      <IonPage>
        <IonContent>
          {loading ? (
            <div className="relative flex items-center justify-center h-full">
              <CircularProgress />
            </div>
          ) : (
            <div className="flex flex-col px-4 py-4 gap-y-6">
              {data && (
                <RewardPolicyMenu data={data} setPolicy={setSelectedPolicy} />
              )}
              {selectedPolicy && selectedPolicy.type === "SEWA" ? (
                <SewaSection selectedPolicy={selectedPolicy} />
              ) : (
                <CidrSection selectedPolicy={selectedPolicy} />
              )}
            </div>
            // <div className="p-6">
            //   <BackButton />
            // <div className="text-center mt-12 text-xs">

            //   <img src={timeImage} className="mb-6 w-36 h-auto"/>
            //   <p>Your rewards are getting ready and will be accessible soon!</p>
            //   <p>Till then continue your wellness journey with Max Fit.</p>
            // </div>
            // </div>
          )}
        </IonContent>
      </IonPage>
    </>
  );
}
