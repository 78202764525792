import React, { Fragment } from "react";
import BMI from "../../assets/images/bmi.png";
import paConstants from "./paConstants";
import bmiConstants from "./bmiConstants";
import { IonDatetime } from "@ionic/react";
import moment from "moment";

const timeNow = "1970-01-01";
export default class TypeTwoQuestion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      //all have different views!
      bmi: "",
      weight: "",
      height: {
        // dynamic fields here
        // height_ft
        // height_in
      },
      sugarLevel: {
        //dynamic fields here
        // hba1c
        // fasting_blood_sugar
        // pp_blood_sugar
      },
      weightFields: [{ tag: "weight", text: "weight" }],
      heightFields: [
        { text: "ft", tag: "height_ft" },
        { text: "in", tag: "height_in" },
      ],
      sugarLevelFields: [
        { text: "HbA1c", tag: "hba1c" },
        { text: "Fasting blood sugar", tag: "fasting_blood_sugar" },
        { text: "PP (2 hours after lunch)", tag: "pp_blood_sugar" },
      ],
      bmiFields: [{ text: "BMI", tag: "bmi" }],
      dob: null,
    };
  }

  componentDidMount() {
    this.setDataToView();
  }

  componentDidUpdate(prevProps) {
    const { currentQuestion } = this.props;
    if (prevProps.currentQuestion.id !== currentQuestion.id) {
      this.setDataToView();
    }
  }

  setDataToView = () => {
    const { currentQuestion, updateAnswerTag, height, weight } = this.props;
    if (currentQuestion.id === 4) {
      this.setState({
        weight: currentQuestion.answer,
      });
      updateAnswerTag(currentQuestion.answer);
    } else if (currentQuestion.id === 5) {
      if (currentQuestion.answer) {
        let inches = parseFloat(currentQuestion.answer / 2.54);
        let foot = Math.round(Math.floor(inches / 12));
        let heightInch = Math.round(Math.floor(inches % 12));

        const answerHeight = [foot, heightInch];
        this.state.heightFields.forEach((field, index) => {
          setTimeout(() => {
            this.setState({
              height: {
                ...this.state.height,
                [String(field.tag)]: answerHeight[index],
              },
            });
          }, 0);
        });
      }
      updateAnswerTag(currentQuestion.answer);
    } else if (currentQuestion.id === 6) {
      let heightInMeter = 1;
      let weightInKg = 1;
      let bmi;

      weightInKg = weight ? Number(weight) : 1;
      let heightAnswer = height ? height : 100;

      heightInMeter = heightAnswer / 100;

      bmi = Number(weightInKg / (heightInMeter * heightInMeter)).toFixed(2);

      this.setState({
        bmi,
      });
      updateAnswerTag(bmi);
    } else if (currentQuestion.type === 4) {
      //optional Case
      if (currentQuestion.answer) {
        const answerSugarLevel = currentQuestion.answer.split(":");
        currentQuestion.fields.forEach((field, index) => {
          setTimeout(() => {
            this.setState({
              sugarLevel: {
                ...this.state.sugarLevel,
                [String(field.tag)]:
                  answerSugarLevel[index] === "0"
                    ? ""
                    : answerSugarLevel[index],
              },
            });
          }, 0);
        });
        updateAnswerTag(currentQuestion.answer);
      } else {
        let emptyAnswer = "0";
        let fieldCount = currentQuestion.fields.length;
        for (let i = 0; i < fieldCount - 1; i++) {
          emptyAnswer += ":";
        }
        updateAnswerTag(emptyAnswer);
      }
    } else if (currentQuestion.id === 3) {
      if (currentQuestion.answer) {
        let dob = moment(currentQuestion.answer, "DDMMYYYYY").format(
          "YYYY-MM-DD"
        );
        this.setState({
          dob,
        });
        updateAnswerTag(currentQuestion.answer);
      } else {
        this.setState({
          dob: timeNow,
        });
        updateAnswerTag(timeNow);
      }
    }
  };

  handleDateChanged = (e) => {
    const { updateAnswerTag } = this.props;

    let dob = e.target.value;
    if (dob.length === 10) {
    } else if (dob.length === 8) {
      dob = moment(dob, "YYYY-MM-DD");
    } else {
      dob = moment(dob).format("YYYY-MM-DD");
    }

    updateAnswerTag(moment(dob, "YYYY-MM-DD").format("DDMMYYYY"));
    this.setState({
      dob: dob,
    });
  };

  handleWeightChange = (e) => {
    const { updateAnswerTag } = this.props;
    this.setState({
      weight: e.target.value,
    });
    updateAnswerTag(e.target.value);
  };

  dobView = () => {
    const { currentQuestion } = this.props;
    const { dob } = this.state;
    // return currentQuestion.fields.map((field) => {
    return (
      <Fragment>
        <IonDatetime
          ref={this.datePicker}
          value={dob}
          onIonFocus={this.handleDateChanged}
          onIonChange={this.handleDateChanged}
          displayFormat="DD MMM YYYY"
          monthShortNames="Jan, Feb, Mar, Apr, May, June, July, Aug, Sep, Oct, Nov, Dec"
          placeholder="10/10/1980"
        />
        <img className="dob-image" alt="dob" src={currentQuestion.image} />
      </Fragment>
    );
    // });
  };

  weightView = () => {
    const { currentQuestion } = this.props;
    const fields = [{ tag: "weight", text: "weight" }];
    return fields.map((field) => {
      return (
        <div>
          <div className="weight-input-data">
            <input
              value={this.state[field.tag]} //this.state[fiels.name] is weight
              onChange={this.handleWeightChange}
              type="tel"
              maxLength={3}
              placeholder={"78"}
              className="common-field"
            />
            <span className="holder-text fw-semibold">Kg</span>
          </div>
          <img className="dob-image" alt="" src={currentQuestion.image} />
        </div>
      );
    });
  };

  handleHeightChange = (e, fieldName) => {
    const { updateAnswerTag } = this.props;
    const { height } = this.state;

    this.setState(
      {
        height: {
          ...height,
          [fieldName]: e.target.value,
        },
      },
      () => {
        const { height } = this.state;

        let answerTag = "";
        this.state.heightFields.forEach((field, index) => {
          let isLast = index + 1 === this.state.heightFields.length;
          answerTag += height[field.tag] + (isLast ? "" : ":");
        });
        let values = answerTag.split(":");
        let heightInCm = 0;

        if (
          values[0] === "" ||
          values[0] === "undefined" ||
          values[0] > 8 ||
          values[0] === 0 ||
          isNaN(values[0])
        ) {
          heightInCm = 0;
        } else {
          heightInCm = parseInt(values[0].trim()) * 12 * 2.54;
          if (
            values[1] === "" ||
            values[1] === "undefined" ||
            isNaN(parseInt(values[1])) ||
            values[1] > 12 ||
            values[1] < 0 ||
            isNaN(values[1])
          ) {
            heightInCm = 0;
          } else {
            heightInCm += parseInt(values[1].trim()) * 2.54;
          }
        }

        updateAnswerTag(`${heightInCm}`);
      }
    );
  };

  heightView = () => {
    const { currentQuestion } = this.props;
    const { height } = this.state;
    const fields = [
      { text: "ft", tag: "height_ft" },
      { text: "in", tag: "height_in" },
    ];
    return (
      <div className="height-input-data">
        {fields.map((field) => {
          let placeHolder = field.tag === "height_ft" ? "5" : "6";
          let mexLength = field.tag === "height_ft" ? 1 : 2;
          let maxValue = field.tag === "height_ft" ? 8 : 11;

          return (
            <div className="height-content">
              <input
                onChange={(e) => this.handleHeightChange(e, field.tag)}
                value={height[field.tag] ? height[field.tag] : ""}
                type="tel"
                maxLength={mexLength}
                placeholder={placeHolder}
                max={maxValue}
                className="common-field"
              />
              <span className="holder-text fw-semibold">{field.text}</span>
            </div>
          );
        })}
        <img className="dob-image" alt="dob" src={currentQuestion.image} />
      </div>
    );
  };

  getBmiString = (bmi) => {
    if (bmi > 15) {
      if (bmi >= 15 && bmi < 16) {
        return bmiConstants.SEVERELY_UNDERWEIGHT;
      } else if (bmi >= 16 && bmi < 18.5) {
        return bmiConstants.UNDERWEIGHT;
      } else if (bmi >= 18.5 && bmi < 25) {
        return bmiConstants.HEALTHY_WEIGHT;
      } else if (bmi >= 25 && bmi < 30) {
        return bmiConstants.OVERWEIGHT;
      } else if (bmi >= 30 && bmi < 35) {
        return bmiConstants.MODERATELY_OBESE;
      } else if (bmi >= 35) {
        return bmiConstants.SEVERELY_OBESE;
      }
    } else {
      return bmiConstants.SEVERELY_UNDERWEIGHT;
    }
  };

  bmiView = () => {
    const { bmi } = this.state;
    const sudoBmi = Number(bmi) - 15;
    const scaleHeight = 250;
    let scaleMargin = (sudoBmi / 25) * scaleHeight;

    if (scaleMargin < 0) {
      scaleMargin = 0;
    } else if (scaleMargin > scaleHeight) {
      scaleMargin = scaleHeight;
    }

    return (
      <div className="bmi-container">
        <div className="bmi-content">
          <div style={{ top: `${scaleMargin}px` }} className="bmi-scale">
            <div className="details-bmi fs12 fw-semibold">
              <div className="fs20">{bmi}</div>
              <div style={{ fontSize: "11px" }}>{this.getBmiString(bmi)}</div>
            </div>
          </div>
          <img style={{ height: `${scaleHeight}px` }} alt="bmi" src={BMI} />
        </div>
      </div>
    );
  };
  isNumber(v) {
    return v === "" || v == parseInt(v, 10) || v == parseFloat(v);
  }
  handleSugarLevelChange = (e, fieldName) => {
    const { sugarLevel } = this.state;

    if (this.isNumber(e.target.value)) {
      this.setState(
        {
          sugarLevel: {
            ...sugarLevel,
            [fieldName]: e.target.value,
          },
        },
        () => {
          const { updateAnswerTag, currentQuestion } = this.props;
          const { sugarLevel } = this.state;

          let answerTag = "";
          currentQuestion.fields.forEach((field, index) => {
            let isLast = index + 1 === currentQuestion.fields.length;
            answerTag +=
              (sugarLevel[field.tag] ? sugarLevel[field.tag] : "0") +
              (isLast ? "" : ":");
          });
          updateAnswerTag(answerTag);
        }
      );
    }
  };

  sugarLevelView = () => {
    const { currentQuestion } = this.props;
    const { sugarLevel } = this.state;
    return currentQuestion.fields.map((field) => {
      let placeHolder = "";
      let maxSize = 0;
      switch (field.tag) {
        case "hba1c":
          placeHolder = "eg. 8.5";
          maxSize = 6;
          break;
        case "fasting_blood_sugar":
          placeHolder = "eg. 140";
          maxSize = 3;
          break;
        case "pp_blood_sugar":
          placeHolder = "eg. 220";
          maxSize = 3;
          break;
        case "kidney_reading":
          placeHolder = "eg. 0.8";
          maxSize = 3;
          break;
        case "diabetes_hb1c":
          placeHolder = "eg. 8.4";
          maxSize = 3;
          break;
        default:
      }

      return (
        <div
          style={{ textAlign: "left" }}
          className={`sugar-level-content ${
            currentQuestion.fields.length < 2 ? "single-input-type-4" : ""
          }`}
        >
          <div className="sugar-text fs12 fw-semibold">{field.text}</div>
          <input
            value={sugarLevel[field.tag] ? sugarLevel[field.tag] : ""}
            placeholder={placeHolder}
            type="text"
            step="any"
            max={maxSize === 3 ? "999.99" : "99999.99"}
            min="0.000"
            onChange={(e) => this.handleSugarLevelChange(e, field.tag)}
            className="common-field"
            onKeyDown={(e) => e.keyCode === 69 && e.preventDefault()}
          />
        </div>
      );
    });
  };

  getViewBasedOnTag = () => {
    const { currentQuestion } = this.props;
    if (currentQuestion.id === 3) {
      return this.dobView();
    } else if (currentQuestion.id === 4) {
      return this.weightView();
    } else if (currentQuestion.id === 5) {
      return this.heightView();
    } else if (currentQuestion.id === 6) {
      return this.bmiView();
    } else if (currentQuestion.type === 4) {
      return this.sugarLevelView();
    }
  };

  render() {
    const { currentQuestion, hasError, error } = this.props;
    let cls =
      currentQuestion.tag === paConstants.sugarLevel
        ? "question-field question-field-sugar text-center"
        : "question-field text-center";
    let questionTextCls =
      currentQuestion.tag === paConstants.sugarLevel ? "" : "text-center";
    return (
      <div
        className="current-question"
        onAnimationEnd={() => {
          this.setState({
            hasError: false,
            hasSuccess: false,
          });
        }}
      >
        {hasError && (
          <div className="error-box">
            <span>{error}</span>
          </div>
        )}

        <div className={`fw-semibold question-text  ${questionTextCls}`}>
          {currentQuestion.text}
        </div>
        <div className={cls}>{this.getViewBasedOnTag()}</div>
      </div>
    );
  }
}
