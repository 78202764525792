import { Dialog } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import walletService from "../../services/WalletServices";
import closeIcon from "../../assets/icons/ic_close.svg";
import "./zylaPay.scss";

import { CircularProgress } from "@mui/material";
import { IonModal } from "@ionic/react";

function ZylaPay({ orderId, handleClose, openZylaPay }) {
  const [timer, setTimer] = useState({ timing: 300, minutes: "", seconds: "" });
  const [shouldCancel, setShouldCancel] = useState(false);
  const timerInterval = useRef(null);
  const statusCallTimeout = useRef(null);

  useEffect(() => {
    // console.log("order id is ", orderId);
    if (window.Capacitor.platform === "web") {
      zylaPayWebHandler();
    } else {
      zylaPayMobileHandler();
    }
    return () => {
      return () => {
        if (typeof window !== "undefined") {
          window.removeEventListener("message", handleIframMessage);
        }
        if (
          typeof window !== "undefined" &&
          timerInterval !== null &&
          timerInterval.current !== null
        ) {
          clearInterval(timerInterval.current);
        }
      };
    };
  }, []);

  useEffect(() => {
    document.addEventListener("ionBackButton", mediHandleBack);

    return () => {
      document.removeEventListener("ionBackButton", mediHandleBack);
    };
  }, []);
  const mediHandleBack = useCallback((ev) => {
    ev.detail.register(10000, () => {
      handleClosePayLoader()
    });
  }, []);

  useEffect(() => {
    if (timer.timing === 0) {
      handleGoBackFunc(false);
    }
  }, [timer]);

  const zylaPayMobileHandler = () => {
    timerInterval.current = setInterval(() => {
      setTimer((prevState) => {
        let newTimings = prevState.timing - 1;
        let sec = newTimings % 60;
        // console.log("new Timings is ", prevState, newTimings);
        return {
          timing: newTimings,
          minutes: parseInt(newTimings / 60),
          seconds: sec < 10 ? `0${sec}` : sec,
        };
      });
    }, 1000);
    checkStatusAndRedirect();
  };

  const zylaPayWebHandler = useCallback(() => {
    window.addEventListener("message", handleIframMessage);
  }, []);

  const handleIframMessage = (e) => {
    if (
      e.origin === process.env.REACT_APP_ZYLA_PAY_URL &&
      e.data &&
      e.data.origin === "ZPAY"
    ) {
      if (e.data.status === "cancelled") {
        handleClose(false, true);
      } else if (e.data.status === "ok") {
        setTimeout(() => {
          handleClose(true);
        }, 2000);
      }
    }
  };

  const checkStatusAndRedirect = () => {
    walletService
      .getOrderStatus(orderId)
      .then((result) => {
        const res = result.data;
        if (res) {
          if (res.status === 2 || res.status === 3) {
            handleGoBackFunc(
              res.status === 2 ? true : false,
              res.status === 3 ? true : false
            );
          } else {
            handleSetCallTimeout();
          }
        }
      })
      .catch((err) => {
        handleSetCallTimeout();
      });
  };
  const handleSetCallTimeout = () => {
    statusCallTimeout.current = setTimeout(() => {
      checkStatusAndRedirect();
    }, 5000);
  };
  const handleGoBackFunc = (isDone, isCancel = false) => {
    if (timerInterval.current) {
      clearInterval(timerInterval.current);
    }
    if (statusCallTimeout.current) {
      clearTimeout(statusCallTimeout.current);
    }
    handleClose(isDone, isCancel);
  };
  const handleClosePayLoader = () => {
    setShouldCancel((prev)=>!prev);
  };

  return (
    <IonModal
      isOpen={openZylaPay}
      disableBackdropClick={false}
      onBackdropClick={() => {}}
      // onDidDismiss={()=>handleClosePayLoader()}
      className="zyla-pay-dialog-fade-in-class zyla-pay-dialog-root-container-100 "
    >
      {window.Capacitor.platform === "web" ? (
        <div className="zyla-pay-dialog-root-container-div">
          <iframe
            src={`${
              process.env.REACT_APP_ZYLA_PAY_URL
            }?orderId=${orderId}&origin=${
              typeof window !== "undefined" ? window.location.origin : ""
            }`}
            width="100%"
            sandbox="allow-scripts allow-top-navigation allow-pointer-lock allow-same-origin allow-popups allow-forms"
          ></iframe>
        </div>
      ) : (
        <React.Fragment>
          <div className="payment-loader-container">
            <img
              src={closeIcon}
              alt="icon"
              className="payment-loader-container-cross"
              onClick={handleClosePayLoader}
            />
            <CircularProgress className="payment-loader-container-progress"></CircularProgress>
            <p className="payment-loader-container-text">
              Complete your payment from your browser &nbsp;
              <strong className="payment-loader-container-text-timer">
                {`0${timer.minutes}:${timer.seconds}`}
                <br />
              </strong>
              If payment is already made, sit back and relax.
            </p>
          </div>
          {shouldCancel && (
            <div className="payment-loader-alert-class">
              <div className="payment-loader-alert-class-box">
                <p>Are you sure you want to cancel the transaction.</p>
                <div className="alert-button-group">
                  <button
                    className="payment-loader-alert-cancel payment-loader-alert-button-common"
                    onClick={() => {
                      setShouldCancel(false);
                      handleGoBackFunc(false, true);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="payment-loader-alert-wait payment-loader-alert-button-common"
                    onClick={() => setShouldCancel(false)}
                  >
                    Wait
                  </button>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </IonModal>
  );
}

export default ZylaPay;
