import React, { useEffect, useState } from "react";
import { IonSlides, IonSlide, IonSpinner } from "@ionic/react";
import "./introCarousel.css";
import { useDispatch } from "react-redux";
import { logEvent } from "../../app/eventSlice";
let swiper = null;
const slideOpts = {
  initialSlide: 0,
  speed: 400,
  autoplay: {
    delay: 2000,
  },
  on: {
    beforeInit() {
      swiper = this;
    },
  },
};

export default function IntroCarousel({ images, loading }) {
  const [imgs, setImgs] = useState(images);
  const dispatch = useDispatch();
  // constructor(props) {
  //   super(props);
  //   this.CleverTapLogger = new CleverTapLogger();
  //   this.apiService = new ApiService();
  // }
  const slideChange = () => {
    if (swiper) {
      // if (swiper.activeIndex == 0) {
      //   this.CleverTapLogger.push("open_1_onboarding_carousel");
      // } else if (swiper.activeIndex == 1) {
      //   this.CleverTapLogger.push("open_2_onboarding_carousel");
      // }
    }

    if (swiper && !swiper.autoplay.running) {
      swiper.autoplay.start();
    }
  };
  useEffect(() => {
    dispatch(logEvent({ action: "open_onboarding_carousel" }));
    setImgs(images);
  }, [images, dispatch]);

  return (
    <>
      {!loading && imgs && imgs.length > 0 && (
        <IonSlides
          className="login-bullets"
          onIonSlideDidChange={slideChange}
          pager={true}
          options={slideOpts}
        >
          {imgs.map((scr, index) => {
            return (
              <IonSlide key={index}>
                <div className="login-slide-show">
                  <img
                    src={scr.image}
                    alt=""
                    className="login-slide-show-main-img"
                  />

                  <div className="login-slide-show-div">{scr.title}</div>
                </div>
              </IonSlide>
            );
          })}
        </IonSlides>
      )}
    </>
  );
}
