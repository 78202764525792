import React from "react";
import TickImage from "../../assets/images/tick.png";
import "./Cards.scss";
import moment from "moment";

//Common for Reports Sent and Vitals Sent

export default class CommonCardHead extends React.Component {
  render() {
    let { time, title } = this.props;

    if (!time) {
      time = Date.now();
    }

    let vitalsDate = moment(time).format("dddd,Do MMMM ");

    return (
      <div className="message-content">
        <div className="tick-container">
          <img className="tick-icon-card" src={TickImage} />
        </div>
        <div className="date-details">
          <div className="date-details-wrapper">
            <div className="text-to-left">{title} Submitted for</div>
            <div className="text-to-left">{vitalsDate}</div>
          </div>
        </div>
      </div>
    );
  }
}
