import React from "react";

import keyboardImg from "../../../assets/images/keyboard_light_copy.png";
import tailImg from "../../../assets/images/Path_8.png";
import headImg from "../../../assets/images/Path_12.png";
import nextSign from "../../../assets/images/next_sign_icon.png";
import tickSign from "../../../assets/images/tick_sign_icon.png";
import enterSign from "../../../assets/images/enter_sign_icon.png";
import { useDispatch, useSelector } from "react-redux";
import { handleAddLineDrawer } from "../reportSlice";
import { Dialog, DialogContent } from "@mui/material";

export default function AddLineTutorial() {
  const openDrawer = useSelector((store) => store.reporting.ui.openDrawer);
  const dispatch = useDispatch();
  return (
    <Dialog
      disableEnforceFocus
      open={openDrawer}
      onClose={() => dispatch(handleAddLineDrawer({ value: false }))}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent className="reporting_next_line_dialog">
        <div className="keyboard_img_box">
          <img
            src={keyboardImg}
            className="keyboard_main_img"
            alt="keyboard-img"
          />
          <img src={tailImg} className="keyboard_tail_img" alt="tail-img" />
          <img src={headImg} className="keyboard_head_img" alt="head-img" />
        </div>
        <div className="new_line_text_content">
          <p className="new-line-text-para">Click here to add a new line</p>
          <div className="bottom__text__content">
            To add a new row hit
            <div className="bottom__text__div">
              <img src={enterSign} alt="icon" />
              Enter
            </div>
            &nbsp;
            <div className="bottom__text__div">
              <img src={tickSign} alt="icon" />
              Done
            </div>
            or
            <div className="bottom__text__div">
              <img src={nextSign} alt="icon" />
              Next
            </div>
            &nbsp; from the bottom right of the keyboard once you have
            completely filled time & activity in one line.
          </div>
        </div>
        <div className="bottom_buttons_div">
          <p onClick={() => {
            
            dispatch(handleAddLineDrawer({ value: false }))
          }}>
            Ok,got it
          </p>
          <p style={{ width: "5%" }}>|</p>
          <p
            onClick={() =>
              window.open(
                "https://www.youtube.com/watch?v=ocxukw5aM5U",
                "_blank"
              )
            }
          >
            Show video
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
}
