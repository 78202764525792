import React, { Component } from "react";
import "./login.scss";
import UserService from "../../services/UserService";
import { connect } from "react-redux";
import { setupChatOperation } from "../../duck/operations.js";
import { withRouter } from "react-router";
import IntroCarousel from "../../components/introCarousel/introCarousel";
import { IonContent, IonPage, IonButton, IonModal } from "@ionic/react";
import { Snackbar } from "@mui/material";
import Validation from "./validation";
import "./PaytmLogin.scss";
import AuthenticationService from "../../services/AuthenticationService";
//TODO refactor
class PaytmLogin extends Component {
  constructor() {
    super();
    this.state = {
      ok_btn_enable: true,
      cont_btn_enable: false,
      phoneNo: "",
      otp: "",

      hasError: false,
      hasSuccess: false,
      error: "",
      visibility: {
        otp: false,
      },
      loading: false,
      time: 60,
      alertBox: false,
    };
    this.userService = new UserService();
    this.validation = new Validation();
    this.autheticationService = new AuthenticationService();
  }
  componentDidMount() {
    let isRegistered = this.autheticationService.isRegistered();
    if (isRegistered === "chat") {
      window.location.href = "/tab/chat";
    }
    this.permissionDialog();
  }
  permissionDialog() {
    this.ready(() => {
      window.JSBridge.call(
        "paytmFetchAuthCode",
        {
          clientId: "merchant-zyla-prod",
        },
        (result) => {
          if (result.error === -1) {
            this.setState({ alertBox: true });
          } else {
            this.firstCall(result);
          }
        }
      );
    });
  }
  firstCall(value) {
    var code = {
      authId: value.data.authId,
      openId: value.data.openId,
    };

    this.userService.firstCall(
      code,
      (response) => {
        if (response.user.patientProfile) {
          window.location.href = "/tab/chat";
        }
      },
      (error) => {
        if (error && error.code === 410) {
          this.setState({
            error: error.message,
            hasError: true,
            loading: false,
          });
        } else {
          this.setState({
            error: "Please enter a valid OTP",
            hasError: true,
            loading: false,
          });
        }
      }
    );
  }
  ready(callback) {
    // call if jsbridge is injected
    if (window.JSBridge) {
      callback && callback();
    } else {
      // listen to jsbridge ready event
      document.addEventListener("JSBridgeReady", callback, false);
    }
  }

  change = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (this.state.phoneNo) {
      // validate phone number
    }
  };
  onChangeOtpInput(value) {
    this.setState({
      otp: value,
    });
  }
  onChangePhoneInput(e) {
    this.setState({
      phoneNo: e.target.value,
    });
  }

  onClickVerifyOtp(e) {
    const { history } = this.props;

    this.setState({ hasError: false });
    if (this.validation.validateOTP(this.state.otp) && this.params.user) {
      this.params.user.otp = this.state.otp;
      this.setState({ loading: true });
      if (this.interval) {
        clearInterval(this.interval);
        this.setState({ time: 60 });
      }
      this.userService.verifyOTP(
        this.params,
        (response) => {
          if (response.user.patientProfile) {
            //history.replace("/chat");
            window.location.reload();
          } else {
            history.replace("/register");
          }
        },
        (error) => {
          if (error && error.code === 410) {
            this.setState({
              error: error.message,
              hasError: true,
              loading: false,
            });
          } else {
            this.setState({
              error: "Please enter a valid OTP",
              hasError: true,
              loading: false,
            });
          }
        }
      );
    } else {
      this.setState({
        hasError: true,
        hasSuccess: false,
        error: "Please enter a valid OTP",
      });
    }
  }

  onClickSubmit(e, resend) {
    if (this.interval) {
      clearInterval(this.interval);
      this.setState({ time: 60 });
    }
    if (this.validation.validatePhoneNo(this.state.phoneNo)) {
      if (resend) {
        this.setState({
          hasSuccess: true,
          hasError: false,
          error: "A new OTP has been sent to your mobile number.",
        });
      }

      this.setState({ loading: true });
      this.userService.getOTP(
        this.state.phoneNo,
        (response) => {
          this.params = response;
          this.setState({
            visibility: {
              otp: true,
              time: 60,
            },
            hasError: false,
            hasSuccess: false,
            error: "",
            loading: false,
          });
          this.interval = setInterval(() => {
            if (this.state.time === 1) {
              clearInterval(this.interval);
            }
            this.setState({ time: this.state.time - 1 });
          }, 1000);
        },
        (error) => {
          this.setState({
            visibility: {
              otp: false,
            },
            loading: false,
            hasError: true,
            hasSuccess: false,
            error: error.message,
          });
        }
      );
    } else {
      this.setState({
        hasError: true,
        hasSuccess: false,
        error: "Please enter a valid mobile number",
      });
    }
  }
  onClickBackToPhonePage() {
    this.setState({
      otp: "",
      visibility: {
        ...this.state.visibility,
        otp: undefined,
      },
      error: "",
      hasError: false,
      hasSuccess: false,
    });
  }

  render() {
    const { hasError, hasSuccess } = this.state;
    let errorCls = hasSuccess
      ? "success-message"
      : hasError
      ? "error-message"
      : "transparent-message";

    return (
      <IonPage>
        <IonContent>
          <div className="login-container">
            <div className="login-info-carousel">
              <IntroCarousel />
            </div>

            <IonModal
              isOpen={this.state.alertBox}
              cssClass="alert-box"
              swipeToClose={true}
              backdropDismiss={true}
              onDidDismiss={() => {
                this.setState({ alertBox: false });
              }}
            >
              <div>
                <p className="message-paytm">
                  In order to continue to the Zyla program, we need the
                  permission to access your phone number and email id.
                </p>
                <IonButton
                  onClick={() => {
                    this.setState({ alertBox: false });
                    window.JSBridge.call("popWindow");
                  }}
                  style={{
                    position: "relative",
                    top: "138px",
                    left: "169px",
                  }}
                >
                  DENY
                </IonButton>
                <IonButton
                  style={{
                    position: "relative",
                    right: "16px",
                    top: "138px",
                  }}
                  onClick={() => {
                    this.setState({ alertBox: false });
                    this.permissionDialog();
                  }}
                >
                  ALLOW
                </IonButton>
              </div>
            </IonModal>
            <Snackbar
              className={errorCls}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              open={true}
              autoHideDuration={2000}
              onClose={() => {
                this.setState({
                  hasError: false,
                  hasSuccess: false,
                });
              }}
              ContentProps={{
                "aria-describedby": "error-id",
              }}
              message={<span id="error-id">{this.state.error}</span>}
            />
          </div>
        </IonContent>
      </IonPage>
    );
  }
}
//TODO
//container
const mapStateToProps = (state) => {
  return {
    isChatInitialized: state.chatReducer.isChatInitialized,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setupChatOperation: (data) => {
      return dispatch(setupChatOperation(data));
    },
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaytmLogin)
);

// change me
