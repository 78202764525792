import React from "react";
import { useHistory } from "react-router";
import "../../containers/diabetesDigit/index.scss";
import dangerIcon from "../../assets/images/Digit/failed-icon.png";
import tick from "../../assets/images/tick.png";

function PaymentDialog(props) {
  const history = useHistory();
  return (
    <div className="paymentDialog-container">
      <img src={props.result ? tick : dangerIcon} />
      <span
        className="paymentDialog-container-span-1"
        style={{ color: `${props.result ? "#A6D577" : "#D0021B"}` }}
      >{`${props.result ? "Payment Successful" : "Payment Failed"}`}</span>
      <span className="paymentDialog-container-span-2">{`${
        props.result
          ? "Thanks for the payment. Your premium plan will be activated soon."
          : "Something went wrong. Please try again"
      }`}</span>
      <br />
      <br />
      {!props.result && (
        <span className="paymentDialog-container-span-3">
          {`Any debited amount would be refunded in 5-7 working days`}
        </span>
      )}
      <div
        className="paymentDialog-container-button"
        onClick={() => {
          props.hideModal();
        }}
      >
        {`${props.result ? "Continue" : "Retry Payment"}`}
      </div>
    </div>
  );
}

export default PaymentDialog;
