import React, { useEffect, useState } from "react";
import LevelLayoutComponent from "./components/levelLayout";
import "./index.scss";
import { IonPage, IonContent } from "@ionic/react";
import "firebase/analytics";
import "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { logEvent } from "../../app/eventSlice";
import { CircularProgress } from "@mui/material";
import { fetchHealthPlan, fetchHealthPlanCategories } from "./healthPlanSlice";

export default function HealthPlanComponent() {
  const [scrollTop, setScrollTop] = useState(0);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.login.user);
  const healthPlanStore = useSelector((state) => state.healthPlan);
  const isPaytm = useSelector((state) => state.app.device.isPaytm);

  const { ui, healthPlan, healthCategory } = healthPlanStore;
  useEffect(() => {
    dispatch(logEvent({ action: "open_health_plan" }));
    dispatch(fetchHealthPlanCategories());
  }, []);

  useEffect(() => {
    if (healthCategory) {
      dispatch(fetchHealthPlan({ id: user.patientProfile.id }));
    }
  }, [user, healthCategory, dispatch]);

  const onIonScrollHandler = (event) => {
    setScrollTop(event.detail.scrollTop);
  };

  return (
    <IonPage>
      <IonContent
        scrollEvents={true}
        className="health-plan-container"
        onIonScroll={(e) => onIonScrollHandler(e)}
      >
        {ui.loading ? (
          <CircularProgress className="ion-router-circular-progress "></CircularProgress>
        ) : (
          <LevelLayoutComponent
            scrollTop={scrollTop}
            healthPlan={healthPlan}
            isPaytm={isPaytm}
            ui={ui}
          />
        )}
      </IonContent>
    </IonPage>
  );
}
