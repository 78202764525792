import { IonContent, IonPage } from "@ionic/react";
import HealthAssesmentImage from "../../assets/images/healthAssesment.png";
import backImage from "../../assets/images/arrow-back.png";
import locationImage from "../../assets/images/addresss.png";
import arrowRightImage from "../../assets/images/arrowhead-right.png";
import hraCallImage from "../../assets/images/hraCall.png";
import { AppoinmentCard } from "./component/AppoinmentCard";
import { Link } from "react-router-dom";

export default function HealthAssesment() {
  return (
    <IonPage>
      <IonContent>
        <>
          <div className="bg-zinc-50  h-full">
            <div className="flex items-center p-4 ">
              <Link to="/tab/home">
                <img src={backImage} alt="icon" className="w-6 h-6" />
              </Link>
              <div className="px-5 font-semibold text-base leading-7">
                Health assessment
              </div>
            </div>
            <div
              className="w-full p-2 px-5"
              style={{ backgroundColor: "#DCDDE0" }}
            >
              {/* <img src={locationImage} alt="" className="w-4 h-4" />
              ... */}
            </div>
            <div className=" m-4 flex justify-center rounded-md">
              <img src={HealthAssesmentImage} alt="" className="w-full h-32" />
            </div>
            <div className="bg-white m-4 p-4 rounded-md ">
              <div className="flex">
                <img
                  src={hraCallImage}
                  alt=""
                  className="h-20 w-20 rounded-md"
                />
                <div className="px-5">
                  <div
                    className="font-bold text-xs "
                    style={{ lineHeight: "14.4px" }}
                  >
                    HRA (on call)
                  </div>
                  <div className="py-1 font-medium text-xs leading-4">
                    Talk to senior nutritionist & get personalised diet plan
                  </div>
                  <div className="pt-4">
                    <span className="font-semibold text-xs leading-4">
                      Price:
                      <del className="font-normal leading-4 text-xs ">
                        ₹1199
                      </del>
                      ₹0
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex justify-between  pt-4">
                <div className="font-semibold text-xs leading-4 flex items-center">
                  Know more
                  <img
                    src={arrowRightImage}
                    alt=""
                    style={{
                      width: "12px",
                      height: "10.5px",
                      paddingLeft: "4px",
                    }}
                  />
                </div>
                <Link to="/risk-Assesment">
                  <button
                    className="bg-zyple rounded-md font-semibold text-xs "
                    style={{
                      width: "119.79px",
                      height: "23.81px",
                      lineHeight: "15.35px",
                      color: "#FFFFFF",
                    }}
                  >
                    Book now
                  </button>
                </Link>
              </div>
            </div>
            <AppoinmentCard />
            <br />
          </div>
        </>
      </IonContent>
    </IonPage>
  );
}
