import React, { useEffect, useRef, useState } from "react";
import BackButton from "../../components/common/BackButton";
import whatsappIcon from "../../assets/icons/whatsapp-black-icon.png";
import emailIcon from "../../assets/icons/email-black-icon.png";
import smsIcon from "../../assets/icons/message-square.svg";
import { IonContent, IonPage, IonSpinner } from "@ionic/react";
import { Checkbox } from "@mui/material";
import "./Settings.scss";
import userService from "../../services/UserService";
import { useSelector } from "react-redux";
import ErrorComponent from "../../components/common/ErrorComponent";

function Settings() {
  const patientProfile = useSelector(
    (store) => store.login.user?.patientProfile
  );
  // const isEmailEnabled = useSelector(
  //   (store) => store.tab.uiConfig.isEmailEnabled
  // );
  // const isSMSEnabled = useSelector((store) => store.tab.uiConfig.isSMSEnabled);
  // const isWhatsAppEnabled = useSelector(
  //   (store) => store.tab.uiConfig.isSMSEnabled
  // );
  const [dataLoaded, setDataLoaded] = useState(false);
  const [settingsArr, setSettingsArr] = useState([
    {
      id: 1,
      icon: whatsappIcon,
      title: "WhatsApp notification",
      subtitle: "Get updates on WhatsApp",
      ischecked: false,
      disabled: false,
      height: 19,
      width: 20,
    },
    {
      id: 2,
      icon: emailIcon,
      title: "Email notification",
      subtitle: "Get updates on email",
      ischecked: false,
      height: 15,
      width: 20,
      disabled: false,
    },
    {
      id: 3,
      icon: smsIcon,
      title: "SMS updates",
      subtitle: "Get updates on SMS",
      ischecked: false,
      height: 24,
      width: 24,
      disabled: false,
    },
  ]);
  const [hasError, setHasError] = useState(false);
  const [errMsg, setErrMsg] = useState(
    "Something went wrong please try again later"
  );
  const [settingId, setSettingId] = useState(-1);
  const shouldCallPatch = useRef(false);

  useEffect(() => {
    getLatestSetting();
  }, []);

  useEffect(() => {
    if (shouldCallPatch.current) {
      sendUpdatedSettings();
    }
    shouldCallPatch.current = false;
  }, [settingsArr]);

  const getLatestSetting = () => {
    userService
      .getLatestSettings(patientProfile?.id)
      .then((result) => {
        const res = result.data;
        if (res) {
          let settingArrlat = settingsArr.map((settings) => {
            if (settings.id == 1) {
              return { ...settings, ischecked: res.isWhatsAppEnabled };
            } else if (settings.id == 2) {
              return { ...settings, ischecked: res.isEmailEnabled };
            } else {
              return { ...settings, ischecked: res.isSMSEnabled };
            }
          });

          setSettingsArr([...settingArrlat]);
          setDataLoaded(true);
        } else {
          setHasError(true);
          setErrMsg("Something went wrong.");
        }
      })
      .catch((err) => {
        setHasError(true);
        setErrMsg("Something went wrong.");
      });
  };

  const sendUpdatedSettings = () => {
    let params = {};

    settingsArr.forEach((setting) => {
      if (setting.id == 1) {
        params.isWhatsAppEnabled = setting.ischecked;
      }
      if (setting.id == 2) {
        params.isEmailEnabled = setting.ischecked;
      }
      if (setting.id == 3) {
        params.isSMSEnabled = setting.ischecked;
      }
    });
    userService
      .patchUserSettings(patientProfile?.id, params)
      .then((res) => {
        shouldCallPatch.current = false;
        setSettingsArr((prevState) => {
          let newSettingArr = prevState.map((setting) => {
            return { ...setting, disabled: false };
          });
          return [...newSettingArr];
        });
      })
      .catch((err) => {
        setSettingsArr((prevState) => {
          let newSettingArr = prevState.map((setting) => {
            if (setting.id === settingId) {
              return {
                ...setting,
                ischecked: !setting.ischecked,
                disabled: false,
              };
            } else {
              return { ...setting, disabled: false };
            }
          });
          return [...newSettingArr];
        });
        setHasError(true);
        setErrMsg("Something went wrong");
      });
  };
  const handleCheckboxChange = (id) => {
    shouldCallPatch.current = true;
    setSettingsArr((prevState) => {
      let newSettingArr = prevState.map((setting) => {
        if (setting.id === id) {
          return {
            ...setting,
            ischecked: !setting.ischecked,
            disabled: true,
          };
        } else {
          return { ...setting, disabled: false };
        }
      });

      return [...newSettingArr];
    });
    setSettingId(id);
  };

  return (
    <IonPage>
      <IonContent style={{ "--background": "#f0f3f8" }}>
        <ErrorComponent
          show={hasError}
          msg={errMsg}
          errorClose={() => {
            setHasError(false);
          }}
        />
        {dataLoaded ? (
          <div className="settings-page-container">
            <div className="settings-page-header">
              <div className="settings-page-header-left">
                <BackButton style={{ height: "24px", width: "24px" }} />
              </div>
              <div className="settings-page-header-right">
                <span className="settings-page-header-right-top">Settings</span>
                <span className="settings-page-header-right-bottom">
                  Notification preferences
                </span>
              </div>
            </div>
            <div className="settings-page-content">
              <div className="settings-page-content-top">
                {settingsArr.map((setting) => {
                  return (
                    <SettingElements
                      handleCheckboxChange={handleCheckboxChange}
                      {...setting}
                    />
                  );
                })}
              </div>
              <div className="settings-page-content-bottom">
                <span>
                  The notifications crucial to your 24*7 health management will
                  not be affected by this change.
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="settings-page-spinner">
            <IonSpinner color="primary" name="crescent" />
          </div>
        )}
      </IonContent>
    </IonPage>
  );
}

function SettingElements(props) {
  return (
    <div className="setting-element-container" key={props.id}>
      <div className="settings-element-box">
        <div className="settings-elements-box-left">
          <img
            src={props.icon}
            style={{ width: `${props.width}px`, height: `${props.height}px` }}
          />
        </div>
        <div className="settings-elements-box-mid">
          <span className="settings-elements-box-mid-title">{props.title}</span>
          <span className="settings-elements-box-mid-subtitle">
            {props.subtitle}
          </span>
        </div>
        <div className="settings-elements-box-right">
          <Checkbox
            checked={props.ischecked}
            disabled={props.disabled}
            onChange={() => {
              props.handleCheckboxChange(props.id);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Settings;
