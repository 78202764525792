import React, { useRef } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { IonCol, IonGrid, IonModal, IonRow } from "@ionic/react";

import CameraImage from "../../../assets/images/camera2.png";
import DocumentImage from "../../../assets/images/documents.png";
import ImageIconImage from "../../../assets/images/images.png";
import UploadDocument from "./uploadDocument";
import documentService from "../../../services/DocumentService";
import PrepareUploadService from "../../../services/PrepareUploadService";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logEvent } from "../../../app/eventSlice";
import "../index.scss";
import IonModalWrapper from "../../../components/common/IonModalWrapper";
export function DocumentChooseAndUpload({
  multipleUpload,
  fileChooser,
  onUploadStart,
  onUploadDone,
  hideFileTypeOption,
  patientId,
  isChatRoom,
  handleCameraStart,
  handleCameraStop,
}) {
  const device = useSelector((store) => store.app.device);
  const uploader = useRef(null);
  const dispatch = useDispatch();
  const onChooseFileType = (type) => {
    if (type === "I" || type === "P") {
      if (window.Capacitor.platform !== "web") {
        if (type === "I") {
          PrepareUploadService.selectFiles(multipleUpload ? true : false)
            .then((res) => {
              if (multipleUpload) {
                onUploadStart(res.fileResponse, res.errors);
              } else {
                uploadFileToServer(res.fileResponse, res.errors);
              }
            })
            .catch((err) => {});
        } else {
          PrepareUploadService.pickFiles(
            multipleUpload
              ? window.Capacitor.platform == "ios"
                ? true
                : false
              : false
          )
            .then((res) => {
              if (multipleUpload) {
                onUploadStart(res.fileResponse, res.errors);
              } else {
                uploadFileToServer(res.fileResponse, res.errors);
              }
            })
            .catch((err) => {});
        }
      } else {
        uploader.current.accept =
          type === "P"
            ? "application/pdf"
            : "image/jpg,image/jpeg,image/png,image/gif";
        uploader.current.click();
      }
    } else if (type === "C") {
      takePicture();
    }
  };
  const takePicture = async () => {
    const fileReq = await PrepareUploadService.takePicture();
    if (fileReq !== null) {
      if (isChatRoom) {
        handleCameraStart();

        if (fileReq?.file.size > 20000000) {
          handleCameraStop({
            status: "F",
            result: "This file is too big. maximum allowed file size is 20MB",
          });
          return;
        }
      } else {
        onUploadStart && onUploadStart();
      }

      documentService
        .uploadDocument(
          fileReq.file,
          fileReq.name,
          fileReq.document,
          null,
          patientId
        )
        .then((response) => {
          const data = response.data;
          if (isChatRoom) {
            handleCameraStop({ status: "S", result: data });
          } else {
            onUploadDone && onUploadDone({ status: "S", result: data });
          }
        })
        .catch((error) => {
          if (isChatRoom) {
            handleCameraStop({ status: "F", result: "Error while uploading file."  });
          } else {
            onUploadDone && onUploadDone({ status: "F", result: "Error while uploading file."  });
          }
        });
    } else {
      if (isChatRoom) {
        handleCameraStop({
          status: "F",
          result: "Image is null",
        });
      } else {
        onUploadDone && onUploadDone({ status: "F", result: "Image is null" });
      }
    }
  };

  const uploadFileToServer = (e, errors = {}) => {
    if (errors.sizeError) {
      onUploadDone({
        status: "F",
        result: "File size should be less than 20MB",
      });
      return;
    }
    const file = e.target.files[0];

    onUploadStart(e);
    let fileReq = PrepareUploadService.buildDocumentFromFile(file);
    if (fileReq === null) {
      return;
    }

    if (file.size <= 20000000) {
      documentService
        .uploadDocument(file.file, file.name, fileReq, undefined, patientId)
        .then((response) => {
          const data = response.data;
          if (onUploadDone) {
            onUploadDone({ status: "S", result: data });
          }
        })
        .catch((error) => {
          if (onUploadDone) {
            onUploadDone({ status: "F", result: error });
          }
        });
    } else {
      onUploadDone({
        status: "F",
        result: "File size should be less than 20MB",
      });
    }
  };

  return (
    <IonModal
      isOpen={fileChooser}
      backdropDismiss={true}
      onDidDismiss={() => hideFileTypeOption()}
      cssClass={"ion-modal-general-class ion-modal-transparent-background"}
      aria-labelledby="form-dialog-title"
    >
      <IonModalWrapper>
        <div className="text-center document-choose-upload-class document-choose-and-upload-modal">
          <h4 style={{ marginTop: "0px" }}>
            Select the file type you want to upload.
          </h4>

          {multipleUpload && <h3>(Maximum 5 files allowed)</h3>}

          <IonGrid>
            <IonRow>
              {window.Capacitor.platform === "ios" && (
                <IonCol
                  className="flex justify-center flex-col items-center"
                  onClick={() => onChooseFileType("C")}
                >
                  <img className="w-16" src={CameraImage} alt=""></img>
                  <br />
                  <span>Camera</span>
                </IonCol>
              )}

              <IonCol
                className="flex  flex-col items-center"
                onClick={() => onChooseFileType("I")}
              >
                <img className="w-16" src={ImageIconImage} alt=""></img>
                <br />
                <span>Image</span>
              </IonCol>
              {!device.isPaytm && (
                <IonCol
                  className="flex items-center flex-col"
                  onClick={() => {
                    onChooseFileType("P");
                    dispatch(
                      logEvent({ action: "click_pdf_from_attachement" })
                    );
                  }}
                >
                  <img className="w-16" src={DocumentImage} alt=""></img>
                  <br />
                  <span>PDF</span>
                </IonCol>
              )}
            </IonRow>
          </IonGrid>
          <UploadDocument
            pId={patientId}
            type={""}
            onUploadStart={(e) => onUploadStart && onUploadStart(e)}
            ref={uploader}
            paytm={device.isPaytm}
            onUploadDone={(response) => onUploadDone && onUploadDone(response)}
            multipleUpload={multipleUpload ? true : false}
          ></UploadDocument>
        </div>
      </IonModalWrapper>
    </IonModal>
  );
}
