import React from "react";
import { IonCol } from "@ionic/react";
import RippleButton from "../../../components/common/RippleButton";

export default function DocumentTab({ onClickTab, title, selected }) {
  return (
    <IonCol
      className={selected ? "doc-tab doc-tab-selected" : "doc-tab"}
      onClick={() => onClickTab()}
    >
      <RippleButton>
        <div>
          <label>{title}</label>
        </div>
      </RippleButton>
    </IonCol>
  );
}
