import { IonAlert } from "@ionic/react";
import { CircularProgress } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import walletService from "../../services/WalletServices";
import closeIcon from "../../assets/icons/ic_close.svg";
import "./index.scss";
function PaymentLoader({ handleClose, orderId }) {
  const [timer, setTimer] = useState({ timing: 300, minutes: "", seconds: "" });
  const [shouldCancel, setShouldCancel] = useState(false);
  const timerInterval = useRef(null);
  const statusCallTimeout = useRef(null);
  useEffect(() => {
    timerInterval.current = setInterval(() => {
      if (timer.timing === 0) {
        handleGoBackFunc();
      } else {
        setTimer((prevState) => {
          let newTimings = prevState.timing - 1;
          let sec = newTimings % 60;
          return {
            timing: newTimings,
            minutes: parseInt(newTimings / 60),
            seconds: sec < 10 ? `0${sec}` : sec,
          };
        });
      }
    }, 1000);
    checkStatusAndRedirect(orderId);
  }, []);

  const checkStatusAndRedirect = (orderId) => {
    walletService
      .getOrderStatus(orderId)
      .then((result) => {
        const res = result.data;
        if (res) {
          if (res.status === 2 || res.status === 3) {
            handleGoBackFunc(res.status === 2 ? true : false);
          } else {
            handleSetCallTimeout(orderId);
          }
        }
      })
      .catch((err) => {
        handleSetCallTimeout(orderId);
      });
  };
  const handleSetCallTimeout = (orderId) => {
    statusCallTimeout.current = setTimeout(() => {
      checkStatusAndRedirect(orderId);
    }, 5000);
  };
  const handleGoBackFunc = (isDone, isCancel = false) => {
    if (timerInterval.current) {
      clearInterval(timerInterval.current);
    }
    if (statusCallTimeout.current) {
      clearTimeout(statusCallTimeout.current);
    }
    handleClose(isDone, isCancel);
  };
  const handleClosePayLoader = () => {
    setShouldCancel(true);
  };

  return (
    <React.Fragment>
      <div className="payment-loader-container">
        <img
          src={closeIcon}
          alt="icon"
          className="payment-loader-container-cross"
          onClick={handleClosePayLoader}
        />
        <CircularProgress className="payment-loader-container-progress"></CircularProgress>
        <p className="payment-loader-container-text">
          Complete your payment from your browser &nbsp;
          <strong className="payment-loader-container-text-timer">
            {`0${timer.minutes}:${timer.seconds}`}
            <br />
          </strong>
          If payment is already made, sit back and relax.
        </p>
      </div>
      {shouldCancel && (
        <IonAlert
          className="payment-loader-alert-class"
          isOpen={shouldCancel}
          onDidDismiss={() => setShouldCancel(false)}
          message={"Are you sure you want to cancel the transaction."}
          buttons={[
            {
              text: "Cancel",

              cssClass:
                "payment-loader-alert-cancel payment-loader-alert-button-common",
              handler: () => {
                setShouldCancel(false);
              },
            },
            {
              text: "Wait",
              cssClass:
                "payment-loader-alert-wait payment-loader-alert-button-common",

              handler: () => {
                setShouldCancel(false);
                handleGoBackFunc(false, true);
              },
            },
          ]}
        />
      )}
    </React.Fragment>
  );
}

export default PaymentLoader;
