import React, { Fragment, useCallback, useEffect, useState } from "react";
import Media from "./media";
import { toggleMeditateNow } from "../meditationSlice";
import { useDispatch } from "react-redux";
export default function MeditationSectionComponent({
  meditationMedia,
  token,
  isRecomShow,
  meditateNow,
  closeRecommended,
  name,
}) {
  const [current, setCurrent] = useState(undefined);
  const [meditationMediaState, setMeditationMediaState] = useState([]);
  const [mediaMap, setMediaMap] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentMeditation, setCurrentMeditation] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    updateState();
  }, [meditationMedia]);

  const updateState = () => {
    let mediaMap = {};
    meditationMedia.map((media, index) => {
      mediaMap[media.id] = {
        previous: getPreviousMedia(meditationMedia, index),
        next: getNextMedia(meditationMedia, index),
      };
    });
    setMeditationMediaState(meditationMedia);
    setMediaMap(mediaMap);
  };

  const getPreviousMedia = useCallback((meditationMedia, index) => {
    if (index === 0) {
      return null;
    }
    return meditationMedia[index - 1].id;
  }, []);
  const getNextMedia = useCallback((meditationMedia, index) => {
    if (index === meditationMedia.length - 1) {
      return null;
    }
    return meditationMedia[index + 1].id;
  }, []);

  const togglePlay = (media, isBack) => {
    if (isBack) {
      dispatch(toggleMeditateNow(null));
      setCurrent(null);
      setIsPlaying(false);
      return;
    }
    let mediaId = isPlaying ? undefined : media.id;
    // console.log("medita id is", isPlaying, mediaId);
    setCurrent(mediaId);
    setIsPlaying((prevState) => {
      return !prevState;
    });
  };
  const onPrevious = (med) => {
    if (mediaMap[med.id].previous) {
      setCurrent(mediaMap[med.id].previous);
    }
  };
  const onNext = (med) => {
    if (mediaMap[med.id].next) {
      setCurrent(mediaMap[med.id].next);
    }
  };

  return (
    <div>
      {meditationMediaState.map((media, index) => {
        return (
          <Fragment key={media.id}>
            <Media
              name={name}
              media={media}
              isPlaying={current === media.id}
              togglePlay={(m, isBack) => togglePlay(m, isBack)}
              onPrevious={(med) => onPrevious(med)}
              onNext={(med) => onNext(med)}
              isNext={mediaMap[media.id].next}
              isPrevious={mediaMap[media.id].previous}
              token={token}
              isRecomShow={isRecomShow}
              currentMedia={current}
            />
          </Fragment>
        );
      })}
    </div>
  );
}
