import React from "react";
import clockIcon from "../../../assets/icons/clock.png";
import vegIcon from "../../../assets/icons/veg.png";
import nonvegIcon from "../../../assets/icons/non-veg.png";
import { useDispatch } from "react-redux";
import { logEvent } from "../../../app/eventSlice";

function RecipeCard({ recipe, openRecipeDrawer }) {
  const dispatch = useDispatch();
  return (
    <>
      {recipe && recipe.length > 0 && (
        <div
          className="recipe-container"
          onClick={() => {
            dispatch(logEvent({ action: "open_recipe" }));
            openRecipeDrawer();
          }}
        >
          <div className="recipe-container-header">Try a new recipe today</div>
          <div className="recipe-card">
            <img
              src={recipe[0]["image"]}
              className="recipe-card-image"
              alt="icon"
            />
            <div className="recipe-card-detail">
              <div className="recipe-card-detail-left">
                <div className="recipe-name">{recipe[0]["name"]}</div>
                <div className="recipe-duration">
                  <img src={clockIcon} alt="icon" />
                  <div className="recipe-duration-text">
                    {recipe[0]["cooking_time"]} min
                  </div>
                </div>
              </div>
              <img
                className="recipe-card-type-symbol"
                src={recipe[0].type === "veg" ? vegIcon : nonvegIcon}
                alt="icon"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RecipeCard;
