import React, { useEffect, useRef } from "react";

export default function DescriptionContainer({ option }) {
  const description = useRef();
  useEffect(() => {
    if (description.current) {
      description.current.innerHTML = option.description;
    }
  }, []);

  return <div ref={description} className="hp-description" />;
}
