import React, { useEffect } from "react";

import "./index.scss";
import WalletFAQ from "./components/WalletFAQ";
import { IonContent, IonPage } from "@ionic/react";
import WalletTransactions from "./components/walletTransactions";
import WalletHome from "./components/WalletHome";

import ErrorComponent from "../../components/common/ErrorComponent";
import { CircularProgress } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { getWalletData, updateErrorState } from "./walletSlice";

export default function ZylaWallet() {
  const walletStore = useSelector((store) => store.wallet);
  const { ui } = walletStore;
  const { error, errorMsg, isErrorPositive, screen } = ui;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWalletData());
  }, []);

  const handleErrorMsg = () => {
    dispatch(updateErrorState({ data: false, msg: "" }));
  };

  return (
    <IonPage>
      <IonContent>
        <ErrorComponent
          displayTimer={4000}
          msg={errorMsg ? errorMsg : "Something went wrong"}
          isPositive={isErrorPositive}
          show={error}
          errorClose={handleErrorMsg}
        />
        {screen === 1 && <WalletHome />}
        {screen === 2 && <WalletTransactions />}
        {screen === 3 && <WalletFAQ />}
        {screen === 4 && (
          <CircularProgress className="ion-router-circular-progress"></CircularProgress>
        )}
      </IonContent>
    </IonPage>
  );
}
