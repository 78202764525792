const temp = `<h6>Consent</h6>
<p>I hereby consent that the data captured by the mobile application or uploaded by me either for self or family members shall be shared with Max Life Insurance Company Limited (Max Life) or its affiliates entities or its authorized third-party entities for rendering insurance services, developing new products and making changes in their products and services.</p>
<h6>Terms of Use</h6>
<p>Please read the Terms of Use carefully before using this mobile application.</p>
<p>By downloading this mobile application, you agree to be fully bound by its terms and conditions. The information, content and services contained in this mobile application and the terms and conditions are subject to change and can be revised at any time at the sole discretion of Zyla Health Private Limited (hereinafter mobile application developer) and Max Life without any prior notice. You are bound by any such revisions and therefore Max Life and mobile application developer (hereinafter ‘we’ or ‘us’) advise you to review these terms and conditions or any amendments in these terms & conditions every time you use this mobile application and periodically to stay apprised of any and all changes. By using the mobile application and accessing its content, you acknowledge and agree that you have read and understood the terms of use.</p>
<p>Information provided on the mobile application should not be treated as an offer, solicitation, invitation, advice regarding any product or services of Max Life. The use of this mobile application by any one is subject to its terms and conditions as amended from time to time, any and all applicable laws and government notifications, rules, regulations and guidelines issued by regulatory and/or governmental and/or statutory bodies. By visiting this mobile application, you provide your explicit consent to the mobile application developer to collect, store and share the data or the information captured by the mobile application or uploaded by you on this mobile application with Max Life, which data or information may be stored, used by Max Life for providing insurance services, developing new products and making changes in their products and services. Further, you also authorize Max Life to share the said data or information with any third party in order to render insurance services. Further, you also authorize mobile app developer to share the said data or information with any third party in order to render health and wellness services. You, also agree to permit Max Life to communicate with you, from time to time, about its products and services. The mobile application developer shall also store and process the data for improving the application features, delivering health and wellness features and develop new products.</p>
<h6>Use of the content</h6>
<p>The content made available, on this mobile application is intended for informational purposes only. The posting of contents by the mobile application developer and/ or Max Life and access to this mobile application by you does not constitute provision of services or products by us, either explicitly or implicitly. All advertisements contain only an indication of features offered in the products. For detailed information, please read the insurance policy terms and conditions wordings carefully.</p>
<h6>Customer Contact</h6>
<p>You authorize Max Life and the mobile app developer to call, send SMS, WhatsApp, email or contact you via available social media platforms or to contact you by any other similar means on the contact information made available by you on the mobile application at any point in time, to offer you information and services for the product you have opted for and with regards to our other products and services that you may be interested in. You authorize us to call, SMS or email or contact you by way of any other similar means for the mentioned purpose irrespective of whether you are registered with the NDNC registry.</p>
<h6>Max Life will also use your contact details information to:​</h6>
<p>• Send you information about products and services offered by Max Life.</p>
<p>• To contact you through SMS and email for reminder notices, service request processing and</p>
<p>• To keep you updated on renewal payments, change in processes and other relevant information.</p>
<p>In addition to the afore mentioned, your personal identifiable information will not be shared with others, except as required by law or in the interest of protecting or exercising their or others’ legal rights, e.g., without limitation, in connection with requests from law enforcement officials and in connection with court proceedings.</p>

<h6>Modification/Updating of mobile application content</h6>
<p>We reserve the right to change, modify, add to, or remove, portions of these terms of use at any time at will and in the event that we are required to do so as a result of any applicable regulations or as required by law for any other purpose and as amended or made applicable from time to time and as per company policy. In the event that any material changes are made, we shall inform you by way of a notice displayed on the mobile application for a period 30 days from such change.</p>

<h6>Interaction with third party service providers</h6>

<p>This application is developed and maintained by Zyla Health Private Limited (CIN: U74999DL2017PTC343630) having its registered and corporate office at Innov8 CoWorking, Saket Salcon Rasvilas, Saket District Centre, Sector 6, New Delhi – 110017, India</p>
<p>Further, Max Life does not accept any liability towards quality of the services made available by third parties, including this application and Zyla Health Private Limited is responsible for any and all services provided by this application including counting the steps and other wellness features and Max Life is not liable for any defects or deficiencies on the part of the mobile application developer or other software/ mobile applications whose services have been availed by the mobile application developer to either count the steps or provide other features of this mobile application . Max Life shall not be responsible and does not offer any warranty for that the steps counted or vitals recorded by the mobile application are accurate and the same are subject to infallibility of technology. Max Life shall monitor the quality of service offered by the mobile application developer from time to time.</p>
<p>Max Life is not involved in providing any medical advice or diagnosis and hence is not responsible for any outcome of any and all such services that you avail, basis any analysis from usage of this application. The results of you availing any services on the mobile application should not be construed as an endorsement by Max Life of any such particular third party service provider.</p>
<p>Max Life shall not be obliged to act in any manner to give effect to the content of your feedback.</p>
<p>You understand and acknowledge that the third-party service providers and/or Max Life may be required to send you notifications/alerts/communication by way of email, SMS or through any other mode. You hereby consent to the receipt of all such notifications/alerts/communications, in all form and manner, as chosen by the third party service providers and/or Max Life.</p>

<h6>Contact us:</h6>

<p>You can get in touch with our Customer Care at 1860 120 5577 or Service.helpdesk@maxlifeinsurance.com</p>

<h6>Indemnity</h6>
<p>You shall indemnify, defend and hold harmless Max Life and mobile application developer (including its officers, directors, employees, affiliates, group/promoter companies, agents/ intermediaries, representatives or subcontractors) from any and all claims and losses imposed on, incurred by or asserted as a result of or related to:</p>
<p>a) Your access and use of this mobile application;</p>
<p>b) Any non-compliance with the terms and conditions hereof;</p>
<p>c) Any third-party actions related to your receipt and use of the information without authorization;</p>
<p>d) Misuse of your user ID and password;</p>
<p>e) Any unauthorized use of your account and/or this mobile application by you or someone using your computer/mobile device.</p>
<p>f) Any material information not disclosed by you.</p>
<p>g) In case you withdraw your consent of information sharing, the cost of processing the information, information already been processed prior to that revocation of such consent.</p>
<p>You shall be liable to pay any and all costs, damages and expenses, losses incurred by Max Life including, but not limited to, reasonable attorney’s fees and costs awarded against or otherwise incurred by or in connection with or arising from any such claim, suit, action or proceeding attributable to any such claim.</p>

<h6>Limitation of liability</h6>
<p>Max Life hereby disclaims any and all liability with regards to the accuracy, completeness and/or suitability of the content and information as is provided on the /mobile application and any information and/or content that may be provided thereon from time to time. Max Life expressly disclaim any liability, whether in contract, tort, strict liability or otherwise, for any direct, indirect, incidental, consequential, punitive or special damages arising out of or in any way connected with your access or use or inability to access or use of the mobile application or reliance on its content, or any failure of performance, interruption, defect, delay in transmission, computer viruses or other harmful components, or line or system failure associated with the mobile application, regardless of our knowledge thereof. Max Life shall not be liable for, any damages to, or viruses that may infect, your computer equipment or other property as a result of your access to, use of, or browsing in the mobile application or your downloading of any materials, data, text, images, video, or audio from this mobile application.</p>
<p>The services offered by Max Life are of facilitation of insurance services through mobile application only. Therefore, in no event is Max Life to be considered or deemed responsible for or with regards to the provision or non-provision/deficiency in provision of services by any of the third party providers or experts that are facilitated through this mobile application.</p>
<h6>Types of Data and Passive Data Collection</h6>
<p>We collect personally identifiable information and non-personal information (collectively referred to as "Information") for rendering insurance services and health and wellness services.</p>
<p>The "personally identifiable information" includes your name, phone number, date of birth, gender, email address, images (profile picture etc.) and general health information (such as medical records, family history, diagnostic test reports, prescriptions (in pdf or image formats), lifestyle, or information contained in consult requests and chats). We may receive personally identifiable information about you if you use any services or products offered through the mobile application (including Doctor consultation, Nutritionist consultation, Diagnostic tests, Medicine order) and if you voluntarily share vitals, health queries, medical records and any other information in regard to the same.</p>
<p>
“Information which does not allow
disclosure of identity through cookies, may be
collected and processed” resulting from your interactions with the mobile application and via any health or monitoring device you connect to the mobile application. Such non-personal information may include:</p>
  <p>• Technical information, including the address used to connect your device to the Internet, your login information, system and operating system type and version, time zone setting, operating system and platform and your location,</p>
	<p>• Master and transaction data and other data stored in your user account,</p>
	<p>• Information about your visit, including products and services you viewed or used; mobile application response times, interaction information (such as button presses, chat time stamps, response times, etc.) and any phone number used to contact the customer support.</p>
<p>The content captured by the mobile application also includes syncing with other application on your mobile (including Google Fit and Apple Health) for which explicit consent shall be taken from you. You can deny and not sync such mobile applications at your own will. In such instance, we will not be liable to record the steps walked and provide you associated benefits, if any.</p>
<p>Max Life may further share the data shared with it with advisors, consultants, re-insurers, etc., for rendering insurance services and other incidental matters thereto and confirm that you have consented to the same. </p>

<h6>Use of the information collected</h6>
<p>The data or information uploaded/consented by you and the other data or information passively tracked is primarily used for purposes of administering, protecting and improving insurance services and also addressing issues with mobile application and our systems, to better understand the policyholder behaviour in general, to identify server problems, to compile aggregated statistics about mobile application usage, and to help improve our services and your experience.</p>

<h6>Security Measures</h6>
<p>• Only authorized personnel have access to your information. Our systems and procedures are designed to prevent the loss, misuse, unauthorized access, disclosure, alteration, and destruction of your information. Our commitment to security extends to the contracts and agreements that we sign with external suppliers and service providers. We will only retain your information for as long as required for the purposes for which it was collected and/or as per any legal or regulatory requirements.
We may use the data that we collect both passively and actively, to:</p>
  <p>• Identify you;</p>
  <p>• Understand your needs;</p>
  <p>• Confirm your application information and assess your eligibility for products and services;</p>
  <p>• Provide you with ongoing services, establish and maintain communication, and to respond to your inquiries;</p>
  <p>• Investigate and settle your claims/requests, and determine your eligibility for benefits;</p>
  <p>• Provide information that may be of interest to you, and;</p>
  <p>• Meet our regulatory requirements.</p>
<h6>Data Protection</h6>
<h6>Confidentiality:</h6> <p>The mobile application developer shall take all reasonable precautions to preserve the confidentiality and prevent any corruption or loss, damage or destruction of the data and information provided by you, in keeping with industry standard practices. The mobile application developer reserves the right to collect statistical, anonymous and aggregate data based on your usage of the mobile application, & share the same with Max Life for rendering insurance services and to understand user behaviour and characteristics and to inform advertisers of such information as well as the number of users that have been exposed to or clicked on the advertising banners.
<h6>Password:</h6> <p>Certain services on the /mobile application may require authentication procedures. You would be able to access such services by using the login credentials (Phone Number and Date of Birth) and the one time password. The mobile application developer shall take reasonable care to ensure the security of and to prevent unauthorized access to the services, which are part of the mobile application. However, the mobile application developer shall not liable in case of unauthorized access from your login credentials and one- time password. In addition to login credentials and password, the mobile application developer may, at its discretion, require you to adopt such other means of authentication including but not limited to digital certification and / or Smart Cards and/or Two Factor Authentication like Public or Private Keys / Risk Engine / Challenge Questions. Internet per se is susceptible to a number of frauds, misuse, hacking and other actions that could affect payment instructions / other instructions to the bank through the payment gateway. The mobile application developer shall aim to provide security to prevent the same, however, there cannot be any guarantee from such internet frauds, hacking and other actions that could affect payment instructions / other instructions including result in delay or failure in processing the instructions. Therefore, we disclaim any liabilities to that effect.
Payment transaction at a cybercafe/shared computer terminal is risky and you shall not use the services of a cybercafe/shared computer terminal to do any such transactions.</p>
<h6>Posting messages, comments or content</h6>
<p>Certain portions of this mobile application may be configured to permit users to post messages, comments, or other content. Any such content shall only serve and be construed as the opinion of the poster, is no substitute for your own research, and should not be relied upon for any purpose. You agree not to post any content:</p>
<p>• which is profane, libellous, defamatory, obscene, pornographic, indecent, lewd, harassing, threatening, harmful, invasive of privacy or publicity rights, abusive, inflammatory, or otherwise objectionable; or</p>
<p>• the transmission of which could violate, or facilitate the violation of, any applicable law, regulation, or intellectual property rights.</p>
<p>• You are solely responsible for the content of any postings you submit and the Max Life or the mobile application developer assumes no responsibility or liability for any content submitted by you or any other website visitor. We may, but are not obligated to, restrict or remove any and all content from a message that we determine in our sole discretion violates these Terms or is otherwise harmful to us, our customers, or any third party. We reserve the right to remove the content you provide at any time, but you understand that we may preserve and access a backup-copy, and we may disclose the content if required to do so by law or in a good faith belief that such access, preservation, or disclosure is required by law or in the best interests of the Company.</p>
<p>You agree that in case of any comment and details of any child or person with disability, it shall be construed to have been posted under the guidance and supervision of the legal guardian of the Child or the person with disability.</p>
<p>Any content you submit is for non-commercial use only. Never assume that you are anonymous and cannot be identified by your posts.</p>
<p>If you do post content or submit material, and unless we indicate otherwise, you grant Max Life a non-exclusive, royalty-free, perpetual, irrevocable, and fully sub-licensable right to use, reproduce, publish, distribute, and display such content throughout the world in any media. You represent and warrant that you own or otherwise control all of the rights to the content that you post; that the content is accurate; that use of the content you supply does not violate these terms and conditions and will not cause injury to any person or entity; and that you will indemnify Max Life from all claims resulting from or relating to the content that you supply.</p>
<h6>Force Majeure</h6> 
<p>We shall not be liable if any transaction does not materialize or may not be completed or for any failure on its part to perform any of its obligations under these terms and conditions or those applicable specifically to any services, if the same is prevented, hindered or delayed by a Force Majeure and in such case its obligations shall be suspended for so long as the Force Majeure event continues. "Force Majeure" means any event due to any cause beyond the reasonable control of the Max Life or the mobile application developer, including without limitations, sabotage, fire, flood, explosion, acts of god, civil commotion, strikes or industrial action of any kind, riots, insurrection, war, acts of government, faults or failures in telecommunication, unavailability of any communication systems, breach, or virus in the processes or payment or delivery mechanism, computer hacking, computer crashes, malfunctioning in the computer terminal or the systems getting affected by any malicious code, unauthorized access to computer data and storage devices, destructive or corrupting code or program, mechanical or technical errors/failures or power outage, etc.</p>
<h6>Declaration & Warranty</h6>
<p>You agree to have read and understood the brochure, product wordings website terms and privacy policy accessible at https://www.maxlifeinsurance.com/privacy-policy and confirm to abide by the same.</p>
<h6>Disclaimer</h6>
<p>To the fullest extent permissible and pursuant to applicable law, the materials on this mobile application are provided "as is" and without warranties of any kind either expressed or implied and Max Life & its affiliates, disclaim all warranties, expressed or implied, including, but not limited to, implied warranties of merchantability and fitness for any purpose.
Max Life does not warrant that your usage and the availability of the mobile application and any of the content and materials thereon will be uninterrupted or error-free, that defects will be corrected, or that this mobile application or the server that makes it available are free of viruses or other harmful components. Max Life does not warrant or make any representations regarding the use or the results of the use of the materials on this website/mobile application in terms of their correctness, accuracy, reliability, or otherwise. The information and descriptions contained herein are not intended to be a complete description of all terms, exclusions and conditions applicable to the products and services, but are provided solely for general informational purposes.</p>
<p>This website/mobile application may be linked to another website which are not maintained by Max Life. Max Life is not responsible for the content of those website. The inclusion of any link on mobile application does not imply approval of or endorsement by Max Life of the website or the content thereof.
Max Life is not responsible for any communications that you may receive from such third party website.
Your use of such third party website shall be subject to the privacy policy, terms and conditions and any other restrictions as may be specified therein. Max Life does not endorse these third party website and disclaims any and all liability resultant from your use thereof or related thereto.</p>
<h6>Proprietary rights</h6>
<p>All materials on this mobile application (as well as the organisation and layout of this mobile application) are owned by Max Life and may be accessed, downloaded or printed for your personal non-commercial use only. Without the prior written permission of Max Life, you may not copy, distribute or transfer any material on this site, in whole or in part. All trademarks, logo, slogans, service marks, and trade names (collectively the "marks") are proprietary to Max Life or other respective owners that have granted Max Life the right and license to use such marks.</p>
<h6>Governing Law</h6>
<p>Any claim relating to use of this mobile application and the use thereof are subject to and governed by the laws of India.</p>
<h6>Jurisdiction</h6>
<p>The competent courts in India shall have the sole jurisdiction with regards to any and all disputes and matters related hereto.</p>
<h6>Severability</h6>
<p>If any part of these Terms and Conditions are determined to be invalid or unenforceable pursuant to applicable law including, then such unenforceable provision shall be deemed to be replaces with a suitable legal alternative provision that achieves the purpose of the original provision as closely as possible.</p>
<h6>Corporate Information</h6>
<p>Max Life Insurance Co Limited</p>
<h6>Corporate office:</h6> <p>11th Floor, DLF Square, Jacaranda Marg, DLF City, Phase II, Gurugram 122002, India.,</p>
<h6>Registered Office:</h6> <p>419, Bhai Mohan Singh Nagar, Railmajra, Tehsil Balachaur, District Nawanshahr, Punjab -144 533</p>
<p>If you have any questions you may contact us at: 1860 120 5577, or write to us at Service.helpdesk@maxlifeinsurance.com</p>
 <p>All rights reserved.</p>`;

export default temp;
