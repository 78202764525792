import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./WalletCard.scss";
function WalletCard() {
  const wallet = useSelector((store) => store.wallet.walletInfo);
  const [expDate, setExpDate] = useState("");

  useEffect(() => {
    if (wallet) {
      setExpDate(moment(wallet.expiryDate, "YYYYMMDD").format("DD/MM/YYYY"));
    }
  }, [wallet.expiryDate]);

  return (
    <div className="zyla-wallet-card">
      <div className="zyla-wallet-card-first">
        <div className="zyla-wallet-card-top">
          <p>Balance</p>
          <p>Zyla</p>
        </div>
        <div className="zyla-wallet-card-mid">
          <p className="zyla-wallet-card-mid-left">₹{wallet.amount}</p>
          <div className="zyla-wallet-card-mid-right">
            <p>Corporate</p>
          </div>
        </div>
        <div className="zyla-wallet-card-mid-validity">
          <p>Valid upto- {expDate}</p>
        </div>
      </div>
      <div className="zyla-wallet-card-mid-bottom">
        <p>{wallet.name}</p>
        <p>{wallet.companyName}</p>
      </div>
    </div>
  );
}
export default WalletCard;
