import AuthenticationService from "./AuthenticationService";
import Helper from "../utils/Helper";
import APIConfig from "./APIConfig";
import { Redirect } from "react-router";

class ApiService {
  constructor() {
    // this.KEY_CLIENT = "client";
    this.KEY_PHONENO = "phone_no";
    this.KEY_TOKEN = "auth_token";
    this.CLIENT = "patient_android";
    this.authenticationService = new AuthenticationService();
    this.helper = new Helper();
    this.headersforpost = {
      client: "patient_android",
      "Content-Type": "application/json",
      // authToken: null,
      // device_id: "20c7fbb4415fcc27",
      // timezone: "GMT+05:30",
      // version_code: 70,
      // version: "v1.3.8",
      // platform: "PWA"
    };
    this.initializeHeaders();
    this.expiryToken = sessionStorage.getItem("expiryToken");
    this.accessToken = sessionStorage.getItem("accessToken");
    this.firstCheck = false;
    // this.checkToken();
  }
  getHeaders() {
    return this.headers;
  }
  initializeHeaders() {
    let user = this.authenticationService.getUserProfile();
    let phoneNo = user ? user.phoneNo : "";
    // let accTkn = this.authenticationService.getToken(false);
    let accTkn2 = this.authenticationService.getToken(true);
    // let token = accTkn ? accTkn : "";
    let token = accTkn2 ? accTkn2.accessToken : "";
    this.headers = {
      client: this.CLIENT,
      "Content-Type": "application/json",
    };

    if (phoneNo) {
      this.headers.phone_no = phoneNo;
    }

    if (token) {
      this.headers.auth_token = token;
      // this.headersforpost.authToken = token;
    }
  }

  // checkToken(onResponse) {
  //   var user = this.authenticationService.getUserProfile();
  //   if (user !== null) {
  //     var accessToken = this.accessToken;
  //     if (accessToken !== null) this.setupToken(accessToken, user);
  //     else {
  //       var payload = {
  //         accessToken: "",
  //         expiryToken: this.expiryToken,
  //       };
  //       var url = APIConfig.user.token;
  //       this.post(
  //         url,
  //         payload,
  //         (response) => {
  //           if (response.accessToken !== null) {
  //             this.authenticationService.activateSession(response.accessToken);
  //             this.checkToken();
  //           } else {
  //             this.authenticationService.logout();
  //             window.location.href = "/login";
  //           }
  //         },
  //         (err) => {
  //           this.authenticationService.logout();
  //           window.location.href = "/login";
  //         }
  //       );
  //     }
  //   } else {
  //     return false;
  //   }
  // }

  // setupToken(token, user) {
  //   let accessToken = this.accessToken;
  //   let expiryToken = this.expiryToken;
  //   if (this.isExpired(accessToken)) {
  //     if (this.isExpired(expiryToken)) {
  //       var payload = {
  //         accessToken: "",
  //         expiryToken: expiryToken,
  //       };
  //       var url = APIConfig.user.token;
  //       this.post(
  //         url,
  //         payload,
  //         (response) => {
  //           if (response.accessToken !== null) {
  //             this.authenticationService.activateSession(response.accessToken);
  //             this.checkToken();
  //           } else {
  //             this.authenticationService.logout();
  //             window.location.href = "/login";
  //           }
  //         },
  //         (err) => {
  //           this.authenticationService.logout();
  //           window.location.href = "/login";
  //         }
  //       );
  //     } else {
  //       this.authenticationService.activateSession(expiryToken);
  //     }
  //   }
  // }
  // isExpired = (token) => {
  //   if (token != null) {
  //     var base64Url = token.split(".")[1];
  //     var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  //     var jsonPayload = decodeURIComponent(
  //       atob(base64)
  //         .split("")
  //         .map(function (c) {
  //           return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
  //         })
  //         .join("")
  //     );
  //     var finalToken = JSON.parse(jsonPayload);
  //     if (finalToken) {
  //       const expiry = finalToken.exp;
  //       const now = new Date();
  //       return now.getTime() > expiry * 1000;
  //     }
  //     return false;
  //   } else return true;
  // };

  checkForExpiredtoken = (response) => {
    if (response && response.status == 401) {
      //some redirect to session page

      window.location.href = "/sessioncheck";
    }
  };
  get(url, onResponse, onError, header = null) {
    let that = this;

    return fetch(url, {
      method: "GET",
      headers: header ? header : this.headers,
    })
      .then(function (response) {
        that.checkForExpiredtoken(response);
        if (response.status === 204) {
          onResponse("No test readings for today.");
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              if (onError) {
                onError({
                  message: "Looks like something went wrong. Please try again.",
                });
              }
            });
          return;
        }
        // Examine the text in the response
        response
          .json()
          .then(function (data) {
            onResponse(data);
            return;
          })
          .catch((err) => {
            if (onError) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            }
          });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  // getWithHeader(url, phoneNo, token, onResponse, onError) {
  //   let that = this;
  //   return fetch(url, {
  //     method: "GET",
  //     headers: this.headers,
  //   })
  //     .then(function (response) {
  //       if (response.status === 201) {
  //         response.json().then(function (data) {
  //           onResponse(data);
  //           return true;
  //         });
  //         return true;
  //       }
  //       if (response.status !== 200) {
  //         response
  //           .json()
  //           .then(function (data) {
  //             onError(data);
  //           })
  //           .catch(function (error) {
  //             onError({
  //               message: "Looks like something went wrong. Please try again.",
  //             });
  //           });
  //         return false;
  //       }

  //       // Examine the text in the response
  //       response.json().then(function (data) {
  //         onResponse(data);
  //         return;
  //       });
  //     })
  //     .catch(function (error) {
  //       if (that.helper.isJSON(error)) {
  //         onError(error);
  //       } else {
  //         onError({
  //           message: "Looks like something went wrong. Please try again.",
  //         });
  //       }
  //       return;
  //     });
  // }

  getImage(url, onResponse, onError) {
    let that = this;

    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };

    return fetch(url, {
      method: "GET",
      headers: headers,
    })
      .then(function (response) {
        that.checkForExpiredtoken(response);
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // return the buffer
        response.arrayBuffer().then((buffer) => {
          onResponse(buffer);
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  post(url, params, onResponse, onError, headers = null) {
    let that = this;

    return fetch(url, {
      method: "POST",
      headers: headers ? headers : this.headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        that.checkForExpiredtoken(response);
        if (response.status === 201) {
          response.json().then(function (data) {
            onResponse(data);
            return true;
          });
          return true;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return false;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  postWithFormData(url, params, onResponse, onError, signal) {
    let that = this;
    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };
    let options = {
      method: "POST",
      headers: headers,
      body: params ? params : {},
    };
    if (signal) {
      options.signal = signal;
    }

    return fetch(url, options)
      .then(function (response) {
        that.checkForExpiredtoken(response);
        if (response.status !== 201 && response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else if (error.name === "AbortError") {
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  patchtWithFormData(url, params, onResponse, onError) {
    let that = this;
    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };
    return fetch(url, {
      method: "PATCH",
      headers: headers,
      body: params,
    })
      .then(function (response) {
        that.checkForExpiredtoken(response);
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  profilePicUpload(url, params, onResponse, onError) {
    let that = this;
    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };
    return fetch(url, {
      method: "PUT",
      headers: headers,
      body: params,
    })
      .then(function (response) {
        that.checkForExpiredtoken(response);
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response

        response
          .text()
          .then((data) => {
            onResponse(data);
          })
          .catch((error) => {
            onError({
              message: "Looks like something went wrong. Please try again.",
            });
          });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  putWithFormData(url, params, onResponse, onError) {
    let that = this;
    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };
    return fetch(url, {
      method: "PUT",
      headers: headers,
      body: params,
    })
      .then(function (response) {
        that.checkForExpiredtoken(response);
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  put(url, params, onResponse, onError) {
    let that = this;

    fetch(url, {
      method: "PUT",
      headers: this.headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        that.checkForExpiredtoken(response);
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  patch(url, params, onResponse, onError) {
    let that = this;

    fetch(url, {
      method: "PATCH",
      headers: this.headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        that.checkForExpiredtoken(response);
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  delete(url, onResponse, onError) {
    let that = this;
    return fetch(url, {
      method: "DELETE",
      headers: this.headers,
    })
      .then(function (response) {
        that.checkForExpiredtoken(response);
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Goal deleted.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  //update new api from here

  getWithStatus(url, header = undefined) {
    let that = this;
    let h = header ? header : this.headers;
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "GET",
        headers: h,
      })
        .then((data) => {
          that.checkForExpiredtoken(data);
          let jsonData = data
            .json()
            .then((data) => data)
            .catch((error) => {});
          return {
            payload: jsonData,
            status: data.status,
          };
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  putWithStatusHeaders = () => {
    let user = this.authenticationService.getUserProfile();
    let phoneNo = user ? user.phoneNo : "";

    let accTkn = this.authenticationService.getToken(false);
    let token = accTkn ? accTkn : "";

    let putHeaders = {
      client: this.CLIENT,
      "Content-Type": "application/json",
    };

    if (phoneNo !== "") {
      putHeaders.phone_no = phoneNo;
    }

    if (token !== "") {
      putHeaders.auth_token = token;
    }

    return putHeaders;
  };

  putWithStatus(url, payload) {
    let that = this;
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "PUT",
        headers: this.putWithStatusHeaders(),
        body: JSON.stringify(payload)
          ? JSON.stringify(payload)
          : JSON.stringify({}),
      })
        .then((data) => {
          that.checkForExpiredtoken(data);
          let jsonData = data
            .json()
            .then((data) => data)
            .catch((error) => {});
          return {
            payload: jsonData,
            status: data.status,
          };
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default ApiService;
