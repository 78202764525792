import { IonPage, IonContent } from "@ionic/react";
import PartialImage from "../../../assets/images/forwardRed.png";
import { useState } from "react";
import BackButton from "../../../components/common/BackButton";
import { useSelector } from "react-redux";

export default function RewardInfo() {
  const plan = useSelector((state) => state.home.plans);

  const [isOpen, setIsOpen] = useState(false);

  const data = [
    {
      id: 1,
      years: "18-35 yrs",
      stars1: "0% of AP",
      stars2: "3% of AP",
      stars3: "6% of AP",
    },
    {
      id: 2,
      years: "36-44 yrs",
      stars1: "0% of AP",
      stars2: "4% of AP",
      stars3: "8% of AP",
    },
    {
      id: 3,
      years: "45-55 yrs",
      stars1: "0% of AP",
      stars2: "5% of AP",
      stars3: "10% of AP",
    },
  ];

  return (
    <>
      <IonPage>
        <IonContent>
          <div className="text-lightblack">
            <div className="flex items-center text-center  p-5 relative flex-col gap-y-3">
              <div className="flex items-center text-center font-bold text-base leading-4 justify-between w-full">
                <div className="flex items-center gap-x-2">
                  <BackButton />
                  {/* <div className="px-2 "> Policy: </div>
                  <span className="text-base font-normal leading-4">
                    {plan.response?.payload.hsaPlan[0].policyNumber}
                  </span>
                </div>
                <div> */}
                  {/* <img
                    src={PartialImage}
                    alt="image"
                    className={`h-6 w-6  cursor-pointer ${
                      isOpen ? "-rotate-90 " : "rotate-90"
                    }`}
                    onClick={() => setIsOpen(!isOpen)}
                  /> */}
                </div>
              </div>

              {/* {isOpen && (
                <div
                  className=" bg-white z-100   bg-grayd9 rounded-lg border-2 border-slate-300 w-full overflow-hidden gap-y-px -bottom-1 flex flex-col justify-start left-0"
                  style={{ border: "1px solid #d9d9d9" }}
                >
                  <div className="text-lightblack text-xs bg-white w-full h-full p-2 text-left">
                    <strong>Policy number:</strong>&nbsp;
                    {plan.response?.payload.hsaPlan[0].policyNumber}
                  </div>
                </div>
              )} */}
            </div>

            <div className="px-5  font-medium text-xs ">
              <div>
                On the Max Fit app, you can earn reward points through a range
                of activities including health risk assessment, steps tracking,
                health checkup and participation in health challenges. Basis
                your reward points, you will be placed in a particular Wellness
                Tier which will determine your Wellness Booster for the year.
              </div>
              <div className=" font-bold py-4 text-xs leading-5">
                Wellness Booster* basis reward points
              </div>
            </div>
            <div className="flex  justify-between py-5 my-1 px-5 bg-[#E7F4ED]">
              {/* span to align content (quick fix) */}
              <div className="font-bold text-xs leading-[15px]">
                Wellness Tier
              </div>
              <div className="font-bold text-xs leading-[15px]">
                Beginner<span className="text-[#E7F4ED]">..</span>
              </div>
              <div className="font-bold text-xs leading-[15px]">
                Advanced<span className="text-[#E7F4ED]">..........</span>
              </div>
              <div className="font-bold text-xs leading-[15px]">
                Pro<span className="text-[#E7F4ED]">.....</span>
              </div>
            </div>
            <div className="flex shadow-sm justify-between px-5 py-5 bg-[#E7F4ED]">
              {["Age/ Points", "0-19,999", "20,000-29,999", "30,000+"].map(
                (e, index) => {
                  return (
                    <>
                      <div
                        key={e}
                        className={`  ${
                          index === 0
                            ? "font-bold text-xs leading-[15px] "
                            : "font-semibold text-xs leading-4"
                        } `}
                      >
                        {e}
                      </div>
                    </>
                  );
                }
              )}
            </div>
            {data.map((e) => {
              return (
                <div
                  className=" flex p-5 justify-between font-medium text-xs my-1 bg-indigo "
                  key={e.id}
                >
                  <div className={`${e.years ? "font-semibold" : ""}`}>
                    {e.years}
                  </div>
                  <div>{e.stars1}</div>
                  <div>{e.stars2}</div>
                  <div>{e.stars3}</div>
                </div>
              );
            })}

            <div className="p-7 font-medium text-xs leading-4">
              *Wellness Booster as a % of Annualized Premium
              <p>
                <span className="font-bold">Note:</span> Every year your reward
                points will expire at the time of policy renewal.
              </p>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
}
