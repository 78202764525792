import React from "react";

export default class SingleSelectQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };
  }

  componentDidMount() {
    this.getSelectedAnswerIfAny();
  }

  componentDidUpdate(prevPros) {
    const { currentQuestion } = this.props;
    if (currentQuestion.id !== prevPros.currentQuestion.id) {
      this.getSelectedAnswerIfAny();
    }
  }

  getSelectedAnswerIfAny = () => {
    const { currentQuestion, updateAnswerTag } = this.props;
    if (currentQuestion.answer !== null) {
      let currentArray,
        finalArray = [];
      if (currentQuestion.answer.length > 2) {
        currentArray = currentQuestion.answer
          .substring(1, currentQuestion.answer.length - 1)
          .trim()
          .split(",");
      }

      currentArray.forEach((answer, index) => {
        currentQuestion.fields.forEach((element) => {
          if (element.tag === answer) finalArray.push(answer);
        });
      });
      updateAnswerTag(`[${finalArray.join(",")}]`);
      this.setState({
        selected: finalArray,
      });
    } else {
      //manipulate here
      this.setState({
        selected: [],
      });
      updateAnswerTag(null);
    }
  };

  updateSelected = (field) => {
    const { updateAnswerTag, currentQuestion } = this.props;
    let { selected } = this.state;

    if (currentQuestion.tag === "energy_level") {
      selected = [field.tag];
      updateAnswerTag(selected.toString());
    } else {
      if (
        selected.includes("none_of_the_above") &&
        field.tag !== "none_of_the_above"
      ) {
        const indexToRemove = selected.indexOf("none_of_the_above");
        selected.splice(indexToRemove, 1);
      }
      if (!selected.includes(field.tag)) {
        if (field.tag === "none_of_the_above") {
          selected = [field.tag];
        } else {
          selected.push(field.tag);
        }
      } else {
        const indexToRemove = selected.indexOf(field.tag);
        selected.splice(indexToRemove, 1);
      }
      updateAnswerTag(`[${selected.join(",")} ]`);
    }

    this.setState({
      selected,
    });
  };

  render() {
    const { currentQuestion } = this.props;
    const { selected } = this.state;
    return (
      <div className="current-question">
        <div className="fw-semibold question-text">{currentQuestion.text}</div>
        <div className="multi-options-content">
          {currentQuestion.fields &&
            currentQuestion.fields.map((field, index) => {
              let isSelected = selected.includes(field.tag);

              return (
                <div
                  className={
                    isSelected ? "selected-pa select-pa " : "select-pa "
                  }
                >
                  <span onClick={() => this.updateSelected(field)} key={index}>
                    {field.text}
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
