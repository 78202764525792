import RippleButton from "../../../components/common/RippleButton";

export default function LoadMoreButton({ loading, onClick }) {
  return (
    <div className="message-load">
      <button onClick={onClick}>
        <RippleButton nospace className="ripple-wrapper">
          {loading ? "Loading Messages" : "Load More"}
        </RippleButton>
      </button>
    </div>
  );
}
