import {
  IonContent,
  IonModal,
  IonPage,
  useIonViewDidLeave,
} from "@ionic/react";
import React, { useCallback, useContext, useEffect } from "react";
import { useHistory, useLocation, withRouter } from "react-router";
import { CircularProgress } from "@mui/material";
import moment from "moment";

import ServiceDetails from "./components/serviceDetails";
import BookingForSection from "./components/bookingForSection";
import NavigoFinalSection from "./components/navigoFinalSection";
import NavigoFirstSection from "./components/navigoFirstSection";
import ErrorComponent from "../../components/common/ErrorComponent";
import NavigoDrawerSection from "./components/navigoDrawerSection";
import { useDispatch, useSelector } from "react-redux";
import { getFamilyData, getFamilyRelations } from "../familyInfo/familySlice";
import {
  clearAppointmentAndServices,
  getAllAppointments,
  getAllServices,
  navigoClearError,
  navigoShowError,
  setStateFromPayload,
} from "./navigoSlice";
import { logEvent, logEventWithData } from "../../app/eventSlice";
import "./index.scss";
import { getExactDisplayName, getStringSlot } from "./utilityFunctions";
// import { File } from "@awesome-cordova-plugins/file";
import ZylaPay from "../../components/zylaPay/zylaPay";
import BookAfterPayment from "./components/bookAfterPayment";
import { NavContext } from "@ionic/react";
import { allRoutes } from "../../app/list";
export default function NavigoBooking() {
  const familyStore = useSelector((store) => store.familyInfo);
  const patientProfile = useSelector(
    (store) => store.login.user.patientProfile
  );
  const navigoStore = useSelector((store) => store.navigo);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const navContext = useContext(NavContext);

  const {
    loadedFamily,
    loadedRelation,
    fetchingFamily,
    fetchingRelations,
    familyData,
    relations,
  } = familyStore;

  const { services, appointments, ui } = navigoStore;
  const {
    error,
    errorMsg,
    currScreen,
    isErrorPositive,
    addressSelected,
    openDrawer,
    openZylaPay,
    paymentLoaderOrderId,
    openBookAfterPayment,
    loadedAppointments,
    loadedServices,
    fetchingAppointments,
    fetchingServices,
    serviceTypeId,
    isUserSelectedFamily,
    currentServices,
  } = ui;
  useEffect(() => {
    if (
      loadedAppointments &&
      loadedServices &&
      loadedFamily &&
      loadedRelation
    ) {
      //new code for history replace
      const searchParams = new URLSearchParams(location.search);
      let locationState = null;
      let currentScreen = 0;
      let serviceId = -1;

      if (searchParams.has("currScreen")) {
        currentScreen = searchParams.get("currScreen");
      
      }
      if (searchParams.has("serviceId")) {
        serviceId = searchParams.get("serviceId");
      }

      if (searchParams.has("isFamily")) {
        let mpId = searchParams.get("memberPatientId");
        let isMember = searchParams.get("isFamily");
        let isSelectedByUser = searchParams.get("isUserSelectedFamily");
        let memberData = null;

        if (familyData && familyData.length > 0) {
          let mdata = familyData.find((ele) => ele.memberPatientId == mpId);

          if (mdata) {
            memberData = { ...mdata };
          }
        }
        if (memberData) {
          locationState = {
            memberPatientId: mpId,
            isFamily: isMember,
            memberData: memberData,
            isSelectedByUser: isSelectedByUser,
          };
        }
      }

      //end of new code

      // const locationState = location.state;
      // console.log("location state is ", location.state);
      let familyMemberSelected = -2;
      let familyMemberSelectedData = {};
      let isSelectedByUser = false;
      if (locationState && locationState.isFamily) {
        familyMemberSelected = locationState.memberPatientId;
        familyMemberSelectedData = locationState.memberData;
        isSelectedByUser = locationState.isSelectedByUser;
      }

      let token = "";
      // this.documentService.getToken((tok) => {
      //   token = tok;
      // });
      try {
        if (patientProfile && patientProfile.countryCode != "91") {
          navContext.navigate("/tab/chat", "none", "pop");
        }
      } catch (err) {}

      let members = [];

      if (familyData && familyData.length > 0) {
        members = [...familyData];
      }

      let fmArr = [];
      let fmObj = {};
      if (members && members.length > 0) {
        fmArr = members.filter((fm) => {
          return (
            fm.countryCode == "91" &&
            fm.memberPatientId !== familyMemberSelected
          );
        });
        if (familyMemberSelected !== -2) {
          fmArr = [{ ...familyMemberSelectedData }, ...fmArr];
        }
        fmArr = fmArr.map((fm) => {
          fmObj[fm.memberPatientId] = fm.firstName;
          return {
            ...fm,
            relationName: relations.entities[fm.relation]?.value,
          };
        });
      }
      let allServices = [];
      let isServicePresent = false;
      let selectedService = null;

      if (services && services.length > 0) {
        allServices = services.map((service) => {
          let afObj = {};

          let availedForArr = service.availedFor.map((af) => {
            afObj[af] = true;
            return af;
          });

          if (service.service.ID == serviceId) {
            isServicePresent = true;
            selectedService = {
              ...service.service,
              availedForFamily: service.availedForFamily,
              availed: service.availed
                ? true
                : familyMemberSelected === -2
                ? service.availed
                : afObj[familyMemberSelected]
                ? true
                : service.availedForFamily
                ? true
                : false,
              availedFor: availedForArr,
              availedForObj: afObj,
            };
          }

          return {
            ...service.service,
            availedForFamily: service.availedForFamily,
            availed: service.availed
              ? true
              : familyMemberSelected === -2
              ? service.availed
              : afObj[familyMemberSelected]
              ? true
              : service.availedForFamily
              ? true
              : false,
            availedFor: availedForArr,
            availedForObj: afObj,
          };
        });
      }
      if (familyMemberSelected !== -2) {
        allServices = allServices.filter((service) => {
          return service.availableForFamily;
        });
      }

      let pastAppoint = [];
      let upcomingAppoint = [];

      if (appointments && appointments.past && appointments.past.length > 0) {
        pastAppoint = appointments.past.map((appoint) => {
          return {
            ...appoint,
            appointment: {
              ...appoint.appointment,
              appointmentDate: moment(
                appoint.appointment.appointmentDate
              ).format("DD MMM, YYYY"),
              timeSlot: getStringSlot(appoint.appointment.timeSlot),
              displayName: getExactDisplayName(fmObj, appoint),
            },
          };
        });
      }
      if (
        appointments &&
        appointments.upcoming &&
        appointments.upcoming.length > 0
      ) {
        upcomingAppoint = appointments.upcoming.map((appoint) => {
          return {
            ...appoint,
            appointment: {
              ...appoint.appointment,
              appointmentDate: moment(
                appoint.appointment.appointmentDate
              ).format("DD MMM, YYYY"),
              timeSlot: getStringSlot(appoint.appointment.timeSlot),
              displayName: getExactDisplayName(fmObj, appoint),
            },
          };
        });
      }

      dispatch(
        setStateFromPayload({
          familyMembers: fmArr,
          services: allServices,
          familyMemberSelected: familyMemberSelected,
          familyMemberSelectedData: familyMemberSelectedData,
          isForFamily: familyMemberSelected !== -2 ? true : false,
          pastAppointments: pastAppoint,
          upcomingAppointments: upcomingAppoint,
          currentServices: selectedService ? selectedService : currentServices,
          currScreen: selectedService
            ? 2
            : currentScreen
            ? Number(currentScreen)
            : currScreen,
          currentAddressSelected: null,
          drawerType:0,
          openDrawer:false,
          token: token,
          isUserSelectedFamily: isSelectedByUser,
        })
      );

      dispatch(
        logEventWithData({
          action: "navigo_home",
          data: { is_app_event: true },
        })
      );
    } else {
      if (!loadedAppointments && !fetchingAppointments) {
        dispatch(
          getAllAppointments({
            id: patientProfile?.id,
            serviceType: serviceTypeId,
          })
        );
      }
      if (!loadedServices && !fetchingServices) {
        dispatch(
          getAllServices({ id: patientProfile?.id, serviceType: serviceTypeId })
        );
      }
      if (!loadedFamily && !fetchingFamily) {
        dispatch(getFamilyData());
      }
      if (!loadedRelation && !fetchingRelations) {
        dispatch(getFamilyRelations());
      }
    }
  }, [
    familyData,
    loadedAppointments,
    loadedServices,
    loadedRelation,
    loadedFamily,
    location.search,
    fetchingFamily,
    fetchingRelations,
    fetchingAppointments,
    fetchingServices,
    appointments,
    services,
    patientProfile,
    relations,
  ]);

  // useEffect(() => {
  //   if (window.Capacitor.platform !== "web") {
  //     File.removeRecursively(File.documentsDirectory, "myDownloads")
  //       .then((res) => {})
  //       .catch((err) => {});
  //   }
  //   return () => {
  //     dispatch(clearAppointmentAndServices({ serviceTypeId: serviceTypeId }));
  //   };
  // }, []);

  useEffect(() => {
    let searchParams = new URLSearchParams(location.search);
    let serviceType = null;
    if (searchParams.has("serviceType")) {
      serviceType = searchParams.get("serviceType");
    }
    dispatch(clearAppointmentAndServices({ serviceTypeId: serviceType }));
  }, [location.search]);

  useEffect(() => {
    let content = document.querySelector("ion-content");
    content.scrollToTop(0);
  }, [currScreen]);

  useEffect(() => {
    if (location.pathname === "/address" && addressSelected) {
      dispatch(
        setStateFromPayload({
          currScreen: 3,
        })
      );
    }
  }, [addressSelected]);

  const handleGotoAddress = useCallback(
    (pId, memberData, isUserSelectedFamily) => {
      history.push({
        pathname: "/address",
        state: {
          isMember: pId !== -1 ? true : false,
          memberPatientId: pId,
          memberEmail: memberData.email,
          memberData: { ...memberData },
          isPath: true,
          isNavigo: true,
          path: "/navigo",
          isUserSelectedFamily: isUserSelectedFamily
            ? isUserSelectedFamily
            : "",
        },
      });
    },
    []
  );

  const handleCompletion = (result, isCancel = false) => {
    if (isCancel) {
      dispatch(
        setStateFromPayload({
          openZylaPay: false,
          paymentLoaderOrderId: "",
          openDrawer: false,
          drawerType: 0,
        })
      );
      return;
    }

    if (result) {
      dispatch(
        setStateFromPayload({
          openZylaPay: false,
          openBookAfterPayment: true,
        })
      );
    } else {
      dispatch(
        navigoShowError({ msg: "Something went wrong while making payments" })
      );
    }
  };

  const handleGoBackBooking = (arg) => {
    if (arg === 1) {
      dispatch(
        setStateFromPayload({
          currScreen: 1,
          openDrawer: false,
          openZylaPay: false,
          openBookAfterPayment: false,
        })
      );
    } else if (arg === 2) {
      dispatch(
        setStateFromPayload({
          currScreen: 1,
          addressSelected: null,
          openDrawer: false,
          openZylaPay: false,
          openBookAfterPayment: false,
        })
      );
      dispatch(logEvent({ action: `Confirm appointment` }));
      navContext.navigate("/ordercomplete?navigo=true", "none", "pop");
    }
  };

  return (
    <IonPage>
      <IonContent style={{ "--background": "#F8F9FA" }}>
        <ErrorComponent
          show={error}
          msg={errorMsg}
          isPositive={isErrorPositive}
          errorClose={() => {
            dispatch(navigoClearError());
          }}
        />

        {currScreen === 1 && (
          <NavigoFirstSection handleGotoAddress={handleGotoAddress} />
        )}
        {currScreen === 2 && (
          <BookingForSection handleGotoAddress={handleGotoAddress} />
        )}
        {currScreen === 3 && <NavigoFinalSection />}
        {currScreen === 4 && <ServiceDetails />}
        {currScreen === 5 && (
          <div className="navigo-booking-screen-5">
            <CircularProgress className="ion-router-circular-progress "></CircularProgress>
          </div>
        )}

        {openDrawer && <NavigoDrawerSection />}
        {openZylaPay && (
          <ZylaPay
            orderId={paymentLoaderOrderId}
            openZylaPay={openZylaPay}
            handleClose={(result, isCancel = false) => {
              handleCompletion(result, isCancel);
            }}
          />
        )}
        {openBookAfterPayment && (
          <IonModal
            cssClass="navigo-booking-after-pay-modal"
            isOpen={openBookAfterPayment}
            animated={false}
          >
            <BookAfterPayment
              orderId={paymentLoaderOrderId}
              handleGoBack={(arg) => {
                handleGoBackBooking(arg);
              }}
            />
          </IonModal>
        )}
      </IonContent>
    </IonPage>
  );
}
