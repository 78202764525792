import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import BackButton from "../../../components/common/BackButton";
import WalletCard from "./WalletCard";

import {
  ChevronDoubleRightIcon,
  PlusIcon,
  MinusIcon,
} from "@heroicons/react/24/solid";

import gridIcon from "../../../assets/icons/grid.svg";
import QRIcon from "../../../assets/icons/qr-code.svg";
import gemIcon from "../../../assets/icons/gem-icon.svg";
import chatIcon from "../../../assets/icons/message-circle.svg";
import WalletQuickLinkElement from "./WalletQuickLinkElement";
import { useDispatch, useSelector } from "react-redux";
import {
  getWalletTransactionByRange,
  handleScreenChanges,
} from "../walletSlice";

const walletHomeQuickLinks = [
  {
    id: 1,
    title: "Explore product",
    subtitle: "",
    link: "/tab/home",
    image: gridIcon,
    isComingSoon: false,
  },
  {
    id: 2,
    title: "Scan and Pay",
    link: "/",
    isDisabled: true,
    image: QRIcon,
    subtitle: "",
    isComingSoon: true,
  },
];

const walletHomeBottomLinks = [
  {
    id: 1,
    title: "Wallet transaction history",
    subTitle: "details of your cash usages",
    icon: gemIcon,
  },
  {
    id: 2,
    title: "FAQs",
    subTitle: "frequently asked questions",
    icon: chatIcon,
  },
];

function WalletHome() {
  const walletStore = useSelector((store) => store.wallet);
  const dispatch = useDispatch();
  const { walletInfo, walletId, walletLastFiveTransactions } = walletStore;

  useEffect(() => {
    if (walletId) {
      dispatch(
        getWalletTransactionByRange({
          id: walletId,
          count: 5,
          range: "all",
          isHome: true,
        })
      );
    }
  }, [walletId]);

  const handleQuickLinkClick = (id) => {
    if (id === 1) {
      dispatch(handleScreenChanges(2));
    } else {
      dispatch(handleScreenChanges(3));
    }
  };

  return (
    <div className="zyla-wallet-home-container">
      <div className="zyla-wallet-home-container-header">
        <div className="zyla-wallet-home-container-header-left">
          <BackButton></BackButton>
        </div>
        <p className="zyla-wallet-home-container-header-mid">Wallet</p>
        {/* <img src={QRIcon} className="zyla-wallet-home-container-header-right" /> */}
      </div>
      <div className="zyla-wallet-home-card">
        <WalletCard
          name={walletInfo.name}
          companyName={walletInfo.companyName}
          expiryDate={walletInfo.expiryDate}
          amount={walletInfo.amount}
          walletInfo={walletInfo}
        />
      </div>
      <div className="zyla-wallet-home-rct-container">
        <p className="zyla-wallet-home-general-section-heading">
          Recent transaction
        </p>
        <div className="zyla-wallet-home-rct-table">
          <div className="zyla-wallet-home-rct-table-header">
            <p>Amount</p>
            <p>Date</p>
          </div>
          <div className="zyla-wallet-home-rct-table-body">
            {walletLastFiveTransactions.map((trs) => {
              return (
                <div
                  className="zyla-wallet-transaction-section-3-ele"
                  key={trs.id}
                >
                  <div className="zyla-wallet-transaction-section-3-ele-left">
                    <div className="zyla-wallet-transaction-section-3-ele-left-left">
                      {trs.transactionType === 2 ? (
                        <MinusIcon className="zyla-wallet-transaction-debit-icon" />
                      ) : (
                        <PlusIcon className="zyla-wallet-transaction-credit-icon" />
                      )}
                    </div>
                    <div className="zyla-wallet-transaction-section-3-ele-left-right">
                      <p className="zyla-wallet-transaction-section-3-ele-left-right-top font-12px-zy-wallet font-weight-700-zy-wallet">
                        {trs.amount}
                      </p>
                      <p className="zyla-wallet-transaction-section-3-ele-left-right-bottom">
                        {trs.comment}
                      </p>
                    </div>
                  </div>
                  <div className="zyla-wallet-transaction-section-3-ele-right">
                    <p>{trs.date}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="zyla-wallet-home-quick-links">
        <p className="zyla-wallet-home-general-section-heading">Quick links</p>
        <div className="zyla-wallet-home-quick-link-wrapper">
          {walletHomeQuickLinks.map((ql) => {
            return <WalletQuickLinkElement ql={ql} key={ql.id} />;
          })}
        </div>
      </div>
      <div className="zyla-wallet-home-looking-for-answers">
        <p className="zyla-wallet-home-general-section-heading">
          Looking for answers?
        </p>
        <div className="zyla-wallet-home-lfa-link-wrapper">
          {walletHomeBottomLinks.map((ele) => {
            return (
              <div
                className="zyla-wallet-home-lfa-link-ele"
                key={ele.id}
                onClick={() => handleQuickLinkClick(ele.id)}
              >
                <div className="zyla-wallet-home-lfa-link-ele-top">
                  <img src={ele.icon} alt="icon" />
                </div>
                <div className="zyla-wallet-home-lfa-link-ele-mid">
                  <p className="zyla-wallet-home-lfa-link-ele-mid-top">
                    {ele.title}
                  </p>
                  <p className="zyla-wallet-home-lfa-link-ele-mid-bottom">
                    {ele.subTitle}
                  </p>
                </div>
                <div className="zyla-wallet-home-lfa-link-ele-bottom">
                  <ChevronDoubleRightIcon />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default withRouter(WalletHome);
