import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

export default function VitalsChartAverage() {
  const history = useHistory();
  const averageVitals = useSelector((store) => store.vitalsChart.ui.average);
  const normalPercentage = useSelector(
    (store) => store.vitalsChart.ui.normalReadingPercentage
  );
  const selectedParamUnit = useSelector(
    (store) => store.vitalsChart.ui.selectedParamUnit
  );
  const paramGroupId = useSelector((store) => store.vitalsChart.paramGroupId);
  return (
    <div className="average_vitals_container">
      <div className="average_vitals">
        <div className="vitals_value">
          <span>
            Average <strong>({selectedParamUnit})</strong>
          </span>
          <p> {averageVitals ? averageVitals : "---"}</p>
        </div>
        <hr />
        <div className="vitals_value">
          <span> Normal Readings </span>
          <p>
            {normalPercentage && paramGroupId != 5 && paramGroupId != 6
              ? `${normalPercentage}%`
              : "---"}
          </p>
        </div>
      </div>
      <div
        className="w-1/3"
        onClick={() => history.replace("/vitals")}
        style={{ textDecoration: "none" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <div className="no-vital-link-div">Share vitals </div>
        </div>
      </div>
    </div>
  );
}
