import React, { useEffect, useState } from "react";
import "../diabetesDigit/index.scss";
import { IonContent, IonModal, IonPage } from "@ionic/react";
import { useHistory, withRouter } from "react-router";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import digitService from "../../services/DigitService";
import womenCoverImg from "../../assets/images/Digit/womencover.jpg";
import zylaLogo from "../../assets/images/zyla-logo.png";
import dollyBagai from "../../assets/images/Digit/Dr Sheetal_1.jpeg";
import sheetal from "../../assets/images/Digit/DrRebeccaShwetaGladwin.jpg";
import priyankaPaul from "../../assets/images/Digit/Dr Priyanka Paull.jpeg";
import kaminiMishra from "../../assets/images/Digit/Kamini Mishra.JPG";
import namrata from "../../assets/images/Digit/Namrata.jpg";
import ketkiTawade from "../../assets/images/Digit/Ketki Tawade.jpg";
import upArrow from "../../assets/images/upArrow_img.png";
import pillarIcon1 from "../../assets/images/Digit/icon1.svg";
import pillarIcon2 from "../../assets/images/Digit/icon2.svg";
import pillarIcon3 from "../../assets/images/Digit/icon3.svg";
import pillarIcon4 from "../../assets/images/Digit/icon4.svg";
import pillarIcon5 from "../../assets/images/Digit/icon5.svg";
import pillarIcon6 from "../../assets/images/Digit/icon6.svg";
import pillar1 from "../../assets/images/Digit/pillar1.svg";
import pillar2 from "../../assets/images/Digit/pillar2.svg";
import pillar3 from "../../assets/images/Digit/pillar3.svg";
import pillar4 from "../../assets/images/Digit/pillar4.svg";
import check from "../../assets/images/Digit/check.svg";
import PACompletedImage from "../../assets/icons/ic_completed_Success.png";
import AuthenticationService from "../../services/AuthenticationService";
import PaymentDialog from "../../components/common/PaymentDialog";
import RequestCompleted from "../premium/components/requestCompleted";
import razorPayHelper from "../../utils/RazorPayHelper";
import dompurify from "dompurify";
import { useSelector } from "react-redux";
import { FAQ } from "./components/helperComponent";
const FAQs = [
  {
    title: "How is the Zyla program different?",
    content: `A personal team of Senior Doctors, Nutritionists, Physiotherapists and Stress Counselors a message away, 24*7 on your phone. Your health plan in the app is completely personalized for your schedule and updated regularly, so it’s super easy to follow. Easy reminders on readings to measure today with immediate guidance. What’s more? Audio-guided meditations so that you sleep like a baby.
      We will be with you throughout to ensure your health improves - Zyla’s care team spends 300+ minutes with you every month to correct your health (30 times that of a monthly doctor visit).
      `,
  },
  {
    title: "How do I pay for the program?",
    content:
      "It will be a 1-step safe online payment gateway. You can complete your registration in under 1-minute by simply clicking on ‘Register Now’, choosing your payment method such as net banking, credit card, debit card and complete the transaction. Soon after you complete the transaction, you will receive a call from our team to setup everything.",
  },
  {
    title: "Which other health programs do you offer?",
    content: `Here are the Zyla programs that can help you with the best of health <We will hyperlink all these when we have all pages><br /><br />
      <ul className="list-disc">
        <li><b>Covid care program:</b> To help patients who have been diagnosed with mild/ moderate Covid or showcasing Covid symptoms and now recovering at home. A holistic care plan under leading Covid specialist doctors with 24x7 support. For any queries</li>
        <li><b>Defeating Diabetes Program:</b> For patients dealing with Type 1 or Type 2 Diabetes and related complications such as Neuropathy, Nephropathy, Retinopathy</li>
        <li><b>Healthy Heart Program:</b> To treat out-of-range Total Cholesterol, LDL, HDL, Triglycerides or for patients who have undergone PTCA/ CABG heart procedures or for patients dealing with Hypertension (High BP)</li>
        <li><b>Ova Women’s Health Program:</b> For women dealing with PCOS, PCOD, Thyroid disorders, Post Menopausal Syndrome, Anemia, Osteoporosis, Arthritis</li>
        <li><b>Ova Safe Pregnancy Program:</b> For a stress-free pregnancy journey under Senior Gynecologists & experts, with pre-natal nutrition & exercise support, pre-natal community yoga, 24x7 chat support and meditation support.</li>
        <li><b>Liver Lifeline Program:</b> For patients dealing with Grade 1/ 2 Fatty liver (higher SGOT/ SGPT/ Alkaline Phosphatase) and patients who have alcoholic liver damage</li>
        <li><b>Kidney Care Program:</b> for patients dealing with Chronic Kidney Disease (Higher Creatinine/ Uric Acid or Low EGFR)</li>
        <li><b>Holistic Health program:</b> To work on weight reduction, vitamin deficiencies, low Hemoglobin, frequent allergies/ infections, and overall health for disease prevention</li>
      </ul>
      `,
  },
  {
    title: "What happens after the completion of my program?",
    content:
      "The primary goal of the Zyla Program is to get your health parameters back in range and reduce your dependence on medicines. You can stay on it for the maintenance of your health for as long as you would like.",
  },
  {
    title: "I am very busy with work, how will I manage the phone calls?",
    content:
      "We understand, and that is exactly the reason the Zyla Program is designed to completely suit your busy life. All your Progress Review Calls are pre-scheduled to suit your work timings. While your first call may take 30 minutes, all follow-up calls usually wrap up in 10-15 minutes. At special occasions, if you are unable to take the calls, you can simply inform on the app and we will make all efforts to re-schedule based on the team’s availability.",
  },
  {
    title:
      "This program is for my mother how will you manage them, they are not even comfortable in using the mobile app?",
    content: `Do not worry, we have handled thousands of parents through their caregivers. In fact, the program will be very helpful as you can pass on the stress of their health to us and the team will ensure they achieve their health goals. Without a doubt, the Zyla app will be the simplest mobile app you have ever used! It is designed keeping in mind that you are busy or may not have time to learn a complex application. What’s more? We will help you at every step and make this super easy and seamless for you.
        Read success stories of our Diabetes award winners `,
  },
];

const Team = [
  {
    image: sheetal,
    heading: "Dr. Sheetal Agarwal",
    subheading: "MBBS, DNB, MNAMS - Gynecology and Obstetrics",
    copy: "24+ years of experience. DNB (Obstetrics & Gynaecology) from the prestigious Sir Gangaram hospital, New Delhi. Currently associated Apollo Spectra hospital, previous experience at Safdarjung Hospital, Batra Hospital, Apollo Cradle.",
  },
  {
    image: dollyBagai,
    heading: "Dr. Dolly Bagai",
    subheading: "MBBS, Specialization in Family Medicine, Women’s Health",
    copy: "37+ years experience. Renowned Family Physician practicing for over 3 decades in New Delhi, specialization from the prestigious GSVM Medical College, Kanpur. ",
  },
  {
    heading: "Dr Priyanka Paul",
    subheading:
      "BAMS, PG - Clinical Nutrition, Fellowship - Pulmonary Rehabilitation",
    copy: "10+ years of experience. BAMS, PG Diploma Clinical Nutrition and Dietetics, Fellowship in Pulmonary Rehabilitation and Nutrition and Dietetics.",
    image: priyankaPaul,
  },
  {
    heading: "Dr Kamini Mishra",
    subheading: "BPT, MPT (Orthopaedics), Pelvic Floor Therapist",
    copy: "9+ years of experience. Senior Physiotherapist & Invasive Pelvic Floor Practitioner, Women health Physical Therapist. Specialization in Orthopedics.",
    image: kaminiMishra,
  },
  {
    heading: "Ketki Tawade",
    subheading: "MA - Applied Psychology, Clinical Psychology",
    copy: "Senior Therapist with specialization in Clinical Psychology. Deep experience in treating health anxiety, stress, sleep issues, depression.",
    image: ketkiTawade,
  },
  {
    image: namrata,
    heading: "Namrata Singh",
    subheading: "Pre-natal & Hatha Yoga expert",
    copy: "13+ years professional experience. Helped more than 10 Lakh people with her yoga teachings during pregnancy. Also excels in Lamaze breathing.",
  },
];

const Problems = [
  { image: pillarIcon1, title: "PCOD" },
  { image: pillarIcon2, title: "Thyroid" },
  { image: pillarIcon3, title: "Obesity/Overweight" },
  { image: pillarIcon4, title: "Post Menstrual Syndrome" },
  { image: pillarIcon5, title: "Osteoporosis" },
  { image: pillarIcon6, title: "Arthritis" },
];

const ProgramPillers = [
  {
    image: pillar1,
    heading: "Doctor Guidance",
    text: [
      "Detailed health assessment & root cause analysis ",
      "Consultations & treatment under senior doctors",
      "Regular progress review calls with medical team",
    ],
  },
  {
    image: pillar2,
    heading: "Nutrition Guidance",
    text: [
      "Regular consultations with clinical nutritionists ",
      "Personalised nutrition & meal plan",
      "Home remedies & tips for natural healing",
    ],
  },
  {
    image: pillar3,
    heading: "Exercise Guidance",
    text: [
      "Regular consultations with physiotherapists ",
      "Personalised exercise & physiotherapy plan ",
      "Live yoga sessions",
    ],
  },
  {
    image: pillar4,
    heading: "Psychological and Emotional Guidance ",
    text: [
      "Stress & anxiety counselling",
      "Audio guided meditation",
      "24x7 care & query support via chat",
    ],
  },
];

const settings2 = {
  dots: true,
  arrows: true,
  autoplay: false,
  autoplaySpeed: 2500,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

function WomenHealth({ therapy }) {
  const patientProfile = useSelector(
    (store) => store.login.user?.patientProfile
  );
  const [blogData, setBlogData] = useState(null);
  const [openPayment, setOpenPayment] = useState(false);
  const [paymentResult, setPaymentResult] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const [callBackRequested, setCallBackRequested] = useState(
    localStorage.getItem("callbackRequested") !== null ? true : false
  );

  const history = useHistory();

  useEffect(() => {
    if (!openPayment && paymentResult) {
      history.replace("/tab");
    }
  }, [openPayment]);

  useEffect(() => {
    digitService
      .getBlogsforTherapy(therapy)
      .then((result) => {
        const res = result.data;

        const shuffle = (array) => array.sort(() => Math.random() - 0.5);
        shuffle(res);
        setBlogData(res ? res.slice(0, 10) : null);
      })
      .catch((err) => {
        console.log("something went wrong while fetching data");
      });

    if (!window.rzploaded) {
      razorPayHelper.loadScript();
    }
  }, []);

  const handleCompletion = (res) => {
    setOpenPayment(true);
    setPaymentResult(res);
  };

  const paymentStarted = () => {
    if (window.rzploaded !== true) {
      return;
    }
    digitService
      .getOrderId(patientProfile?.id, {
        planId: parseInt(process.env.REACT_APP_RAZORPAY_PLAN_ID),
        currency: "INR",
      })
      .then((result) => {
        const res = result.data;
        if (!res) {
          alert("Server error.");
          return;
        }

        const options = {
          key: res.rzpKeyId, // Enter the Key ID generated from the Dashboard
          amount: parseFloat(res.amount),
          currency: res.currency,
          name: "Zyla Health",
          description: "Digit customer payment Transaction",
          image: { zylaLogo },
          order_id: res.id,
          handler: (response) => {
            const data = {
              orderCreationId: res.order_id,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            };
            handleCompletion(true);
          },
          prefill: {
            name: patientProfile?.firstname,
            email: patientProfile?.email,
          },
          notes: {
            address:
              "LGF, Saket Salcon, Rasvilas, Saket District Centre, Sec 6, Pushp Vihar, New Delhi- 110017",
          },
          theme: {
            color: "#6E5ABB",
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.on("payment.failed", (response) => {
          handleCompletion(false);
        });
        paymentObject.open();
      })
      .catch((err) => {});
  };
  const hideModal = () => {
    setOpenPayment(false);
  };

  const requestCallback = () => {
    digitService
      .requestCallBack()
      .then((res) => {
        localStorage.setItem("callbackRequested", true);
        setRequestSent(true);
      })
      .catch((err) => {
        console.log("call back cannot be requested");
      });
  };
  const handleRequestSent = () => {
    setRequestSent(false);
    setCallBackRequested(true);
  };

  return (
    <IonPage>
      <IonContent>
        {!requestSent && (
          <div className="women-health-page-container">
            <div className="women-health-container-box-1">
              <img src={zylaLogo} />
              <span>Your personal health expert</span>
            </div>
            <div className="women-health-container-box-2">
              <p className="women-health-container-box-2-top">
                Ova Women’s <br /> Health Program
              </p>
              <div className="women-health-container-box-2-bottom">
                <p>
                  A holistic women's health <br /> & 24x7 care program
                </p>
              </div>
            </div>
            <div className="women-health-container-box-3">
              <img src={womenCoverImg} />
            </div>
            <div className="women-health-container-box-4">
              <p className="women-health-container-box-4-heading">
                Ova Women’s Health Program
              </p>
              <p className="women-health-container-box-4-desc">
                Today, a woman carries multiple responsibilities on her
                shoulders, both on personal and professional fronts. These
                responsibilities are followed by an array of health concerns.
                Meticulously designed by a team of best experts, Ova Women’s
                Health Program is a holistic health program that covers all the
                vital aspects that are necessary to lead a healthy and a happy
                womanhood.
              </p>
              <div className="women-health-container-box-4-price">
                <p>
                  Program starting <br />
                  @2900 for 3 month
                </p>
              </div>
              <div
                className="womens-health-container-box-4-payment"
                onClick={() => paymentStarted()}
              >
                <span className="inner-button-span-1">Register Now</span>
                <span className="inner-button-span-2">Secured by Razorpay</span>
              </div>
            </div>
            <div className="womens-health-container-box-5">
              <div className="womens-health-container-box-5-inner">
                <h2 className="womens-health-container-box-5-inner-heading">
                  Many Problems. One Solution.
                </h2>

                <div className="womens-health-container-box-5-inner-boxes">
                  {Problems.map((p) => (
                    <div className="womens-health-container-box-5-inner-boxes-single">
                      <img src={p.image} />
                      <h2>{p.title}</h2>
                    </div>
                  ))}
                </div>

                <a
                  href="#talktoexperts"
                  className="womens-health-container-box-5-link"
                >
                  Talk to our medical expert
                </a>
              </div>
            </div>
            <div className="womens-health-container-box-6">
              <h2 className="womens-health-container-box-6-heading">
                Program Pillars
              </h2>
              <div className="womens-health-container-box-6-content">
                {ProgramPillers.map((p) => (
                  <Pillar {...p} key={p.heading} />
                ))}
              </div>
            </div>
            <div className="womens-health-box-7-container">
              <div className="womens-health-box-7-inner">
                <h2 className="womens-health-box-7-inner-heading">
                  Meet <br />
                  our Experts
                </h2>
                <div className="womens-health-box-7-inner-content">
                  {Team.map((p) => (
                    <DocCard {...p} key={p.heading} />
                  ))}
                </div>
              </div>
            </div>
            <div className="womens-health-box-8" id="talktoexperts">
              <div className="womens-health-box-8-inner">
                <iframe
                  className="womens-health-box-8-inner-iframe"
                  src="https://www.youtube.com/embed/--i6aHmw1B8"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
              <div className="womens-health-box-8-inner-callback">
                {!callBackRequested ? (
                  <div
                    className="womens-health-callback-button"
                    onClick={() => requestCallback()}
                  >
                    <span>Request Callback</span>
                  </div>
                ) : (
                  <div className="womens-health-callback-button-2">
                    <img src={PACompletedImage} />
                    <span>Call Scheduled</span>
                  </div>
                )}
              </div>
            </div>
            <div className="diabetes-container-box-3 womens-health-faq">
              <h3 className="womens-health-faq-heading">
                Frequently Asked Questions
              </h3>
              {FAQs.map((f) => (
                <FAQ title={f.title}>{f.content}</FAQ>
              ))}
            </div>
            {openPayment && (
              <IonModal cssClass="diabetes-payment-modal" isOpen={true}>
                <PaymentDialog result={paymentResult} hideModal={hideModal} />
              </IonModal>
            )}
          </div>
        )}
        {requestSent && (
          <RequestCompleted
            handleRequestSent={handleRequestSent}
            isDigit={true}
          />
        )}
      </IonContent>
    </IonPage>
  );
}

export default WomenHealth;

const Pillar = ({ image, heading, text }) => (
  <div
    className="womens-health-pillar-box"
    style={{ backgroundColor: "#FFE1EC" }}
  >
    <div className="womens-health-pillar-box-top">
      <img src={image} />
    </div>
    <div className="womens-health-pillar-box-bottom">
      <h3>{heading}</h3>
      <ul>
        {text.map((t) => (
          <li>{t}</li>
        ))}
      </ul>
    </div>
  </div>
);

const DocCard = ({ image, heading, subheading, copy }) => (
  <div className="womens-health-container-box-7-card">
    <img
      src={image}
      className="w-36 h-36 mb-4 object-cover rounded-full womens-health-container-box-7-card-img"
    />

    <h3 className="text-pink-600 font-bold text-2xl womens-health-container-box-7-card-heading">
      {heading}
    </h3>
    <h3 className="text-gray-500 mb-2 text-lg womens-health-container-box-7-card-subheading">
      {subheading}
    </h3>
    <p
      className="text-gray-800 womens-health-container-box-7-card-desc"
      style={{ fontFamily: "Montserrat" }}
    >
      {copy}
    </p>
  </div>
);
