import { IonContent, IonPage } from "@ionic/react";
import { HealthBlogs } from "./components/HealthBolgs";
import { HealthVideos } from "./components/HealthVideos";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import homeService from "../../services/HomeService";
import ZylaTV from "./zylaTV";
import { CircularProgress } from "@mui/material";
import HomePageBlogs from "./components/HomePageBlogs";

export default function () {
  const [loading, setLoading] = useState(true);
  const [videos, setVideos] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const patientProfile = useSelector(
    (state) => state.login.user.patientProfile
  );
  useEffect(() => {
    homeService
      .getHomePageData(patientProfile.id)
      .then((res) => {
        setBlogs(res.data.content.blogs || []);
        setVideos(res.data.content.videos || []);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);
  return (
    <IonPage>
      <IonContent>
        {loading ? (
          <div className="relative flex items-center justify-center h-full">
            <CircularProgress className=""></CircularProgress>
          </div>
        ) : (
          <div className="p-4">
            <HomePageBlogs blogs={blogs} />
            <ZylaTV videos={videos} />
          </div>
        )}
      </IonContent>
    </IonPage>
  );
}
