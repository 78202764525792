import { CidrRewardPointrCard } from "./cidrRewardPointCard";
import { CidrRewardProgressCard } from "./cidrRewardProgressCard";

export function CidrSection({ data, selectedPolicy }) {
  return (
    <div>
      <CidrRewardPointrCard selectedPolicy={selectedPolicy} />
      <CidrRewardProgressCard selectedPolicy={selectedPolicy} />
    </div>
  );
}
