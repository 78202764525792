import React from "react";
import "../../../assets/css/doc.scss";
export default function NoDocumentExists({ imgIcon, text }) {
  return (
    <div className="no-doc">
      <div className="no-doc-wrapper">
        <img src={imgIcon} className="no-doc-img" alt="icon"></img>
        <div className="no-doc-text">
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
}
