import React from "react";
import { useSelector } from "react-redux";
import DoctorCard from "../../../components/common/doctorCard";
export default function PremiumDoctorSection() {
  const doctors = useSelector((store) => store.tab.careTeams);
  return doctors && doctors.length > 0 ? (
    <div className="section-wrapper premium-doctor-section">
      <h2>Your care team</h2>
      <p>
        The highly experienced doctor’s team uses the TCT approach to work on
        your health with Zyla Premium.
      </p>
      <div className="scroll-div">
        {doctors && doctors.map((doctor) => <DoctorCard doctor={doctor} />)}
        {doctors && doctors.length > 0 && (
          <div className="pseudo-margin"> &nbsp;</div>
        )}
      </div>
    </div>
  ) : null;
}
