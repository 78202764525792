import React from "react";
import weightLossImg from "../../assets/images/Digit/weight_loss.jpg";
import dollyBagai from "../../assets/images/Digit/Dr_Dolly_Bagai.svg";
import priyankaPaul from "../../assets/images/Digit/Dr Priyanka Paull.jpeg";
import kaminiMishra from "../../assets/images/Digit/Kamini Mishra.JPG";
import nidhiRawat from "../../assets/images/Digit/Nidhi Rawat.jpg";
import vaishnavi from "../../assets/images/Digit/Vaishnavi.jpg";
import ketkiTawade from "../../assets/images/Digit/Ketki Tawade.jpg";
import rakhi from "../../assets/images/Digit/Rakhi Chawla.jpg";
import CommonComponent from "./components/CommonComponent";

const middleList = [
  {
    id: 1,
    p1: "Personalised holistic nutrition (Healthy eating) ",
    p2: " and Ayurveda (Natural home remedies)",
  },
  {
    id: 2,
    p1: "Physiotherapy and Yoga sessions ",
    p2: " for proactive weight management",
  },
  {
    id: 3,
    p1: "Guidance under senior ",
    p2: "doctors to deal with vitamin deficiencies",
  },
];
const Doctors = [
  {
    name: "Dr Dolly Bagai",
    image: dollyBagai,
    meta: "MBBS, Specialization in Family Medicine, Women’s Health. Renowned Family Physician, specialization from the prestigious GSVM Medical College, Kanpur. ",
    text: "MBBS, Specialization in Family Medicine, Women’s Health. Renowned Family Physician, specialization from the prestigious GSVM Medical College, Kanpur.  ",
    exp: "37+ years experience",
  },
  {
    name: "Dr Priyanka Paul",
    image: priyankaPaul,
    meta: "BAMS,PG (Diploma) in Clinical Nutrition and Dietetics with Fellowship in Pulmonary Rehabilitationand Nutrition and Dietetics ",
    text: "10+ years of rich experiene at renonwed hospitals such as Jyoti Punj, Fortis  and Apollo Hospitals. PG in Clinical Nutrition and Fellowship in Pulmonary Rehabilitation from Apollo Educational and Research Foundation. Specialises in managing chronic health disorders in patients.",
    exp: "10+ yrs experience",
  },

  {
    name: "Dr Kamini Mishra",
    image: kaminiMishra,
    meta: "MPT (Physiotherapy). Previously at NHI, Cloudnine and Apollo. Expertise in lung rehabilitation.",
    text: "9+ years of experience in treating patients with chronic health issues. Masters in Orthopedics, with previous work experience at NHI, Cloudnine and Apollo. Specializes in Physiotherapy modalities especially in chronic diseases such as Diabetes and Hypertension, an Invasive Pelvic Floor practitioner and an expert in Women health management.",
    exp: "9+ yrs experience",
  },
  {
    name: "Vaishnavi Arun",
    image: vaishnavi,
    meta: "B. Sc. Composite Home Science M. Sc. Food Science and Nutrition from Mount Carmel College with previous work experience at Bangalore Baptist Hospital, Bengaluru.",
    text: "9+ years of experience in treating patients with chronic health issues. Masters in Orthopedics, with previous work experience at NHI, Cloudnine and Apollo. Specializes in Physiotherapy modalities especially in chronic diseases such as Diabetes and Hypertension, an Invasive Pelvic Floor practitioner and an expert in Women health management.",
    exp: "9+ yrs experience",
  },
  {
    name: "Nidhi Rawat",
    image: nidhiRawat,
    meta: "Distinction holder in Therapeutic Nutrition from SGT University with additional certification in weight management as well as Yoga science. Previously at Sri Balaji Action Medical Institute.",
    text: "9+ years of experience in treating patients with chronic health issues. Masters in Orthopedics, with previous work experience at NHI, Cloudnine and Apollo. Specializes in Physiotherapy modalities especially in chronic diseases such as Diabetes and Hypertension, an Invasive Pelvic Floor practitioner and an expert in Women health management.",
    exp: "9+ yrs experience",
  },
  {
    name: "Ketki Tawade",
    image: ketkiTawade,
    meta: "MA in Applied Psychology with specialization in Clinical Psychology",
    text: "Qualified in Therapeutic Nutrition with distinction from the prestigious Lady Irwin College, Delhi University. A Diploma holder in Dietetics from VLCC and MSc. in Dietetics from IGNOU. Previous experience at Metro Hospital, Faridabad focused on chronic diseases.",
  },
];
const SecondList1 = [
  "24x7 analysis of vitals & symptoms and guidance",
  "24x7 unlimited queries and support on chat",
  "Personalized health plan (Diet & Exercise) based on your current routine with no restrictions/ fasting",
  "Consultation calls with experts",
];
const SecondList2 = [
  "Stress/ anxiety management under leading Psychotherapy experts",
  "Live Yoga sessions and live webinars with MD / DM doctors",
  "Educational content – Meditation, Recipes, Educational videos, Blogs and happiness program",
  "Savings on lab tests and medicines through trusted partners ",
];
const innerList = [
  {
    id: 1,
    day: "DAY 1",
    text: " - Detailed health assessment call (30-45 mins) for medical history",
  },
  { id: 2, day: "DAY 2", text: " - App training call" },
  {
    id: 3,
    day: "DAY 7",
    text: " - Progress review call with Clinical Nutritionist",
  },
  { id: 4, day: "DAY 21", text: " - Physiotherapist call and exercise plan" },
  {
    id: 5,
    day: "Every 15 days",
    text: " - Clinical Nutritionist/Physiotherapist call",
  },
];
const MainList = [
  `<b>Patients looking for healthy weight management</b><br />
    We work with you in healthy weight management to avoid the onset of metabolic disorders like diabetes, hypertension & cholesterol disorders.
    `,
  `<b>Patients with vitamin deficiencies and low immunity., low on energy or weakness</b><br />
    We work with you on the root cause of your symptoms by working on deficiencies and boosting immunity  through personalized nutrition.`,
];
const Patients = [
  {
    image: rakhi,
    name: "Rakhi Chawla",
    content:
      "From 'arey tune to bahut weight gain kr liya' to 'wow you loose ur weight' thanks Zyla it's like dream for me to loose weight. Thanks for your care because of you my sugar level and my blood pressure is under control and I reduce my medicine feel very much energetic and fresh.",
  },
];
const FAQs = [
  {
    title: "How is the Zyla program different?",
    content: `A personal team of Senior Doctors, Nutritionists, Physiotherapists and Stress Counselors a message away, 24*7 on your phone. Your health plan in the app is completely personalized for your schedule and updated regularly, so it’s super easy to follow. Easy reminders on readings to measure today with immediate guidance. What’s more? Audio-guided meditations so that you sleep like a baby.
          We will be with you throughout to ensure your health improves - Zyla’s care team spends 300+ minutes with you every month to correct your health (30 times that of a monthly doctor visit).
          `,
  },
  {
    title: "What is your refund policy?",
    content:
      "At Zyla, we provide care under the most able & experienced doctors. We are therefore confident of our care quality. If the user/ patient is not satisfied with the quality of care, he/ she can seek a 100% refund, no questions asked within the first 7 days of purchasing the program. However, if the care gets stalled after the commencement, by the patient, no refund shall be applicable. More details here - https://zyla.in/refund-policy",
  },

  {
    title: "What happens after the completion of my program?",
    content:
      "The primary goal of the Zyla Program is to get your health parameters back in range and reduce your dependence on medicines. You can stay on it for the maintenance of your health for as long as you would like.",
  },
  {
    title: "I am very busy with work, how will I manage the phone calls?",
    content:
      "We understand, and that is exactly the reason the Zyla Program is designed to completely suit your busy life. All your Progress Review Calls are pre-scheduled to suit your work timings. While your first call may take 30 minutes, all follow-up calls usually wrap up in 10-15 minutes. At special occasions, if you are unable to take the calls, you can simply inform on the app and we will make all efforts to re-schedule based on the team’s availability.",
  },
  {
    title:
      "This program is for my father/ mother how will you manage them, they are not even comfortable in using the mobile app?",
    content: `Do not worry, we have handled thousands of parents through their caregivers. In fact, the program will be very helpful as you can pass on the stress of their health to us and the team will ensure they achieve their health goals. Without a doubt, the Zyla app will be the simplest mobile app you have ever used! It is designed keeping in mind that you are busy or may not have time to learn a complex application. What’s more? We will help you at every step and make this super easy and seamless for you.
            Read success stories of our Diabetes award winners `,
  },
  {
    title: "Which other health programs do you offer?",
    content: `Here are the Zyla programs that can help you with the best of health<br />
          <ul className="list-disc">
            <li><b>Covid care program:</b> To help patients who have been diagnosed with mild/ moderate Covid or showcasing Covid symptoms and now recovering at home. A holistic care plan under leading Covid specialist doctors with 24x7 support. For any queries</li>
            <li><b>Defeating Diabetes Program:</b> For patients dealing with Type 1 or Type 2 Diabetes and related complications such as Neuropathy, Nephropathy, Retinopathy</li>
            <li><b>Healthy Heart Program:</b> To treat out-of-range Total Cholesterol, LDL, HDL, Triglycerides or for patients who have undergone PTCA/ CABG heart procedures or for patients dealing with Hypertension (High BP)</li>
            <li><b>Ova Women’s Health Program:</b> For women dealing with PCOS, PCOD, Thyroid disorders, Post Menopausal Syndrome, Anemia, Osteoporosis, Arthritis</li>
            <li><b>Ova Safe Pregnancy Program:</b> For a stress-free pregnancy journey under Senior Gynecologists & experts, with pre-natal nutrition & exercise support, pre-natal community yoga, 24x7 chat support and meditation support.</li>
            <li><b>Liver Lifeline Program:</b> For patients dealing with Grade 1/ 2 Fatty liver (higher SGOT/ SGPT/ Alkaline Phosphatase) and patients who have alcoholic liver damage</li>
            <li><b>Kidney Care Program:</b> for patients dealing with Chronic Kidney Disease (Higher Creatinine/ Uric Acid or Low EGFR)</li>
            <li><b>Holistic Health program:</b> To work on weight reduction, vitamin deficiencies, low Hemoglobin, frequent allergies/ infections, and overall health for disease prevention</li>
          </ul>
          `,
  },
];
function WeightManagement(props) {
  return (
    <CommonComponent
      topImg={weightLossImg}
      programName={"Weight Management program"}
      programDesc={
        "The primary focus is to work on for people who are trying to manage weight sustainably & target a healthy BMI to avoid metabolic disorders like diabetes, hypertension and heart disease."
      }
      middleListHeading={
        "The most effective Weight loss program, with a triple combination approach:"
      }
      middleListContent={middleList}
      doctorsList={Doctors}
      box4={{
        heading: "24*7 care from a leading medical team:",
        heading1: "Weight Management program",
        heading2: "₹2900 for 3 months of care",
        secondList1: SecondList1,
        secondList2: SecondList2,
        innerList: innerList,
        mainList: MainList,
      }}
      patients={Patients}
      therapy={"other"}
      faqs={FAQs}
      requestbox={{
        heading: "Ready to get started?",
        desc: "Improve overall health, under expert medical guidance",
      }}
      blogHeading={"Read More"}
    />
  );
}

export default WeightManagement;
