import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import navigoServices from "../../services/NavigoServices";
import moment from "moment";
import { getStringSlot } from "./utilityFunctions";
import { errorMessageHandler } from "../../utils/helperMethods";

const initialState = {
  services: [],
  appointments: [],
  ui: {
    currScreen: 1,
    currentServices: {},
    currentAppointment: {},
    isReschedule: false,
    isForFamily: false,
    loadingSlots: false,
    appointmentDate: null,
    timeSlot: null,
    slots: [],
    dateArr: [],
    dateSelected: -1,
    sltInd: -1,
    callReschedule: false,
    currentFamilyMemberCount: 0,
    cancelAppointmentId: -1,
    upcomingAppointments: [],
    pastAppointments: [],
    addressSelected: null,
    familyMemberSelected: -1,
    familyMemberSelectedData: {},
    callHandleAvailNow: false,
    paymentLoaderOrderId: "",
    openZylaPay: false,
    openBookAfterPayment: false,
    loadedAppointments: false,
    lodedServices: false,
    fetchingAppointments: false,
    fetchingServices: false,
    vendorId: "",
    isErrorPositive: false,
    serviceTypeId: "",
    isUserSelectedFamily: false,
  },
};

export const getAllServices = createAsyncThunk(
  "navigo/services",
  async (data, { rejectWithValue }) => {
    const { id, serviceType } = data;
    try {
      const response = await navigoServices.getServiceByPatientId(
        id,
        serviceType
      );

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getAllAppointments = createAsyncThunk(
  "navigo/appointments",
  async (data, { rejectWithValue }) => {
    try {
      const { id, serviceType } = data;
      const response = await navigoServices.getAllAppointmentsByPatientId(
        id,
        serviceType
      );
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const cancelAppointement = createAsyncThunk(
  "navigo/cancel",
  async (data, { rejectWithValue }) => {
    try {
      const response = await navigoServices.updateAppointmentStatus(data);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const rescheduleAppointments = createAsyncThunk(
  "navigo/reschedule",
  async (data, { rejectWithValue }) => {
    try {
      const response = await navigoServices.rescheduleAppointments(data);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const rescheduleLabtestAppointments = createAsyncThunk(
  "navigo/resheduleLabtest",
  async (data, { rejectWithValue }) => {
    try {
      const response = await navigoServices.cancelAndRescheduleLabAppointments(
        data
      );
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createAppointments = createAsyncThunk(
  "navigo/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await navigoServices.createAppointment(data);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAllTimeSlots = createAsyncThunk(
  "navigo/rcSlots",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = arg;
      const response = await navigoServices.getAllTimeSlots(data);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getNavigoServicesSlots = createAsyncThunk(
  "navigo/slots",
  async (arg, { rejectWithValue }) => {
    try {
      const { serviceId, date } = arg.data;
      const response = await navigoServices.getTimeSlots(serviceId, date);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const navigoSlice = createSlice({
  name: "navigo",
  initialState: initialState,
  reducers: {
    handleError: (state, actions) => {
      return {
        ...state,
      };
    },
    updateCurrentScreen: (state, actions) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          currScreen: actions.payload,
        },
      };
    },
    navigoShowError: (state, actions) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          error: true,
          errorMsg: actions.payload.msg,
          isErrorPositive: actions.payload.isPositive,
          openDrawer: false,
          openZylaPay: false,
          paymentLoaderOrderId: "",
          drawerType: 0,
          loadingSlots: false,
        },
      };
    },
    navigoClearError: (state, actions) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          error: false,
          errorMsg: "",
          isErrorPositive: false,
        },
      };
    },
    setStateFromPayload: (state, actions) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          ...actions.payload,
        },
      };
    },
    openFamilyProfileReducer: (state, actions) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          openFamilyProfile: true,
          familyProfileData: actions.payload,
          openAddFamily: false,
        },
      };
    },
    clearAppointmentAndServices: (state, actions) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          // currScreen: 1,
          loadedAppointments: false,
          fetchingAppointments: false,
          loadedServices: false,
          fetchingServices: false,
          serviceTypeId: actions.payload.serviceTypeId
            ? actions.payload.serviceTypeId
            : "",
        },
      };
    },
  },
  extraReducers: {
    [getAllServices.pending]: (state, actions) => {
      return { ...state, ui: { ...state.ui, fetchingServices: true } };
    },
    [getAllServices.fulfilled]: (state, actions) => {
      return {
        ...state,

        services: [...actions.payload.data.services],
        ui: {
          ...state.ui,
          loadedServices: true,
        },
      };
    },
    [getAllServices.rejected]: (state, actions) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          error: true,
          errorMsg: "Error while fetching services.",
        },
      };
    },
    [getAllAppointments.pending]: (state, actions) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchingAppointments: true,
        },
      };
    },
    [getAllAppointments.fulfilled]: (state, actions) => {
      return {
        ...state,
        appointments: { ...actions.payload.data },
        ui: {
          ...state.ui,
          loadedAppointments: true,
        },
      };
    },
    [getAllAppointments.rejected]: (state, actions) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          error: true,
          errorMsg: "Error while fetching appointments.",
        },
      };
    },
    [cancelAppointement.pending]: (state, actions) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          openDrawer: true,
          drawerType: 3,
        },
      };
    },
    [cancelAppointement.fulfilled]: (state, actions) => {
      const appointId = actions.meta.arg.appointmentID;
      let cancelledAppointment = {};
      let filteredList = state.ui.upcomingAppointments.filter((appoint) => {
        if (appointId === appoint.appointment.ID) {
          cancelledAppointment = { ...appoint };
        }
        return appointId !== appoint.appointment.ID;
      });
      let pastAppoint = [];
      if (state.ui.pastAppointments && state.ui.pastAppointments.length > 0) {
        pastAppoint = [
          { ...cancelledAppointment },
          ...state.ui.pastAppointments,
        ];
      } else {
        pastAppoint = [{ ...cancelledAppointment }];
      }
      return {
        ...state,
        ui: {
          ...state.ui,
          openDrawer: false,
          drawerType: 0,
          upcomingAppointments: filteredList,
          pastAppointments: pastAppoint,
          cancelAppointmentId: -1,
        },
      };
    },
    [cancelAppointement.rejected]: (state, actions) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          error: true,
          errorMsg: "Something went wrong.",
          openDrawer: false,
          drawerType: 0,
        },
      };
    },
    [rescheduleAppointments.pending]: (state, actions) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          openDrawer: true,
          drawerType: 3,
        },
      };
    },
    [rescheduleAppointments.fulfilled]: (state, actions) => {
      const res = actions.payload.data;
      let newUpcomingAppointments = state.ui.upcomingAppointments.map(
        (appoint) => {
          if (
            appoint.appointment.ID ===
            state.ui.currentAppointment.appointment.ID
          ) {
            return {
              ...appoint,
              appointment: {
                ...appoint.appointment,
                appointmentDate: moment(res.appointmentDate).format(
                  "DD MMM, YYYY"
                ),
                timeSlot: getStringSlot(res.timeSlot),
                status: res.status,
              },
            };
          }
          return { ...appoint };
        }
      );
      return {
        ...state,
        ui: {
          ...state.ui,
          openDrawer: false,
          drawerType: 0,
          currScreen: 1,
          upcomingAppointments: newUpcomingAppointments,
          isReschedule: false,
          callReschedule: false,
        },
      };
    },
    [rescheduleAppointments.rejected]: (state, actions) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          error: true,
          errorMsg: "Reschedule failed.",
          openDrawer: false,
          drawerType: 0,
          callReschedule: false,
        },
      };
    },
    [rescheduleLabtestAppointments.pending]: (state, actions) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          openDrawer: true,
          drawerType: 3,
        },
      };
    },
    [rescheduleLabtestAppointments.fulfilled]: (state, actions) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          callReschedule: true,
        },
      };
    },
    [rescheduleLabtestAppointments.rejected]: (state, actions) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          openDrawer: false,
          drawerType: 0,
          error: true,
          errorMsg: "Reschedule failed.",
        },
      };
    },
    [createAppointments.pending]: (state, actions) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          openDrawer: true,
          drawerType: 3,
        },
      };
    },
    [createAppointments.rejected]: (state, actions) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          openDrawer: false,
          drawerType: 0,
          error: true,
          errorMsg: errorMessageHandler(
            actions.payload.response.data,
            "Couldn't create appointment."
          ),
        },
      };
    },
    [getAllTimeSlots.pending]: (state, actions) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          loadingSlots: true,
          dateSelected: actions.meta.arg.dateSelected,
          appointmentDate: actions.meta.arg.appointmentDate,
        },
      };
    },
    [getAllTimeSlots.fulfilled]: (state, actions) => {
      const res = actions.payload.data;
      let allSlots = [];
      if (res && res.slots && res.slots.length > 0) {
        allSlots = res.slots.map((slt, index) => {
          return { id: slt.key, time: getStringSlot(slt.value) };
        });
      }

      return {
        ...state,
        ui: {
          ...state.ui,
          slots: allSlots,
          timeSlot: null,
          loadingSlots: false,
          sltInd: -1,
          vendorId: res.vendorId,
        },
      };
    },
    [getAllTimeSlots.rejected]: (state, actions) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          loadingSlots: false,
          error: true,
          errorMsg: "error while fetching slots.",
        },
      };
    },
    [getNavigoServicesSlots.pending]: (state, actions) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          loadingSlots: true,
          dateSelected: actions.meta.arg.dateSelected,
          appointmentDate: actions.meta.arg.appointmentDate,
        },
      };
    },
    [getNavigoServicesSlots.fulfilled]: (state, actions) => {
      const res = actions.payload.data;
      let allSlots = [];
      if (res && res.slot && res.slot.length > 0) {
        allSlots = res.slot.map((slt, index) => {
          return { id: index, time: getStringSlot(slt) };
        });
      }
      return {
        ...state,
        ui: {
          ...state.ui,
          slots: allSlots,
          timeSlot: null,
          loadingSlots: false,
          sltInd: -1,
        },
      };
    },
    [getNavigoServicesSlots.rejected]: (state, actions) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          loadingSlots: false,
          error: true,
          errorMsg: "No slot available for selected date.",
        },
      };
    },
  },
});

export const {
  updateCurrentScreen,
  navigoShowError,
  navigoClearError,
  setStateFromPayload,
  clearAppointmentAndServices,
} = navigoSlice.actions;
export default navigoSlice.reducer;
