import React from "react";
import SentTick from "../../../../assets/images/ic_tick_grey_1.png";
import InProgressClock from "../../../../assets/images/ic_in_progress.png";
import { currentTimeFormat } from "../../../../utils/dateManager";

export default function TimeMessage({ message }) {
  const mine = message.mine;
  const time = message.createdAt;
  const isTransactionPending = message.isTransactionPending;
  return (
    <div className="flex justify-end pt-1 items-center">
      {mine && (
        <img
          alt=""
          className="message-status-icon"
          src={isTransactionPending ? InProgressClock : SentTick}
        />
      )}
      <small>{currentTimeFormat(time).toLowerCase()}</small>
    </div>
  );
}
