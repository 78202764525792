import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import familyPlanServices from "../../services/FamilyPlanServices";

const emptyAddress = {
  city: "",
  countryCode: "91",
  district: "",
  landmark: "",
  line1: "",
  line2: "",
  name: "",
  phoneNo: "",
  pincode: "",
  state: "",
  type: 1,
};

const initialState = {
  addressbook: [],
  openEdit: false,
  openRemoveDialog: false,
  loading: true,
  addnewAddress: false,
  layout: 1,
  showToast: "",
  memberPatientId: -1,
  medicineOrderAddress: -1,
  selectMedicineOrderAddress: -1,
  disabledDeliveryAddress: true,
  defaultLayout: 1,
  indexOfAddressToRemove: -1,
  addressToRemove: {},
  selectedAddress: {
    city: "",
    countryCode: "91",
    district: "",

    landmark: "",
    line1: "",
    line2: "",
    name: "",

    phoneNo: "",
    pincode: "",
    state: "",
    type: "1",
  },
  isEdit: false,
  memberData: {},
  editRemoveIndex: -1,
  path: "",
  buttonMsg: "Deliver to this address",
  error: false,
  errorMsg: "",
  addressLoaded: false,
};

export const removeAddress = createAsyncThunk(
  "removeAddress",
  async (data, { rejectWithValue }) => {
    try {
      const { memberPatientId, id } = data;

      const response = await familyPlanServices.deleteAddress(
        memberPatientId,
        id
      );

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAddresses = createAsyncThunk(
  "get/address",
  async (data, { rejectWithValue }) => {
    try {
      const { id } = data;
      const response = await familyPlanServices.getAllAddress(id);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    handleRadioBoxChange: (state, action) => {
      return {
        ...state,
        selectMedicineOrderAddress: action.payload,
        disabledDeliveryAddress: false,
      };
    },
    handleEditAddress: (state, action) => {
      return {
        ...state,
        selectedAddress: {
          ...action.payload,
          type:
            action.payload.type === "Home"
              ? 1
              : action.payload.type === "Work"
              ? 2
              : 3,
        },
        isEdit: true,
        layout: 3,
        editRemoveIndex: -1,
      };
    },
    handleEditRemove: (state, action) => {
      return {
        ...state,
        editRemoveIndex: state.editRemoveIndex === -1 ? action.payload : -1,
      };
    },
    handleAddressAddition: (state, action) => {
      return {
        ...state,
      };
    },
    setStateFromPayloadAddress: (state, actions) => {
      return {
        ...state,
        ...actions.payload,
      };
    },
    toggleDisabledAddress: (state, actions) => {
      return {
        ...state,
        disabledDeliveryAddress: actions.payload,
      };
    },
    handleAddressErrorReducer: (state, action) => {
      return {
        ...state,
        error: action.payload.err,
        errorMsg: action.payload.errMsg,
        disabledDeliveryAddress: false,
        loading: false,
      };
    },
  },
  extraReducers: {
    [removeAddress.rejected]: (state, action) => {
      return {
        ...state,
        error: true,
        errorMsg: "Something went wrong",
        editRemoveIndex: -1,
        openRemoveDialog: false,
      };
    },
    [removeAddress.fulfilled]: (state, action) => {
      console.log("action is ", action);
      let newAddressBook = state.addressbook.filter(
        (addr) => addr.id !== state.addressToRemove.id
      );
      return {
        ...state,
        addressToRemove: {},
        showToast: "Address removed",
        editRemoveIndex: -1,
        addressbook: [...newAddressBook],
        openRemoveDialog: false,
      };
    },
    [getAddresses.fulfilled]: (state, actions) => {
      return {
        ...state,
        addressLoaded: true,
      };
    },
  },
});

export const {
  handleRadioBoxChange,
  handleEditAddress,
  handleEditRemove,
  setStateFromPayloadAddress,
  handleCloseRemoveDialog,
  handleOpenRemoveDialog,
  toggleDisabledAddress,
  handleAddressErrorReducer,
} = addressSlice.actions;
export default addressSlice.reducer;
