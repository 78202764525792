import React, { useEffect } from "react";

import moment from "moment";
import { useState } from "react";
import { useRef } from "react";

import CommonCardMessage from "./commonCard";
import { updateAndSaveReports } from "../../../reporting/reportSlice";
import { useDispatch, useSelector } from "react-redux";
import TimeMessage from "./time";
import reportingServices from "../../../../services/ReportingService";

export default function ReportCardMessage({ message }) {
  const containerRef = useRef();
  const dispatch = useDispatch();
  const [reportingDate, setReportingDate] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const reportingStore = useSelector((state) => state.reporting);
  const loginStore = useSelector((state) => state.login);
  const { user } = loginStore;
  const patientId = user.patientProfile.id;
  const { reports } = reportingStore;

  useEffect(() => {
    const content = message.content.message;

    if (content && content.metadata && content.metadata.reportingDate) {
      const reportingDate = content.metadata.reportingDate;
      let requestDate = reportingDate;

      checkHeight();
      if (reportingDate.length > 10) {
        requestDate = moment(reportingDate).format("DD-MM-YYYY");
      }

      setReportingDate(requestDate);
      if (!reports[requestDate]) {
        reportingServices
          .getReportsByDate(requestDate, patientId)
          .then((res) => {
            const data = res.data;
            dispatch(
              updateAndSaveReports({
                date: requestDate,
                report: {
                  data: reportingServices._postProcessResponse(data),
                  _id: data._id,
                },
              })
            );
          })
          .catch((err) => {});
      }
    }
  }, [message, patientId, dispatch]);

  const checkHeight = () => {
    if (containerRef.current) {
      let cardDimensions = containerRef.current.getBoundingClientRect();
      let cardHeight = cardDimensions.height;

      if (cardHeight > 200) {
        setIsExpanded(false);
        setShowOptions(true);
      }
    }
  };
  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div style={{ minHeight: "120px" }}>
      <div className="content-card">
        <CommonCardMessage
          title={"Reporting"}
          time={moment(reportingDate, "DD-MM-YYYY")}
        />
        <div className="card-border-fix card-radius-fix" ref={containerRef}>
          <div
            className={`content-of-card ${
              !isExpanded && showOptions && "partial-view"
            }`}
          >
            {reports &&
              reports[reportingDate] &&
              reports[reportingDate].data.map((data, key) => {
                const tt = `${data.hour} : ${data.minute} ${data.dayNight}`;
                return (
                  <div key={key} className="reporting-list">
                    <div className="time-report">{tt}</div>
                    <div className="info-report">{data.activity}</div>
                  </div>
                );
              })}
          </div>
          {showOptions ? (
            <div className="show-options" onClick={toggleExpanded}>
              {isExpanded ? "Show Less..." : "Show More..."}
            </div>
          ) : null}
        </div>
      </div>
      <TimeMessage message={message} />
    </div>
  );
}
