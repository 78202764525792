import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CleverTapLogger from "../../src/clevertapLogger";

const initialState = {
  events: [],
};

export const logEvent = createAsyncThunk(
  "log/event",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const user = state.login.user;
      CleverTapLogger.push(data.action, null, user);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const logEventWithData = createAsyncThunk(
  "log/eventWithData",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const user = state.login.user;

      CleverTapLogger.push(data.action, data.data, user);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    // logEvent: (state, action) => {
    //   CleverTapLogger.push(action.payload.action);
    // },
    // logEventWithData: (state, action) => {
    //   CleverTapLogger.push(action.payload.action, action.payload.data);
    // },
  },
});

export const {} = eventSlice.actions;
export default eventSlice.reducer;
