import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { logEvent } from "../../../app/eventSlice";
import React from "react";
import arrowRight from "../../../assets/icons/arrow-narrow-right.png";
import careTeamAvatar from "../../../assets/images/avatar.png";
import DoctorDetailModal from "./doctorDetailModal";

function DoctorCard({ doctor, onClick, docToken }) {
  let profilePic = doctor.profile_image;
  return (
    <div className="doctor-cards" key={doctor.id}>
      <div className="dr-card-wrapper" onClick={onClick}>
        <div>
          <img
            alt=""
            src={
              profilePic ? `${profilePic}?token=${docToken}` : careTeamAvatar
            }
            className="doctor-image"
            onError={() => {
              // onE = null;
              profilePic = careTeamAvatar;
            }}
          />
          <div>
            <h3>{doctor.name}</h3>
            <p>{doctor.title}</p>
          </div>
        </div>
        <p>{doctor.description}</p>
      </div>
    </div>
  );
}

export default function CareTeamComponent({
  showCareTeam,

  uiConfig,
  doctors,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [show, setShow] = useState(showCareTeam);
  const docToken = useSelector((state) => state.tab.docToken?.token);
  const [selectedDoctor, setSelectedDoctor] = useState(undefined);
  const isPremium = uiConfig.isPremium;
  useEffect(() => {
    setShow(showCareTeam);
  }, [showCareTeam]);

  return (
    <>
      {show && (
        <div className={` care-team-section`}>
          {isPremium && <span>Your care team</span>}
          {!isPremium && (
            <span
              onClick={() => {
                dispatch(logEvent({ action: "Premium_CTA_Arrow" }));
                history.push("/premium");
              }}
            >
              Unlock your care team with premium
              <img src={arrowRight} alt="" />
            </span>
          )}
          <div className="doctor-card-wrapper">
            {doctors &&
              doctors.map((doctor) => (
                <DoctorCard
                  docToken={docToken}
                  onClick={() => {
                    setSelectedDoctor(doctor);
                  }}
                  doctor={doctor}
                ></DoctorCard>
              ))}
          </div>
          {selectedDoctor && (
            <DoctorDetailModal
              docToken={docToken}
              doctor={selectedDoctor}
              onClose={() => {
                setSelectedDoctor(undefined);
              }}
              open={selectedDoctor ? true : false}
            ></DoctorDetailModal>
          )}
        </div>
      )}
    </>
  );
}
