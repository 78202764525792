import React from "react";
import blackCalendar from "../../../assets/icons/black-calendar-icon.svg";
import blackPeopleIcon from "../../../assets/icons/black-people-icon.svg";
import blackClock from "../../../assets/icons/black-clock-icon.svg";

export default function SingleAppointmentComponent({
  serviceName,
  displayName,
  date,
  timeSlot,
}) {
  return (
    <>
      <div className="navigo-booking-container-section-3-ele-top">
        {serviceName}
      </div>
      <div className="navigo-booking-container-section-3-ele-mid">
        <div>
          <img src={blackCalendar} />
          <p>{date}</p>
        </div>
        <div>
          <img src={blackClock} />
          <p>{timeSlot}</p>
        </div>
        {displayName && (
          <div>
            <img src={blackPeopleIcon} />
            <p>{displayName}</p>
          </div>
        )}
      </div>
    </>
  );
}
