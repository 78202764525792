import StorageService from "./StorageService";
import SessionManager from "../SessionManager";
import IndexDbService from "./IndexDbService";

import male from "../assets/images/ic_male_active.png";
import fem from "../assets/images/ic_female_active.png";
import Gender from "../models/Gender";
import APIConfig from "./APIConfig";
import { App } from "@capacitor/app";
import { Device } from "@capacitor/device";
class AuthenticationService {
  constructor() {
    this.storage = new StorageService();
    this.session = new SessionManager();

    let environment = "uat";
    if (
      process.env.NODE_ENV == "development" &&
      process.env.REACT_APP_CUSTOM_NODE_ENV
    ) {
      environment = process.env.REACT_APP_CUSTOM_NODE_ENV;
    }

    this.KEY_USER_PROFILE = "PWA_" + environment + "_USER_PROFILE";
    this.KEY_LAT_USER_PROFILE = "LATEST_USER_PROFILE";
    this.KEY_CLIENT = "client";
    this.KEY_PHONE_NO = "phone_no";
    this.KEY_USER_IDENTITY = "USER_IDENTITY";

    this.CLIENT = "patient_android";
  }

  activateSession(token) {
    this.session.setSession(token, 1051200); //2 years
  }

  activateLogin = (data, platform = "web") => {
    // if (platform === "web") {
    localStorage.setItem("zy.uid", data.user.id);
    if (data.user.patientProfile) {
      localStorage.setItem("zy.pid", data.user.patientProfile.id);
    }
    localStorage.setItem("zy.phone_no", data.user.phoneNo);
    localStorage.setItem("zy.token", data.token.accessToken);
    localStorage.setItem("zy.etoken", data.token.expiryToken);
    localStorage.setItem("zy.client", "patient_android");
    localStorage.setItem("zy.ACCESS_TOKEN", JSON.stringify(data.token));
    // }
    //  else {
    //   this.sessionManager.setCookie("uid", data.user.id);
    //   if (data.user.patientProfile) {
    //     this.sessionManager.setCookie("pid", data.user.patientProfile.id);
    //   }
    //   this.sessionManager.setCookie("phone_no", data.user.phoneNo);
    //   this.sessionManager.setCookie("token", data.token.accessToken);
    //   this.sessionManager.setCookie("etoken", data.token.expiryToken);
    //   this.sessionManager.setCookie("client", "patient_android");
    //   localStorage.setItem("zy.ACCESS_TOKEN", data.token);
    // }
  };

  getSessionToken() {
    return this.storage.get("TOKEN");
    // return this.session.getSession();
  }

  setToken(token) {
    this.storage.set("zy.ACCESS_TOKEN", token);
    sessionStorage.setItem("auth_token", token.accessToken);
    //         token:
    // accessToken: "eyJraWQiOjMxNiwiYWxnIjoiUlMyNTYifQ.eyJpc3MiOiJaeWxhIiwic3ViIjoiYWNjZXNzX3Rva2VuIiwiYXVkIjoienlsYSIsImV4cCI6MTY1NTY2ODY4NCwibmJmIjoxNjU1NTgyMjg0LCJpYXQiOjE2NTU1ODIyODQsImp0aSI6IjUxMTczIiwicGhvbmVObyI6Ijk5MTE5OTAwMTIiLCJ3aG8iOiJwYXRpZW50IiwiY291bnRyeUNvZGUiOiI5MSJ9.VrjsOzDQe3UBtdqxBGY-PAaVAy9cAj9Qte2FjnmIW4jZIjW-TMv_SCeIZ9snWj0vrVaO53y1W2eYaWYz4YiDdQZ8h2zTufrX3jwl7JRCDnJ409ncYfC5vxr4oL_sspsfwEXls_piUbfbwYMb4P6lWG8HypUVEE9pROrlWfu8szTjYGIkhMwgk3y_dBhhxAaj5nsGVQBHJ-jAGMv0vzkH3BQi9dmS_Con0Xq0VxhHFHw0V1mTvvPxGtkMmHJnKrsS59zMa4iGI6U8NT4sD1VHyYBvO0aplWxMvIq3aiX5XemGGO-V-r2R5jWZjykU6EwaCSEjRvaxS6M_sqSqZ1xRow"
    // expiryToken:
  }
  getToken(status) {
    if (status) {
      return this.storage.get("zy.ACCESS_TOKEN");
    } else {
      return sessionStorage.getItem("auth_token");
    }
    //         token:
    // accessToken:
    // expiryToken:
  }
  logout() {
    this.session.deleteSession();
    this.storage.clearAll();
    sessionStorage.clear();
    var indexDbService = new IndexDbService();
    return indexDbService.clearAll();
  }
  getDeviceInfo() {
   
    Device.getInfo().then(async (info) => {
      let appInfo=await App.getInfo();
      return {
        appVersion: appInfo.version,
        device: info.platform,
        deviceName: info.model,
        osVersion: info.osVersion,
      };
    });
  }
  isRegistered() {
    let user = this.getUserProfile();
    let registered = "login";

    if (user) {
      if (user.otpSent && !user.patientProfile && user.otp !== 0) {
        registered = "register";
      } else if (user.patientProfile) {
        registered = "chat";
      }
    }

    return registered;
  }
  isLoggedIn() {
    let user = this.getUserProfile();
    if (user && user.patientProfile) {
      return true;
    }

    return false;
  }

  updateProfileImage(image) {
    let user = this.getUserProfile();

    user.patientProfile.profileImage = image;
    this.storage.set(this.KEY_USER_PROFILE, user);
  }
  setUserProfile(user) {
    this.storage.set(this.KEY_USER_PROFILE, user);
    this.storage.set(this.KEY_USER_IDENTITY, "");
    this.setDefaultCookies();
  }

  getUserProfile() {
    return this.storage.get(this.KEY_USER_PROFILE);

    //   return await this.storage
    // .get(this.KEY_USER_PROFILE)
    // .then((res) => {
    //   return res;
    // })
    // .catch((er) => {
    //   console.log(er);
    // });
    // }
  }
  getLatestUserProfile() {
    let user = this.storage.get(this.KEY_LAT_USER_PROFILE);
    if (user) {
      return user;
    }
    return this.storage.get(this.KEY_USER_PROFILE);
  }
  setLatestUserProfile(user) {
    this.storage.set(this.KEY_LAT_USER_PROFILE, user);
  }

  setDefaultCookies() {
    this.session.setCookie(this.KEY_CLIENT, this.CLIENT, 1051200); // 2 years

    if (this.isLoggedIn()) {
      this.session.setCookie(
        this.KEY_PHONE_NO,
        this.getUserProfile().phoneNo,
        1051200
      ); // 2 years

      // one time
      this.activateSession(this.getSessionToken());
    }
  }

  getUserName() {
    let name = "User";
    let user = this.getUserProfile();

    if (user && user.employeeProfile) {
      if (user.employeeProfile.firstName) {
        name =
          user.employeeProfile.firstName +
          (user.employeeProfile.lastName
            ? " " + user.employeeProfile.lastName
            : "");
      }
    }

    return name;
  }

  getUserProfileImage() {
    let image;
    let user = this.getUserProfile();

    if (user && user.patientProfile) {
      image = user.patientProfile.profileImage
        ? user.patientProfile.profileImage
        : undefined;
    }

    let gender = 1;

    if (user && user.patientProfile) {
      gender = user.patientProfile.gender;
    }

    let females = [fem];
    let males = [male];

    let rand;

    if (!image) {
      let storedIdentity = this.storage.get(this.KEY_USER_IDENTITY);

      switch (gender) {
        case Gender.FEMALE:
          rand = storedIdentity ? storedIdentity : 1;
          image = females[rand - 1];

          break;
        case Gender.MALE:
          rand = storedIdentity ? storedIdentity : 1;
          image = males[rand - 1];

          break;
        default:
          image = fem;
          break;
      }

      this.storage.set(this.KEY_USER_IDENTITY, rand);
    }

    return image;
  }

  autoLogin(phoneNo, token, onResponse, onError) {
    let that = this;
    const url = APIConfig.user.autoLogin.format(phoneNo);
    // this.apiService.getWithHeader(
    //   url,
    //   phoneNo,
    //   token,
    //   (response) => {
    //     this.authenticationService.setUserProfile(response.user);
    //     this.authenticationService.activateSession(response.user.authToken);
    //     onResponse(response);
    //   },
    //   onError
    // );
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        phone_no: phoneNo,
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function (response) {
        if (response.status === 201) {
          response.json().then(function (data) {
            onResponse(data);
            return true;
          });
          return true;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return false;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          let savedData = that.getUserProfile();
          if (savedData && data.user.phoneNo !== savedData.phoneNo) {
            try {
              that.logout();
            } catch (err) {}
          }
          that.setUserProfile(data.user);
          that.activateSession(data.user.authToken);

          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (error) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  autoLoginDigit(phoneNo, token, onResponse, onError) {
    let that = this;

    let url = APIConfig.user.autoLoginDigit.format(phoneNo, token);

    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        phone_no: phoneNo,
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function (response) {
        if (response.status === 201) {
          response.json().then(function (data) {
            onResponse(data);
            return true;
          });
          return true;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return false;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          let savedData = that.getUserProfile();
          if (savedData && data.user.phoneNo !== savedData.phoneNo) {
            try {
              that.logout();
            } catch (err) {}
          }
          that.setUserProfile(data.user);
          that.activateSession(data.user.authToken);

          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (error) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
}

export default AuthenticationService;
