import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { shouldRedirectToNavigo } from "../../../utils/helperMethods";
import "./WalletQuickLinkElement.scss";
function WalletQuickLinkElement({ ql }) {
  const clientConfig = useSelector((store) => store.tab.clientConfig);
  const patientProfile = useSelector(
    (store) => store.login.user?.patientProfile
  );
  const history = useHistory();
  return (
    <div
      className="zyla-wallet-home-quick-link-ele"
      key={ql.id}
      onClick={() => {
        if (!ql.isDisabled) {
          if (ql.isNavigo) {
            history.push(ql.link);
          } else {
            if (ql.clickFunc) {
              ql.clickFunc(patientProfile);
            } else {
              history.push(ql.link);
            }
          }
        }
      }}
    >
      <div className="zyla-wallet-home-quick-link-ele-top">
        <div className="zyla-wallet-home-quick-link-ele-img-wrapper">
          <img src={ql.image} alt="icon" />
          {ql.isComingSoon && (
            <div className="zyla-wallet-home-quick-link-ele-coming-soon-wrapper">
              <p>Coming soon</p>
            </div>
          )}
        </div>
      </div>
      <div className="zyla-wallet-home-quick-link-ele-bottom">
        <p>{ql.title}</p>
        <p>{ql.subtitle}</p>
      </div>
    </div>
  );
}

export default WalletQuickLinkElement;
