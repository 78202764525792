import { Camera,CameraResultType,CameraSource } from "@capacitor/camera";
import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { FilePicker } from "@capawesome/capacitor-file-picker";
class PrepareUploadService {
  buildDocumentFromFile(file) {
    let document = {
      id: "",
      title: "",
      description: "",
      url: "",
      type: 6,
      format: 1,
      status: 4,
      file,
    };
    if (file.type === "image/jpg" || file.type === "image/jpeg") {
      document.format = 1;
    } else if (file.type === "image/png") {
      document.format = 2;
    } else if (file.type === "image/gif") {
      document.format = 3;
    } else if (file.type === "application/pdf") {
      document.format = 4;
    } else if (file.type === "audio/mp3") {
      document.format = 5;
    } else if (file.type === "video/mp4") {
      document.format = 6;
    } else {
      return null;
    }
    return document;
  }
  _initiateUpload(e) {
    const file = e;

    let document = {
      title: "",
      description: "",
      url: "",
      type: 6,
      format: 1,
      status: 4,
    };
    if (file.meta.imageType === "jpg" || file.meta.imageType === "jpeg") {
      document.format = 1;
    } else if (file.type === "png") {
      document.format = 2;
    } else {
      return;
    }

    return document;
  }
  paytmGallery(startUpload) {
    const requestObjectP = {
      permission: "storage",
    };

    window.JSBridge.call("paytmCheckPermission", requestObjectP, (result) => {
      if (result.data.storage !== 1) {
        const requestObject1 = {
          permission: "storage",
        };

        window.JSBridge.call(
          "paytmRequestPermission",
          requestObject1,
          (result) => {
            if (result.data.storage !== 1) {
              const requestObject = {
                requestCode: 456,
                title: "Select Image",
              };
              window.JSBridge.call(
                "paytmOpenGallery",
                requestObject,
                (result) => {
                  if (result.data) {
                    const uploadReq = this._initiateUpload(result.data);
                    startUpload(uploadReq);
                  }
                }
              );
            } else return;
          }
        );
      } else {
        const requestObject = {
          requestCode: 456,
          title: "Select Image",
        };
        window.JSBridge.call("paytmOpenGallery", requestObject, (result) => {
          if (result.data) {
            const uploadReq = this._initiateUpload(result.data);
            startUpload(uploadReq);
          }
        });
      }
    });
  }
  async takePicture() {
    
    return new Promise(async (resolve, reject) => {

      const image = await Camera.getPhoto({
        quality: 100,
        allowEditing: false,
        correctOrientation: false,
        
        
        // source: CameraSource.Camera,
        resultType: CameraResultType.Uri,
        // webUseInput: false,
      });
      
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      if (image) {
        const blob = await fetch(image.webPath).then((r) => r.blob());

        // let fileExtension = image.webPath.substring(
        //   image.webPath.lastIndexOf(".") + 1,
        //   image.webPath.length
        // );
        let fileName = Math.random() * (9999999 - 1111111) + 1111111;
        let fileExtension = image.format;
        resolve({
          file: blob,
          name: `${fileName}.${fileExtension}`,
          document: {
            id: "",
            title: "",
            description: "",
            url: "",
            type: 6,
            format: 1,
            status: 4,
          },
        });
      } else {
        return resolve(null);
      }
    });
  }

  async pickPictures() {
    
    return new Promise(async (resolve, reject) => {

      const images = await Camera.pickImages({
        quality: 100,
        allowEditing: false,
        correctOrientation: false,
        
        
        source: CameraSource.Photos,
        resultType: CameraResultType.Uri,
        // webUseInput: false,
      });
      
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)

      let errors={
        sizeError:false,
        countError:0
       }
     
      if (images && images.photos && images.photos.length>0) {
        const photos=images.photos.slice(0,5)
        let fileResponse={target:{
          files:[]
        }};
       
        if(images.photos.length>5){
          errors.countError=images.photos.length-5;
        }
        Promise.allSettled(photos.map((ele,ind)=>{
          return new Promise((resolve,reject)=>{
            if(ele.size<=20000000){
              fetch(ele.webPath).then((r) => r.blob()).then((blob)=>{
                let fileName = Date.now();
                let fileExtension = `image/${ele.format}`;
                const file={
                  file:blob,
                  name:`${fileName}.${ele.format}`,
                  size:blob.size,
                  type:fileExtension,
                  isBlob:true,
      
                }
                resolve(file)
              }).catch((err)=>{
                resolve(null)
              })
            }else{
              errors.sizeError=true;
              resolve(null)
            }
            
           
    
  
          })
            
          })).then((res)=>{
           
            let response={target:{files:[]}};
            res.forEach((ele)=>{
              if(ele.value){
                response.target.files.push(ele.value);
              }
            })
            resolve({fileResponse:response});
          }).catch(err=>{})

      } else {
        return resolve(null);
      }
    });
  }

  async selectFiles(isMultiple=true) {
    
    return new Promise(async (resolve, reject) => {

      let files=await FilePicker.pickImages({
        multiple: isMultiple,
      
      })
      
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)

     
      let errors={
        sizeError:false,
        countError:0
       }
      if (files && files.files && files.files.length>0) {
        const myFiles=files.files.slice(0,5)
        if(files.files.length>5){
          errors.countError=files.files.length-5;
        }
        Promise.allSettled(myFiles.map((ele,ind)=>{
          return new Promise((resolve,reject)=>{
            if(ele.size<=20000000){

            
            const path=Capacitor.convertFileSrc(ele.path);
            fetch(path).then((r) => r.blob()).then((blob)=>{
             
              const file={
                file:blob,
                name:ele.name,
                size:ele.size,
                type:ele.mimeType,
                isBlob:true,
    
              }
              resolve(file)
            }).catch((err)=>{
              resolve(null)
            })
          }else{
            errors.sizeError=true;
            resolve(null)
          }
            
           
    
  
          })
            
          })).then((res)=>{
           
            let response={target:{files:[]}};
            res.forEach((ele)=>{
              if(ele.value){
                response.target.files.push(ele.value);
              }
            })
            resolve({fileResponse:response,errors:errors});
          }).catch(err=>{})

      } else {
        return resolve(null);
      }
    });
  }
  async pickFiles(isMultiple=true,type=["application/pdf"]) {
    
    return new Promise(async (resolve, reject) => {

      let files=await FilePicker.pickFiles({
        types:[...type],
        multiple: isMultiple,
      })
      
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)

     
      let errors={
        sizeError:false,
        countError:0
       }
      if (files && files.files && files.files.length>0) {
        const myFiles=files.files.slice(0,5)
        if(files.files.length>5){
          errors.countError=files.files.length-5;
        }
        Promise.allSettled(myFiles.map((ele,ind)=>{
          return new Promise((resolve,reject)=>{
            if(ele.size<=20000000){

            
            const path=Capacitor.convertFileSrc(ele.path);
            fetch(path).then((r) => r.blob()).then((blob)=>{
             
              const file={
                file:blob,
                name:ele.name,
                size:ele.size,
                type:ele.mimeType,
                isBlob:true,
    
              }
              resolve(file)
            }).catch((err)=>{
              resolve(null)
            })
          }else{
            errors.sizeError=true;
            resolve(null)
          }
            
           
    
  
          })
            
          })).then((res)=>{
           
            let response={target:{files:[]}};
            res.forEach((ele)=>{
              if(ele.value){
                response.target.files.push(ele.value);
              }
            })
            resolve({fileResponse:response,errors:errors});
          }).catch(err=>{})

      } else {
        return resolve(null);
      }
    });
  }
}

export default new PrepareUploadService();
