import ApiService from "./ApiService";
import APIConfig from "./APIConfig";
import axiosClient from "../app/axiosConfig";

class FamilyPlanServices {
  constructor() {
    this.apiService = new ApiService();
  }
  getAllAddress = (id) => {
    let url = APIConfig.familyPlan.primaryAddress;
    if (id !== -1) {
      url = APIConfig.familyPlan.familyAddress.format(id);
    }
    return axiosClient.get(url);
  };
  postAddress = (body, isPatch, id, onResponse, onError) => {
    let url = APIConfig.familyPlan.primaryAddress;
    if (isPatch) {
      url = url + `/${id}`;
      return axiosClient.put(url, body);
    }

    return axiosClient.post(url, body);
  };

  deleteAddress = (memberId, id) => {
    let url = APIConfig.familyPlan.primaryAddress + `/${id}`;
    if (memberId !== -1) {
      url = url = APIConfig.familyPlan.modifyFamilyAddress.format(memberId, id);
    }
    return axiosClient.delete(url);
  };
  getAllFamilyMembers = (onResponse, onError) => {
    let url = APIConfig.familyPlan.getFamilyMembers;
    return axiosClient.get(url);
    // return this.apiService.get(url, onResponse, onError);
  };
  //add a family member
  createMember = (body) => {
    let url = APIConfig.familyPlan.getFamilyMembers;
    return axiosClient.post(url, body);
  };

  //create member minor
  createMemberMinor = (body) => {
    let url = APIConfig.familyPlan.createMemberMinor;
    return axiosClient.post(url, body);
  };

  //get all relations
  getAllRelations = (onResponse, onError) => {
    let url = APIConfig.familyPlan.getAllRelation;
    return axiosClient.get(url);
    // return this.apiService.get(url, onResponse, onError);
  };

  //get otp for adding family members
  getFamilyOTP = (body) => {
    let url = APIConfig.familyPlan.getFamilyOTP;
    return axiosClient.post(url, body);
    // return this.apiService.post(url, body, onResponse, onError);
  };

  //verify family OTP
  verifyFamilyOTP = (body) => {
    let url = APIConfig.familyPlan.verifyFamilyOTP;
    return axiosClient.post(url, body);
    // return this.apiService.post(url, body, onResponse, onError);
  };

  //create family member address
  createFamilyMemberAddress = (id, body, onResponse, onError) => {
    let url = APIConfig.familyPlan.familyAddress.format(id);
    return axiosClient.post(url, body);
  };
  //update family member address
  updateFamilyMemberAddress = (memberId, addressID, body) => {
    let url = APIConfig.familyPlan.modifyFamilyAddress.format(
      memberId,
      addressID
    );
    return axiosClient.put(url, body);
  };

  //get district and state and pincode
  getDistrictFromPincode = (pincode, onResponse, onError) => {
    let pinUrl = APIConfig.patientsData.getAddress.format(pincode);
    return axiosClient.get(pinUrl);
  };
}

export default new FamilyPlanServices();
