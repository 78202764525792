import React from "react";
import { IonSlides, IonSlide } from "@ionic/react";
import "./testimonial.scss";
import AtulGupta from "../../assets/images/testimonial_atul_gupta.png";
import ShantiGanesh from "../../assets/images/testimonial_shanti_ganesh.jpg";
import Vigneswari from "../../assets/images/testimonial_vigneswari.jpg";
import GauravDhiman from "../../assets/images/testimonial_gaurav_dhiman.png";
import Abhijeet from "../../assets/images/testimonial_abhijeet.png";
import KapilKhurana from "../../assets/images/testimonial_kapil_khurana.png";
let swiper = null;
const slideOpts = {
  initialSlide: 0,
  speed: 400,
  autoplay: {
    delay: 2000,
  },
  on: {
    beforeInit() {
      swiper = this;
    },
  },
};

export default class testimonialSlides extends React.Component {
  slideChange = () => {
    if (swiper && !swiper.autoplay.running) {
      swiper.autoplay.start();
    }
  };

  render() {
    const patients = [
      {
        name: "Shanti Ganesh",
        age: 53,
        testimonial:
          "“Hats off to Zyla team for excellent guidance and prompt suggestions from time to time. It has helped me to improve my health and set a road map for sustaining the same.”",
        image: ShantiGanesh,
      },
      {
        name: "Vigneswari S",
        age: 27,
        testimonial:
          "“Very effective way of treating patients through app. It's really good. I got answers immediately through app chat. They take very good care of problems of the patients.”",
        image: Vigneswari,
      },
      {
        name: "Abhijeet Dutta",
        age: 42,
        testimonial:
          "“This is an amazing app, a true companion, guide and mentor.”",
        image: Abhijeet,
      },
      {
        name: "Atul Gupta",
        age: 43,
        testimonial:
          "“Zyla provides complete health care including consultation with specialists.”",
        image: AtulGupta,
      },
      {
        name: "Gaurav Dhiman",
        age: 31,
        testimonial:
          "“The Zyla care team is a phenomenal group of experienced doctors.”",
        image: GauravDhiman,
      },
      {
        name: "Kapil Khurana",
        age: 33,
        testimonial:
          "“The best part about Zyla has been the consistent & regular follow ups.”",
        image: KapilKhurana,
      },
    ];
    return (
      <IonSlides
        onIonSlideDidChange={this.slideChange}
        options={slideOpts}
        class="testimonial-slides"
      >
        {patients.map((patient, index) => (
          <IonSlide key={index}>
            <div>
              <span className="fs16">{patient.testimonial}</span>
              <img src={patient.image} className="patient-picture"></img>
              <p>
                {patient.name}, {patient.age} Years
              </p>
            </div>
          </IonSlide>
        ))}
      </IonSlides>
    );
  }
}
