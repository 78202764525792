const SEVERELY_UNDERWEIGHT = "Severely Underweight";
const UNDERWEIGHT = "Underweight";
const HEALTHY_WEIGHT = "Healthy Weight";
const OVERWEIGHT = "Overweight";
const MODERATELY_OBESE = "Moderately Obese";
const SEVERELY_OBESE = "Severely Obese";

export default {
  SEVERELY_UNDERWEIGHT,
  UNDERWEIGHT,
  HEALTHY_WEIGHT,
  OVERWEIGHT,
  MODERATELY_OBESE,
  SEVERELY_OBESE
}