import arrowRightImage from "../../../assets/images/arrowhead-right.png";
import { Link } from "react-router-dom";

export default function BookCard({ v }) {
  return (
    <>
      <div
        className=" mt-4 rounded-md bg-white p-4  "
        style={{ height: "151.81px" }}
        key={v.id}
      >
        <div className="flex">
          <img src={v.image} alt="" className="h-20 w-20 rounded-md" />
          <div className="px-5">
            <div
              className="font-bold text-xs "
              style={{ lineHeight: "14.4px" }}
            >
              {v.title}
            </div>
            <div className="py-1 font-medium text-xs leading-4">{v.text}</div>
            <div className="pt-4">
              <span className="font-semibold text-xs leading-4">
                {v.fee}
                <del className="font-normal leading-4 text-xs ">
                  {v.feeDeleteValue}
                </del>
                {v.feeValue}
              </span>
            </div>
          </div>
        </div>{" "}
        <div className="flex justify-between  pt-4">
          <Link to={v.link} className="no-underline text-lightblack">
            <div className="font-semibold text-xs leading-4 flex items-center">
              {v.detail}
              <img
                src={arrowRightImage}
                alt=""
                style={{
                  width: "12px",
                  height: "10.5px",
                  paddingLeft: "4px",
                }}
              />
            </div>
          </Link>
          <Link to={v.booklink}>
            <button
              className="bg-zyple rounded-md font-semibold text-xs "
              style={{
                width: "119.79px",
                height: "23.81px",
                lineHeight: "15.35px",
                color: "#FFFFFF",
              }}
            >
              {v.button}
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
