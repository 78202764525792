import React, { useRef, useState } from "react";
import arrowLeft from "../../../assets/icons/arrow-back-black.svg";
import icon_circle from "../../../assets/images/icon_circle.svg";

import MaleActive from "../../../assets/images/ic_male_active.png";
import FemaleActive from "../../../assets/images/ic_female_active.png";

import docIcon from "../../../assets/images/Icon Documents.png";
import dentalIcon from "../../../assets/icons/dental-icon.png";
import labtestIcon from "../../../assets/images/icon_diagonostics.png";
import addressIcon from "../../../assets/images/addresss.png";
import medicineIcon from "../../../assets/images/icon_medicine.png";

import careTeam from "../../../assets/images/icon_careteam.png";

import "../index.scss";
import { CircularProgress } from "@mui/material";

import { useHistory } from "react-router";
import SecureImage from "../../../components/common/SecureImage";

import {
  updateErrorState,
  updateLayout,
  updateSinglePropertyUI,
  uploadProfilePic,
} from "../familySlice";
import { useDispatch, useSelector } from "react-redux";
import {
  processUplodedFile,
  redirectToWebWithToken,
} from "../../../utils/helperMethods";
import PrepareUploadService from "../../../services/PrepareUploadService";

const familyProfileFuncArr = [
  {
    id: 1,
    icon: careTeam,
    name: "Book expert consultations",
  },
  {
    id: 2,
    icon: medicineIcon,
    name: "Order medicines ",
  },
  {
    id: 3,
    icon: labtestIcon,
    name: "Book diagnostics",
  },
  {
    id: 4,
    icon: docIcon,
    name: "Documents",
  },
  {
    id: 5,
    icon: addressIcon,
    name: "Address book",
  },
];

function FamilyProfileOverview(props) {
  const [isImg, setIsImg] = useState(
    props.familyInfo?.profilePicURL ? props.familyInfo.profilePicURL : ""
  );
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const imgRef = useRef();
  const history = useHistory();
  const familyStore = useSelector((store) => store.familyInfo);
  const clientConfig = useSelector((store) => store.tab.clientConfig);
  const { relations, ui } = familyStore;
  const { currentMember: familyInfo, loadingOverview } = ui;

  const handleItemClick = (id) => {
    if (id === 1) {
      getCreateRequestToken(1);
    } else if (id === 2) {
      handleItemClickUtil("/medicine");
    } else if (id === 5) {
      handleItemClickUtil("/address");
    } else if (id === 4) {
      handleItemClickUtil("/documents");
    } else if (id === 3) {
      getCreateRequestToken(2);
    }
  };
  const getCreateRequestToken = (arg) => {
    if (arg === 1 || arg === 2) {
      handleItemClickUtil("/navigo");
      return;
    }
    // redirectToWebWithToken(
    //   arg === 1
    //     ? process.env.REACT_APP_ZYLA_WEBSITE + "teleconsult"
    //     : process.env.REACT_APP_ZYLA_WEBSITE +
    //         "full-body-test-health-checkup?utm_source=app_launcher&utm2=app",
    //   familyInfo.patientId,
    //   familyInfo.memberPatientId
    // );
  };
  const handleImageUpload = (e, isAndroid = false) => {
    let file = e.target.files[0];
    if (isAndroid) {
      file = file.file;
    }
    dispatch(
      updateSinglePropertyUI({ property: "loadingOverview", value: true })
    );
    processUplodedFile(file)
      .then((res) => {
        if (res && res.result) {
          dispatch(
            uploadProfilePic({
              file: res.blob,
              fileName: file.name,
              pId: familyInfo.memberPatientId,
            })
          );
        } else {
          dispatch(
            updateSinglePropertyUI({
              property: "loadingOverview",
              value: false,
            })
          );
          dispatch(
            updateErrorState({ data: true, msg: "Something went wrong." })
          );
        }
      })
      .catch((errObj) => {
        if (errObj && errObj.result === false) {
          dispatch(
            updateSinglePropertyUI({
              property: "loadingOverview",
              value: false,
            })
          );
          dispatch(updateErrorState({ data: true, msg: errObj.msg }));
        }
      });
  };

  const handleItemClickUtil = (link) => {
    history.push({
      pathname: `${link}`,
      state: {
        isMember: true,
        memberPatientId: familyInfo.memberPatientId,
        memberEmail: familyInfo.email,
        memberData: { ...familyInfo },
        isFamily: true,
      }, // your data array of objects
    });
  };

  const handleInputRefClick = () => {
    if (window.Capacitor.platform !== "web") {
      PrepareUploadService.selectFiles(false)
        .then((res) => {
          let file = null;
          if (
            res.fileResponse.target.files &&
            res.fileResponse.target.files.length > 0
          ) {
            if (res.errors && res.errors.sizeError) {
              dispatch(
                updateSinglePropertyUI({
                  property: "loadingOverview",
                  value: false,
                })
              );
              dispatch(
                updateErrorState({
                  data: true,
                  msg: "Image of size less than 20 mb are allowed",
                })
              );
              return;
            }
            handleImageUpload(res.fileResponse, true);
          } else {
            dispatch(
              updateSinglePropertyUI({
                property: "loadingOverview",
                value: false,
              })
            );
            dispatch(
              updateErrorState({ data: true, msg: "Something went wrong." })
            );
          }
        })
        .catch((err) => {
          dispatch(
            updateSinglePropertyUI({
              property: "loadingOverview",
              value: false,
            })
          );
          dispatch(
            updateErrorState({ data: true, msg: "Something went wrong." })
          );
        });
    } else {
      if (inputRef.current) {
        inputRef.current.click();
      }
    }
  };

  return (
    <div className="family-profile-overview-container">
      <div className="family-profile-overview-header">
        <img
          src={arrowLeft}
          onClick={() => dispatch(updateLayout(1))}
          alt="icon"
        />
        {/* <img
          src={icon_circle}
          alt="icon"
          onClick={() => dispatch(updateLayout(3))}
        /> */}
      </div>
      <div className="family-profile-overview-body-top">
        {familyInfo && familyInfo.profilePicURL ? (
          <SecureImage
            src={familyInfo.profilePicURL}
            defaultImage={familyInfo.gender === 1 ? FemaleActive : MaleActive}
            onClick={handleInputRefClick}
            classN="family-profile-overview-body-top-profilepic"
          ></SecureImage>
        ) : (
          <img
            src={
              familyInfo && familyInfo.gender === 1 ? FemaleActive : MaleActive
            }
            onClick={handleInputRefClick}
            className="family-profile-overview-body-top-profilepic"
            alt="default"
          />
        )}
        <p className="family-profile-overview-body-top-name">
          {familyInfo.firstName + " " + (familyInfo.lastName || "")}
        </p>
        {familyInfo.relation !== 6 && familyInfo.relation !== 8 && (
          <p className="family-profile-overview-body-top-number">{`+${familyInfo.countryCode}-${familyInfo.phoneNo}`}</p>
        )}
        <p className="family-profile-overview-body-top-relation">
          {relations.entities[familyInfo.relation]?.value}
        </p>
      </div>
      {/* {familyInfo.status === 4 && (
        <div className="family-profile-body-mid">
          <span>{`Zyla Zen Family Plan >>`}</span>
        </div>
      )} */}
      <div className="family-profile-body-bottom">
        {familyProfileFuncArr.map((item) => {
          if (familyInfo.countryCode !== 91 && item.id !== 4) {
            console.log("if condiyion in overview", familyInfo);
            return null;
          }
          return (
            <div
              className="family-profile-body-bottom-items"
              key={item.id}
              onClick={() => {
                handleItemClick(item.id);
              }}
            >
              <img src={item.icon} alt="icon" />
              <p>{item.name}</p>
            </div>
          );
        })}
      </div>
      <input
        type="file"
        hidden={true}
        ref={inputRef}
        accept={"image/jpg,image/jpeg,image/png"}
        onChange={handleImageUpload}
      />
      {loadingOverview && (
        <div className="family-profile-overview-loader">
          <CircularProgress className="family-profile-overview-circular-progress"></CircularProgress>
        </div>
      )}
    </div>
  );
}

export default FamilyProfileOverview;
