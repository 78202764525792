import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../app/axiosConfig";
import APIConfig from "../../services/APIConfig";
import { deepEqual } from "../../utils/helperMethods";
import { getMessages, getNextMessages } from "../chat/chatSlice";

const initialState = {
  settings: undefined,
  deviceInfoUpdate: false,
  chat: undefined,

  showRedDotHome: true,
  uiConfig: {
    ancillaryLab: false,
    ancillaryMed: false,
    isChatEnabled: false,
    isChatVisible: false,
  },
  careTeams: [],
  docToken: null,
  clientConfig: {},
  ui: {
    showRedDotChat: false,
    unReadCount: 0,
    chatFound: true,
    scrollChat: false,
    isOffline: false,
    updatedob: false,
  },
};

export const fetchGenericSetting = createAsyncThunk(
  "tab/fetchGenericSetting",
  async (data, { rejectWithValue }) => {
    try {
      const url = APIConfig.system.generalSettings;
      const response = axiosClient.post(url, {});
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateDeviceInfo = createAsyncThunk(
  "tab/updateDeviceInfo",
  async (data, { rejectWithValue }) => {
    try {
      const url = APIConfig.user.deviceDetails.format(data.id);
      const response = axiosClient.post(url, data.req);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getChat = createAsyncThunk(
  "tab/getChat",
  async (userId, { rejectWithValue }) => {
    try {
      const getURL = APIConfig.chat.get.format(userId);
      const response = axiosClient.get(getURL);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const generateDocumentToken = createAsyncThunk(
  "tab/generateDocumentToken",
  async (userId, { rejectWithValue }) => {
    try {
      const getURL = APIConfig.document.tokenGenerate;
      const response = axiosClient.get(getURL);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUIConfig = createAsyncThunk(
  "tab/getUIConfig",
  async (userId, { rejectWithValue }) => {
    try {
      const url = APIConfig.user.setting.format(userId);
      const response = axiosClient.get(url);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const createChat = createAsyncThunk(
  "tab/createChat",
  async (data, { rejectWithValue }) => {
    try {
      const postURL = APIConfig.chat.connect;
      const response = axiosClient.post(postURL, data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCareTeam = createAsyncThunk(
  "tab/getCareTeam",
  async (pid, { rejectWithValue }) => {
    try {
      let url = APIConfig.careTeam.getByPatient.format(pid);
      const response = axiosClient.get(url);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const clearChatDot = createAsyncThunk(
  "tab/clearChatDot",
  async (data, { getState, rejectWithValue }) => {
    try {
      const { tab } = getState();
      let url = `${APIConfig.chat.message}/${tab.chat.chatRoom}`;
      const response = axiosClient.patch(url, {});
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getClientConfig = createAsyncThunk(
  "tab/clientConfig",
  async (data, { getState, rejectWithValue }) => {
    try {
      const { login } = getState();
      let url = APIConfig.user.clientConfigUsingClientCode.format(
        login.user.patientProfile.clientCode
      );
      const response = axiosClient.get(url);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const tabSlice = createSlice({
  name: "tab",
  initialState,
  reducers: {
    clearHomeDot: (state, action) => {
      state.showRedDotHome = false;
    },
    clearChatDot: (state, action) => {
      state.ui = {
        ...state.ui,
        showRedDotChat: false,
      };
    },
    toggleScrollChat: (state, action) => {
      state.ui = {
        ...state.ui,
        scrollChat: action.payload.value,
      };
    },
    toggleNetworkStatus: (state, action) => {
      state.ui = {
        ...state.ui,
        isOffline: action.payload,
      };
    },
  },
  extraReducers: {
    [fetchGenericSetting.fulfilled]: (state, action) => {
      state.settings = action.payload.data;
    },
    [updateDeviceInfo.fulfilled]: (state, action) => {
      state.deviceInfoUpdate = true;
    },
    [getChat.fulfilled]: (state, action) => {
      state.chat = action.payload.data;
    },
    [getChat.rejected]: (state, action) => {
      state.ui.chatFound = false;
    },
    [createChat.fulfilled]: (state, action) => {
      state.ui.updatedob = true;
      state.chat = action.payload.data;
    },
    [getUIConfig.fulfilled]: (state, action) => {
      // console.log("i am fetch ui config", state.uiConfig, action.payload.data);
      if (!deepEqual(state.uiConfig, action.payload.data)) {
        state.uiConfig = action.payload.data;
      }
    },
    [getCareTeam.fulfilled]: (state, action) => {
      state.careTeams = action.payload.data.doctors;
    },

    [getNextMessages.fulfilled]: (state, action) => {
      const urc = action.payload.data.unreadCount;

      state.ui.unReadCount = urc > 0 ? urc : 0;

      state.ui.showRedDotChat = urc > 0 ? true : false;
    },
    // [getMessages.fulfilled]: (state, action) => {
    //   const urc = action.payload.data.unreadCount;
    //   state.ui.unReadCount = urc > 0 ? urc : state.ui.unReadCount;
    //   state.ui.showRedDotChat = urc > 0 ? true : state.ui.showRedDotChat;
    // },
    [generateDocumentToken.fulfilled]: (state, action) => {
      state.docToken = action.payload.data;
    },
    [clearChatDot.fulfilled]: (state, action) => {
      state.ui.showRedDotChat = false;
      state.ui.unReadCount = 0;
    },
    [clearChatDot.rejected]: (state, action) => {
      state.ui.showRedDotChat = false;
      state.ui.unReadCount = 0;
    },
    [getClientConfig.fulfilled]: (state, action) => {
      state.clientConfig = action.payload.data;
    },
  },
});
export const { clearHomeDot, toggleScrollChat, toggleNetworkStatus } =
  tabSlice.actions;
export default tabSlice.reducer;
