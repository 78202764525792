import React, { useEffect, useRef } from "react";
import trackerService from "../../../services/trackerServices";
import Chart from "chart.js/auto";
import moment from "moment";
import { useSelector } from "react-redux";

let globalSleepMin = 7;
let globalSleepMax = 9;

function LineGraph({ paramGroupId, time, getAverage }) {
  const patientProfile = useSelector(
    (store) => store.login.user?.patientProfile
  );
  const barChart = useRef(null);

  useEffect(() => {
    getTrackerChartData(paramGroupId, time);
  }, [time, paramGroupId]);

  const getTrackerChartData = (paramGroupId, time) => {
    let todate = time;
    let milli = time - 6 * 24 * 60 * 60 * 1000;
    let fromdate = time - 6 * 24 * 60 * 60 * 1000;

    let trackerData = { labels: [], fulldates: [] };
    for (let i = 0; i < 7; i++) {
      let fulldate = moment(milli + i * 24 * 60 * 60 * 1000);
      let myObj = fulldate.toObject();

      trackerData.labels.push(myObj.date);
      trackerData.fulldates.push(moment(fulldate).format("DD-MMM"));
    }

    let datasetObj = {};
    let dataValues = [];

    if (paramGroupId == 1) {
      trackerService
        .getStepsByDate(fromdate, todate, patientProfile?.id)
        .then((result) => {
          const response = result.data;
          getAverage(response.average, response.streak);
          Object.keys(response.steps).map((ele) => {
            datasetObj[new Date(ele).getDate()] = response.steps[ele];
          });

          trackerData.labels.map((ele) => {
            if (datasetObj[ele]) {
              dataValues.push(datasetObj[ele]);
            } else {
              dataValues.push(0);
            }
          });
          trackerData.data = [...dataValues];
          trackerData.currentLabel = "Steps";
          trackerData.text = "Steps";
          trackerData.min = 0;
          trackerData.max = 14000;
          trackerData.stepSize = 2000;
          if (barChart.current) {
            barChart.current.destroy();
          }
          chartRender(trackerData);
        })
        .catch((error) => {
          console.log("error is ", error);
        });
    } else {
      trackerService
        .getSleepByDate(fromdate, todate, patientProfile?.id)
        .then((result) => {
          const response = result.data;
          getAverage(response.average, response.streak);
          Object.keys(response.time).map((ele) => {
            datasetObj[new Date(ele).getDate()] = response.time[ele];
          });
          trackerData.labels.map((ele) => {
            if (datasetObj[ele]) {
              dataValues.push(datasetObj[ele] / 60);
            } else {
              dataValues.push(0);
            }
          });
          trackerData.data = [...dataValues];
          trackerData.currentLabel = "Sleep";
          trackerData.text = "Hours";
          trackerData.min = 0;
          trackerData.max = 12;
          trackerData.stepSize = 2;
          globalSleepMin = Math.floor(response.goalFrom / 60);
          globalSleepMax = Math.floor(response.goalTo / 60);

          if (barChart.current) {
            barChart.current.destroy();
          }
          chartRender(trackerData);
        })
        .catch((error) => {
          console.log("error is ", error);
        });
    }
  };

  const chartRender = (trackerData) => {
    let chartStatus = Chart.getChart("trackerChart"); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }
    var barIcons = {
      id: "baricons",
      afterDatasetsDraw: function (chartInstance, easing) {
        var ctx = chartInstance.ctx;
        const y1 = chartInstance.chartArea.height - 24;
        chartInstance.data.datasets.forEach(function (dataset) {
          for (var i = 0; i < dataset.data.length; i++) {
            if (dataset.label === "Steps" && dataset.data[i] < 10000) {
              continue;
            }
            if (
              dataset.label === "Sleep" &&
              (dataset.data[i] < globalSleepMin ||
                dataset.data[i] > globalSleepMax)
            ) {
              continue;
            }
            const model = {
              index: i,
              x: chartInstance.getDatasetMeta(0).data[i].x,
              y: chartInstance.getDatasetMeta(0).data[i].y + 8,
              width: chartInstance.getDatasetMeta(0).data[i].width,
              base: chartInstance.getDatasetMeta(0).data[i].base,
              icons: dataset.pointStyle || null,
              color: dataset.color || Chart.defaults.color,
            };

            if (model.icons) {
              ctx.textAlign = "center";

              ctx.font = '50px "Helvetica Neue", Helvetica, Arial, sans-serif';
              ctx.textBaseline = "end";
              ctx.fillStyle = model.color;
              ctx.save();
              ctx.fillText(model.icons[i], model.x, model.y);

              ctx.fillStyle = Chart.defaults.color;

              ctx.restore();
            }
          }
        });
      },
      destroy: function (chartInstance) {},
    };
    Chart.register(barIcons);
    let canvas = document.querySelector("canvas");
    let ctx;
    if (canvas) {
      ctx = canvas.getContext("2d");
    } else {
      return;
    }

    let gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "#143A72");
    // gradient.addColorStop(1, "#00C9D3");
    const mydata = {
      labels: trackerData.labels,
      datasets: [
        {
          label: trackerData.currentLabel,
          borderRadius: 25,
          data: trackerData.data,
          fulldates: trackerData.fulldates,
          color: "white",
          barThickness: 15,
          borderRadius: {
            topLeft: 100,
            topRight: 100,
            bottomRight: 100,
            bottomLeft: 100,
          },
          borderSkipped: false,
          backgroundColor: gradient,
          pointStyle: ["⋆", "⋆", "⋆", "⋆", "⋆", "⋆", "⋆"],

          borderColor: [
            "rgb(255, 99, 132)",
            "rgb(255, 159, 64)",
            "rgb(255, 205, 86)",
            "rgb(75, 192, 192)",
            "rgb(54, 162, 235)",
            "rgb(153, 102, 255)",
            "rgb(201, 203, 207)",
          ],
          borderWidth: 0,
        },
      ],
    };
    barChart.current = new Chart(canvas, {
      type: "bar",
      data: mydata,

      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          tooltip: {
            mode: "index",
            position: "nearest",
            intersect: false,
            callbacks: {
              title: function (tooltipitem) {
                return tooltipitem[0].dataset.fulldates[
                  tooltipitem[0].dataIndex
                ];
              },
              label: function (tooltipitem) {
                let ds = tooltipitem.dataset.data[tooltipitem.dataIndex];

                return `${tooltipitem.dataset.label} : ${
                  tooltipitem.dataset.label === "Sleep"
                    ? `${Math.floor(
                        tooltipitem.dataset.data[tooltipitem.dataIndex]
                      )} hrs ${
                        Math.floor(
                          tooltipitem.dataset.data[tooltipitem.dataIndex] * 60
                        ) % 60
                      } mins`
                    : tooltipitem.dataset.data[
                        tooltipitem.dataIndex
                      ].toLocaleString()
                } `;
              },
            },
          },
          legend: {
            display: true,
            position: "bottom",
            fullWidth: true,
            align: "center",
            labels: {
              boxWidth: 20,
              fontColor: "black",
              usePointStyle: true,
              boxPadding: 20,
              color: "#171A21",
            },
          },
        },

        scales: {
          y: {
            min: trackerData.min,
            max: trackerData.max,
            ticks: {
              stepSize: trackerData.stepSize,
              callback: function (value, index, values) {
                if (trackerData.currentLabel === "Steps") {
                  if (value == 0) {
                    return value;
                  }
                  return `${value / 1000}k`;
                } else {
                  return value;
                }
              },
            },
            scaleLabel: {
              display: false,
              labelString: " Steps",
            },
            title: {
              display: true,
              text: trackerData.text,
              color: "#171A21",
            },

            grid: {
              borderDash: [5, 10],
              drawBorder: false,
              borderDashOffset: [20],
            },
          },
          x: {
            title: {
              display: true,
              text: "Date",
              color: "#171A21",
            },

            grid: {
              display: false,
            },
          },
        },
      },
    });
  };

  return <canvas className="chart-tracker" id="trackerChart"></canvas>;
}
export default LineGraph;
