import APIConfig from "./APIConfig";
import axiosClient from "../app/axiosConfig";
class MeditationService {
  getAllMeditations(id) {
    let url = APIConfig.meditation.getMeditation.format(id);
    return axiosClient.get(url);
  }

  getMeditation(id) {
    let url = APIConfig.meditation.get.format(id);
    return axiosClient.get(url);
  }

  getRecommendedMeditation(id) {
    let url = APIConfig.meditation.recommendedMeditation.format(id);
    return axiosClient.get(url);
  }
}

let meditationService = new MeditationService();

export default meditationService;
