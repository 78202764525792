import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import APIConfig from "../../../services/APIConfig";
import axios from "axios";
import axiosClient from "../../../app/axiosConfig";

const initialState = {
  data: null,
  status: "idle",
  error: null,
  rewardPointStrapi: [],
  rewardLedgerData: [],
  selectedPolicy: null,
  loading: false,
};

export const rewardData = createAsyncThunk(
  "reward/getrewardData",
  async (id, { rejectWithValue }) => {
    console.log("slice id:", id);
    try {
      const url = APIConfig.user.reward.format(id);
      const response = await axiosClient.get(url);
      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getRewardStrapiData = createAsyncThunk(
  "reward/getRewardStrapiData",
  async (id, { rejectWithValue }) => {
    try {
      console.log("try");
      const url = APIConfig.user.rewardPointServices.format(id);
      const response = axios.get(url, { headers: { origin: "*" } });
      return response;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getRewardLedgerData = createAsyncThunk(
  "reward/getRewardLedgerData",
  async (body) => {
    try {
      const url = APIConfig.user.rewardLedger.format(
        body.id,
        body.policyId,
        body.page,
        body.month
      );

      const response = await axiosClient.get(url);
      return response;
    } catch (error) {
      return error;
    }
  }
);

const rewardSlice = createSlice({
  name: "reward",
  initialState,
  reducers: {
    setSelectedPolicy: (state, action) => {
      state.selectedPolicy = action.payload;
    },
  },

  extraReducers: {
    [rewardData.pending]: (state, action) => {
      state.status = "loading";
    },
    [rewardData.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = "success";
    },
    [rewardData.rejected]: (state, action) => {
      state.status = "error";
    },

    [getRewardStrapiData.pending]: (state, action) => {},
    [getRewardStrapiData.fulfilled]: (state, action) => {
      console.log(action.payload.data.earn_more_reward_points, "im here");
      return {
        ...state,
        rewardPointStrapi: action.payload.data.earn_more_reward_points,
      };
    },
    [getRewardStrapiData.rejected]: (state, action) => {
      console.log("rejected", action);
    },
    [getRewardLedgerData.fulfilled]: (state, action) => {
      return {
        ...state,
        rewardLedgerData: action.payload.data?.data,
        rewardLedgerPageCount: action.payload.data?.pageCount,
        loading: false,
      };
    },
    [getRewardLedgerData.rejected]: (state, action) => {
      console.log(action);
      console.log("rejected");
      state.loading = false;
    },
    [getRewardLedgerData.pending]: (state, action) => {
      state.loading = true;
      console.log("pending");
    },
  },
});
export const { setSelectedPolicy } = rewardSlice.actions;

export default rewardSlice.reducer;
