import ActionCreator from "./action";

export const updateUiConfig = (data) => (dispatch) => {
  dispatch(ActionCreator.updateUiConfig(data));
};
export const updateChatOperation = (data) => (dispatch) => {
  dispatch(ActionCreator.updateChatAction(data));
};

export const setupChatOperation = () => (dispatch) => {
  dispatch(ActionCreator.setupChatAction());
};

export const clearChatOperation = () => (dispatch) => {
  dispatch(ActionCreator.clearChatAction());
};

export const sendMessageOperation = (data) => (dispatch) => {
  dispatch(ActionCreator.sendMessageAction(data));
};

export const toggleAllowMessageSendOperation = (data) => (dispatch) => {
  dispatch(ActionCreator.toggleAllowMessageSendAction(data));
};

export const toggleOnlineStatusOperation = (data) => (dispatch) => {
  dispatch(ActionCreator.toggleOnlineStatusAction(data));
};

export const updatePendingChatOperation = (data) => (dispatch) => {
  dispatch(ActionCreator.updatePendingChatAction(data));
};

export const updateMessageStatusOperation = (data) => (dispatch) => {
  dispatch(ActionCreator.updateMessageStatusAction(data));
};

export const putDeviceReading = (data) => (dispatch) => {
  dispatch(ActionCreator.deviceReadingPushAction(data));
};
export const clearDeviceReadings = () => (dispatch) => {
  dispatch(ActionCreator.deviceReadingClearAction());
};
export const setCurrentDevice = (data) => (dispatch) => {
  dispatch(ActionCreator.deviceSetCurrentAction(data));
};
