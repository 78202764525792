import React from "react";
import GroupTick from "../../../../assets/images/group.png";
import TimeMessage from "./time";
export default function PACompleteCard({ message }) {
  return (
    <div className="assesment-completed-container">
      <div className="assesment-completed-container-parent">
        <img
          className="assesment-completed-tick"
          alt="complete"
          src={GroupTick}
        />
        <div className="congratulations-text bold">Congratulations!</div>
        <div className="info-text-completeted">
          You have completed your health assesment.
        </div>
      </div>
      <TimeMessage message={message} />
    </div>
  );
}
