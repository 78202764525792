import { useSelector } from "react-redux";
import maleImage from "../../../assets/images/male.png";
import femaleImage from "../../../assets/images/female.png";

export function LeaderBoardCard({ item }) {
  const status = useSelector((state) => state.stepathon.status);

  return (
    <>
      <div className="text-lightblack mb-2 mx-4 ">
        {item[0] !== "Rank" ? (
          <div
            className="font-bold leading-4 text-xs rounded-lg  text-justify flex justify-between items-center text-lightblack px-3 py-2 space-x-4 "
            style={{
              backgroundColor:
                item[0] === "1"
                  ? "#E6F2F3"
                  : item[0] === "2"
                  ? "#FAEEF7"
                  : item[0] === "3"
                  ? "#FEF4E5"
                  : "#EEECEC",
            }}
          >
            <div className="flex items-center gap-4">
              <span>
                {(status === "success", item[0] === "Rank" ? "" : item[0])}.
              </span>
              <div>
                <img
                  src={item[3] === "Male" ? maleImage : femaleImage}
                  alt="Image"
                  className="w-[26px] h-[26px] rounded-full "
                  style={{
                    visibility: item[3] === "Gender" ? "hidden" : "visible",
                  }}
                />
              </div>
              <div
                className="rounded-md font-bold text-xs leading-3"
                style={{ width: "70%!important", textAlign: "left" }}
              >
                {(status === "success", item[2] === "Name" ? "" : item[2])}
              </div>
            </div>
            {/* <div className=" rounded-md font-bold text-xs leading-3 tracking-[-2%]">
            <span className="">{(status === "success", item[3])}</span>
          </div> */}
            <div className=" rounded-full text-center bg-[#6D7079] w-[6em] text-[#FFFFFF] font-bold text-xs leading-3 px-1 py-1 tracking-[-2%]">
              {(status === "success", item[4] === "Total Steps" ? "" : item[4])}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
