import {
  IonAlert,
  IonButton,
  IonContent,
  IonFooter,
  IonModal,
  IonPage,
} from "@ionic/react";
import * as moment from "moment";
import React, { Component, useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router";
import BackButton from "../../components/common/BackButton";
import closeButton from "../../assets/icons/ic_gray_circle_close.png";
import checkMarkWhite from "../../assets/icons/check_mark_white.png";
import chevronDownBlack from "../../assets/icons/chevron_down_black.png";
import checkDisabled from "../../assets/images/check_disable.png";
import tick from "../../assets/images/tick.png";
import deviceService from "../../services/DeviceService";
import CleverTapLogger from "../../clevertapLogger";
import NextImage from "../../assets/images/ic_arrow_forward.png";
const readingTypeArray = [
  { text: "Fasting Blood Sugar", code: "5", value: "FBS" },
  { text: "2 hrs. after breakfast (PP1)", code: "25", value: "PP1" },
  { text: "Before lunch", value: "Before Lunch", code: "70" },
  { text: "2 hrs. after lunch (PP2)", code: "58", value: "PP2" },
  { text: "Pre-dinner", code: "26", value: "Pre-dinner" },
  { text: "2 hrs. after dinner (PP3)", code: "27", value: "PP3" },
  { text: "3am sugar", code: "66", value: "3AM Sugar" },
  { text: "Random Blood Sugar", code: "67", value: "RBS" },
];

const readingTypeMap = {
  FBS: { text: "Fasting Blood Sugar", code: "5", value: "FBS" },
  PP1: { text: "2 hrs. after breakfast (PP1)", code: "25", value: "PP1" },
  "Before Lunch": { text: "Before lunch", value: "Before Lunch", code: "70" },
  PP2: { text: "2 hrs. after lunch (PP2)", code: "58", value: "PP2" },
  "Pre-dinner": { text: "Pre-dinner", code: "26", value: "Pre-dinner" },
  PP3: { text: "2 hrs. after dinner (PP3)", code: "27", value: "PP3" },
  "3AM Sugar": { text: "3am sugar", code: "66", value: "3AM Sugar" },
  RBS: { text: "Random Blood Sugar", code: "67", value: "RBS" },
};

function SyncedVitals({}) {
  const currentDeviceId = useSelector(
    (store) => store.connectSlice.currentDeviceId
  );
  const readings = useSelector((store) => store.connectSlice.readings);
  const user = useSelector((store) => store.login.user);
  const [readingsArray, setReadingsArray] = useState([]);
  const [submittedVitals, setSubmittedVitals] = useState(false);
  const [totalReading, setTotalReading] = useState(0);
  const [showAlert, setShowAlert] = useState(false);

  const [deleteItemIndex, setDeleteItemIndex] = useState();
  const [deleteReadingIndex, setDeleteReadingIndex] = useState();
  const [readingToDelete, setReadingToDelete] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectTypeItemIndex, setSelectTypeItemIndex] = useState();
  const [selectTypeReadingIndex, setSelectTypeReadingIndex] = useState();
  const history = useHistory();
  useEffect(() => {
    groupByDate(readings);
  }, [readings]);

  const groupByDate = (readings) => {
    const unique = [
      ...new Set(
        readings.map((reading) => {
          return moment(reading.date).format("DD-MM-YYYY");
        })
      ),
    ]; // [ 'A', 'B']
    let finalArray = new Array();
    let tCount = 0;
    unique.forEach((d) => {
      let newArray = readings.filter(
        (reading) => moment(reading.date).format("DD-MM-YYYY") === d
      );
      newArray.forEach((r) => {
        r.formattedDate = moment(r.data).format("DD-MM-YYYY");
        tCount++;
      });
      finalArray.push({
        date: d,
        readings: newArray,
      });
    });
    setReadingsArray(finalArray);
    setTotalReading(tCount);
  };
  const toggleAlert = (showAlert, itemIndex, readingIndex) => {
    setShowAlert(showAlert);
    setDeleteItemIndex(itemIndex);
    setDeleteReadingIndex(readingIndex);
    setReadingToDelete(showAlert && getReading(itemIndex, readingIndex));
  };
  const getReading = (itemIndex, readingIndex) => {
    let arr = readingsArray;
    let readings = arr[itemIndex].readings;
    return readings[readingIndex];
  };
  const deleteReading = (itemIndex, readingIndex) => {
    let newArray = readingsArray;
    let newReadings = newArray[itemIndex].readings;
    newReadings.splice(readingIndex, 1);
    newArray[itemIndex].readings = newReadings;
    setReadingsArray(newArray);
  };
  const toggleModal = (showModal, itemIndex, readingIndex) => {
    setShowModal(showModal);
    setSelectTypeItemIndex(itemIndex);
    setSelectTypeReadingIndex(readingIndex);
  };
  const setReadingType = (type, itemIndex, readingIndex) => {
    let newArray = readingsArray;
    let newReadings = newArray[itemIndex].readings;
    newReadings[readingIndex].type = type;
    newArray[itemIndex].readings = newReadings;
    setReadingsArray(newArray);
    setShowModal(false);
    setSelectTypeItemIndex(null);
    setSelectTypeReadingIndex(null);
  };
  const clickToSubmitVitals = () => {
    let rs = [];
    readingsArray.forEach((rwd) => {
      rwd.readings.forEach((r) => {
        var rToS = {
          date: `${new Date(r.date).getTime()}`,
          reading: `${r.value}`,
          unit: `${r.unit}`,
          sequence: `${r.seq}`,
        };
        if (r.type) {
          let tm = readingTypeMap[r.type];
          if (tm) {
            rToS.paramId = tm.code;
          }
        }
        rs.push(rToS);
      });
    });

    deviceService
      .saveReading(rs, currentDeviceId, user)
      .then((s) => {
        console.log(s);
        setSubmittedVitals(true);
        setTimeout(() => {
          setSubmittedVitals(false);
          history.push("/tab/chat");
        }, 3000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  if (readingsArray) {
    let tReading = 0;
    readingsArray.forEach((g) => {
      g.readings.forEach((r) => {
        tReading++;
      });
    });
  }
  return (
    <IonPage>
      <IonContent>
        {!submittedVitals && (
          <div className="cd-wrapper">
            <header className="cd-header">
              <BackButton history={history} ismeditate={false} />
              <h1>Synced Vitals</h1>
            </header>
            {totalReading > 0 && (
              <div className="synced-heading-count">
                <img src={checkMarkWhite}></img> {totalReading} Blood sugar
                readings synced
              </div>
            )}

            {readingsArray.length > 0 && (
              <div>
                <table className="synced-vitals">
                  <thead>
                    <td className="sv-cell w-40">Blood Sugar</td>
                    <td className="sv-cell w-20">Time</td>
                    <td className="sv-cell w-40">Reading(mg/dl)</td>
                  </thead>
                  {readingsArray &&
                    readingsArray.map((item, itemIndex) => (
                      <div className="reading-container">
                        <p>
                          {moment(item.date, "DD-MM-YYYY").format(
                            "dddd, D MMMM YYYY"
                          )}
                        </p>
                        <table>
                          {item.readings.map((reading, readingIndex) => (
                            <tr>
                              <td
                                className={`sv-cell w-40 ${
                                  reading.type ? "" : "select-type"
                                }`}
                                onClick={() => {
                                  toggleModal(true, itemIndex, readingIndex);
                                }}
                              >
                                {reading.type ? reading.type : "Select Type* "}
                                {reading.type ? (
                                  ""
                                ) : (
                                  <img
                                    style={{ width: "14px", height: "10px" }}
                                    src={chevronDownBlack}
                                  ></img>
                                )}
                              </td>
                              <td className="sv-cell w-20">
                                {moment(reading.date).format("HH:mm")}
                              </td>
                              <td className="sv-cell w-40 flex-around">
                                {reading.value}{" "}
                                <button
                                  style={{
                                    maxWidth: "1rem",
                                    border: "none",
                                    background: "none",
                                    padding: "0",
                                  }}
                                  onClick={() => {
                                    toggleAlert(true, itemIndex, readingIndex);
                                  }}
                                >
                                  <img src={closeButton} />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    ))}
                </table>

                <button
                  className="submit-btn"
                  onClick={(e) => clickToSubmitVitals()}
                >
                  <img src={NextImage}></img>
                </button>

                {/* <IonButton
                    expand="full"
                    size="large"
                    style={{ margin: "0" }}
                    className="submit-vital-button"
                    onClick={() => {
                      this.clickToSubmitVitals();
                    }}
                  >
                    SUBMIT VITALS
                  </IonButton> */}
              </div>
            )}
            {readingsArray.length === 0 && (
              <>
                <table className="synced-vitals">
                  <thead>
                    <td className="sv-cell w-40">Blood Sugar</td>
                    <td className="sv-cell w-20">Time</td>
                    <td className="sv-cell w-40">Reading(mg/dl)</td>
                  </thead>
                </table>
                <div className="text-center">
                  <br />
                  <br />
                  <br />
                  <br />
                  <img src={checkDisabled}></img>
                  <h4>Sync complete!</h4>
                  <h4> No new readings found.</h4>
                  <br />
                  <br />
                  <br />
                  <IonButton
                    expand="full"
                    size="large"
                    style={{ margin: "0" }}
                    className="submit-vital-button"
                    onClick={() => {
                      history.replace("/tab/hamburger");
                    }}
                  >
                    Back to Profile
                  </IonButton>
                </div>
              </>
            )}
          </div>
        )}
        {submittedVitals && <SyncedVitalSubmitted />}
        {readingToDelete && (
          <IonAlert
            isOpen={showAlert}
            mode="ios"
            onDidDismiss={() => {
              toggleAlert(false, false, false);
            }}
            cssClass="delete-alert"
            header={"Are you sure you want to delete this reading?"}
            message={`READING: <strong>${
              readingToDelete.value
            }</strong> &nbsp;&nbsp;TIME: <strong>${moment(
              readingToDelete.date
            ).format("HH:mm")}</strong>`}
            buttons={[
              {
                text: "DELETE",
                role: "delete",
                cssClass: "delete-button",
                handler: () => {
                  deleteReading(deleteItemIndex, deleteReadingIndex);
                },
              },
              {
                text: "CANCEL",
                cssClass: "cancel-button",

                handler: () => {
                  toggleAlert(false, false, false);
                },
              },
            ]}
          />
        )}
        {
          <IonModal
            isOpen={showModal}
            cssClass="selection-modal"
            onDidDismiss={() => {
              toggleModal(false, null, null);
            }}
          >
            <div className="vital-type-header">Select Type</div>
            {readingTypeArray.map((type) => (
              <button
                onClick={() =>
                  setReadingType(
                    type.value,
                    selectTypeItemIndex,
                    selectTypeReadingIndex
                  )
                }
                className="vital-type-button"
              >
                <span>{type.text}</span>
              </button>
            ))}
          </IonModal>
        }
      </IonContent>
    </IonPage>
  );
}

//container

export default SyncedVitals;

const SyncedVitalSubmitted = (props) => {
  return (
    <div className="synced-vitals-submitted-container">
      <img src={tick} />
      <span className="submitted-succesfully">Submitted</span>
      <span>Thank you for submitting your Vitals synced via glucometer.</span>
    </div>
  );
};
