import React from "react";
import forwardArrow from "../../../assets/images/forward-black-arrow.png";
const Premium_faqs = [
  "How is the Zyla program different?",
  "Which health conditions do you treat?",
  "How will you treat me through an online program?",
  "I travel frequently, how will I keep up with your program?",
  "This program is for my father/mother how will you manage them, they are not even comfortable using the mobile app?",
];

export default function PremiumFAQS({}) {
  return (
    <div className="section-wrapper background-color-white-class margin-Top-15 margin-Bottom-15">
      <h2>FAQs</h2>
      {Premium_faqs.map((faq) => (
        <div
          className="faq-wrapper"
          onClick={() => {
            window.open("https://zyla.in/faqs", "_blank");
          }}
        >
          <p>
            {faq} <img src={forwardArrow} />
          </p>
          <hr />
        </div>
      ))}
      <button
        className="faq-button"
        onClick={() => {
          window.open("https://zyla.in/faqs", "_blank");
        }}
      >
        View All FAQs
      </button>
    </div>
  );
}
