import { IonContent, IonPage } from "@ionic/react";
import { BookHassleCard } from "./component/Card";
import { AppoinmentCard } from "./component/AppoinmentCard";
import BookExpertConsultaionCard from "./component/BookExpertConCard";
import ConsultationImage from "../../assets/images/Doctor consultation.png";

export default function Consultation() {
  const data = {
    title: "Consultations",
    image: ConsultationImage,
    doctor: "Doctor tele-consultation- Dr Rebecca Shweta...",
  };
  return (
    <IonPage>
      <IonContent>
        <div className="bg-slate-50">
          <BookHassleCard {...data} />
          <AppoinmentCard {...data} />
          <BookExpertConsultaionCard />
        </div>
      </IonContent>
    </IonPage>
  );
}
