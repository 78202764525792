// import Dexie from "dexie";

class StorageService {
  constructor() {
    this.prefix = "zy.";
    this.defaultUiConfig = {
      isChatEnabled: false,
      isChatVisible: false,
      isHealthPlanEnabled: false,
      isMenuEnabled: false,
      isReportingEnabled: false,
      isVitalEnabled: false,
    };
    // this.localStorage = new EventDatabase();
  }

  set(key, value) {
    try {
      localStorage.setItem(this.prefix + key, JSON.stringify(value));
      // this.localStorage.localData.put({
      //   value: JSON.stringify(value),
      //   key: this.prefix + key,
      // });
    } catch (e) {
    } finally {
      // nothing
    }
  }

  // async get(key) {
  //   try {
  //     return await this.localStorage.localData.get(
  //       { key: this.prefix + key },
  //       (res) => {
  //         let ob = res;
  //         if (key === "stateMessage" && (ob === null || ob === "undefined")) {
  //           ob = this.defaultUiConfig;
  //         } else {
  //           ob = res ? res.value : undefined;
  //           ob = JSON.parse(ob);
  //         }
  //         return ob;
  //       }
  //     );
  //   } catch (e) {
  //     console.log(e, "catch");
  //     return e;
  //   }
  // }
  get(key) {
    try {
      let ob = localStorage.getItem(this.prefix + key);

      if (key === "stateMessage" && (ob === null || ob === "undefined")) {
        ob = this.defaultUiConfig;
      } else {
        ob = JSON.parse(ob);
      }

      return ob;
    } catch (e) {
      return undefined;
    }
  }
  remove(key) {
    try {
      let ob = localStorage.removeItem(this.prefix + key);

      if (key === "stateMessage" && (ob === null || ob === "undefined")) {
        ob = this.defaultUiConfig;
      } else {
        ob = JSON.parse(ob);
      }

      return ob;
    } catch (e) {
      return undefined;
    }
  }

  clearAll() {
    localStorage.clear();
  }
}

export default StorageService;
// class EventDatabase extends Dexie {
//   constructor() {
//     super("local-table");

//     this.version(1).stores({
//       storage: "&key",
//     });
//     this.localData = this.table("storage");
//   }

//   deleteIndexDb(id) {
//     return new Promise((resolve) => {
//       let idb = indexedDB.open("localStorage", 10);
//       idb.onsuccess = (event) => {
//         let db = event.target.result;
//         let eventTransaction = db.transaction("events", "readwrite");
//         let eventStore = eventTransaction.objectStore("events");
//         eventStore.delete(id);
//       };
//     })
//       .then((res) => {})
//       .catch((err) => {
//         console.log(err);
//       });
//   }
// }
