import React from "react";
import { useDispatch } from "react-redux";
import { logEvent } from "../../../app/eventSlice";
import zylaLogo from "../../../assets/icons/zyla-logo.png";

export default function PremiumBuyCard({ country, startPayment }) {
  const dispatch = useDispatch();
  return (
    <div className="premium-div-parent" id="premium-buy-card">
      <div className="premium-div">
        <h3>Zyla Premium for you</h3>
        <ul>
          <li>Root cause analysis call on Day 1</li>
          <li>
            Customized Health Plan with a well analysed medical summary document
          </li>
          <li>Unlimited care management via chat</li>
          <li>Progress Review Call after 7 days</li>
          <li>Review calls every 15 days</li>
          <li>
            Scheduled calls with Physiotherapists, Nutritionists and Senior
            doctors
          </li>
          <li>Live Interactive Yoga sessions</li>
          <li>Special offers on Dental checkups</li>
        </ul>
        <div className="gold-div">
          <img className="logo" src={zylaLogo} alt="logo" />
          <p>ZYLA Premium Health Program</p>
          <p id="pricing-text">
            {country?.currencySymbol} {country?.price} / 3 months
          </p>
          <button
            className="get-plan"
            onClick={() => {
              dispatch(logEvent({ action: "Click_Buy_Now" }));
              if (country?.code === "91") {
                startPayment();
              } else {
                window.open("https://zyla.in/safe-pay", "_blank");
              }
            }}
          >
            GET NOW
          </button>
        </div>
      </div>
    </div>
  );
}
