import React, { Component } from "react";
import { IonSpinner } from "@ionic/react";

class LoadingModal extends Component {
  constructor() {
    super();
  }
  render() {
    return (
      <div className="loading-spinner-holder">
        <IonSpinner />
      </div>
    );
  }
}
export default LoadingModal;
