import backImage from "../../../assets/images/back.png";
import locationImage from "../../../assets/images/addresss.png";
import { Link } from "react-router-dom";

export function BookHassleCard({ title, image }) {
  const headerStyle = {
    color: "#171A21",
  };
  return (
    <>
      <div className="flex items-center p-4 ">
        <Link to="/tab/home">
          <img src={backImage} alt="icon" className="w-6 h-6 no-underline" />
        </Link>
        <div className="px-3 font-bold text-base leading-7" style={headerStyle}>
          {title}
        </div>
      </div>
      <div className="w-full p-2 px-5" style={{ backgroundColor: "#DCDDE0" }}>
        <img src={locationImage} alt="" className="w-4 h-4" />
        ...
      </div>
      <div className="p-4">
        <img src={image} alt="" className="w-full h-32" />
      </div>
    </>
  );
}
