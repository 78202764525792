import { IonContent, IonPage } from "@ionic/react";
import React, { useEffect } from "react";
import "../privacy/index.scss";
import BackButton from "../../components/common/BackButton";
import DOMPurify from "dompurify";
import backImage from "../../assets/icons/arrow-back-black.svg";
import { Link, useLocation } from "react-router-dom";
import temp from "../../utils/termsAndConditionData";

const imgStyle = {
  width: "24px",
  height: "24px",
};

const Terms = () => {
  useEffect(() => {
    const content = document.getElementById("terms-content");
    const paraTags = content.querySelectorAll("p");
    paraTags.forEach((tag) => {
      tag.classList.add("text-xs");
    });
  }, []);

  const location = useLocation();
  let passedData = false;

  if (location.state) {
    passedData = location.state;
  }

  const backButtonLink = passedData ? "/login" : "/tab/hamburger";

  // console.log("passed data", passedData);
  return (
    <IonPage>
      <IonContent>
        <div className="terms-privacy-page-container">
          <div className=" flex items-center pr-5 pt-4 space-x-2">
            {/* <Link to={backButtonLink}>
              {" "}
              <img
                src={backImage}
                alt="backButton"
                className="cursor-pointer"
                style={imgStyle}
              />
            </Link> */}
            <BackButton />
            <span
              className="font-bold text-base "
              style={{ lineHeight: "17.39px", color: "#171A21" }}
            >
              Terms and conditions
            </span>
          </div>
          <div
            id="terms-content"
            className="text-[#171a21]"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(temp),
            }}
          ></div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Terms;
