import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../app/axiosConfig";
import APIConfig from "../../services/APIConfig";

const initialState = {
  paCompleted: false,
  metadata: {
    next: true,
    previous: false,
    progress: 0,
  },
  currentQuestion: undefined,
  height: 1,
  weight: 1,
  ui: {
    loading: false,
    error: undefined,
    submitted: false,
  },
};

export const fetchAssessmentApi = createAsyncThunk(
  "assessment/fetchAssessmentApi",
  async (data, { rejectWithValue }) => {
    try {
      const url = APIConfig.assessment.getPA.format(data.patientId);
      const response = axiosClient.get(url);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitAnswerApi = createAsyncThunk(
  "assessment/submitAnswerApi",
  async (data, { rejectWithValue }) => {
    try {
      const url = APIConfig.assessment.submitPA.format(data.patientId);
      let payload = {
        answer: data.answer,
        questionId: "" + data.id,
      };
      const response = axiosClient.post(url, payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchNextQuestionApi = createAsyncThunk(
  "assessment/fetchNextQuestionApi",
  async (data, { rejectWithValue }) => {
    try {
      const url = APIConfig.assessment.nextQuestion.format(
        data.patientId,
        data.id
      );

      const response = axiosClient.get(url);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPreviousQuestionApi = createAsyncThunk(
  "assessment/fetchPreviousQuestionApi",
  async (data, { rejectWithValue }) => {
    try {
      const url = APIConfig.assessment.previousQuestion.format(
        data.patientId,
        data.id
      );
      const response = axiosClient.get(url);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
const handleError = (state, action) => {
  state.ui = {
    ...state.ui,
    loading: false,
    error: action.payload?.data,
  };
};
const handlePending = (state, action) => {
  state.ui = {
    ...state.ui,
    loading: true,
    error: null,
  };
};
export const assessmentSlice = createSlice({
  name: "assessment",
  initialState,
  reducers: {
    setValError: (state, action) => {
      state.ui = {
        ...state.ui,
        error: action.payload,
      };
    },
    markComplete: (state, action) => {
      state.paCompleted = true;
    },
  },
  extraReducers: {
    [submitAnswerApi.pending]: (state, action) => {
      handlePending(state, action);
    },
    [submitAnswerApi.rejected]: (state, action) => {
      handleError(state, action);
    },
    [submitAnswerApi.fulfilled]: (state, action) => {
      const qid = action.meta.arg.id;

      if (action.payload.data) {
        if (qid === 4 || qid === "4") {
          // weight
          state.weight = action.meta.arg.answer;
        } else if (qid === 5 || qid === "5") {
          // height
          state.height = action.meta.arg.answer;
        }
      }

      state.ui = {
        ...state.ui,
        loading: false,
        error: null,
      };
    },
    [fetchAssessmentApi.pending]: (state, action) => {
      handlePending(state, action);
    },
    [fetchAssessmentApi.rejected]: (state, action) => {
      handleError(state, action);
    },
    [fetchAssessmentApi.fulfilled]: (state, action) => {
      const pa = action.payload.data;
      state.ui = {
        ...state.ui,
        loading: false,
        error: null,
      };
      state.paCompleted = pa.paCompleted;
      if (!pa.paCompleted) {
        state.currentQuestion = pa.currentQuestion.question;
        state.metadata = pa.currentQuestion.metadata;
      }
    },
    [fetchNextQuestionApi.pending]: (state, action) => {
      handlePending(state, action);
    },
    [fetchNextQuestionApi.rejected]: (state, action) => {
      handleError(state, action);
      if (action.error.code === "ERR_BAD_REQUEST") {
        // no next question.. mark pa as complete
        state.paCompleted = true;
        state.currentQuestion = null;
      }
    },
    [fetchNextQuestionApi.fulfilled]: (state, action) => {
      const pa = action.payload.data;

      state.ui = {
        ...state.ui,
        loading: false,
        error: null,
      };
      state.currentQuestion = pa.question;
      state.metadata = pa.metadata;
    },

    [fetchPreviousQuestionApi.pending]: (state, action) => {
      handlePending(state, action);
    },
    [fetchPreviousQuestionApi.rejected]: (state, action) => {
      handleError(state, action);
    },
    [fetchPreviousQuestionApi.fulfilled]: (state, action) => {
      const pa = action.payload.data;
      state.ui = {
        ...state.ui,
        loading: false,
        error: null,
      };
      state.currentQuestion = pa.question;
      state.metadata = pa.metadata;
    },
  },
});
export const { setValError, markComplete } = assessmentSlice.actions;
export default assessmentSlice.reducer;
