import { Device } from "@capacitor/device";

// let globalClevertap = "original";

// const webScript = () => {
//   window.clevertap = {
//     event: [],
//     profile: [],
//     account: [],
//     onUserLogin: [],
//     notifications: [],
//     privacy: [],
//   };
//   // replace with the CLEVERTAP_ACCOUNT_ID with the actual ACCOUNT ID value from your Dashboard -> Settings page
//   window.clevertap.account.push({ id: "TEST-Z4R-4KW-7R6Z" });
//   window.clevertap.privacy.push({ optOut: false }); //set the flag to true, if the user of the device opts out of sharing their data
//   window.clevertap.privacy.push({ useIP: false }); //set the flag to true, if the user agrees to share their IP data

//   let wzrk = document.createElement("script");
//   wzrk.type = "text/javascript";
//   wzrk.async = true;
//   wzrk.src =
//     ("https:" == document.location.protocol
//       ? "https://d2r1yp2w7bby2u.cloudfront.net"
//       : "http://static.clevertap.com") + "/js/a.js";
//   let s = document.getElementsByTagName("script")[0];
//   s.parentNode.insertBefore(wzrk, s);

//   window.clevertap.event.push("registration successfull");
//   console.log("glovbal clevertap is created ", window.clevertap);

//   return window.clevertap;
// };

// export default globalClevertap;

const webScript = () => {
  window.clevertap = {
    event: [],
    profile: [],
    account: [],
    onUserLogin: [],
    notifications: [],
    privacy: [],
  }; // replace with the CLEVERTAP_ACCOUNT_ID with the actual ACCOUNT ID value from your Dashboard -> Settings page
  window.clevertap.account.push({
    id: process.env.REACT_APP_CLEVERTAP_ACCOUNT_ID,
  });
  window.clevertap.privacy.push({ optOut: false }); //set the flag to true, if the user of the device opts out of sharing their data
  window.clevertap.privacy.push({ useIP: true }); //set the flag to true, if the user agrees to share their IP data

  let wzrk = document.createElement("script");
  wzrk.type = "text/javascript";
  wzrk.async = true;
  wzrk.src =
    ("https:" === document.location.protocol
      ? "https://d2r1yp2w7bby2u.cloudfront.net"
      : "http://static.clevertap.com") + "/js/a.js";
  let s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(wzrk, s); // window.clevertap.event.push("registration successfull");
};

class CleverTapGlobal {
  constructor() {
  
    Device.getInfo().then((r) => {
      if (r.platform === "ios") {
        // optional: add listeners for CleverTap Events
        this.platform = "ios";

        // CleverTap.setDebugLevel(3);

        document.addEventListener("onCleverTapProfileDidInitialize", (e) => {
          // console.log(e.CleverTapID);
        });

        document.addEventListener(
          "onCleverTapInAppNotificationDismissed",
          (e) => {
            // console.log("onCleverTapInAppNotificationDismissed");
            // console.log(JSON.stringify(e.extras));
            // console.log(JSON.stringify(e.actionExtras));
          }
        ); //deeplink handling

        document.addEventListener("onDeepLink", (e) => {
          // console.log("onDeepLink");
          // console.log(e.deeplink);
        }); //push notification payload handling

        document.addEventListener("onPushNotification", (e) => {
          // console.log("onPushNotification");
          // console.log(JSON.stringify(e.notification));
        });
        // CleverTap.registerPush()
        //   .then((e) => {
        //     console.log("registered for push notification successfully", e);
        //   })
        //   .catch((e) =>
        //     console.log(
        //       "something went wrong cannot register for push notification"
        //     )
        //   );
      } else {
        webScript();

        this.platform = "web";
      }
    });
  }
}

export default new CleverTapGlobal();
