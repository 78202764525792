const troublingCondition = 'troubling_condition';
const DOB= 'dob';
const weight = 'weight';
const height = 'height';
const BMI = 'bmi';
const symptoms = 'symptoms'
const polar = 'polar';
const polarTwo = 'polar_2';
const polarThree = 'polar_3';
const polarFour = 'polar_4';
const polarFive = 'polar_5';
const sugarLevel = 'sugar_level';
const diabetesMedicine = 'diabetes_medicine';
const polarSeven = 'polar_7';
const energyLevel = 'energy_level';

export default {
  troublingCondition,
  DOB,
  weight,
  height,
  BMI,
  symptoms,
  polar,
  polarTwo,
  polarThree,
  polarFour,
  polarFive,
  sugarLevel,
  diabetesMedicine,
  polarSeven,
  energyLevel
}