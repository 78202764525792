import ApiService from "./ApiService";
import APIConfig from "./APIConfig";
import AuthenticationService from "./AuthenticationService";
import { LocalNotifications } from "@capacitor/local-notifications";
import StorageService from "./StorageService";
import axiosClient from "../app/axiosConfig";
import axios from "axios";

export default class NotificationService {
  constructor() {
    this.authService = new AuthenticationService();
    this.storageService = new StorageService();
  }

  updatePushCredentials(token, user) {
    const url = APIConfig.fcm.pushCredential;
    // const url = "https://staging.services.zyla.in/v1/notifications";

    this.storageService.set("identity", token);
    let pushCredential = {
      pushToken: token,
      userId: user.id,
      identity: token,
    };
    axiosClient
      .post(url, pushCredential)
      .then((res) => {})
      .catch((err) => {});
  }

  _processNotification(notification) {
    if (notification.data) {
      // Sample obj
      // {
      //   "id":"0:1569331101047632%008d0b4c008d0b4c",
      //   "data":{
      //   "body":"This is my second push",
      //   "title":"Test Push"
      //   }
      // }

      this._processMessage(notification.data);
    }
    if (notification.notification) {
      // Sample obj
      // {
      //   "actionId":"tap",
      //   "notification":{
      //   "id":"0:1569331195228966%008d0b4c008d0b4c",
      //   "data":{
      //   "google.delivered_priority":"high",
      //   "google.sent_time":"1569331195220",
      //   "google.ttl":"2419200",
      //   "google.original_priority":"high",
      //   "body":"This is my second push",
      //   "from":"160012889513",
      //   "title":"Test Push",
      //   "collapse_key":"com.enappd.IonicReactPush"}
      //   }
      //   }
      this._processMessage(notification.body);
    }
  }
  _processMessage(message) {
    let action = message.action ? message.action : "default";
    if (action === "generic") {
      message.targetUrl = "/tab/chat";
      this._showNotification(message);
    } else if (action === "update") {
      let resultIntent =
        "https://apps.apple.com/in/app/zyla-control-your-diabetes/id1496316750";
      message.targetUrl = encodeURI(resultIntent);
      message.external = true;
      this._showNotification(message);
    } else if (action === "meditation") {
      message.targetUrl = "/daily-task";
      this._showNotification(message);
    } else if (action === "logout") {
    } else if (action === "reporting") {
      message.targetUrl = "/reporting";
      this._showNotification(message);
    } else if (action === "document") {
      message.targetUrl = "/document";
      this._showNotification(message);
    } else if (action === "reminder_reporting") {
      message.targetUrl = "/document";
      this._showNotification(message);
    } else if (action === "chat") {
      if (window.location.href !== "/tab/chat") {
        message.targetUrl = "/tab/chat";
        this._showNotification(message);
      }
    }
  }
  _showNotification(message) {

    LocalNotifications.schedule({
      notifications: [
        {
          title: message.title,
          body: message.body,
          id: Math.random(),
          schedule: { at: new Date(Date.now() + 1000 * 1) },
          sound: null,
          attachments: null,
          actionTypeId: "",
          extra: {
            targetUrl: message.targetUrl,
            external: message.external,
          },
        },
      ],
    });
  }
  _showPictureNotification(message) {
   
    LocalNotifications.schedule({
      notifications: [
        {
          title: message.title,
          body: message.body,
          id: Math.random(),
          schedule: { at: new Date(Date.now() + 1000 * 1) },
          sound: null,
          attachments: null,
          actionTypeId: "",
          extra: {
            targetUrl: message.targetUrl,
            external: message.external,
          },
        },
      ],
    });
  }
  postNotifAnalytics = (payload, user) => {
    let shouldSendReq = false;

    let url = APIConfig.events.notificationAnalytics.format(user.id);
    let token = this.storageService.get("identity");
    let params = {
      userId: user.id,
      token: token,
    };

    if (window.Capacitor.platform !== "web") {
      if (payload.id) {
        shouldSendReq = true;
        params.notificationId = payload.id;
      }
    }
    if (shouldSendReq) {
      axiosClient
        .post(url, params)
        .then((res) => {})
        .catch((err) => {});
    }
  };
}
