import React, { useState } from "react";
import PlayImage from "../../../../assets/images/group_copy.png";
import "../../../meditation/index.scss";
import { Drawer } from "@mui/material";
import AudioPlayer from "../../../meditation/components/audioPlayer";
import BackArrowImage from "../../../../assets/images/icon_arrow_back_white.png";

import SecureImage from "../../../../components/common/SecureImage";
import defaultImage from "../../../../assets/images/bg_pattern.png";

import overlayLock from "../../../../assets/images/overlay-lock.png";

import { useDispatch, useSelector } from "react-redux";
import { logEventWithData } from "../../../../app/eventSlice";

const MeditationCard = ({ media }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const token = useSelector((store) => store.tab.docToken?.token);
  const dispatch = useDispatch();

  const togglePlay = () => {
    setIsPlaying((prevIsPlaying) => !prevIsPlaying);
  };
  const getUrlWithToken = (url) => {
    if (url && url.indexOf("?") != -1) {
      url = `${url}&token=${token}`;
    } else {
      url = `${url}?token=${token}`;
    }
    return url;
  };

  let fileURL = getUrlWithToken(media.url);

  return (
    <React.Fragment>
      {isPlaying && (
        <Drawer
          className={"menu-partial-full-height meditation-container"}
          anchor="bottom"
          open={isPlaying}
          onClose={() => togglePlay()}
        >
          <SecureImage
            classN="playing-current-media"
            defaultImage={defaultImage}
            src={media.artwork}
          ></SecureImage>
          {/* <img className="playing-current-media" src={media.artwork} /> */}
          <div className="media-content-data">
            <img
              onClick={() => togglePlay()}
              className="back-icon"
              src={BackArrowImage}
            />
            <div className="playing-page-content responsive-600">
              <div className="paying-media-data">
                <div className="media__language">{media.language}</div>
                <div className="title-playing">{media.title}</div>
                <div className="description-playing">{media.description}</div>
              </div>
              <AudioPlayer
                file={fileURL}
                isNext={false}
                isPrevious={false}
              ></AudioPlayer>
            </div>
          </div>
        </Drawer>
      )}
      {!isPlaying && (
        <div className="meditation-chat-card" style={{ marginBottom: "0px" }}>
          <div className="meditation-container">
            <div
              onClick={() => {
                if (window.location.pathname == "/tab/home") {
                  dispatch(
                    logEventWithData({
                      action: "click_recommended_meditation_home",
                      data: { id: media.id, title: media.title },
                    })
                  );
                } else {
                  dispatch(
                    logEventWithData({
                      action: "click_meditation",
                      data: { id: media.id, title: media.title },
                    })
                  );
                }
                togglePlay(media.id, media.title);
              }}
              className="media-container"
            >
              <SecureImage
                classN="media-thumbnail"
                defaultImage={defaultImage}
                src={media.artwork}
              ></SecureImage>

              <div className="media-list-data">
                <div className="content-data">
                  <div className="media-text-content">
                    <div className="media__language">{media.language}</div>
                    <div className="media-title  fw-medium">{media.title}</div>
                    <div className="fs12">{media.description}</div>
                  </div>
                  <div>
                    <img className="common-img" src={PlayImage} />
                  </div>
                </div>
              </div>
            </div>
            {media.locked && (
              <div
                className="meditation-overlay"
                onClick={() => togglePlay(media.id, media.title)}
              >
                <img src={overlayLock} />
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default MeditationCard;
