import axios from "axios";
import APIConfig from "../services/APIConfig";
import jwt_decode from "jwt-decode";
import { refreshAndSaveTokenNew } from "../utils/helperMethods";

// Next we make an 'instance' of it

let isTokenGettingReplaced = false;

const axiosClient = axios.create({
  // .. where we make our configurations
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",

  headers: {
    common: {
      client: "patient_android",
    },
  },
});

// Where you would set stuff like your 'Authorization' header, etc ...
//instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';
axiosClient.interceptors.request.use(
  async function (config) {
    let header = {
      client: "patient_android",
      "content-type": config.headers.get("content-type")
        ? config.headers.get("content-type")
        : "application/json",
    };
    // const cookies = sessionManager.getAllCookies();
    // const token = cookies["token"];
    // const phoneNo = cookies["phone_no"];

    const token = localStorage.getItem("zy.token");
    const phoneNo = localStorage.getItem("zy.phone_no");
    if (token && !isTokenGettingReplaced) {
      let decoded = jwt_decode(token);
      if (decoded.exp * 1000 < Date.now()) {
        isTokenGettingReplaced = true;
        try {
          let res = await refreshAndSaveTokenNew();
          isTokenGettingReplaced = false;
        } catch (err) {
          isTokenGettingReplaced = false;
        }
      }
    }
    if (phoneNo && token) {
      if (!config.url.endsWith("login/token")) {
        header = {
          ...header,
          phone_no: phoneNo,
          auth_token: token,
        };
      } else {
        header = {
          ...header,
          phone_no: phoneNo,
        };
      }
    }
    if (config.skipHeader) {
      config.headers = {};
    } else {
      config.headers = header;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// axiosClient.interceptors.request.use(
//   async (config) => {
//     console.log("config recieved is ", config);
//     try {
//       const cookies = sessionManager.getAllCookies();
//       const aToken = cookies["token"];
//       const eToken = cookies["etoken"];
//       let shouldSendReq = false;
//       if (aToken) {
//         let decoded = jwt_decode(aToken);
//         if (decoded.exp * 1000 < Date.now()) {
//           shouldSendReq = true;
//         }
//       }
//       if (!shouldSendReq && eToken) {
//         let decoded = jwt_decode(eToken);
//         if (decoded.exp * 1000 < Date.now()) {
//           shouldSendReq = true;
//         }
//       }
//       if (!shouldSendReq && (!aToken || !eToken)) {
//         console.log("3rd request is true");
//         shouldSendReq = true;
//       }
//       if (shouldSendReq) {
//         console.log("token expired ", aToken, eToken);
//         var url = APIConfig.user.token;
//         var payload = {
//           accessToken: "",
//           expiryToken: eToken,
//         };
//         const phoneNo = cookies["phone_no"];
//         const rs = axios
//           .post(url, payload, {
//             headers: {
//               client: "patient_android",
//               "Content-Type": "application/json",
//               phone_no: phoneNo,
//             },
//           })
//           .then((res) => {
//             console.log("res is ", res);
//           })
//           .catch((err) => {
//             console.log("err is ", err);
//           });
//         console.log("request sent res recieved");
//         if (rs.status === 200) {
//           sessionManager.setCookie("token", rs.data.accessToken);
//           sessionManager.setCookie("etoken", rs.data.expiryToken);
//           sessionManager.setCookie("phone_no", phoneNo);
//         }
//         return config;
//       } else {
//         return config;
//       }
//     } catch (_error) {
//       return config;
//     }
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );

axiosClient.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    // Access Token was expired

    if (
      (err.response.status === 401 || err.response.status === 403) &&
      !originalConfig._retry
    ) {
      originalConfig._retry = true;

      try {
        const eToken = localStorage.getItem("zy.etoken");
        const phoneNo = localStorage.getItem("zy.phone_no");

        if (eToken) {
          var url = APIConfig.user.token;
          var payload = {
            accessToken: "",
            expiryToken: eToken,
          };

          let headers = {
            client: "patient_android",
            "Content-Type": "application/json",
            phone_no: phoneNo,
          };

          const rs = await axiosClient.post(url, payload, originalConfig);
          if (rs.status === 200) {
            window.dispatchEvent(new Event("generateDocToken"));
            localStorage.setItem("zy.token", rs.data.accessToken);
            localStorage.setItem("zy.etoken", rs.data.expiryToken);
            localStorage.setItem("zy.phone_no", phoneNo);
          } else if (rs.status === 403) {
            window.dispatchEvent(new Event("logUserOut"));
          } else {
            let decoded = jwt_decode(eToken);
            if (decoded.exp * 1000 < Date.now()) {
              window.dispatchEvent(new Event("logUserOut"));
              // window.location.replace("/login");
            }
          }
        } else {
          window.dispatchEvent(new Event("logUserOut"));
        }

        // sessionManager.setCookie("token", rs.);
        //  localStorage.setItem("zy.ACCESS_TOKEN", d.token);

        return axiosClient(originalConfig);
      } catch (_error) {
        if (_error && _error.response && _error.response.status === 403) {
          window.dispatchEvent(new Event("logUserOut"));
        }
        return Promise.reject(_error);
      }
    }

    return Promise.reject(err);
  }
);
export default axiosClient;
