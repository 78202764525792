import React from "react";
import happyIcon from "../../../assets/icons/emojiIcon/happy.png";
import veryhappyIcon from "../../../assets/icons/emojiIcon/veryHappy.png";
import sadIcon from "../../../assets/icons/emojiIcon/sad.png";
import neutralIcon from "../../../assets/icons/emojiIcon/neutral.png";

let foodMsg = {
  recommended: {
    icon: veryhappyIcon,
    msg: "Definitely, it's amazing for you!",
  },
  allowed: { icon: happyIcon, msg: "Good choice" },
  notAllowed: { icon: sadIcon, msg: "Let's avoid having it for now" },
  limited: { icon: neutralIcon, msg: "Try limiting it as much as possible" },
};

export default function FoodListItem({ item }) {
  const {
    recommended,
    allowed,
    notAllowed,
    limited,
    foodItemType,
    foodItemName,
  } = item;
  let type = recommended
    ? "recommended"
    : allowed
    ? "allowed"
    : limited
    ? "limited"
    : "notAllowed";
  return (
    <div className="food-list-item-container">
      <div className="food-list-item-box">
        <div className="food-list-item-box-left">
          <div className="food-list-item-box-left-top">
            <span>{foodItemName}</span>
          </div>
          <div className="food-list-item-box-left-bottom">
            <img src={foodMsg[type].icon} alt="icon" />
            <span>{foodMsg[type].msg}</span>
          </div>
        </div>
        <div className="food-list-item-box-right">
          <span>{foodItemType}</span>
        </div>
      </div>
    </div>
  );
}
