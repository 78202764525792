import { Link } from "react-router-dom";
import backIMage from "../../assets/images/back.png";
import partialImage from "../../assets/images/partial.png";
import { useHistory } from "react-router";
import { useState } from "react";
import { Premium } from "./component/RenePremCard";
import { createPortal } from "react-dom";
import { HealthyWeekCard } from "./component/HealthyWeekCard";
import { DiscountCard } from "./component/DiscountCard";
import { IonContent, IonPage } from "@ionic/react";
import infoImage from "../../assets/images/info.png";
import BackButton from "../../components/common/BackButton";
export function Policy() {
  const policy = [
    {
      id: 2,
      weeks: "0-12",
      discount: "Nil",
    },
    {
      id: 3,
      weeks: "13-26",
      discount: "5%",
    },
    {
      id: 4,
      weeks: "27-36",
      discount: "7.5%",
    },
    {
      id: 4,
      weeks: "Above 36",
      discount: "10%",
    },
  ];
  const [showModal, setshowModel] = useState(false);
  const [showHealthyWeek, setshowHealthyWeek] = useState(false);
  const [showDiscount, setshowDiscount] = useState(false);
  function closeModel(e) {
    setshowModel(false);
  }
  function closeHealthyWeekModel(e) {
    setshowHealthyWeek(false);
  }
  function closeDiscountModel() {
    setshowDiscount(false);
  }

  return (
    <IonPage>
      <IonContent>
        <section className="h-full">
          <div className=" bg-white h-full text-lightblack" id="container">
            <div className="flex items-center text-center justify-between">
              <div className="flex items-center text-center font-bold text-base leading-4 p-4">
                <BackButton />
                {/* <div className="px-3">
                  Policy:
                  <span
                    style={{ color: "#171A21" }}
                    className="text-base font-normal leading-4"
                  >
                    GH126X1201
                  </span>
                </div> */}
              </div>

              {/* <div>
                <img src={partialImage} alt="image" className="w-6 h-6" />
              </div> */}
            </div>

            <div className="font-medium text-xs leading-5 pb-2 px-4 ">
              Healthy Weeks help you earn discount on your renewal premium of
              your Critical Illness Rider. Any week in which you record more
              than 50000 steps with a maximum of 15000 steps per day will be
              counted as a Healthy Week.
            </div>
            <div
              className="cursor-pointer pt-9"
              onClick={() => setshowModel(true)}
            >
              <div className="font-bold text-xs leading-5 pb-3 px-4 flex items-center">
                Discount on renewal premium &nbsp;
                <img src={infoImage} alt="" className="w-3 h-3" />
              </div>
            </div>
            <div className="p-4 w-full bg-[#E7F4ED] flex justify-between  mb-2 font-semibold ">
              <div
                className="cursor-pointer"
                onClick={() => setshowHealthyWeek(true)}
              >
                <div className="font-bold leading-3 bg-[#E7F4ED] flex items-center text-xs ">
                  Healthy Weeks &nbsp;
                  <img src={infoImage} alt="" className="w-3 h-3" />
                </div>
              </div>
              <div
                className="cursor-pointer"
                onClick={() => setshowDiscount(true)}
              >
                <div className="font-bold leading-3 flex items-cente text-xs">
                  Discount &nbsp;
                  <img src={infoImage} alt="" className="w-3 h-3" />
                </div>
              </div>
            </div>
            {policy.map((e) => {
              return (
                <>
                  <div key={e.id}>
                    <div className="p-4 bg-[#E7F4ED]  flex justify-between w-full  mb-2 text-xs ">
                      <span className="font-semibold">{e.weeks}</span>
                      <span className="font-semibold">{e.discount}</span>
                    </div>
                  </div>
                </>
              );
            })}
            {showModal && <Premium closeModel={closeModel} />}
            {showHealthyWeek && (
              <HealthyWeekCard closeHealthyWeekModel={closeHealthyWeekModel} />
            )}
            {showDiscount && (
              <DiscountCard
                closeDiscountModel={closeDiscountModel}
                showDiscount={showDiscount}
              />
            )}
          </div>
        </section>
      </IonContent>
    </IonPage>
  );
}
