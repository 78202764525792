import { IonContent, IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import BackButton from "../../components/common/BackButton";
import { useSelector } from "react-redux";
import navigoServices from "../../services/NavigoServices";
import moment from "moment";
import { HistoryCard } from "./components/historyCard";
import "./index.scss";
import { NoAppointmentFound } from "../navigo/components/noAppointmentFound";
import { CircularProgress } from "@mui/material";

const BookingHistory = () => {
  const user = useSelector((store) => store.login.user);
  const [allAppoints, setAllAppoints] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleBookingHistory();
  }, []);

  const processAppointmentArray = (appoints) => {
    let newAppoints = appoints.map((appoint) => {
      return {
        ...appoint.appointment,
        appointmentDate: moment(appoint.appointment.appointmentDate).format(
          "DD MMM, YYYY"
        ),
        bookingDate: moment(appoint.appointment.bookingDate).format(
          "DD MMM, YYYY"
        ),
        serviceName: appoint.service.name,
        price: appoint.service.discountedPrice,
      };
    });
    return newAppoints;
  };

  const handleBookingHistory = () => {
    let patientProfileId = user?.patientProfile?.id;
    setLoading(true);
    navigoServices
      .getAllAppointmentsByPatientId(patientProfileId, "")
      .then((res) => {
        const result = res.data;
        let upAppoints = [];
        let pastAppoints = [];

        if (result && result.past && result.past.length > 0) {
          pastAppoints = processAppointmentArray(result.past);
        }
        if (result && result.upcoming && result.upcoming.length > 0) {
          upAppoints = processAppointmentArray(result.upcoming);
        }
        setLoading(false);
        setAllAppoints([...upAppoints, ...pastAppoints]);
      })
      .catch((err) => {
        setLoading(false);
        setAllAppoints([]);
      });
  };

  return (
    <IonPage>
      <IonContent style={{ "--background": "#F8F9FA" }}>
        {loading ? (
          <CircularProgress
            className="ion-router-circular-progress"
            style={{ marginTop: "200px" }}
          />
        ) : (
          <div className="booking-history-container">
            <div className="booking-history-back-button">
              <BackButton></BackButton>
              <p>Order history</p>
            </div>

            <div className="booking-history-card-container">
              {allAppoints &&
                allAppoints.length > 0 &&
                allAppoints.map((appoint) => {
                  return (
                    <HistoryCard
                      key={appoint.ID}
                      name={appoint.serviceName}
                      consultDate={appoint.appointmentDate}
                      bookDate={appoint.bookingDate}
                      bookingId={appoint.ID}
                      price={appoint.price}
                      status={appoint.status}
                    />
                  );
                })}
              {allAppoints && allAppoints.length === 0 && (
                <NoAppointmentFound msg={"No previous booking found!"} />
              )}
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default BookingHistory;
