import React, { useCallback, useEffect, useRef } from "react";
import $ from "jquery";
import "../reporting.scss";
import { logEvent } from "../../../app/eventSlice";
import { useDispatch, useSelector } from "react-redux";
import { tableErrorHandle, updateReportItems } from "../reportSlice";

const fields = ["hour", "minute", "dayNight", "activity"];
export default function ReportingTable() {
  const items = useSelector((store) => store.reporting.ui.items);
  const hourRef = useRef([]);
  const minuteRef = useRef([]);
  const dayNightRef = useRef([]);
  const activityRef = useRef([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (hourRef.current[items.length - 1]) {
      hourRef.current[items.length - 1].focus();
    }
  }, [items.length]);

  const onItemUpdate = (index, property, value, isNew = false) => {
    let newRecord = {
      id: "",
      hour: "",
      minute: "",
      dayNight: "",
      activity: "",
    };

    let newItems = items.map((it, ind) => {
      if (ind === index) {
        return {
          ...it,
          [property]: value,
        };
      }
      return { ...it };
    });

    if (isNew) {
      newItems.push(newRecord);
    }
    dispatch(updateReportItems({ items: newItems, isNew: isNew }));
  };

  const onChange = (index, e) => {
    if (isInputValid(e.target.name, e.target.value)) {
      const nextName = getNextAutoFocusElement(e.target.name);
      focusTo(index, nextName);
    }

    onItemUpdate(index, "activity", e.target.value);
  };
  const onKeyDown = (index, e) => {
    let currentField = e.target.name;
    if (e.key === "Backspace") {
      if (e.target.value === 0 || e.target.value === "") {
        if (index >= 0) {
          if (currentField === "hour") {
            focusToPreviousElement(currentField, index - 1);
          } else {
            focusToPreviousElement(currentField, index);
          }
        }
      }
    }
    if (e.key === "Enter" && currentField === "activity") {
      dispatch(logEvent({ action: "add_new_row_reporting" }));

      onItemUpdate(-1, "", "", true);
    } else {
      const txtRef = $(e.target);
      $(txtRef).parent().height(txtRef[0].scrollHeight);
    }
  };
  const onChangeHour = (index, e) => {
    let value = e.target.value;

    let valid = false;
    if ((value > 1 || value === "01") && value <= 12) {
      valid = true;
      focusToNextElement("hour", index);
    }
    if (value > 12) {
      valid = false;
      e.preventDefault();
    }

    if (
      valid ||
      value === "" ||
      value === "0" ||
      value === "1" ||
      value === "01"
    ) {
      onItemUpdate(index, "hour", e.target.value);
    } else {
      dispatch(
        tableErrorHandle({ value: true, msg: "Please type correct time" })
      );
    }
  };
  const onChangeTime = (index, e) => {
    let value = e.target.value;
    let valid = false;

    if (value) {
      let isSingleDigit = value.toString().length === 1;
      let isDoubleDigit = value.toString().length === 2;

      if (isSingleDigit && value < 6 && value >= 0) {
        valid = true;
      } else if (isDoubleDigit && value < 60 && value >= 0) {
        valid = true;
        focusToNextElement("minute", index);
      }

      if (valid) {
        onItemUpdate(index, "minute", e.target.value, false);
      } else {
        dispatch(
          tableErrorHandle({ value: true, msg: "Please type correct time" })
        );
      }
    } else {
      onItemUpdate(index, "minute", value);
    }
  };
  const onChangeDayTime = (index, e) => {
    let value = e.target.value;
    let valid = false;
    value = value.toUpperCase();
    if (value) {
      let isSingleDigit = value.toString().length === 1;
      let isDoubleDigit = value.toString().length === 2;

      if (isSingleDigit && (value === "A" || value === "P")) {
        valid = true;
      } else if (isDoubleDigit && (value === "AM" || value === "PM")) {
        valid = true;
        focusToNextElement("dayNight", index);
      }

      if (valid) {
        onItemUpdate(index, "dayNight", value);
      } else {
        dispatch(
          tableErrorHandle({ value: true, msg: "Please type AM or PM" })
        );
      }
    } else {
      onItemUpdate(index, "dayNight", value);
    }
  };

  const isInputValid = useCallback((name, value) => {
    if (name === "hour" || name === "minute" || name === "dayNight") {
      return value.length === 2;
    } else {
      return false;
    }
  }, []);

  const focusToPreviousElement = (currentField, index) => {
    if (index >= 0) {
      focusTo(index, getPreviousAutoFocusElement(currentField));
    }
  };
  const focusToNextElement = (currentField, index) => {
    if (index >= 0) {
      focusTo(index, getNextAutoFocusElement(currentField));
    }
  };
  const focusTo = (index, nextName) => {
    if (nextName && index > -1) {
      let that =
        nextName === "hour"
          ? hourRef.current[index]
          : nextName === "minute"
          ? minuteRef.current[index]
          : nextName === "dayNight"
          ? dayNightRef.current[index]
          : activityRef.current[index];
      that.focus();
      var val = that.value; //store the value of the element
      that.value = ""; //clear the value of the element
      that.value = val;
    }
  };
  const getPreviousAutoFocusElement = useCallback((previousField) => {
    let v = _getAdjacentFocusElement(previousField, true);

    return v;
  }, []);
  const _getAdjacentFocusElement = useCallback((previousField, previous) => {
    let indexOfPreviousField = fields.indexOf(previousField);
    if (indexOfPreviousField != -1) {
      let fieldIndex = previous
        ? indexOfPreviousField - 1
        : indexOfPreviousField + 1;
      return fieldIndex < 0
        ? fields[fields.length - 1]
        : fieldIndex >= fields.length
        ? fields[0]
        : fields[fieldIndex];
    } else {
      return "";
    }
  }, []);
  const getNextAutoFocusElement = useCallback((previousField) => {
    return _getAdjacentFocusElement(previousField, false);
  }, []);

  return (
    <table className="reporting-table">
      <thead>
        <tr>
          <td className="reporting-cell-time" colSpan="3">
            Time of Activity
          </td>
          <td className="reporting-cell-activity" colSpan="1">
            Activity
          </td>
        </tr>
      </thead>
      <tbody>
        {items &&
          items.map((reportingItem, index) => {
            return (
              <tr key={`rp-${reportingItem.id}-${index}`}>
                <td className="text-h-m">
                  <input
                    ref={(input) => (hourRef.current[index] = input)}
                    type="tel"
                    max="2"
                    className="small-input"
                    name="hour"
                    placeholder="00"
                    onChange={(e) => onChangeHour(index, e)}
                    onKeyDown={(e) => onKeyDown(index, e)}
                    value={reportingItem.hour}
                  ></input>
                </td>
                <td className="text-h-m">
                  <input
                    ref={(input) => (minuteRef.current[index] = input)}
                    type="tel"
                    max="2"
                    className="small-input"
                    name="minute"
                    placeholder="00"
                    onChange={(e) => onChangeTime(index, e)}
                    onKeyDown={(e) => onKeyDown(index, e)}
                    value={reportingItem.minute}
                  ></input>
                </td>
                <td className="text-h-m">
                  <input
                    ref={(input) => (dayNightRef.current[index] = input)}
                    type="text"
                    className="medium"
                    name="dayNight"
                    placeholder="AM"
                    onChange={(e) => onChangeDayTime(index, e)}
                    onKeyDown={(e) => onKeyDown(index, e)}
                    value={reportingItem.dayNight}
                  ></input>
                </td>
                <td>
                  <textarea
                    className="text-v-m"
                    ref={(input) => (activityRef.current[index] = input)}
                    cols="1"
                    type="text"
                    name="activity"
                    placeholder="Activity"
                    onChange={(e) => onChange(index, e)}
                    onKeyDown={(e) => onKeyDown(index, e)}
                    tabIndex="0"
                    value={reportingItem.activity}
                  ></textarea>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}
