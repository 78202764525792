import React from "react";
import { useDispatch } from "react-redux";
import noMember from "../../../assets/images/no-member-img.svg";
import { updateLayout } from "../familySlice";
export default function NoFamilyAdded() {
  const dispatch = useDispatch();
  return (
    <div className="family-plan-no-member-container">
      <div className="family-plan-no-member-top">
        <img
          src={noMember}
          className="family-plan-no-member-top-img"
          alt="No member"
        />
      </div>
      <div className="family-plan-no-member-bottom">
        <div className="family-plan-no-member-bottom-top">
          <p>No family member added yet</p>
        </div>
        <div className="family-plan-no-member-bottom-mid">
          <button
            onClick={() => {
              dispatch(updateLayout(4));
            }}
          >
            Add member
          </button>
        </div>
        <div className="family-plan-no-member-bottom-bottom">
          <p>
            <strong>Disclaimer: </strong>By adding a family member to your
            account, you can access their medical profile. You are responsible
            for maintaining the safety of the medical records of your family
            members.
          </p>
        </div>
      </div>
    </div>
  );
}
