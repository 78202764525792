import { useSelector } from "react-redux";
import { SewaAnswerCard } from "./SewaAnswerCard";
import { SewaPointerCard } from "./SewaPointerCard";
import { SewaProgressCard } from "./SewaProgressCard";

export function SewaSection({ selectedPolicy }) {
  const defaultPolicyData = useSelector(
    (state) => state.reward.initialPolicyData
  );
  console.log("state");
  return (
    <div className="space-y-3">
      <SewaPointerCard selectedPolicy={selectedPolicy} />
      <SewaProgressCard selectedPolicy={selectedPolicy} />
      <SewaAnswerCard selectedPolicy={selectedPolicy} />
    </div>
  );
}
