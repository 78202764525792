import React from 'react';
import People from '../../assets/images/people.png';
import { IonButton, IonIcon, IonContent } from '@ionic/react';

const EmptyLongTermGoals = (props) => {

  const onClick = (e) => {
    const { handleClick } = props;
    handleClick();
  }

  return(
    <div className='empty-long-term-goals'>
      <div>
        <img src={People}/>
      </div>
      <div className='fitness-hp'>
        <div className='fitness-head fs20 fw-semibold'>Amazing fitness</div>
        <div className='fs16'>
          The only health goal you should have is to eat healthy and exercise regularly because I have found nothing off in your assessment &
          believe me its a great news.
          <br/><br/>
          Now as you know more than others about us do let the needy ones know that we can help them in managing their diabetes.
        </div>
        <div className='cont-btn-container'>
          <div className='btn-content'>
            <IonButton onClick={onClick} className='primary-btn'>
              <div className='fw-semibold fs20'>
                Continue
              </div>
            </IonButton>
          </div>
        </div>
      </div>
    </div>
  );  
}

export default EmptyLongTermGoals