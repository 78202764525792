import React from "react";
import { useDispatch } from "react-redux";

import { logEvent } from "../../../app/eventSlice";

export default function MenuItemComponent({ item, handleClick }) {
  const dispatch = useDispatch();

  return (
    <div
      nospace
      onClick={() => {
        if (item.id === "LifeStyleCharts") {
          dispatch(logEvent({ action: "open_tracking_chart_profile" }));
        }

        handleClick(item);
      }}
      className="detail-item "
    >
      <div className="item-icon-image">
        <img className="item-icon" src={item.icon} alt="icon"   />
      </div>
      <div className="menu-area">
        <div className="font-semibold text-base leading-6 " style={{color:"#171A21"}}>{item.name}</div>
        {item.new && <div className="new-badge">NEW</div>}

        {/* <img className="next-item-icon" src={next} /> */}
      </div>
    </div>
  );
}
