import { useHistory } from "react-router-dom";
import DailyTarget from "./DailyTargets";
import { useDispatch } from "react-redux";
import { logEvent } from "../../../app/eventSlice";
import { updateVitalChartEndTime } from "../../vitalsChart/vitalsChartSlice";
import moment from "moment/moment";

export function MyVitals() {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <>
      <div className="text-lightblack mt-14">
        <div className=" flex justify-between mt-3 ">
          <div className="text-lightblack font-bold text-base leading-5">
            My vitals
          </div>
          <div
            className="font-bold text-base text-zyple cursor-pointer underline"
            onClick={() => {
              dispatch(logEvent({ action: "my vitals view all" }));
              dispatch(updateVitalChartEndTime(moment.now()));
              history.push("/vitalsChart");
            }}
          >
            View all
          </div>
        </div>
      </div>
      <div>
        <DailyTarget />
        {window.Capacitor.platform === "ios" && (
          <p className="text-[10px] text-[#777777] mt-[4px] mb-0">
            We use HealthKit APIs to fetch your step count.
          </p>
        )}
      </div>
    </>
  );
}
