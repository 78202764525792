const loadScriptfun = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

class RazorPayHelper {
  loadScript = () => {
    loadScriptfun("https://checkout.razorpay.com/v1/checkout.js")
      .then((res) => {
        console.log("script loaded");
        window.rzploaded = res;
      })
      .catch((res) => {
        console.log("something went wrong");
      });
  };
}

export default new RazorPayHelper();
