import moment from "moment";
export const stringToIntTime = (s) => {
  let tt = s.split(":");
  let h = parseInt(tt[0]);
  let m = parseInt(tt[1]);
  return parseInt(h * 60 + m);
};
export const getStringSlot = (slot) => {
  let hours = Math.floor(slot / 60);
  let minutes = slot % 60;
  let hourString = hours < 10 ? "0" + hours : hours;
  let minString = minutes < 10 ? "0" + minutes : minutes;
  return hourString + ":" + minString;
};
export const getExactDisplayName = (fmObj, appoint) => {
  let displayName = "Self";
  if (appoint.appointment.bookedFor !== 0) {
    if (fmObj[appoint.appointment.bookedFor]) {
      displayName = fmObj[appoint.appointment.bookedFor];
    } else {
      displayName = "";
    }
  }
  return displayName;
};

export const createDateAndChangeScreen = (minDayBooking, maxDayBooking) => {
  let minDays = minDayBooking || 0;
  let maxDays = maxDayBooking || 0;
  let dateArr = [];

  for (let i = 0; i < maxDays; i++) {
    let d = moment(new Date(new Date().setHours(12))).add(minDays + i, "days");
    let dateObj = {
      day: d.date(),
      month: moment(d).format("MMM"),
      exactDate: d,
    };
    dateArr.push(dateObj);
  }
  if (dateArr.length === 0) {
    let d = moment(new Date(new Date().setHours(12)));
    let dateObj = {
      day: d.date(),
      month: moment(d).format("MMM"),
      exactDate: d,
    };
    dateArr.push(dateObj);
  }
  return dateArr;
};

export const errorMessageHandler = (
  err,
  defaultMsg = "Something went wrong."
) => {
  let errMsg = defaultMsg;
  try {
    let msgArr = JSON.parse(err.message);

    if (msgArr.message) {
      errMsg = msgArr.message;
    }
  } catch (error) {
    if (err?.message) {
      errMsg = err.message;
    }
  }
  return errMsg;
};

export const getUrlWithToken = (url, token) => {
  if (url && url.indexOf("?") !== -1) {
    url = `${url}&token=${token}`;
  } else {
    url = `${url}?token=${token}`;
  }

  return url;
};
