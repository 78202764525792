import React, { useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import $ from "jquery";
import whiteBackButton from "../../../assets/icons/arrow-back.png";
import searchIcon from "../../../assets/icons/search-black-icon.png";

import foodPlate2 from "../../../assets/images/no-food-hp-2.png";
import closeBlackIcon from "../../../assets/icons/ic_send_primary_copy.png";

import userService from "../../../services/UserService";

import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import FoodListItem from "./foodListItem";
import NoSearchResult from "./noSearchResult";

let paramGroups = [
  { name: "All", id: 1 },
  { name: "Recommended", id: 2 },
  { name: "Allowed", id: 3 },
  { name: "Limited", id: 4 },
  { name: "Avoid", id: 5 },
];

function FoodList({ handleDrawerClick }) {
  const [searchStr, setSearchStr] = useState("");
  const [selectedTag, setSelectedTag] = useState(1);
  const [allFood, setAllFood] = useState([]);
  const [recomFood, setRecomFood] = useState([]);
  const [allowedFood, setAllowedFood] = useState([]);
  const [notAllowedFood, setNotAllowedFood] = useState([]);
  const [limitedFood, setLimitedFood] = useState([]);
  const [currenList, setCurrentList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [noFoodList, setNoFoodList] = useState(false);
  const patientProfile = useSelector(
    (store) => store.login.user?.patientProfile
  );
  const isSearching = useRef(false);
  const myRef = useRef();
  useEffect(() => {
    userService
      .getFoodList(patientProfile?.id, "all")
      .then((result) => {
        const res = result.data;
        if (res) {
          const {
            recommendedFoodList,
            allowedFoodList,
            notAllowedFoodList,
            limitedFoodList,
          } = res;
          let allFoodList = recommendedFoodList.concat(
            allowedFoodList,
            notAllowedFoodList,
            limitedFoodList
          );
          allFoodList.sort((a, b) =>
            a.foodItemType.localeCompare(b.foodItemType)
          );
          recommendedFoodList.sort((a, b) =>
            a.foodItemType.localeCompare(b.foodItemType)
          );
          allowedFoodList.sort((a, b) =>
            a.foodItemType.localeCompare(b.foodItemType)
          );
          notAllowedFoodList.sort((a, b) =>
            a.foodItemType.localeCompare(b.foodItemType)
          );
          limitedFoodList.sort((a, b) =>
            a.foodItemType.localeCompare(b.foodItemType)
          );
          setCurrentList([...allFoodList]);
          setAllFood([...allFoodList]);
          setRecomFood([...recommendedFoodList]);
          setAllowedFood([...allowedFoodList]);
          setNotAllowedFood([...notAllowedFoodList]);
          setLimitedFood([...limitedFoodList]);
        }
      })
      .catch((err) => {
        setNoFoodList(true);
        setCurrentList([]);
      });
  }, []);

  const toggleHeaderOnSearch = () => {
    $(".healthplan-foodlist-header-1-span-2").hide();
    $(".healthplan-foodlist-header-1-food-img").hide();
    $(".healthplan-foodlist-container-header-1").css("position", "sticky");

    $(".healthplan-foodlist-container-header-1-top").addClass(
      "healthplan-foodlist-container-header-1-top-2"
    );
  };
  const handleHeaderFunc = () => {
    let totalScroll = $(".healthplan-foodlist-container").scrollTop();
    let headerHeight = $(".healthplan-foodlist-container-header-1").height();
    let exactheight = headerHeight;
    if (!isSearching.current) {
      if (totalScroll > exactheight) {
        $(".healthplan-foodlist-header-1-span-2").hide();
        $(".healthplan-foodlist-header-1-food-img").hide();
        $(".healthplan-foodlist-container-header-1").css("position", "sticky");

        $(".healthplan-foodlist-container-header-1-top").addClass(
          "healthplan-foodlist-container-header-1-top-2"
        );
      } else {
        $(".healthplan-foodlist-container-header-1").css(
          "position",
          "relative"
        );
        $(".healthplan-foodlist-header-1-span-2").css("display", "initial");
        $(".healthplan-foodlist-header-1-food-img").css("display", "initial");
        $(".healthplan-foodlist-container-header-1-top").removeClass(
          "healthplan-foodlist-container-header-1-top-2"
        );
      }
    }
  };
  const updateSearchList = (searchStr) => {
    let updatetSearchStr = allFood.filter(
      (item) =>
        item.foodItemName
          .toLocaleLowerCase()
          .indexOf(searchStr.toLocaleLowerCase()) !== -1
    );
    setSearchList([...updatetSearchStr]);
  };

  const onSearchInput = (e) => {
    let stateObj = { searchStr: e.target.value };
    e.target.value.length > 0 && (stateObj.selectedTag = 1);
    setSearchStr(e.target.value);
    if (e.target.value.length > 0) {
      toggleHeaderOnSearch();
      isSearching.current = true;
      updateSearchList(e.target.value.trim());
    } else {
      isSearching.current = false;
    }
  };

  const handleTagChange = (list, tag) => {
    setCurrentList(list);
    setSelectedTag(tag);
  };

  return (
    <div
      className="healthplan-foodlist-container"
      onScroll={(e) => {
        handleHeaderFunc();
      }}
    >
      <div className="healthplan-foodlist-container-header-1">
        <div className="healthplan-foodlist-container-header-1-top">
          <img
            src={whiteBackButton}
            alt="icon"
            className="healthplan-foodlist-header-back-img"
            onClick={() => handleDrawerClick()}
          />
          <span className="healthplan-foodlist-header-1-span-1">Food list</span>
        </div>

        <span className="healthplan-foodlist-header-1-span-2">
          See what food items you should take or avoid.
        </span>
        <div className="healthplan-foodlist-header-search-bar">
          <img
            src={searchIcon}
            alt="icon"
            className="healthplan-foodlist-header-search-bar-icon-1"
          />
          <input
            value={searchStr}
            placeholder="Search for a food item"
            onChange={(e) => {
              onSearchInput(e);
            }}
          />
          {searchStr && (
            <img
              src={closeBlackIcon}
              className="healthplan-foodlist-header-search-bar-icon-2"
              alt="icon"
              onClick={() => {
                setSearchStr("");
                isSearching.current = false;
              }}
            />
          )}
        </div>
        <div className="healthplan-foodlist-header-buttons">
          <IonSegment
            style={{ marginTop: "10px" }}
            mode="md"
            scrollable
            value={selectedTag}
            ref={myRef}
            onIonChange={(e) => {
              if (e && e.detail && e.detail.value) {
                switch (e.detail.value) {
                  case "1":
                    handleTagChange([...allFood], 1);
                    break;
                  case "2":
                    handleTagChange([...recomFood], 2);
                    break;
                  case "3":
                    handleTagChange([...allowedFood], 3);
                    break;
                  case "4":
                    handleTagChange([...limitedFood], 4);
                    break;
                  default:
                    handleTagChange([...notAllowedFood], 5);
                }
              }
            }}
            className="foodlist-header-buttons-box"
          >
            {paramGroups &&
              paramGroups.map((k, i) => {
                return (
                  <div
                    id={`${k.id}`}
                    key={`${k.id}`}
                    className="foodlist-ion-segment"
                  >
                    <IonSegmentButton
                      key={`${k.id}`}
                      className="foodlist-ion-segment-tab"
                      value={`${k.id}`}
                    >
                      <IonLabel className="foodlist-ion-segment-label">
                        {k.name}
                      </IonLabel>
                    </IonSegmentButton>
                  </div>
                );
              })}
          </IonSegment>
        </div>
        <div className="healthplan-foodlist-header-1-food-img">
          <img src={foodPlate2} />
        </div>
      </div>

      {searchStr.length === 0 ? (
        <div className="foodlist-main-content">
          {currenList &&
            currenList.map((item) => {
              return <FoodListItem item={item} />;
            })}
          {currenList && currenList.length === 0 && (
            <div className="foodlist-main-content-spinner-class">
              {noFoodList ? (
                <p>No food list item</p>
              ) : (
                <CircularProgress></CircularProgress>
              )}
            </div>
          )}
        </div>
      ) : (
        <>
          {searchList.length > 0 ? (
            <div className="foodlist-main-content">
              {searchList.map((item) => {
                return <FoodListItem item={item} />;
              })}
            </div>
          ) : (
            <NoSearchResult />
          )}
        </>
      )}
    </div>
  );
}

export default FoodList;
