import React, { useRef } from "react";
import SingleSelectQuestion from "./singleSelectQuestion";
import MultiSelectQuestion from "./multiSelectQuestion";
import TypeTwoQuestion from "./typeTwoQuestion";
import paConstants from "./paConstants";
import { useDispatch } from "react-redux";
import {
  fetchNextQuestionApi,
  fetchPreviousQuestionApi,
  submitAnswerApi,
} from "./assessmentSlice";

let paAnswerTag = null;

export default function PAQuestion({
  currentQuestion,
  loading,
  handleInvalidValue,
  questionMetadata,
  patientId,
  height,
  weight,
  markComplete,
}) {
  const dispatch = useDispatch();
  const isCompleted = useRef(false);
  const handleNextClick = () => {
    let no_error = true;

    if (
      !currentQuestion.required &&
      (currentQuestion.type === 1 || currentQuestion.type === 3) &&
      (paAnswerTag === "[ ]" || paAnswerTag === "" || paAnswerTag == null)
    ) {
    } else if (
      currentQuestion.type === 1 &&
      (paAnswerTag === "[ ]" || paAnswerTag === "")
    ) {
      // render select at least one option snackbar here
      handleInvalidValue({
        error: "Please select at least one option",
        hasError: true,
      });
      // type 1 return
      no_error = false;
    } else if (currentQuestion.type === 2) {
      let isValid = true;
      let answerArray = String(paAnswerTag).split(":");
      answerArray.forEach((answerField) => {
        if (answerField.trim() === "") {
          isValid = false;
        }
      });
      if (currentQuestion.id === 4) {
        if (
          !paAnswerTag ||
          paAnswerTag.trim() === "" ||
          isNaN(paAnswerTag) ||
          paAnswerTag === "0" ||
          paAnswerTag > 400 ||
          paAnswerTag < 10
        ) {
          handleInvalidValue({
            error: "Please enter valid weight",
            hasError: true,
          });
          no_error = false;
        }
      }
      //date of birth
      if (currentQuestion.id === 3) {
        if (paAnswerTag === "1970-01-01") {
        } else if (!paAnswerTag || paAnswerTag.length >= 10) {
          handleInvalidValue({
            error: "Please select a valid Date Of Birth",
            hasError: true,
          });
          no_error = false;
        }
      }
      //Height
      if (currentQuestion.id === 5) {
        if (paAnswerTag === "0" || paAnswerTag === null) {
          //render height-error snackbar here
          handleInvalidValue({
            error: "Please enter valid height",
            hasError: true,
          });
          no_error = false;
        }
      }
      if (!isValid && currentQuestion.tag !== paConstants.sugarLevel) {
        //since sugar level is optional question
        no_error = false;
      }
    } else if (paAnswerTag === null) {
      // render seelct option snackbar here
      handleInvalidValue({
        hasError: true,
        error: "Please select correct option",
      });
      // handles type 3
      // general/t3 return
      no_error = false;
    } else if (currentQuestion.type === 4) {
      if (paAnswerTag === "0::") {
        paAnswerTag = "0:0:0";
      }

      let answers = paAnswerTag.split(":");
      for (var i = 0; i < answers.length; i++) {
        const sugarAnswer = answers[i];
        if (sugarAnswer.length > 6) {
          handleInvalidValue({
            error: "Value should be max 6 character long",
            hasError: true,
          });
          no_error = false;
        }
        var charCheck = /^\d{1,3}(\.\d{1,3})?$/;
        if (!charCheck.test(paAnswerTag)) {
          handleInvalidValue({
            error: "Enter valid number only",
            hasError: true,
          });
          no_error = false;
        }
      }
    }

    if (no_error) {
      dispatch(
        submitAnswerApi({
          patientId,
          answer: paAnswerTag,
          id: currentQuestion.id,
        })
      ).then((a) => {
        if (questionMetadata.next) {
          dispatch(fetchNextQuestionApi({ patientId, id: currentQuestion.id }));
        } else {
          // mark pa complete
          if (!isCompleted.current) {
            isCompleted.current = true;
            markComplete();
          }
        }
      });
    }
  };

  const _handlePreviousClick = () => {
    if (questionMetadata.previous) {
      dispatch(fetchPreviousQuestionApi({ patientId, id: currentQuestion.id }));
    }
  };

  const updateAnswerTag = (tag) => {
    paAnswerTag = tag;
  };

  return (
    <div className="pa-question-container">
      <div className="pa-questions-body">
        {currentQuestion.type === 3 && (
          <SingleSelectQuestion
            updateAnswerTag={updateAnswerTag}
            currentQuestion={currentQuestion}
          />
        )}
        {currentQuestion.type === 1 && (
          <MultiSelectQuestion
            updateAnswerTag={updateAnswerTag}
            currentQuestion={currentQuestion}
          />
        )}
        {(currentQuestion.type === 2 || currentQuestion.type === 4) && (
          <TypeTwoQuestion
            updateAnswerTag={updateAnswerTag}
            currentQuestion={currentQuestion}
            height={height}
            weight={weight}
          />
        )}

        <div className={loading ? "disabled-item" : ""}>
          <div
            onClick={handleNextClick}
            className={
              !questionMetadata
                ? "disabled-item next-btn fw-semibold"
                : "next-btn fw-semibold"
            }
          >
            {loading ? "PROCESSING..." : "NEXT"}
          </div>
        </div>
      </div>
      <br />
      <div className={loading ? "disabled-item" : ""}>
        {questionMetadata && questionMetadata.previous && (
          <div
            onClick={_handlePreviousClick}
            className={
              !questionMetadata
                ? "disabled-item fw-semibold prev-btn"
                : "fw-semibold prev-btn"
            }
          >
            PREVIOUS
          </div>
        )}
      </div>
    </div>
  );
}
