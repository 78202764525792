import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import HpImage from "../../../../assets/images/hp.png";
import { sendMessage } from "../../chatSlice";

import TimeMessage from "./time";
import { debounce } from "lodash";

export default function HpCardMessage({ message, lastMessage, chat, history }) {
  const dispatch = useDispatch();
  const [hpText, setHpText] = useState({
    one: "",
    two: "Level 1",
    three: "Health Plan",
    four: "Here is your upgraded new heath plan designed to achieve better routine and health.",
    five: "Unlock Health Plan",
  });
  const isDisabled = useRef(false);

  useEffect(() => {
    const text = message.content.message.text;
    const t = {
      one: "",
      two: "Level 1",
      three: "Health Plan",
      four: "Here is your upgraded new heath plan designed to achieve better routine and health.",
      five: "Unlock Health Plan",
    };
    let contentArray = text.split("#");

    if (contentArray.length === 1) {
      setHpText({ ...t, four: contentArray[0] });
    } else {
      setHpText({
        one: contentArray[0],
        two: contentArray[1],
        three: contentArray[2],
        four: contentArray[3],
        five: contentArray[4],
      });
    }
  }, [message]);
  const onClickHP = () => {
    if (
      lastMessage &&
      lastMessage.content.state &&
      lastMessage.content.state.type === "hp_complete"
    ) {
      const previousState = lastMessage.content.state;
      let message = {
        message: {
          text: "Awesome. I have understood the health plan and I will try to follow it every day.",
          type: 1,
          id: "" + new Date().getTime() + Math.random(),
        },
      };
      if (previousState && previousState.type) {
        message.state = previousState;
      }

      const req = {
        roomId: chat.chatRoom,
        userId: chat.externalUserIdSt,
        content: message,
      };
      dispatch(sendMessage(req));
      history.push("/health-plan");
    } else {
      history.push("/health-plan");
    }
  };
  const onClickHPDebounce = () => {
    if (!isDisabled.current) {
      isDisabled.current = true;
      onClickHP();
    }
  };

  const onClickHPCard = () => {
    history.push("/health-plan");
  };

  return (
    <>
      <div className="hp-card">
        <div>
          <div className="hp-head-card 	">
            <div className="hp-detail">
              {hpText.one && (
                <div className="new-card fw-medium">{hpText.one}</div>
              )}
              <div className="pl level fw-semibold fs20">{hpText.two}</div>
              <div className="pl fs14">{hpText.three}</div>
            </div>
            <img className="hp-card-img" alt="hpcard" src={HpImage} />
          </div>
          <div className="body-hp-card ">{hpText.four}</div>
          <div onClick={onClickHPCard} className="start-button">
            {hpText.five}
          </div>
        </div>
      </div>
      <TimeMessage message={message} />
    </>
  );
}
