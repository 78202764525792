import React, { useLayoutEffect, useRef, useState } from "react";
import "./home-page.scss";
import { IonButton, IonContent, IonModal } from "@ionic/react";
import PlayIcon from "../../assets/icons/play_icon.png";
import BackBtnImg from "../../assets/icons/arrow-back-black.svg";
import { useDispatch } from "react-redux";
import { logEvent } from "../../app/eventSlice";

const ZylaTV = ({ videos }) => {
  const dispatch = useDispatch();
  const divRef = useRef();
  const [divWidth, setDivWidth] = useState();
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  useLayoutEffect(() => {
    setDivWidth(divRef.current.clientWidth);
  }, []);
  return (
    <div className="zylatv-wrapper">
      <h1>Learn from experts</h1>
      <div ref={divRef} className="zylatv-videos-container">
        {videos?.map((video) => {
          let url = `url(" ${video.thumbnail} ")`;
          let imgStyle = {
            borderRadius: "10px",
            border: `1px solid grey`,
            marginTop: "5px",
            marginBottom: "5px",
            backgroundImage: url,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            width: `${divWidth}px`,
            height: `${(divWidth * 9) / 16}px`,
          };
          return (
            <div style={imgStyle}>
              <div className="zylatv-video-container">
                <img
                  src={PlayIcon}
                  height={"60px"}
                  onClick={() => {
                    setSelectedVideo(video);
                    setShowVideoModal((prev) => {
                      return true;
                    });
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
      {showVideoModal && (
        <IonModal
          isOpen={showVideoModal}
          cssClass="videozyla-modal"
          onDidDismiss={() => {
            setShowVideoModal(false);
          }}
        >
          <IonContent>
            <div
              className="zylatv-playing-video-backbtn"
              onClick={() => {
                setShowVideoModal(false);
              }}
            >
              <img src={BackBtnImg} alt="close" height={"20px"} />
            </div>

            <div className="zylatv-playing-video-title">
              {selectedVideo?.title}
            </div>
            <div
              key={selectedVideo.id}
              className="zylatv-playing-video"
              onClick={() => {
                dispatch(logEvent({ action: "click_play_video" }));

                window.open(
                  `https://www.youtube.com/watch/${selectedVideo.videoId}`,
                  "_blank"
                );
              }}
            >
              <iframe
                className="thumbnail"
                src={`https://www.youtube.com/embed/${selectedVideo.link}?autoplay=1`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                width={divWidth}
                height={(divWidth * 9) / 16}
              ></iframe>
            </div>
          </IonContent>
        </IonModal>
      )}
      <IonButton
        expand="block"
        color="#fff"
        className="all-videos"
        onClick={() => {
          dispatch(logEvent({ action: "click_view_all_videos_Home" }));
          window.open(
            "https://www.youtube.com/playlist?list=PLEkiivFalO4IR9AjhaN46fyh1oZG8foYx",
            "_blank"
          );
        }}
      >
        View All Videos
      </IonButton>
    </div>
  );
};

export default ZylaTV;
