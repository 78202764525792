import React, { useEffect, useRef, useState } from "react";

import PDFicon from "../../../../assets/icons/pdf_icon.png";

import TimeMessage from "./time";
import { useDispatch, useSelector } from "react-redux";
import { getDocumentById } from "../../../documents/documentSlice";
import DocumentPreview from "../../../documents/components/documentPreview";
import { useHistory } from "react-router";

const getInitialDocumentId = (message) => {
  const content = message.content.message;
  if (content && ((content.metadata && content.metadata.id) || content.text)) {
    return (content.metadata && content.metadata.id) || content.text;
  }
  return "";
};

export default function DocumentCard({ message, uiConfig }) {
  const [document, setDocument] = useState(null);
  const documentCached = useSelector((state) => state.documents.documentCached);
  const token = useSelector((state) => state.tab.docToken);
  const documentId = useRef(getInitialDocumentId(message));
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const content = message.content.message;

    if (
      content &&
      ((content.metadata && content.metadata.id) || content.text)
    ) {
      if (!documentCached[documentId.current]) {
        dispatch(getDocumentById(documentId.current));
      } else {
        setDocument(documentCached[documentId.current]);
      }
    }
  }, [documentCached[documentId.current]]);

  const getUrlWithToken = (url, token) => {
    if (url && url.indexOf("?") !== -1) {
      url = `${url}&token=${token}`;
    } else {
      url = `${url}?token=${token}`;
    }
    return url;
  };
  const medicineOrder = () => {
    // this.storageService.set("document", document);
    // this.storageService.set("token", token.token);
    history.push({
      pathname: "/medicine",
    });
  };

  if (!document) {
    return <></>;
  }
  const url = getUrlWithToken(document.url, token.token);
  return (
    <>
      {(document.type !== 4 ||
        (document.type === 4 && document.format !== 4)) && (
        <div className={" document-card "}>
          <div className="document-card-holder ">
            <DocumentPreview
              document={document}
              token={token.token}
              isWeb={true}
              mine={message.mine}
            ></DocumentPreview>
          </div>

          <TimeMessage message={message} />
        </div>
      )}
      {document.type === 4 && document.format === 4 && (
        <div className={" medicine-order-card "}>
          <div className="medicine-order-card-holder ">
            <div id="order-container" style={{ width: "100%" }}>
              <DocumentPreview
                document={document}
                token={token.token}
                mine={message.mine}
              ></DocumentPreview>
              {uiConfig.ancillaryMed && (
                <div>
                  <div id="document-title">
                    <img alt="" src={PDFicon} />
                    <a href={url}>
                      {document.title ? document.title : "prescription.pdf"}
                    </a>
                  </div>
                  {!message.mine && (
                    <div id="disc-text">Save 15% on prescription medicines</div>
                  )}
                  {!message.mine && (
                    <button onClick={() => medicineOrder()}>Order Now</button>
                  )}
                </div>
              )}
            </div>
          </div>

          <TimeMessage message={message} />
        </div>
      )}
    </>
  );
}
