import { IonHeader } from "@ionic/react";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { logEvent } from "../../../app/eventSlice";
import upArrow from "../../../assets/images/upArrow_img.png";
import RequestCallback from "../../../assets/icons/request-callback.png";
import CareTeamComponent from "./careTeam";
import styled from "@emotion/styled";

const PremiumTag = styled.div`
  background: linear-gradient(
    94.33deg,
    #a6743b 19.66%,
    #cfa44f 66.8%,
    #e4c780 108.38%
  );
  font-size: 8px;
  color: white;
  padding: 2.5px 4px;
  border-radius: 4px;
  margin-left: 2px;
  margin-top: 2px;
`;
export default function ChatHeader({ doctors, uiConfig }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showCareTeam, setShowCareTeam] = useState(false);
  const { isZylaZen, isPremium } = uiConfig;
  return (
    <>
      {/* <IonHeader className="chatroom__ion__header">
        <div
          className="header-div"
          onClick={() => {
            setShowCareTeam(!showCareTeam);
          }}
        >
          <div id="zyla-text" className="text-black mb-1">
            Max Life Insurance
          </div>
          {isPremium && (
            <PremiumTag>{isZylaZen ? "zyla ZEN" : "PREMIUM"}</PremiumTag>
          )}

          <img
            src={upArrow}
            alt=""
            className={`w-8 icon-z-index ${!showCareTeam ? "rotate-icon" : ""}`}
          />
        </div>

        <div
          className="request-callback-div"
          onClick={() => {
            dispatch(logEvent({ action: "Click_call_button" }));
            history.push("/premium");
          }}
        >
          <img src={RequestCallback} alt="" />
          <span>
            Request <br /> Callback
          </span>
        </div>
      </IonHeader> */}

      <CareTeamComponent
        doctors={doctors}
        showCareTeam={showCareTeam}
        uiConfig={uiConfig}
      ></CareTeamComponent>
    </>
  );
}
