const UPDATE_CHAT = "UPDATE_CHAT";
const SETUP_CHAT = "SETUP_CHAT";
const CLEAR_SETUP_CHAT = "CLEAR_SETUP_CHAT";
const SEND_MESSAGE = "SEND_MESSAGE";
const TOGGLE_MESSAGE_ABILITY = "TOGGLE_MESSAGE_ABILITY";
const TOGGLE_ONLINE_STATUS = "TOGGLE_ONLINE_STATUS";
const UPDATE_PENDING_CHAT = "UPDATE_PENDING_CHAT";
const UPDATE_MESSAGE_STATUS = "UPDATE_MESSAGE_STATUS";
const UPDATE_UI_CONFIG = "UPDATE_UI_CONFIG";
const DEVICE_READING_PUSH = "DEVICE_READING_PUSH";
const DEVICE_READING_CLEAR = "DEVICE_READING_CLEAR";
const DEVICE_SET_CURRENT = "DEVICE_SET_CURRENT";
export default {
  UPDATE_CHAT,
  SETUP_CHAT,
  CLEAR_SETUP_CHAT,
  SEND_MESSAGE,
  TOGGLE_MESSAGE_ABILITY,
  TOGGLE_ONLINE_STATUS,
  UPDATE_PENDING_CHAT,
  UPDATE_MESSAGE_STATUS,
  UPDATE_UI_CONFIG,

  // Device
  DEVICE_READING_PUSH,
  DEVICE_READING_CLEAR,
  DEVICE_SET_CURRENT,
};
