// import "capacitor-razorpay";
import ApiService from "./ApiService";
import AuthenticationService from "./AuthenticationService";
import zylaLogo from "../assets/images/zyla-logo.png";
import APIConfig from "./APIConfig";
import RazorPayHelper from "../utils/RazorPayHelper";


export default class PaymentService {
  constructor() {
    this.apiService = new ApiService();
    this.authenticationService = new AuthenticationService();
    this.razorPayHelper = new RazorPayHelper();
    const user = this.authenticationService.getUserProfile();
    this.patientId = user && user.patientProfile ? user.patientProfile.id : 0;
  }

  getPaymentDetails(onSuccess, onError) {
    let url = APIConfig.payment.initiate.format(this.patientId);
    let payload = {};
    return this.apiService.post(url, payload, onSuccess, onError);
  }

  initiatePayment(orderId, onSuccess, onError) {
    let url = APIConfig.payment.status.format(orderId);
    let payload = {};
    return this.apiService.post(url, payload, onSuccess, onError);
  }

  paymentStarted = async (handleCompletion, res, patient) => {
    if (window.Capacitor.platform === "web" && window.rzploaded !== true) {
      alert("something went wrong");
      return;
    }

    const options = {
      key: res.rzpKeyId, // Enter the Key ID generated from the Dashboard
      amount: parseFloat(res.amount),
      currency: res.currency,
      name: "Zyla Health",
      description: "Digit customer payment Transaction",
      image: { zylaLogo },
      order_id: res.id,
      handler: (response) => {
        handleCompletion(true);
      },
      modal: {
        animation: false,
      },
      prefill: {
        name: patient.patientProfile.firstname,
        email: patient.patientProfile.email,
        contact: "" + patient.patientProfile.countryCode + patient.phoneNo,
      },
      notes: {
        address:
          "LGF, Saket Salcon, Rasvilas, Saket District Centre, Sec 6, Pushp Vihar, New Delhi- 110017",
      },
      theme: {
        color: "#6E5ABB",
      },
    };
    const options2 = {
      key: res.rzpKeyId, // Enter the Key ID generated from the Dashboard
      amount: parseFloat(res.amount),
      currency: res.currency,
      name: "Zyla Health",
      description: "Digit customer payment Transaction",
      image: { zylaLogo },
      order_id: res.id,

      prefill: {
        name: patient.patientProfile.firstname,
        email: patient.patientProfile.email,
        contact: "" + patient.patientProfile.countryCode + patient.phoneNo + "",
      },
      notes: {
        address:
          "LGF, Saket Salcon, Rasvilas, Saket District Centre, Sec 6, Pushp Vihar, New Delhi- 110017",
      },
      theme: {
        color: "#6E5ABB",
      },
    };

    if (window.Capacitor.platform === "web") {
      const paymentObject = new window.Razorpay(options);
      paymentObject.on("payment.failed", (response) => {
        handleCompletion(false);
      });
      paymentObject.open();
    } else {
      try {
        // let data = await Checkout.open(options2);

        handleCompletion(true);
      } catch (error) {
        if (error.message) {
          let str = error.message.substring(0, error.message.indexOf(":"));
          if (str !== "2") {
            handleCompletion(false);
          }
        }
      }
    }
  };

  getOrderId = (patientId, param, onSuccess, onError) => {
    let url = APIConfig.razorPay.orders.format(patientId);
    this.apiService.post(
      url,
      param,
      (res) => {
        onSuccess(res);
      },
      (err) => {
        onError(err);
      }
    );
  };
}
