import React, { useCallback, useState } from "react";
import { IonSlides, IonSlide, IonContent, IonPage } from "@ionic/react";
import $ from "jquery";
import infoIcon from "../../../assets/icons/close-i-icon.png";
import closeIcon from "../../../assets/images/ic_close_white.png";
import dummyRecipe from "../../../assets/images/dummy-recipe-image.png";
import blackBack from "../../../assets/icons/black-back-arrow.png";
import dompurify from "dompurify";

let swiper = null;
const slideOptsGuideline = {
  initialSlide: 0,
  speed: 400,
  on: {
    beforeInit() {
      swiper = this;
    },
  },
};
function Guidelines({ handleDrawerClick, guidelines }) {
  const [openguideliensheet, setOpenguidelineSheet] = useState(false);
  const [openGuidelinesSlide, setOpenGuidelinesSlide] = useState(false);

  const closeGuidelines = () => {
    $(".open-guidelines-slide-container").css("left", "100%");
    setTimeout(() => {
      setOpenGuidelinesSlide(false);
    }, 300);
  };

  const getExactNumbers = useCallback((num) => {
    let str = num.split(".");

    if (str && str.length > 1) {
      let top = str[0];
      if (str[0] == "0") {
        top = "";
      }
      if (str[1] == "5" || str[1] == "50") {
        return (
          <span>
            {top}
            <sup>1</sup>&frasl;<sub>2</sub>
          </span>
        );
      } else if (str[1] == "25") {
        return (
          <span>
            {top}
            <sup>1</sup>&frasl;<sub>4</sub>
          </span>
        );
      } else if (str[1] == "75") {
        return (
          <span>
            {top}
            <sup>3</sup>&frasl;<sub>4</sub>
          </span>
        );
      } else {
        return <span>{str[0]}</span>;
      }
    } else if (str && str.length == 1) {
      return <span>{str[0]}</span>;
    }
  }, []);

  const getRecommendedMsg = useCallback((guideline) => {
    if (guideline.minRange && guideline.maxRange) {
      return (
        <span>
          {getExactNumbers(guideline.minRange)}&nbsp;-&nbsp;
          {getExactNumbers(guideline.maxRange)} {guideline?.unit}
        </span>
      );
    } else if (guideline.minRange) {
      return (
        <span>
          {getExactNumbers(guideline.minRange)} {guideline?.unit}
        </span>
      );
    } else if (guideline.maxRange) {
      return (
        <span>
          upto {getExactNumbers(guideline.maxRange)} {guideline?.unit}
        </span>
      );
    } else {
      return "";
    }
  }, []);

  return (
    <div className="healthplan-guideline-container">
      <div className="healthplan-guideline-header">
        <img
          src={blackBack}
          alt="icon"
          className="healthplan-guideline-back-button"
          onClick={() => {
            handleDrawerClick();
          }}
        />
        <div className="healthplan-guideline-header-right">
          <span>Guidelines</span>
          <div>
            <img
              src={infoIcon}
              alt="icon"
              onClick={() => {
                setOpenguidelineSheet(true);
              }}
            />
          </div>
        </div>
      </div>
      <div className="healthplan-guideline-main-content">
        {guidelines?.map((myguideline, index) => {
          return (
            <div
              className="healthplan-guideline-main-content-element"
              onClick={() => {
                slideOptsGuideline.initialSlide = index;
                setOpenGuidelinesSlide(true);
              }}
            >
              <span>{myguideline.name}</span>
              <img
                src={myguideline.image ? myguideline.image : dummyRecipe}
                alt="bg-img"
              />
            </div>
          );
        })}
        {!guidelines && (
          <div className="no-guidelines-available-section">
            <p>No guidelines available</p>
          </div>
        )}
      </div>
      {openguideliensheet && (
        <div className="guideline-swipe-sheet">
          <div className="guideline-sheet-bottom">
            <p className="guideline-sheet-bottom-heading">Guidelines</p>
            <div className="guideline-sheet-bottom-desc">
              Guidelines are customized & help you with common food related
              things you should take care of along with healthplan.
            </div>
            <div className="guideline-sheet-bottom-button">
              <button
                onClick={() => {
                  setOpenguidelineSheet(false);
                }}
              >
                Ok, got it.
              </button>
            </div>
          </div>
        </div>
      )}

      {openGuidelinesSlide && (
        <div className="open-guidelines-slide-container">
          <IonSlides
            className="guidelines_drawer_ion_slides"
            onIonSlideDidChange={(e) => {}}
            pager={true}
            options={slideOptsGuideline}
          >
            {guidelines.map((guideline) => {
              return (
                <IonSlide>
                  <div className="guidelines_drawer_container">
                    <div className="guidelines_drawer_header">
                      <img
                        src={
                          guideline["image"] ? guideline["image"] : dummyRecipe
                        }
                        className="guidelines_drawer_header_img"
                        alt="bg-img"
                      />
                      <img
                        src={closeIcon}
                        alt="icon"
                        className="guidelines-close-icon"
                        onClick={() => closeGuidelines()}
                      />

                      <div className="common-slider-bottom-gradient"></div>
                    </div>

                    <div className="guidelines_drawer_main">
                      <div className="guidelines_name_div">
                        <div className="guidelines_name_div_top">
                          <div>{guideline.name}</div>
                        </div>
                        <div className="guidelines_name_div_bottom"></div>
                      </div>
                      <div className="guidelines_drawer_main_ingredients">
                        {(guideline.minRange || guideline.maxRange) && (
                          <div className="guidelines_drawer_main_ingredients_header">
                            <div className="guidelines_drawer_main_ingredients_header_top">
                              <b>Recommended range:</b>
                              <span>{getRecommendedMsg(guideline)} </span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="guidelines_drawer_main_ingredients">
                        <div className="fw-semibold">Instructions:</div>
                        <div
                          className="guidelines_drawer_list "
                          dangerouslySetInnerHTML={{
                            __html: dompurify.sanitize(guideline.description),
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </IonSlide>
              );
            })}
          </IonSlides>
        </div>
      )}
    </div>
  );
}

export default Guidelines;
