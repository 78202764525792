import React from "react";
import "./index.scss";
import BackButton from "../../components/common/BackButton";
import { IonContent, IonPage } from "@ionic/react";
import DOMPurify from "dompurify";
import backImage from "../../assets/icons/arrow-back-black.svg";
import { Link } from "react-router-dom";
import {useEffect} from "react"

import temp from "../../utils/termsAndConditionData";

const imgStyle = {
  width: "24px",
  height: "24px",
};

const Privacy = () => {

  useEffect(()=> {
    const content = document.getElementById("privacy-content")
    const paraTags = content.querySelectorAll("p")
    paraTags.forEach((tag) => {
      tag.classList.add("text-xs")
    })
  },[])

  return (
    <IonPage>
      <IonContent>
        <div className="terms-privacy-page-container">
          <div className=" flex items-center pr-5 pt-4 space-x-2">
            <Link to="/tab/hamburger">
              {" "}
              <img
                src={backImage}
                alt="backButton"
                className="cursor-pointer"
                style={imgStyle}
              />
            </Link>
            <span
              className="font-bold text-base "
              style={{ lineHeight: "17.39px", color: "#171A21" }}
            >
              Privacy policy
            </span>
          </div>
          <div id="privacy-content" className="text-[#171a21]"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(temp),
              }}
            ></div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Privacy;
