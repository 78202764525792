import ApiService from "./ApiService";
import APIConfig from "./APIConfig";
import axiosClient from "../app/axiosConfig";
class WalletService {
  getOrCreateWallet = (onResponse, onError) => {
    let url = APIConfig.wallet.getWallet;
    return axiosClient.get(url);
  };
  getWalletTransactions = (walletId, trSize = 1, range) => {
    let url = APIConfig.wallet.walletTransactions.format(
      walletId,
      trSize,
      range === "" ? "all" : range
    );
    return axiosClient.get(url);
  };

  getWalletFAQs = (onResponse, onError) => {
    let url = APIConfig.wallet.walletFAQs;
    return axiosClient.get(url);
  };

  //get order status
  getOrderStatus = (orderId) => {
    let url = APIConfig.wallet.orderDetails.format(orderId);
    return axiosClient.get(url);
  };

  //get order id
  getOrderId = (params) => {
    let url = APIConfig.wallet.createOrder;
    return axiosClient.post(url, params);
  };
}

export default new WalletService();
