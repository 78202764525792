import React, { useCallback, useEffect, useRef, useState } from "react";
import ChatService from "../../../../services/ChatService";
import testImage from "../../../../assets/images/zyla-logo.png";
import * as moment from "moment";
import TimeMessage from "./time";
import { useDispatch, useSelector } from "react-redux";
import { updateCardInfo } from "../../chatSlice";
const DoctorNotes = ({ message }) => {
  const [notes, setNotes] = useState({});
  const notesContent = useRef();
  const callNotes = useSelector((store) => store.chat.callNotes);
  const dispatch = useDispatch();
  useEffect(() => {
    if (callNotes[message.metadata.id]) {
      setNotesToView(callNotes[message.metadata.id]);
    } else {
      getCallNote();
    }
  }, [callNotes[message.metadata.id]]);

  const getCallNote = () => {
    const chatService = new ChatService();
    chatService
      .getDoctorCallNotes(message.metadata.id)
      .then((res) => {
        dispatch(updateCardInfo({ cardName: "callNotes", data: res.data }));
      })
      .catch((err) => {});
  };

  const setNotesToView = (data) => {
    setNotes(data);

    if (notesContent && notesContent.current) {
      notesContent.current.innerHTML = data.content;
    }
  };
  const onErrorLoadingImage = useCallback((e) => {
    e.target.src = testImage;
  }, []);

  return (
    <React.Fragment>
      {notes ? (
        <div>
          <div className="doctor-notes-content">
            <div className="doctor-information" id="doctor-nodes">
              <div className="doc-img doc-width-fix" id="doctor-notes-img">
                <img
                  className="image-doc"
                  onError={(e) => onErrorLoadingImage(e)}
                  src={
                    notes && notes.doctor && notes.doctor.profile_image
                      ? notes.doctor.profile_image
                      : testImage
                  }
                />
              </div>
              <div className="doc-info doc-info-flex-class">
                <div className="call-notes-text">Review Notes</div>

                <div className="doc-desc">
                  {notes &&
                    notes.doctor &&
                    "On the basis of your review by " +
                      notes.doctor.name +
                      " at " +
                      moment(notes.startTime).format("LT") +
                      " on " +
                      moment(notes.startTime).format("dddd,D MMMM,YYYY.")}
                </div>
              </div>
            </div>
            <div ref={notesContent} className="notes-content "></div>
          </div>
          <TimeMessage message={message} />
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default DoctorNotes;
