import { CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { Redirect, useHistory, withRouter } from "react-router";
import AuthenticationService from "../../services/AuthenticationService";
import APIConfig from "../../services/APIConfig";
import ErrorComponent from "../../components/common/ErrorComponent";
import ApiService from "../../services/ApiService";
import { IonContent, IonPage } from "@ionic/react";
import DocumentService from "../../services/DocumentService";
import StorageService from "../../services/StorageService";
import { refreshAndSaveToken } from "../../utils/helperMethods";
import "./SessionCheck.scss";

class SessionCheck extends React.Component {
  constructor(props) {
    super(props);
    this.authenticationService = new AuthenticationService();
    this.apiService = new ApiService();
    this.documentService = new DocumentService();
    this.storageService = new StorageService();
    this.state = {
      timer: 10,
      isError: false,
      isErrorPositive: false,
      errMsg: "",
    };
    this.reconnectInterval = null;
  }

  componentDidMount() {
    this.reconnectInterval = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.timer === 0) {
          return { timer: 10, isError: true };
        }
        return { timer: prevState.timer - 1 };
      });
    }, 1000);

    refreshAndSaveToken(
      () => {
        this.storageService.set("scroll-chat", true);
        this.props.history.replace("/tab/chat");
      },
      () => {
        this.logUserOut();
      }
    );
  }
  componentWillUnmount() {
    if (this.reconnectInterval) {
      clearInterval(this.reconnectInterval);
    }
  }

  logUserOut = () => {
    let cc = "";
    let user = this.authenticationService.getUserProfile();
    if (user && user.patientProfile && user.patientProfile.clientCode) {
      cc = user.patientProfile.clientCode;
    }
    if (cc === "DI" || cc === "MG" || cc === "PY") {
      this.props.history.replace("/session-expired");
    } else {
      try {
        this.authenticationService.logout();

        window.location.href = "/login";
      } catch (err) {
        window.location.href = "/login";
      }
    }
  };
  render() {
    const { errMsg, isErrorPositive, isError } = this.state;
    return (
      <IonPage>
        <IonContent>
          <ErrorComponent
            displayTimer={5000}
            msg={
              errMsg
                ? errMsg
                : "Please check your internet connection and try again!"
            }
            isPositive={isErrorPositive}
            show={isError}
            errorClose={() => {
              this.setState({
                isError: false,
                errMsg: "",
                isErrorPositive: false,
              });
            }}
          />
          <div className="session-check-container">
            <p className="session-check-heading">Session expired!</p>
            <CircularProgress className="ion-router-circular-progress"></CircularProgress>
            <p className="session-check-footer">
              Your session has expired.
              <br /> Reconnecting in&nbsp;
              <strong>{this.state.timer}</strong>
            </p>
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

export default withRouter(SessionCheck);
