import React, { useCallback, useRef } from "react";

import {
  IonContent,
  IonFooter,
  IonModal,
  IonPage,
  IonSpinner,
  useIonViewDidEnter,
} from "@ionic/react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import ChatInputBox from "./components/input";
import ChatHeader from "./components/header";
import ChatMessage from "./components/chatMessage";
import ChatBubble from "./components/chatBubble";
import {
  getPreviousMessages,
  sendMessage,
  setNextFeedbackTime,
  toggleAction,
  toggleDisabledSend,
  toggleEnableAutoScroll,
  toggleTour,
} from "./chatSlice";
import chatUtility from "./utils";
import { fetchAssessmentApi } from "../assesment/assessmentSlice";
import { useHistory } from "react-router";
import Feedback from "./components/freedback";
import ChatPageTour from "./components/tour";
import moment from "moment";
import UploadingMessages from "./components/uploadingMessages";
import { DocumentChooseAndUpload } from "../documents/components/documentChooseAndUpload";
import { Drawer, Snackbar } from "@mui/material";
import { debounce } from "lodash";
import { MessageWrapper } from "./misc";
import { clearChatDot } from "../tab/tabSlice";
import LoadMoreButton from "./components/loadMore";
import scrollIntoView from "scroll-into-view";
import { handleBackButtonFunc } from "../../utils/helperMethods";
import CameraPreviewComponent from "../../components/common/cameraPreview";

const defaultErrorMessage = "Something went wrong please try again later.";

export default function ChatRoomPage() {
  const [showFeedback, setShowFeedback] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");
  const [openCameraPreview, setOpenCameraPreview] = useState(false);
  const device = useSelector((state) => state.app.device);
  const chat = useSelector((state) => state.tab.chat);
  const careTeams = useSelector((state) => state.tab.careTeams);
  const uiConfig = useSelector((state) => state.tab.uiConfig);
  const showRedDotChat = useSelector((state) => state.tab.ui.showRedDotChat);
  const unReadCount = useSelector((state) => state.tab.ui.unReadCount);
  const chatStore = useSelector((state) => state.chat);
  const paCompleted = useSelector((state) => state.assessment.paCompleted);
  const currentQuestion = useSelector(
    (state) => state.assessment.currentQuestion
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const ionContentRef = useRef(null);
  const patientId = chat?.patientId;
  const {
    messages,
    prevMetadata,
    feedback,
    ui,
    pendingMessages,
    isStartTourShown,
  } = chatStore;
  const {
    hasPreviousMessages,
    action,
    scrollToMsg,
    loadMoreLoading,
    enableAutoScroll,
    disableSend,
  } = ui;

  useIonViewDidEnter(() => {
    if (showRedDotChat && window.location.pathname === "/tab/chat") {
      dispatch(clearChatDot());
    }
    if (ionContentRef && ionContentRef.current) {
      if (localStorage.getItem("zy.isWebview")) {
        localStorage.removeItem("zy.isWebview");
      } else {
        ionContentRef.current.scrollToBottom(500);
      }
    }
  }, []);

  useEffect(() => {
    if (unReadCount && window.location.pathname === "/tab/chat") {
      dispatch(clearChatDot());
    }
  }, [unReadCount]);

  useEffect(() => {
    if (!paCompleted && patientId) {
      dispatch(fetchAssessmentApi({ patientId: patientId }));
    }
  }, [paCompleted, dispatch, patientId]);

  // useEffect(() => {
  //   if (openUploadModal) {
  //     document.addEventListener("ionBackButton", handleBackButtonFunc);
  //   } else {
  //     document.removeEventListener("ionBackButton", handleBackButtonFunc);
  //   }
  // }, [openUploadModal]);

  useEffect(() => {
    if (
      window.Capacitor.platform !== "web" &&
      uiConfig &&
      uiConfig.isChatVisible &&
      isStartTourShown &&
      window.location.pathname === "/tab/chat"
    ) {
      if (!feedback.submitted) {
        if (feedback.nextReminderTime) {
          let nextDate = moment(feedback.nextReminderTime).add(7, "day");

          if (moment().isAfter(nextDate) && !feedback.submitted) {
            setShowFeedback(true);
          }
        } else {
          setShowFeedback(true);
        }
      }
    }
  }, []);

  let lastMessage = null;
  if (messages && messages.ids.length > 0 && !disableSend) {
    lastMessage = messages.entities[messages.ids[messages.ids.length - 1]];
  }
  useEffect(() => {
    // message changes
    // console.log("use effect get executed");
    if (action === "previous") {
      let msgDiv = document.getElementById("msg-id-" + scrollToMsg);
      if (msgDiv) {
        scrollIntoView(msgDiv, { time: 200 });
      }
      dispatch(toggleAction());
    } else {
      // console.log("else is true", enableAutoScroll);
      if (enableAutoScroll && ionContentRef && ionContentRef.current) {
        ionContentRef.current.scrollToBottom(500);
      }
    }
  }, [messages, action, enableAutoScroll, pendingMessages, files]);
  const _sendMessage = (content) => {
    const req = {
      roomId: chat.chatRoom,
      userId: chat.externalUserIdSt,
      content: content,
    };
    dispatch(sendMessage(req));
  };

  const onClickBubbleDebounce = useCallback(
    debounce((text, lastMessage, chat) => {
      dispatch(toggleDisabledSend(true));
      let content = chatUtility.buildBubbleRequest(lastMessage, text);
      const req = {
        roomId: chat.chatRoom,
        userId: chat.externalUserIdSt,
        content: content,
      };
      dispatch(sendMessage(req));
    }, 200),
    []
  );

  const onClickBubble = (text) => {
    if (!ui.disableSend) {
      onClickBubbleDebounce(text, lastMessage, chat);
    }
  };
  const insertUploadMessage = (e,fileError={}) => {
    let newMsg = [];
    let sizeError = false;
    let typeError = false;
    for (let i = 0; i < e.target.files.length && i < 5; i++) {
      let type = e.target.files[i].type;
      if (
        e.target.files[i].size <= 20000000 &&
        (type === "application/pdf" || type.includes("image"))
      ) {
        let msg = {
          id: "" + new Date().getTime() + Math.random(),
          name: e.target.files[i].name,
          format: e.target.files[i].type === "application/pdf" ? 4 : 3,
          file:e.target.files[i].isBlob?e.target.files[i].file: e.target.files[i],
        };

        newMsg.push({ ...msg });
      } else {
        if (e.target.files[i].size <= 20000000) {
          typeError = true;
        } else {
          sizeError = true;
        }
      }
    }

  

    if (e.target.files.length > 5 || fileError.countError) {
      let extraFileCount=fileError.countError? fileError.countError:e.target.files.length - 5;
      setError(
        `Uploading 5 files, please retry remaining ${
          extraFileCount
        } ${extraFileCount === 1 ? "file" : "files"}.`
      );
    }  else if (typeError || sizeError || fileError.sizeError) {
      setError("Only Pdfs and Images of size less than 20 mb are allowed");
    }

    setFiles((prevFiles) => {
      return [...prevFiles, ...newMsg];
    });
    dispatch(toggleEnableAutoScroll(true));
    // setEnableAutoScroll(true);
  };
  const removeUploadAndSendMsg = (id, content, shouldSend) => {
    setFiles((prevFiles) => {
      let newMsgs = prevFiles.filter((msg) => {
        return msg.id !== id;
      });

      return newMsgs;
    });
    _sendMessage(content);
  };

  const handleCameraPreviewClose = useCallback((arg) => {
    setOpenUploadModal(false);
    setOpenCameraPreview(arg);
  }, []);

  return (
    <IonPage className="ion-tab-page">
      <ChatHeader doctors={careTeams} uiConfig={uiConfig} />

      <IonContent
        id="ion-content"
        scrollEvents={true}
        ref={ionContentRef}
        className={`ion-padding leading-tight flex flex-col px-4 ${
          !device.ios14 && "bottom-safe-area"
        }`}
        style={{
          "--background": "#f0f3f8",
          "--padding-start": "12px",
          "--padding-end": "12px",
        }}
        onIonScroll={(e) => {
          e.target.getScrollElement().then((se) => {
            let scrollAuto =
              se.scrollHeight - se.clientHeight - 80 < e.detail.scrollTop;

            if (enableAutoScroll !== scrollAuto) {
              dispatch(toggleEnableAutoScroll(scrollAuto));
            }
          });
        }}
      >
        <Snackbar
          className={"chat-window-error-message"}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={error !== ""}
          autoHideDuration={9000}
          onClose={() => {
            setError("");
          }}
          ContentProps={{
            "aria-describedby": "error-id",
          }}
          message={
            error ? (
              <span id="error-id">{error ? error : defaultErrorMessage}</span>
            ) : (
              <span></span>
            )
          }
        />
        {hasPreviousMessages && (
          <LoadMoreButton
            onClick={() => {
              dispatch(
                getPreviousMessages({
                  roomId: chat.chatRoom,
                  userId: chat.chatUserId,
                  maxId: prevMetadata.minId,
                })
              );
            }}
            loading={loadMoreLoading}
          />
        )}
        {messages.ids &&
          messages.ids.map((id) => {
            const message = messages.entities[id];

            return (
              <>
                {message && (
                  <ChatMessage
                    key={`messaging-ids-${id}`}
                    message={message}
                    type={message.content.message.type}
                    currentQuestion={currentQuestion}
                    paCompleted={paCompleted}
                    chat={chat}
                    lastMessage={lastMessage}
                    history={history}
                    device={device}
                    setUpload={setOpenUploadModal}
                  ></ChatMessage>
                )}
              </>
            );
          })}
        <div className="w-full flex">
          {lastMessage && (
            <ChatBubble
              message={lastMessage}
              onClickBubble={onClickBubble}
            ></ChatBubble>
          )}
          {messages && messages.length === 0 && <IonSpinner name="dots" />}
        </div>

        {pendingMessages.ids &&
          pendingMessages.ids.map((id) => {
            const message = pendingMessages.entities[id];
            return (
              <>
                {message && (
                  <ChatMessage
                    key={`messaging-ids-${id}`}
                    message={message}
                    type={message.content.message.type}
                    currentQuestion={currentQuestion}
                    paCompleted={paCompleted}
                    chat={chat}
                    lastMessage={lastMessage}
                    history={history}
                    device={device}
                  ></ChatMessage>
                )}
              </>
            );
          })}
        {messages.ids && messages.ids.length === 0 && (
          <div style={{ position: "absolute", bottom: "80px" }}>
            <IonSpinner name="dots" />
          </div>
        )}

        <MessageWrapper mine={true} className="message message-right">
          <UploadingMessages
            patientId={patientId}
            files={files}
            sendUploadMsg={(content, id, shouldSend) => {
              removeUploadAndSendMsg(id, content, shouldSend);
            }}
            handleError={(err, errMsg) => {
              setError(errMsg);
            }}
          ></UploadingMessages>
        </MessageWrapper>

        <Feedback
          showFeedback={showFeedback}
          onClose={() => {
            dispatch(
              setNextFeedbackTime({ submitted: false, time: Date.now() })
            );
            setShowFeedback(false);
          }}
        ></Feedback>
        <ChatPageTour
          showTour={
            uiConfig.showTour &&
            ui.showTour &&
            !isStartTourShown &&
            window.location.pathname === "/tab/chat"
          }
          onClose={() => {
            dispatch(toggleTour());
          }}
        />

        {openUploadModal && (
          <DocumentChooseAndUpload
            patientId={patientId}
            shouldUpload={false}
            device={device}
            onUploadStart={(e,error={}) => {
              insertUploadMessage(e,error);
              setOpenUploadModal(false);
            }}
            onUploadDone={(res) => {
              setShowSpinner(false);
              setOpenUploadModal(false);
              if (res && res.status === "S") {
                let content = {
                  message: {
                    text: res.result?.id,
                    type: res.result?.format === 4 ? 3 : 2,
                    id: "" + new Date().getTime() + Math.random(),
                    metadata: {
                      id: res.result?.id,
                    },
                  },
                };
                _sendMessage(content);
                setError("");
              } else {
                setError(
                  "This file is too big. maximum allowed file size is 20MB"
                );
              }
            }}
            handleCameraStart={() => {
              setShowSpinner(true);
              setOpenUploadModal(false);
            }}
            handleCameraStop={(res) => {
              setShowSpinner(false);
              setOpenUploadModal(false);
              if (res && res.status === "S") {
                let content = {
                  message: {
                    text: res.result?.id,
                    type: res.result?.format === 4 ? 3 : 2,
                    id: "" + new Date().getTime() + Math.random(),
                    metadata: {
                      id: res.result?.id,
                    },
                  },
                };
                _sendMessage(content);
                setShowSpinner(false);
                setOpenUploadModal(false);
                setError("");
              } else {
                setShowSpinner(false);
                setOpenUploadModal(false);
                setError(res.result);
              }
            }}
            fileChooser={openUploadModal}
            hideFileTypeOption={() => {
              setOpenUploadModal(false);
            }}
            multipleUpload={true}
            isChatRoom={true}
            handleCameraPreviewClose={handleCameraPreviewClose}
          ></DocumentChooseAndUpload>
        )}
        {showSpinner && (
          <Drawer
            role="presentation"
            className={"share-msg-spinner"}
            disableEnforceFocus
            open={showSpinner}
            onClose={() => {}}
          >
            <IonSpinner name="crescent" color="red"></IonSpinner>
          </Drawer>
        )}
      </IonContent>
      {uiConfig.isChatVisible && (
        <IonFooter className="ion-no-border">
          <ChatInputBox
            insertUploadMessage={insertUploadMessage}
            patientId={patientId}
            uiConfig={uiConfig}
            onClickSend={_sendMessage}
            onClickUpload={() => {
              setOpenUploadModal(true);
            }}
          />
        </IonFooter>
      )}
      {openCameraPreview && (
        <div className="test-div-new-chat">
          <CameraPreviewComponent
            onCloseCamera={setOpenCameraPreview}
            patientId={patientId}
            shouldUpload={false}
            device={device}
            onUploadStart={(e) => {
              insertUploadMessage(e);
              setOpenUploadModal(false);
              setOpenCameraPreview(false);
            }}
            onUploadDone={(res) => {
              setShowSpinner(false);
              setOpenUploadModal(false);
              setOpenCameraPreview(false);
              if (res && res.status === "S") {
                let content = {
                  message: {
                    text: res.result?.id,
                    type: res.result?.format === 4 ? 3 : 2,
                    id: "" + new Date().getTime() + Math.random(),
                    metadata: {
                      id: res.result?.id,
                    },
                  },
                };
                _sendMessage(content);
                setError("");
              } else {
                setError(
                  "This file is too big. maximum allowed file size is 20MB"
                );
              }
            }}
            handleCameraStart={() => {
              setShowSpinner(true);
              setOpenUploadModal(false);
              setOpenCameraPreview(false);
            }}
            handleCameraStop={(res) => {
              setShowSpinner(false);
              setOpenUploadModal(false);
              setOpenCameraPreview(false);
              if (res && res.status === "S") {
                let content = {
                  message: {
                    text: res.result?.id,
                    type: res.result?.format === 4 ? 3 : 2,
                    id: "" + new Date().getTime() + Math.random(),
                    metadata: {
                      id: res.result?.id,
                    },
                  },
                };
                _sendMessage(content);
                setShowSpinner(false);
                setOpenUploadModal(false);
                setOpenCameraPreview(false);
                setError("");
              } else {
                setShowSpinner(false);
                setOpenUploadModal(false);
                setOpenCameraPreview(false);
                setError(res.result);
              }
            }}
            fileChooser={openUploadModal}
            hideFileTypeOption={() => {
              setOpenUploadModal(false);
              setOpenCameraPreview(false);
            }}
            multipleUpload={true}
            isChatRoom={true}
            handleCameraPreviewClose={handleCameraPreviewClose}
          />
        </div>
      )}
    </IonPage>
  );
}
