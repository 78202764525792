class Validation {
  testForHTMLInjection = (text) => {
    let htmlTagsRegex = /<(“[^”]*”|'[^’]*’|[^'”>])*>/;
    let result = htmlTagsRegex.test(text);
    return result;
  };
  validatePhoneNo(phoneno, countryCode) {
    if (this.testForHTMLInjection(phoneno)) {
      return false;
    }
    if (countryCode == "61" || countryCode == "971") {
      let re = /^\d{9}$/;
      return re.test(phoneno);
    } else {
      let re = /^\d{10}$/;
      return re.test(phoneno);
    }
  }
  validateOTP(otp) {
    if (this.testForHTMLInjection(otp)) {
      return false;
    }
    let re = /^\d{5}$/;
    return re.test(otp);
  }
}
export default new Validation();
