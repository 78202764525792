import successGreenImage from "../../assets/images/successGreen.png";
import { IonContent } from "@ionic/react";
export const BookingSuccessfullyCard = () => {
  return (
    <>
      <IonContent>
        <div className="p-8 bg-white h-full">
          <div className="w-full flex justify-center pt-40">
            <img src={successGreenImage} className="h-20 w-16 "></img>
          </div>
          <div className="text-center font-medium text-2xl  text-lightblack py-4">
            Your booking has been placed successfully.
          </div>

          <p className="text-lightblack text-xs font-normal text-center">
            You will receive a call from our medicine<br></br> partner to
            confirm medicine &<br /> quantity you want to order.
          </p>
          <button className="w-full bg-zyple text-white p-4 text-xl   font-semibold rounded-full text-center mt-28">
            Go back
          </button>
        </div>
      </IonContent>
    </>
  );
};
