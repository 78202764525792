import UploadingCard from "./types/uploadingCard";

export default function UploadingMessages({
  patientId,
  files,
  sendUploadMsg,
  handleError,
}) {
  return (
    <>
      {files &&
        files.map((file) => {
          return (
            <UploadingCard
              key={`uploading-card-${file.id}`}
              msg={file}
              name={file.name}
              sendUploadMsg={sendUploadMsg}
              handleError={handleError}
              patientId={patientId}
            ></UploadingCard>
          );
        })}
    </>
  );
}
