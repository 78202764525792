import React, { useEffect, useState } from "react";
import meditationService from "../../../../services/MeditationService";

import MeditationCard from "./meditationCard";
import TimeMessage from "./time";
import { useDispatch, useSelector } from "react-redux";
import { updateCardInfo } from "../../chatSlice";

const MeditationCardContent = ({ message }) => {
  const [media, setMedia] = useState(null);
  const mediCards = useSelector((store) => store.chat.mediCards);
  const dispatch = useDispatch();
  useEffect(() => {
    if (mediCards[message.metadata.id]) {
      setMedia(mediCards[message.metadata.id]);
    } else {
      fetchMeditationData();
    }
  }, [mediCards[message.metadata.id]]);

  const fetchMeditationData = () => {
    meditationService
      .getMeditation(message.metadata.id)
      .then((res) => {
        dispatch(updateCardInfo({ cardName: "mediCards", data: res.data }));
      })
      .catch((err) => {});
  };

  return (
    <div>
      {media ? (
        <>
          <MeditationCard media={media} />
          <TimeMessage message={message} />:
        </>
      ) : null}
    </div>
  );
};

export default MeditationCardContent;
