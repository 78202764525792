import React from "react";
import { Drawer } from "@mui/material";
export default function VitalDetails({
  showDetail,
  currentDetail,
  closeDrawer,
}) {
  return (
    <Drawer
      open={showDetail}
      anchor="bottom"
      onClose={() => closeDrawer()}
      className="param-detail-drawer"
    >
      <div className="section">
        <div>
          <h5>{currentDetail.name}</h5>
        </div>
        <div>{currentDetail.description}</div>

        <div className="text-center">
          <button className="okay-btn" onClick={() => closeDrawer()}>
            Okay
          </button>
        </div>
      </div>
    </Drawer>
  );
}
