import React from "react";
import DocumentFolder from "./documentFolder";
import { IonRow } from "@ionic/react";
import { useSelector } from "react-redux";

export default function DocumentList({ onClickFolder }) {
  const documents = useSelector((store) => store.documents.documents);

  return (
    <IonRow className="row" style={{ padding: "20px" }}>
      {documents &&
        documents.ids.map((document, index) => {
          return (
            <DocumentFolder
              onClickFolder={() => onClickFolder(documents.entities[document])}
              document={documents.entities[document]}
              isRight={(index + 1) % 2 === 0}
            ></DocumentFolder>
          );
        })}
    </IonRow>
  );
}
