import { IonProgressBar } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
const rewardProgressDots = [1, 2, 3];
export const SewaProgressCard = ({ selectedPolicy }) => {
  const status = useSelector((state) => state.reward.status);
  const [dot2X, setDot2X] = useState(50);

  const policyData = selectedPolicy.data;

  let progressValue = policyData?.totalRewardsPoint / 30000;

  const dotContainer = useRef();
  useEffect(() => {
    let dot2 = (20000 / 30000) * 100;
    setDot2X(dot2);
  }, []);

  return (
    <>
      <div
        className="outline rounded-lg"
        style={{ outlineColor: "#E0771180", outlineWidth: "1px" }}
      >
        <div className="p-3 relative level-progress">
          <div className="relative">
            <IonProgressBar
              value={progressValue}
              className="h-2 rounded-full"
            ></IonProgressBar>
            <div ref={dotContainer} className="level-progress-dots-container">
              {rewardProgressDots.map((dots, ind) => {
                let style = {};
                if (ind === 1) {
                  style = { left: `${dot2X}%` };
                } else if (ind === 2) {
                  style = { right: `0px` };
                }

                return (
                  <div
                    key={`level-progress-dots-${dots}`}
                    className="level-progress-dots mr-[1px]"
                    style={style}
                  >
                    <div className="flex justify-center px-2">
                      {dots === 1 && (
                        <div
                          className="font-bold leading-9  text-xs  pl-4 whitespace-nowrap"
                          style={{ marginLeft: "15px" }}
                        >
                          Beginner{" "}
                          <div
                            className="font-medium text-xs"
                            style={{ marginTop: "-10px" }}
                          >
                            0-19,999
                          </div>
                        </div>
                      )}
                      {dots === 2 && (
                        <div className="font-bold leading-9 text-xs text-center pr-12 mr-[17px] whitespace-nowrap">
                          Advanced{"  "}
                          <div
                            className="font-medium text-xs mr-[17px]"
                            style={{
                              textWrap: "nowrap",
                              marginTop: "-10px",
                            }}
                          >
                            20,000-29,999
                          </div>
                        </div>
                      )}
                      {dots === 3 && (
                        <div
                          className="font-bold text-xs leading-9 pr-1 justify-right whitespace-nowrap"
                          style={{ marginLeft: "10px" }}
                        >
                          &nbsp; &nbsp;&nbsp; Pro{" "}
                          <div
                            className="font-medium text-xs pr-12 mr-[1px]"
                            style={{ marginTop: "-10px" }}
                          >
                            {" "}
                            30,000
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* <div className="flex justify-between px-3">
          <div className="font-bold text-xs text-center">
            Beginner{" "}
            <div className="font-medium text-xs text-left">0-19,999</div>
          </div>
          <div className="font-bold text-xs text-center ">
            Advanced{" "}
            <div className="font-medium text-xs mr-[15px]">20,000-29,999</div>
          </div>
          <div className="font-bold text-xs text-right">
            Pro <div className="font-medium text-xs ">30,000</div>
          </div>
        </div> */}

        <hr
          className=" w-[95%]"
          style={{ backgroundColor: "#E0771180", marginTop: "32px" }}
        />

        <div className="p-3 pb-4 flex text-center justify-between space-x-2">
          <div className="flex flex-col items-center">
            <div className="font-medium text-xs mb-3">
              Next target to level up
            </div>
            <div className="px-8 py-1 font-bold text-xs rounded-lg bg-gradient-to-r from-[#E3E7F1] to-[#f9f9fc] ring-1 ring-[#E3E7F1] ">
              {policyData.pointNeededForNextLevel.toLocaleString()}
              <span>&nbsp; Points</span>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="font-medium text-xs mb-3">Days left to achieve</div>
            <div className="font-bold text-xs px-8 py-1 rounded-lg bg-gradient-to-r from-[#E3E7F1] to-[#f9f9fc] ring-1 ring-[#E3E7F1]">
              {policyData.daysLeftToComplete}
              <span>&nbsp; Days</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
