import React from "react";
import StepathonStepCard from "./components/stepathonStepCrad";
import { LeaderBoardCard } from "./components/leadreboardCard";

function StepathonPage2() {
  return (
    <div>
      <StepathonStepCard />
      <LeaderBoardCard />
    </div>
  );
}

export default StepathonPage2;
