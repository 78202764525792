import { AppoinmentCard } from "./component/AppoinmentCard";
import { IonContent, IonPage } from "@ionic/react";
import { BookHassleCard } from "./component/Card";

import BookExpertConsultaionCard from "./component/BookExpertConCard";
import diagnosticsImage from "../../assets/images/Diagnostic banner.png";
import BookDiagnosticsCard from "./component/BookDiagnosticsCard";

export default function Diagnostics() {
  const data = {
    title: "Diagnostic",
    image: diagnosticsImage,
    doctor: "Full body health checkup",
  };
  return (
    <>
      <IonPage>
        <IonContent>
          <div className="bg-slate-50">
            <BookHassleCard {...data} />
            <AppoinmentCard {...data} />
            <BookDiagnosticsCard />
          </div>
        </IonContent>
      </IonPage>
    </>
  );
}
