import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
const FIRST_ROW_EMPTY = 1;
const FIRST_ROW_FILLED = 2;
const REPORT_SUBMITTED = 3;
const NONE = 4;
const FINAL_REPORT_SUBMIT = 5;
const reportingMessages = {
  1: [
    "Please select the day of activities first.",
    "Select the date and list your activities.",
    "Welcome back! Select the date of activity.",
    "Hello! Don't forget to select the date.",
    "Glad to see you! Select the date please.",
  ],
  2: [
    "Fill out your routine for doctors' review.",
    "Your reportings are important for us.",
    "Great work, keep going!",
    "Help us analyze your health better.",
    "We are proud of you! Keep going.",
  ],
  3: [
    "We missed you! Awaiting your reportings.",
    "Hello! How was your day?",
    "Hope your day is progressing well!",
    "We are eager to know how your day was.",
    "Tell us how how your day went.",
  ],
  4: [
    "Great job! Feel free to edit and re-send.",
    "Care team is reviewing your reportings!",
    "Thank you for your honest reportings!",
    "Feel free to add more and re-send.",
    "Wonderful progress so far!",
  ],
  5: [
    "Go on and include the tiniest detail.",
    "Anything you type is saved instantly.",
    "Anything you type is always editable.",
    "You can come back anytime to complete.",
    "Make sure you SEND your reporting.",
  ],
  6: [
    "Simply hit Send when done.",
    "The doctors are awaiting your reportings.",
    "Would you like to add something more?",
    "Include all meals and exercises.",
    "Daily reporting equals best outcomes!",
  ],
  7: ["Your reporting has been successfully submitted"],
};
export default function ReportingMessage() {
  const items = useSelector((store) => store.reporting.ui.items);
  const tableError = useSelector((store) => store.reporting.ui.tableError);
  const tableErrorMsg = useSelector(
    (store) => store.reporting.ui.tableErrorMsg
  );
  const [message, setMessage] = useState();

  useEffect(() => {
    getMessage(tableError, items, tableErrorMsg);
  }, [items.length, tableError, tableErrorMsg]);
  const getMessage = useCallback((tableError, items, tableErrorMsg) => {
    if (tableError) {
      setMessage(tableErrorMsg);
    } else {
      let size = items ? items.length : 0;

      let type;
      if (size > 0) {
        type = getMessageType(size, FIRST_ROW_FILLED);
      } else {
        type = getMessageType(size, FIRST_ROW_EMPTY);
      }
      if (reportingMessages[type]) {
        setMessage(reportingMessages[type][getRandomInt(1, 4)]);
      } else {
        setMessage("");
      }
    }
  }, []);
  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  const getMessageType = (size, rowStatus) => {
    if (size === 1 && rowStatus === FIRST_ROW_EMPTY) {
      return "1";
    } else if (size > 1 && size < 3 && rowStatus === NONE) {
      return "2";
    } else if (size >= 1 && rowStatus === FIRST_ROW_FILLED) {
      return "3";
    } else if (size >= 1 && rowStatus === REPORT_SUBMITTED) {
      return "4";
    } else if (size >= 4 && size < 7 && rowStatus === NONE) {
      return "5";
    } else if (rowStatus === 4 && size > 7) {
      return "6";
    } else if (size === 0 && rowStatus === REPORT_SUBMITTED) {
      return "7";
    } else {
      return size;
    }
  };

  return (
    <div
      className="message-view-container"
      style={{ position: "sticky", top: "0" }}
    >
      {message && (
        <div className={`message-view ${tableError ? "message-error" : ""}`}>
          <label>{message}</label>
        </div>
      )}
    </div>
  );
}
