import React from "react";

export default class SingleSelectQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
    };
  }

  componentDidMount() {
    this.getSelectedAnswerIfAny();
  }

  componentDidUpdate(prevPros) {
    const { currentQuestion } = this.props;
    if (currentQuestion.id !== prevPros.currentQuestion.id) {
      this.getSelectedAnswerIfAny();
    }
  }

  getSelectedAnswerIfAny = () => {
    const { currentQuestion, updateAnswerTag } = this.props;
    if (currentQuestion.answer !== null) {
      this.updateSelected(currentQuestion.answer);
    } else {
      this.setState({
        selected: null,
      });
      updateAnswerTag(null);
    }
  };

  updateSelected = (name) => {
    const { updateAnswerTag } = this.props;
    this.setState({
      selected: name,
    });
    updateAnswerTag(name);
  };

  render() {
    const { currentQuestion } = this.props;
    const { selected } = this.state;
    return (
      <div className="current-question">
        <div className="fw-semibold question-text">{currentQuestion.text}</div>
        <div className="single-options-content">
          {currentQuestion.fields &&
            currentQuestion.fields.map((field, index) => {
              return (
                <div
                  onClick={() => this.updateSelected(field.tag)}
                  className={
                    selected === field.tag
                      ? "selected-pa select-pa fw-semibold"
                      : "select-pa fw-semibold"
                  }
                  key={index}
                >
                  <span>{field.text}</span>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
