import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import { App } from "@capacitor/app";
import "./index.scss";

const RootedPage = () => {
  return (
    <IonPage>
      <IonContent>
        <div className="rooted-page-container">
          <div className="rooted-page-container-bottom">
            <p className="rooted-page-container-bottom-top">
              {window.Capacitor.platform === "ios"
                ? "Jailbreak detected"
                : "Root detected"}
            </p>
            <p className="rooted-page-container-bottom-bottom">
              {window.Capacitor.platform === "ios"
                ? `Your device is found out to be jailbroken. Due to security reasons you
              are not allowed to login with this device.`
                : `Your device is found out to be rooted. Due to security reasons you
              are not allowed to login with this device.`}
            </p>
          </div>
          <button
            className="rooted-page-ok-btn"
            onClick={() => {
              App.exitApp();
            }}
          >
            OK
          </button>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default RootedPage;
