import React, { useEffect, useState } from "react";
import nextImg from "../../../assets/images/forward-black-arrow.png";
import { useHistory } from "react-router";
import RippleButton from "../../../components/common/RippleButton";
import { useDispatch, useSelector } from "react-redux";
import { logEvent } from "../../../app/eventSlice";
import { fetchLongTermGoalsApi } from "../../healthGoals/goalSlice";
import { fetchHealthPlanProfile } from "../../../containers/healthPlan/healthPlanSlice";

export default function HealthDetailsComponent({ data }) {
  const patientProfile = useSelector(
    (store) => store.login.user.patientProfile
  );
  const [healthInfo, setHealthInfo] = useState({});

  const longTermGoals = useSelector((store) => store.goals.longTermGoals);
  const healthPlan = useSelector((state) => state.healthPlan.healthPlan);
  const [fields, setFields] = useState({
    primaryTitle: data === "goals" ? "Long term" : "Level",
    secondaryTitle: data === "goals" ? "Health goals" : "Health plan",
    description: data === "goals" ? "To be achieved" : "To be followed",
  });
  const [healthLevel, setHealthLevel] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (data == "goals") {
      dispatch(fetchLongTermGoalsApi({ patientId: patientProfile.id }));
    } else {
      dispatch(fetchHealthPlanProfile({ id: patientProfile.id }))
        .unwrap()
        .then((res) => {
          handleUpdateHealthPlanLevel(res.data);
        })
        .catch((err) => {
          console.log("error while fetching heath plan", err);
        });
      // this.indexDBService.getHealthPlan("HP").then((result) => {
      //   if (result && result.data) {
      //     let healthLevel = result.data.current_level.split(" ")[1];
      //     this.setState({
      //       healthInfo: result.data,
      //       healthLevel: healthLevel,
      //     });
      //   }
      //   this.apiService
      //     .getWithStatus(ApiConfig.healthPlan.getPlan.format(patientId))
      //     .then((data) => {
      //       if (data && data.status === 200) {
      //         data.payload.then((apiPayload) => {
      //           let healthLevel = apiPayload.current_level.split(" ")[1];
      //           this.setState({
      //             healthInfo: apiPayload,
      //             healthLevel: healthLevel,
      //             loading: false,
      //             loaded: true,
      //           });
      //           this.indexDBService.updateHealthPlan(apiPayload, "HP");
      //         });
      //       } else if (data && data.status === (404 || 502 || 504)) {
      //         this.setState({
      //           healthPlan: {
      //             current_level: null,
      //           },

      //           loading: false,
      //           loaded: true,
      //         });
      //       }
      //     })
      //     .catch((error) => {
      //       this.setState({
      //         loading: false,
      //         loaded: false,
      //         hasError: true,
      //       });
      //     });
      // });
    }
  }, []);

  const handleUpdateHealthPlanLevel = (data) => {
    let level = 0;
    level = data.current_level.split(" ")[1];
    setHealthLevel(level);
    setHealthInfo(data);
  };

  useEffect(() => {
    if (healthPlan && healthPlan.current_level) {
      handleUpdateHealthPlanLevel(healthPlan);
    }
  }, [healthPlan]);

  const openPage = () => {
    if (data === "plan") {
      dispatch(logEvent({ action: "open_health_plan_profile" }));
      history.push("/health-plan");
    } else if (data === "goals") {
      dispatch(logEvent({ action: "click_goals_via_menu" }));
      history.push("/health-goals-screen");
    }
  };

  return (
    <div
      onClick={openPage}
      className={
        data === "goals"
          ? "goal-border health-detail-content"
          : "plan-border health-detail-content"
      }
    >
      <div className="head-det-data">
        <div className="fs14" style={{ color: "#171A21" }}>
          {fields.primaryTitle}
        </div>
        <div>
          <img
            style={{ height: "15px" }}
            src={nextImg}
            alt="icon"
            onClick={() => {
              // console.log("open_long_term_goals");
              dispatch(
                logEvent({
                  action: "open_long_term_goals",
                })
              );
            }}
          />
        </div>
      </div>
      <div className={data === "goals" ? "goal-color fs36" : "plan-color fs36"}>
        {data == "goals"
          ? longTermGoals.length
          : healthInfo.current_level
          ? healthLevel
          : "--"}
      </div>
      <div className="fs18 fw-medium">{fields.secondaryTitle}</div>
      <div className="fs12 subheading fw-light">{fields.description}</div>
    </div>
  );
}
