import DateMessage from "./types/date";
import HpCardMessage from "./types/hpCard";
import ReportCardMessage from "./types/reportCard";
import TextChatMessage from "./types/text";
import RequestCardMessage from "./types/requestCard";
import VitalCardMessage from "./types/vitalCard";
import CustomCardMessage from "./types/customCard";
import InformationCard from "./types/informationCard";
import DoctorCallNoteCard from "./types/doctorCallNotesCard";
import DoctorReviewCard from "./types/doctorReviewNote";
import MeditationCardContent from "./types/meditationCardContent";
import styled from "@emotion/styled";
import AssessmentStartCard from "./types/paStart";
import PACompleteCard from "./types/paCompleteCard";
import DocumentCard from "./types/documentCard";
import { MessageWrapper } from "../misc.js";

export default function ChatMessage({
  message,
  type,
  paCompleted,
  currentQuestion,
  lastMessage,
  chat,
  history,
  device,
  setUpload,
}) {
  return (
    <>
      {type !== -1 && (
        <MessageWrapper
          className={`message ${
            message.mine ? "message-right" : "message-left"
          }`}
          mine={message.mine}
          id={`msg-id-${message.id}`}
          key={`message-id-${message.id}`}
        >
          {type === 1 && <TextChatMessage message={message} type={type} />}
          {(type === 2 || type === 3) && (
            <DocumentCard message={message} type={type} />
          )}
          {type === 6 && <ReportCardMessage message={message} type={type} />}
          {type === 17 && (
            <HpCardMessage
              lastMessage={lastMessage}
              message={message}
              type={type}
              chat={chat}
              history={history}
            />
          )}
          {type === 13 && (
            <AssessmentStartCard
              currentQuestion={currentQuestion}
              paCompleted={paCompleted}
              message={message}
              type={type}
            />
          )}
          {type === 14 && <PACompleteCard message={message} type={type} />}

          {type === 8 && <VitalCardMessage message={message} type={type} />}
          {type === 12 && (
            <InformationCard message={message.content.message} type={type} />
          )}
          {type === 11 && (
            <DoctorCallNoteCard message={message.content.message} type={type} />
          )}
          {type === 19 && (
            <DoctorReviewCard message={message.content.message} type={type} />
          )}
          {type === 4 && (
            <MeditationCardContent
              message={message.content.message}
              type={type}
            />
          )}
          {type === 20 && (
            <CustomCardMessage
              patientId={chat.patientId}
              device={device}
              message={message}
              type={type}
              setUpload={setUpload}
            />
          )}
          {[5, 7, 15, 18].indexOf(type) !== -1 && (
            <RequestCardMessage message={message} type={type} />
          )}
        </MessageWrapper>
      )}

      {type === -1 && <DateMessage message={message} />}
    </>
  );
}
