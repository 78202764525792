import React, { useEffect, useRef, useState } from "react";
import checkIcon from "../../../assets/images/ic_completed_success.png";
import navigoServices from "../../../services/NavigoServices";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { logEvent } from "../../../app/eventSlice";

function BookAfterPayment({ orderId, handleGoBack }) {
  const [status, setStatus] = useState(0);
  const totalTime = useRef(0);
  const intervalRef = useRef(null);
  const dispatch=useDispatch();
  useEffect(() => {
    dispatch(logEvent({ action: `payment complete` }));
    intervalRef.current = setInterval(callAndCheckStatus, 4000);
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);
  const cancelIntervalAndSetStatus = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    setStatus(2);
  };
  const callAndCheckStatus = () => {
    totalTime.current = totalTime.current + 10;
    if (totalTime.current >= 120) {
      cancelIntervalAndSetStatus();
    } else {
      navigoServices
        .checkNavigoCreateStatus(orderId)
        .then((result) => {
          const res = result.data;
          if (res && res.status === 1) {
            handleGoBack(2);
          } else if (res && res.status === 2) {
            cancelIntervalAndSetStatus();
          }
        })
        .catch((err) => {
          cancelIntervalAndSetStatus();
        });
    }
  };
  return (
    <div className="navigo-book-after-payment-container">
      <img src={checkIcon} />
      <h1>Payment Completed</h1>
      {status === 0 && (
        <>
          <CircularProgress className="navigo-book-after-payment-loader"></CircularProgress>
          <p className="navigo-book-after-payment-status">
            Creating appointment...
          </p>
        </>
      )}

      {status === 2 && (
        <p className="navigo-book-after-payment-status">
          ⚠️We couldn't confirm your booking, please try again after some time.
          If any sum is deducted at your end it will be refunded within 7
          working days.
        </p>
      )}
      {status === 2 && (
        <button
          className="navigo-book-after-payment-go-back"
          onClick={() => {
            handleGoBack(1);
          }}
        >
          Go back
        </button>
      )}
    </div>
  );
}

export default BookAfterPayment;
