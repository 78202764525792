class ChatUtility {
  buildBubbleRequest(lastMessage, replyText) {
    const state = lastMessage.content.state;
    let messageBody = {
      message: {
        id: "" + new Date().getTime() + Math.random(),
        text: replyText,
        type: 1, //msg.data.type, bubble always gives a text back!
      },
    };
    if (state) {
      messageBody.state = state;
    }
    return messageBody;
  }
}

export default new ChatUtility();
