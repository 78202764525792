import React, { useState } from "react";
import { IonModal } from "@ionic/react";
import { FeatureCard } from "./FeatureCard";
// import Swiper from 'swiper/dist/js/swiper.js'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import YogaPage from "./YogaPage";
import { useHistory } from "react-router";
import { redirectToWebWithToken } from "../../../utils/helperMethods";
import { useDispatch, useSelector } from "react-redux";
import { logEvent } from "../../../app/eventSlice";

SwiperCore.use([Pagination]);

export const FeatureCards = ({ featureTiles }) => {
  const clientConfig = useSelector((store) => store.tab.clientConfig);
  const patientProfile = useSelector(
    (store) => store.login.user?.patientProfile
  );
  const [showFeatureDetail, setShowFeatureDetail] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const handleFeatureClose = () => {
    setShowFeatureDetail(false);
  };
  const handleFeatureClick = (tag, link = undefined, subtitle1) => {
    setSelectedFeature(tag);
    if (tag === "chat") {
      dispatch(logEvent({ action: "feature_tile_click_chat" }));
      history.push("/tab/chat");
    } else if (tag === "expert") {
      dispatch(logEvent({ action: "feature_tile_click_expert_consultation" }));
      window.open("https://zyla.in/expert-doctor-consultation");
    } else if (tag === "healthplan") {
      dispatch(logEvent({ action: "feature_tile_click_healthplan" }));
      history.push("/health-plan");
    } else if (tag === "meditations") {
      dispatch(logEvent({ action: "feature_tile_click_meditation" }));
      history.push("/meditation");
    } else if (tag === "medicine") {
      dispatch(logEvent({ action: "feature_tile_click_medicine" }));
      history.push("/medicine");
    } else if (tag === "dental") {
      dispatch(logEvent({ action: "feature_tile_click_dental" }));
      history.push("/dentalCare");
    } else if (tag === "vitals") {
      dispatch(logEvent({ action: "feature_tile_click_vital" }));
      history.push("/vitals");
    } else if (tag === "eyecheckup") {
      dispatch(logEvent({ action: "feature_tile_click_eyeCheckup" }));
    } else if (tag === "documents") {
      dispatch(logEvent({ action: "feature_tile_click_document" }));
      history.push("/documents");
    } else if (tag === "BOOKING_OFFER") {
      dispatch(logEvent({ action: "feature_tile_click_book_diagnostic" }));
      history.push("/navigo");
    } else if (tag === "webview") {
      if (link) {
        dispatch(
          logEvent({
            action: "feature_tile_click_webview",
            data: { link: link },
          })
        );

        if (subtitle1 === "Diagnostics") {
          history.push("/navigo");
        } else {
          redirectToWebWithToken(link, patientProfile?.id);
        }
      }
    } else {
      dispatch(
        logEvent({
          action: "feature_tile_click_yoga",
        })
      );
      setShowFeatureDetail(true);
    }
  };

  return (
    <div className="feature-cards">
      <IonModal isOpen={showFeatureDetail} className="home-yoga-page-modal">
        {selectedFeature === "yoga" && (
          <YogaPage handleClose={handleFeatureClose} />
        )}
      </IonModal>
      <Swiper
        slidesPerView={3}
        spaceBetween={8}
        pagination={{
          clickable: true,
        }}
        observer={true}
        observeParents={true}
      >
        {featureTiles.map((featureCard) => (
          <SwiperSlide>
            <FeatureCard
              accentColor={featureCard.color}
              title={featureCard.title}
              subtitle1={featureCard.subtitle}
              link={featureCard.link}
              offertext={featureCard.text}
              icon={featureCard.image}
              tag={featureCard.action}
              showFeatureDetail={handleFeatureClick}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
