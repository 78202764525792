import React, { useEffect, useState } from "react";
import ChatService from "../../../../services/ChatService";
import test from "../../../../assets/images/bg_pattern.png";

import SecureImage from "../../../../components/common/SecureImage";
import ZylaLogo from "../../../../assets/images/zyla-text-logo.png";
import whatsappIcon from "../../../../assets/icons/whatsapp-icon.png";
import { SocialSharing } from "@ionic-native/social-sharing";
import TimeMessage from "./time";
import { useDispatch, useSelector } from "react-redux";
import { updateCardInfo } from "../../chatSlice";
import { IonSpinner } from "@ionic/react";
import { Drawer } from "@mui/material";
import { logEvent } from "../../../../app/eventSlice";
const InformationCard = ({ message }) => {
  const [infoData, updateInfoData] = useState({
    title: "",
    image: "",
    summary: "",
  });
  const [showSpinner, setShowSpinner] = useState(false);
  const dynCards = useSelector((store) => store.chat.dynCards);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dynCards[message.metadata.id]) {
      updateInfoData(dynCards[message.metadata.id]);
    } else {
      fetchInfoCard();
    }
  }, [dynCards[message.metadata.id]]);

  const fetchInfoCard = () => {
    const chatService = new ChatService();
    chatService
      .getInformationCard(message.metadata.id)
      .then((res) => {
        dispatch(updateCardInfo({ cardName: "dynCards", data: res.data }));
      })
      .catch((err) => {});
  };

  const handleGenerateImage = () => {
    dispatch(logEvent({ action: `click_share_did_you_know_knowledge` }));
    setShowSpinner(true);
    const chatService = new ChatService();
    chatService
      .getDynImage(infoData.image, infoData.title, infoData.summary)
      .then((result) => {
        const res = result.data;
        setShowSpinner(false);
        SocialSharing.share(
          null,
          null,
          "data:image/png;base64," + res.image,
          null
        )
          .then((res) => {})
          .catch((err) => {
            console.log("error is ", err);
          });
      })
      .catch((err) => {
        setShowSpinner(false);
        console.log("error is", err);
      });
  };

  return (
    <div>
      <div style={{ display: "flex", minHeight: "300px" }}>
        <div className="information-chat">
          <SecureImage
            classN="background"
            defaultImage={test}
            src={infoData.image}
          ></SecureImage>
          <div className="information-card-content">
            <div className="data-info">
              <div className="information-chat-title">{infoData.title}</div>
              <div className="information-chat-summary">{infoData.summary}</div>
            </div>
          </div>
          {window.Capacitor.platform !== "web" && (
            <div className="information-card-share">
              <div className="information-card-share-below">
                <span>share with your friends & family</span>
                <img src={ZylaLogo} alt="logo" />
              </div>
              <div
                className="information-card-share-above"
                onClick={handleGenerateImage}
              >
                <img src={whatsappIcon} alt="icon" />
                <span>{`${
                  new Date(message.time).getDate() % 2 === 0
                    ? "Share on Whatsapp"
                    : "Share Knowledge"
                }`}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <TimeMessage message={message} />
      {showSpinner && (
        <Drawer
          role="presentation"
          className={"share-msg-spinner"}
          disableEnforceFocus
          open={showSpinner}
          onClose={() => {
            setShowSpinner(false);
          }}
        >
          <IonSpinner name="crescent" color="red"></IonSpinner>
        </Drawer>
      )}
    </div>
  );
};

export default InformationCard;
