import React from "react";
import careTeamAvatar from "../../assets/images/avatar.png";
import { getUrlWithToken } from "../../utils/helperMethods";
export default function DoctorCard({ doctor }) {
  return (
    <div className="doctor-cards">
      <div className="dr-card-wrapper">
        <div>
          <img
            src={
              doctor.profile_image
                ? getUrlWithToken(doctor.profile_image)
                : careTeamAvatar
            }
            onErrorCapture={(e) => {
              if (e.target.src !== careTeamAvatar) {
                e.target.src = careTeamAvatar;
              }
            }}
            className="doctor-image"
            alt="profile-img"
          />
          <div>
            <h3>{doctor.name}</h3>
            <p>{doctor.title}</p>
          </div>
        </div>
        <p>{doctor.description}</p>
      </div>
    </div>
  );
}
