import React, { useEffect, useRef, useState } from "react";
import ChatService from "../../../../services/ChatService";
import testImage from "../../../../assets/images/zyla-logo.png";
import TimeMessage from "./time";
import { useDispatch, useSelector } from "react-redux";
import { updateCardInfo } from "../../chatSlice";

const DoctorReviewNotes = ({ message }) => {
  const [notes, setNotes] = useState(null);
  const notesContent = useRef();
  const reviewNotes = useSelector((store) => store.chat.reviewNotes);
  const dispatch = useDispatch();

  useEffect(() => {
    if (reviewNotes[message.metadata.id]) {
      setNotes(reviewNotes[message.metadata.id]);
    } else {
      getDoctorNote();
    }
  }, [reviewNotes[message.metadata.id]]);

  const getDoctorNote = () => {
    const chatService = new ChatService();
    chatService
      .getDoctorNotes(message.metadata.id)
      .then((res) => {
        setNotes(res.data);
        dispatch(updateCardInfo({ cardName: "reviewNotes", data: res.data }));
      })
      .catch((err) => {});
  };

  const onErrorLoadingImage = (e) => {
    e.target.src = testImage;
  };

  return (
    <React.Fragment>
      {notes ? (
        <div>
          <div className="doctor-notes-content">
            <div className="doctor-information" id="doctor-nodes">
              <div id="doctor-notes-img" className="doc-img">
                <img
                  className="image-doc"
                  onError={(e) => onErrorLoadingImage(e)}
                  src={
                    notes && notes.doctor && notes.doctor.profile_image
                      ? notes.doctor.profile_image
                      : testImage
                  }
                />
              </div>
              <div className="doc-info card-margin-fix doc-info-flex-class">
                <div id="blue-text">Doctor Notes</div>
                <div className="fs12">
                  by {notes && notes.doctor && notes.doctor.name}
                </div>
              </div>
            </div>
            <div className="notes-content">{notes.notes}</div>
          </div>
          <TimeMessage message={message} />
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default DoctorReviewNotes;
