import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import arrowLeft from "../../../assets/icons/arrow-back-black.svg";
import emptyCheckBox from "../../../assets/icons/checkbox-empty.svg";
import filledCheckBox from "../../../assets/icons/checkbox-filled.svg";

import homeIcon from "../../../assets/icons/home-selected.png";
import { CircularProgress } from "@mui/material";

import AuthenticationService from "../../../services/AuthenticationService";
import familyPlanService from "../../../services/FamilyPlanServices";

import ErrorComponent from "../../../components/common/ErrorComponent";
import { useSelector } from "react-redux";

function EditAddAddress({ handleClose }) {
  const addressStore = useSelector((store) => store.address);
  const patientProfile = useSelector(
    (store) => store.login.user.patientProfile
  );
  const [layout, setLayout] = useState(1);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [pincode, setPincode] = useState("");
  const [landmark, setLandmark] = useState("");
  const [city, setCity] = useState("");
  const [selectedAdd, setSelectedAdd] = useState(-1);
  const [rajya, setRajya] = useState("");
  const [sublocality, setSubLocality] = useState("");
  const [locality, setLocality] = useState("");
  const [zila, setZila] = useState("");
  const [countryCode, setCountryCode] = useState("91");
  const [addType, setAddType] = useState("");
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openImpDiag, setOpenImpDiag] = useState(false);
  const [addressBook, setAddressBook] = useState([]);
  const {
    isEdit,
    memberPatientId: memberId,
    selectedAddress: address,
  } = addressStore;
  const errMsg = useRef("");
  const authService = new AuthenticationService();

  useEffect(() => {
    window.scrollTo(0, 0);
    setName(address.name);
    setNumber(address.phoneNo);
    setPincode(address.pincode);
    setLandmark(address.landmark);
    setCity(address.city);
    setRajya(address.state);
    setLocality(address.line2);
    setSubLocality(address.line1);
    setZila(address.district);
    setAddType(address.type);
  }, []);
  const handleAddressSave = (e) => {
    let namePattern = /^[A-Za-z0-9'-. _]+$/;
    let phoneNoPattern = /^\d{10}$/;
    let pincodePattern = /^\d{6}$/;
    let addressPattern = /^[A-Za-z0-9,'-. _#\/]+$/;
    let landmarkPattern = /^[A-Za-z0-9,'-. _#\/]*$/;

    if (name === "") {
      errMsg.current = "Name is required";
      setIsError(true);
      return;
    } else {
      if (!namePattern.test(name)) {
        errMsg.current = "Please enter a correct name";
        setIsError(true);
        return;
      }
    }
    if (number === "") {
      errMsg.current = "Phone number is required";
      setIsError(true);
      return;
    } else {
      if (!phoneNoPattern.test(number)) {
        errMsg.current = "Please enter a correct phone number";
        setIsError(true);
        return;
      }
    }
    if (pincode === "") {
      errMsg.current = "Pincode is required";
      setIsError(true);
      return;
    } else {
      if (!pincodePattern.test(pincode)) {
        errMsg.current = "Please enter a correct pincode";
        setIsError(true);
        return;
      }
    }
    if (zila === "") {
      errMsg.current = "District is required";
      setIsError(true);
      return;
    } else {
      if (!addressPattern.test(zila)) {
        errMsg.current = "Please enter a correct district";
        setIsError(true);
        return;
      }
    }
    if (rajya === "") {
      errMsg.current = "State is required";
      setIsError(true);
      return;
    } else {
      if (!addressPattern.test(rajya)) {
        errMsg.current = "Please enter a correct state";
        setIsError(true);
        return;
      }
    }
    if (sublocality === "") {
      errMsg.current = "House No. / Floor / Building is required";
      setIsError(true);
      return;
    } else {
      if (!addressPattern.test(sublocality)) {
        errMsg.current = "Please enter correct House No./Floor/Building";
        setIsError(true);
        return;
      }
    }
    if (locality === "") {
      errMsg.current = "Colony / Street / Area  is required";
      setIsError(true);
      return;
    } else {
      if (!addressPattern.test(locality)) {
        errMsg.current = "Please enter correct Colony/Street/Area";
        setIsError(true);
        return;
      }
    }
    if (city === "") {
      errMsg.current = "City is required";
      setIsError(true);
      return;
    } else {
      if (!addressPattern.test(city)) {
        errMsg.current = "Please enter a correct city";
        setIsError(true);
        return;
      }
    }
    if (!landmarkPattern.test(landmark)) {
      errMsg.current = "Please enter correct landmark";
      setIsError(true);
      return;
    }

    setLoading(true);
    let resObj = {
      name: name,
      phoneNo: parseInt(number),
      countryCode: parseInt(countryCode),
      line1: sublocality,
      line2: locality,
      landmark: landmark,
      district: zila,
      state: rajya,
      city: city,
      pincode: parseInt(pincode),
      patientId: memberId != -1 ? memberId : patientProfile.id,
      isDefault: true,
      type: addType === 1 ? "Home" : addType === 2 ? "Work" : "Other",
    };
    if (memberId != -1) {
      const onResponse = (res) => {
        setLoading(false);
        handleClose(1, res);
      };

      if (isEdit) {
        familyPlanService
          .updateFamilyMemberAddress(memberId, address.id, resObj)
          .then((res) => {
            onResponse(res.data);
          })
          .catch((err) => {
            setLoading(false);
          });
      } else {
        familyPlanService
          .createFamilyMemberAddress(memberId, resObj)
          .then((res) => {
            onResponse(res.data);
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    } else {
      familyPlanService
        .postAddress(resObj, isEdit, address.id)
        .then((res) => {
          setLoading(false);
          handleClose(1, res.data);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const handleImportAddress = () => {
    let currentAddr = addressBook[selectedAdd];
    setName(currentAddr.name);
    setAddType(
      currentAddr.type === "Home" ? 1 : currentAddr.type === "Work" ? 2 : 3
    );
    setCity(currentAddr.city);
    setRajya(currentAddr.state);
    setZila(currentAddr.district);
    setLandmark(currentAddr.landmark || "");
    setNumber(currentAddr.phoneNo);
    setCountryCode(currentAddr.countryCode);
    setSubLocality(currentAddr.line1);
    setLocality(currentAddr.line2);
    setPincode(currentAddr.pincode);
    setLayout(1);
    // setLoading(false);
  };
  const handleImportClick = () => {
    setOpenImpDiag((prevState) => {
      return !prevState;
    });
  };
  const changeLayoutToImport = () => {
    setLoading(true);
    setOpenImpDiag(false);

    let id = patientProfile.id;
    if (id) {
      familyPlanService
        .getAllAddress(-1)
        .then((result) => {
          let res = result.data;
          if (res && res.length === 0) {
            errMsg.current = "Primary user address book is empty!";
            setIsError(true);
            setLoading(false);
          } else {
            setAddressBook([...res]);
            setLayout(2);
            setLoading(false);
          }
        })
        .catch((err) => {
          errMsg.current = "Something went wrong while importing.";
          setIsError(true);
          setLoading(false);
        });
    } else {
      errMsg.current = "Something went wrong while importing.";
      setIsError(true);
    }
  };
  const getDistrictFromPincode = useCallback((pin) => {
    familyPlanService
      .getDistrictFromPincode(pin)
      .then((result) => {
        const res = result.data;
        if (res && res.district && res.state) {
          setZila(res.district);
          setRajya(res.state);
        }
      })
      .catch((err) => {});
  }, []);

  return (
    <div className="edit-address-container">
      <ErrorComponent
        show={isError}
        msg={errMsg.current}
        errorClose={() => {
          setIsError(false);
        }}
      />

      <div className="edit-address-header">
        <img
          src={arrowLeft}
          className="edit-address-header-backIMG"
          onClick={() => {
            handleClose(0);
          }}
        />
      </div>

      {layout === 1 && (
        <div className="edit-address-layout-1-container">
          <div className="edit-address-layout-1-section-1">
            <p className="editAddressf16">Address</p>
            {!isEdit && memberId !== -1 && (
              <div
                className="edit-address-layout-1-section-1-options"
                onClick={handleImportClick}
              >
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
            {openImpDiag && (
              <div className="edit-address-open-import">
                <button onClick={changeLayoutToImport}>Import</button>
              </div>
            )}
          </div>

          <div className="edit-address-layout-1-section-2 edit-address-common-section">
            <label>
              Address type<span className="required-mark-class">*</span>
            </label>
            <div className="edit-address-section-2-options">
              <div
                className={`edit-address-section-2-option-ele ${
                  addType === 1 && "option-selected"
                }`}
                onClick={() => {
                  setAddType(1);
                }}
              >
                <span>Home</span>
              </div>
              <div
                className={`edit-address-section-2-option-ele ${
                  addType === 2 && "option-selected"
                }`}
                onClick={() => {
                  setAddType(2);
                }}
              >
                <span>Work</span>
              </div>
              <div
                className={`edit-address-section-2-option-ele ${
                  addType === 3 && "option-selected"
                }`}
                onClick={() => {
                  setAddType(3);
                }}
              >
                <span>Other</span>
              </div>
            </div>
          </div>
          <div className="edit-address-general-section">
            <label>
              Full name<span className="required-mark-class">*</span>
            </label>
            <input
              type="text"
              placeholder="Full Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="edit-address-general-section">
            <label>
              Phone number<span className="required-mark-class">*</span>
            </label>
            <div className="edit-address-section-select">
              <p className="edit-address-section-select-country-code">+91-</p>
              <input
                type="text"
                placeholder="Phone No."
                value={number}
                onPaste={(e) => {
                  e.preventDefault();
                }}
                onCopy={(e) => {
                  e.preventDefault();
                }}
                onCut={(e) => {
                  e.preventDefault();
                }}
                maxLength={10}
                onChange={(e) => {
                  setNumber(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="edit-address-general-section">
            <label>
              Pincode<span className="required-mark-class">*</span>
            </label>
            <input
              type="text"
              placeholder="xxxxxx"
              value={pincode}
              maxLength={6}
              onChange={(e) => {
                setPincode(e.target.value);

                if (e.target.value.length === 6) {
                  getDistrictFromPincode(e.target.value);
                }
              }}
            />
          </div>

          <div className="edit-address-general-section-grouped">
            <div className="edit-address-general-section edit-address-district-section">
              <label>
                District<span className="required-mark-class">*</span>
              </label>
              <input
                type="text"
                value={zila}
                onChange={(e) => setZila(e.target.value)}
                placeholder="District"
              />
            </div>
            <div className="edit-address-general-section">
              <label>
                State<span className="required-mark-class">*</span>
              </label>
              <input
                type="text"
                placeholder="State"
                value={rajya}
                onChange={(e) => {
                  setRajya(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="edit-address-general-section">
            <label>
              {`House No. / Floor / Building`}
              <span className="required-mark-class">*</span>
            </label>
            <input
              type="text"
              placeholder="House No. / Floor / Building"
              value={sublocality}
              onChange={(e) => {
                setSubLocality(e.target.value);
              }}
            />
          </div>
          <div className="edit-address-general-section">
            <label>
              {`Colony / Street / Area`}
              <span className="required-mark-class">*</span>
            </label>
            <input
              type="text"
              placeholder="Colony / Street / Area"
              value={locality}
              onChange={(e) => {
                setLocality(e.target.value);
              }}
            />
          </div>
          <div className="edit-address-general-section">
            <label>Landmark</label>
            <input
              type="text"
              placeholder="Landmark "
              value={landmark}
              onChange={(e) => {
                setLandmark(e.target.value);
              }}
            />
          </div>
          <div className="edit-address-general-section">
            <label>
              City <span className="required-mark-class">*</span>
            </label>
            <input
              type="text"
              placeholder="City "
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
            />
          </div>

          <button
            className="common-global-button edit-address-save-button"
            onClick={handleAddressSave}
            disabled={
              pincode === "" ||
              rajya === "" ||
              city === "" ||
              zila === "" ||
              number === "" ||
              sublocality === "" ||
              locality === "" ||
              name === ""
            }
          >
            Save
          </button>
        </div>
      )}
      {layout === 2 && (
        <div className="edit-address-layout-2-container">
          <div className="edit-address-layout-2-main">
            {addressBook.map((addr, index) => {
              return (
                <div className="address-book-element" key={addr.id}>
                  <div className="address-book-element-top">
                    <img
                      src={homeIcon}
                      className="address-book-element-top-type"
                    />
                    <p className="address-book-element-top-name">{addr.name}</p>
                    <div
                      className="address-book-element-top-threeIcon"
                      onClick={() => {
                        setSelectedAdd(index);
                      }}
                    >
                      {selectedAdd === index ? (
                        <img
                          src={filledCheckBox}
                          className="edit-address-import-checkobx-img"
                        />
                      ) : (
                        <img
                          src={emptyCheckBox}
                          className="edit-address-import-checkobx-img"
                        />
                      )}
                    </div>
                  </div>
                  <div className="address-book-element-bottom">
                    <p>
                      {`${addr.line1},${addr.line2} ${
                        addr.landmark ? addr.landmark : ""
                      }`}
                      <br />
                      {`${addr.city},${addr.state}-${addr.pincode}`}
                    </p>
                    <p>Phone number- {addr.phoneNo}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <button
            className="common-global-button edit-address-layout-2-button"
            onClick={handleImportAddress}
          >
            Import address
          </button>
        </div>
      )}
      {loading && (
        <div className="edit-add-address-overview-loader">
          <CircularProgress className="ion-router-circular-progress"></CircularProgress>
        </div>
      )}
    </div>
  );
}

export default EditAddAddress;
