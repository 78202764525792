import React from "react";

export const FeatureCard = ({
  accentColor,
  title,
  subtitle1,
  link,
  offertext,
  icon,
  tag,
  showFeatureDetail,
}) => {
  const style = {
    borderBottom: `5px solid ${accentColor}`,
  };
  return (
    <div
      className="feature-card"
      style={style}
      onClick={() => {
        showFeatureDetail(tag, link, subtitle1);
      }}
    >
      <img src={icon} alt=""></img>
      <div className="feat-title">{title}</div>
      <div className="feat-subtitle text-center">{subtitle1}</div>
      <div className="offer-text">{offertext}</div>
      {/* <div className="feat-subtitle">{subtitle2}</div> */}
    </div>
  );
};
