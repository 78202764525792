import APIService from "./ApiService";
import AuthenticationService from "./AuthenticationService";
import Dexie from "dexie";
import APIConfig from "./APIConfig";
import { interval, BehaviorSubject } from "rxjs";

import axiosClient from "../app/axiosConfig";
import axios from "axios";

export default class EventLoggerService {
  constructor(props) {}

  _getDeviceId() {
    let cliId = localStorage.getItem("cli_id");
    if (!cliId) {
      // create cli id
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < 20; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      cliId = result;
      localStorage.setItem("cli_id", result);
    }

    return cliId;
  }

  log(event, metadata, user = null) {
    let logEvent = {
      userId: user ? user.id : 0,
      event: event,
      severity: "INFO",
      description: event,
      device: this.device,
      metadata: metadata,
      module: {
        service: this.platform, //ios or web package name here
        file: "", //module ? module.file : "",
        method: "", //module ? module.method : "",
      },
    };
    const url = APIConfig.events.logEvent;
    try {
      axiosClient
        .post(url, { events: [{ ...logEvent }] })
        .then((res) => {})
        .catch((err) => {});
    } catch (err) {}
  }
}
