import ApiService from "./ApiService";
import APIConfig from "./APIConfig";
import UserService from "./UserService";
import AuthenticationService from "./AuthenticationService";
import Helper from "../utils/Helper";
import axiosClient from "../app/axiosConfig";

class ReportingService {
  constructor() {
    this.apiService = new ApiService();

    this.helper = new Helper();
    this.authenticationService = new AuthenticationService();
    this.user = this.authenticationService.getUserProfile();
  }

  getReportsByDate = async (dateInDDMMYYYY, patientId) => {
    const url = APIConfig.report.getByDate.format(patientId, dateInDDMMYYYY);
    return axiosClient.get(url);
  };
  _postProcessResponse(response) {
    let itemsArr = [];
    if (response && response.data && response.data.length > 0) {
      itemsArr = response.data[0].data.map((reportItem) => {
        let timeEntities = reportItem.time.split(" ");
        let time = timeEntities[1];
        return {
          ...reportItem,
          hour: time.split(":")[0],
          minute: time.split(":")[1],
          dayNight: timeEntities[2],
        };
      });
    }
    return itemsArr;
  }
  saveAndUpdateReport(report) {
    let url = APIConfig.report.save;

    let isExists = report.data && report.data.length > 0 && report._id;
    console.log("report recieved to me is ", report);
    if (!isExists) {
      return axiosClient.post(url + "/", report);
    } else {
      url = `${url}/${report._id}`;
      return axiosClient.patch(url, report);
    }
  }
}
let reportingServices = new ReportingService();
export default reportingServices;
