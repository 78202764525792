import React from "react";
import TickImage from "../../../../assets/images/tick.png";
import moment from "moment";

export default function CommonCardMessage({ time, title }) {
  if (!time) {
    time = Date.now();
  }

  let date = moment(time).format("dddd,Do MMMM ");

  return (
    <div className="message-content">
      <div className="tick-container">
        <img className="tick-icon-card" alt="tick" src={TickImage} />
      </div>
      <div className="date-details">
        <div className="date-details-wrapper">
          <div className="text-left text-sm">{title} Submitted for</div>
          <div className="text-left text-sm">{date}</div>
        </div>
      </div>
    </div>
  );
}
