import { useState } from "react";
import check from "../../../assets/images/Digit/check.svg";
import upArrow from "../../../assets/images/upArrow_img.png";
import dompurify from "dompurify";

export function FAQ({ title, children }) {
  const [open, setOpen] = useState(false);

  return (
    <div className="diabetes-faq-container">
      <div className="diabetes-faq-title" onClick={() => setOpen(!open)}>
        <h2>{title}</h2>
        <img src={upArrow} alt="Open Faq" className="down-chevron" />
      </div>
      {open && (
        <p
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "rgba(55, 65, 81, 1)",
            lineHeight: "22px",
          }}
          className="diabetes-faq-content"
          dangerouslySetInnerHTML={{ __html: children }}
        ></p>
      )}
    </div>
  );
}

export const Check = ({ content }) => (
  <div className="check-container-css">
    <img className="mt-1" src={check} alt="check" />
    <p
      dangerouslySetInnerHTML={{ __html: dompurify.sanitize(content) }}
      className="check-para"
    ></p>
  </div>
);

export const BlogPreviewAlt = ({ title, link, image, body }) => (
  <a
    href={link}
    target="_blank"
    className="flex text-black no-underline flex-shrink-0 flex-col items-start w-64 text-left blog-container"
    rel="noopener"
  >
    <img
      width="256"
      height="224"
      src={image}
      alt={title}
      className="w-64 h-56 object-cover rounded-lg"
    />
    <h2 className="font-bold text-lg mt-3 mb-4">{title.rendered}</h2>
    <p className="text-gray-600 text-sm">{body}</p>
  </a>
);
