import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
function ErrorComponent({
  show,
  errorClose,
  msg,
  isPositive,
  displayTimer,
  isBottom,
}) {
  const [display, setDisplay] = useState(show);
  const [errMsg, setErrMsg] = useState(msg);
  const isOffline = useSelector((store) => store.tab.ui.isOffline);
  const timerRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const handleShowError = (msg) => {
    setDisplay(true);
    setErrMsg(msg);
    timerRef.current = setTimeout(
      () => {
        setDisplay(false);
        if (errorClose) {
          errorClose();
        }
      },
      displayTimer ? displayTimer : 4000
    );
  };
  useEffect(() => {
    if (show) {
      handleShowError(msg);
    }
  }, [show, msg]);

  return (
    <div>
      {show ? (
        <div
          className={`global-error-component-container ${
            isPositive ? "global-error-comp-bg-green-class" : ""
          } ${
            display
              ? "global-error-component-show"
              : "global-error-component-hide"
          } ${isBottom ? "fixed-bottom-class" : ""}`}
        >
          <span>{isOffline ? "No internet connection" : errMsg}</span>
        </div>
      ) : null}
    </div>
  );
}

export default ErrorComponent;
