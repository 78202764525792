import React, { useRef } from "react";
import { useSelector } from "react-redux";
import dummyImg from "../../assets/images/dummy-recipe-image.png";
import { getUrlWithToken } from "../../utils/helperMethods";

export default function SecureImage({
  defaultImage,
  classN,
  src,
  height,
  onClick,
}) {
  const token = useSelector((store) => store.tab.docToken?.token);
  let errorCount = useRef(1);

  const onErrorLoadingImage = (e) => {
    if (errorCount.current) {
      e.target.src = defaultImage;
      errorCount.current = 0;
    } else {
      e.target.src = defaultImage ? defaultImage : dummyImg;
    }
    e.target.onerror = null;
  };

  return token ? (
    <img
      alt=""
      async
      onClick={(e) => {
        onClick && onClick(e);
      }}
      className={classN}
      src={getUrlWithToken(src, token)}
      height={height}
      onError={(e) => onErrorLoadingImage(e)}
      key={src}
    ></img>
  ) : (
    <div className={classN}></div>
  );
}
