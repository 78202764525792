import swetaImage from "../../../assets/images/Dr.R.sweta.png";
import manviImage from "../../../assets/images/Dr.Manvi.png";
import RituImage from "../../../assets/images/Dr.Ritu.png";
import arrowRightImage from "../../../assets/images/arrowhead-right.png";
import BookCard from "./BookCard";

export default function BookExpertConsultaionCard() {
  const data = [
    {
      id: 1,
      image: swetaImage,
      title: "Dr Rebecca Shweta Gladwin",
      text: "MBBS, MD- Medicine, PG- Diabetology, PG- Endocrinology  ",
      fee: "Fee",
      feeDeleteValue: " ₹1199 ",
      feeValue: "₹0",
      detail: "Know more",
      button: "Book Now",
      booklink: "/risk-Assesment",
    },
    {
      id: 2,
      image: manviImage,
      title: "Dr. Manvi Mukherjee",
      text: "General Physician ",
      fee: "Fee",
      feeDeleteValue: " ₹1199 ",
      feeValue: "₹0",
      detail: "Know more",
      button: "Book Now",
      booklink: "/risk-Assesment",
    },
    {
      id: 3,
      image: RituImage,
      title: "Ritu Sheokand",
      text: "Nutritionist (Weight & Diet Management)  ",
      fee: "Fee",
      feeDeleteValue: " ₹1199 ",
      feeValue: "₹0",
      detail: "Know more",
      button: "Book Now",
      booklink: "/risk-Assesment",
    },
  ];
  return (
    <>
      <div className="p-4 " style={{ color: "#171A21" }}>
        <div className="font-bold text-base leading-5">
          Book expert tele-consultation
        </div>
        {data.map((v) => {
          return <BookCard v={v} />;
        })}
      </div>
    </>
  );
}
