import swetaImage from "../../../assets/images/bookDiagnostics3.png";
import manviImage from "../../../assets/images/bookDiagnostics2.png";
import RituImage from "../../../assets/images/bookDiagnostics1.png";
import BookCard from "./BookCard";

export default function BookDiagnosticsCard({ bookexpert, text }) {
  const data = [
    {
      id: 1,
      image: swetaImage,
      title: "Full body health checkup",
      text: "Includes 70+ tests      ",
      fee: "Price:",
      feeDeleteValue: "₹1199 ",
      feeValue: "₹799",
      detail: "Know more",
      button: "Book Now",
      link: "/body-health-checkup",
      booklink: "/risk-Assesment",
    },
    {
      id: 2,
      image: manviImage,
      title: "Annual health checkup",
      text: "Includes 55+ tests      ",
      fee: "Price:",
      feeDeleteValue: "  ₹899 ",
      feeValue: "₹599",
      detail: "Know more",
      button: "Book Now",
      booklink: "/risk-Assesment",
    },
    {
      id: 3,
      image: RituImage,
      title: "Annual health checkup",
      text: "Includes 45+ tests      ",
      fee: "Price:",
      feeDeleteValue: "  ₹899 ",
      feeValue: "₹599",
      detail: "Know more",
      button: "Book Now",
      booklink: "/risk-Assesment",
    },
  ];
  return (
    <>
      <div className="p-4 " style={{ color: "#171A21" }}>
        <div className="font-bold text-base leading-5">
          Book Diagnostic test
        </div>
        {data.map((v) => {
          return <BookCard v={v} />;
        })}
      </div>
    </>
  );
}
