import React, { useCallback, useEffect } from "react";
import { Drawer } from "@mui/material";
import arrowLeftOrange from "../../../assets/icons/arrow-left-orange.png";
import careTeamAvatar from "../../../assets/images/avatar.png";
export default function DoctorDetailModal({ open, onClose, doctor, docToken }) {
  useEffect(() => {
    document.addEventListener("ionBackButton", careTeamHandleBack);

    return () => {
      document.removeEventListener("ionBackButton", careTeamHandleBack);
    };
  }, []);

  const careTeamHandleBack = useCallback((ev) => {
    ev.detail.register(10000, () => {
      onClose();
    });
  }, []);
  return (
    <Drawer
      role="presentation"
      className={" signup-container menu-partial-medium chatRoom__drawer"}
      anchor="bottom"
      disableEnforceFocus
      open={open}
      onClose={onClose}
    >
      <div className="fullscreen-dr-card">
        <div className="fs-doctor-cards">
          <div className="fs-dr-card-wrapper responsive-600">
            <button onClick={onClose}>
              <img
                src={arrowLeftOrange}
                desc="patial menu icon"
                alt="patial menu icon"
              />
            </button>
            <div>
              <div className="doc__img__wrapper">
                <img
                  alt=""
                  src={
                    doctor.profile_image
                      ? `${doctor.profile_image}?token=${docToken}`
                      : careTeamAvatar
                  }
                  className="fs-doctor-image"
                  onError={() => {
                    // onE = null;
                    doctor.profile_image = careTeamAvatar;
                  }}
                />
              </div>
              <div className="fs24 fw-light">{doctor.name}</div>
              <div className="fs16 chat__room__opendoc__title">
                {doctor.title}
              </div>
            </div>
            <div className="fs14 chat__room__opendoc__desc">
              {doctor.description}
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}
