import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UserService from "../../services/UserService";

const initialState = {
  registrationStatus: 0,
  registerData: null,
  ui: {
    loading: false,
    error: false,
    errorMsg: "",
    email: "",
    sourceCodeOpen: false,
    sourceModal: false,
    shouldShowEmail: true,
    source: 0,
    sourceAdded: false,
    whitelisted: false,
    pageLoader: true,
    doctorCode: "",
    doctor: {},
    documentId: undefined,
  },
};
// export const registerUser = createAsyncThunk(
//   "register/user",
//   async (data, { rejectWithValue }) => {
//     try {
//       const response = await UserService.registerUser(data.body, data.user);

//       return response;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

export const checkWhitelistedStatus = createAsyncThunk(
  "register/checkWhitelisted",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserService.getClientInfo(data);

      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateSouceOnTentacle = createAsyncThunk(
  "register/updateSourceOnTentacle",
  async (data, { rejectWithValue }) => {
    try {
      const response = UserService.postSource(data);
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    updateSourceAndCloseModal: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          sourceAdded: true,
          sourceModal: false,
          sourceCodeOpen: action.payload !== 3 ? action.payload : 0,
          shouldShowEmail: true,
          whitelisted: false,
          doctorCode: "",
          documentId: undefined,
          source: action.payload,
        },
      };
    },
    updateSourceCodeOpen: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          sourceCodeOpen: true,
        },
      };
    },
    generalStateUpdate: (state, action) => {
      return {
        ...state,
        [action.payload.property]: action.payload.data,
      };
    },
    updateRegistrationEmail: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          email: action.payload,
        },
      };
    },
    updateLoader: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: action.payload,
        },
      };
    },
    updateAddedSource: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          sourceModal: true,
          shouldShowEmail: true,
          email: "",
        },
      };
    },
    updateErrorState: (state, action) => {
      let errMsg = action.payload.msg
        ? action.payload.msg
        : "Something went wrong";

      return {
        ...state,
        ui: {
          ...state.ui,
          error: action.payload.data,
          errorMsg: action.payload.data ? errMsg : "",
          loading: action.payload.data ? false : state.loading,
        },
      };
    },
    updateWhiteListedFlow: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          shouldShowEmail: true,
          sourceAdded: false,
          whitelisted: true,
        },
      };
    },
    handleOrganisationCloseSuccess: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          sourceCodeOpen: 0,
          shouldShowEmail: false,
          sourceAdded: true,
          whitelisted: false,
          doctorCode: "",
          documentId: undefined,
          email: action.payload.email,
        },
      };
    },
    handleOrganisationCloseFailure: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          sourceModal: true,
          sourceCodeOpen: 0,
        },
      };
    },
    onCloseDrawerSuccess: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          doctor: action.payload.doctor,
          sourceCodeOpen: 0,
          doctorCode: action.payload.doctorCode,
          documentId: action.payload.documentId,
          sourceAdded: true,
          sourceModal: false,
          shouldShowEmail: true,
          whitelisted: false,
        },
      };
    },
    onCloseDrawerFailure: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          sourceModal: true,
          sourceCodeOpen: 0,
          email: "",
        },
      };
    },
    registerUser: (state, action) => {
      return {
        ...state,
        registerData: {
          ...action.payload.body,
        },
      };
    },
    clearRegisterData: (state, action) => {
      return {
        ...state,
        registerData: null,
      };
    },
  },
  extraReducers: {
    // [registerUser.pending]: (state, action) => {
    //   return {
    //     ...state,
    //     ui: {
    //       ...state.ui,
    //       loading: true,
    //     },
    //   };
    // },
    // [registerUser.fulfilled]: (state, action) => {
    //   return {
    //     ...state,

    //     registrationStatus: 1,
    //     ui: {
    //       ...state.ui,
    //       loading: false,
    //     },
    //   };
    // },
    // [registerUser.rejected]: (state, action) => {
    //   return {
    //     ...state,
    //     ui: {
    //       ...state.ui,
    //       error: true,
    //       loading: false,
    //       errorMsg: "Something went wrong, Please try again later.",
    //     },
    //   };
    // },
    [checkWhitelistedStatus.pending]: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          pageLoader: true,
        },
      };
    },
    [checkWhitelistedStatus.fulfilled]: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          pageLoader: false,
          whitelisted: true,
          sourceCodeOpen: 0,
          sourceAdded: false,
          sourceModal: false,
        },
      };
    },
    [checkWhitelistedStatus.rejected]: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          pageLoader: false,
          whitelisted: false,
          sourceModal: true,
        },
      };
    },
  },
});

export const {
  updateSourceAndCloseModal,
  updateAddedSource,
  updateLoader,
  updateSourceCodeOpen,
  updateErrorState,
  onCloseDrawerFailure,
  onCloseDrawerSuccess,
  handleOrganisationCloseFailure,
  handleOrganisationCloseSuccess,
  updateRegistrationEmail,
  generalStateUpdate,
  updateWhiteListedFlow,
  registerUser,
  clearRegisterData,
} = registrationSlice.actions;
export default registrationSlice.reducer;
