import { IonContent, IonPage, IonSpinner } from "@ionic/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import AuthenticationService from "../../services/AuthenticationService";
import digitService from "../../services/DigitService";
import { updateUserFromAutoLogin } from "../login/loginSlice";

function AutoLogin() {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    let url = window.location.search;
    let searchParams = new URLSearchParams(url);
    let redirect = searchParams.get("redirect");
    let phoneNo = searchParams.get("user_id");
    let token = searchParams.get("idToken");

    if (phoneNo && token) {
      digitService
        .digitAutoLogin(phoneNo, token)
        .then((res) => {
          const authService = new AuthenticationService();
          const data = res.data;
          let profile = data.user;
          authService.activateLogin(data);
          dispatch(
            updateUserFromAutoLogin({ user: data.user, token: data.token })
          );
          history.replace("/tab");
        })
        .catch((err) => {
          //TODO clear cookies as well here
          history.replace("/login");
        });
    } else {
      history.replace("/login");
    }
  }, []);

  return (
    <IonPage>
      <IonContent>
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IonSpinner />
        </div>
      </IonContent>
    </IonPage>
  );
}

export default AutoLogin;
