import successGreenImage from "../../../assets/images/successGreen.png";
import calenderImage from "../../../assets/images/calender.png";
import clockImage from "../../../assets/images/clock-black.png";
import { IonContent, IonPage } from "@ionic/react";
import { useHistory, useLocation, useParams } from "react-router";
export const BookingSuccessfully = () => {
  const history = useHistory();
  const location = useLocation();

  let params = new URLSearchParams(location.search);

  return (
    <IonPage>
      <IonContent>
        <div className="p-8 bg-white h-full">
          <div className="w-full flex justify-center pt-40">
            <img src={successGreenImage} className="h-20 w-16 "></img>
          </div>
          <div className="text-center font-medium text-2xl  text-lightblack py-4">
            Your booking has been placed successfully.
          </div>

          <div className="flex justify-between mx-14 text-lightblack items-center">
            <div className=" flex items-center">
              <img src={calenderImage} alt="" className="w-4 h-4 " />
              <span className="px-2  font-semibold text-xs leading-5">
                {params.get("date")}
              </span>
            </div>
            <div className=" flex items-center">
              <img src={clockImage} alt="" className="w-4 h-4" />
              <span className="px-2 font-semibold text-xs leading-5">
                {params.get("time")}
              </span>
            </div>
          </div>

          <button
            className="w-full bg-zyple text-white p-4 text-xl   font-semibold rounded-full text-center mt-28"
            onClick={() => {
              history.replace("/navigo?currScreen=1");
            }}
          >
            Go back
          </button>
        </div>
      </IonContent>
    </IonPage>
  );
};
