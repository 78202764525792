import React from "react";
import noAddress from "../../../assets/images/no-address.svg";

export default function NoAddressFound({ handleAddressAddition }) {
  return (
    <div className="edit-address-no-add-layout">
      <img
        src={noAddress}
        className="edit-address-no-add-img"
        alt="no address"
      />
      <p>Your address book is empty!</p>
      <button onClick={handleAddressAddition} className="common-global-button">
        Add address
      </button>
    </div>
  );
}
