const tags = ["script", "iframe", "img", "html"];
class Helper {
  constructor() {
    this._initializeNaturalExtensions();
  }

  isJSON(obj) {
    try {
      JSON.parse(obj);
      return true;
    } catch (error) {
      return false;
    }
  }
  _initializeNaturalExtensions() {
    if (!String.prototype.format) {
      String.prototype.format = function () {
        var args = arguments;
        return this.replace(/{(\d+)}/g, function (match, number) {
          return typeof args[number] !== "undefined" ? args[number] : match;
        });
      };
    }
  }
  checkHtmlInjection(text) {
    let result = true;
    tags.forEach((txt) => {
      let startTag = "<" + txt + ">";
      let endTag = "</" + txt + ">";
      if (text.includes(startTag) || text.includes(endTag)) {
        result = false;
      }
    });
    return result;
  }
}

export default Helper;
