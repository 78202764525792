import React from "react";
import { useSelector } from "react-redux";
import quoationMarks from "../../../assets/icons/quotaion-marks.png";
export default function PremiumPatientStories() {
  const testimonials = useSelector((store) => store.premium.testimonials);
  return (
    <div className="section-wrapper background-color-white-class padding-Bottom-25">
      <h2>Patient success stories</h2>
      <div className="scroll-div">
        {testimonials.map((patient) => (
          <div className="patient-card">
            <img className="quotes" src={quoationMarks} alt="icon" />
            <h3>{patient.header}</h3>
            <p>{patient.body}</p>
            <div>
              <img
                className="patient-photo"
                src={patient.image}
                alt="patient-img"
              />
              <div>
                <p> {patient.name}</p>
                <span>
                  {patient.age},&nbsp;{patient.region}
                </span>
              </div>
            </div>
          </div>
        ))}
        <div className="pseudo-margin"> &nbsp;</div>
      </div>
    </div>
  );
}
