export default function ChatBubble({ message, onClickBubble }) {
  const form = message.content.form;

  return (
    <>
      {form && form.options && form.options.length > 1 && (
        <div className="possible-actions-state-machine">
          {form.options.map((bubble, index) => {
            return (
              <div
                onClick={() => onClickBubble(bubble)}
                className="text-sm button-bubble font-semibold  button-bubble-bounce"
              >
                {bubble}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
