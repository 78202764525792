import React from "react";
import "./message.css";

const Message = (props) => {
  const { handleClick, hideMessage = false } = props;
  return (
    <div
      className={
        hideMessage
          ? "hide-common-message common-message-content"
          : "common-message-content"
      }
    >
      <div className="message-body">{props.message}</div>
      <div onClick={handleClick} className="message-button">
        {props.button}
      </div>
    </div>
  );
};

export default Message;
