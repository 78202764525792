import React from "react";
import "./partialMenu.scss";

import RippleButton from "../../../../components/common/RippleButton";
export default class PartialMenuItem extends React.Component {
  renderItem() {
    const { text, icon, subtext } = this.props;
    return (
      <React.Fragment>
        <div className="partial-icon-menu">
          <img className="partial-menu-icon" src={icon} />
        </div>
        <div className={`menu-item-text ${subtext ? "mn-10" : ""}`}>
          {text}
          {subtext && <p className="menu-sub-text">{subtext}</p>}
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <RippleButton nospace className="menu-item">
        {this.renderItem()}
      </RippleButton>
    );
  }
}
