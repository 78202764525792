//Create Store For Redux

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import appReducer from "./appSlice";
import eventReducer from "./eventSlice";
import loginReducer, { updateUser } from "../containers/login/loginSlice";
import tabReducer from "../containers/tab/tabSlice";
import chatReducer from "../containers/chat/chatSlice";
import reportingReducer from "../containers/reporting/reportSlice";
import vitalReducer from "../containers/vitals/vitalSlice";
import documentReducer from "../containers/documents/documentSlice";
import goalReducer from "../containers/healthGoals/goalSlice";
import homeReducer from "../containers/HomePage/homePageSlice";
import healthPlanReducer from "../containers/healthPlan/healthPlanSlice";
import assessmentReducer from "../containers/assesment/assessmentSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist-indexeddb-storage";
import thunk from "redux-thunk";
import registrationReducer from "../containers/registration/registrationSlice";
import familyReducer from "../containers/familyInfo/familySlice";
import stepathonSlice from "../containers/stepathon/stepathonSlice";
import rewardReducer from "../../src/containers/zylaWallet/rewardPages/rewardSlice";

import walletSlice from "../containers/zylaWallet/walletSlice";
import navigoSlice from "../containers/navigo/navigoSlice";
import addressSlice from "../containers/address/addressSlice";
import SessionManager from "../SessionManager";
import vitalsChartSlice from "../containers/vitalsChart/vitalsChartSlice";
import premiumSlice from "../containers/premium/premiumSlice";
import meditationSlice from "../containers/meditation/meditationSlice";
import medicineSlice from "../containers/medicineOrder/medicineSlice";
import AuthenticationService from "../services/AuthenticationService";
import rewardSlice from "../containers/zylaWallet/rewardPages/rewardSlice";
// import indexDBStorage from "redux"
const sessionManager = new SessionManager();
const persistConfig = {
  key: "root",
  storage: storage("zyla.app.db"),

  blacklist: ["address", "medicine","stepathon","reward"],
  timeout: 0,
};

export const authentication = (store) => (next) => (action) => {
  if (action.type === "login/verifyOTP/fulfilled") {
    const d = action.payload.data;
    const authService = new AuthenticationService();
    authService.activateLogin(d);
    // sessionManager.setCookie("uid", d.user.id);
    // if (d.user.patientProfile) {
    //   sessionManager.setCookie("pid", d.user.patientProfile.id);
    // }
    // sessionManager.setCookie("phone_no", d.user.phoneNo);
    // sessionManager.setCookie("token", d.token.accessToken);
    // sessionManager.setCookie("etoken", d.token.expiryToken);
    // sessionManager.setCookie("client", "patient_android");
    // localStorage.setItem("zy.ACCESS_TOKEN", d.token);
  } else if (action.type === "register/user/fulfilled") {
    const d = action.payload.data;
    if (d.user.patientProfile) {
      localStorage.setItem("pid", d.user.patientProfile.id);
      // sessionManager.setCookie("pid", d.user.patientProfile.id);
    }
    store.dispatch(updateUser(d.user));
  }

  return next(action);
};

const vitalPersistConfig = {
  key: "vitals",
  storage: storage("zyla.app.db"),
  blacklist: ["ui"],
};
const premiumPersistConfig = {
  key: "premium",
  storage: storage("zyla.app.db"),
  blacklist: ["ui"],
};
const meditationPersistConfig = {
  key: "meditation",
  storage: storage("zyla.app.db"),
  blacklist: ["ui"],
};
const healthPlanPersistConfig = {
  key: "healthPlan",
  storage: storage("zyla.app.db"),
  blacklist: ["ui"],
};
const tabPersistConfig = {
  key: "tab",
  storage: storage("zyla.app.db"),
  blacklist: ["ui"],
};
const chatPersistConfig = {
  key: "tab",
  storage: storage("zyla.app.db"),
  blacklist: ["ui"],
};
const documentPersistConfig = {
  key: "document",
  storage: storage("zyla.app.db"),
  blacklist: ["ui"],
};
const vitalChartPersistConfig = {
  key: "vitalsChart",
  storage: storage("zyla.app.db"),
  blacklist: ["ui"],
};
const homePersistConfig = {
  key: "home",
  storage: storage("zyla.app.db"),
  blacklist: ["ui"],
};
const loginPersistConfig = {
  key: "login",
  storage: storage("zyla.app.db"),
  blacklist: ["ui"],
};

const ReportingPersistConfig = {
  key: "reporting",
  storage: storage("zyla.app.db"),
  blacklist: ["ui"],
};
const RegistrationPersistConfig = {
  key: "registration",
  storage: storage("zyla.app.db"),
  blacklist: ["ui"],
};
const goalPersistConfig = {
  key: "goals",
  storage: storage("zyla.app.db"),
  blacklist: ["ui"],
};
const assessmentPersistConfig = {
  key: "assessment",
  storage: storage("zyla.app.db"),
  blacklist: ["ui"],
};
const familyInfoPersistConfig = {
  key: "familyInfo",
  storage: storage("zyla.app.db"),
  blacklist: ["ui"],
};
const walletPersistConfig = {
  key: "wallet",
  storage: storage("zyla.app.db"),
  blacklist: ["ui"],
};
const navigoPersistConfig = {
  key: "navigo",
  storage: storage("zyla.app.db"),
  blacklist: ["ui"],
};
const rewardPersistConfig = {
  key: "reward",
  storage: storage("zyla.app.db"),
  blacklist: ["ui"],
};

const baseReducer = combineReducers({
  app: appReducer,

  tab: persistReducer(tabPersistConfig, tabReducer),
  login: persistReducer(loginPersistConfig, loginReducer),
  event: eventReducer,
  chat: persistReducer(chatPersistConfig, chatReducer),
  reporting: persistReducer(ReportingPersistConfig, reportingReducer),
  vitals: persistReducer(vitalPersistConfig, vitalReducer),
  premium: persistReducer(premiumPersistConfig, premiumSlice),
  assessment: persistReducer(assessmentPersistConfig, assessmentReducer),
  goals: persistReducer(goalPersistConfig, goalReducer),
  documents: persistReducer(documentPersistConfig, documentReducer),
  registration: persistReducer(RegistrationPersistConfig, registrationReducer),
  familyInfo: persistReducer(familyInfoPersistConfig, familyReducer),
  vitalsChart: vitalsChartSlice,
  healthPlan: persistReducer(healthPlanPersistConfig, healthPlanReducer),
  wallet: persistReducer(walletPersistConfig, walletSlice),
  navigo: persistReducer(navigoPersistConfig, navigoSlice),
  address: addressSlice,
  home: persistReducer(homePersistConfig, homeReducer),
  // reward: persistReducer(rewardPersistConfig, rewardReducer),
  reward: rewardReducer,
  meditation: persistReducer(meditationPersistConfig, meditationSlice),
  medicine: medicineSlice,
  stepathon: stepathonSlice,
});

export const logoutUtil = () => {
  storage("zyla.app.db").removeItem("persist:app");
  storage("zyla.app.db").removeItem("persist:tab");

  storage("zyla.app.db").removeItem("persist:event");
  storage("zyla.app.db").removeItem("persist:chat");
  storage("zyla.app.db").removeItem("persist:reporting");
  storage("zyla.app.db").removeItem("persist:vitals");
  storage("zyla.app.db").removeItem("persist:premium");

  storage("zyla.app.db").removeItem("persist:assessment");
  storage("zyla.app.db").removeItem("persist:goals");
  storage("zyla.app.db").removeItem("persist:documents");
  storage("zyla.app.db").removeItem("persist:assessment");
  storage("zyla.app.db").removeItem("persist:registration");
  storage("zyla.app.db").removeItem("persist:familyInfo");
  storage("zyla.app.db").removeItem("persist:vitalsChart");
  storage("zyla.app.db").removeItem("persist:healthPlan");
  storage("zyla.app.db").removeItem("persist:wallet");
  storage("zyla.app.db").removeItem("persist:navigo");
  storage("zyla.app.db").removeItem("persist:home");
  storage("zyla.app.db").removeItem("persist:meditation");
  // storage("zyla.app.db").removeItem("persist:reward");
  storage("zyla.app.db").removeItem("persist:login");
  storage("zyla.app.db").removeItem("persist:root");

  localStorage.clear();
  // for all keys defined in your persistConfig(s)
  sessionManager.deleteSession();
};

const rootReducer = (state, action) => {
  if (action.type === "logout") {
    logoutUtil();
    window.isReload=true;
    return baseReducer(undefined, action);
  }
  return baseReducer(state, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk, authentication],
});
export const persistor = persistStore(store);
