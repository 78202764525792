import { IonContent, IonLoading, IonPage } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import offlineVector from "../../assets/images/offline-vector.svg";
import { Network } from "@capacitor/network";
import "./index.scss";
import { useHistory, withRouter } from "react-router";
import { refreshAndSaveToken } from "../../utils/helperMethods";
import ApiService from "../../services/ApiService";
import DocumentService from "../../services/DocumentService";
import AuthenticationService from "../../services/AuthenticationService";
import APIConfig from "../../services/APIConfig";
import UserService from "../../services/UserService";
import CleverTapLogger from "../../clevertapLogger";
import { CircularProgress } from "@mui/material";

const OfflinePage = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const networkTimeout = useRef(null);
  useEffect(() => {

    Network.addListener("networkStatusChange", (status) => {
      if (status.connected) {
        Network.removeAllListeners();
        setLoading(true);

        networkTimeout.current = setTimeout(() => {
          history.replace("/tab/chat");
        }, 3000);
      }
    });
    return () => {
      handleClearInterval();
    };
  }, []);

  const handleClearInterval = () => {
    if (networkTimeout) {
      clearTimeout(networkTimeout.current);
    }
  };

  return (
    <IonPage>
      <IonContent>
        {loading && (
          <CircularProgress className="ion-router-circular-progress"></CircularProgress>
        )}
        {!loading && (
          <div className="offline-page-container">
            <img src={offlineVector} className="offline-page-container-top" />
            <div className="offline-page-container-bottom">
              <p className="offline-page-container-bottom-top">
                You are offline
              </p>
              <p className="offline-page-container-bottom-bottom">
                It seems there is a problem with your connection. Please check
                your connection status and try again.
              </p>
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default OfflinePage;
