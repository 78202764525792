import ApiService from "./ApiService";
import APIConfig from "./APIConfig";
import axiosClient from "../app/axiosConfig";

class NavigoServices {
  constructor() {
    this.apiService = new ApiService();
  }
  //reschedule appointments
  rescheduleAppointments = (body) => {
    let url = APIConfig.navigoDashboard.rescheduleAppointments;
    return axiosClient.post(url, body);
  };

  //update stataus of appointment
  updateAppointmentStatus = (body) => {
    let url = APIConfig.navigoDashboard.updateAppointmentStatus;
    return axiosClient.post(url, body);
  };

  //get time slots
  getTimeSlots = (serviceId, date) => {
    let url = APIConfig.navigoDashboard.getTimeSlots.format(serviceId, date);
    return axiosClient.get(url);
    // return this.apiService.get(url, onResponse, onError);
  };

  //create appointment
  createAppointment = (body) => {
    let url = APIConfig.navigoDashboard.createAppointment;
    return axiosClient.post(url, body);
    // return this.apiService.post(url, body, onResponse, onError);
  };

  //get service by patient id
  getServiceByPatientId = (patientId, serviceType) => {
    let url =
      APIConfig.navigoDashboard.getServicesByPatientId.format(patientId);
    return axiosClient.get(url);
    // return this.apiService.get(url, onResponse, onError);
  };

  //get appointments by patient ID
  getAllAppointmentsByPatientId = (patientID) => {
    let url = APIConfig.navigoDashboard.allAppointments.format(patientID);
    return axiosClient.get(url);
    // return this.apiService.get(url, onResponse, onError);
  };
  //download report api
  getNavigoReport = (url, onResponse, onError) => {
    fetch(url, {
      method: "GET",
    })
      .then((res) => {
        return res.blob();
      })
      .then((data) => onResponse(data))
      .catch((err) => {
        onError(err);
      });
  };

  //get redcliffe time slots
  getRedCliffTimeSlots = (body) => {
    let url = APIConfig.navigoDashboard.redcliffTimeSlot;
    return axiosClient.post(url, body);
  };

  //reschedule appointment
  cancelAndRescheduleLabAppointments = (body) => {
    let url = APIConfig.navigoDashboard.redcliffBooking;
    return axiosClient.put(url, body);
    // return this.apiService.put(url, body, onResponse, onError);
  };

  getAllTimeSlots = (body) => {
    let url = APIConfig.navigoDashboard.generalTimeSlot;
    return axiosClient.post(url, body);
  };

  //check service availability
  checkServiceAvailability = (body) => {
    let url = APIConfig.navigoDashboard.checkAvailability;
    return axiosClient.post(url, body);
  };

  //generate order id for navigo
  generateOrderForNavigo = (body) => {
    let url = APIConfig.navigoDashboard.generateNavigoOrderId;

    return axiosClient.post(url, body);
  };

  //check navigo create booking status
  checkNavigoCreateStatus = (orderId) => {
    let url =
      APIConfig.navigoDashboard.checkAppointCreateStatus.format(orderId);

    return axiosClient.get(url);
  };
}

export default new NavigoServices();
