import React from "react";
import { useSelector } from "react-redux";
export default function PremiumOutcomeCard() {
  const outcomes = useSelector((store) => store.premium.outcomes);
  return (
    <div className="section-wrapper background-color-white-class margin-Top-10">
      <h6>With Zyla’s proprietary TCT approach & technology</h6>
      <h2>
        Lakhs of patients from 500+ cities in India and the world have achieved
      </h2>
      <div className="benefits-wrapper">
        {outcomes &&
          outcomes.map((benefit) => (
            <div className="benefits-div">
              {/* <img src={benefit.image} alt="icon" /> */}
              <p>{benefit.outcome}</p>
            </div>
          ))}
      </div>
    </div>
  );
}
