import styled from "@emotion/styled";

const DateMessageContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-size: 10px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Row = styled.div`
  border-top: 1px solid gainsboro;
  flex: 1;
  display: flex;
`;
const Value = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;
export default function DateMessage({ message }) {
  return (
    <DateMessageContainer>
      <Row></Row>
      <Value>{message.content.message.text}</Value>
      <Row></Row>
    </DateMessageContainer>
  );
}
