import React, { useState } from "react";
import VitalsImage from "../../../../assets/images/banner_vitals_copy.png";
import RequestRoutineImage from "../../../../assets/images/banner_reporting.png";
import CompleteTaskImage from "../../../../assets/images/banner_tasks.png";
import ViewHealthGoals from "../../../../assets/images/group_2.png";
import { logEvent } from "../../../../app/eventSlice";
import dompurify from "dompurify";
import { useEffect } from "react";
import TimeMessage from "./time";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

export default function RequestCard({ message, type }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [text, setText] = useState("");

  useEffect(() => {
    if (message && message.content.message) {
      setText(message.content.message.text);
    }
  }, [message]);
  let getRequestText = () => {
    if (type === 5) {
      return "Start Reporting";
    } else if (type === 7) {
      return "Share Vitals";
    } else if (type === 15) {
      return "View Health Goals";
    } else if (type === 18) {
      return "Complete Task";
    }
  };

  let getLinkTo = () => {
    if (type === 5) {
      dispatch(logEvent({ action: "open_reporting_via_chat" }));
      history.push("/reporting");
    } else if (type === 7) {
      history.push("/vitals");
    } else if (type === 15) {
      dispatch(logEvent({ action: "click_goals_via_chat" }));
      history.push("/health-goals");
    } else if (type === 18) {
      history.push("/daily-task");
    }
  };

  let getImage = () => {
    if (type === 5) {
      return RequestRoutineImage;
    } else if (type === 7) {
      return VitalsImage;
    } else if (type === 15) {
      return ViewHealthGoals;
    } else if (type === 18) {
      return CompleteTaskImage;
    }
  };

  return (
    <>
      <div className="request-card-container">
        <div>
          <div className="request-card-body">
            <img className="icon-card" alt="" src={getImage()} />
            {text && text.trim() && (
              <div
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(text),
                }}
                className="request-message-content"
              ></div>
            )}
            <div className="start-button fw-medium">
              <div onClick={() => getLinkTo()}>{getRequestText()}</div>
            </div>
          </div>
        </div>
      </div>
      <TimeMessage message={message} />
    </>
  );
}
