import React from "react";
import covidImg from "../../assets/images/Digit/covid_cover.jpg";
import dollyBagai from "../../assets/images/Digit/Dr_Dolly_Bagai.svg";
import dikshaGoyal from "../../assets/images/Digit/Diksha Goyal.jpg";
import priyankaPaul from "../../assets/images/Digit/Dr Priyanka Paull.jpeg";
import kaminiMishra from "../../assets/images/Digit/Kamini Mishra.JPG";
import nidhiRawat from "../../assets/images/Digit/Nidhi Rawat.jpg";
import vaishnavi from "../../assets/images/Digit/Vaishnavi.jpg";
import karan from "../../assets/images/Digit/Dr Karan.png";
import rituSheokand from "../../assets/images/Digit/Ritu Sheokand.jpg";
import ketkiTawade from "../../assets/images/Digit/Ketki Tawade.jpg";
import pragya from "../../assets/images/Digit/Pragya Pal.jpg";
import arpit from "../../assets/images/Digit/Arpit_Gupta.jpg";
import pratistha from "../../assets/images/Digit/Pratishtha Naithani.png";
import trishlaRaut from "../../assets/images/Digit/Trishla Rautela.jpg";
import rebecaShweta from "../../assets/images/Digit/DrRebeccaShwetaGladwin.jpg";
import parimal from "../../assets/images/Digit/Parimal Piyush.jpg";
import CommonComponent from "./components/CommonComponent";

const middleList = [
  {
    id: 1,
    p1: "Specialist doctor consultations",
    p2: " and medicine prescription",
  },
  {
    id: 2,
    p1: "Nutrition and Ayurveda",
    p2: " (Natural home remedies)",
  },
  {
    id: 3,
    p1: "Physiotherapy and Lung Rehabilitation",
    p2: "",
  },
];
const Doctors = [
  {
    name: "Dr Dolly Bagai",
    image: dollyBagai,
    meta: "MBBS, Specialization in Family Medicine, Women’s Health. Renowned Family Physician, specialization from the prestigious GSVM Medical College, Kanpur. ",
    text: "MBBS, Specialization in Family Medicine, Women’s Health. Renowned Family Physician, specialization from the prestigious GSVM Medical College, Kanpur.  ",
    exp: "37+ years experience",
  },
  {
    name: "Dr Rebecca Shweta Gladwin",
    image: rebecaShweta,
    meta: "MD (Medicine), Family Physician, Previously at Boston University and Columbia Asia. Treated 5000+ COVID patients.",
    text: "MD (Medicine), Family Physician, Previously at Boston University and Columbia Asia. Treated 5000+ COVID patients.",
    exp: "10+ yrs experience",
  },
  {
    name: "Dr Karan Chopra",
    image: karan,
    meta: "MBBS, MD (Medicine), DM Cardiology. Head of Department at Venkateshwar Hospital, New Delhi Treated 2000+ Covid patients.",
    text: "MD (Medicine), FIACM, FIAMS 	Ex-Prof of Medicine, FH Medical College",
    exp: "10+ years of experience",
  },
  {
    name: "Dr Arpit Gupta",
    image: arpit,
    meta: "MD (Medicine), Fellowship in Cardiology from Medanta. Previously at Fortis. Expertise in infectious diseases.",
    exp: "10+ yrs experience",
    text: "MD (Medicine), Fellowship in Cardiology from Medanta. Previously at Fortis. Expertise in infectious diseases.",
  },
  {
    name: "Dr Kamini Mishra",
    image: kaminiMishra,
    meta: "MPT (Physiotherapy). Previously at NHI, Cloudnine and Apollo. Expertise in lung rehabilitation.",
    text: "9+ years of experience in treating patients with chronic health issues. Masters in Orthopedics, with previous work experience at NHI, Cloudnine and Apollo. Specializes in Physiotherapy modalities especially in chronic diseases such as Diabetes and Hypertension, an Invasive Pelvic Floor practitioner and an expert in Women health management.",
    exp: "9 yrs experience",
  },
  {
    name: "Dr Priyanka Paul",
    image: priyankaPaul,
    meta: "BAMS,PG (Diploma) in Clinical Nutrition and Dietetics with Fellowship in Pulmonary Rehabilitationand Nutrition and Dietetics ",
    text: "10+ years of rich experiene at renonwed hospitals such as Jyoti Punj, Fortis  and Apollo Hospitals. PG in Clinical Nutrition and Fellowship in Pulmonary Rehabilitation from Apollo Educational and Research Foundation. Specialises in managing chronic health disorders in patients.",
    exp: "10+ yrs experience",
  },
  {
    name: "Dr Pratishtha Naithani",
    image: pratistha,
    meta: "MA Clinical Psychology, Doctor of Philosophy",
    text: "Clinical Psychotherapist with expertise in understanding the psychological state of patients and their family members to help them deal with chronic diseases. Also associated with organisations such as Optum, Gautam Hospital & Research Centre.",
  },
  {
    name: "Ritu Sheokand",
    image: rituSheokand,
    meta: "BSc. Home Science Hons., MSc. in Dietetics & Food Service Management",
    text: "Qualified in Therapeutic Nutrition with distinction from the prestigious Lady Irwin College, Delhi University. A Diploma holder in Dietetics from VLCC and MSc. in Dietetics from IGNOU. Previous experience at Metro Hospital, Faridabad focused on chronic diseases.",
  },
  {
    name: "Trishla Rautela",
    image: trishlaRaut,
    meta: "BSc. Home Science, MSc. Dietetics & Food Service Management, MSc. Nutrition",
    text: "An expert in Nutrition with Masters from distinguished universities like IGNOU and University of Leeds, UK. Skilled in analysing the routine & lifestyle of patients dealing with chronic diseases and helping them get make improvements in their acute and chronic symptoms.",
  },
  {
    name: "Dr Pragya Pal",
    image: pragya,
    meta: "Occupational Therapist, Specialised in Neuro-rehabilitation and Motor Reeducation",
    text: "Alumna of the prestigious Pt. Deen Dayal Upadhyaya National Institute., Delhi University. Specialised in Neuro-rehabilitation and Motor re-education with a passion for holistic recovery of patients and improvement in their quality of life through personalised physiotherapy programs.",
  },
];
const SecondList1 = [
  "24x7 analysis of vitals and guidance",
  "24x7 unlimited queries for 15 days and support on chat",
  "Consultation calls with experts:",
];
const SecondList2 = [
  "Continuous stress/ anxiety counselling",
  "Continuous analysis of latest test reports",
  "Management of other comorbidities like diabetes, BP and thyroid",
  "Do’s & dont’s for home quarantine to protect other family members",
];
const innerList = [
  {
    id: 1,
    day: "DAY 1",
    text: " - COVID Diagnosis consultation call & COVID nutrition guidelines + quarantine protocol",
  },
  {
    id: 2,
    day: "DAY 2",
    text: " - Senior Doctor consultation call + COVID prescription",
  },
  {
    id: 3,
    day: "DAY 3",
    text: " - Lung Rehabilitation expert call",
  },
  { id: 4, day: "DAY 7", text: "- Senior Doctor consultation call" },
  {
    id: 5,
    day: "DAY 9",
    text: " - Clinical Nutritionist consultation call",
  },
  {
    id: 6,
    day: "DAY 14",
    text: " - Post-COVID recovery expert call",
  },
];
const MainList = [
  "Patients who have been diagnosed with COVID and now recovering at home",
  "Patients alone at home who tested positive, in need for medical + emotional support",
  "Criterion for enrolment is SPO2 more than 94",
];
const Patients = [
  {
    image: parimal,
    name: "Parimal Piyush",
    content:
      "Recently, I was found positive for COVID. Being a diabetic, I had to take all necessary measures so that I could face it with positive mind. By God's grace I got cured and now again recovery was a challenge due to immense weakness and little breathlessness. Zyla guided me here for many things and now I have almost recovered. My diabetes is also controlled. Where there is a will, there is a way.",
  },
];
const FAQs = [
  {
    title: "Will I be able to Cancel this plan?",
    content:
      "This plan is non-cancellable as managing the post-COVID symptoms with lung rehabilitation, boosting immunity, managing the blood sugar fluctuations along with the other health issues such as BP, thyroid needs an effective and expert’s management. ",
  },
  {
    title:
      "How is COVID Recovery Plan different from the other health Programs?",
    content:
      "The Covid Recovery Plan is 100% personalized, based on your latest diagnostic test report, all your health conditions in addition to Covid (such as diabetes, BP, thyroid, fatty liver, obesity), your chief complaints (such as fever, weakness, difficulty in breathing, lack of appetite, headaches, body pains, acidity, constipation). Our senior doctors will recommend preventive and immunity building medicines on Day 1-2 of the Program and our Nutritionist will design the best nutrition plan for you. The Physiotherapy doctors will plan customized breathing exercises and the team will enable stress/ health anxiety management with audio guided meditations. The program includes your own Care Manager on the app for daily hand-holding and answers to any query in real-time anytime, anywhere.",
  },
  {
    title: "What makes COVID Recovery Plan unique?",
    content:
      "We strongly believe that recovering from a severe health condition such as COVID and preventing other associated complications requires hand-holding, which isn’t possible if we ask you to visit a Centre daily. Ours is a virtual program that gives you access to some of the best doctor brains in the country at your home, enabled through phone calls and our mobile application. Your Care Manager will be with you every time, quickly accessible via chat. With this program, you will feel like we are right next to you, in fact, you will be surprised by the level of personal care you get.",
  },
  {
    title:
      "I have other health problems also along with COVID. Will you also help me with those problems?",
    content:
      "Of course! In the first detailed assessment call, the Care Team will be spending around 30 minutes to understand your complete health, organ by organ. They will be discussing all your symptoms and health issues and plan personalized interventions for recovering from COVID and the other key health issues or organ damage that you are facing. The goal of the program is to help you recover from COVID, protect your organs like the lungs, heart and kidney from damage, and possibly, reduce your dependence on medicines.",
  },
  {
    title:
      "This program is for my father/ mother. How will you manage them, they are not even comfortable in using the mobile app?",
    content:
      "Do not worry, we have handled thousands of parents through their caregivers. In fact, the program will be very helpful as you can pass on the stress of their health to us and the team will ensure they achieve their health goals. Without a doubt, our app will be the simplest mobile app you have ever used! It is designed keeping in mind that you are busy or may not have time to learn a complex application. What’s more? We will help you at every step and make this super easy and seamless for you.",
  },
  {
    title: "How will you treat me through a virtual program?",
    content:
      "We strongly believe that treating any health condition requires a top-notch team, a lot of analysis of your health, and hand-holding. Every interaction you have with the care team, all health plans that are designed for you are duly documented and shared with you on the app. Your Care Manager will be with you all the time, quickly accessible via chat, so that we stay with you at every step of recovery. In fact, you will be surprised by the level of personal care you get.",
  },
];
function WeightManagement(props) {
  return (
    <CommonComponent
      topImg={covidImg}
      programName={"COVID Home Recovery Program"}
      programDesc={
        "Trusted online doctor consultation for COVID positive patients through COVID specialist doctors are available through Zyla Health. To help patients who have been diagnosed with COVID and now recovering at home, Zyla has introduced the most effective program under Doctors previously at"
      }
      middleListHeading={
        "Get high quality care to recover from COVID-19 under leading COVID specialist doctors."
      }
      middleListContent={middleList}
      doctorsList={Doctors}
      box4={{
        heading: "The 15 day program includes -",
        heading1: "COVID Home Care Program",
        heading2: "₹2900 for 15 days care",
        secondList1: SecondList1,
        secondList2: SecondList2,
        innerList: innerList,
        mainList: MainList,
      }}
      patients={Patients}
      therapy={"covid"}
      faqs={FAQs}
      requestbox={{
        heading: "Ready to get started?",
        desc: "Protect yourself by choosing COVID -19 care under expert healthcare guidance",
      }}
      blogHeading={"Read More about Covid"}
      isCovid={true}
    />
  );
}

export default WeightManagement;
